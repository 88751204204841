import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {TextField} from "@mui/material";
import DateTimeInput from "../../../../components/UI/filter/DateTimeInput";
import DateTimeUtil from "../../../../utils/common/DateTimeUtil";
import IssuanceService from "../../../../API/orders/prodaction.issueedge/issuance/IssuanceService";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../../Constants";

const InWork = () => {
        const [startInWork, setStartInWork] = useState(null);
        const [startInStock, setStartInStock] = useState(null);
        const [dataInWork, setDataInWork] = useState(null);
        const [dataInStock, setDataInStock] = useState(null);
        const [alertData, setAlertData] = useState({});

        const applyInWork = () => {
            IssuanceService.getInWork(DateTimeUtil.convertToJavaDateTime(startInWork)).then(function (response) {
                const data = response.data;
                setDataInWork(data);
            })
                .catch(function (error) {
                    handleError(error);
                });
        }
        const handleError = (error) => {
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }

        const applyInStock = () => {
            IssuanceService.getInStock(DateTimeUtil.convertToJavaDateTime(startInStock)).then(function (response) {
                const data = response.data;
                setDataInStock(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        return (
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={"В работе"}
                    path={[
                        {name: "Главная", href: "/"},
                    ]}/>
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                    В работе
                </Typography>
                <Box sx={{display: "flex", gap: "50px", paddingTop: "30px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "20px", width: 480}}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                flexWrap: 'wrap',
                            }}
                        >
                            <DateTimeInput
                                value={startInWork}
                                setValue={setStartInWork}
                                variant={"outlined"}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: '0.5rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <ButtonCreate
                                    text={"Вычислить в работе"}
                                    onClick={applyInWork}
                                    icon={false}
                                    width={180}
                                    disabled={!startInWork}/>
                            </Box>
                        </Box>

                        <TextField
                            size={"small"}
                            margin="none"
                            key={"base"}
                            label={"В базовой валюте"}
                            variant="standard"
                            name={"base"}
                            disabled={true}
                            value={dataInWork === null ? "" : dataInWork.first.toFixed(2)}
                        />
                        <TextField
                            size={"small"}
                            margin="none"
                            key={"alternative"}
                            label={"В Альтернативной валюте"}
                            variant="standard"
                            name={"base"}
                            disabled={true}
                            value={dataInWork === null ? "" : dataInWork.second.toFixed(2)}
                        />

                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "20px", width: 490}}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                flexWrap: 'wrap',
                            }}>
                            <DateTimeInput
                                value={startInStock}
                                setValue={setStartInStock}
                                variant={"outlined"}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: '0.5rem',

                                    flexWrap: 'wrap',
                                }}
                            >
                                <ButtonCreate
                                    text={"Вычислить на складе"}
                                    onClick={applyInStock}
                                    icon={false}
                                    width={190}
                                    disabled={!startInStock}/>
                            </Box>
                        </Box>
                        <TextField
                            size={"small"}
                            margin="none"
                            key={"base"}
                            label={"В базовой валюте"}
                            variant="standard"
                            name={"base"}
                            disabled={true}
                            value={dataInStock === null ? "" : dataInStock.first.toFixed(2)}
                        />
                        <TextField
                            size={"small"}
                            margin="none"
                            key={"alternative"}
                            label={"В Альтернативной валюте"}
                            variant="standard"
                            name={"base"}
                            disabled={true}
                            value={dataInStock === null ? "" : dataInStock.second.toFixed(2)}
                        />
                    </Box>
                </Box>
                <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                    return {...alert, open: false}
                })}/>
            </Box>
        );
    }
;

export default InWork;