import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_ToggleFiltersButton} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Paper, Stack, Tooltip} from "@mui/material";
import MaterialEstimateUnitService
    from "../../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import ModalConfirm from "../../../../modal/ModalConfirm";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import {Link} from "react-router-dom";
import CellMaterialReserveOptimizationQuantity from "./CellMaterialReserveOptimizationQuantity";
import ReplacementSimilarMaterials
    from "../../../../../../pages/organization/orders/estimate/ReplacementSimilarMaterials";
import MenuItem from "@mui/material/MenuItem";
import ButtonCreate from "../../../../button/ButtonCreate";
import ModalAddMaterial from "./ModalAddMaterial";
import MaterialReserveService from "../../../../../../API/orders/reservation/MaterialReserveService";
import {materialEstimateUnitStates} from "../../../../../../data/MaterialEstimateUnitState";
import ButtonSelectAction from "../../../../button/ButtonSelectAction";
import {ModalSelectAction} from "../../../../modal/ModalSelectAction";
import selectionTableUtil from "../../../../../../utils/common/SelectionTableUtil";

const EstimateTableItem = ({
                               estimateId,
                               estimateState,
                               orderId,
                               orderNumber,
                               orderHeaderId,
                               year,
                               isButtonPurchase,
                               isActual,
                               enableEditing,
                               inWork,
                               orderCloud,
                               isFact,
                               data,
                               setData,
                               isBlockedReplacement,
                               currency,
                               currentCurrency,
                               tableOptions,
                               tableOptionsHandle,
                               selectData,
                               handleError,
                               getData
                           }) => {
    const initValue = {material: {}, quantity: "", note: ""}
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);
    const [currentMaterialData, setCurrentMaterialData] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [rowSelection, setRowSelection] = useState([]);
    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
    const [currentInWork, setCurrentInWork] = useState(inWork);
    const [searchSimilarMaterials, setSearchSimilarMaterials] = useState(false);
    const [showReservesModal, setShowReservesModal] = useState(false);
    const [materialModal, setMaterialModal] = useState(false);
    const [reservesDelete, setReservesDelete] = useState(false);
    const [openSelection, setOpenSelection] = useState(false)

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.costBasePrice && mEU.costBasePrice.price != null) {
                return acc + mEU.costBasePrice.price;
            }
            return acc;
        }, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.costAlternativePrice && mEU.costAlternativePrice.price != null) {
                return acc + mEU.costAlternativePrice.price;
            }
            return acc;
        }, 0),
        [data]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.id;
            const materialId = row.id
            MaterialEstimateUnitService.delete(id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                    /*  materialPriceUnits.delete(materialId);*/
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );

    const handleSubmitSetPurchase = async () => {
        EstimateService.setPurchase(estimateId)
            .then(function () {
                handleCloseSetPurchase()
                setCurrentInWork(false);
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const handleCloseSetPurchase = () => {
        setSetPurchaseModalOpen(false);
    };

    const openSearchSimilarMaterials = useCallback(
        (row) => {
            setCurrentMaterialData(row.materialOrganizationOption);
            setCurrentEditRowData(row);
            setCurrentEditRowId(row.id)
            setSearchSimilarMaterials(true)
        },
        [],
    );

    const replacement = async () => {
        const id = currentEditRowData.id
        const materialOrganizationOptionId = currentMaterialData.id
        MaterialEstimateUnitService.replacementByMaterialOrganizationOptionId(id, materialOrganizationOptionId)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                setSearchSimilarMaterials(false);
            })
            .catch(function (error) {
                handleError(error);
            })
    }
    const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

    const handleReserveRow = (row) => {
        setCurrentEditRowId(row.id)
        setShowReservesModal(true)
    }
    const handleDeleteReserve = (row) => {
        setCurrentEditRowId(row.id)
        setShowReservesModal(true)
        setReservesDelete(true)
    }

    const closeShowReservesModal = () => {
        setShowReservesModal(false)
        setCurrentEditRowId(null)
        setReservesDelete(false)
        setRowSelection([])
        setOpenSelection(false)
    }

    const handleShowReservesModal = async () => {
        reservesDelete ?
            MaterialReserveService.delete(currentEditRowId, false, false, false, false)
                .then(function (response) {
                    getData()
                    closeShowReservesModal()
                })
                .catch(function (error) {
                    handleError(error);
                })
            :
            EstimateService.createReserves(orderId, selectData.cost === 1, rowSelection.length > 0 ? {
                "ids": rowSelection
            } : {
                "ids": null
            })
                .then(function (response) {
                    getData()
                    closeShowReservesModal()
                })
                .catch(function (error) {
                    handleError(error);
                })
    }
    const handleCloseMaterialModal = () => {
        setMaterialModal(false)
        setCurrentEditRowData(initValue)
        setCurrentEditRowId(null)
    }
    const handleSubmit = async (value) => {
        MaterialEstimateUnitService.create(estimateId, value)
            .then(function (response) {
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
                handleCloseMaterialModal()
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.articleNumber : '';
                }
            },
            {
                id: 'requestsCuttingNumber',
                header: 'Заявка',
                accessorKey: 'requestsCutting.number',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row, rowIndex}) => {
                    const {materialOrganizationOption, requestsCutting} = row.original;

                    if (materialOrganizationOption === null) {
                        return '';
                    }

                    if (materialOrganizationOption.typeMaterial === 'SLAB') {
                        const nominalQuantity = requestsCutting ? requestsCutting.nominalQuantity.toFixed(3) : '';

                        return (
                            <Tooltip title={"В заявке на раскрой " + nominalQuantity + " кв. м"} arrow>
                                {requestsCutting === null ? (
                                    'Ожидание'
                                ) : (
                                    <Link
                                        to={'/organization/requests-cutting/detail/' + requestsCutting.id + '/' + requestsCutting.number}>
                                        {cell.getValue()}
                                    </Link>
                                )}
                            </Tooltip>
                        );
                    } else if (materialOrganizationOption.subTypeMaterial === "EDGE") {
                        return 'Кромочный';
                    } else {
                        return 'Фурнитура';
                    }
                }
            },
            {
                id: 'unit',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.unit.name : row.original.tempUnit.name;
                }
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => (row.original.materialOrganizationOption === null) ? '' :
                    <CellMaterialReserveOptimizationQuantity
                        value={cell.getValue()}
                        row={row}
                    />
            },
            {
                header: 'Статус',
                accessorKey: 'materialEstimateUnitState',
                size: 150,
                Cell: ({cell}) => materialEstimateUnitStates[cell.getValue()],
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ];

        if (!isFact) {
            baseColumns.splice(1, 0, {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row, rowIndex}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    const isSlab = temp ? null : row.original.materialOrganizationOption.typeMaterial === 'SLAB';
                    const link = temp ? null : <Link
                        to={'/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId
                            + '/' + year + '/estimates/' + estimateId + '/' + estimateState + '/' + isButtonPurchase + '/reservation/'
                            + row.original.id + '/' + isSlab + '/' + encodeURIComponent(row.original.materialOrganizationOption.name)
                            + '/' + encodeURIComponent(row.original.materialOrganizationOption.articleNumber) + '/' + 'calculation/false'}>
                        {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                    </Link>
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {link}
                            </span>
                    );
                },
            });
        }

        if (isFact) {
            baseColumns.splice(1, 0, {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row, rowIndex}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    const isSlab = temp ? null : row.original.materialOrganizationOption.typeMaterial === 'SLAB';
                    const link = temp ? null : <Link
                        to={'/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId
                            + '/' + year + '/estimates/' + estimateId + '/' + estimateState + "/" + isButtonPurchase + '/reservation/'
                            + row.original.id + '/' + isSlab + '/' + encodeURIComponent(row.original.materialOrganizationOption.name)
                            + '/' + encodeURIComponent(row.original.materialOrganizationOption.articleNumber) + '/' + 'plan/true'}>
                        {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                    </Link>
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {link}
                            </span>
                    );
                },
            });
        }

        baseColumns.splice(10, 0, {
            id: 'costBasePrice',
            header: `Стоимость в ${currency[0].value}`,
            accessorKey: 'costBasePrice.price',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {sumAmountBasePrice?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })} {currency[0].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(10, 0, {
            id: 'costAlternativePrice',
            header: `Стоимость в ${currency[1].value}`,
            accessorKey: 'costAlternativePrice.price',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })} {currency[1].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(10, 0, {
            id: 'reservePercentage',
            header: '% резерва',
            accessorKey: 'reservePercentage',
            minSize: 15,
            maxSize: 15,
        });

        return baseColumns;
    }, [data, setData]);

    const menuItem = [
        {type: "edit", text: "Заменить", handler: openSearchSimilarMaterials},
        {type: "delete", text: "Удалить", handler: handleDeleteRow},
        {type: "deleteReserve", text: "Удалить резервы", handler: handleDeleteReserve}
    ]
    const modalActions = [
        {name: "Создать резервы", type: "action1"},
    ]

    const statusesForAddMaterial = ["IN_WORK",
        "CREATED_RESERVES",
        "PURCHASE",
        "PURCHASED"]
    const addMaterialDisabled = (selectData.cost === 1 && estimateState !== "CALCULATION") || (selectData.cost === 2 && !statusesForAddMaterial.includes(estimateState))

    return (
        <Box sx={{maxWidth: "2500px"}}>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", gap: "10px", paddingBottom: "30px"}}
            >
                <ButtonCreate
                    text={"Создать резервы"}
                    width={210}
                    onClick={() => setShowReservesModal(true)}
                    disabled={addMaterialDisabled}
                />
                <ButtonCreate
                    text={"Добавить материал"}
                    width={210}
                    onClick={() => setMaterialModal(true)}
                    disabled={addMaterialDisabled}
                />
                {isButtonPurchase === 'true' &&
                    <ButtonCreate
                        text={"Подать заявку на закупку"}
                        width={210}
                        onClick={() => setSetPurchaseModalOpen(true)}
                        disabled={!currentInWork}
                    />}
            </Box>
            <Box sx={{overflow: 'auto'}}>
                <MaterialReactTable
                    muiTopToolbarProps={{
                        sx: {
                            "& .MuiBox-root": {
                                padding: 0
                            }
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem",
                            "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                "minWidth": "100%"
                            }
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            sx: () => ({
                                height: '36px',
                                backgroundColor: "white",
                                "&:hover& td:after": {
                                    display: "none",
                                },
                                "&:hover": {
                                    "& td:after": {
                                        display: "none",
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                },
                            })
                        })
                    }}

                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        sx: {
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                        'mrt-row-select': {
                            size: "50px",
                            grow: false,
                        },
                    }}
                    columns={columns}
                    data={data}
                    onRowSelectionChange={onRowSelectionChange}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowSelection={true}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    initialState={{
                        columnVisibility: {
                            id: false,
                            model: false,
                            year: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                    }}
                    localization={MRT_Localization_RU}
                    enableRowActions={true}
                    enableToolbarInternalActions={false}
                    renderTopToolbarCustomActions={({table}) => (
                        <Box sx={{
                            width: "100%",
                            padding: 0,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItem: "center"
                        }}>
                            <MRT_ToggleFiltersButton table={table}/>
                        </Box>)}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: Object.keys(rowSelection).length !== 0 ? `calc(100vh - 780px)` : `calc(100vh - 670px)`,
                            width: "2500px"
                        }
                    }}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <Tooltip arrow placement="left"
                                        title={row.original.isBlocked ? "Материал заблокирован" : ""}><MenuItem
                            key={el.type}
                            onClick={() => {
                                !row.original.isBlocked && el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                opacity: row.original.isBlocked && 0.5,
                                "&:hover": {backgroundColor: row.original.isBlocked ? "transparent" : (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                        </Tooltip>
                    })}
                />
            </Box>
            <ModalConfirm
                title={"Подтвердить создание заявки на закупку?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
            {currentMaterialData ? <ReplacementSimilarMaterials
                open={searchSimilarMaterials}
                setOpen={setSearchSimilarMaterials}
                replacement={replacement}
                currentEditRowData={currentMaterialData}
                setCurrentEditRowData={setCurrentMaterialData}
                id={currentEditRowData.id}
                handleError={handleError}
            /> : ""}
            <ModalConfirm
                title={reservesDelete ? "Вы действительно хотите удалить резервы?" : "Вы действительно хотите создать резервы?"}
                modalOpen={showReservesModal}
                handleClose={closeShowReservesModal}
                handleSubmit={handleShowReservesModal}
                action={reservesDelete ? "Удалить" : 'Создать'}
            />
            <ModalAddMaterial
                open={materialModal}
                title={"Добавить материал"}
                initValue={currentEditRowData}
                onClose={handleCloseMaterialModal}
                handleSubmit={handleSubmit}
                handleError={handleError}
            />
            {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: '100%',
                height: "88px",
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.primary.lightGrey,
            }} component="footer">
                <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpenSelection(true)}/>
            </Paper>}
            <ModalSelectAction
                open={openSelection}
                reset={() => setOpenSelection(false)}
                submit={handleShowReservesModal}
                modalActions={modalActions}
            />
        </Box>
    );
};

export default EstimateTableItem;

