import React, {useState} from 'react';
import AllMaterialPageItemModule from "../../../components/UI/modul/AllMaterialPageItemModule";
import MaterialPageItemModule from "../../../components/UI/modul/MaterialPageItemModule";
import {Breadcrumbs, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import {constantsErrorMessages} from "../../../Constants";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";

const AllMaterials = () => {
    const [alertData, setAlertData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    return <Box sx={{padding: "0 30px"}}>
        <BreadcrumbsComponent page={"Материалы"} path={[{name: "Главная", href: "/"}]}/>
        <MaterialPageItemModule
            allMaterial={true}
            refMaterial={true}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleError={handleError}
        />
        <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
            return {...alert, open: false}
        })}/>
    </Box>
};

export default AllMaterials;