import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";
import MaterialPageItemModule from "../../../modul/MaterialPageItemModule";
import {useEffect, useState} from "react";
import MaterialEstimateUnitService from "../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import MaterialForRequestsCuttingTable from "../../orders/organization/MaterialForRequestsCuttingTable";


const ModalAddCustomerRequestCutting = ({
                                            initValue,
                                            open,
                                            onClose,
                                            title,
                                            handleSubmit,
                                            handleError,
                                            isCustomer,
                                            orderId
                                        }) => {
    const [material, setMaterial] = useState(initValue)
    const [materials, setMaterials] = useState([{}])
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 10,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }

    useEffect(() => {
        setMaterial(initValue)
    }, [initValue])
    useEffect(() => {
        open && !isCustomer && MaterialEstimateUnitService.getAllByOrderIdForRequestsCutting(orderId).then(function (response) {
            let data = response.data;
            setMaterials(data);
            setMaterial(data[0]?.id)
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [open]);
    return (<Dialog open={open} PaperProps={{
        style: {
            minWidth: "1080px",
            height: "716px",
            borderRadius: 8,
        }
    }}>
        <DialogContent sx={{padding: "0px 20px 0px 40px"}}>
            <Box style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{title}</DialogTitle>
            </Box>
            {isCustomer ? <MaterialPageItemModule
                    isOrganization={false}
                    selectMaterialId={material}
                    selectMaterial={(el) => setMaterial(el.id)}
                    typeMaterial={"SLAB"}
                    modal={true}
                    handleError={handleError}
                /> :
                <MaterialForRequestsCuttingTable
                    data={materials} tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    selectMaterialId={material}
                    selectMaterial={(el) => setMaterial(el.id)}
                />}
        </DialogContent>
        <DialogActions style={{padding: 40, display: "flex", justifyContent: "flex-end"}}>

            <ButtonExit text={"Выйти"} onClick={onClose}/>
            <ButtonAdd text={"Выбрать"} onClick={() => handleSubmit(material)}/>

        </DialogActions>
    </Dialog>)
}
export default ModalAddCustomerRequestCutting