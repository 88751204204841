import React, {useEffect, useState} from 'react';
import PlanTable from "../../../../components/UI/table/optimization/plans/PlanTable";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import PlanService from "../../../../API/orders/optimization/organization/PlanService";

const Plans = () => {
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    useEffect(() => {
        getStartPageSize()
        PlanService.getAllNotArchive().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/plans`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/plans`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Планы"}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заказы", href: "/organization/orders"}
                ]}/>

            <PlanTable
                data={data}
                setData={setData}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
        </Box>
    );
};

export default Plans;