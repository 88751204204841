import React, {useEffect, useState} from 'react';
import BasicModelEstimateTableItem
    from "../../../../components/UI/table/model-estimate/estimate/BasicModelEstimateTableItem";
import {useLocation, useParams} from "react-router-dom";
import {Box, Button, Tabs} from "@mui/material";
import ModelDetails from "./ModelDetails";
import ModelProductionCosts
    from "../../../../components/UI/table/model-estimate/modelproductioncosts/ModelProductionCosts";
import ModelJobMetric from "../../../../components/UI/table/model-estimate/modelproductioncosts/ModelJobMetric";
import ModelMaterialEstimateUnitService from "../../../../API/model-estimate/estimate/ModelMaterialEstimateUnitService";
import UnitService from "../../../../API/orders/warehouse/UnitService";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../../Constants";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";

const BasicModelsMaterialEstimateUnit = () => {
    const pathname = useLocation().pathname
    const params = useParams();
    const id = params.id;
    const estimateNumber = params.estimateNumber
    const estimateId = params.estimateId;
    const [data, setData] = useState([]);
    const [jobWorkMetricOrderOrganization, setJobWorkMetricOrderOrganization] = useState([]);
    const [productionCostsWork, setProductionCostsWork] = useState([]);
    const [tab, setTab] = useState(1);
    const [listUnits, setListUnits] = useState([]);
    const [unitMap, setUnitMap] = useState(new Map());
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const savedTab = pagesData[pathname]?.tab
        savedTab && setTab(savedTab)

        getTabsData(savedTab, true)
    }
    const tableOptionsHandle = (nextState, currTab) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${tab}`, "tableOptions", nextState)
        }
    }
    const getTabsData = (tab, start) => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const tableOptions = pagesData[`${pathname}+${tab}`]?.tableOptions

        let totalElements
        if (tab === 1) {
            totalElements = data?.length
        } else if (tab === 3) {
            totalElements = jobWorkMetricOrderOrganization?.totalElements
        } else if (tab === 4) {
            totalElements = productionCostsWork?.totalElements
        }

        setTableOptions((prev) => ({
            ...prev,
            pageSize: tableOptions?.pageSize || 20,
            totalElements: start ? tableOptions?.totalElements : totalElements
        }))
    }

    const handleChangeTab = (tab) => {
        setTab(tab)
        getTabsData(tab)
        EditLocalStoragePagesData(pathname, "tab", tab)
    }
    const calcData = (data) => {
        setData(data);
        const othersWithJobMetric = [];
        const prCostsWork = [];
        data.forEach(item => {
            let jobMetricOrderOrganizations = item.jobMetricOrderOrganizations;
            if (jobMetricOrderOrganizations) {
                jobMetricOrderOrganizations.forEach(jobMetricItem => {
                    othersWithJobMetric.push({...jobMetricItem, material: item.id})
                    let productionCosts = jobMetricItem.productionCosts
                    if (Array.isArray(productionCosts)) {
                        productionCosts.forEach(cost => {
                            prCostsWork.push({...cost, material: item.id, job: jobMetricItem.id})
                        })
                    }
                });
            }
        });
        setJobWorkMetricOrderOrganization(othersWithJobMetric)
        setProductionCostsWork(prCostsWork);
    }
    useEffect(() => {
        getStartPageSize()
        ModelMaterialEstimateUnitService.getAllByEstimateId(estimateId).then(function (response) {
            const data = response.data;
            calcData(data)
        })
            .catch(function (error) {
                handleError(error);
            });

        UnitService.getAll().then(function (response) {
            let data = response.data;
            setListUnits(data)
            data.forEach(unit => {
                unitMap.set(unit.id.toString(), unit.name);
            });
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const generalTabComponents = [
        {
            tabIndex: 1, label: "Материалы",
            component:
                <BasicModelEstimateTableItem
                    data={data}
                    setData={setData}
                    estimateId={estimateId}
                    unitMap={unitMap}
                    listUnits={listUnits}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                    calcData={calcData}
                />
        },
        {
            tabIndex: 2, label: "Детали",
            component:
                <ModelDetails
                    estimateId={estimateId}
                    handleError={handleError}
                />
        },
        {
            tabIndex: 3, label: "Работы",
            component:
                <ModelJobMetric
                    data={jobWorkMetricOrderOrganization}
                    setData={setJobWorkMetricOrderOrganization}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    andleError={handleError}
                    calcData={calcData}
                    materials={data}
                    listUnits={listUnits}
                />
        },
        {
            tabIndex: 4, label: "Затраты по работам",
            component:
                <ModelProductionCosts
                    data={productionCostsWork}
                    setData={setProductionCostsWork}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                    calcData={calcData}
                    materials={data}
                    listUnits={listUnits}
                />
        }]
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={estimateNumber}
                path={[{name: "Главная", href: "/"}, {name: "Настройки", href: "/settings"},
                    {name: "Смета", href: `/organization/basic-models/${id}/estimates`}]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "30px"}}>
                <ButtonGroupTabBar activeTab={tab}>
                    {generalTabComponents.map(({tabIndex, label}, index) => (
                        <Button key={tabIndex} tabIndex={tabIndex} onClick={() => handleChangeTab(tabIndex)}>
                            <CheckIcon/>
                            {label}
                        </Button>
                    ))}
                </ButtonGroupTabBar>
                {generalTabComponents.find(component => component.tabIndex === tab)?.component}
            </Box>
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => ({...alert, open: false}))}/>
        </Box>
    );
};

export default BasicModelsMaterialEstimateUnit;