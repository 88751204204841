import React, {useEffect, useState} from 'react';
import ArrivalMaterialTable from "../../../../../components/UI/table/warehouse/material/arrival/ArrivalMaterialTable";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import ArrivalMaterialService from "../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import {BreadcrumbsComponent} from "../../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";

const ArrivalMaterialArchive = () => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    useEffect(() => {
        getStartPageSize()
        ArrivalMaterialService.getAll('archive').then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Архив"}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заявки на поставку", href: "/organization/materials-arrival"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Архив
                    </Typography>
                </Box>
                <ArrivalMaterialTable
                    isCreateOrUpdate={false}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    data={data}
                    setData={setData}
                    enableRowSelection={false}
                    setTableOptions={setTableOptions}
                    archive={true}
                />
            </Box>
        </Box>
    );
};

export default ArrivalMaterialArchive;