import React, {useEffect, useState} from 'react';
import CustomerTable from "../../../components/UI/table/orders/organization/customers/CustomerTable";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import OrganizationCustomerService from "../../../API/orders/orders/organization/OrganizationCustomerService";
import {Paper} from "@mui/material";
import ButtonSelectAction from "../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../components/UI/modal/ModalSelectAction";

const Customers = ({itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [rowSelection, setRowSelection] = useState([]);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const modalActions = [
        {name: "Объединить", type: "merge"},
    ]
    const handleSelectAction = () => {
        OrganizationCustomerService.merge(rowSelection).then(function (response) {
            setOpen(false)
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    useEffect(() => {
        getStartPageSize()
        OrganizationCustomerService.getAllBySettings().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);
    const buttonSelectActionDisabled = data.filter(el => rowSelection.includes(el.id)).every(el => el.blocked)
    return (
        <div>
            <CustomerTable
                data={data}
                setData={setData}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleError={handleError}/>
            {Object.keys(rowSelection).length === 2 && <Paper sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: '100%',
                height: "88px",
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.primary.lightGrey,
            }} component="footer">
                <ButtonSelectAction text={"Выбрать действие"} disabled={buttonSelectActionDisabled}
                                    onClick={() => setOpen(true)}/>
            </Paper>}
            <ModalSelectAction
                open={open}
                reset={() => setOpen(false)}
                submit={handleSelectAction}
                modalActions={modalActions}
            />

        </div>
    );
};

export default Customers;