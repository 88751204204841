import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper} from "@mui/material";
import MaterialPriceUnitService from "../../../../../../API/orders/warehouse/MaterialPriceUnitService";
import MaterialPageItemModule from "../../../../modul/MaterialPageItemModule";
import MaterialPriceUnitUtil from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import UserSupplierMaterialService from "../../../../../../API/orders/warehouse/material/UserSupplierMaterialService";
import CurrencyNationalBank from "../../../../../../API/orders/warehouse/currency/CurrencyNationalBankService";
import ChoiceSlabMaterialModal from "./slab/ChoiceSlabMaterialModal";
import ChoiceFixedStripeModal from "./slab/ChoiceFixedStripeModal";
import SupplierMaterialUtil from "../../../../../../utils/warehouse/SupplierMaterialUtil";
import MaterialOrganizationOptionService
    from "../../../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import {Box} from "@mui/system";
import ButtonBack from "../../../../button/ButtonBack";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import AddMaterialPriceParameters from "./AddMaterialPriceParameters";


export async function setMaterialPriceUnit(basePrice, setBasePriceCurrency,
                                           currentCurrency, baseCurrency,
                                           alternativeCurrency,
                                           supplierMaterial,
                                           material,
                                           isSlabMaterial,
                                           materialPriceUnit,
                                           isAddMaterial,
                                           isSetBasePriceCurrency,
                                           currencyMap, handleError) {
    async function getCurrency(currency) {

        const BYN = {
            curOfficialRate: 1,
            curScale: 1,
        }

        let currencyNationalBank;

        if (currency === 'BYN' || currency === null) {
            currencyNationalBank = BYN;
        } else {
            await CurrencyNationalBank.getByCurAbbreviation(currency)
                .then(function (response) {
                    currencyNationalBank = response.data;
                })
                .catch(function (error) {
                    handleError(error);
                });
        }
        return currencyNationalBank;
    }

    let curAbbreviationBasePrice
    if (basePrice !== null && basePrice !== undefined) {
        curAbbreviationBasePrice = currencyMap.get(basePrice.currencyId).curAbbreviation;
        if (isSetBasePriceCurrency) {
            setBasePriceCurrency(curAbbreviationBasePrice);
        }
    }

    let currencyNationalBankBasePrice = await getCurrency(curAbbreviationBasePrice);
    let currencyNationalBankArrivalPrice = await getCurrency(currentCurrency);
    let currencyNationalBankBaseMaterialPrice = await getCurrency(baseCurrency);
    let currencyNationalAlternativeBaseMaterialPrice = await getCurrency(alternativeCurrency);

    let length = 0;
    let width = 0;

    if (isSlabMaterial && supplierMaterial !== null) {
        const typeSupplierMaterial = supplierMaterial.typeSupplierMaterial;
        if (typeSupplierMaterial === "BY_FIXED_STRIPE" || typeSupplierMaterial === "WHOLE") {
            length = supplierMaterial.length;
            width = supplierMaterial.width;
        } else {
            length = supplierMaterial.length;
            width = 2070 - supplierMaterial.width;
        }
    }

    let quantity = 0;
    let amountInCurrencyArrival = 0;

    const officialExchangeRateToBaseCurrencyBaseMaterials = MaterialPriceUnitUtil.setExchangeRate
    (currencyNationalBankBaseMaterialPrice, currencyNationalBankArrivalPrice);
    const officialExchangeRateToAlternativeCurrencyBaseMaterials = MaterialPriceUnitUtil.setExchangeRate
    (currencyNationalAlternativeBaseMaterialPrice, currencyNationalBankArrivalPrice);
    const officialExchangeRateToBasePrice = basePrice === null ? null : MaterialPriceUnitUtil.setExchangeRate
    (currencyNationalBankBasePrice, currencyNationalBankArrivalPrice);
    if (isSlabMaterial) {
        quantity = length * width / 1000000
        amountInCurrencyArrival = (basePrice.price / officialExchangeRateToBasePrice * quantity).toFixed(2);
    }

    materialPriceUnit.supplierMaterial = supplierMaterial
    materialPriceUnit.amountInCurrencyArrival = amountInCurrencyArrival
    materialPriceUnit.officialExchangeRateToBaseCurrencyBaseMaterials = officialExchangeRateToBaseCurrencyBaseMaterials
    materialPriceUnit.exchangeRateToBaseCurrencyBaseMaterials = officialExchangeRateToBaseCurrencyBaseMaterials
    materialPriceUnit.officialExchangeRateToAlternativeCurrencyBaseMaterials = officialExchangeRateToAlternativeCurrencyBaseMaterials
    materialPriceUnit.exchangeRateToAlternativeCurrencyBaseMaterials = officialExchangeRateToAlternativeCurrencyBaseMaterials
    materialPriceUnit.officialExchangeRateToBasePrice = officialExchangeRateToBasePrice
    materialPriceUnit.exchangeRateToBasePrice = officialExchangeRateToBasePrice
    materialPriceUnit.length = length
    materialPriceUnit.width = width
    materialPriceUnit.quantity = quantity

    if (isAddMaterial) {
        materialPriceUnit.materialOrganizationOption = {
            id: material.id,
            name: material.name,
            unit: {
                name: material.unit.name
            }
        }
    }

    return materialPriceUnit;
}

const ModalAddMaterialPriceUnitModule = ({
                                             data,
                                             setData,
                                             open,
                                             setOpen,
                                             currentCurrency,
                                             baseCurrency,
                                             alternativeCurrency,
                                             isBaseCurrency,
                                             isAlternativeCurrency,
                                             supplierId,
                                             setUpdateModalOpen,
                                             setCurrentEditRowData,
                                             materialPriceUnits,
                                             arrivalMaterialId,
                                             currencyMap,
                                             tableOptionsHandle,
                                             handleError
                                         }) => {

        const [values, setValues] = useState(null);
        const [basePriceCurrency, setBasePriceCurrency] = useState(null);
        const [isSlabMaterial, setIsSlabMaterial] = useState(null);
        const [choiceSlabMaterialModalOpen, setChoiceSlabMaterialModalOpen] = useState(false);

        const [typeSlabMaterial, setTypeSlabMaterial] = useState(null);
        const [materialFixedStripeId, setMaterialFixedStripeId] = useState(null);
        const [materialFixedStripes, setMaterialFixedStripes] = useState(null);

        const [listChoiceSlabMaterial, setListChoiceSlabMaterial] = useState(null);
        const [choiceFixedStripeModalOpen, setChoiceFixedStripeModalOpen] = useState(false);
        const [currentBasePrice, setCurrentBasePrice] = useState(null);
        const [isFixedStripe, setIsFixedStripe] = useState(null);
        const [selectedString, setSelectedString] = useState({})
        const [activeStep, setActiveStep] = useState(0)

        const [validationError, setValidationError] = useState("")

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
            tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
            MaterialPriceUnitUtil.addOrUpdateMaterialPriceUnits(materialPriceUnits, values);
        };


        const uploadMaterialFixedStripes = async (typeSlabMaterial, idMaterial) => {
            if (typeSlabMaterial === "BY_FIXED_STRIPE")
                await UserSupplierMaterialService.getAllCurrentByMaterialOrganizationOptionIdAndSupplierIdFixedStripe(idMaterial, supplierId)
                    .then(function (response) {
                        setMaterialFixedStripes(response.data);
                    })
                    .catch(function (error) {
                        handleError(error);
                    });
        }

        const createMaterialPriceUnit = async (idMaterial, row, isByMinStripe) => {

            if (typeSlabMaterial === null && isSlabMaterial) {
                setTypeSlabMaterial("WHOLE");
            }

            let supplierMaterial = null;

            if (isFixedStripe) {
                if (materialFixedStripeId === null) {
                    supplierMaterial = materialFixedStripes[0];
                } else {
                    supplierMaterial = SupplierMaterialUtil.getSupplierMaterialFixedStripe(materialFixedStripeId, materialFixedStripes);
                }
            } else if (isByMinStripe) {
                await UserSupplierMaterialService.getCurrentByMaterialOrganizationOptionIdAndSupplierIdMinStripe(idMaterial, supplierId)
                    .then(function (response) {
                        supplierMaterial = response.data;
                    })
                    .catch(function (error) {
                        handleError(error);
                    });
            } else {
                await UserSupplierMaterialService.getCurrentByMaterialOrganizationOptionIdAndSupplierId(idMaterial, supplierId)
                    .then(function (response) {
                        supplierMaterial = response.data;
                    })
                    .catch(function (error) {
                        handleError(error);
                    });
            }

            let basePrice = supplierMaterial.basePrice;
            setCurrentBasePrice(basePrice)
            const materialPriceUnit = await setMaterialPriceUnit(basePrice, setBasePriceCurrency,
                currentCurrency, baseCurrency, alternativeCurrency, supplierMaterial, row,
                isSlabMaterial, MaterialPriceUnitUtil.materialPriceUnit, true, true, currencyMap, handleError);
            setValues(materialPriceUnit)
            setActiveStep(1)
        };

        const handleSubmitChoiceSlabMaterial = async () => {
            const currentTypeSlabMaterial = typeSlabMaterial === null ? "WHOLE" : typeSlabMaterial;
            const idMaterial = selectedString.id
            const isByFixedStripe = currentTypeSlabMaterial === "BY_FIXED_STRIPE";
            const isByMinStripe = currentTypeSlabMaterial === "BY_MIN_STRIPE";
            setIsFixedStripe(isByFixedStripe)

            if (!isByFixedStripe && !isByMinStripe) {
                const materialPriceUnit = MaterialPriceUnitUtil.checkAvailability(materialPriceUnits, idMaterial);
                if (materialPriceUnit === null) {
                    await createMaterialPriceUnit(idMaterial, selectedString, isByMinStripe);

                } else {
                    setUpdateModalOpen(true);
                    setCurrentEditRowData(materialPriceUnit);
                }

            } else {
                if (isByFixedStripe) {
                    await uploadMaterialFixedStripes(currentTypeSlabMaterial, idMaterial);
                    setChoiceFixedStripeModalOpen(true)
                } else {
                    await createMaterialPriceUnit(idMaterial, selectedString, isByMinStripe);
                    setChoiceSlabMaterialModalOpen(false);

                }
            }
            onCloseChoiceSlabMaterialModalOpen();
        }

        const handleSubmitChoiceFixedStripe = async () => {
            await createMaterialPriceUnit(selectedString.id, selectedString, false);
            setChoiceFixedStripeModalOpen(false)

        };

        const onCloseChoiceSlabMaterialModalOpen = () => {
            setChoiceSlabMaterialModalOpen(false);
        };

        const onCloseChoiceFixedStripeModalOpen = () => {
            setChoiceFixedStripeModalOpen(false);
        };

        const submit = async () => {
            if (activeStep === 0) {
                let idMaterial = selectedString.id;
                const isSlab = selectedString.typeMaterial === 'SLAB';
                setIsSlabMaterial(isSlab);
                setValues(null);
                if (isSlab) {
                    await MaterialOrganizationOptionService.getChoiceSlabMaterial(idMaterial, supplierId)
                        .then(function (response) {
                            const startChoiceSlabMaterial = [];
                            startChoiceSlabMaterial.push('WHOLE')
                            const filteredChoiceSlabMaterialServer = response.data.filter(function (value) {
                                return value !== null;
                            });
                            const combinedChoiceSlabMaterial = startChoiceSlabMaterial.concat(filteredChoiceSlabMaterialServer);
                            setListChoiceSlabMaterial(combinedChoiceSlabMaterial);
                            setTypeSlabMaterial(combinedChoiceSlabMaterial[0])
                        })
                        .catch(function (error) {
                            handleError(error);
                        });
                    setChoiceSlabMaterialModalOpen(true);
                } else {
                    if (materialPriceUnits.has(idMaterial)) {
                        setUpdateModalOpen(true);
                        setCurrentEditRowData(materialPriceUnits.get(idMaterial));
                    } else {
                        await createMaterialPriceUnit(idMaterial, selectedString, false);
                    }
                }

            } else {
                MaterialPriceUnitUtil.setExchangeRateBasePriceCurrency(baseCurrency, alternativeCurrency, basePriceCurrency, values)
                MaterialPriceUnitService.create(currentCurrency, supplierId, arrivalMaterialId, values,
                ).then(function (response) {
                    handleCreateNewRow(response.data);
                    onClose();
                    setValues(null);
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }
        }
        const onClose = () => {
            setOpen(false)
            setValues(null)
            setActiveStep(0)
            setSelectedString({})
            setTypeSlabMaterial(null)
        }
        const onBack = () => {
            setActiveStep(0)
        }

        return (
            <div>
                <ChoiceSlabMaterialModal
                    open={choiceSlabMaterialModalOpen}
                    onClose={onCloseChoiceSlabMaterialModalOpen}
                    value={typeSlabMaterial}
                    setValue={setTypeSlabMaterial}
                    handleSubmit={handleSubmitChoiceSlabMaterial}
                    keyFilter={listChoiceSlabMaterial}
                />
                <ChoiceFixedStripeModal
                    open={choiceFixedStripeModalOpen}
                    onClose={onCloseChoiceFixedStripeModalOpen}
                    value={materialFixedStripeId}
                    setValue={setMaterialFixedStripeId}
                    handleSubmit={handleSubmitChoiceFixedStripe}
                    entityList={materialFixedStripes}
                />

                <Dialog open={open} PaperProps={{
                    style: {
                        minWidth: `${activeStep === 0 ? "1080px" : "300px"}`,
                    }
                }}>
                    <Box style={{
                        padding: 40,
                        display: "flex",
                        flexDirection: "column",
                        gap: 16,
                        alignItems: "center"
                    }}>
                        <DialogTitle sx={{
                            color: (theme) => theme.palette.text.main,
                            padding: 0,
                            fontSize: 24,
                            fontWeight: 700
                        }}>Добавить поступление материала</DialogTitle>
                        <Stepper
                            style={{width: 446}}
                            activeStep={activeStep}
                        >
                            {["Материал", "Параметры"].map((label) => (
                                <Step key={label}>
                                    <StepLabel sx={{
                                        ".MuiSvgIcon-root": {
                                            fontSize: 40,
                                            color: "#737478",
                                            "&.Mui-active": {
                                                color: "#3B8BEC",
                                            }
                                        },
                                        "& .MuiStepLabel-label": {
                                            fontSize: 18,
                                            "&.Mui-active": {
                                                fontWeight: 700,
                                            }
                                        }
                                    }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <DialogContent>
                        {activeStep === 0 ? <MaterialPageItemModule
                            refMaterial={false}
                            pageSize={20}
                            enableEditing={true}
                            isAddMaterial={false}
                            nameMethodGetMaterial={'with-group-material-unit-supplierMaterials'}
                            supplierId={supplierId}
                            isSelectInArrival={true}
                            isSelectInEstimate={false}
                            modal={true}
                            selectMaterialId={selectedString?.id}
                            selectMaterial={setSelectedString}
                            handleError={handleError}
                        /> : <AddMaterialPriceParameters
                            calcQuantity={selectedString.typeMaterial === 'SLAB' && typeSlabMaterial === "WHOLE"}
                            isNew={true}
                            values={values}
                            setValues={setValues}
                            baseCurrency={baseCurrency}
                            alternativeCurrency={alternativeCurrency}
                            currentCurrency={currentCurrency}
                            basePriceCurrency={basePriceCurrency}
                            typeSlabMaterial={typeSlabMaterial}
                            isSlabMaterial={isSlabMaterial}
                            isBaseCurrency={isBaseCurrency}
                            isAlternativeCurrency={isAlternativeCurrency}
                            validationError={validationError}
                            setValidationError={setValidationError}/>}
                    </DialogContent>
                    <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                        <Box>{activeStep !== 0 && <ButtonBack text={"Назад"} onClick={onBack}/>} </Box>
                        <Box>
                            <ButtonExit
                                text={"Выйти"} onClick={onClose}/>
                            <ButtonAdd
                                text={activeStep === 0 ? "Выбрать" : "Добавить"}
                                onClick={() => validationError === "" && submit()}
                                disabled={!selectedString?.id}
                            />
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
;

export default ModalAddMaterialPriceUnitModule;