import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import ModelMaterialEstimateUnitService
    from "../../../../../API/model-estimate/estimate/ModelMaterialEstimateUnitService";
import {typeMaterial} from "../../../../../data/TypeMaterial";
import ModalUpdateOrAddModalEstimateUnit from "./ModalUpdateOrAddModalEstimateUnit";
import MenuItem from "@mui/material/MenuItem";
import ButtonCreate from "../../../button/ButtonCreate";

const BasicModelEstimateTableItem = ({
                                         data, setData,
                                         estimateId,
                                         unitMap,
                                         listUnits,
                                         tableOptions,
                                         tableOptionsHandle,
                                         handleError,
                                         modal,
                                         selectedStringId,
                                         setSelectedString,
                                         calcData
                                     }) => {

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [unitId, setUnitId] = useState(null);

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.id;
                ModelMaterialEstimateUnitService.delete(id)
                    .then(function () {
                        const newData = data.filter(el => el.id !== id)
                        calcData(newData)
                    })
                    .catch(function (error) {
                        handleError(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setCreateOrUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const isNew = currentEditRowData === null ? true : currentEditRowData.id === null

        const handleCloseCreateOrUpdateModalOpen = () => {
            setCreateOrUpdateModalOpen(false)
            setCurrentEditRowData(null);
        };

        const handleSubmit = () => {
            setUnit();
            isNew ? ModelMaterialEstimateUnitService.create(currentEditRowData, estimateId,
                ).then(function (response) {
                    handleCloseCreateOrUpdateModalOpen();
                    const newData = [...data, response.data]
                    calcData(newData)
                })
                    .catch(function (error) {
                        handleError(error);
                    }) :

                ModelMaterialEstimateUnitService.update(currentEditRowData, estimateId,
                ).then(function (response) {
                    handleCloseCreateOrUpdateModalOpen();
                    const newData = data.map(el => el.id === response.data.id ? response.data : el)
                    calcData(newData)
                })
                    .catch(function (error) {
                        handleError(error);
                    });
        }

        const setUnit = () => {
            currentEditRowData.unitId = unitId ? unitId : currentEditRowData.unitId ? currentEditRowData.unitId.toString() : "1";
            currentEditRowData.unitName = unitMap.get(currentEditRowData.unitId);
        }

        const handleCreateRow = () => {
            const modelMaterialEstimateUnit = {
                typeMaterial: 'SLAB',
                quantity: 0,
                tempName: null,
                note: null,
                id: null,
                unitId: 1,
            };
            setCurrentEditRowData(modelMaterialEstimateUnit);
            setCreateOrUpdateModalOpen(true);
        };

        const columns = useMemo(
            () => [
                {
                    id: 'tempName',
                    header: 'Найменование',
                    accessorKey: 'tempName',
                    minSize: 650,
                    enableEditing: false,
                },
                {
                    id: 'quantity',
                    header: 'Кол.',
                    accessorKey: 'quantity',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    id: 'unitName',
                    header: 'Ед. изм.',
                    accessorKey: 'unitName',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    id: 'typeMaterial',
                    header: 'Тип материала',
                    accessorKey: 'typeMaterial',
                    minSize: 5,
                    maxSize: 5,
                    Cell: ({cell}) => typeMaterial[cell.getValue()],
                },
                {
                    id: 'note',
                    header: 'Примечание',
                    accessorKey: 'note',
                    minSize: 300,
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                },
            ],
            [data, setData],
        );
        const menuItem = [
            {type: "edit", text: "Изменить", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow},
        ]
        return (
            <Box sx={{maxWidth: "1500px", overflow: 'auto',}}>
                {!modal && <Box
                    sx={{display: 'flex', justifyContent: "flex-end", gap: "10px", paddingBottom: "30px"}}
                >
                    <ButtonCreate
                        text={"Создать базовый материал"}
                        width={270}
                        onClick={handleCreateRow}
                    />
                </Box>}
                <MaterialReactTable
                    muiTableContainerProps={{
                        sx: {
                            width: "1500px",
                            maxHeight: `calc(100vh - 500px)`
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": !modal && {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& li": {
                                listStyleType: "none",
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }
                                }
                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem"
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            onClick: () => setSelectedString && setSelectedString(row.original),
                            sx: () => ({
                                height: '36px',
                                cursor: setSelectedString && "pointer",
                                "&:hover& td:after": {
                                    display: "none",
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: row.original.id === selectedStringId ? "#D7E8FA" : '#F5F6FA',
                                },
                                backgroundColor: row.original.id === selectedStringId ? "#D7E8FA" : "white",
                            })
                        })
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        backgroundColor: "red",
                        sx: {
                            backgroundColor: "red",
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        sorting: [{id: 'unitName', desc: false}, {id: 'tempName', desc: false}],
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                    }}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    rowCount={tableOptions.totalElements}
                    state={{showGlobalFilter: true, pagination: tableOptions,}}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    enableRowActions={!modal}
                    enableToolbarInternalActions={false}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })}
                    localization={MRT_Localization_RU}
                />
                <ModalUpdateOrAddModalEstimateUnit
                    open={createOrUpdateModalOpen}
                    handleClose={() => handleCloseCreateOrUpdateModalOpen()}
                    title={isNew ? "Создать материал" : "Обновить материал"}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    handleSubmit={handleSubmit}
                    isNew={isNew}
                    listUnits={listUnits}
                    setUnitId={setUnitId}
                />
            </Box>
        );
    }
;

export default BasicModelEstimateTableItem;

