import React, {useEffect, useState} from 'react';
import BasicModelEstimateTable
    from "../../../../components/UI/table/model-estimate/estimate/BasicModelEstimateTable";
import {useParams} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../Constants";
import {Box} from "@mui/system";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import {Button} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
    UploadModelEstimateFileForm
} from "../../../../components/UI/table/model-estimate/estimate/UploadModelEstimateFileForm";
import ModelEstimateService from "../../../../API/model-estimate/estimate/ModelEstimateService";

const BasicModelsEstimate = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [alertData, setAlertData] = useState({});
    const [tab, setTab] = useState(1);
    const [change, setChange] = useState(false);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    useEffect(() => {
        getStartPageSize()
        ModelEstimateService.getAllByModelId(params.id).then(function (response) {
            const data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData("organization/basic-models", "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData["organization/basic-models"]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const generalTabComponents = [
        {
            tabIndex: 1, label: "Смета",
            component:
                <BasicModelEstimateTable
                    modelId={params.id}
                    data={data}
                    setData={setData}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
        },
        {
            tabIndex: 2, label: "Импорт",
            component:
                <Box sx={{paddingTop: "32px", display: "flex", gap: "24px"}}>
                    <UploadModelEstimateFileForm
                        modelId={params.id}
                        label={'Загрузить смету модели'}
                        change={change}
                        setChange={setChange}
                        handleError={handleError}
                    />
                </Box>
        }]
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Смета`}
                path={[{name: "Главная", href: "/"}, {name: "Настройки", href: "/settings"}]}/>
            <Box>
                <ButtonGroupTabBar activeTab={tab}>
                    {generalTabComponents.map(({tabIndex, label}, index) => (
                        <Button key={tabIndex} tabIndex={tabIndex} onClick={() => setTab(tabIndex)}>
                            <CheckIcon/>
                            {label}
                        </Button>
                    ))}
                </ButtonGroupTabBar>
                {generalTabComponents.find(component => component.tabIndex === tab)?.component}
            </Box>
            <ModalAlert data={alertData} onClose={handleCloseAlert}/>
        </Box>
    );

};

export default BasicModelsEstimate;