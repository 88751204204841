import axios from "axios";
import {gatewayUrl, nameAuthorizationServer} from "../../Constants";

export default class CustomerService {
    static async getAll() {
        return axios.get(gatewayUrl + nameAuthorizationServer + "/api/v1/customers/customer");
    }

    static async edit(address, residentialAddress, data) {
        return axios.patch(gatewayUrl + nameAuthorizationServer + "/api/v1/customers/customer?residentialAddressId=" + residentialAddress + "&addressId=" + address, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}