import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step,
    StepLabel,
    Stepper,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";
import MaterialWarehouseService from "../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import Box from "@mui/material/Box";

import {materialReserveChoice} from "../../../../../data/MaterialReserveState";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import SupplierTable from "../supplier/SupplierTable";
import UserSupplierMaterialService from "../../../../../API/orders/warehouse/material/UserSupplierMaterialService";
import MaterialWarehouseTableRMEU from "../material/materialwarehouse/MaterialWarehouseTableRMEU";
import TextFieldObjectWithSupplierMaterialListMaterialReserve
    from "./TextFieldObjectWithSupplierMaterialListMaterialReserve";
import ButtonBack from "../../../button/ButtonBack";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const steps1 = [
    'Формат резерва',
    'Выбор поставщика',
    'Тип поставки',
];

const steps2 = [
    'Формат резерва',
    'Выбор резерва',
];

function getArea(length, width) {
    return length * width / 1000000;
}

function getQuantity(supplierMaterial, isSlab) {
    if (isSlab && supplierMaterial !== null) {
        let length;
        let width;
        const typeSupplierMaterial = supplierMaterial.typeSupplierMaterial;
        if (typeSupplierMaterial === "BY_FIXED_STRIPE" || typeSupplierMaterial === "WHOLE") {
            length = supplierMaterial.length;
            width = supplierMaterial.width;
        } else {
            length = supplierMaterial.length;
            width = 2070 - supplierMaterial.width;
        }
        return getArea(length, width);
    } else {
        return 0;
    }
}

function getAndSetSupplierMaterials(data, supplier, setSupplierMaterials, setQuantity,
                                    isSlab,
                                    setTypeSupplierMaterial,
                                    setSupplierMaterial) {
    const supplierMaterials = data.get(supplier.id);
    setSupplierMaterials(supplierMaterials);
    const supplierMaterial = supplierMaterials[0];
    let quantity = getQuantity(supplierMaterial, isSlab);
    setQuantity(quantity)
    setTypeSupplierMaterial(supplierMaterial.typeSupplierMaterial)
    setSupplierMaterial(supplierMaterial)
}

export const CreateSlabMaterialModal = ({
                                            open,
                                            onClose,
                                            handleCreateNewRow,
                                            id,
                                            baseCurrency,
                                            alternativeCurrency,
                                            isSlab
                                        }) => {

    const currentSlab = isSlab === "true"
    const [dataSupplierMaterials, setDataSupplierMaterials] = useState([]);
    const [dataMaterialWarehouse, setDataMaterialWarehouse] = useState([]);

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [activeStep, setActiveStep] = useState(0)
    const [dataSuppliers, setDataSuppliers] = useState([])
    const [supplier, setSupplier] = useState(null)
    const [supplierMaterials, setSupplierMaterials] = useState(null)
    const [formatMaterialReserve, setFormatMaterialReserve] = useState(Object.entries(materialReserveChoice)[0][0])
    const materialReservesChoice = Object.entries(materialReserveChoice)
    const [steps, setSteps] = useState(steps1);
    const [quantity, setQuantity] = useState(0);
    const [typeSupplierMaterial, setTypeSupplierMaterial] = useState("WHOLE");
    const [width, setWidth] = useState(null);
    const [supplierMaterial, setSupplierMaterial] = useState(null);

    const selectMaterial = (material) => {
        setSelectedMaterial(material)
    }

    useEffect(() => {
        if (formatMaterialReserve === materialReservesChoice[0][0]) {
            setSteps(steps1);
        } else {
            setSteps(steps2);
        }
    }, [formatMaterialReserve]);

    useEffect(() => {
            MaterialWarehouseService.getAllByMaterialEstimateUnitId(id)
                .then(function (response) {
                    const data = response.data;
                    setDataMaterialWarehouse(data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, []
    );

    useEffect(() => {
        UserSupplierMaterialService.getAllByMaterialEstimateUnit(id)
            .then(response => {
                const rawData = response.data;
                const supplierMap = new Map();
                rawData.forEach(item => {
                    const supplierKey = item.supplier.id;
                    if (!supplierMap.has(supplierKey)) {
                        supplierMap.set(supplierKey, []);
                    }
                    supplierMap.get(supplierKey).push(item);
                });
                setDataSupplierMaterials(supplierMap);
                const suppliersArray = Array.from(supplierMap.values()).map(items => items[0].supplier);
                setDataSuppliers(suppliersArray);
                setSupplier(suppliersArray[0])
                getAndSetSupplierMaterials(supplierMap, suppliersArray[0], setSupplierMaterials, setQuantity, isSlab,
                    setTypeSupplierMaterial, setSupplierMaterial);
            })
            .catch(error => {
                console.log(error);
            });
    }, [id, baseCurrency, alternativeCurrency]);

    const handleSubmit = () => {

        const materialWarehouseId = (selectedMaterial === null || selectedMaterial === undefined) ? null : selectedMaterial.id;
        const supplierMaterialId = (supplierMaterial === null || supplierMaterial === undefined) ? null : supplierMaterial.id;
        const actualWidth = (width === null || width === undefined) ? null : width.height;

        MaterialReserveService.save(id, materialWarehouseId, supplierMaterialId, quantity, true, true,
            true, true, actualWidth).then(function (response) {
            handleCreateNewRow(response.data);
            reset()

        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const back = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        } else {
            onClose();
        }
    }

    const reset = () => {
        setActiveStep(0)
        onClose()
        selectMaterial(null)
    }

    const selectSupplier = (supplier) => {
        setSupplier(supplier)
        getAndSetSupplierMaterials(dataSupplierMaterials, supplier,
            setSupplierMaterials, setQuantity, isSlab,
            setTypeSupplierMaterial,
            setSupplierMaterial);
    }

    const submit = () => {
        if (activeStep < 2 && formatMaterialReserve === materialReservesChoice[0][0]) {
            setActiveStep(activeStep + 1);
        } else if (activeStep < 1 && formatMaterialReserve !== materialReservesChoice[0][0]) {
            setActiveStep(activeStep + 1);
        } else {
            handleSubmit();
        }
    }

    const handleChange = (event) => {
        setFormatMaterialReserve(event.target.value);
    };

    const handleChangeSupplierMaterial = (id) => {
        const foundMaterial = supplierMaterials.find(material => material.id.toString() === id);
        let quantity = getQuantity(foundMaterial, isSlab);
        setQuantity(quantity)
        setTypeSupplierMaterial(foundMaterial.typeSupplierMaterial)
        setSupplierMaterial(foundMaterial)
    }

    const setField = (value) => {
        setQuantity(value)
    }

    const handleChangeWidth = (value) => {
        setWidth(value)
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${activeStep === 0 ? "40vw" : "700px"}`,
                height: `${activeStep === 1 ? "550px" : "450px"}`,
            }
        }}>
            <DialogTitle textAlign="center">Добавить резерв</DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box style={{
                    height: 24,
                    marginTop: 30,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    border: supplier ? activeStep === 1 ? formatMaterialReserve === materialReservesChoice[0][0]
                        ? "blue 1px solid" : (selectedMaterial !== null && selectedMaterial !== undefined ? "blue 1px solid"
                            : "transparent 1px solid") : "transparent 1px solid" : "transparent 1px solid"
                }}>
                    <Box
                        style={{marginRight: 40}}>
                        {formatMaterialReserve === materialReservesChoice[0][0] &&
                        activeStep === 1 && supplier !== null
                        && supplier !== undefined ? supplier.name : (selectedMaterial !== null && selectedMaterial !== undefined ?
                                isSlab ? "[" + selectedMaterial.materialPriceUnit.length + "x" +
                                    +selectedMaterial.materialPriceUnit.width + "] количество " + selectedMaterial.materialPriceUnit.quantity
                                    : " количество " + selectedMaterial.materialPriceUnit.quantity
                                : ""
                        )}</Box>
                </Box>
                {activeStep === 0 ?
                    <Box style={{display: "flex", flexDirection: "column"}}>

                        <RadioGroup
                            defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            onChange={handleChange}
                        >
                            {materialReservesChoice.map((option) => (
                                    <FormControlLabel
                                        value={option[0]}
                                        control={<Radio/>}
                                        label={option[1]}
                                        sx={{
                                            width: "752px",
                                            height: "36px",
                                            "&:not(:last-of-type)": {
                                                marginBottom: "4px",
                                            },
                                            borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                            "&:hover": {
                                                backgroundColor: (theme) => theme.palette.primary.light
                                            }
                                        }
                                        }
                                    />
                                )
                            )}
                        </RadioGroup>

                    </Box>
                    : activeStep === 1 ?
                        <Box>
                            {formatMaterialReserve === materialReservesChoice[0][0] ? <SupplierTable
                                data={dataSuppliers}
                                selectSupplier={selectSupplier}
                            /> : <MaterialWarehouseTableRMEU
                                data={dataMaterialWarehouse}
                                selectMaterial={selectMaterial}
                            />}
                        </Box> :

                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '0.01rem',
                            }}
                        >
                            {currentSlab && <TextFieldObjectWithSupplierMaterialListMaterialReserve
                                label="Тип поставки"
                                field={'supplierMaterialId'}
                                setId={handleChangeSupplierMaterial}
                                entityList={supplierMaterials}
                                name={'typeSupplierMaterial'}
                                typeSupplierMaterial={typeSupplierMaterial}
                            />}
                            {typeSupplierMaterial === "BY_MIN_STRIPE" && <TextField
                                size={"small"}
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '1.5rem',
                                }}
                                margin="normal"
                                key={'height'}
                                label={"Высота"}
                                name={'height'}
                                disabled={currentSlab && typeSupplierMaterial === "BY_FIXED_STRIPE" || currentSlab && typeSupplierMaterial === "WHOLE"}
                                defaultValue={width === null ? "" : width}
                                onChange={(e) => {
                                    let area = getArea(2800, e.target.value);
                                    setQuantity(area);
                                    handleChangeWidth({...width, [e.target.name]: e.target.value})
                                }}
                            />}
                            <TextField
                                size={"small"}
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '1.5rem',
                                }}
                                margin="normal"
                                key={'quantity'}
                                label={"Количество"}
                                name={'quantity'}
                                disabled={currentSlab}
                                value={quantity === null ? 0 : quantity}
                                onChange={(e) => {
                                    setField({...quantity, [e.target.name]: e.target.value})
                                }}
                            />
                        </Stack>

                }
            </DialogContent>

            <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep !== 0 && <ButtonBack text={"Назад"} onClick={back}/>} </Box>
                <Box>
                    <ButtonExit text={"Выйти"} onClick={reset}/>
                    <ButtonAdd text={"Выбрать"} onClick={submit}
                               disabled={activeStep === 2 && supplierMaterial == null}/>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
export default CreateSlabMaterialModal