import {Box, Container} from "@mui/system";
import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {newsData} from "../../data/NewsData";
import {stockData} from "../../data/StockData";
import {constantsErrorMessages} from "../../Constants";
import ModalAlert from "../../components/UI/table/customer-detales/ModalAlert";
import {BreadcrumbsComponent} from "../../components/UI/breadcrumb/BreadcrumbComponent";
import {Paper, Typography} from "@mui/material";
import Footer from "./Footer";
import Card from "../../components/UI/Card";
import {newsType} from "../../data/NewsType";
import {stockType} from "../../data/StockType";
import {Link, useLocation, useNavigate} from "react-router-dom";

const NewsAndStock = ({news}) => {
    const location = useLocation()
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)
    const [alertData, setAlertData] = useState({});

    const [data, setData] = useState([])
    const [types, setTypes] = useState([])

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    useEffect(() => {
        try {
            const pageData = news ? newsData : stockData
            const pageTypes = pageData.reduce((acc, item) => acc.includes(item.type) ? acc : [...acc, item.type], []);
            setData(pageData)
            setTypes(pageTypes)
            setIsLoading(false)
        } catch (error) {
            handleError(error)
        }
    }, [news])

    const onClickHandle = (el) => {
        navigate(`${location.pathname}/${el.id}`);
    }

    return <>
        <ModalAlert
            data={alertData}
            onClose={() => setAlertData((alert) => ({...alert, open: false}))}
        />
        {isLoading ?
            <Box sx={{
                height: "calc(100vh - 150px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> :
            <>
                <Paper sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: "space-between",
                    padding: "0 0 40px 0",
                }}>
                    <Container sx={{
                        "&.MuiContainer-root": {
                            maxWidth: "1668px",
                            display: 'flex',
                            flexDirection: "column",
                            gridGap: "100px",
                        },
                    }}>
                        <Box>
                            <BreadcrumbsComponent
                                page={news ? "Новости" : "Акции"}
                                path={[
                                    {name: "Главная", href: "/"},
                                ]}/>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "column",
                                gridGap: "40px",
                                justifyContent: "center",
                                width: "100%",
                            }}>
                                <Box sx={{display: "flex", alignItems: "center", gap: "16px"}}>
                                    {types.map((el, i) => {
                                        return <Box key={i} sx={{
                                            borderRadius: "28px",
                                            backgroundColor: (theme) => theme.palette.background.grey
                                        }}>
                                            <Typography sx={{
                                                fontSize: "14px",
                                                color: (theme) => theme.palette.text.main,
                                                lineHeight: 1,
                                                padding: "4px 8px",
                                            }}>{news ? newsType[el] : stockType[el]}</Typography>
                                        </Box>
                                    })}
                                </Box> <Typography sx={{
                                fontSize: "40px", fontWeight: 700, lineHeight: 1,
                                '@media (max-width: 1450px)': {
                                    fontSize: "28px",
                                }
                            }}>
                                Актуальные новости
                            </Typography>
                                <Box sx={{
                                    display: "grid",
                                    gap: "16px",
                                    gridTemplateAreas: `"news1 news1 news2 news3" "news1 news1 news4 news5"`,
                                }}>
                                    {data.map((el, i) => {
                                        return <Box key={el.id} sx={{
                                            gridArea: `news${i + 1}`
                                        }}
                                        >
                                            <Card
                                                key={el.id}
                                                element={el}
                                                clickable={true}
                                                picturePosition={"top"}
                                                width={i === 0 ? 802 : 392}
                                                height={i === 0 ? 680 : 332}
                                                imgHeight={i === 0 && "474px"}
                                                onClickHandle={() => onClickHandle(el)}
                                            />
                                        </Box>
                                    })}
                                </Box>
                                <Typography sx={{
                                    fontSize: "40px", fontWeight: 700, lineHeight: 1,
                                    '@media (max-width: 1450px)': {
                                        fontSize: "28px",
                                    }
                                }}>
                                    Вам также может быть интересно
                                </Typography>
                                <Box sx={{
                                    display: "grid",
                                    gap: "16px",
                                    gridTemplateAreas: `                               
                                    "news1 news3"
                                    "news1 news4"
                                    "news2 news4"
                                    "news2 news5"`,
                                }}>
                                    {data.map((el, i) => {
                                        return <Box key={el.id} sx={{
                                            gridArea: `news${i + 1}`
                                        }}
                                        >
                                            <Card
                                                element={el}
                                                clickable={true}
                                                picturePosition={"left"}
                                                width={802}
                                                height={i === 0 || i === 1 ? 332 : 216}
                                                imgWidth={i === 0 || i === 1 ? "365px" : "173px"}
                                                onClickHandle={() => onClickHandle(el)}
                                            />
                                        </Box>
                                    })}
                                </Box>


                                <Typography sx={{
                                    fontSize: "40px", fontWeight: 700, lineHeight: 1,
                                    '@media (max-width: 1450px)': {
                                        fontSize: "28px",
                                    }
                                }}>
                                    Популярные новости
                                </Typography>
                                <Box sx={{
                                    display: "flex", gap: "16px",
                                }}>
                                    {data.map((el) => {
                                        return <Card
                                            key={el.id}
                                            element={el}
                                            clickable={true}
                                            picturePosition={"top"}
                                            width={311}
                                            height={380}
                                            imgHeight={"174px"}
                                            onClickHandle={() => onClickHandle(el)}
                                        />
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Paper>
                <Footer></Footer>
            </>
        }
    </>
}
export default NewsAndStock