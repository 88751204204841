import React, {useCallback, useEffect, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import MaterialPriceUnitService from "../../../../../../API/orders/warehouse/MaterialPriceUnitService";
import {useNavigate} from "react-router-dom";
import ModalOpenSetValues from "./ModalOpenSetValues";
import ModalAddMaterialPriceUnitModule from "./ModalAddMaterialPriceUnitModule";
import MaterialPriceUnitUti from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import MaterialPriceUnitUtil from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import ModalConfirm from "../../../../modal/ModalConfirm";
import ArrivalMaterialService from "../../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import {ArrivalMaterialPriceUnitColumns} from "../../../../../../colums/ArrivalMaterialPriceUnitColumns";
import CurrencyService from "../../../../../../API/common/CurrencyService";
import ModalUpdateMaterialPriceUnit from "./ModalUpdateMaterialPriceUnit";
import {EditLocalStoragePagesData} from "../../../../../../utils/EditLocalStoragePagesData";
import ButtonSelectAction from "../../../../button/ButtonSelectAction";
import {ModalSelectAction} from "../../../../modal/ModalSelectAction";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import selectionTableUtil from "../../../../../../utils/common/SelectionTableUtil";


const ArrivalMaterialPriceUnitTable = ({
                                           arrivalMaterialId,
                                           currentCurrency,
                                           isBaseCurrency,
                                           isAlternativeCurrency,
                                           baseCurrency,
                                           alternativeCurrency,
                                           supplierId,
                                           warehouseId,
                                           handleError
                                       }) => {
        const [data, setData] = useState([]);

        const [modalCurrencyOpen, setModalCurrencyOpen] = useState(false);
        const [currentCurrencyBM, setCurrentCurrencyBM] = useState([]);
        const [typeCurrentCurrency, setTypeCurrentCurrency] = useState([]);

        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [addModalOpen, setAddModalOpen] = useState(false);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
        const [currentBasePriceCurrency, setCurrentBasePriceCurrency] = useState(null);
        const [rowSelection, setRowSelection] = useState([]);
        const [disabledButtonBasePriceCurrency, setDisabledButtonBasePriceCurrency] = useState(true);
        const [showConfirmationModalArrivalMaterial, setConfirmationModalArrivalMaterial] = useState(false);
        const [slabMaterial, setSlabMaterial] = useState(true);
        const [currencyMap, setCurrencyMap] = useState(new Map);
        const [open, setOpen] = useState(false)

        const [tableOptions, setTableOptions] = useState({
            pageIndex: 0,
            pageSize: 20,
            totalElements: 0,
            searchData: ""
        })
        const tableOptionsHandle = (nextState) => {
            setTableOptions(nextState)
            if (nextState.pageSize !== tableOptions.pageSize) {
                EditLocalStoragePagesData(`organization/orders`, "pageSize", nextState.pageSize)
            }
        }

        const getStartPageSize = () => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const pageSize = pagesData[`organization/orders`]?.pageSize
            pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
        }

        const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)
        CurrencyService.getAll().then(function (response) {
            const data = response.data;
            data.forEach((c) => {
                currencyMap.set(c.id, c);
            });
        });

        useEffect(() => {
            getStartPageSize()
            MaterialPriceUnitService.getAllByArrivalMaterialId(parseInt(arrivalMaterialId)).then(function (response) {
                let data = response.data;
                setTableOptions((prev) => ({...prev, totalElements: data.length}))
                setSlabMaterial(MaterialPriceUnitUtil.checkSlab(data));
                setData(data);
                MaterialPriceUnitUti.addIdMaterialInMap(data, materialPriceUnits)
            })
                .catch(function (error) {
                    handleError(error);
                });
        }, []);

        useEffect(() => {
            data.forEach((row) => {
                if (rowSelection[row.id]) {
                    setCurrentBasePriceCurrency(row.supplierMaterial.basePrice.currency.curAbbreviation)
                }
            });
        }, [rowSelection]);

        const isNotSelected = () => {
            return Object.keys(rowSelection).length === 0;
        };

        const unlockBaseCoursePrices = () => {
            const checkCurrency = currentBasePriceCurrency === currentCurrency || currentBasePriceCurrency === baseCurrency
                || currentBasePriceCurrency === alternativeCurrency
            const length = Object.keys(rowSelection).length !== 1
            return !disabledButtonBasePriceCurrency ? false : checkCurrency ? true : length;
        };

        const disabledOrUnableButtonBasePriceCurrency = () => {
            setDisabledButtonBasePriceCurrency(!disabledButtonBasePriceCurrency);
            // setOpen(false)
        };

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.id;
                const materialId = row.id
                MaterialPriceUnitService.delete(id)
                    .then(function () {
                        setData(prev => prev.filter(el => el.id !== row.id))
                        tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                        materialPriceUnits.delete(materialId);
                    })
                    .catch(function (error) {
                        handleError(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setCurrentEditRowId(row.id);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );
        const updateCurrencyByIds = async () => {
            MaterialPriceUnitService.updateCurrencyByIds(rowSelection, currentCurrencyBM, typeCurrentCurrency, supplierId)
                .then(function () {
                    setData(prev => prev.map(el => rowSelection?.includes(el.id)
                        ? {...el, [setExchangeRateMethod(typeCurrentCurrency)]: +currentCurrencyBM}
                        : el))
                    setOpen(false)
                })
                .catch(function (error) {
                    handleError(error);
                })
            handleCloseModalCurrency();
        }

        const setTitleModalOpenMethod = (typeCurrentCurrency) => {
            return typeCurrentCurrency === "base" ?
                'Установть курс к базовой валюте склада' : "basePrice" ?
                    'Установть курс к базовой цене' : 'Установть курс к альтернативной валюте склада';
        };

        const setLabelModalOpenMethod = (typeCurrentCurrency) => {
            return typeCurrentCurrency === "base" ?
                'Базовый курс' : "basePrice" ?
                    'Курс к базовой цене' : 'Альтернативный курс';
        };

        const setExchangeRateMethod = (typeCurrentCurrency) => {
            return typeCurrentCurrency === "base" ?
                'exchangeRateToBaseCurrencyBaseMaterials' : "basePrice" ?
                    'exchangeRateToBasePrice' : 'exchangeRateToAlternativeCurrencyBaseMaterials';
        };

        const handleCloseModalCurrency = () => {
            setModalCurrencyOpen(false);
        };

        const getIsBaseCurrency = () => {
            return isBaseCurrency === 'true';
        };

        const getIsAlternativeCurrency = () => {
            return isAlternativeCurrency === 'true';
        };

        const navigate = useNavigate();


        const handleSubmitArrivalMaterial = () => {
            ArrivalMaterialService.performMaterialReceipt(arrivalMaterialId, warehouseId)
                .then(function () {
                    navigate('/organization/materials-arrival');
                })
                .catch(function (error) {
                    handleError(error);
                })
        };

        const cancelArrivalMaterial = () => {
            setConfirmationModalArrivalMaterial(false);
        };

        const columns = ArrivalMaterialPriceUnitColumns({
            data,
            setData,
            currentCurrency,
            alternativeCurrency,
            baseCurrency,
            slabMaterial,
            currencyMap
        });
        const handleSubmitUpdate = () => {
            MaterialPriceUnitUtil.setExchangeRateBasePriceCurrency(baseCurrency, alternativeCurrency, currentEditRowData.supplierMaterial.basePrice.currency?.curAbbreviation, currentEditRowData)
            MaterialPriceUnitService.update(currentCurrency, supplierId, currentEditRowData,
            ).then(function (response) {
                handleUpdate(response.data);
                setUpdateModalOpen(false)
            })
                .catch(function (error) {
                    handleError(error);
                });
        };

        const handleUpdate = (values) => {
            setData(prev => prev.map(el => el.id === values.id ? values : el))
            MaterialPriceUnitUtil.addOrUpdateMaterialPriceUnits(materialPriceUnits, values);
        };

        const modalActions = [
            {
                name: disabledButtonBasePriceCurrency ? `Разблокировать курс баз. цены ${unlockBaseCoursePrices() ? " (Выберите только одну строку)" : ""}` : `Заблокировать курс баз. цены ${unlockBaseCoursePrices() ? " (Выберите только одну строку)" : ""}`,
                type: "disabled",
                disabled: unlockBaseCoursePrices()
            },
            {name: "Установить баз. курс", type: "base"},
            {name: "Установить ал. курс", type: "alternative"},
            {
                name: "Установить курс баз. цены",
                type: "basePrice",
                disabled: disabledButtonBasePriceCurrency
            },
        ]
        const handleSelectAction = (type) => {
            const setCurrency = (typeCurrentCurrency) => {
                setModalCurrencyOpen(true)
                setTypeCurrentCurrency(typeCurrentCurrency)
            };
            if (type === "disabled") {
                disabledOrUnableButtonBasePriceCurrency()
            } else {
                setCurrency(type)
            }
        }
        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow}
        ]
        return (
            <Box>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
                >
                    <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>
                        <ButtonCreate
                            text={"Добавить материал"}
                            width={210}
                            onClick={() => setAddModalOpen(true)}
                        />
                        <ButtonCreate
                            text={"Ввести приход"}
                            width={140}
                            icon={false}
                            onClick={() => setConfirmationModalArrivalMaterial(true)}
                        />
                    </Box>
                </Box>
                <MaterialReactTable
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem",
                            "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                "minWidth": "100%"
                            }
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            sx: () => ({
                                height: '36px',
                                backgroundColor: "white",
                                "&:hover& td:after": {
                                    display: "none",
                                },
                                "&:hover": {
                                    "& td:after": {
                                        display: "none",
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                },
                            })
                        })
                    }}

                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        sx: {
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                        'mrt-row-select': {
                            size: "50px",
                            grow: false,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    onRowSelectionChange={onRowSelectionChange}
                    getRowId={(originalRow) => originalRow.id}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowActions={true}
                    enableToolbarInternalActions={false}
                    initialState={{
                        columnVisibility: {
                            id: false,
                            exchangeRateToBaseCurrencyBaseMaterials: getIsBaseCurrency(),
                            exchangeRateToAlternativeCurrencyBaseMaterials: getIsAlternativeCurrency(),
                            priceInCurrencyBase: getIsBaseCurrency(),
                            priceInCurrencyAlternative: getIsAlternativeCurrency(),
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    enableRowSelection={true}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })}
                    localization={MRT_Localization_RU}
                />
                {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>}
                <ModalSelectAction
                    open={open}
                    width={"650px"}
                    reset={() => setOpen(false)}
                    submit={handleSelectAction}
                    modalActions={modalActions}
                />
                <ModalUpdateMaterialPriceUnit
                    open={updateModalOpen}
                    onClose={() => setUpdateModalOpen(false)}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    currentCurrency={currentCurrency}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    /* basePriceCurrency={currentBasePrice === null ? null : currentBasePrice.currency?.curAbbreviation}*/
                    isBaseCurrency={isBaseCurrency}
                    isAlternativeCurrency={isAlternativeCurrency}
                    handleSubmit={handleSubmitUpdate}
                    // typeSlabMaterial={currentEditRowData?.typeMaterial === 'SLAB' && "WHOLE"}
                    isSlabMaterial={currentEditRowData?.typeMaterial === 'SLAB'}
                    isNew={false}
                    currencyMap={currencyMap}
                />
                <ModalAddMaterialPriceUnitModule
                    data={data}
                    setData={setData}
                    open={addModalOpen}
                    setOpen={setAddModalOpen}
                    currentCurrency={currentCurrency}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    isBaseCurrency={isBaseCurrency}
                    isAlternativeCurrency={isAlternativeCurrency}
                    supplierId={supplierId}
                    currentEditRowData={currentEditRowData}
                    setCurrentEditRowData={setCurrentEditRowData}
                    currentEditRowId={currentEditRowId}
                    materialPriceUnits={materialPriceUnits}
                    arrivalMaterialId={arrivalMaterialId}
                    currencyMap={currencyMap}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                    setUpdateModalOpen={setUpdateModalOpen}
                />
                <ModalOpenSetValues
                    title={setTitleModalOpenMethod(typeCurrentCurrency)}
                    label={setLabelModalOpenMethod(typeCurrentCurrency)}
                    modalOpen={modalCurrencyOpen}
                    handleClose={handleCloseModalCurrency}
                    setValue={setCurrentCurrencyBM}
                    handleSubmit={updateCurrencyByIds}
                />
                <ModalConfirm
                    title={"Подтвердить приход?"}
                    modalOpen={showConfirmationModalArrivalMaterial}
                    handleClose={cancelArrivalMaterial}
                    handleSubmit={handleSubmitArrivalMaterial}
                    action={"Подтвердить"}
                />
            </Box>
        )
            ;
    }
;

export default ArrivalMaterialPriceUnitTable;

