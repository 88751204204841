import React from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";

const RequestsCuttingOptimization = () => {

    const params = useParams();

    let optimizationId = params.optimizationId;
    const name = params.name;

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Заявки на раскрой оптимизации ${name}`}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Typography variant="h6" component="h6">
                Заявки на раскрой оптимизации {name}
            </Typography>
            <RequestsCuttingTable
                getAll={RequestsCuttingService.getAllWithMaterialByOptimizationId}
                optimizationId={optimizationId}
                isOptimization={false}
                isRedirectRequestsCutting={true}
                isCalculation={false}
                isAddOptimization={true}
                isDeleteOptimization={true}
                isCreateOptimization={true}
                isPlan={true}
                deleteRowBeforeAction={true}
            />
        </Box>
    );
};

export default RequestsCuttingOptimization;