import React, {useEffect, useState} from 'react';
import JobMetricOrganizationTable
    from "../../../components/UI/table/prodaction/jobmetricorganization/JobMetricOrganizationTable";
import JobMetricOrganizationService from "../../../API/orders/job/JobMetricOrganizationService";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import JobMetricAdminPackingService from "../../../API/orders/job/Admin/JobMetricAdminPackingService";
import JobMetricAdminMillingService from "../../../API/orders/job/Admin/JobMetricAdminMillingService";
import JobMetricAdminDrillingService from "../../../API/orders/job/Admin/JobMetricAdminDrillingService";
import JobMetricAdminEdgeService from "../../../API/orders/job/Admin/JobMetricAdminEdgeService";
import JobMetricAdminCuttingService from "../../../API/orders/job/Admin/JobMetricAdminCuttingService";

const JobMetricOrganization = ({admin, itemId, handleError, settings}) => {
    const pathname = useLocation().pathname
    const [change, setChange] = useState(false);
    const [dataJobMetricOrganization, setDataJobMetricOrganization] = useState([]);
    const [rowSelection, setRowSelection] = useState([]);
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const profile = JSON.parse(localStorage.getItem("profile"))
    const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
    const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const api = itemId === "listJobPacking" ? JobMetricAdminPackingService :
        itemId === "listJobMilling" ? JobMetricAdminMillingService
            : itemId === "listJobEdge" ? JobMetricAdminEdgeService
                : itemId === "listJobDrilling" ? JobMetricAdminDrillingService
                    : itemId === "listJobCutting" ? JobMetricAdminCuttingService
                        : JobMetricOrganizationService

    useEffect(() => {
        getStartPageSize()
        const method = itemId === "listJob" ? api.getAllJobMetricOrganizationForSettings : api.get
        method().then(function (response) {
            setDataJobMetricOrganization(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });

    }, [itemId]);


    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    return (

        <JobMetricOrganizationTable
            admin={admin}
            api={api}
            itemId={itemId}
            data={dataJobMetricOrganization}
            setData={setDataJobMetricOrganization}
            change={change}
            setChange={setChange}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            rowIndexSelection={rowIndexSelection}
            setRowIndexSelection={setRowIndexSelection}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            isChooseSignJob={false}
            baseCurrency={baseCurrency}
            alternativeCurrency={alternativeCurrency}
            tableOptions={tableOptions}
            tableOptionsHandle={tableOptionsHandle}
            columnAdded={true}
            isUnit={admin}
            handleError={handleError}
            settings={settings}
        />

    );
};

export default JobMetricOrganization;