import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import ModelEstimateService from "../../../../../API/model-estimate/estimate/ModelEstimateService";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import MenuItem from "@mui/material/MenuItem";
import ButtonCreate from "../../../button/ButtonCreate";
import Radio from "@mui/material/Radio";

const BasicModelEstimateTable = ({
                                     modelId, data, setData, tableOptions, tableOptionsHandle, handleError
                                 }) => {

    const handleDeleteRow = useCallback((row) => {
        ModelEstimateService.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
            })
            .catch(function (error) {
                handleError(error);
            })
    }, [data]);

    const handleSubmit = useCallback(() => {
        ModelEstimateService.create(modelId)
            .then(function (response) {
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
            })
            .catch(function (error) {
                handleError(error);
            })
    }, [data]);

    const setEnabledDefault = (row) => {
        ModelEstimateService.unableCurrent(row.id, modelId)
            .then(function () {
                setData(prev => prev.map(el => el.id === row.id
                    ? {...el, current: true}
                    : {...el, current: false}
                ))
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    const columns = useMemo(() => [{
        id: 'number',
        header: 'Номер',
        accessorKey: 'number',
        minSize: 450,
        Cell: ({cell, row}) => (<li key={cell.getValue()}>
            <Link
                to={'/organization/basic-models/' + modelId + '/estimates/' + data[row.index].id + "/" + data[row.index].number}>{cell.getValue()}</Link>
        </li>),
    }, {
        accessorFn: (row) => {
            if (row.date === null) {
            } else {
                return new Date(row.date)
            }
        },
        id: 'date', header: 'Дата', sortingFn: 'datetime', minSize: 450, Cell: ({cell}) => {
            if (cell.getValue() !== undefined) {
                return cell.getValue().toLocaleDateString();
            }
        },
        filterFn: (row, id, filterValue) => {
            const startDate = filterValue?.startDate;
            const endDate = filterValue?.endDate;
            if (startDate && endDate) {
                return (
                    DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                    DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                );
            }
            return true;
        },
        Filter: ({column}) => <DateRangeFilter
            column={column}
        />,
    },
        {
            id: 'current',
            header: 'Текущий',
            accessorKey: 'current',
            maxSize: 80,
            enableColumnActions: false,
            Cell: ({cell, row}) => <Radio
                onChange={() => setEnabledDefault(row.original)}
                checked={row.original.current}
                sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
            />
        },


    ], [data, setData]);

    const menuItem = [
        {type: "delete", text: "Удалить", handler: handleDeleteRow}
    ]
    return (
        <Box sx={{width: '1150px',}}>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}>
                <ButtonCreate
                    text={"Создать"}
                    width={120}
                    onClick={handleSubmit}
                />
            </Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '1150px',
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',
                        },
                        "&:last-of-type": {
                            padding: 0,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                    }, isFullScreen: false, density: "compact",
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                localization={MRT_Localization_RU}
                getRowId={(originalRow) => originalRow.id}
            />
        </Box>);
};

export default BasicModelEstimateTable;