import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const groupMaterialRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/admin/group-materials'

export default class AdminGroupMaterialMaterialCloudService {
    static async getAllForSettings() {
        return axios.get(groupMaterialRestUrl);
    }

    static async update(id, data) {
        const parent = data.parentId ? `?parentId=${data.parentId}` : ''
        return await axios.put(`${groupMaterialRestUrl}/${id}${parent}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        const parent = data.parentId ? `?parentId=${data.parentId}` : ''
        return await axios.post(`${groupMaterialRestUrl}${parent}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(groupMaterialRestUrl + '/' + id);
    }
}