import React, {useCallback, useState} from 'react';
import Box from "@mui/material/Box";
import StarIcon from '@mui/icons-material/Star';
import {Rating} from "@mui/lab";
import Typography from "@mui/material/Typography";
import {Button, Tooltip} from "@mui/material";
import {ReactComponent as TrashIcon} from "../../../../../../components/Icons/trash.svg"
import ModalConfirm from "../../../../modal/ModalConfirm";

const RatingWorker = ({item, workerId, handleDelete, handleEdit}) => {
    const [confirm, setConfirm] = useState(false);

    const labels = {
        0.5: 'Useless',
        1: 'Useless+',
        1.5: 'Poor',
        2: 'Poor+',
        2.5: 'Ok',
        3: 'Ok+',
        3.5: 'Good',
        4: 'Good+',
        4.5: 'Excellent',
        5: 'Excellent+',
    };

    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>
            <Tooltip arrow placement="bottom" title="Удалить">
                <Button sx={{padding: 0}} onClick={() => setConfirm(true)}>
                    <TrashIcon style={{width: "25px", height: "25px"}}/>
                </Button>
            </Tooltip>
            <Typography sx={{width: 450}} component="legend">{item.typeJob.name}</Typography>
            <Rating
                name={workerId}
                value={item.level}
                onChange={(event, newValue) => {
                    handleEdit(item.id, {...item, level: newValue});
                }}
                precision={0.5}
                getLabelText={getLabelText}
                emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit"/>}
            />
            <ModalConfirm
                title={"Подтвердить удаление?"}
                modalOpen={confirm}
                handleClose={() => setConfirm(false)}
                handleSubmit={() => handleDelete(item, setConfirm)}
                action={"Подтвердить"}
            />
        </Box>
    );
};

export default RatingWorker;