import React, {useEffect, useState} from "react";
import {Paper, Stack, TextField, Typography} from "@mui/material";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import {BreadcrumbsComponent} from "../../components/UI/breadcrumb/BreadcrumbComponent";
import Footer from "./Footer";
import Card from "../../components/UI/Card";
import Autocomplete from '@mui/material/Autocomplete'
import UtilityHelpService from "../../API/common/UtiltyHelpServices";
import {constantsErrorMessages} from "../../Constants";
import ModalAlert from "../../components/UI/table/customer-detales/ModalAlert";

const Help = () => {
    const profileStorage = JSON.parse(localStorage.getItem("profile"))
    const isAdmin = profileStorage?.userRoles?.find(el => el.name === "ROLE_SYSTEM_ADMIN" || el.name === "ROLE_ADMIN")
    const [currTheme, setCurrTheme] = useState([])
    const [allTheme, setAllTheme] = useState([])
    const [helpData, setHelpData] = useState([])
    const [alertData, setAlertData] = useState({});

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    useEffect(() => {
        const apiTheme = isAdmin ? UtilityHelpService.getThemeHelp : UtilityHelpService.getThemeHelpUser
        const api = isAdmin ? UtilityHelpService.get : UtilityHelpService.getHelpUser
        apiTheme().then(function (response) {
            setAllTheme(response.data);
        })
            .catch(function (error) {
                handleError(error);
            });
        api().then(function (response) {
            setHelpData(response.data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (<>
            <Paper sx={{
                width: '100%',
                paddingBottom: "40px",
                display: 'flex',
                justifyContent: "space-between",
                flexGrow: 1
            }}>
                <Container sx={{
                    "&.MuiContainer-root": {
                        maxWidth: "1668px",
                        display: 'flex',
                        flexDirection: "column",
                        gridGap: "100px",
                    },
                }}>
                    <Box>
                        <BreadcrumbsComponent
                            page={"Помощь"}
                            path={[
                                {name: "Главная", href: "/"},
                            ]}/>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: "column",
                            gridGap: "40px",
                            justifyContent: "center",
                            width: "100%",
                        }}>
                            <Box sx={{
                                display: 'flex', justifyContent: "space-between",
                            }}>
                                <Typography sx={{
                                    fontSize: "40px", fontWeight: 700, lineHeight: 1,
                                    '@media (max-width: 1450px)': {
                                        fontSize: "28px",
                                    }
                                }}>
                                    Популярные видеоролики
                                </Typography>
                                <Stack sx={{width: 500}}>
                                    <Autocomplete
                                        multiple
                                        value={currTheme}
                                        onChange={(e, value) => setCurrTheme(value)}
                                        id="tags"
                                        limitTags={3}
                                        options={allTheme}
                                        getOptionLabel={(option) => option.title}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Темы"
                                            />
                                        )}
                                    />
                                </Stack>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                            }}>
                                {helpData.map((el, i) => {
                                    const available = currTheme.length === 0 || currTheme.some((theme) => el.themeHelps.find(el => el.id === theme.id))
                                    return available && <Box key={el.id} sx={{height: "360px"}}>
                                        <Card
                                            key={el.id}
                                            element={el}
                                            clickable={true}
                                            picturePosition={"top"}
                                            width={526}
                                            height={360}
                                            padding={"20px"}
                                            imgHeight={"260px"}
                                        />
                                    </Box>
                                })}
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Paper>
            <Footer></Footer>
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </>
    );
};

export default Help;