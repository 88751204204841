import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import UploadOrderFilesForm from "../../../../form/UploadOrderFilesForm";
import {MaterialReactTable} from "material-react-table";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import MenuItem from "@mui/material/MenuItem";
import UtilityService from "../../../../../API/common/UtilityService";

const FilesTable = ({
                        nameOrder,
                        year,
                        orderId,
                        change,
                        setChange,
                        title,
                        handleError
                    }) => {

    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/orders`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/orders`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        UtilityService.getAllProjectFileNames(orderId).then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [change]);

    const handleDeleteRow = useCallback(
        (row) => {
            UtilityService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    setTableOptions((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );

    const menuItem = [
        {type: "delete", text: "Удалить  файл", handler: handleDeleteRow}]

    const columns = useMemo(
        () => [
            {
                id: 'nameFile',
                header: 'Найменование файла',
                accessorKey: 'nameFile',
                minSize: 650,
            },
            {
                id: 'description',
                header: 'Описание',
                accessorKey: 'description',
                minSize: 650,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <Box sx={{maxWidth: "1500px"}}>
            <Typography variant="h6" component="h6" sx={{paddingBottom: "24px"}}>
                Файлы проекта
            </Typography>
            <UploadOrderFilesForm
                label={"Загрузить файлы проекта"}
                year={year}
                nameOrder={nameOrder}
                fileNameType={"PROJECT"}
                uploadData={{
                    id: 1,
                    helpText: "Загрузить файлы проекта",
                    upload: UtilityService.uploadOrderFile
                }}
                orderId={orderId}
                change={change}
                setChange={setChange}
                calculationRequestsCutting={false}
                handleError={handleError}
            />
            <Box sx={{overflow: 'auto'}}>
                <MaterialReactTable
                    muiTopToolbarProps={{
                        sx: {
                            "& .MuiBox-root": {
                                padding: 0
                            }
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem",
                            "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                "minWidth": "100%"
                            }
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            sx: () => ({
                                height: '36px',
                                backgroundColor: "white",
                                "&:hover& td:after": {
                                    display: "none",
                                },
                                "&:hover": {
                                    "& td:after": {
                                        display: "none",
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                },
                            })
                        })
                    }}

                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        sx: {
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                    }}
                    columns={columns}
                    data={data}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowSelection={false}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                    }}
                    localization={MRT_Localization_RU}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: `calc(100vh - 600px)`,
                            width: "1500px"
                        }
                    }}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })}
                    enableRowActions={true}
                    enableToolbarInternalActions={false}
                />
            </Box>
        </Box>
    );
};
export default FilesTable;