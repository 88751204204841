import React, {useMemo, useState} from "react";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import MenuItem from "@mui/material/MenuItem";
import {MaterialReactTable} from "material-react-table";
import ButtonCreate from "../../button/ButtonCreate";
import Box from "@mui/material/Box";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../customer-detales/ModalAlert";
import SignJobModal from "./SignJobModal";
import {modelParameterUnit} from "../../../../data/ModelParameterUnit";
import {signJobType} from "../../../../data/SignJobType";
import SignJobService from "../../../../API/orders/job/SignJobService";
import AddSignJobMetricModal from "./AddSignJobMetricModal";
import TypeJobOrganizationService from "../../../../API/orders/job/TypeJobOrganizationService";
import {orderJobType} from "../../../../data/OrderJobType";

const SettingsSignJobTable = ({
                                  data,
                                  setData,
                                  isError,
                                  baseCurrency,
                                  alternativeCurrency,
                                  id,
                                  dataForSelects,
                                  jobMetric,
                                  isModal,
                                  selectedString,
                                  setSelectedString,
                                  initValue,
                                  handleError
                              }) => {
    const [modal, setModal] = useState(false);
    const [modalAddSignJob, setModalAddSignJob] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);

    const handleClose = () => {
        setModal(false)
        setModalAddSignJob(false)
        setCurrentEditRowData(initValue)
    }

    const handleUpdate = (data) => {
        SignJobService.update(data, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleDelete = (row) => {
        jobMetric ?
            TypeJobOrganizationService.deleteSignJob(id, row.id)
                .then(function (response) {
                    setData(prev => prev.filter(el => el.id !== row.id))
                })
                .catch(function (error) {
                    handleError(error)
                }) : SignJobService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                })
                .catch(function (error) {
                    handleError(error)
                })
    };
    const handleSubmit = (data) => {
        SignJobService.create({...data, jobMetricOrganizationId: id})
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }
    const handleSubmitMetric = (signJobId) => {
        TypeJobOrganizationService.addSignJob(id, signJobId)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleEdit = (row) => {
        setCurrentEditRowData({
            id: row.id,
            signJobNameId: row.signJobName.id,
            modelParameterUnit: row.modelParameterUnit,
            price: row.basePrice?.price,
            curAbbreviation: baseCurrency,
            laborIntensity: row.laborIntensity,
            unitId: row.unit?.id
        });
        setModal(true)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Найменование', accessorKey: 'signJobName.name', minSize: 300,
            },
            {
                id: "modelParameterUnit",
                header: 'Параметр с модели',
                accessorKey: 'modelParameterUnit',
                accessorFn: (row) => isModal ? row.jobMetricOrganization?.modelParameterUnit : row.modelParameterUnit,
                minSize: 500,
                Cell: ({cell}) => modelParameterUnit[cell.getValue()],
            },
            {
                header: `Ед.измерения`, accessorKey: 'unit.name', size: 8,
            },
        ]
        if (isModal) {
            baseColumns.splice(2, 0, {
                id: "orderJobType",
                header: 'Тип работы',
                accessorKey: 'jobMetricOrganization.orderJobType',
                minSize: 280,
                Cell: ({cell}) => orderJobType[cell.getValue()],
            });
        }
        if (!isModal) {
            baseColumns.splice(1, 0, {
                header: `Стоимость: ${baseCurrency}`, accessorKey: 'basePrice.price', size: 8,
                Cell: ({cell}) => cell.getValue().toFixed(2)
            });
            baseColumns.splice(2, 0, {
                header: `Стоимость: ${alternativeCurrency}`, accessorKey: 'alternativePrice.price', size: 8,
                Cell: ({cell}) => cell.getValue().toFixed(2)
            });
            baseColumns.splice(4, 0, {
                header: `Трудоёмкость`, accessorKey: 'laborIntensity', size: 8,
            });
        }
        if (!jobMetric && !isModal) {
            baseColumns.splice(4, 0, {
                id: "signJobType",
                header: 'Тип работы',
                accessorKey: 'signJobType',
                minSize: 280,
                Cell: ({cell}) => signJobType[cell.getValue()],
            });
        }
        return baseColumns;
    }, [data]);

    const menuItem = jobMetric ? [{type: "delete", text: "Удалить", handler: handleDelete}] : [
        {type: "edit", text: "Редактировать", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}]

    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
        >
            {!isModal && <ButtonCreate
                text={jobMetric ? "Добавить метрику" : "Добавить затрату"}
                width={200}
                onClick={jobMetric ? () => setModalAddSignJob(true) : () => setModal(true)}
            />}
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": !isModal && {
                        padding: 0,
                        width: '50px',
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    onClick: () => isModal && setSelectedString(row.original),
                    sx: () => ({
                        height: '36px',
                        cursor: isModal && "pointer",
                        backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : '#F5F6FA',
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 10,
                    minSize: 10,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={isError ? [] : data}
            rowHeight={13}
            columnSpacing={1}
            state={{showGlobalFilter: true,}}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            localization={MRT_Localization_RU}
            enableRowActions={!isModal}
            enableToolbarInternalActions={false}
        />
        {!jobMetric && !isModal && <SignJobModal
            title={currentEditRowData.id ? "Изменить" : "Добавить"}
            open={modal} onClose={handleClose}
            handleSubmit={currentEditRowData.id ? handleUpdate : handleSubmit}
            initValue={currentEditRowData}
            dataForSelects={dataForSelects}
        />}
        {jobMetric && <AddSignJobMetricModal
            title={"Добавить метрику"}
            open={modalAddSignJob} onClose={handleClose}
            handleSubmit={handleSubmitMetric}
            jobMetric={jobMetric}
            baseCurrency={baseCurrency}
            alternativeCurrency={alternativeCurrency}
            handleError={handleError}
        />}
    </div>);
};

export default SettingsSignJobTable;