const selectionTableUtil = (data, rowSelection, setRowSelection) => {
    const currentSelection = data.reduce(
        (acc, {id}) => {
            if (!rowSelection?.includes(id)) {
                return acc
            }
            return {...acc, [id]: true}
        },
        {}
    );
    const onRowSelectionChange = (updater) => {
        const result = Object.fromEntries(Object.entries(updater(currentSelection)).filter(([key, value]) => Number.isInteger(+key)))
        const currentSelectedIds = Object.entries(result)
            .filter(([_, value]) => value)
            .map(([index]) => +index)

        const currentPageIds = data.map(({id}) => id)
        const allSelectedWithoutCurrentPage = rowSelection.filter((it) => !currentPageIds.includes(it))

        setRowSelection(Array.from(new Set([...allSelectedWithoutCurrentPage, ...currentSelectedIds])))
    }
    return {currentSelection, onRowSelectionChange}
}

export default selectionTableUtil

