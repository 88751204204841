import {TextField} from "@mui/material";
import React from "react";

const CreateOrderTextFieldCustomer = ({label, defaultValue, setId, setCustomerName, field, entityList}) => {

    return (
        <div>
            <TextField
                size={"small"}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                defaultValue={defaultValue}
                name={field}
                onChange={(e) => {
                    const itemsMap = entityList.reduce((map, e) => {
                        map.set(e.id.toString(), e.name);
                        return map;
                    }, new Map());
                    const id = e.target.value.toString()
                    const name = itemsMap.get(id)
                    setId(id);
                    setCustomerName(name);
                }}
                SelectProps={{
                    native: true,
                }}
            >
                {entityList.map((entity) => (
                    <option
                        key={entity.id}
                        value={entity.id}>
                        {entity.name}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default CreateOrderTextFieldCustomer;