import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, Tabs} from "@mui/material";
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import UploadFilesRequestsForm from "./UploadFilesRequestsForm";
import FileService from "../../../../API/orders/orders/organization/FileService";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";
import Typography from "@mui/material/Typography";
import RequestsCuttingTableOrderItemTable
    from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTableOrderItemTable";

const RequestsCuttingEstimateItem = ({
                                         getAll,
                                         isOptimization,
                                         isRedirectRequestsCutting,
                                         orderId,
                                         nameOrder,
                                         idHeader,
                                         year,
                                         isOrder,
                                         isButtonAddRequestsCutting,
                                         isEstimate,
                                         isCreateOptimizationCalculation,
                                         isSetForOptimization,
                                         isRedirectOptimizations,
                                         isRedirectCalculationOptimizations,
                                         isRedirectCalculationCalculationWorkingCuttingMaps,
                                         isRedirectCalculationRequestsCuttings,
                                         customer,
                                         handleError,
                                         change,
                                         setChange
                                     }) => {
    const pathname = useLocation().pathname
    const [tab, setTab] = useState(1);
    const [calculationRequestsCutting, setCalculationRequestsCutting] = useState(false)

    const handleChangeTab = (tab) => {
        setTab(tab)
        EditLocalStoragePagesData(pathname, "tab", tab)
    }
    useEffect(() => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const savedTab = pagesData[pathname]?.tab
        savedTab && setTab(savedTab)
    }, []);

    const generalTabComponents = [
        {
            tabIndex: 1, label: "Заявки на раскрой",
            component:
                <RequestsCuttingTableOrderItemTable
                    getAll={getAll}
                    isOptimization={isOptimization}
                    isRedirectRequestsCutting={isRedirectRequestsCutting}
                    orderId={orderId}
                    nameOrder={nameOrder}
                    idHeader={idHeader}
                    year={year}
                    isOrder={isOrder}
                    isButtonAddRequestsCutting={isButtonAddRequestsCutting}
                    isEstimate={isEstimate}
                    isCreateOptimizationCalculation={isCreateOptimizationCalculation}
                    isSetForOptimization={isSetForOptimization}
                    hideOrder={true}
                    hideWorkingCuttingMap={true}
                    customer={customer}
                    isRedirectOptimizations={isRedirectOptimizations}
                    isRedirectCalculationOptimizations={isRedirectCalculationOptimizations}
                    isRedirectCalculationCalculationWorkingCuttingMaps={isRedirectCalculationCalculationWorkingCuttingMaps}
                    isRedirectCalculationRequestsCuttings={isRedirectCalculationRequestsCuttings}
                    handleError={handleError}
                    setChange={setChange}
                    isButtonCreate={true}
                    isCreateOptimization={true}
                />
        }, {
            tabIndex: 2, label: "Импорт",
            component:
                <Box sx={{paddingTop: "32px", display: "flex", gap: "24px"}}>
                    <UploadFilesRequestsForm
                        uploadData={{
                            id: 1,
                            helpText: "Импорт заявок на раскрой из файлов раскроя",
                            upload: FileService.uploadRequestsCuttingFiles
                        }}
                        orderId={orderId}
                        change={change}
                        setChange={setChange}
                        calculationRequestsCutting={calculationRequestsCutting}
                        handleError={handleError}
                    />
                    <Box sx={{display: "flex", height: "35px", alignItems: "center"}}>
                        <Typography component="p" sx={{fontSize: "20px",}}>
                            Расчёт
                        </Typography>
                        <Checkbox
                            onChange={(event) => setCalculationRequestsCutting(event.target.checked)}
                            checked={calculationRequestsCutting}
                        />
                    </Box>
                </Box>
        }]
    return (
        <Box>
            <ButtonGroupTabBar activeTab={tab}>
                {generalTabComponents.map(({tabIndex, label}, index) => (
                    <Button key={tabIndex} tabIndex={tabIndex} onClick={() => handleChangeTab(tabIndex)}>
                        <CheckIcon/>
                        {label}
                    </Button>
                ))}
            </ButtonGroupTabBar>
            {generalTabComponents.find(component => component.tabIndex === tab)?.component}
        </Box>
    );
};

export default RequestsCuttingEstimateItem;