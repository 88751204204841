import React, {useState} from 'react';
import UploadFilesForm from "./UploadFilesForm";

const UploadOrderFilesForm = ({label, year, orderId, change, setChange, fileNameType, uploadData, handleError}) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {
        event.preventDefault();
        uploadData.upload && uploadData.upload(year, orderId, fileNameType, selectedFiles)
            .then(function () {
                setSelectedFiles([]);
                setChange(!change)
                event.target.reset();
            })
            .catch(function (error) {
                handleError(error);
                event.target.reset();
            })
    }

    return (
        <div>
            <UploadFilesForm
                uploadData={uploadData}
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={label}
            />
        </div>
    );
};

export default UploadOrderFilesForm;