import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Stack,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import OrganizationCustomerService from "../../../../../../API/orders/orders/organization/OrganizationCustomerService";
import CellCheckboxCTUnable from "./CellCheckboxCTUnable";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import AddressOrganizationService from "../../../../../../API/organization/AddressOrganizationService";
import {country} from "../../../../../../data/Country";
import CheckFieldUtil from "../../../../../../utils/orders/CustomerUtil";
import selectionTableUtil from "../../../../../../utils/common/SelectionTableUtil";

const CustomerTable = ({
                           data,
                           setData,
                           isModal,
                           tableOptions,
                           tableOptionsHandle,
                           rowSelection,
                           setRowSelection,
                           handleError
                       }) => {
    const initValue = {
        name: "",
        email: "",
        registrationNumber: "",
        residentialAddress: "",
        organizationalAndLegalForm: "",
        address: "",
    }
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);

    const handleClose = () => {
        setCreateModalOpen(false)
        setCurrentEditRowData(initValue)
    }
    const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)
    const handleSaveRowEdits = (values) => {
        const {id, ...data} = values
        OrganizationCustomerService.update({
            ...data,
            address: data.address ? {id: data.address} : null,
            residentialAddress: data.residentialAddress ? {id: data.residentialAddress} : null
        }, currentEditRowData.id)
            .then(function (response) {
                handleClose()
                setData(prev => prev.map(el => el.id === id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
    }
    const handleSubmit = (values) => {
        OrganizationCustomerService.save({
            ...values,
            address: values.address ? {id: values.address} : null,
            residentialAddress: values.residentialAddress ? {id: values.residentialAddress} : null
        }).then(function (response) {
            setData(prev => [...prev, response.data])
            tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
            handleClose()
        })
            .catch(function (error) {
                handleError(error);
            });

    };

    const handleDeleteRow = useCallback(
        (row) => {
            OrganizationCustomerService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );
    const handleEditRow = (row) => {
        setCurrentEditRowData({...row, address: row.address?.id, residentialAddress: row.residentialAddress?.id});
        setCreateModalOpen(true)
    }
    const handleUnable = (id, enabled) => {
        OrganizationCustomerService.unable(id, enabled)
            .then(function () {
                setData(prev => prev.map(el => el.id === id ? {...el, enabled: !el.enabled} : el))
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 300,
            },
            {
                id: 'email',
                header: 'Email',
                accessorKey: 'email',
                minSize: 300,
            },
            {
                id: 'registrationNumber',
                header: 'УНП',
                accessorKey: 'registrationNumber',
                minSize: 15,
                maxSize: 15,
            },
            {
                id: 'organizationalAndLegalForm',
                header: 'ОПФ',
                accessorKey: 'organizationalAndLegalForm',
                minSize: 6,
                maxSize: 6,
            },
            {
                id: 'enabled',
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxCTUnable
                    enabled={data[row.index].enabled}
                    id={data[row.index].id}
                    handleUnable={handleUnable}
                >
                </CellCheckboxCTUnable>,
            },
            {
                id: 'residentialAddress',
                header: 'Адрес доставки',
                accessorKey: 'residentialAddress',
                minSize: 400,
                Cell: ({cell, row}) => {
                    const address = cell.getValue()
                    return address && [country[address?.country], address?.locality, address?.street, address?.houseNumber, address?.building, address?.roomNumber].filter(el => el).join(", ")
                }
            },

            {
                id: 'address',
                header: 'Юридический адрес',
                accessorKey: 'address',
                minSize: 400,
                Cell: ({cell, row}) => {
                    const residentialAddress = cell.getValue()
                    return [country[residentialAddress?.country], residentialAddress?.locality, residentialAddress?.street, residentialAddress?.houseNumber, residentialAddress?.building, residentialAddress?.roomNumber].filter(el => el).join(", ")
                }
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditRow},
        {type: "delete", text: "Удалить", handler: handleDeleteRow}]

    return (
        <>
            <Box
                sx={{
                    maxWidth: "2300px",
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: "space-between",
                    paddingBottom: "30px",
                    gap: "16px"
                }}>
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>Заказчики</Typography>
                {!isModal && <ButtonCreate
                    text={"Создать заказчика"}
                    width={210}
                    onClick={() => setCreateModalOpen(true)}
                />}
            </Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        maxHeight: Object.keys(rowSelection).length === 2 ? `calc(100vh - 600px)` : `calc(100vh - 520px)`,
                        width: "2300px"
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": !isModal && {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            cursor: isModal && "pointer",
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: '#F5F6FA',
                            },
                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: "50px",
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                initialState={{
                    sorting: [{id: 'enabled', desc: true}, {id: 'name', desc: false}],
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                onRowSelectionChange={onRowSelectionChange}
                enableSelectAll={false}
                enableRowSelection={(row) => rowSelection.length === 2 ? rowSelection.includes(row.original.id) : true}
                state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                getRowId={(originalRow) => originalRow.id}
                rowCount={tableOptions.totalElements}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />
            <CreateNewCustomerModal
                columns={columns}
                open={createModalOpen}
                onClose={handleClose}
                initValue={currentEditRowData}
                onSubmit={currentEditRowData.id ? handleSaveRowEdits : handleSubmit}
                handleError={handleError}
            />
        </>
    );
};

export const CreateNewCustomerModal = ({open, columns, onClose, onSubmit, initValue, handleError}) => {
    const [addressData, setAddressData] = useState([])
    const [values, setValues] = useState(initValue);
    const fields = initValue.blocked === true
        ? [{accessorKey: "name", header: "Наименование"}, {accessorKey: "address", header: "Юридический адрес"}]
        : columns

    useEffect(() => {
        setValues(initValue)
    }, [initValue])

    useEffect(() => {
        if (open) {
            AddressOrganizationService.getAll().then(function (response) {
                setAddressData(response.data);
            })
                .catch(function (error) {
                    handleError(error);
                });
        }
    }, [open]);

    return (
        <Dialog open={open}>
            <DialogTitle
                textAlign="center">{initValue.id ? "Изменить заказчика" : "Добавить заказчика"}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                        }}
                    >
                        {fields.map((column) => (
                            CheckFieldUtil.customer(column) && (column.accessorKey === "residentialAddress" || column.accessorKey === "address" ?
                                <TextFieldValidation
                                    select
                                    key={column.accessorKey}
                                    name={column.accessorKey}
                                    label={column.header}
                                    value={values[column.accessorKey]}
                                    setCurrValues={setValues}
                                    required={false}
                                >
                                    {addressData.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {`${option?.locality} ${option?.street} ${option?.houseNumber}`}
                                        </MenuItem>))}
                                </TextFieldValidation>
                                : <TextFieldValidation
                                    key={column.accessorKey}
                                    name={column.accessorKey}
                                    label={column.header}
                                    value={values[column.accessorKey]}
                                    required={false}
                                    setCurrValues={setValues}
                                />)))}
                        {initValue.id && <FormControlLabel control={<Checkbox
                            checked={values.enabled === true}
                            onChange={() => setValues(value => ({
                                ...value,
                                "enabled": !value.enabled
                            }))}
                        />} label="Статус"/>}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Сохранить"} onClick={() => onSubmit(values)}/>
            </DialogActions>
        </Dialog>
    );
};

export default CustomerTable;