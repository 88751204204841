import React, {useMemo} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";

const MaterialForRequestsCuttingTable = ({
                                             data, tableOptions, tableOptionsHandle, selectMaterialId, selectMaterial
                                         }) => {
    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [data],
    );

    return (
        <div>
            <MaterialReactTable
                muiTopToolbarProps={{
                    sx: {
                        "& .MuiBox-root": {
                            padding: "8px 0"
                        }
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',
                        },
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem",
                        "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                            "minWidth": "100%"
                        }
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        onClick: () => selectMaterial && selectMaterial(row.original),
                        sx: () => ({
                            height: '36px',
                            cursor: selectMaterial && "pointer",
                            "&:hover& td:after": row.original.id === selectMaterialId && {
                                display: "none",
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: row.original.id === selectMaterialId ? "#D7E8FA" : '#F5F6FA',
                            },
                            backgroundColor: row.original.id === selectMaterialId ? "#D7E8FA" : "white",
                        })
                    })
                }}

                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                localization={MRT_Localization_RU}
                enableToolbarInternalActions={false}
            />

        </div>
    );
};

export default MaterialForRequestsCuttingTable;