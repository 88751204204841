import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const attributeRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/attributes'

export default class AttributesService {

    static async getAll() {
        return axios.get(attributeRestUrl);
    }

    static async getAllCheckForOrder(orderId) {
        return axios.get(attributeRestUrl + '/check-for-order/' + orderId);
    }

    static async update(data) {
        return await axios.put(attributeRestUrl + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(attributeRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(attributeRestUrl + '/' + id);
    }

    static async unable(id, status) {
        return axios.patch(attributeRestUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async unableDefault(id) {
        return axios.patch(attributeRestUrl + '/' + id + '/enabled-default', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}