import React, {useEffect, useState} from 'react';
import OptimizationTable from "../../../../components/UI/table/optimization/optimization/OptimizationTable";
import OptimizationService from "../../../../API/orders/optimization/organization/OptimizationService";
import {Box} from "@mui/system";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";
import ButtonSelectAction from "../../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../../components/UI/modal/ModalSelectAction";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../Constants";
import ModalConfirm from "../../../../components/UI/modal/ModalConfirm";
import WorkingCuttingMapService from "../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";

const Optimizations = () => {
    const [rowSelection, setRowSelection] = useState([]);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [createCuttingMapsModalOpen, setCreateCuttingMapsModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/orders`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/orders`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const modalActions = [
        {name: "Создать карты раскроя", type: "cuttingMaps"},
    ]
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    const handleSelectAction = () => {
        setOpen(false)
        setCreateCuttingMapsModalOpen(true)
    }

    useEffect(() => {
        getStartPageSize()
        OptimizationService.getAllNotArchive().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);
    const handleSubmitCreateCuttingMaps = async () => {
        WorkingCuttingMapService.createByIdsOptimization(rowSelection, false)
            .then(function () {
                const newData = data.map(item => {
                    if (rowSelection.includes(item.id)) {
                        return {
                            ...item,
                            optimizationState: 'CREATED_CUTTING_MAP'
                        };
                    } else {
                        return item;
                    }
                });
                setData(newData);
                setCreateCuttingMapsModalOpen(false)
                setRowSelection([])
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Оптимизации"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Оптимизации
                    </Typography>
                </Box>
                <OptimizationTable
                    data={data}
                    setData={setData}
                    isCreateCreateOptimization={true}
                    isRedirectPlans={true}
                    isCalculation={false}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />

                {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>}
                <ModalSelectAction
                    open={open}
                    reset={() => setOpen(false)}
                    submit={handleSelectAction}
                    modalActions={modalActions}
                />
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
                <ModalConfirm
                    title={"Подтвердить создание карт?"}
                    modalOpen={createCuttingMapsModalOpen}
                    handleClose={() => setCreateCuttingMapsModalOpen(false)}
                    handleSubmit={handleSubmitCreateCuttingMaps}
                    action={"Подтвердить"}/>
            </Box>
        </Box>
    );
};

export default Optimizations;