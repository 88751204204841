import React, {useState} from 'react';
import FileService from "../../API/orders/orders/organization/FileService";
import UploadFilesForm from "./UploadFilesForm";

const UploadOrderEstimateFileForm = ({label, year, orderId, change, setChange, handleError}) => {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleUpload = async (event) => {
        event.preventDefault();
        FileService.uploadOrderEstimateFile(year, orderId, selectedFile[0])
            .then(function () {
                setChange(!change)
                setSelectedFile(null)
                event.target.reset();
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    return (
        <div>
            <UploadFilesForm
                uploadData={{id: 1, helpText: "Импорт сметы из файла сметы базис-мебельщика *.xls"}}
                handleUpload={handleUpload}
                selectedFiles={selectedFile}
                setSelectedFiles={setSelectedFile}
                label={'Загрузить смету проекта'}
                multiple={false}
            />
        </div>
    );
};

export default UploadOrderEstimateFileForm;