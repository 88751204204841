import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const unitRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/user/units'

export default class UnitService {

    static async getAll() {
        return axios.get(unitRestUrl);
    }

    static async update(data) {
        await axios.post(unitRestUrl + '/' + +data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(unitRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(unitRestUrl + '/' + id);
    }
}