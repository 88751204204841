import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import HeaderService from "../../../../../../API/orders/orders/organization/HeaderService";
import CellCheckboxMHTUnable from "./CellCheckboxMHTUnable";
import CellCheckboxMHTUnableModels from "./CellCheckboxMHTUnableModels";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";

const ModelHeadersTable = () => {

    const [data, setData] = useState([]);
    const [initValue, setInitValue] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [change, setChange] = useState(null);

    useEffect(() => {
        HeaderService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleClose = () => {
        setInitValue({})
        setModalOpen(false)
    }

    const handleSubmit = (values) => {
        HeaderService.save(values).then(function (response) {
            data.push(response.data);
            setData([...data]);
        })
            .catch(function (error) {
                console.log(error);
            }).finally(handleClose)
    };

    const handleUpdate = (values) => {
        HeaderService.update(values).then(function () {
            setData(prev => prev.map(el => el.id === values.id ? values : el))
        })
            .catch(function (error) {
                console.log(error);
            }).finally(handleClose)
    };

    const handleEditRow = (row) => {
        setInitValue(row)
        setModalOpen(true)
    }
    const handleDeleteRow = useCallback(
        (row) => {
            HeaderService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 400,
            },
            {
                id: 'enabled',
                header: 'Статус',
                accessorKey: 'enabled',
                minSize: 100,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxMHTUnable
                    cell={cell}
                    id={data[row.index].id}
                    change={change}
                    setChange={setChange}
                >
                </CellCheckboxMHTUnable>,
            },
            {
                id: 'enabledModels',
                header: 'Для моделей',
                accessorKey: 'enabledModels',
                minSize: 100,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxMHTUnableModels
                    cell={cell}
                    id={data[row.index].id}
                    change={change}
                    setChange={setChange}
                >
                </CellCheckboxMHTUnableModels>,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditRow},
        {type: "delete", text: "Удалить", handler: handleDeleteRow}
    ]
    return (
        <div>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>Заголовки заказов</Typography>
                <ButtonCreate
                    text={"Создать найменование"}
                    width={255}
                    onClick={() => setModalOpen(true)}
                />
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: "white",
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey
                                },
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                state={{showGlobalFilter: true}}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })
                }
                initialState={{
                    sorting: [{id: 'name', desc: false}],
                    columnVisibility: {
                        id: false,
                    },
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />
            <ModalHeaders
                title={initValue?.id ? "Изменить найменование" : "Создать найменование"}
                initValue={initValue}
                open={modalOpen}
                onClose={handleClose}
                onSubmit={initValue?.id ? handleUpdate : handleSubmit}
            />
        </div>
    );
};

export const ModalHeaders = ({open, title, initValue, onClose, onSubmit}) => {
    const [formState, setFormState] = useState({})
    const [validationState, setValidationState] = useState({name: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleValidationData = () => {
        const newErrors = {...validationState}
        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit(formState)
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    name={"name"}
                    value={formState?.name}
                    label="Наименование"
                    setCurrValues={setFormState}
                    validationError={validationState?.name}
                    setValidationState={setValidationState}
                />
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Сохранить"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
};

export default ModelHeadersTable;