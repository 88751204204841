import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {constantsErrorMessages} from "../../../../Constants";
import CustomerRequestsCuttingEstimateItem from "../../../customer/orders/CustomerRequestsCuttingEstimateItem";
import CustomerDetailsTable from "../../../../components/UI/table/customer-detales/CustomerDetailsTable";
import EstimateItem from "../estimate/EstimateItem";
import CustomerRequestsCuttingService
    from "../../../../API/orders/optimization/customer/CustomerRequestsCuttingService";
import JobService from "../../../../API/orders/job/JobService";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";

const OrderCloudItem = () => {
        const pathname = useLocation().pathname
        const params = useParams();
        const [tab, setTab] = useState(6);
        const {numberCustomer, orderId} = params;

        const [data, setData] = useState([]);
        const [unable, setUnable] = useState({unableTurn: {}, unablePackage: {}});
        const [isLoading, setIsLoading] = useState(true);
        const [isError, setIsError] = useState(false);
        const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'
        const [technicalMapData, setTechnicalMapData] = useState([]);

        useEffect(() => {
            JobService.getJobsByOrderId(orderId).then(function (response) {
                setTechnicalMapData(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleError = (error) => {
            setIsError(true)
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }

        useEffect(() => {
            setIsLoading(true)
            // DetailControllerService.getAllByOrderId(orderId)
            //     .then(function (response) {
            //         const data = response?.data
            //         setData(data);
            //         setUnable(({unableTurn, unablePackage}) => {
            //             data.forEach(row => {
            //                 unableTurn[row.id] = row.turn;
            //                 unablePackage[row.id] = row.package;
            //             })
            //             return {unableTurn, unablePackage}
            //         })
            //         setIsError(false)
            //     })
            //     .catch(function (error) {
            //         handleError(error)
            //     })
            //     .finally(() => setIsLoading(false))
        }, []);

        const handleChangeTab = (tab) => {
            setTab(tab)
            EditLocalStoragePagesData(pathname, "tab", tab)
        }
        const generalTabComponents = [
            {
                tabIndex: 1, label: "Файлы",
                component: <Box
                    sx={{display: ' block', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                    {/*   <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'проекта'}
                                label={'Загрузить файлы проекта'}
                                getAllFile={FileService.getAllProjectFileNamesByOrderId}
                                fileNameType={'PROJECT'}
                            />
                            <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'замера'}
                                label={'Загрузить файлы замера'}
                                getAllFile={FileService.getAllMeasuringFileNamesByOrderId}
                                fileNameType={'MEASURING'}
                            />*/}
                </Box>
            },
            {
                tabIndex: 2, label: "Заявки на раскрой",
                component: <CustomerRequestsCuttingEstimateItem
                    getAll={CustomerRequestsCuttingService.getAllByCustomerOrderId}
                    isOptimization={false}
                    isRedirectRequestsCutting={false}
                    orderId={orderId}
                    /* nameOrder={nameOrder}*/
                    isOrder={true}
                    isButtonAddRequestsCutting={true}
                    isEstimate={true}
                    isCreateOptimizationCalculation={false}
                    isSetForOptimization={false}
                />
            },
            {
                tabIndex: 3, label: "Обработка",
                component: <CustomerDetailsTable
                    data={data}
                    unableTurn={unable.unableTurn}
                    unablePackage={unable.unablePackage}
                    pageSize={100}
                    isError={isError}
                    setData={setData}
                    enableEditing={true}
                    orderId={orderId}
                />
            },
            {
                tabIndex: 4, label: "Кромки",
                component: <></>
            },
            {
                tabIndex: 5, label: "Фурнитура",
                component: <></>
            },
            {
                tabIndex: 6, label: "Общая смета",
                component: <EstimateItem
                    orderId={orderId}
                    isButtonPurchase={true}
                    enableEditing={true}
                    isActual={true}
                    inWork={true}
                    orderCloud={true}
                    handleError={handleError}
                />
            },]
        return (
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={`Заказ: ${numberCustomer}`}
                    path={[{name: "Главная", href: "/"},
                        {name: "Заказы из облака", href: "/organization/orders-cloud"},
                    ]}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Заказ: {numberCustomer}
                    </Typography>
                    <ButtonGroupTabBar activeTab={tab}>
                        {generalTabComponents.map(({tabIndex, label}, index) => {
                            return <Button key={tabIndex} tabIndex={tabIndex} onClick={() => handleChangeTab(tabIndex)}>
                                <CheckIcon/>
                                {label}
                            </Button>
                        })}
                    </ButtonGroupTabBar>
                    {generalTabComponents.find(component => component.tabIndex === tab)?.component}
                </Box>
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
        );
    }
;

export default OrderCloudItem;
