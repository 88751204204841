import React, {useEffect, useMemo, useState} from "react";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";
import {constantsErrorMessages} from "../../../Constants";
import Box from "@mui/material/Box";
import ButtonCreate from "../../../components/UI/button/ButtonCreate";
import {MaterialReactTable} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import AddGroupModal from "../../customer/samples/groups/AddGroupModal";
import GroupMaterialService from "../../../API/orders/orders/organization/GroupMaterialService";
import {Button, Paper, Typography} from "@mui/material";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";
import ButtonSelectAction from "../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../components/UI/modal/ModalSelectAction";
import selectionTableUtil from "../../../utils/common/SelectionTableUtil";
import ModalAddJob from "../../../components/UI/table/prodaction/jobmetricorganization/ModalAddJob";
import MaterialOrganizationOptionService
    from "../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import AdminGroupMaterialMaterialCloudService
    from "../../../API/material-cloud/admin/AdminGroupMaterialMaterialCloudService";
import MaterialService from "../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";

const MaterialGroups = ({itemId, admin, handleError}) => {
    const pathname = useLocation().pathname
    const [groupMap, setGroupMap] = useState(null);
    const [filterGroup, setFilterGroup] = useState(new Map());
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);
    const [openSelection, setOpenSelection] = useState(false)
    const rowInitValue = {name: "", outputPriority: "", currentGroup: null}
    const [data, setData] = useState([]);
    const [dataTree, setDataTree] = useState([]);
    const [rowSelection, setRowSelection] = useState([]);
    const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [isNew, setIsNew] = useState(true);
    const [modalAddJob, setModalAddJob] = useState(false);
    const [deleteJob, setDeleteJob] = useState(false);
    const api = admin ? AdminGroupMaterialMaterialCloudService : GroupMaterialService
    const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 30,
        totalElements: 0,
        searchData: ""
    })

    const [modalOpen, setModalOpen] = useState(false)

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        api.getAllForSettings().then((response) => {
            handleEditTreeGroup(response.data)
        }).catch(function (error) {
            handleError(error)
        });
    }, []);

    const handleEditTreeGroup = (newData) => {
        setData(newData);
        const buildTreeGroups = GroupMaterialUtil.buildTreeGroupsSettings(newData);
        setDataTree(buildTreeGroups)
        setTableOptions((prev) => ({...prev, totalElements: buildTreeGroups?.length}))
        const groupMap = new Map();
        GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
        setGroupMap(groupMap);
    }

    const handleClose = () => {
        setModalOpen(false)
        setCurrentEditRowData(rowInitValue)
        setIsNew(true)
    }
    const handleEditGroup = (row) => {
        setCurrentEditRowData({name: row.name, outputPriority: row?.outputPriority, currentGroup: row.parent || {}})
        setCurrentEditRowId(row.id);
        setIsNew(false);
        setModalOpen(true)
    };

    const handleAddGroup = (value) => {
        api.save(value).then((response) => {
            handleEditTreeGroup([...data, response.data])
        }).catch(function (error) {
            handleError(error)
        }).finally(handleClose)
    }

    const handleUpdate = (value) => {
        api.update(currentEditRowId, value)
            .then(function (response) {
                handleEditTreeGroup([...data].map(el => el.id === currentEditRowId ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            }).finally(handleClose)
    }

    const handleDeleteGroup = (row) => {
        api.delete(row.id)
            .then(function () {
                handleEditTreeGroup([...data].filter(el => el.id !== row.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };
    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 600
            },
            {
                header: 'Приоритет',
                accessorKey: 'outputPriority',
                size: 10
            },
            {
                header: 'Родительская группа',
                accessorFn: (row) => row.parent?.name,
                minSize: 600,
                Cell: ({cell}) => {
                    {
                        return cell.getValue() || "Группа является корневой"
                    }
                }
            },
        ]
        return baseColumns;
    }, [data]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditGroup},
        {type: "delete", text: "Удалить", handler: handleDeleteGroup}
    ]
    const handleSelectAction = (type) => {
        type === "delete" && setDeleteJob(true)
        setModalAddJob(true)
        setOpenSelection(false)
    }
    const modalActions = [
        {name: "Добавить работу на группу", type: "add"},
        {name: "Удалить работу c группы", type: "delete"},
    ]
    const handleSubmitAddJob = (ids, addedData, orderJobType) => {
        if (admin) {
            const api = deleteJob ? MaterialService.deleteJobMetricByMaterialGroup : MaterialService.addJobMetricByMaterialGroup
            api({
                "firstIds": ids,
                "secondIds": rowSelection
            }, orderJobType).then(function (response) {
                setModalAddJob(false)
                setDeleteJob(false)
                setRowSelection([])
            }).catch(function (error) {
                handleError(error);
            });
        } else {
            const api = deleteJob ? MaterialOrganizationOptionService.deleteJobMetricByMaterialGroup : MaterialOrganizationOptionService.addJobMetricByMaterialGroup
            api({
                "firstIds": ids,
                "secondIds": rowSelection
            }).then(function (response) {
                setModalAddJob(false)
                setDeleteJob(false)
                setRowSelection([])
            }).catch(function (error) {
                handleError(error);
            });
        }

    }

    const [expanded, setExpanded] = useState({})

    const handleRowExpanded = (updater) => {
        if (Object.prototype.toString.call(updater) === '[object Function]') {
            const expandedRows = Object.entries(updater(expanded)).map(([key, value]) => +key)
            const calcExpandedRows = (dataTree, expandedRows) => {
                let res = 0
                return dataTree.forEach(el => {
                    res += 1
                    if (expandedRows.includes(el)) {
                        calcExpandedRows(el, expandedRows)
                    } else {
                        res += 1
                    }
                })
                return res
            }
            setTableOptions((prev) => ({...prev, totalElements: calcExpandedRows(dataTree, expandedRows)}))
            setExpanded(updater(expanded));
        } else if (typeof updater === "boolean") {
            setTableOptions((prev) => ({...prev, totalElements: data?.length}))
            setExpanded(updater);
        } else {
            setTableOptions((prev) => ({...prev, totalElements: dataTree?.length}))
            setExpanded(updater);
        }
    }

    return <Box>
        <Box
            sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
        >
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>Группы материалов</Typography>
            <ButtonCreate
                onClick={setModalOpen}
                variant="contained"
                width={170}
                text={"Cоздать группу"}
            />
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "50px",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",

                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.primary.light,
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light
                            },
                        },
                    })
                })
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
                'mrt-row-expand': {
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
                'mrt-row-select': {
                    size: "50px",
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={dataTree}
            rowHeight={13}
            columnSpacing={1}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            state={{
                showAlertBanner: rowSelection.length > 0,
                expanded: expanded,
                rowSelection: currentSelection,
                showGlobalFilter: true,
                pagination: tableOptions,
            }}
            muiToolbarAlertBannerProps={{
                sx: {
                    backgroundColor: (theme) => theme.palette.primary.light,
                }
            }}
            renderToolbarAlertBannerContent={() => {
                return <Box sx={{padding: "0 20px", display: "flex", alignItems: "center", gap: "20px"}}>
                    <Typography>{`${rowSelection.length} из ${data.length} строк выбрано`}</Typography>
                    <Button onClick={() => setRowSelection([])}>Очистить выбор</Button>
                </Box>
            }}
            onRowSelectionChange={onRowSelectionChange}
            enableRowSelection={true}
            getRowId={(originalRow) => originalRow.id}
            enableExpanding={true}
            onExpandedChange={(updater) => handleRowExpanded(updater)}
            getSubRows={(originalRow) => originalRow.subGroups}
            selectionPadding={1}
            rowCount={tableOptions.totalElements}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
        />
        {Object.keys(rowSelection).length !== 0 && <Paper sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: '100%',
            height: "88px",
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.primary.lightGrey,
        }} component="footer">
            <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpenSelection(true)}/>
        </Paper>}
        <ModalSelectAction
            open={openSelection}
            reset={() => setOpenSelection(false)}
            submit={handleSelectAction}
            modalActions={modalActions}
        />
        {modalAddJob && <ModalAddJob
            open={modalAddJob}
            onClose={() => setModalAddJob(false)}
            onSubmit={handleSubmitAddJob}
            handleError={handleError}
            group={true}
            admin={admin}
            deleteJob={deleteJob}
        />}
        <AddGroupModal
            title={isNew ? "Добавить группу" : "Изменить группу"}
            buttonText={isNew ? "Добавить" : "Изменить"}
            dataTree={dataTree}
            groupMap={groupMap}
            open={modalOpen}
            onSubmit={isNew ? handleAddGroup : handleUpdate}
            onClose={handleClose}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            changeFilterGroup={changeFilterGroup}
            setChangeFilterGroup={setChangeFilterGroup}
            initValue={currentEditRowData}
            priorityField={true}>
        </AddGroupModal>
    </Box>
}
export default MaterialGroups