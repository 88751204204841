import React from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import Typography from "@mui/material/Typography";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";

const RequestsCuttingsAll = () => {

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Заявки на раскрой`}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заказы", href: "/organization/orders"},
                ]}/>
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                Заявки на раскрой
            </Typography>
            <RequestsCuttingTable
                getAll={RequestsCuttingService.getAllWithMaterialNotArchive}
                isOptimization={true}
                isRedirectRequestsCutting={false}
                isButtonAddRequestsCutting={false}
                isOrder={true}
                isPlan={true}
                isAddOptimization={true}
                isCreateOptimization={true}
                isRedirectPlans={true}
                isRedirectOptimizations={true}
                isRedirectOrders={true}
                page={'RequestsCuttingsAll'}
                isCalculation={false}
                isDeleteOptimization={true}
            />
        </Box>
    );
};

export default RequestsCuttingsAll;