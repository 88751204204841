import {blue, red, purple, indigo, lightBlue, green, yellow, orange} from '@mui/material/colors';

export const prod = false
export const gatewayUrl = prod ? "https://upme.by" : "http://t.upme.by"
export const authorization = prod ? "https://upme.by" : "http://t.upme.by"
export const logout = authorization + "/logout/oauth"
export const apiVersionMaterialCloud = "/api/v1"
export const apiVersionAuthorizationServer = "/api/v1"
export const apiVersionOrders = "/api/v1"
export const apiVersionDetail = "/api/v1"
export const apiVersionModelEstimate = "/api/v1"
export const apiVersionCutting = "/api/v1"
export const apiVersionUtility = "/api/v1"
export const nameAuthorizationServer = '/upme-authorization-server'
export const nameOrders = '/upme-orders'
export const nameMaterialCloud = '/upme-material-cloud'
export const nameDetail = '/upme-detail'
export const modelEstimate = '/upme-model-estimate'
export const nameCutting = '/upme-cutting'
export const utility = '/upme-utility-service'
export const userRegistration = `http://authorization.t.upme.by:9000/user-registration?createOrganization=true`

export const authorizedOrganization = ['ROLE_ORGANIZATION_USER', 'ROLE_ORGANIZATION_SUPER_USER']
export const authorizedAdmin = ['ROLE_ADMIN', 'SYSTEM_ADMIN']

export const offerWaitingTime = 60 //minutes
export const constantsOfInputValues = {
    MAX_LENGTH: 2800,
    MAX_WIDTH: 2070
}
export const constantsErrorMessages = {
    401: "доступ запрещён",
    403: "доступ запрещён",
    404: "нет связи с сервером"
}
export const COLORS = {
    "1": red,
    "2": purple,
    "3": indigo,
    "4": lightBlue,
    "5": green,
    "6": yellow,
    "7": orange
}

export const SIDE = [
    {
        value: 'TOP',
        label: 'Верхняя грань',
    },
    {
        value: 'BOTTOM',
        label: 'Нижняя грань',
    },
    {
        value: 'FRONT',
        label: 'Передний торец',
    },
    {
        value: 'BACK',
        label: 'Задний торец',
    },
    {
        value: 'LEFT',
        label: ' Левый торец',
    },
    {
        value: 'RIGHT',
        label: 'Правый торец',
    },
]
