import React, {useEffect, useState} from 'react';
import WorkerTable from "../../../../components/UI/table/orders/organization/workerTable/WorkerTable";
import WorkerService from "../../../../API/orders/job/WorkerService";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";

const Workers = ({itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});
    const [unableProduction, setUnableProduction] = useState({});
    const [unableConstructor, setUnableConstructor] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        WorkerService.getAll().then(function (response) {
            let data = response.data;
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
            data.forEach((row) => {
                unable[row.id] = row.enabled;
                unableProduction[row.id] = row.production;
                unableConstructor[row.id] = row.constructor;
            });
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);
    return (
        <div>
            <WorkerTable
                data={data} setData={setData}
                unable={unable} setUnable={setUnable}
                unableProduction={unableProduction}
                setUnableProduction={setUnableProduction}
                unableConstructor={unableConstructor}
                setUnableConstructor={setUnableConstructor}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}/>
        </div>
    );
};

export default Workers;