import React, {useEffect, useState} from 'react';
import PackagingUnitTable from "../../../../components/UI/table/model-estimate/packaging/PackagingUnitTable";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import PackagingUnitService from "../../../../API/model-estimate/packaging/PackagingUnitService";

const PackagingUnit = ({itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    useEffect(() => {
        getStartPageSize()
        PackagingUnitService.getAll().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    return (
        <div>
            <PackagingUnitTable
                handleError={handleError}
                data={data}
                setData={setData}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}/>
        </div>
    );
};

export default PackagingUnit;