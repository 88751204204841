import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import React, {useEffect, useState} from "react";

import Box from "@mui/material/Box";
import UserSupplierMaterialService from "../../../../../../API/orders/warehouse/material/UserSupplierMaterialService";
import SupplierTable from "../../supplier/SupplierTable";
import ArrivalMaterialTextField from "../arrival/ArrivalMaterialTextField";
import TextFieldObjectWithSupplierMaterialList from "./TextFieldObjectWithSupplierMaterialList";
import MaterialPriceUnitUtil from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import {setMaterialPriceUnit} from "../arrival/ModalAddMaterialPriceUnitModule";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import ButtonBack from "../../../../button/ButtonBack";
import Typography from "@mui/material/Typography";

const steps = [
    'Постащик',
    'Тип поставки',
    'Размеры',
];

function setMaterialPriceUnitByFound(foundMaterial, dataFormat, id, setIsFixedStripe, setIsFixedWhole,
                                     baseCurrency, alternativeCurrency, setPrice, currencyMap) {
    if (foundMaterial) {
        dataFormat.supplierMaterial = foundMaterial;
        dataFormat.supplierMaterialId = id;
        setIsFixedStripe(foundMaterial.typeSupplierMaterial === "BY_FIXED_STRIPE");
        setIsFixedWhole(foundMaterial.typeSupplierMaterial === "WHOLE")
        const basePrice = foundMaterial.basePrice
        const currentCurrency = currencyMap.get(basePrice.currencyId).curAbbreviation;
        setMaterialPriceUnit(basePrice, setPrice,
            currentCurrency, baseCurrency,
            alternativeCurrency,
            foundMaterial,
            null,
            true,
            dataFormat.materialPriceUnit,
            false,
            true,
            currencyMap)

    } else {
        console.warn(`Material with id ${id} not found`);
    }
}

function getAndSetSupplierMaterials(data, supplier, setSupplierMaterials, dataFormat, setIsFixedStripe, setIsFixedWhole,
                                    baseCurrency, alternativeCurrency, setPrice, currencyMap) {
    const supplierMaterials = data.get(supplier.id);
    setSupplierMaterials(supplierMaterials);
    setMaterialPriceUnitByFound(supplierMaterials[0], dataFormat,
        supplierMaterials[0].id, setIsFixedStripe, setIsFixedWhole,
        baseCurrency, alternativeCurrency, setPrice, currencyMap);
}

const ModalAddWarehouseMaterial = ({
                                       open, onClose, title, materialId,
                                       supplierMaterials, setSupplierMaterials,
                                       dataSuppliers, setDataSuppliers,
                                       baseCurrency, alternativeCurrency,
                                       handleSubmitCreate,
                                       currencyMap
                                   }) => {


    const [activeStep, setActiveStep] = useState(0)
    const [data, setData] = useState([])
    const [isFixedStripe, setIsFixedStripe] = useState(false)
    const [isFixedWhole, setIsFixedWhole] = useState(false)
    const [price, setPrice] = useState([])

    const [dataFormat, setDataFormat] = useState({
        supplier: null,
        supplierMaterialId: null,
        supplierMaterial: null,
        materialPriceUnit: MaterialPriceUnitUtil.materialPriceUnit
    })

    const setCurrentSupplier = (value) => {
        const newData = data.get(value.id)[0]
        setDataFormat(prev => ({...prev, supplier: newData.supplier}))
    }

    useEffect(() => {
        UserSupplierMaterialService.getAllByMaterialOrganizationOption(materialId)
            .then(response => {
                const rawData = response.data;
                const supplierMap = new Map();
                rawData.forEach(item => {
                    const supplierKey = item.supplier.id;
                    if (!supplierMap.has(supplierKey)) {
                        supplierMap.set(supplierKey, []);
                    }
                    supplierMap.get(supplierKey).push(item);
                });
                setData(supplierMap);
                const suppliersArray = Array.from(supplierMap.values()).map(items => items[0].supplier);
                setDataSuppliers(suppliersArray);
                dataFormat.supplier = suppliersArray[0];
                getAndSetSupplierMaterials(supplierMap, suppliersArray[0], setSupplierMaterials, dataFormat,
                    setIsFixedStripe, setIsFixedWhole, baseCurrency, alternativeCurrency, setPrice, currencyMap);
            })
            .catch(error => {
                console.log(error);
            });
    }, [open, materialId, baseCurrency, alternativeCurrency]);

    const submit = () => {
        if (activeStep < 2) {
            setActiveStep(activeStep + 1);
        } else {
            handleSubmitCreate(dataFormat);
            setActiveStep(0);
        }
    }

    const reset = () => {
        setActiveStep(0)
        onClose()
    }

    const back = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        } else {
            onClose();
        }
    }

    const selectSupplier = (supplier) => {
        dataFormat.supplier = supplier;
        getAndSetSupplierMaterials(data, supplier, setSupplierMaterials, dataFormat,
            setIsFixedStripe, setIsFixedWhole, baseCurrency, alternativeCurrency, setPrice, currencyMap);
    }


    const setField = (value) => {
        dataFormat.materialPriceUnit = value
    }

    const setSupplierMaterial = (id) => {
        const foundMaterial = supplierMaterials.find(material => material.id.toString() === id);
        setMaterialPriceUnitByFound(foundMaterial, dataFormat,
            id, setIsFixedStripe, setIsFixedWhole, baseCurrency, alternativeCurrency, setPrice, currencyMap);
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${activeStep === 0 ? "40vw" : "700px"}`,
                height: `${activeStep === 0 ? "800px" : activeStep === 1 ? "500px" : "650px"}`
            }
        }}>
            <Box style={{
                padding: 40,
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{title}</DialogTitle>
                <Stepper
                    style={{width: 600}}
                    activeStep={activeStep}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                }
                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box sx={{
                height: "32px",
                fontSize: "18px",
                margin: "0px 40px 0px 40px",
                display: "flex",
                alignItems: "center",
                borderBottom: dataFormat.supplier ? "#3B8BEC 1px solid" : "transparent 1px solid",
                '& .MuiTypography-root': {
                    fontWeight: (theme) => theme.typography.fontWeight,
                    color: (theme) => theme.palette.text.main,
                    '&:first-of-type': {
                        marginRight: '10px'
                    }
                },
            }}>
                <Typography
                    component="p">{dataFormat.supplier !== null && dataFormat.supplier !== undefined ? dataFormat.supplier.name : ""}</Typography>
            </Box>
            <DialogContent sx={{paddingBottom: 0}}>
                {activeStep === 0 ?
                    <Box>
                        <SupplierTable
                            data={dataSuppliers}
                            selectId={dataFormat?.supplier?.id}
                            select={setCurrentSupplier}
                        />
                    </Box>
                    : activeStep === 1 ?
                        <Box style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <Box style={{paddingTop: 20}}>
                                <TextFieldObjectWithSupplierMaterialList
                                    label="Тип поставки"
                                    values={dataFormat}
                                    field={'supplierMaterialId'}
                                    setId={setSupplierMaterial}
                                    entityList={supplierMaterials}
                                    name={'typeSupplierMaterial'}
                                />
                            </Box>
                        </Box> :
                        <Box>
                            <Stack
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '0.01rem',
                                }}
                            >
                                <ArrivalMaterialTextField
                                    label="Количество"
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'quantity'}
                                    disabled={isFixedStripe}
                                />
                                <ArrivalMaterialTextField
                                    label="Ширина"
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'length'}
                                    disabled={isFixedWhole || isFixedStripe}
                                />
                                <ArrivalMaterialTextField
                                    label="Высота"
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'width'}
                                    disabled={isFixedWhole || isFixedStripe}
                                />
                                <ArrivalMaterialTextField
                                    label={`Сумма в ${price}`}
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'amountInCurrencyArrival'}
                                    disabled={true}
                                />
                            </Stack>
                        </Box>
                }
            </DialogContent>

            <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep !== 0 && <ButtonBack text={"Назад"} onClick={back}/>} </Box>
                <Box>
                    <ButtonExit text={"Выйти"} onClick={reset}/>
                    <ButtonAdd text={"Выбрать"}
                               disabled={activeStep === 2 && dataFormat.materialPriceUnit.supplierMaterial == null}
                               onClick={submit}/>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
export default ModalAddWarehouseMaterial