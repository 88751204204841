import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import MaterialStripeTable
    from "../../../../../../components/UI/table/warehouse/material/materialStripe/MaterialStripeTable";
import UserSupplierMaterialService from "../../../../../../API/orders/warehouse/material/UserSupplierMaterialService";
import {BreadcrumbsComponent} from "../../../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const OrganizationMaterialMinStripeArchiveItem = () => {

    const [data, setData] = useState([]);
    const params = useParams();
    const materialId = params.materialId
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState, currTab) => {
        setTableOptions(nextState)
    }
    useEffect(() => {

        UserSupplierMaterialService.getAllByMaterialIdMinStripe(materialId)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Архив цен"}
                path={[{name: "Главная", href: "/"}, {name: "Материалы", href: "/organization/materials"}]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Архив цен
                    </Typography>
                </Box>
            </Box>
            <MaterialStripeTable
                data={data}
                setData={setData}
                id={params.id}
                materialId={materialId}
                baseUnit={params.baseUnit}
                isArchive={true}
                typeMaterial={params.typeMaterial}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
        </Box>
    );
};

export default OrganizationMaterialMinStripeArchiveItem;