import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_ToggleFiltersButton} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography} from "@mui/material";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {
    rulesModifyingAvailableField,
    rulesModifyingOptimizationMethod
} from "../../../../../data/RulesModifyingOptimizationMethod";
import TextFieldValidation from "../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";
import {rulesModifyingOptimizationInputFields} from "../../../../../data/RulesModifyingOptimizationInputFields";
import RulesModifyingOptimizationService from "../../../../../API/details/RulesModifyingOptimizationService";

const RulesModifyingOptimizationTable = ({
                                             data,
                                             setData,
                                             tableOptions,
                                             tableOptionsHandle,
                                             handleError
                                         }) => {

        const initValue = {
            "name": "",
            "position": "",
            "method": Object.keys(rulesModifyingOptimizationMethod)[0],
            "lesThan": "",
            "newPosition": "",
            "plusPosition": "",
            "plusOldLength": true,
            "plusOldWidth": true,
            "newSizeLength": "",
            "newSizeWidth": "",
            "priority": 0,
            "l1": "",
            "l1Name": "",
            "l2": "",
            "l2Name": "",
            "w1": "",
            "w1Name": "",
            "w2": "",
            "w2Name": "",

        }
        const [modalOpen, setModalOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(initValue);

        const handleDeleteRow = (row) => {
            RulesModifyingOptimizationService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        }

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleClose = () => {
            setModalOpen(false)
            setCurrentEditRowData(initValue)
        };

        const handleUpdate = (value) => {
            const {id, ...data} = value
            RulesModifyingOptimizationService.update(data, id).then(function (response) {
                handleClose()
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
                .catch(function (error) {
                    handleError(error);
                });
        };
        const handleSubmit = (data) => {
            RulesModifyingOptimizationService.create(data).then(function (response) {
                handleClose()
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: response.data.length}))
            })
                .catch(function (error) {
                    handleError(error);
                });
        };


        const columns = useMemo(
                () => [
                    {
                        id: 'name',
                        header: 'Найменование',
                        accessorKey: 'name',
                    },
                    {
                        id: 'position',
                        header: 'Позиция',
                        accessorKey: 'position',
                    },
                    {
                        id: 'method',
                        header: 'Метод',
                        accessorKey: 'method',
                        Cell: ({cell}) => rulesModifyingOptimizationMethod[cell.getValue()],
                        minSize: 80
                    },
                    {
                        id: 'lesThan',
                        header: 'Меньше чем',
                        accessorKey: 'lesThan',
                    },
                    {
                        id: 'newPosition',
                        header: 'Новая позиция',
                        accessorKey: 'newPosition',
                    },
                    {
                        id: 'plusPosition',
                        header: 'Плюс позиция',
                        accessorKey: ' plusPosition',
                    },
                    {
                        id: 'plusOldLength',
                        header: 'Плюс мм к длине',
                        accessorKey: 'plusOldLength',
                    },
                    {
                        id: 'plusOldWidth',
                        header: 'Плюс мм к ширине',
                        accessorKey: 'plusOldWidth',
                    },

                    {
                        id: 'newSizeLength',
                        header: 'Новый размер по длине',
                        accessorKey: 'newSizeLength',
                    },

                    {
                        id: 'newSizeWidth',
                        header: 'Новый размер по ширине',
                        accessorKey: 'newSizeWidth',
                    },
                    {
                        id: 'l1',
                        header: 'Новый l1',
                        accessorKey: 'l1',
                    },
                    {
                        id: 'l1Name',
                        header: 'Новый l1Name',
                        accessorKey: 'l1Name',
                    },
                    {
                        id: 'l2',
                        header: 'Новый l2',
                        accessorKey: 'l2',
                    },
                    {
                        id: 'l2Name',
                        header: 'Новый l2Name',
                        accessorKey: 'l2Name',
                    },
                    {
                        id: 'w1',
                        header: 'Новый w1',
                        accessorKey: 'w1',
                    },
                    {
                        id: 'w1Name',
                        header: 'Новый w1Name',
                        accessorKey: 'w1Name',
                    },
                    {
                        id: 'w2',
                        header: 'Новый w2',
                        accessorKey: 'w2',
                    },
                    {
                        id: 'w2Name',
                        header: 'Новый w2Name',
                        accessorKey: 'w2Name',
                    },
                    {
                        id: 'priority',
                        header: 'Приоритет',
                        accessorKey: 'priority',
                    },
                ],
                [data, setData],
            )
        ;
        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow}
        ]

        return (
            <Box sx={{maxWidth: "calc(100vw - 450px)"}}>
                <Box
                    sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
                >
                    <Typography sx={{fontSize: "24px", fontWeight: 700}}>Правила модификации</Typography>
                    <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>

                        <ButtonCreate
                            text={"Добавить"}
                            width={130}
                            onClick={() => setModalOpen(true)}
                        />
                    </Box>
                </Box>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='ru'
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <MaterialReactTable
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: 0
                                }
                            }
                        }}

                        muiTableBodyCellProps={({cell, table}) => {
                            return ({
                                sx: () => ({
                                    p: '0px 0px 0px 10px',
                                    m: '0px 0px 0px 0px',
                                    border: "none",
                                    "& td:after": {
                                        "&:hover": {
                                            backgroundColor: "red"
                                        }
                                    },
                                    "&:not(:last-of-type)": {
                                        borderRight: '1px solid #EAEBEA',
                                    },
                                    "&": cell.column.id === "requests_cutting" && {
                                        width: 'calc(100% - 1px)',
                                        padding: 0,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    },
                                    "&:first-of-type": {
                                        padding: 0,
                                        width: "50px",
                                        height: "36px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: (theme) => theme.palette.text.light,
                                        "& .MuiButtonBase-root": {
                                            borderRadius: "4px",
                                            width: "24px",
                                            height: "24px",
                                            opacity: 1,
                                            margin: 0,
                                            padding: 0,
                                            "&:hover": {
                                                backgroundColor: (theme) => theme.palette.primary.stroke,
                                            }
                                        }
                                    },

                                    "& a": {
                                        color: "#006CCC",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline"
                                        }

                                    }
                                })
                            })
                        }}

                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            sx: {
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: "",
                                size: 3,
                                minSize: 3,
                                grow: false,
                            },
                        }}
                        columns={columns}
                        data={data}
                        rowHeight={13}
                        columnSpacing={1}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{showGlobalFilter: true, pagination: tableOptions,}}
                        rowCount={tableOptions.totalElements}
                        renderTopToolbarCustomActions={({table}) => (
                            <Box sx={{
                                width: "100%",
                                padding: 0,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItem: "center"
                            }}>
                                <MRT_ToggleFiltersButton table={table}/>
                            </Box>)}
                        initialState={{
                            columnVisibility: {
                                id: false,
                            },
                            isFullScreen: false,
                            density: "compact",
                            pagination: {pageIndex: 0, pageSize: 50},
                        }}
                        renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                            return <MenuItem
                                key={el.type}
                                onClick={() => {
                                    el.handler(row.original)
                                    closeMenu();
                                }}
                                sx={{
                                    margin: "0 8px",
                                    borderRadius: "4px",
                                    "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                                }}
                            >
                                {el.text}
                            </MenuItem>
                        })}
                        localization={MRT_Localization_RU}
                        enableRowActions={true}
                        enableToolbarInternalActions={false}
                    />
                </LocalizationProvider>
                <RulesModifyingOptimizationModal
                    title={currentEditRowData?.id ? "Изменить" : "Добавить"}
                    open={modalOpen} onClose={handleClose}
                    handleSubmit={currentEditRowData?.id ? handleUpdate : handleSubmit}
                    initValue={currentEditRowData}/>
            </Box>
        );
    }
;
const RulesModifyingOptimizationModal = ({open, onClose, handleSubmit, initValue, title}) => {
    const [formState, setFormState] = useState(initValue)

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleSetFormState = (getData) => {
        const [currKey, currValue] = Object.entries(getData())[0]
        if (currKey === "method") {
            const newData = Object.fromEntries(Object.entries(formState).map(([key, value]) => {
                return key === currKey ? [currKey, currValue] : rulesModifyingAvailableField[currValue].includes(key) ? [key, value] : [key, ""]
            }))
            setFormState(newData)
        } else {
            setFormState(prev => ({...prev, [currKey]: currValue}))
        }
    }
    
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "700px",
                height: "870px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{
                    display: "flex", flexFlow: "column wrap", gap: "20px", height: 630,
                    "& div": {
                        height: "50px"
                    }
                }}>
                    {Object.entries(formState).map(([key, value]) => key !== "id" && key !== "plusOldWidth" && key !== "plusOldLength" &&
                        <TextFieldValidation
                            key={key}
                            select={key === "method"}
                            name={key}
                            value={value}
                            disabled={key !== "method" && !rulesModifyingAvailableField[formState.method].includes(key)}
                            label={rulesModifyingOptimizationInputFields[key]}
                            setCurrValues={handleSetFormState}
                            required={false}
                        >
                            {key === "method" && Object.entries(rulesModifyingOptimizationMethod).map((option) => (
                                <MenuItem key={option[0]} value={option[0]}>
                                    {option[1]}
                                </MenuItem>))}
                        </TextFieldValidation>
                    )}
                </Box>
                <Box sx={{display: "flex", gap: "20px"}}>
                    <FormControlLabel control={<Checkbox
                        checked={formState.plusOldLength === true}
                        onChange={() => setFormState(value => ({
                            ...value,
                            "plusOldLength": !value.plusOldLength
                        }))}
                    />} label="Плюс мм к длине"/>
                    <FormControlLabel control={<Checkbox
                        checked={formState.plusOldWidth === true}
                        onChange={() => setFormState(value => ({
                            ...value,
                            "plusOldWidth": !value.plusOldWidth
                        }))}
                    />} label="Плюс мм к ширине"/>
                </Box>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={initValue.id ? "Изменить" : "Добавить"} onClick={() => handleSubmit(formState)}/>
            </DialogActions>
        </Dialog>
    );
}

export default RulesModifyingOptimizationTable;