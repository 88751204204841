import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";
import OrderModelsService from "../../../../API/orders/orders/organization/ModelsService";
import {CreateNewModelInOrderModal} from "./CreateNewModelInOrderModal";

const Models = ({orderId, estimateId, handleError, setChange}) => {
    const pathname = useLocation().pathname
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        OrderModelsService.getAll(orderId).then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            data.forEach((row) => {
                unable[row.id] = row.enabled;
            });
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const handleAdd = async (data, onClose) => {
        OrderModelsService.save(estimateId, data).then(function (response) {
            setData(prev => ([...prev, response.data]))
            tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
            onClose()
            setChange(change => !change)
        })
            .catch(function (error) {
                handleError(error);
            })
    };
    const deleteModels = (row) => {
        OrderModelsService.delete(row.id, estimateId)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
                setChange(change => !change)
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
            })
            .catch(function (error) {
                handleError(error);
            })
    }
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'moduleType',
                header: 'Тип модели',
                accessorKey: 'moduleType',
                minSize: 300,
            },
            {
                id: 'originalName',
                header: 'Наименование',
                accessorKey: 'originalName',
                minSize: 300,
            },
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'articleNumber',
                minSize: 300,
            },
            {
                id: 'barcode',
                header: 'Штрих-код',
                accessorKey: 'barcode',
                minSize: 300,
            },

            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ];


        return baseColumns;

    }, [data, setData]);
    const menuItem = [
        {type: "delete", text: "Удалить", handler: deleteModels},
    ]
    return (
        <Box sx={{width: '1700px',}}>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", gap: "10px", paddingBottom: "30px"}}
            >
                <ButtonCreate
                    text={"Добавить модуль"}
                    width={190}
                    onClick={() => setCreateModalOpen(true)}
                />
            </Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '1700px',
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    sorting: [{id: 'name', desc: false}],
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })
                }
                localization={MRT_Localization_RU}
            />
            {createModalOpen && <CreateNewModelInOrderModal
                open={createModalOpen}
                handleClose={() => setCreateModalOpen(false)}
                handleSubmit={handleAdd}
                handleError={handleError}
            />}
        </Box>
    );
};

export default Models;