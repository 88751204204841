import {SimpleTreeView} from "@mui/x-tree-view/SimpleTreeView";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import React from "react";
import {MinusSquare, PlusSquare} from "../../../components/UI/icons/IconsComponents";

const TreeViewSettings = ({
                              activeSetting,
                              expandedItems,
                              handleExpandedItemsChange,
                              handleActiveItemsChange,
                              userRoles,
                              stage
                          }) => {
    return <SimpleTreeView
        aria-label="customized"
        selectedItems={[activeSetting]}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
        onItemClick={(e, itemId) => handleActiveItemsChange(itemId)}
        slots={{
            expandIcon: PlusSquare,
            collapseIcon: MinusSquare,
        }}>
        <TreeItem itemId="profile" label={"Профиль"}/>
        {userRoles.type !== "admin" && <TreeItem itemId="personalization" label={"Персонализация"}/>}
        {userRoles.type === "admin" && <TreeItem itemId="settings" label={userRoles.name}>
            <TreeItem itemId="adminMaterialSettings" label="Настройки материалов">
                <TreeItem itemId="adminMaterialGroups" label="Группы материалов"/>
                <TreeItem itemId="adminMaterial" label="Материалы"/>
                <TreeItem itemId="adminImports" label="Импорт"/>
            </TreeItem>
            <TreeItem itemId="jobSettings" label="Настройки работ">
                <TreeItem itemId="listJobPacking" label="Упаковки"/>
                <TreeItem itemId="listJobMilling" label="Фрезерование"/>
                <TreeItem itemId="listJobEdge" label="Кромление"/>
                <TreeItem itemId="listJobDrilling" label="Сверление"/>
                <TreeItem itemId="listJobCutting" label="Раскрой"/>
            </TreeItem>
            <TreeItem itemId="samplesSettings" label="Настройки шаблонов">
                <TreeItem itemId="systemSamples" label="Шаблоны системы"/>
                <TreeItem itemId="systemSamplesGroup" label="Группы"/>
                <TreeItem itemId="systemSamplesEdges" label="Кромки"/>
            </TreeItem>
            <TreeItem itemId="helpSettings" label="Настройки помощи">
                <TreeItem itemId="themeHelp" label="Темы"/>
                <TreeItem itemId="help" label="Помощь"/>
            </TreeItem>
            <TreeItem itemId="currencySettings" label="Настройки валюты">
                <TreeItem itemId={"currency"} label="Валюта"></TreeItem>
                <TreeItem itemId={"currenciesNationalBank"} label="Курсы валют"></TreeItem>
            </TreeItem>
            <TreeItem itemId="adminSuppliersSettings" label="Настройки поставщиков">
                <TreeItem itemId={"adminSuppliers"} label="Поставщики"></TreeItem>
            </TreeItem>
            <TreeItem itemId="adminManufacturerSettings" label="Настройки производителей">
                <TreeItem itemId={"adminManufacturer"} label="Производители"></TreeItem>
            </TreeItem>
            <TreeItem itemId="unitSettings" label="Настройки единиц измерения">
                <TreeItem itemId={"unit"} label="Единицы измерения"></TreeItem>
            </TreeItem>
        </TreeItem>}
        {userRoles.type === "organization" &&
            <TreeItem itemId="settings" label={userRoles.name}>
                {stage !== 1 && <TreeItem itemId="generalSettings" label="Основные настройки"/>}
                {stage < 9 && <TreeItem itemId="setupStages" label="Этапы настройки"/>}
                {stage > 3 && <TreeItem itemId="warehouseSettings" label="Настройки склада">
                    <TreeItem itemId="warehouseName" label="Наименования складов"/>
                    {stage > 7 && <>
                        <TreeItem itemId="materialGroups" label="Группы материалов"/>
                        <TreeItem itemId="material" label="Материалы"/>
                        <TreeItem itemId="imports" label="Импорт"/>
                    </>}

                </TreeItem>}
                {stage > 5 && <TreeItem itemId="jobSettings" label="Настройки работ">
                    <TreeItem itemId="listJob" label="Найменования работ"/>
                    <TreeItem itemId="listJobName" label="Найменования затрат по работам"/>
                    <TreeItem itemId="listTypeJob" label="Группы работ"/>
                    <TreeItem itemId="importJob" label="Импорт"/>
                </TreeItem>}
                {stage > 5 && <TreeItem itemId="orderSettings" label="Настройки заказа">
                    <TreeItem itemId="headers" label="Заголовки заказов"/>
                    {stage > 6 && <TreeItem itemId="attributes" label="Aтрибуты заказов"/>}
                </TreeItem>}
                {stage > 7 && <TreeItem itemId="modelSettings" label="Настройки моделей">
                    <TreeItem itemId="packagingUnits" label="Единицы упаковки"/>
                    <TreeItem itemId="packagingSets" label="Комплекты упаковки"/>
                    <TreeItem itemId="basicModels" label="Базовые модели"/>
                    <TreeItem itemId="models" label="Модели"/>
                    <TreeItem itemId="importModels" label="Импорт"/>
                </TreeItem>}
                {stage > 7 && <TreeItem itemId="workerSettings" label="Настройки сотрудников">
                    <TreeItem itemId="workers" label="Работники"/>
                </TreeItem>}
                {stage > 7 && <TreeItem itemId="organizationCustomersSettings" label="Настройки заказчиков">
                    <TreeItem itemId="organizationCustomers" label="Заказчики"/>
                </TreeItem>}
                {stage > 5 && <TreeItem itemId="organizationSuppliersSettings" label="Настройки поставщиков">
                    <TreeItem itemId="organizationSuppliers" label="Поставщики"/>
                </TreeItem>}
                {stage > 7 && <TreeItem itemId="optimizationSettings" label="Настройки оптимизации">
                    <TreeItem itemId="rulesModifyingOptimization" label="Правила модификации"/>
                </TreeItem>}
                <TreeItem itemId="adress" label="Адреса">
                    <TreeItem itemId="addressList" label="Список адресов"/>
                </TreeItem>
            </TreeItem>}

        {userRoles.type === "customer" && <TreeItem itemId="settings" label={userRoles.name}>
            <TreeItem itemId="customerSettings" label="Настройки"></TreeItem>
            <TreeItem itemId="favorite-organization" label="Избранные организации"></TreeItem>
            <TreeItem itemId="adress" label="Адреса">
                <TreeItem itemId="addressList" label="Список адресов"/>
            </TreeItem>
        </TreeItem>}


    </SimpleTreeView>
}
export default TreeViewSettings