import {TextField} from "@mui/material";
import React from "react";

const CreateOrderTextFieldObject = ({label, defaultValue, setId, field, entityList}) => {

    return (
        <div>
            <TextField
                size={"small"}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                defaultValue={defaultValue}
                name={field}
                onChange={(e) =>
                    setId(e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {entityList.map((entity) => (
                    <option
                        key={entity.id}
                        value={entity.id}>
                        {entity.name}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default CreateOrderTextFieldObject;