export default class DataUtil {

    static getListField(data, field) {
        let set = new Set();
        for (const row of data) {
            if (row[field] !== null) {
                set.add(row[field])
            }
        }
        return Array.from(new Set(set));
    }

    static getListChildField(data, field, childField) {
        let set = new Set();
        for (const row of data) {
            if (row[field] !== null && row[field] !== undefined) {
                set.add(row[field][childField])
            }
        }
        return Array.from(new Set(set));
    }
}