import Box from "@mui/material/Box";
import {BreadcrumbsComponent} from "../../components/UI/breadcrumb/BreadcrumbComponent";
import React, {useEffect, useState} from "react";
import UploadGroupOfMaterialFileForm from "../../components/UI/cloud/UploadGroupOfMaterialFileForm";
import ModelHeadersTable from "../../components/UI/table/orders/organization/header/ModelHeadersTable";
import AttributesTable from "../../components/UI/table/orders/organization/attributes/AttributesTable";
import JobMetricOrganization from "./settings/JobMetricOrganization";
import JobNameTable from "../../components/UI/table/orders/organization/job/JobNameTable";
import Workers from "../organization/orders/worker/Workers";
import PackagingUnit from "../organization/model-estimate/packaging/PackagingUnit";
import PackagingSet from "../organization/model-estimate/packaging/PackagingSet";
import BasicModels from "../organization/model-estimate/models/BasicModels";
import Customers from "../organization/orders/Customers";
import TypeJobOrganization from "./settings/TypeJobOrganization";
import AddressOrganization from "./settings/AddressOrganization";
import {EditLocalStoragePagesData} from "../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";
import TreeViewSettings from "./settings/TreeViewSettings";
import Suppliers from "../organization/orders/Suppliers";
import WorkerService from "../../API/orders/job/WorkerService";
import MaterialCloudSuppliers from "../../API/material-cloud/common/MaterialCloudSuppliers";
import {importArray} from "../../data/ImportArray";
import MaterialGroups from "./settings/MaterialGroups";
import MaterialPageItemModule from "../../components/UI/modul/MaterialPageItemModule";
import WarehouseNameTable from "../../components/UI/table/settings/WarehouseNameTable";
import ModelsSetting from "../organization/model-estimate/models/ModelsSetting";
import {constantsErrorMessages} from "../../Constants";
import ModalAlert from "../../components/UI/table/customer-detales/ModalAlert";
import Profile from "./settings/userSettings/Profile";
import OrganizationCustomerService from "../../API/orders/orders/organization/OrganizationCustomerService";
import Personalization from "./settings/userSettings/Personalization";
import CustomerSettings from "./settings/userSettings/CustomerSettings";
import RulesModifyingOptimization
    from "../organization/optimization/rulesmodifyingoptimization/RulesModifyingOptimization";
import SetupStages from "./settings/SetupStages";
import ThemeHelp from "./settings/ThemeHelp";
import HelpSetting from "./settings/HelpSetting";
import Currency from "./settings/Currency";
import CurrenciesNationalBank from "./settings/CurrenciesNationalBank";
import Unit from "./settings/Unit";
import Manufacturer from "./settings/Manufacturer";
import Samples from "../customer/samples/samples/Samples";
import SamplesGroups from "../customer/samples/groups/SamplesGroups";
import SamplesEdges from "../customer/samples/SamplesEdges";
import FaforiteOrganization from "./settings/FaforiteOrganization";

const Settings = ({stage, setStage, profile}) => {
    const pathname = useLocation().pathname
    const [activeSetting, setActiveSetting] = useState("profile")
    const [expandedItems, setExpandedItems] = React.useState([]);
    const [alertData, setAlertData] = useState({});
    const [profileSettings, setProfileSettings] = useState({})
    const [workers, setWorkers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const handleExpandedItemsChange = (event, itemIds) => {
        setExpandedItems(itemIds);
        EditLocalStoragePagesData(pathname, "expanded", itemIds)
    };
    const handleActiveItemsChange = (itemId) => {
        setActiveSetting(itemId);
        EditLocalStoragePagesData(pathname, "settingName", itemId)
    };

    let userRoles = {
        type: "unknown",
        name: ""
    }
    profile?.userRoles?.find(el => el.name === "ROLE_SYSTEM_ADMIN" || el.name === "ROLE_ADMIN"
        ? userRoles = {
            type: "admin",
            name: "Настройки системы"
        } : el.name === "ROLE_ORGANIZATION_SUPER_USER" || el.name === "ROLE_ORGANIZATION_USER_SETTINGS"
            ? userRoles = {
                type: "organization",
                name: "Настройки организации"
            } : el.name === "ROLE_CUSTOMER_USER_SETTINGS" || el.name === "ROLE_CUSTOMER_CREATOR"
                ? userRoles = {
                    type: "customer",
                    name: "Настройки заказчика"
                } : el.name === "ROLE_CUSTOMER_USER"
                    ? userRoles = {
                        type: "customerUser",
                        name: "Настройки заказчика"
                    } : el.name === "ROLE_ORGANIZATION_USER"
                        ? userRoles = {
                            type: "organizationUser",
                            name: "Настройки заказчика"
                        } : userRoles = {
                            type: "unknown",
                            name: ""
                        })

    useEffect(() => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData'))

        const settingsData = pagesData && pagesData[pathname] || {
            settingName: "profile",
            expanded: []
        }

        const {settingName, expanded} = settingsData
        setActiveSetting(settingName)
        setExpandedItems(expanded)

        OrganizationCustomerService.profileSettings().then(function (response) {
            setProfileSettings(response.data);
        })
            .catch(function (error) {
                handleError(error);
            })

        {
            userRoles?.type === "organization" && WorkerService.getWorkers().then(function (response) {
                setWorkers(response.data);
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        userRoles?.type === "organization" && MaterialCloudSuppliers.get().then(function (response) {
            setSuppliers(response.data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent page={"Настройки"} path={[{name: "Главная", href: "/"}]}/>
            <Box sx={{display: "flex", gap: "50px"}}>
                <Box sx={{minHeight: 352, minWidth: 300}}>
                    <TreeViewSettings
                        activeSetting={activeSetting}
                        expandedItems={expandedItems}
                        handleExpandedItemsChange={handleExpandedItemsChange}
                        handleActiveItemsChange={handleActiveItemsChange}
                        userRoles={userRoles}
                        stage={stage}/>
                </Box>
                <Box>
                    {(() => {
                        switch (activeSetting) {
                            case "profile":
                                return <Profile
                                    data={profileSettings} setData={setProfileSettings}
                                    handleError={handleError}/>
                            case "personalization":
                                return <Personalization
                                    role={userRoles.type}
                                    handleError={handleError}>
                                </Personalization>
                            case "customerSettings":
                                return <CustomerSettings handleError={handleError}></CustomerSettings>
                            case "generalSettings":
                                return <CustomerSettings
                                    handleError={handleError}
                                    organization={true}
                                    stage={stage}></CustomerSettings>
                            case "setupStages":
                                return <SetupStages
                                    handleError={handleError}
                                    stage={stage}
                                    handleActiveItemsChange={handleActiveItemsChange}
                                    setStage={setStage}>
                                </SetupStages>
                            case "favorite-organization":
                                return <FaforiteOrganization
                                    handleError={handleError} itemId={"favorite-organization"}/>;
                            //Склад
                            case "warehouseName":
                                return <WarehouseNameTable handleError={handleError} itemId={"warehouseName"}/>;
                            case "imports":
                                return (
                                    <Box sx={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                        {importArray.slice(0, 3).map(el => {
                                            return <UploadGroupOfMaterialFileForm
                                                key={el.id}
                                                label={'Отправить'}
                                                uploadData={el}
                                                multiple={false}
                                                handleError={handleError}
                                            />
                                        })}
                                    </Box>
                                );
                            case "headers":
                                return <ModelHeadersTable handleError={handleError}/>;
                            case "attributes":
                                return <AttributesTable handleError={handleError} itemId={"attributes"}/>;
                            //Материалы
                            case "materialGroups":
                                return <MaterialGroups handleError={handleError} itemId={"materialGroups"}/>;
                            case "material":
                                return <MaterialPageItemModule
                                    handleError={handleError}
                                    refMaterial={true}
                                    isAddMaterial={true}
                                    pageSize={30}
                                    settings={true}
                                    itemId={"material"}
                                />;
                            //Работы
                            case "listJob":
                                return <JobMetricOrganization
                                    handleError={handleError} itemId={"listJob"} settings={true}/>;
                            case "listJobName":
                                return <JobNameTable
                                    handleError={handleError} itemId={"listJobName"}/>;
                            case "listTypeJob":
                                return <TypeJobOrganization
                                    handleError={handleError}
                                    workers={workers}
                                    itemId={"listTypeJob"}/>;
                            case "importJob":
                                return (
                                    <Box sx={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                        {importArray.slice(3, 6).map(el => {
                                                return <UploadGroupOfMaterialFileForm
                                                    key={el.id}
                                                    label={'Отправить'}
                                                    uploadData={el}
                                                    multiple={false}
                                                    handleError={handleError}
                                                />
                                            }
                                        )}
                                    </Box>
                                )
                                    ;
                            //Модели
                            case
                            "packagingUnits"
                            :
                                return <PackagingUnit itemId={"packagingUnits"} handleError={handleError}/>;
                            case
                            "packagingSets"
                            :
                                return <PackagingSet itemId={"packagingSets"} handleError={handleError}/>;
                            case
                            "basicModels"
                            :
                                return <BasicModels handleError={handleError} itemId={"basicModels"}/>;
                            case
                            "models"
                            :
                                return <ModelsSetting itemId={"models"} handleError={handleError}/>;
                            case "importModels":
                                return (
                                    <Box sx={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                        {importArray.slice(6, 11).map(el => {
                                            return <UploadGroupOfMaterialFileForm
                                                key={el.id}
                                                label={'Отправить'}
                                                uploadData={el}
                                                multiple={(el.id === 7 || el.id === 10) ? false : true}
                                                handleError={handleError}
                                            />
                                        })}
                                    </Box>
                                );
                            //Работники
                            case
                            "workers"
                            :
                                return <Workers itemId={"workers"} handleError={handleError}/>;
                            //Заказчики
                            case
                            "organizationCustomers"
                            :
                                return <Customers itemId={"organizationCustomers"} handleError={handleError}/>;
                            //Поставщики
                            case
                            "organizationSuppliers"
                            :
                                return <Suppliers handleError={handleError} suppliers={suppliers}
                                                  itemId={"organizationSuppliers"}/>;
                            //АдресаОрганизации
                            case
                            "addressList"
                            :
                                return <AddressOrganization isCustomer={userRoles.type === 'customer'}
                                                            itemId={"addressOrganizationList"}
                                                            handleError={handleError}/>;
                            case
                            "rulesModifyingOptimization"
                            :
                                return <RulesModifyingOptimization itemId={"rulesModifyingOptimization"}
                                                                   handleError={handleError}/>;
                            //Материалы админ
                            case "adminMaterialGroups":
                                return <MaterialGroups handleError={handleError} admin={true}
                                                       itemId={"adminMaterialGroups"}/>;
                            case "adminMaterial":
                                return <MaterialPageItemModule
                                    admin={true}
                                    handleError={handleError}
                                    refMaterial={true}
                                    isAddMaterial={true}
                                    pageSize={30}
                                    settings={true}
                                    itemId={"adminMaterial"}
                                    allMaterial={true}
                                />;
                            case "adminImports":
                                return (
                                    <Box sx={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                        {importArray.slice(11, 13).map(el => {
                                            return <UploadGroupOfMaterialFileForm
                                                key={el.id}
                                                label={'Отправить'}
                                                uploadData={el}
                                                multiple={false}
                                                handleError={handleError}
                                            />
                                        })}
                                    </Box>
                                );
                            //Работы админ
                            case "listJobPacking":
                                return <JobMetricOrganization
                                    admin={true} handleError={handleError} itemId={"listJobPacking"} settings={true}/>;
                            case "listJobMilling":
                                return <JobMetricOrganization
                                    admin={true} handleError={handleError} itemId={"listJobMilling"} settings={true}/>;
                            case "listJobEdge":
                                return <JobMetricOrganization
                                    admin={true} handleError={handleError} itemId={"listJobEdge"} settings={true}/>;
                            case "listJobDrilling":
                                return <JobMetricOrganization
                                    admin={true} handleError={handleError} itemId={"listJobDrilling"} settings={true}/>;
                            case "listJobCutting":
                                return <JobMetricOrganization
                                    admin={true} handleError={handleError} itemId={"listJobCutting"} settings={true}/>;
                            //Помощь
                            case "themeHelp":
                                return <ThemeHelp
                                    handleError={handleError} itemId={"themeHelp"}/>;
                            case "help":
                                return <HelpSetting
                                    handleError={handleError} itemId={"help"}/>;
                            //валюта
                            case "currency":
                                return <Currency
                                    handleError={handleError} itemId={"currency"}/>;
                            case "currenciesNationalBank":
                                return <CurrenciesNationalBank
                                    handleError={handleError} itemId={"currenciesNationalBank"}/>;
                            case "unit":
                                return <Unit
                                    handleError={handleError} itemId={"unit"}/>;
                            case "adminSuppliers":
                                return <Suppliers
                                    handleError={handleError} admin={true} itemId={"adminSuppliers"}/>;
                            case "adminManufacturer":
                                return <Manufacturer
                                    handleError={handleError} admin={true} itemId={"adminManufacturer"}/>;
                            //шаблоны
                            case "systemSamples":
                                return <Samples admin={true} system={true} handleError={handleError}
                                                itemId={"systemSamples"}/>;
                            case "systemSamplesGroup":
                                return <SamplesGroups system={true} handleError={handleError}
                                                      itemId={"systemSamplesGroup"}/>
                            case "systemSamplesEdges":
                                return <SamplesEdges system={true} handleError={handleError}
                                                     itemId={"systemSamplesEdges"}/>
                            default:
                                return <Box/>;
                        }
                    })()}
                </Box>
            </Box>
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </Box>
    );
};

export default Settings;