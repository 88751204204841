import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from "react-router-dom";
import MaterialWarehouseService from "../../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import PriceWithCurrency from "../PriceWithCurrency";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import CellCheckbox from "../../../../сheckbox/CellCheckbox";
import CellCheckboxAMPUTFixPrice from "../arrival/CellCheckboxAMPUTFixPrice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import ModalAddWarehouseMaterial from "./ModalAddWarehouseMaterial";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";

const WarehouseMaterialItemTable = ({
                                        id,
                                        materialId,
                                        baseUnit,
                                        typeMaterial,
                                        material,
                                        currencyMap,
                                        tableOptions,
                                        tableOptionsHandle,
                                        currencies,
                                        handleError
                                    }) => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [modalAddWarehouseMaterial, setModalAddWarehouseMaterial] = useState(false);
    const nameMaterial = material ? material.name : null;
    const isTypeMaterial = material ? material.typeMaterial === "SLAB" : false;

    const [supplierMaterials, setSupplierMaterials] = useState()
    const [dataSuppliers, setDataSuppliers] = useState()
    const [modal, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState({id: "", price: "", currencyId: ""})

    const navigate = useNavigate();

    const profile = JSON.parse(localStorage.getItem("profile"))
    const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
    const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);

    useEffect(() => {
        MaterialWarehouseService.getAllByMaterialId(id).then(function (response) {
            setData(response.data);
            tableOptionsHandle({...tableOptions, totalElements: response.data?.length}, 3)
        })
            .catch(function (error) {
                handleError(error);
            });

    }, []);

    const sumTotalIssued = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.totalIssued, 0),
        [data]
    );

    const sumInReserve = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.inReserve, 0),
        [data]
    );

    const sumFreeQuantity = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.freeQuantity, 0),
        [data]
    );

    const sumArrivalAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.arrivalAmountBasePrice, 0),
        [data]
    );

    const sumArrivalAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.arrivalAmountAlternativePrice, 0),
        [data]
    );

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountBasePrice, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountAlternativePrice, 0),
        [data]
    );

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const handleSubmitCreate = (data) => {

        const materialWarehouseData = {
            materialPriceUnit: data.materialPriceUnit
        }

        const supplierMaterialId = data.supplierMaterialId;

        MaterialWarehouseService.createPlanned(materialWarehouseData, nameMaterial, supplierMaterialId)
            .then(function (response) {
                const responseData = response.data;
                handleCreateNewRow(responseData)
                handleClose();
            })
            .catch(function (error) {
                handleError(error);
            });
    }

    const handleClose = () => {
        setModalAddWarehouseMaterial(false)
        setModal(false)
        setCurrentEditRowData({id: "", price: "", currencyId: ""})
    }

    const handleAddWarehouseMaterial = () => {
        setModalAddWarehouseMaterial(true)
    };

    const handleSubmit = (data) => {
        MaterialWarehouseService.setPriceCloud(data.id, data.currencyId, data.price)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error);
            })
    }
    const handleAdd = (row) => {
        setModal(true)
        setCurrentEditRowData({
            id: row.id,
            price: row.materialWarehouseCloudMarketPrice?.price,
            currencyId: row.materialWarehouseCloudMarketPrice?.currencyId || (currencies && currencies[0]?.id)
        })
    }
    const handleDelete = (row) => {
        const id = row.id;
        MaterialWarehouseService.delete(id)
            .then(function () {
                setData(prev => prev.map(el => el.id === id ? {...el, materialWarehouseCloudMarketPrice: null} : el))
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const columns = useMemo(() => {
        const baseColumns = [
            {
                accessorFn: (row) => {
                    if (row.dateTime === null) {
                    } else {
                        return new Date(row.dateTime)
                    }
                },
                id: 'dateTime',
                header: 'Приход',
                sortingFn: 'dateTime',
                size: 10,
                Cell: ({cell, row}) => (
                    cell.getValue() !== undefined && cell.getValue() !== null ? (
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/materials-price-unit-arrival-item/' + data[row.index].materialPriceUnit.id + '/'
                                    + (typeMaterial === 'SLAB') + '/' + baseUnit}>
                                {new Date(cell.getValue()).toLocaleDateString()}
                            </Link>
                        </li>
                    ) : ""
                ),
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'quantity',
                header: `Кол. ${baseUnit}`,
                accessorKey: 'quantity',
                size: 3,
                maxSize: 3,
            },
            {
                id: 'totalIssued',
                header: `Выдано`,
                accessorKey: 'totalIssued',
                size: 3,
                maxSize: 3,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumTotalIssued?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </Stack>
                ),
            },

            {
                id: 'inReserve',
                header: `В резерве`,
                accessorKey: 'inReserve',
                size: 3,
                maxSize: 3,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumInReserve?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'freeQuantity',
                header: `Свободно`,
                accessorKey: 'freeQuantity',
                size: 3,
                maxSize: 3,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumFreeQuantity?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'arrivalAmountBasePrice',
                header: `Сумма прихода в ${baseCurrency}`,
                accessorKey: 'arrivalAmountBasePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumArrivalAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'arrivalAmountAlternativePrice',
                header: `Сумма прихода в ${alternativeCurrency}`,
                accessorKey: 'arrivalAmountAlternativePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumArrivalAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'amountBasePrice',
                header: `Сумма в ${baseCurrency}`,
                accessorKey: 'amountBasePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'amountAlternativePrice',
                header: `Сумма в ${alternativeCurrency}`,
                accessorKey: 'amountAlternativePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'PriceInCurrencyArrival',
                header: 'Цена в валюте прихода',
                accessorKey: 'materialPriceUnit.materialPriceInCurrencyArrival.price',
                size: 250,
                enableEditing: false,
                Cell: ({cell, row}) => <
                    PriceWithCurrency cell={cell}
                                      row={row}
                                      currencyMap={currencyMap}
                />,
            },
            {
                id: 'PriceInCurrencyBase',
                header: `Цена в ${baseCurrency}`,
                accessorKey: 'materialPriceUnit.materialPriceInCurrencyBase.price',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'PriceInCurrencyAlternative',
                header: `Цена в ${alternativeCurrency}`,
                accessorKey: 'materialPriceUnit.materialPriceInCurrencyAlternative.price',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'coefficientCurrencyBasePrice',
                header: `Кф. курса б.цены`,
                accessorKey: 'materialPriceUnit.coefficientCurrencyBasePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'basePrice',
                header: 'Баз. цена',
                accessorKey: 'basePrice.price',
                size: 8,
                Cell: ({row}) => {
                    const basePrice = row.original.materialPriceUnit.materialPriceInRealBasePrice || null;
                    return basePrice === null ? '' : (<CellCheckboxAMPUTFixPrice
                        price={basePrice}
                        fixPrice={row.original.materialPriceUnit.materialPriceInRealBasePrice.fixPrice}
                        currencyMap={currencyMap}
                    />);
                }, minSize: 8, maxSize: 8,
            },
            {
                id: 'materialWarehouseCloudMarketPrice',
                header: `Цена продажи`,
                accessorFn: (row) => row.materialWarehouseCloudMarketPrice && `${row.materialWarehouseCloudMarketPrice?.price} ${currencies?.find(el => el.id === row.materialWarehouseCloudMarketPrice?.currencyId)?.curAbbreviation}`,
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },

            {
                id: 'totalWrittenOff',
                header: 'Списано',
                accessorKey: 'totalWrittenOff',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'warehouse.name',
                header: 'Склад',
                accessorKey: 'warehouse.name',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                enableHiding: false,
            },
        ];

        if (typeMaterial === 'SLAB') {
            baseColumns.splice(0, 0, {
                id: 'length',
                header: 'Ширина',
                accessorKey: 'materialPriceUnit.length',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (typeMaterial === 'SLAB') {
            baseColumns.splice(1, 0, {
                id: 'width',
                header: 'Высота',
                accessorKey: 'materialPriceUnit.width',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (typeMaterial === 'SLAB') {
            baseColumns.splice(2, 0, {
                id: 'cutOff',
                header: 'Обрезок',
                accessorKey: 'materialPriceUnit.cutOff',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            });
        }

        return baseColumns;

    }, [data, setData, baseCurrency, alternativeCurrency, typeMaterial, baseUnit]);

    const menuItem = [
        {type: "add", text: "Добавить цену продажи", handler: handleAdd},
        {type: "delete", text: "Удалить цену продажи", handler: handleDelete}]

    return (
        <div>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px", gap: "10px"}}
            >
                {isTypeMaterial && <ButtonCreate
                    text={"Добавить плановый"}
                    onClick={handleAddWarehouseMaterial}
                    width={220}
                >
                </ButtonCreate>}
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                isFullScreen={isFullScreen}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.type === "add" && row.original.materialWarehouseCloudMarketPrice?.price ? "Изменить цену продажи" : el.text}
                    </MenuItem>
                })}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />

            <MaterialWarehouseCloudMarketPriceModal
                title={currentEditRowData?.price ? "Изменить цену продажи" : "Добавить цену проажи"}
                open={modal} onClose={handleClose}
                handleSubmit={handleSubmit}
                initValue={currentEditRowData}
                currencies={currencies}/>

            {modalAddWarehouseMaterial && baseCurrency !== null && alternativeCurrency !== null &&
                baseCurrency !== null && alternativeCurrency !== null
                && <ModalAddWarehouseMaterial
                    title={"Добавить плановую закупку материала"}
                    open={modalAddWarehouseMaterial}
                    onClose={handleClose}
                    materialId={id}
                    supplierMaterials={supplierMaterials}
                    setSupplierMaterials={setSupplierMaterials}
                    dataSuppliers={dataSuppliers}
                    setDataSuppliers={setDataSuppliers}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    handleSubmitCreate={handleSubmitCreate}
                    currencyMap={currencyMap}
                />}
        </div>
    );
};
const MaterialWarehouseCloudMarketPriceModal = ({open, onClose, handleSubmit, initValue, title, currencies}) => {
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({price: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [open, initValue])

    const handleClose = () => {
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            console.log(value)
            if (value === "" || value === undefined) {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    name={"price"}
                    value={formState?.price}
                    type={"number"}
                    label="Цена продажи"
                    setCurrValues={setFormState}
                    validationError={validationState?.price}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    select
                    name="currencyId"
                    label="Валюта"
                    value={formState?.currencyId}
                    setCurrValues={setFormState}
                >
                    {currencies?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.curName}
                        </MenuItem>))}
                </TextFieldValidation>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}

export default WarehouseMaterialItemTable;