import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import WorkerJobTable from "../workerJobTable/WorkerJobTable";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";

const ModalReplacementWorker = ({
                                    data,
                                    setData,
                                    open,
                                    onClose,
                                    selectId,
                                    currentRow,
                                }) => {


    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth='lg'>
                <DialogTitle textAlign="center">Изменить выполнение</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: '0.2rem'}}>
                        <WorkerJobTable
                            jobId={selectId}
                            jobData={data}
                            setJobData={setData}
                            currentRow={currentRow}
                            onClose={onClose}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <ButtonExit text={"Выйти"} onClick={onClose}/>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalReplacementWorker;