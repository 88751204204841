import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import Delete from "@mui/icons-material/DeleteOutlined";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAlert from "../ModalAlert";
import MenuItem from "@mui/material/MenuItem";

const ProcessingTable = ({
                             data,
                             processingHandle,
                             openConfirmModal,
                             hoveredHole,
                             setHoveredHole,
                         }) => {
    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'position',
                header: 'Позиция',
                accessorKey: "position",
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.contour.position}</span>,
            },

            {
                id: 'name',
                header: 'Наименование',
                accessorKey: "name",
                maxSize: 50,
                Cell: ({cell, row}) => {
                    return <Tooltip title={row.original.name}><span>{row.original.name}</span>
                    </Tooltip>
                },
            },

            {
                id: 'x',
                header: 'x',
                accessorKey: 'x',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.center.x}</span>,
            },
            {
                id: 'y',
                header: 'y',
                accessorKey: 'y',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.center.y}</span>,
            },
            {
                id: 'z',
                header: 'z',
                accessorKey: 'z',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.center.z}</span>,
            },
            {
                id: 'depth',
                header: 'Глубина',
                accessorKey: 'depth',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{cell.getValue()}</span>,
            },
            {
                id: 'radius',
                header: 'Радиус',
                accessorKey: 'radius',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{cell.getValue()}</span>,
            },
        ];

        return baseColumns;

    }, [data]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: processingHandle},
        {type: "delete", text: "Удалить", handler: openConfirmModal},
    ]
    return <>
        <MaterialReactTable

            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "100%",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    onPointerOver: (event) => (event.stopPropagation(), setHoveredHole(`${row.id}${row.original.name}`)),
                    onPointerOut: (event) => (event.stopPropagation(), setHoveredHole(null)),
                    sx: () => ({
                        height: '36px',
                        backgroundColor: `${row.id}${row.original.name}` === hoveredHole ? (theme) => theme.palette.primary.light : "white",
                        "&:hover td:after": {
                            display: "none",
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: `${row.id}${row.original.name}` === hoveredHole ? (theme) => theme.palette.primary.light : (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            rowHeight={13}
            columnSpacing={1}
            initialState={{
                density: "compact",
                sorting: [{id: 'position', desc: false}],
            }}
            state={{showGlobalFilter: true}}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.type === "edit" ? el.handler("editHole", row.original.id) : el.handler(row.original.id)
                        setHoveredHole(null);
                        closeMenu();

                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })}
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
            enableColumnActions={false}
            enableBottomToolbar={false}


        />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
    </>

}
export default ProcessingTable
