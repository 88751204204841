import React, {useEffect, useState} from 'react';
import IssuingEdgeWareHouseTable from "./IssuingEdgeWareHouseTable";
import MaterialWarehouseService from "../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import WarehouseMaterialSettingsService
    from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";

const ModulAddMaterialIssuingEdge = ({
                                         open,
                                         onClose,
                                         titleModal,
                                         id,
                                         change,
                                         setChange,
                                         handleError
                                     }) => {
        const pathname = useLocation().pathname
        const [data, setData] = useState([]);
        const [additionallyColumn, setAdditionallyColumn] = useState([]);

        const profile = JSON.parse(localStorage.getItem("profile"))
        const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
        const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);

        const [dataMaterialWarehouse, setDataMaterialWarehouse] = useState([]);
        const [tableOptions, setTableOptions] = useState({
            pageIndex: 0,
            pageSize: 20,
            totalElements: 0,
            searchData: ""
        })

        const getStartPageSize = () => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const tableOptions = pagesData[`${pathname}+2`]?.tableOptions
            tableOptions && setTableOptions((prev) => ({...prev, pageSize: tableOptions.pageSize}))
        }
        const tableOptionsHandle = (nextState, currTab) => {
            setTableOptions(nextState)
            if (nextState.pageSize !== tableOptions.pageSize) {
                EditLocalStoragePagesData(`${pathname}+2`, "tableOptions", nextState)
            }
        }

        useEffect(() => {
                getStartPageSize()
                MaterialWarehouseService.getAllByIssuingEdgeId(id).then(function (response) {
                    const data = response.data;
                    setDataMaterialWarehouse(data);
                    setTableOptions((prev) => ({...prev, totalElements: data.length}))
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }, [change]
        );

        return (
            <div>
                <IssuingEdgeWareHouseTable
                    data={dataMaterialWarehouse}
                    setData={setDataMaterialWarehouse}
                    isPriceInCurrencyArrival={true}
                    isPriceInCurrencyAlternative={true}
                    isPriceInCurrencyBase={true}
                    isWarehouse={true}
                    isAddCutOffButton={true}
                    isIndentsButton={true}
                    isActions={true}
                    isColumnCut={true}
                    isColumnReserve={true}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    idIssuingEdge={id}
                    change={change}
                    setChange={setChange}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />

                {/*<Dialog open={open} fullWidth={true} maxWidth='lg'>
                <DialogTitle textAlign="center">{titleModal}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: '0.2rem'}}>

                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>*/}
            </div>
        );
    }
;

export default ModulAddMaterialIssuingEdge;