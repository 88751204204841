import React, {useEffect, useState} from 'react';
import WorkingCuttingMapTable from "../../../../components/UI/table/cutting/workingcuttingmap/WorkingCuttingMapTable";
import WorkingCuttingMapService from "../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../Constants";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";

const WorkingCuttingMapsArchive = () => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    useEffect(() => {
        getStartPageSize()
        WorkingCuttingMapService.getAllArchive().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Архив карт раскроя"}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Карты раскроя", href: "/organization/working-cutting-maps"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Архив карт раскроя
                    </Typography>
                </Box>
                <WorkingCuttingMapTable
                    data={data}
                    setData={setData}
                    disabledEdit={true}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
        </Box>
    );
};

export default WorkingCuttingMapsArchive;