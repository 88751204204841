import React, {useEffect, useState} from 'react';
import ReservationMaterialTable from "../../../../components/UI/table/warehouse/reservation/ReservationMaterialTable";
import OrderUtil from "../../../../utils/orders/OrderUtil";
import MaterialReserveService from "../../../../API/orders/reservation/MaterialReserveService";
import {constantsErrorMessages} from "../../../../Constants";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {Box} from "@mui/system";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {Paper} from "@mui/material";
import ButtonSelectAction from "../../../../components/UI/button/ButtonSelectAction";

const ReservationLinerMaterial = () => {
    const [rowSelection, setRowSelection] = useState([]);
    const [data, setData] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [change, setChange] = useState(null);
    const [open, setOpen] = useState(false)
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/reservation-linear-material`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/reservation-linear-material`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        MaterialReserveService.getAllNotArchivePieceMaterial().then(function (response) {
            const data = response.data;
            setData(data);
            setOrderList(OrderUtil.getListOrderNumbers(data))
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [change]);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Резервирование погонных материалов"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Резервирование погонных материалов
                    </Typography>
                </Box>
                <ReservationMaterialTable
                    data={data}
                    setData={setData}
                    pageSize={100}
                    enableGrouping={true}
                    columnGrouping={["materialName"]}
                    expanded={false}
                    isColumnMaterialWarehouse={false}
                    isColumnOptimization={false}
                    orderList={orderList}
                    change={change}
                    setChange={setChange}
                    titleModalUpdateOrAddMaterialArrival={"Создать приход кромочных материалов"}
                    typeMaterial={'LINER'}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    openSelectRowModal={open}
                    closeSelectRowModal={() => setOpen(false)}
                    handleError={handleError}
                />
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
                {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>}
            </Box>
        </Box>
    );
};

export default ReservationLinerMaterial;