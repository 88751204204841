import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import CustomerUserEntity from "../../../../API/customer/CustomerUserEntity";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ListItemText} from "@mui/material";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import PersonalizationModal from "./PersonalizationModal";
import WorkerService from "../../../../API/orders/job/WorkerService";

const Personalization = ({role, handleError}) => {
    const [data, setData] = useState({})
    const [modal, setModal] = useState(false)

    useEffect(() => {
        const api = role === "customerUser" || role === 'customer' ? CustomerUserEntity.getAll : WorkerService.getAllOwn
        api().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [])

    const handleClose = () => {
        setModal(false)
    }
    const handleSubmit = (res, handleClose) => {
        const {id, ...data} = res
        const api = role === "customerUser" || role === 'customer' ? CustomerUserEntity.edit : WorkerService.editOwn
        api(data).then(function (response) {
            setData(response.data)
            handleClose()
        })
            .catch(function (error) {
                handleError(error);
            })
    }
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        width: "700px",
        padding: "24px",
        borderRadius: "16px",
        border: (theme) => `${theme.palette.primary.stroke} 1px solid`
    }}>
        <Box Box sx={{
            display: "flex"
        }}>
            <List sx={{width: '100%', maxWidth: 350,}}>
                <ListItem>
                    <ListItemText primary={"Фамилия:"} secondary={data.lastName || "-"}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Имя:"} secondary={data.firstName || "-"}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Отчество:"} secondary={data.middleName || "-"}/>
                </ListItem>
            </List>
            <List sx={{width: '100%', maxWidth: 350}}>
                <ListItem>
                    <ListItemText primary={"Дата рождения:"} secondary={data.birthday || "-"}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Номер телефона:"} secondary={data.phoneNumber || "-"}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Дожность:"} secondary={data.position || "-"}/>
                </ListItem>
            </List>
        </Box>
        <Box Box sx={{
            display: "flex",
            justifyContent: "end"
        }}>
            <ButtonCreate icon={false} text={"Изменить"} width={90} onClick={() => setModal(true)}></ButtonCreate>
        </Box>
        <PersonalizationModal
            open={modal}
            organization={!(role === "customerUser" || role === 'customer')}
            onClose={handleClose}
            handleSubmit={handleSubmit}
            initValue={data}
            handleError={handleError}
        />
    </Box>
}
export default Personalization