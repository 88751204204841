import React, {useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {constantsErrorMessages} from "../../../../Constants";
import ButtonCreate from "../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {Checkbox, Paper, Typography} from "@mui/material";
import ModalAlert from "../customer-detales/ModalAlert";
import TypeJobOrganizationModal from "./TypeJobOrganizationModal";
import TypeJobOrganizationService from "../../../../API/orders/job/TypeJobOrganizationService";
import {Link} from "react-router-dom";
import WorkersModal from "./WorkersModal";
import ButtonText from "../../button/ButtonText";

const TypeJobOrganizationTable = ({
                                      data,
                                      setData,
                                      workers,
                                      setChange,
                                      tableOptions,
                                      tableOptionsHandle,
                                      handleError
                                  }) => {
        const initValue = {name: "", outputPriority: "", active: true}
        const [modal, setModal] = useState(false);
        const [workersModal, setWorkersModal] = useState(false)
        const [currentEditRowData, setCurrentEditRowData] = useState(initValue);
        const [currentEditWorkersData, setCurrentEditWorkersData] = useState({idGroup: "", workers: {}});

        const handleClose = () => {
            setModal(false)
            setCurrentEditRowData(initValue)
        }

        const handleUpdate = (data) => {
            TypeJobOrganizationService.update(data, currentEditRowData.id)
                .then(function (response) {
                    setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                    handleClose()
                })
                .catch(function (error) {
                    handleError(error)
                })

        }

        const handleDelete = (row) => {
            TypeJobOrganizationService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error)
                })
        };

        const handleSubmit = (data) => {
            TypeJobOrganizationService.create(data)
                .then(function (response) {
                    setData(prev => [...prev, response.data])
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
                })
                .catch(function (error) {
                    handleError(error)
                })
            handleClose()
        }

        const handleAdd = () => {
            setModal(true)
        }
        const handleEdit = (row) => {
            setCurrentEditRowData(row);
            setModal(true)
        };
        const editWorkers = (idGroup, currWorkers) => {
            const added = {}
            workers.forEach((row, i) => {
                added[row.id] = currWorkers.some(el => el.id === row.id)
            });
            setCurrentEditWorkersData({idGroup: idGroup, workers: added})
            setWorkersModal(true)
        }
        const handleCloseWorkers = (isChange) => {
            isChange && setChange(prev => !prev)
            setWorkersModal(false)
            setCurrentEditWorkersData({idGroup: "", workers: {}})
        }

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'name',
                    header: 'Наименование',
                    accessorKey: "name",
                    size: 450,
                    grow: true,
                    Cell: ({cell, row}) => (row.original.active ?
                        <Link to={`/settings/metricJob/${row.original.id}`}>{cell.getValue()}</Link> : cell.getValue()),
                },
                {
                    id: 'outputPriority',
                    header: 'Приоритет вывода',
                    accessorKey: "outputPriority",
                    size: 30,
                    grow: true,
                },
                {
                    id: 'workers',
                    header: 'Работники по умолчанию',
                    accessorFn: (row) => row.workers,
                    size: 300,
                    grow: true,
                    Cell: ({cell, row}) => {
                        const text = cell.getValue()?.map(worker => `${worker.lastName} ${worker.firstName}, `)
                        return text?.length > 0 ?
                            <ButtonText
                                text={text}
                                blue={true}
                                onClick={() => editWorkers(row.id, cell.getValue())}/>
                            : <ButtonCreate
                                text={"Добавить работников"}
                                height={30}
                                onClick={() => editWorkers(row.id, [])}/>
                    }
                },
                {
                    header: 'Активна',
                    accessorKey: 'active',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.active}
                        disabled={true}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                },
            ];
            return baseColumns;

        }, [data]);

        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleEdit},
            {type: "delete", text: "Удалить", handler: handleDelete}
        ]

        return (<div>
            < Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px", gap: "16px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>Группы работ</Typography>
                <ButtonCreate
                    text={"Добавить группу"}
                    width={190}
                    onClick={handleAdd}
                />
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",

                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:last-of-type": {
                            padding: 0,
                            width: 'calc(100% - 1px)',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    density: "compact",
                    sorting: [{id: 'name', desc: false}]
                }}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return (el.type === "edit" || (el.type === "delete" && !row.original.blocked)) && <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                enableColumnActions={false}
                enableStickyHeader={true}
                enableStickyFooter={true}
            />
            <TypeJobOrganizationModal
                title={currentEditRowData ? "Изменить групу" : "Добавить группу"}
                open={modal}
                onClose={handleClose}
                handleSubmit={currentEditRowData.id ? handleUpdate : handleSubmit}
                initValue={currentEditRowData}/>

            <WorkersModal
                open={workersModal}
                onClose={handleCloseWorkers}
                workers={workers}
                currentEditWorkersData={currentEditWorkersData}
                setCurrentEditWorkersData={setCurrentEditWorkersData}
            />
        </div>)
            ;
    }
;

export default TypeJobOrganizationTable;
