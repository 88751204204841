import React from "react";
import {Button, DialogContent, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import TextFieldWithSetValueMainOptions
    from "../../../components/UI/modul/mainoptions/TextFieldWithSetValueMainOptions";
import MainOptionsMaterialModuleEntityTextField
    from "../../../components/UI/modul/mainoptions/MainOptionsMaterialModuleEntityTextField";
import TextFieldArrivalMaterialModuleItem
    from "../../../components/UI/modul/arrivalmaterialmoduleitem/TextFieldArrivalMaterialModuleItem";
import MaterialTextFieldObjectWithEntityList
    from "../../../components/UI/modul/mainoptions/MaterialTextFieldObjectWithEntityList";
import {typeMaterial} from "../../../data/TypeMaterial";
import {statusesYesOrNo} from "../../../data/StatusesYesOrNo";
import {materialCutDefault} from "../../../data/MaterialCutDefault";
import {structure} from "../../../data/Structure";

const MaterialItemSettings = ({
                                  data,
                                  dataMOO,
                                  setData,
                                  supplierList,
                                  groupMaterialList,
                                  manufacturerList,
                                  handleSubmit,
                                  addMaterial,
                                  setSupplierId,
                                  setManufacturerId,
                                  setGroupMaterialId,
                                  baseUnit,
                                  isDataMOO,
                                  isMaterialOrganizationOption
                              }) => {
    const isSlab = dataMOO?.typeMaterial === 'SLAB' || data?.typeMaterial === 'SLAB'
    const isPiece = data?.typeMaterial === 'PIECE'
    const isEdge = data?.subTypeMaterial === "EDGE"

    return <div>
        <DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    "& > *:not(:last-child)": {marginRight: '20px'}
                }}>
                    {isDataMOO && <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '500px',
                        }}
                    >
                        <Box>
                            <TextFieldWithSetValueMainOptions
                                label="Артикул"
                                values={data}
                                setValues={setData}
                                field={'articleNumber'}
                                disabled={true}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Текущее найменование"
                                values={data}
                                setValues={setData}
                                field={'name'}
                                disabled={true}
                            />
                            {supplierList && <MainOptionsMaterialModuleEntityTextField
                                label={'Поставщик по умолчанию'}
                                values={data}
                                field={'defaultSupplier'}
                                childFirstField={'id'}
                                setId={setSupplierId}
                                entityList={supplierList}
                                disabled={true}
                            />}
                            <TextFieldArrivalMaterialModuleItem
                                label="Ед. измерения"
                                value={baseUnit}
                                field={baseUnit}
                                maxWidth={'600px'}
                                disabled={true}

                            />
                            <MaterialTextFieldObjectWithEntityList
                                label="Тип материала"
                                values={data}
                                setValues={setData}
                                field={'typeMaterial'}
                                entityList={typeMaterial}
                                disabled={true}
                            />

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                {(isSlab || isEdge) && <TextFieldWithSetValueMainOptions
                                    label="Ширина"
                                    values={dataMOO}
                                    setValues={setData}
                                    field={'weight'}
                                    marginRight={'10px'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={true}
                                />}
                                {isSlab && <TextFieldWithSetValueMainOptions
                                    label="Высота"
                                    values={dataMOO}
                                    setValues={setData}
                                    field={'height'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={true}
                                />}
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                {(isSlab || isEdge) && <TextFieldWithSetValueMainOptions
                                    label="Толщина"
                                    values={dataMOO}
                                    setValues={setData}
                                    field={'thickness'}
                                    marginRight={'10px'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={true}
                                />}
                                {isPiece && <TextFieldWithSetValueMainOptions
                                    label="Плотность"
                                    values={dataMOO}
                                    setValues={setData}
                                    field={'density'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={true}
                                />}
                            </Box>
                            {isSlab && <MaterialTextFieldObjectWithEntityList
                                label="Структура"
                                values={data}
                                setValues={setData}
                                field={'structure'}
                                entityList={structure}
                                disabled={true}
                            />}
                        </Box>
                    </Stack>}

                    {isDataMOO && isSlab && <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '200px',
                        }}
                    >
                        {isSlab && <MaterialTextFieldObjectWithEntityList
                            label="Кроить по умолчанию"
                            values={dataMOO}
                            setValues={setData}
                            field={'cutDefault'}
                            entityList={materialCutDefault}
                            disabled={true}
                        />}

                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп слева"
                                values={data}
                                setValues={setData}
                                field={'trimmingLeft'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={true}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп справа"
                                values={data}
                                setValues={setData}
                                field={'trimmingRight'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={true}
                            />
                        </Box>}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп снизу"
                                values={data}
                                setValues={setData}
                                field={'trimmingBottom'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                marginRight={'10px'}
                                disabled={true}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп сверху"
                                values={data}
                                setValues={setData}
                                field={'trimmingTop'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={true}
                            />
                        </Box>}

                        {isSlab && <TextFieldWithSetValueMainOptions
                            label="Вес"
                            values={data}
                            setValues={setData}
                            field={'density'}
                            minWidthXS={'25px'}
                            minWidthSM={'25px'}
                            minWidthPX={'25px'}
                            disabled={true}
                        />}

                    </Stack>}
                </Box>
            </form>
        </DialogContent>
    </div>
}
export default MaterialItemSettings;