import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import MaterialTree from "../tree/MaterialTree";
import MaterialTable from "../table/warehouse/material/material/MaterialTable";
import MaterialOrganizationOptionService
    from "../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import GroupMaterialService from "../../../API/orders/orders/organization/GroupMaterialService";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";

import {useLocation} from "react-router-dom";
import UserMaterialMaterialCloudService from "../../../API/material-cloud/user/UserMaterialMaterialCloudService";
import UserGroupMaterialMaterialCloudService
    from "../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import {Paper, Switch, Typography} from "@mui/material";
import ButtonCreate from "../button/ButtonCreate";
import ButtonSelectAction from "../button/ButtonSelectAction";
import {ModalSelectAction} from "../modal/ModalSelectAction";
import {
    authorizedAdmin,
    authorizedOrganization,
} from "../../../Constants";
import MaterialService from "../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";
import ModalAddSignJob from "../table/warehouse/material/material/ModalAddSignJob";
import {ModalAddMaterial} from "../modal/ModalAddMaterial";
import ModalAddJob from "../table/prodaction/jobmetricorganization/ModalAddJob";
import FormControlLabel from "@mui/material/FormControlLabel";

const MaterialPageItemModule = ({
                                    refMaterial,
                                    pageSize,
                                    enableEditing,
                                    isAddMaterial,
                                    isSelectInArrival,
                                    isSelectInEstimate,
                                    selectMaterialId,
                                    selectMaterial,
                                    typeMaterial,
                                    subTypeMaterial,
                                    modal,
                                    allMaterial,
                                    disabled,
                                    materialCloud,
                                    settings,
                                    itemId,
                                    supplierId,
                                    handleError,
                                    admin,
                                }) => {

    const pathname = useLocation().pathname
    const profile = JSON.parse(localStorage.getItem("profile"))
    const isOrganization = profile?.userRoles.some(role => authorizedOrganization.includes(role.name))
    const isAdmin = profile?.userRoles.some(role => authorizedAdmin.includes(role.name))

    const [archive, setArchive] = useState(false)
    const [open, setOpen] = useState(false)
    const [modalAddMaterialOpen, setModalAddMaterialOpen] = useState(false)

    const [filterGroup, setFilterGroup] = useState(new Map());
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);
    const [dataTree, setDataTree] = useState([]);
    const [groupMap, setGroupMap] = useState(null);

    const [rowSelection, setRowSelection] = useState([]);
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const [openModalAddSignJob, setOpenModalAddSignJob] = useState(false);
    const [groupNames, setGroupNames] = useState("");
    const [groupIds, setGroupIds] = useState([]);
    const [disabledAddMaterial, setDisabledAddMaterial] = useState(true);
    const [currentGroup, setCurrentGroup] = useState(null);
    const [modalAddJob, setModalAddJob] = useState(false);
    const [deleteJob, setDeleteJob] = useState(false);

    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: modal ? 10 : 20,
        totalElements: 0,
        searchData: ""
    })

    const [change, setChange] = useState(false)

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(settings ? `${pathname}+${itemId}` : pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[settings ? `${pathname}+${itemId}` : pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const modalActions = (() => {
        const actions = [];
        if (!archive && admin) {
            actions.push({name: "Перместить в архив", type: "setToArchive"});
        }
        if (admin) {
            actions.push({name: "Переместить в группу", type: "setGroup"})
        }
        actions.push({name: "Добавить работу на материал", type: "add"},
            {name: "Удалить работу c материала", type: "delete"});

        return actions;
    })();

    const handleCloseModalAddSignJob = () => {
        setOpenModalAddSignJob(false)
        setRowSelection([]);
        setRowIndexSelection([]);
        setSelectedIds([]);
    };

    const handleSubmitAddSignJobInMaterial = () => {
        MaterialOrganizationOptionService.addSigJobInMaterialsByMaterialGroup(selectedIds, groupIds).then(function () {
            handleCloseModalAddSignJob();
        })
            .catch(function (error) {
                handleError(error)
            });
    }

    const [dataSignJob, setDataSignJob] = useState([]);

    useEffect(() => {
        if (groupIds.length === 0) {
            setDisabledAddMaterial(true)
        } else {
            setDisabledAddMaterial(false)
        }
    }, [groupIds]);

    useEffect(() => {
        const api = isOrganization && !allMaterial && !materialCloud ? MaterialOrganizationOptionService.getAllFilter : UserMaterialMaterialCloudService.getAllFilter

        if (currentGroup) {
            api(currentGroup, tableOptions.pageIndex, tableOptions.pageSize,
                null, typeMaterial, subTypeMaterial, tableOptions.searchData, archive, supplierId).then(function (response) {
                let data = response.data;
                setCurrentData(data.content)
                setTableOptions((prev) => ({...prev, totalElements: data.total}))
            })
                .catch(function (error) {
                    handleError(error)
                });
        }
    }, [change, currentGroup, tableOptions.searchData, tableOptions.pageIndex, tableOptions.pageSize, archive]);

    useEffect(() => {
        getStartPageSize()
        const api = isOrganization && !allMaterial && !materialCloud ? GroupMaterialService.getAllByOrganization : UserGroupMaterialMaterialCloudService.getAll

        api(typeMaterial, subTypeMaterial).then((response) => {
            const buildTreeGroups = GroupMaterialUtil.buildTreeGroups(response.data);
            setDataTree(buildTreeGroups);
            const groupMap = new Map();
            GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
            setGroupMap(groupMap);
            setCurrentGroup({})
        }).catch(function (error) {
            handleError(error)
        });
    }, []);

    const handleSelectAction = (action, groupId) => {
        if (action === "add") {
            setModalAddJob(true)
            setOpen(false)
        } else if (action === "delete") {
            setModalAddJob(true)
            setDeleteJob(true)
            setOpen(false)
        } else if (action === "setGroup") {
            MaterialService.setGroup(groupId, {"ids": rowSelection}).then(() => {
                setRowSelection([])
                setOpen(false)
            }).catch(function (error) {
                handleError(error)
            })
        } else {
            MaterialService.setToArchive({"ids": rowSelection}).then(() => {
                setRowSelection([])
                setOpen(false)
            }).catch(function (error) {
                handleError(error)
            })
        }

    }
    const handleOpenModalAddSignJob = () => {
        setOpenModalAddSignJob(true)
    };
    const handleSubmitAddJob = (ids, addedData, orderJobType) => {
        if (admin) {
            const api = deleteJob ? MaterialService.deleteJobMetricByMaterialIds : MaterialService.addJobMetricByMaterialIds
            api({
                "firstIds": ids,
                "secondIds": rowSelection
            }, orderJobType).then(function (response) {
                setModalAddJob(false)
                setDeleteJob(false)
                setRowSelection([])
            })
                .catch(function (error) {
                    handleError(error);
                });
        } else {
            const api = deleteJob ? MaterialOrganizationOptionService.deleteJobMetricByMaterialIds : MaterialOrganizationOptionService.addJobMetricByMaterialIds
            api({
                "firstIds": ids,
                "secondIds": rowSelection
            }).then(function (response) {
                setModalAddJob(false)
                setDeleteJob(false)
            })
                .catch(function (error) {
                    handleError(error);
                });
        }
    }
    const handleAddMaterial = (byMaterial, queryStringData, data) => {
        const api = admin ? MaterialService.save : byMaterial ? MaterialOrganizationOptionService.saveByMaterial : MaterialOrganizationOptionService.save
        api(queryStringData, data).then((response) => {
            setChange(prev => !prev)
        }).catch(function (error) {
            handleError(error)
        })
    }
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: "column",
                maxWidth: isAdmin ? "1550px" : "1465px",
            }}>
                {!modal && <Box sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "8px"}}>
                    <Typography sx={{fontSize: "24px", fontWeight: 700}}>Материалы</Typography>
                    <Box sx={{display: 'flex', gap: "8px"}}>
                        {settings && <FormControlLabel
                            control={<Switch checked={archive} onChange={() => setArchive(!archive)} name="archive"/>}
                            label="Архив материалов"
                        />}
                        {settings && <ButtonCreate
                            onClick={() => setModalAddMaterialOpen(true)}
                            variant="contained"
                            text={"Cоздать материал"}
                        />}
                    </Box>
                </Box>}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}>
                    <Box sx={{
                        flexBasis: '50%',
                        maxWidth: '225px',
                        paddingTop: Object.keys(rowSelection).length !== 0 ? "71px" : "49px"
                    }}>
                        <MaterialTree
                            name={"Материалы"}
                            filterGroup={filterGroup}
                            setFilterGroup={setFilterGroup}
                            changeFilterGroup={changeFilterGroup}
                            setChangeFilterGroup={setChangeFilterGroup}
                            data={dataTree}
                            groupMap={groupMap}
                            currentGroup={currentGroup}
                            setCurrentGroup={setCurrentGroup}
                        />
                    </Box>
                    <Box sx={{
                        flexBasis: 'auto',
                        marginLeft: '16px',
                        overflow: 'auto',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px'
                    }}>
                        <MaterialTable
                            currentData={currentData}
                            refMaterial={refMaterial}
                            pageSize={pageSize}
                            enableEditing={enableEditing}
                            isSelectInArrival={isSelectInArrival}
                            isSelectInEstimate={isSelectInEstimate}
                            openModalAddSignJob={openModalAddSignJob}
                            setOpenModalAddSignJob={setOpenModalAddSignJob}
                            handleCloseModalAddSignJob={handleCloseModalAddSignJob}
                            handleSubmitAddSignJobInMaterial={handleSubmitAddSignJobInMaterial}
                            groupNames={groupNames}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                            rowIndexSelection={rowIndexSelection}
                            setRowIndexSelection={setRowIndexSelection}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            disabledAddMaterial={disabledAddMaterial}
                            dataSignJob={dataSignJob}
                            setDataSignJob={setDataSignJob}
                            change={change}
                            setChange={setChange}
                            selectMaterialId={selectMaterialId}
                            selectMaterial={selectMaterial}
                            tableOptionsHandle={tableOptionsHandle}
                            tableOptions={tableOptions}
                            modal={modal}
                            allMaterial={allMaterial}
                            enableRowSelection={settings}
                            disabled={disabled}
                            settings={settings}
                            sx={{
                                borderBottomLeftRadius: '0px',
                                borderBottomRightRadius: '0px',
                                maxWidth: '100%',
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: '100%',
                height: "88px",
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.primary.lightGrey,
            }} component="footer">
                <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
            </Paper>}
            <ModalSelectAction
                open={open}
                reset={() => setOpen(false)}
                submit={handleSelectAction}
                modalActions={modalActions}
                dataTree={dataTree}
            />
            <ModalAddMaterial
                open={modalAddMaterialOpen}
                onClose={() => setModalAddMaterialOpen(false)}
                title={"Добавить материал"}
                onSubmit={handleAddMaterial}
                filterGroup={filterGroup}
                dataTree={dataTree}
                groupMap={groupMap}
                setFilterGroup={setFilterGroup}
                setChangeFilterGroup={setChangeFilterGroup}
                admin={admin}
            />
            <ModalAddSignJob
                title={"Добавить работы на группу " + groupNames}
                open={openModalAddSignJob}
                handleClose={handleCloseModalAddSignJob}
                handleSubmit={handleSubmitAddSignJobInMaterial}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                rowIndexSelection={rowIndexSelection}
                setRowIndexSelection={setRowIndexSelection}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                data={dataSignJob}
                setData={setDataSignJob}
                change={change}
                setChange={setChange}
            />
            {modalAddJob && <ModalAddJob
                open={modalAddJob}
                onClose={() => setModalAddJob(false)}
                onSubmit={handleSubmitAddJob}
                handleError={handleError}
                group={true}
                deleteJob={deleteJob}
                admin={admin}
            />}
        </>
    );
};

export default MaterialPageItemModule;
