import Box from "@mui/material/Box";
import React from "react";
import CustomerRequestsEdges from "../edges/CustomerRequestsEdges";
import {Typography} from "@mui/material";

const SamplesEdges = ({system}) => {
    return <Box sx={{padding: "0 30px"}}>
        <Typography sx={{fontSize: "24px", fontWeight: 700}}> Кромки шаблонов</Typography>
        <CustomerRequestsEdges samples={true} system={system}/></Box>
}
export default SamplesEdges