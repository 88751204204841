import React, {useState} from "react";
import EstimateItem from "./EstimateItem";
import {useParams} from "react-router-dom";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {estimateStatuses} from "../../../../data/EstimateState";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";

const OrganizationEstimate = () => {
    const [alertData, setAlertData] = useState({});
    const params = useParams();
    const year = params.year;
    const orderNumber = params.orderNumber;
    const orderId = params.orderId;
    const orderHeaderId = params.orderHeaderId;
    const estimateId = params.estimateId
    const estimateState = params.estimateState;
    const name = orderNumber === "null" ? "Не занумирован" : orderNumber
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={name}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заказы", href: "/organization/orders"},
                    {
                        name: "Смета",
                        href: `/organization/orders/${orderId}/${orderNumber}/${orderHeaderId}/${year}`
                    }
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        {name}
                    </Typography>
                    <Typography>{`Статус: ${estimateStatuses[estimateState]}`}</Typography>
                </Box>
                <EstimateItem
                    orderId={orderId}
                    isButtonPurchase={true}
                    enableEditing={true}
                    isActual={true}
                    inWork={true}
                    orderCloud={true}
                    isImport={true}
                    detailed={false}
                    orderNumber={orderNumber}
                    orderHeaderId={orderHeaderId}
                    year={year}
                    setIsLoading
                    estimateId={estimateId}
                    estimateState={estimateState}
                    handleError={handleError}
                />
            </Box>
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </Box>
    )
};

export default OrganizationEstimate;