import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../textfield/TextFieldWithSetValue";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const ModalOpenCreatePlanOrOptimization = ({
                                               title,
                                               label,
                                               field,
                                               modalOpen,
                                               handleClose,
                                               handleSubmit,
                                               values,
                                               setValues,
                                               isCalculationOptimization
                                           }) => {
    return (
        <Dialog open={modalOpen} onClose={handleClose}>
            <DialogTitle textAlign={"center"}>{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextFieldWithSetValue
                            label={label}
                            values={values}
                            setValues={setValues}
                            field={field}
                            disabled={isCalculationOptimization}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={handleClose}/>
                <ButtonAdd text={"Создать"} onClick={handleSubmit}/>
            </DialogActions>

        </Dialog>
    );
};

export default ModalOpenCreatePlanOrOptimization;