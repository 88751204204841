import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import ModalConfirm from "../../../modal/ModalConfirm";
import ModalOpenSetValues from "../material/arrival/ModalOpenSetValues";
import EstimateService from "../../../../../API/orders/estimate/organization/EstimateService";
import MaterialPriceUnitService from "../../../../../API/orders/warehouse/MaterialPriceUnitService";
import ButtonCreate from "../../../button/ButtonCreate";
import {materialEstimateUnitStates} from "../../../../../data/MaterialEstimateUnitState";
import selectionTableUtil from "../../../../../utils/common/SelectionTableUtil";

const RequestsPurchaseTableItem = ({
                                       orderId,
                                       orderNumber,
                                       status,
                                       estimateId,
                                       data,
                                       setData,
                                       rowSelection,
                                       setRowSelection,
                                       tableOptions,
                                       tableOptionsHandle
                                   }) => {

        const [modalCurrencyOpen, setModalCurrencyOpen] = useState(false);
        const [currentCurrencyBM, setCurrentCurrencyBM] = useState([]);

        const [updateModalOpen, setUpdateModalOpen] = useState(false);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
        const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);
        const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());

        const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
        const [createReserves, setCreateReserves] = useState(false)
        const [purchaseStatus, setPurchaseStatus] = useState(status !== 'IN_WORK');

        const handlePurchaseModalOpen = (create) => {
            setCreateReserves(create)
            setSetPurchaseModalOpen(true)
        }

        const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.original.id;
                const materialId = row.original.id
                MaterialPriceUnitService.delete(id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                        materialPriceUnits.delete(materialId);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleSubmitSetPurchase = async () => {
            const api = createReserves ? EstimateService.createReservesInWork : EstimateService.setPurchase
            api(orderId)
                .then(function () {
                    setPurchaseStatus(true);
                    handleCloseSetPurchase()
                })
                .catch(function (error) {
                    console.log(error);
                }).finally(() => setSetPurchaseModalOpen(false))
        }

        const handleCloseSetPurchase = () => {
            setSetPurchaseModalOpen(false);
        };

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const updateCurrencyByIds = async () => {
        }

        const handleCloseModalCurrency = () => {
            setModalCurrencyOpen(false);
        };

        const columns = useMemo(
            () => [
                {
                    id: 'articleNumber',
                    header: 'Артикул',
                    accessorKey: 'materialOrganizationOption.articleNumber',
                    minSize: 400,
                    grow: true,
                },
                {
                    id: 'name',
                    header: 'Найменование',
                    accessorKey: 'materialOrganizationOption.name',
                    minSize: 1000,
                    grow: true,
                    Cell: ({cell, row, rowIndex}) => {
                        const temp = row.original.materialOrganizationOption === null;
                        const isSlab = temp ? null : row.original.materialOrganizationOption.typeMaterial === 'SLAB';
                        const link = temp ? null : <Link
                            to={'/organization/orders/' + orderId + '/' + orderNumber + '/estimates/' + estimateId + '/' + false + '/reservation/'
                                + row.original.id + '/' + isSlab + '/' + encodeURIComponent(row.original.materialOrganizationOption.name)
                                + '/' + encodeURIComponent(row.original.materialOrganizationOption.articleNumber) + '/' + 'plan/true/' + status}>
                            {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                        </Link>
                        return (
                            <span
                                style={{
                                    color: temp ? 'red' : 'black'
                                }}>
                               {link}
                            </span>
                        );
                    },
                },
                {
                    id: 'quantity',
                    header: 'Кол.',
                    accessorKey: 'quantity',
                    minSize: 5,
                    maxSize: 5,
                    Cell: ({cell}) => cell.getValue().toFixed(3),
                },
                {
                    id: 'unit',
                    header: 'Ед. изм.',
                    accessorKey: 'unit.name',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    id: 'materialEstimateUnitState',
                    header: 'Статус',
                    accessorKey: 'materialEstimateUnitState',
                    minSize: 5,
                    maxSize: 5,
                    grow: true,
                    Cell: ({cell}) => materialEstimateUnitStates[cell.getValue()],
                },

                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                },
            ],
            [data, setData],
        );

        return (
            <Box sx={{maxWidth: "2000px"}}>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
                >
                    <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "20px"}}>
                        <ButtonCreate
                            text={"Создать резервы"}
                            width={200}
                            onClick={() => handlePurchaseModalOpen(true)}
                            disabled={purchaseStatus}
                        />
                        <ButtonCreate
                            text={"Подать резервы на закупку"}
                            width={280}
                            onClick={() => handlePurchaseModalOpen(false)}
                            disabled={purchaseStatus}
                        />
                    </Box>
                </Box>
                <Box sx={{overflow: 'auto'}}>
                    <MaterialReactTable
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: "8px 0"
                                }
                            }
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                p: '0px 0px 0px 10px',
                                m: '0px 0px 0px 0px',
                                border: "none",
                                "& td:after": {
                                    "&:hover": {
                                        backgroundColor: "red"
                                    }
                                },
                                "&:not(:last-of-type)": {
                                    borderRight: '1px solid #EAEBEA',

                                },
                                "&:first-of-type": {
                                    padding: 0,
                                    width: "50px",
                                    height: "36px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                },

                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }

                                }
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            backgroundColor: "red",
                            sx: {
                                backgroundColor: "red",
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        displayColumnDefOptions={{
                            'mrt-row-select': {
                                size: "50px",
                                grow: false,
                            },
                        }}
                        columns={columns}
                        data={data}
                        enableMultiRowSelection
                        enableRowSelection={true}
                        rowHeight={13}
                        columnSpacing={1}
                        initialState={{
                            columnVisibility: {
                                id: false,
                            },
                            isFullScreen: false,
                            density: "compact",
                            pagination: {pageIndex: 0, pageSize: 50},
                        }}

                        onRowSelectionChange={onRowSelectionChange}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                        getRowId={(originalRow) => originalRow.id}
                        rowCount={tableOptions.totalElements}
                        selectionPadding={1}
                        selectionCss={{
                            backgroundColor: 'lightblue',
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            borderColor: 'blue',
                            borderRadius: '4px',
                        }}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: Object.keys(rowSelection).length !== 0 ? `calc(100vh - 600px)` : `calc(100vh - 520px)`,
                                width: "2000px"
                            }
                        }}
                        enableStickyHeader={true}
                        enableStickyFooter={true}
                        enableToolbarInternalActions={false}
                        localization={MRT_Localization_RU}
                    />
                </Box>
                <ModalOpenSetValues
                    title={titleModalOpenSetValues}
                    label={labelModalOpenSetValues}
                    modalOpen={modalCurrencyOpen}
                    handleClose={handleCloseModalCurrency}
                    setValue={setCurrentCurrencyBM}
                    handleSubmit={updateCurrencyByIds}
                />
                <ModalConfirm
                    title={createReserves ? "Подтвердить создание резервов" : "Подать резервовы на закупку?"}
                    modalOpen={setPurchaseModalOpen}
                    handleClose={handleCloseSetPurchase}
                    handleSubmit={handleSubmitSetPurchase}
                    action={"Подтвердить"}/>
            </Box>
        );
    }
;

export default RequestsPurchaseTableItem;

