import React, {useCallback, useEffect, useState} from 'react';
import SupplierMaterialTable from "../../table/warehouse/material/supplier/SupplierMaterialTable";
import ModalCreateOrUpdateSupplierMaterial
    from "../../table/warehouse/material/supplier/ModalCreateOrUpdateSupplierMaterial";
import SupplierMaterialUtil from "../../../../utils/warehouse/SupplierMaterialUtil";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import MaterialStripeTable from "../../table/warehouse/material/materialStripe/MaterialStripeTable";
import SupplierService from "../../../../API/orders/warehouse/supplier/SupplierService";
import {ButtonGroupTabBar} from "../../button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";

const SupplierMaterialItemModule = ({
                                        id,
                                        materialId,
                                        baseUnit,
                                        typeMaterial,
                                        dataMaterialWholeData,
                                        dataMaterialMinStripeData,
                                        dataMaterialFixedStripeData,
                                        setDataMaterialWholeData,
                                        setDataMaterialMinStripeData,
                                        setDataMaterialFixedStripeData,
                                        allPrice,
                                        create,
                                        update,
                                        urlArchiveSM,
                                        urlArchiveMS,
                                        urlArchiveFS,
                                        addArchive,
                                        isFixPrice,
                                        currencyMap,
                                        tableOptions,
                                        tableOptionsHandle,
                                        handleError
                                    }) => {

    const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
    const [isNew, setIsNew] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [currencyId, setCurrencyId] = useState(null);

    const [supplierList, setSupplierList] = useState(null);
    const [supplierId, setSupplierId] = useState(null);
    const [price, setPrice] = useState(null);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [tab, setTab] = useState(1);

    useEffect(() => {
        getStartPageSize()
        tableOptionsHandle({...tableOptions, totalElements: dataMaterialWholeData?.length}, 2)
        SupplierService.get().then(function (response) {
            setSupplierList(response.data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);
    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const savedTab = pagesData["/organization/materials-supplier"]?.tab
        savedTab && setTab(savedTab)
    }

    const handleChangeTab = (tab) => {
        setTab(tab)
        EditLocalStoragePagesData(`/organization/materials-supplier`, "tab", tab)
        const totalElements = tab === 1 ? dataMaterialWholeData?.length : tab === 2 ? dataMaterialMinStripeData?.length : dataMaterialFixedStripeData?.length
        tableOptionsHandle({...tableOptions, totalElements: totalElements}, 2)
    }

    const handleAddSupplierMaterial = () => {
        setIsNew(true);

        const basePrice = {
            id: null,
            currency: currencyMap.get(1),
            price: "",
        };

        const supplierMaterial = {
            id: null,
            fixPrice: false,
            article: "",
            typeSupplierMaterial: "WHOLE",
            basePrice: basePrice,
        };

        setCurrentEditRowData(supplierMaterial);
        setCreateOrUpdateModalOpen(true);
    };

    const handleCloseCreateOrUpdate = () => {
        setCurrentEditRowData(null);
        setIsNew(null);
        setCurrencyId(null);
        setSupplierId(null);
        setPrice(null);
        setCreateOrUpdateModalOpen(false);
    };

    const handleSubmitCreateSupplierMaterial = () => {

        const currentSupplierId = supplierId === null ? supplierList[0]?.id : supplierId;
        const currentCurrencyId = currencyId === null ? currencyMap.get(1)?.id : currencyId;

        create(currentEditRowData, id, currentSupplierId, currentCurrencyId, price).then(function (response) {
            let data = response.data;
            handleCreateNewRow(data)
            handleCloseCreateOrUpdate();
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    const handleCreateNewRow = (values) => {
        SupplierMaterialUtil.deleteSupplierId(dataMaterialWholeData, supplierId === null ? supplierList[0].id : supplierId);
        setDataMaterialWholeData(dataMaterialWholeData);
        dataMaterialWholeData.push(values);
        setDataMaterialWholeData([...dataMaterialWholeData]);
    };

    const handleUpdateSupplierMaterial = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setCurrentEditRowId(row.index);
            setCreateOrUpdateModalOpen(true);
            setIsNew(false);
        },
        [setDataMaterialWholeData, currentEditRowData, setCurrentEditRowData],
    );

    const handleSubmitUpdateSupplierMaterial = () => {
        update(currentEditRowData,
        ).then(function (response) {
            let data = response.data;
            handleUpdate(data)
            handleCloseCreateOrUpdate();
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    const handleUpdate = (values) => {
        dataMaterialWholeData[currentEditRowId] = values;
        setDataMaterialWholeData([...dataMaterialWholeData]);
    };

    const renderContent = () => {
        switch (tab) {
            case 1:
                return <SupplierMaterialTable
                    data={dataMaterialWholeData}
                    setData={setDataMaterialWholeData}
                    handleAddSupplierMaterial={handleAddSupplierMaterial}
                    handleUpdateSupplierMaterial={handleUpdateSupplierMaterial}
                    isArchive={false}
                    id={id}
                    materialId={materialId}
                    baseUnit={baseUnit}
                    typeMaterial={typeMaterial}
                    allPrice={allPrice}
                    urlArchive={urlArchiveSM}
                    addArchive={addArchive}
                    currencyMap={currencyMap}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
            case 2:
                return <MaterialStripeTable
                    data={dataMaterialMinStripeData}
                    setData={setDataMaterialMinStripeData}
                    id={id}
                    materialId={materialId}
                    supplierList={supplierList}
                    isArchive={false}
                    urlArchive={urlArchiveMS}
                    baseUnit={baseUnit}
                    typeMaterial={typeMaterial}
                    currencyMap={currencyMap}
                    setSupplierId={setSupplierId}
                    setCurrencyId={setCurrencyId}
                    price={price}
                    setPrice={setPrice}
                    supplierId={supplierId}
                    currencyId={currencyId}
                    isMin={true}
                    allPrice={allPrice}
                    create={create}
                    update={update}
                    addArchive={addArchive}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
            case 3:
                return <MaterialStripeTable
                    data={dataMaterialFixedStripeData}
                    setData={setDataMaterialFixedStripeData}
                    id={id}
                    materialId={materialId}
                    supplierList={supplierList}
                    isArchive={false}
                    urlArchive={urlArchiveFS}
                    baseUnit={baseUnit}
                    typeMaterial={typeMaterial}
                    currencyMap={currencyMap}
                    setSupplierId={setSupplierId}
                    setCurrencyId={setCurrencyId}
                    price={price}
                    setPrice={setPrice}
                    supplierId={supplierId}
                    currencyId={currencyId}
                    isMin={false}
                    allPrice={allPrice}
                    create={create}
                    update={update}
                    addArchive={addArchive}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
        }
    }
    return (
        <div>
            <Box sx={{flexGrow: 1}}>
                <ButtonGroupTabBar activeTab={tab}>
                    <Button tabIndex={1} onClick={(e) => handleChangeTab(1)}>
                        <CheckIcon/>Основной
                    </Button>
                    <Button disabled={typeMaterial !== 'SLAB'} tabIndex={2} onClick={(e) => handleChangeTab(2)}>
                        <CheckIcon/>По минимальной полосе
                    </Button>
                    <Button disabled={typeMaterial !== 'SLAB'} tabIndex={3} onClick={(e) => handleChangeTab(3)}>
                        <CheckIcon/>По фиксированной полосе
                    </Button>
                </ButtonGroupTabBar>
                {renderContent()}
            </Box>

            <ModalCreateOrUpdateSupplierMaterial
                open={createOrUpdateModalOpen}
                onClose={handleCloseCreateOrUpdate}
                title={isNew ? 'Добавить базовую цену поставщика' : 'Редактировать базовую цену поставщика'}
                values={currentEditRowData}
                setValues={setCurrentEditRowData}
                labelButtonSSubmit={isNew ? 'Создать' : 'Обновить'}
                handleSubmit={isNew ? handleSubmitCreateSupplierMaterial : handleSubmitUpdateSupplierMaterial}
                supplierList={supplierList}
                setSupplierId={setSupplierId}
                currencyMap={currencyMap}
                setCurrencyId={setCurrencyId}
                price={price}
                setPrice={setPrice}
                isNew={isNew}
                isFixPrice={isFixPrice}
            />
        </div>
    );
};

export default SupplierMaterialItemModule;