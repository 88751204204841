import axios from "axios";
import {gatewayUrl, nameAuthorizationServer} from "../../Constants";

export default class CustomerUserEntity {
    static async getAll() {
        return axios.get(gatewayUrl + nameAuthorizationServer + "/api/v1/customers/customer-user-entity/own");
    }

    static async edit(data) {
        return axios.patch(gatewayUrl + nameAuthorizationServer + "/api/v1/customers/customer-user-entity/own", JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}