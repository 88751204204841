import React, {useMemo} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import {HeaderOPT, HeaderOPTAdditionally} from "./HeaderOPT";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import Box from "@mui/material/Box";
import CellOPTm from "./CellOPTm";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import Typography from "@mui/material/Typography";
import ButtonCreate from "../../../../button/ButtonCreate";

const OrderProductionTable = ({
                                  additionallyColumn,
                                  data,
                                  setData,
                                  customerList,
                                  nameList,
                                  planningList,
                                  isColumnPlan,
                                  isIssue,
                                  tableOptions,
                                  tableOptionsHandle
                              }) => {

    const navigate = useNavigate();
    // const arr = additionallyColumn.splice(2)
    // console.log(additionallyColumn, arr)

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: 'Номер заказа',
                accessorKey: 'number',
                filterFn: 'contains',
                minSize: 400,
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
                Cell: ({cell, row}) => (isIssue ? <li key={cell.getValue()}>
                            <Link
                                to={'/organization/issuance-piece-materials/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                        </li> :
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/technical-map/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                        </li>
                ),
            },
            {
                id: 'customerName',
                header: 'Заказчик',
                accessorKey: 'customerName',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                minSize: 300,
                grow: true,
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
            },
            {
                id: 'header',
                header: 'Найменование',
                accessorKey: 'header',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                minSize: 200,
                grow: true,
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
            },
            /* {
                 id: 'attributes',
                 header: 'Атрибуты',
                 accessorKey: 'attributes',
                 filterVariant: 'multi-select',
                 filterSelectOptions: attributeList,
                 filterFn: (row, id, filterValue) => {
                     return OrderUtil.checkStatus(row.getValue(id), filterValue)
                 },
                 minSize: 20,
                 Cell: ({cell}) => (<div>{AttributeUtil.convertAttributes(cell.getValue())}</div>),
                 Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,

             },*/
            {
                accessorFn: (row) => {
                    if (row.dateOfPlannedProduction === null) {
                    } else {
                        return new Date(row.dateOfPlannedProduction)
                    }
                },
                id: 'dateOfPlannedProduction',
                grow: true,
                header: 'Плановая дата произодства',
                sortingFn: 'datetime',
                minSize: 200,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
            },
        ];

        {
            additionallyColumn.map((statusKey) => (
                baseColumns.splice(5, 0, {
                    id: statusKey.name,
                    header: statusKey.name,
                    accessorKey: 'id',
                    maxSize: 34,
                    size: 34,
                    minSize: 1,
                    grow: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableColumnFilter: false,
                    enableSorting: false,
                    Header: ({column}) => <HeaderOPTAdditionally column={column} height={400}></HeaderOPTAdditionally>,
                    Cell: ({row, cell}) => <CellOPTm
                        row={row}
                        cell2={cell}
                    >
                    </CellOPTm>,
                })
            ))
        }

        if (isColumnPlan) {
            baseColumns.splice(5, 0, {
                id: 'state',
                header: 'Планирование',
                accessorKey: 'state',
                filterVariant: 'multi-select',
                filterSelectOptions: planningList,
                minSize: 50,
                maxSize: 6,
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
            });
        }

        return baseColumns;

    }, [data, setData, isColumnPlan, additionallyColumn]);

    return (
        <Box sx={{maxWidth: `${1200 + additionallyColumn.length * 35}px`, overflow: 'auto',}}>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                    Производство
                </Typography>

                <ButtonCreate
                    text={"Планы"}
                    width={80}
                    onClick={redirectPlans}
                    icon={false}
                />
            </Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: `${1200 + additionallyColumn.length * 35}px`,
                        maxHeight: `calc(100vh - 500px)`
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        padding: '0px 0px 0px 10px',
                        margin: 0,
                        border: '1px solid #EAEBEA',
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },

                        "&:nth-of-type(n+5)": {
                            padding: 0,
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: '0px 0px 0px 10px',
                        "&:nth-of-type(n+5)": {
                            padding: 0,
                        },
                        "& .Mui-TableHeadCell-Content": {
                            alignItems: "end",
                            "& .Mui-TableHeadCell-Content-Wrapper": {
                                justifyContent: "center",
                                display: "flex",
                            },
                            "& .Mui-TableHeadCell-Content-Labels": {
                                alignItems: "end",


                                "& span": {
                                    marginBottom: "3px"
                                }
                            }
                        },
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                enableColumnFilterModes
                enableGrouping
                enableStickyHeader
                enablePinning
                rowHeight={13}
                columnSpacing={0}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                        customerId: false,
                        dateOfPlannedProduction: false
                    },
                    isFullScreen: false,
                    density: "compact",
                    columnPinning: {left: ['number']},
                }}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
                enableRowActions={false}
                enableToolbarInternalActions={false}
            />
        </Box>
    );
};

export default OrderProductionTable;