import React, {useEffect, useState} from 'react';
import ModelsTable from "../../../../components/UI/table/model-estimate/model/ModelsTable";
import ModelService from "../../../../API/model-estimate/model/ModelService";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";

const ModelsSetting = ({itemId,handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});
    const [change, setChange] = useState(null);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        ModelService.getAll().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            /*  setModelHeaderList(DataUtil.getListChildField(response.data, 'header', 'name'));*/
            data.forEach((row) => {
                unable[row.id] = row.enabled;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (
        <div>
            <ModelsTable
                data={data}
                setData={setData}
                unable={unable}
                setUnable={setUnable}
                isButtonDelete={true}
                isButtonBasicModels={true}
                isButtonCreateModel={true}
                isButtonSettings={true}
                isButtonUpdate={true}
                enableRowSelection={true}
                isColumnUnable={true}
                isRedirect={true}
                isBasicModel={true}
                upLoad={true}
                change={change}
                setChange={setChange}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        </div>
    );
};

export default ModelsSetting;