export const estimateTableParams = {
    "COST": "Себестоимость материалов",
    "TOTALCOST": "Себестоимость итого",
    "PROFIT": "Прибыль",
    "JOB": "Стоимость работ",
    "JOBCOST": "Себестоимость работ",
    "GROSSMARGIN": "Валовая маржа",
    "SALES": "Стоимость итого",
    "STATE": "Статус",
    "PRICE": "Стоимость материалов"
}