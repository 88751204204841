import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Step,
    StepLabel,
    Stepper, Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import JobMetricOrganizationService from "../../../../../../API/orders/job/JobMetricOrganizationService";
import JobMetricOrganizationTable from "../../../prodaction/jobmetricorganization/JobMetricOrganizationTable";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonBack from "../../../../button/ButtonBack";

const ModalAddJobMetric = ({open, workData, onClose, handleSubmit, currency, handleError}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [materialEstimateUnit, setMaterialEstimateUnit] = useState(workData[0])
    const [dataJobMetricOrganization, setDataJobMetricOrganization] = useState([]);
    const [selectedString, setSelectedString] = useState({});
    const [formState, setFormState] = useState({quantity: ""})
    const [validationState, setValidationState] = useState({
        "quantity": ""
    })
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 15,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }

    useEffect(() => {
        setMaterialEstimateUnit(workData[0])
    }, [workData]);

    const handleClose = () => {
        onClose()
        setMaterialEstimateUnit(workData[0])
        setFormState({quantity: ""})
        setActiveStep(0)
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit({
                jobMetricOrganizationId: selectedString.id,
                materialEstimateUnitId: materialEstimateUnit.id,
                quantity: formState.quantity
            })
            handleClose()
        }
    }

    const submit = () => {
        if (activeStep === 0) {
            JobMetricOrganizationService.getByMaterialOrganizationOption(materialEstimateUnit?.materialOrganizationOption?.id)
                .then(function (response) {
                    setDataJobMetricOrganization(response.data)
                    setSelectedString(response.data[0])
                    setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
                })
                .catch(function (error) {
                    handleError(error);
                })
            setActiveStep(1)
        } else if (activeStep === 1) {
            setActiveStep(2)
        } else {
            handleValidationData()
        }
    }
    return <Dialog open={open} PaperProps={{
        style: {
            minWidth: activeStep === 1 ? "1650px" : activeStep === 0 ? "800px" : "600px",
            height: activeStep === 1 ? "1120px" : activeStep === 0 ? "800px" : "600px"
        }
    }}>
        <Box style={{
            padding: 40,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "center"
        }}>
            <DialogTitle sx={{
                color: (theme) => theme.palette.text.main,
                margin: "auto",
                padding: 0,
                fontSize: 24,
                fontWeight: 700
            }}>Добавить работу</DialogTitle>
            <Stepper
                alternativeLabel
                style={{width: "100%"}}
                activeStep={activeStep}
            >
                {["Материал", "Наименование операции", "Количество"].map((label) => (
                    <Step key={label} sx={{
                        "& .MuiStepConnector-root": {
                            top: "20px",
                            left: 'calc(-50% + 30px)',
                            right: "calc(50% + 30px)"
                        }
                    }}>
                        <StepLabel sx={{
                            ".MuiSvgIcon-root": {
                                fontSize: 40,
                                color: "#737478",
                                "&.Mui-active": {
                                    color: "#3B8BEC",
                                }
                            },
                            "& .MuiStepLabel-label": {
                                fontSize: 18,
                                "&.Mui-active": {
                                    fontWeight: 700,
                                }
                            },

                        }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
        <DialogContent sx={{}}>
            {activeStep === 0
                ?
                <Box
                    style={{width: "100%"}}
                >
                    <RadioGroup
                        value={materialEstimateUnit?.id || workData[0]?.id}
                        onChange={(event) => setMaterialEstimateUnit(workData.find(el => el.id === +event.target.value))}
                    >
                        {workData.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={option.id}
                                    control={<Radio/>}
                                    label={option.materialOrganizationOption.name}
                                    sx={{
                                        height: "36px",
                                        "&:not(:last-of-type)": {
                                            marginBottom: "4px",
                                        },
                                        borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                        "&:hover": {
                                            backgroundColor: (theme) => theme.palette.primary.light
                                        }
                                    }
                                    }

                                />
                            )
                        )}
                    </RadioGroup>
                </Box> : activeStep === 1
                    ?
                    <Box
                        style={{width: "100%"}}
                    >
                        <Box sx={{
                            padding: "8px 14px",
                            fontSize: "18px",
                            border: "rgba(0, 0, 0, 0.23) 1px solid",
                            borderRadius: "4px",
                            '& .MuiTypography-root': {
                                fontWeight: (theme) => theme.typography.fontWeight,
                                color: (theme) => theme.palette.text.main,
                                '&:first-of-type': {
                                    marginRight: '10px'
                                }
                            },
                        }}>
                            <Typography
                                component="p">{materialEstimateUnit.materialOrganizationOption.name}
                            </Typography>
                        </Box>
                        <JobMetricOrganizationTable
                            data={dataJobMetricOrganization}
                            isModal={true}
                            columnAdded={false}
                            baseCurrency={currency[0].value}
                            alternativeCurrency={currency[1].value}
                            selectedString={selectedString}
                            setSelectedString={setSelectedString}
                            tableOptions={tableOptions}
                            tableOptionsHandle={tableOptionsHandle}
                            handleError={handleError}/>
                    </Box> :
                    <Box sx={{display: "flex", flexDirection: "column", gap: "10px"}}><Box sx={{
                        padding: "8px 14px",
                        fontSize: "18px",
                        border: "rgba(0, 0, 0, 0.23) 1px solid",
                        borderRadius: "4px",
                        '& .MuiTypography-root': {
                            fontWeight: (theme) => theme.typography.fontWeight,
                            color: (theme) => theme.palette.text.main,
                            '&:first-of-type': {
                                marginRight: '10px'
                            }
                        },
                    }}>
                        <Typography
                            component="p">{materialEstimateUnit.materialOrganizationOption.name}</Typography>
                    </Box>
                        <Box sx={{
                            padding: "8px 14px",
                            fontSize: "18px",
                            border: "rgba(0, 0, 0, 0.23) 1px solid",
                            borderRadius: "4px",
                            '& .MuiTypography-root': {
                                fontWeight: (theme) => theme.typography.fontWeight,
                                color: (theme) => theme.palette.text.main,
                                '&:first-of-type': {
                                    marginRight: '10px'
                                }
                            },
                        }}>
                            <Typography component="p">{selectedString.metricName}</Typography>
                        </Box>
                        <TextFieldValidation
                            margin={"none"}
                            name={"quantity"}
                            label={"Количество"}
                            value={formState.quantity}
                            setCurrValues={setFormState}
                            validationError={validationState?.quantity}
                            setValidationState={setValidationState}
                            adornment={selectedString.unit?.name}
                        /></Box>}
        </DialogContent>
        <DialogActions style={{padding: "0 40px 40px 40px", display: "flex", justifyContent: "space-between"}}>
            <Box>{activeStep !== 0 &&
                <ButtonBack text={"Назад"} onClick={() => setActiveStep(prev => prev - 1)}/>} </Box>
            <Box>
                <ButtonExit text={"Выйти"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={submit}/>
            </Box>
        </DialogActions>
    </Dialog>
}
export default ModalAddJobMetric