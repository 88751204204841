import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";

const productionCostsUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/production-costs'

export default class ModelProductionCostsService {

    static async create(jobMetricModelOrganizationId, data) {
        return await axios.post(productionCostsUrl + '?jobMetricModelOrganizationId=' + jobMetricModelOrganizationId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(productionCostsUrl + '/' + id);
    }

    static async update(id,data) {
        return await axios.put(productionCostsUrl + '/' + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}