import React, {useState} from 'react';
import MaterialPageItemModule from "../../../../../components/UI/modul/MaterialPageItemModule";
import {BreadcrumbsComponent} from "../../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAlert from "../../../../../components/UI/table/customer-detales/ModalAlert";

const Materials = () => {
    const [alertData, setAlertData] = useState({});
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    return <Box sx={{padding: "0 30px"}}>
        <BreadcrumbsComponent page={"Материалы"} path={[{name: "Главная", href: "/"}]}/>
        <MaterialPageItemModule
            refMaterial={true}
            isAddMaterial={true}
            pageSize={30}
            nameMethodGetMaterial={'with-group-material-unit'}
            handleError={handleError}
        />
        <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
            return {...alert, open: false}
        })}/>
    </Box>
};

export default Materials;