import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import CurrencyService from "../../../../../API/common/CurrencyService";
import SupplierService from "../../../../../API/orders/warehouse/supplier/SupplierService";
import WarehouseService from "../../../../../API/orders/warehouse/WarehouseService";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import {materialReserveState} from "../../../../../data/MaterialReserveState";
import WarehouseMaterialSettingsService from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";

const IssuingEdgeMaterialMaterialReserveTable = ({
                                                     data,
                                                     setData,
                                                     isColumnOrder,
                                                     isColumnMaterialWarehouse,
                                                     isColumnOptimization,
                                                     enableGrouping,
                                                     expanded,
                                                     pageSize,
                                                     orderList,
                                                     change,
                                                     setChange,
                                                     columnGrouping,
                                                     titleModalUpdateOrAddMaterialArrival,
                                                     tableOptions,
                                                     tableOptionsHandle,
                                                     handleError
                                                 }) => {

    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);


    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
    const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);


    const [messageError, setMessageError] = useState(null);
    const [messageInfo, setMessageInfo] = useState(null);
    const [rowSelection, setRowSelection] = useState({});

    const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
    const [date, setDate] = useState(null);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [materialDeliveryDate, setMaterialDeliveryDate] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [currencyId, setCurrencyId] = useState(null);
    const [supplierList, setSupplierList] = useState([]);
    const [supplierId, setSupplierId] = useState(null);
    const [warehouseList, setWarehouseList] = useState([]);
    const [warehouseId, setWarehouseId] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        CurrencyService.getAll().then(function (response) {
            let data = response.data;
            data.sort((a, b) =>
                a.defaultBase === b.defaultBase ? 0 : a.defaultBase ? -1 : 1
            );
            setCurrencyList(data);
        })
            .catch(function (error) {
                handleError(error);
            });
        SupplierService.get().then(function (response) {
            let data = response.data;
            setSupplierList(data);
        })
            .catch(function (error) {
                handleError(error);
            });
        WarehouseService.getAll().then(function (response) {
            let data = response.data;
            setWarehouseList(data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'materialArticleNumber',
                header: 'Артикул',
                accessorKey: 'materialArticleNumber',
                minSize: 70,
            },
            {
                id: 'materialName',
                header: 'Найменование',
                accessorKey: 'materialName',
                minSize: 70,

            },
            {
                id: 'quantity',
                header: 'Необходимо.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'primary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
            },
            {
                id: 'availabilityQuantity',
                header: 'Наличие',
                accessorKey: 'availabilityQuantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'success.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
            },
            {
                id: 'materialReserveState',
                header: 'Статус',
                accessorKey: 'materialReserveState',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell}) => materialReserveState[cell.getValue()],
            },
            {
                id: 'orderNumber',
                header: 'Заказы',
                accessorKey: 'orders',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    return OrderUtil.convertNumberOrders(cell.getValue())
                },
                filterVariant: 'multi-select',
                filterSelectOptions: orderList,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkNumberOrder(row.getValue(id), filterValue)
                },
            },
            {
                id: 'plan.number',
                header: 'План',
                accessorKey: 'plan.number',
                minSize: 15,
                maxSize: 15,
            },

            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ];

        return baseColumns;

    }, [data, setData, isColumnOrder]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyCellProps={({cell, table}) => {
                    return ({

                        sx: () => ({
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',
                            },
                            "&": cell.column.id === "requests_cutting" && {
                                width: 'calc(100% - 1px)',
                                padding: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            },

                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        })
                    })
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        },
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,

                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        backgroundColor: (theme) => theme.palette.primary.light,
                    }
                }}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                rowCount={tableOptions.totalElements}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableToolbarInternalActions={false}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    expanded: expanded,
                    grouping: columnGrouping,
                }}
                enableGrouping={enableGrouping}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default IssuingEdgeMaterialMaterialReserveTable;