import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import {useLocation, useParams} from "react-router-dom";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import CustomerDetailsTable from "../../../../components/UI/table/customer-detales/CustomerDetailsTable";
import {constantsErrorMessages} from "../../../../Constants";
import MaterialTree from "../../../../components/UI/tree/MaterialTree";
import GroupMaterialUtil from "../../../../utils/orders/GroupMaterialUtil";
import SamplesControllerServiceGroup from "../../../../API/samples/Customers/SamplesGroup";
import SamplesControllerService from "../../../../API/samples/Customers/SamplesController";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import SamplesControllerServiceGroupAdmin from "../../../../API/samples/Admin/SamplesGroupAdmin";
import TemplateControllerServiceGroup from "../../../../API/samples/Customers/SamplesGroup";
import SamplesControllerServiceAdmin from "../../../../API/samples/Admin/SamplesControllerAdmin";

const CustomerSamples = ({system, admin, modal, setSelectedSamples, selectedSamples}) => {
    const pathname = useLocation().pathname
    const params = useParams();
    const {orderId, customerOrderNumber, requestsCuttingId, requestsCuttingNumber} = params

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'

    const [filterGroup, setFilterGroup] = useState(new Map());
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);
    const [dataTree, setDataTree] = useState([]);
    const [groupMap, setGroupMap] = useState(null);
    const [currentGroup, setCurrentGroup] = useState(null);

    const [rowSelection, setRowSelection] = useState([]);

    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({unableTurn: {}, unablePackage: {}});

    const [change, setChange] = useState(false);
    const [tableOptions, setTableOptions] = useState({pageIndex: 0, pageSize: 20, totalElements: 0, searchData: ""})


    const handleError = (error) => {
        setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const tableOptionsHandle = (nextState) => {

        if (nextState.pageSize !== tableOptions.pageSize) {
            setTableOptions(nextState)
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }
    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }

    useEffect(() => {
        const api = admin ? SamplesControllerServiceAdmin : SamplesControllerService
        currentGroup && api.getAll(tableOptions.pageIndex + 1, tableOptions.pageSize, currentGroup)
            .then(function (response) {
                const data = response?.data.detailSamples
                setData(data);
                setTableOptions((prev) => ({...prev, totalElements: response?.data.totalElements}))
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
    }, [change, currentGroup, tableOptions.searchData, tableOptions.pageIndex, tableOptions.pageSize]);

    useEffect(() => {
        getStartPageSize()
        const api = admin ? SamplesControllerServiceGroupAdmin.getAll() : system ? SamplesControllerServiceGroup.getAllRoot() : SamplesControllerServiceGroup.getAll()
        api.then((response) => {
            const buildTreeGroups = GroupMaterialUtil.buildTreeGroupsSamples(response.data);
            setDataTree(buildTreeGroups);
            const groupMap = new Map();
            GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
            setGroupMap(groupMap);
            setCurrentGroup({})
        }).catch(function (error) {
            handleError(error)
        });
    }, []);


    return (
        isLoading ?
            <Box sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> :
            <Box sx={{padding: modal ? "0px" : "0 30px"}}>
                {!modal && !admin && <BreadcrumbsComponent
                    page={system ? "Шаблоны системы" : "Мои шаблоны"}
                    path={[
                        {name: "Главная", href: "/"},
                    ]}/>}
                {admin && <Typography sx={{fontSize: "24px", fontWeight: 700}}> Шаблоны системы</Typography>}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}>
                    <Box sx={{
                        flexBasis: '50%',
                        maxWidth: '225px',
                        paddingTop: Object.keys(rowSelection).length !== 0 ? "108px" : "49px"
                    }}>
                        <MaterialTree
                            name={"Группы"}
                            filterGroup={filterGroup}
                            setFilterGroup={setFilterGroup}
                            changeFilterGroup={changeFilterGroup}
                            setChangeFilterGroup={setChangeFilterGroup}
                            data={dataTree}
                            groupMap={groupMap}
                            currentGroup={currentGroup}
                            setCurrentGroup={setCurrentGroup}
                        />
                    </Box>
                    <Box sx={{
                        flexBasis: 'auto',
                        marginLeft: '16px',
                        overflow: 'auto',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px'
                    }}>
                        <CustomerDetailsTable
                            data={data}
                            materialTreeData={{dataTree, groupMap}}
                            setChange={setChange}
                            unableTurn={unable.unableTurn}
                            unablePackage={unable.unablePackage}
                            pageSize={100}
                            isError={isError}
                            setData={setData}
                            enableEditing={true}
                            orderId={orderId}
                            samples={true}
                            system={system}
                            admin={admin}
                            customerOrderNumber={customerOrderNumber}
                            requestsCuttingNumber={requestsCuttingNumber}
                            tableOptionsHandle={tableOptionsHandle}
                            tableOptions={tableOptions}
                            modal={modal}
                            selectedSamples={selectedSamples}
                            setSelectedSamples={setSelectedSamples}
                        />
                    </Box>
                </Box>
            </Box>
    )
}
export default CustomerSamples
