import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import FilesTable from "../../../../components/UI/table/common/files/FilesTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import RequestsCuttingEstimateItem from "../estimate/RequestsCuttingEstimateItem";
import TechnicalMapTable from "../../../../components/UI/table/orders/organization/technicalmaptable/TechnicalMapTable";
import JobService from "../../../../API/orders/job/JobService";
import ModalConfirm from "../../../../components/UI/modal/ModalConfirm";
import EstimateService from "../../../../API/orders/estimate/organization/EstimateService";
import MaterialEstimateUnitService from "../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import EstimateTable from "../../../../components/UI/table/orders/organization/estimate/EstimateTable";
import UploadOrderEstimateFileForm from "../../../../components/form/UploadOrderEstimateFileForm";
import {
    UpdateOrAddBasicModelsModule
} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddBasicModelsModule";
import {UpdateOrAddModelsModule} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddModelsModule";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import CheckIcon from "@mui/icons-material/Check";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../../Constants";
import MenuItem from "@mui/material/MenuItem";
import Models from "../../model-estimate/models/Models";

const OrderItem = () => {
        const profile = JSON.parse(localStorage.getItem("profile"))
        const currency = [
            {tab: 1, type: "baseCurrency", value: profile?.baseCurAbbreviation},
            {tab: 2, type: "alternativeCurrency", value: profile?.alternativeCurAbbreviation}]

        const [currentCurrency, setCurrentCurrency] = useState(1);
        const [alertData, setAlertData] = useState({});

        const params = useParams();
        const navigate = useNavigate();
        const year = params.year;
        const nameOrder = params.number;
        const orderId = params.id;
        const orderIdHeader = params.idHeader;

        const [change, setChange] = useState(null);
        const [tab, setTab] = useState(4);
        const [technicalMapData, setTechnicalMapData] = useState([]);
        const [inWorkModalOpen, setInWorkModalOpen] = useState(false);
        const [modalBasicModelsOpen, setBasicModalModelsOpen] = useState(false);
        const [modalModelsOpen, setModalModelsOpen] = useState(false);
        const [basicModelId, setBasicModelId] = useState(null);
        const [data, setData] = useState([]);
        const [materialEstimateUnits, setMaterialEstimateUnits] = useState([]);

        const [tableOptions, setTableOptions] = useState({
            pageIndex: 0,
            pageSize: 20,
            totalElements: 0,
            searchData: ""
        })
        const handleError = (error) => {
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }

        const getStartPageSize = () => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const savedTab = pagesData[`/organization/orders/${orderId}`]?.tab
            savedTab && setTab(savedTab)

            getTabsData(savedTab, true)
        }
        const tableOptionsHandle = (nextState, currTab) => {
            setTableOptions(nextState)
            if (nextState.pageSize !== tableOptions.pageSize) {
                EditLocalStoragePagesData(`/organization/orders/${orderId}+${tab}`, "tableOptions", nextState)
            }
        }

        const redirectSetAttribute = () => {
            navigate('/organization/orders/set-attribute/' + orderId + '/' + nameOrder + '/' + orderIdHeader + '/' + year);
        };

        const handleSubmitSetInWork = async () => {
            EstimateService.setInWork(orderId)
                .then(function () {
                    handleCloseSetPurchase()
                    /*         setCurrentInWork(false);*/
                })
                .catch(function (error) {
                    handleError(error)
                })
        }

        const handleCloseSetPurchase = () => {
            setInWorkModalOpen(false);
        };

        useEffect(() => {
            getStartPageSize()
            EstimateService.getAllByOrderId(orderId).then(function (response) {
                let data = response.data;
                setData([data])
            })
                .catch(function (error) {
                    handleError(error)
                });
            MaterialEstimateUnitService.getAllByOrderId(orderId).then(function (response) {
                const data = response.data;
                setMaterialEstimateUnits(data);
            })
                .catch(function (error) {
                    handleError(error)
                });

            JobService.getJobsByOrderId(orderId).then(function (response) {
                setTechnicalMapData(response.data);
            })
                .catch(function (error) {
                    handleError(error)
                });
        }, [change]);

        const openBasicModels = () => {
            setBasicModalModelsOpen(true);
        };

        const closeModels = () => {
            setModalModelsOpen(false);
            setBasicModelId(null);
        };

        const closeBasicModels = () => {
            setBasicModalModelsOpen(false);
        };

        const getTabsData = (tab, start) => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const tableOptions = pagesData[`/organization/orders/${orderId}+${tab}`]?.tableOptions

            let totalElements
            if (tab === 5) {
                totalElements = technicalMapData?.length
            } else {
                totalElements = tableOptions?.totalElements
            }

            setTableOptions((prev) => ({
                ...prev,
                pageSize: tableOptions?.pageSize || 20,
                totalElements: start ? tableOptions?.totalElements : totalElements
            }))
        }
        const handleChangeTab = (tab) => {
            setTab(tab)
            getTabsData(tab)
            EditLocalStoragePagesData(`/organization/orders/${orderId}`, "tab", tab)
        }
        const generalTabComponents = [
            {
                tabIndex: 1, label: "Файлы",
                component:
                    <FilesTable
                        nameOrder={nameOrder}
                        year={year}
                        orderId={orderId}
                        change={change}
                        setChange={setChange}
                        handleError={handleError}
                    />
            }, {
                tabIndex: 2, label: "Заявки на раскрой",
                component:
                    <RequestsCuttingEstimateItem
                        getAll={RequestsCuttingService.getAllByOrderIdWithMaterial}
                        isOptimization={true}
                        isRedirectRequestsCutting={true}
                        orderId={orderId}
                        nameOrder={nameOrder}
                        idHeader={orderIdHeader}
                        year={year}
                        isOrder={true}
                        isButtonAddRequestsCutting={true}
                        isEstimate={true}
                        isCreateOptimizationCalculation={true}
                        isSetForOptimization={true}
                        isRedirectOptimizations={true}
                        isRedirectCalculationOptimizations={true}
                        isRedirectCalculationCalculationWorkingCuttingMaps={true}
                        isRedirectCalculationRequestsCuttings={true}
                        customer={false}
                        change={change}
                        setChange={setChange}
                        handleError={handleError}
                    />
            },
            {
                tabIndex: 3, label: "Модули",
                component: <Models
                    orderId={orderId}
                    estimateId={data[0]?.id}
                    setChange={setChange}
                    handleError={handleError}/>
            },
            {
                tabIndex: 4, label: "Смета",
                component: <Box
                    sx={{display: "flex", gap: "120px"}}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <UploadOrderEstimateFileForm
                            year={year}
                            orderId={orderId}
                            label={'Загрузить смету проекта'}
                            change={change}
                            setChange={setChange}
                            handleError={handleError}
                        />
                        <TextField
                            sx={{width: "240px"}}
                            size="small"
                            select
                            label="Валюта"
                            value={currentCurrency}
                            onChange={(e) => setCurrentCurrency(e.target.value)}
                        >
                            {currency.map((option) => (
                                <MenuItem key={option.tab} value={option.tab}>
                                    {option.value}
                                </MenuItem>))}
                        </TextField>
                    </Box>
                    <EstimateTable
                        data={data}
                        setData={setData}
                        orderId={orderId}
                        orderNumber={nameOrder}
                        orderHeaderId={orderIdHeader}
                        year={year}
                        enableEditing={true}
                        currentCurrency={currentCurrency}
                        handleError={handleError}
                    />
                </Box>
            },
            {
                tabIndex: 5, label:
                    "Тех карта",
                component:
                    <Box>
                        <Typography component="p" sx={{paddingBottom: "10px", fontSize: "24px", fontWeight: 700}}>
                            Техническая карта заказа: {params.order}
                        </Typography>
                        <TechnicalMapTable
                            data={technicalMapData}
                            setData={setTechnicalMapData}
                            estimateIsCurrent={true}
                            tableOptions={tableOptions}
                            tableOptionsHandle={tableOptionsHandle}
                            handleError={handleError}
                        />
                    </Box>
            }
        ]
        const name = nameOrder === "null" ? "Не занумирован" : nameOrder

        return (
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={name}
                    path={[
                        {name: "Главная", href: "/"},
                        {name: "Заказы", href: "/organization/orders"},
                    ]}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                            Заказ: {name}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex', gap: "10px"
                        }}
                    >
                        <ButtonCreate
                            text={"Добавить атрибут"}
                            width={160}
                            icon={false}
                            onClick={redirectSetAttribute}
                        />
                        <ButtonCreate
                            text={"Отправить заказ в работу"}
                            width={230}
                            icon={false}
                            onClick={() => setInWorkModalOpen(true)}
                        />

                    </Box>
                    <ButtonGroupTabBar activeTab={tab}>
                        {generalTabComponents.map(({tabIndex, label}, index) => (
                            <Button key={tabIndex} tabIndex={tabIndex} onClick={() => handleChangeTab(tabIndex)}>
                                <CheckIcon/>
                                {label}
                            </Button>
                        ))}
                    </ButtonGroupTabBar>
                    {generalTabComponents.find(component => component.tabIndex === tab)?.component}
                    <ModalConfirm
                        title={"Подтвердить заказ в работу?"}
                        modalOpen={inWorkModalOpen}
                        handleClose={handleCloseSetPurchase}
                        handleSubmit={handleSubmitSetInWork}
                        action={"Подтвердить"}
                    />
                    <UpdateOrAddBasicModelsModule
                        open={modalBasicModelsOpen}
                        setBasicModelId={setBasicModelId}
                        setModalModelsOpen={setModalModelsOpen}
                        onClose={closeBasicModels}
                        orderIdHeader={orderIdHeader}
                        handleError={handleError}
                    />
                    <UpdateOrAddModelsModule
                        open={modalModelsOpen}
                        setModelsOpen={setModalModelsOpen}
                        setBasicModalModelsOpen={setBasicModalModelsOpen}
                        onClose={closeModels}
                        basicModelId={basicModelId}
                        orderId={orderId}
                        change={change}
                        setChange={setChange}
                    />
                </Box>
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
        );
    }
;

export default OrderItem;