export const materialReserveState = {
    "CALCULATION" : "Расчетный",
    "IN_WORK" : "В работе",
    "WAITING_FOR_AN_APPLICATION" : "Ожидание закупки",
    "EXECUTION_OF_APPLICATION": "Оформление закупки",
    "RECONCILIATION_OF_DATA": "Согласование закупки",
    "WAITING_FOR_DELIVERY": "Ожидание доставки",
    "IN_STOCK": "В наличии",
    "ISSUED" : "Выдан",
    "ARCHIVE" : "Архив"
}

export const materialReserveChoice = {
    "CALCULATION" : "Расчетный",
    "WAREHOUSE" : "Со склада",
}