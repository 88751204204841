import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";
import axios from "axios";

const restUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/admin/suppliers'

export default class AdminSuppliersService {
    static async get() {
        return axios.get(restUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        return await axios.post(restUrl + '/' + id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(restUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(restUrl + '/' + id);
    }
}