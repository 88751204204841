import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import ModalReplacementWorker from "./ModalReplacementWorker";
import WorkerUtil from "../../../../../../utils/orders/WorkerUtil";
import ModalExecuteJob from "./ModalExecuteJob";
import JobService from "../../../../../../API/orders/job/JobService";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import MenuItem from "@mui/material/MenuItem";
import {Tooltip} from "@mui/material";

const TechnicalMapTable = ({
                               data, setData, estimateIsCurrent, tableOptions,
                               tableOptionsHandle, handleError
                           }) => {

    const [openModalSimilarWorker, setOpenModalSimilarWorker] = useState(false);
    const [openModalExecuteJob, setOpenModalExecuteJob] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const columns = useMemo(
        () => [
            {
                header: 'Тип работы',
                accessorKey: 'typeJobName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
                maxSize: 50,
            },
            {
                header: 'Материал',
                accessorKey: 'materialName',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 50,
            },
            {
                header: 'Количество',
                accessorKey: 'count',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 10,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                },
            },
            {
                id: 'unitName',
                header: 'Ед.изм.',
                accessorKey: 'unitName',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'НВ/ч',
                accessorKey: 'normOfTime',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                },
            },
            {
                header: 'Отметки',
                accessorKey: 'jobWorkers',
                id: 'jobWorkers',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => (<div>
                        {WorkerUtil.convertWorkers(cell.getValue())}
                    </div>
                )
            },
            {
                accessorFn: (row) => {
                    if (row.completionDateTime === null) {
                    } else {
                        return new Date(row.completionDateTime)
                    }
                },
                header: 'Дата выполнения',
                id: 'completionDateTime',
                maxSize: 8,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'Описание',
                accessorKey: 'metricName',
                id: 'metricName',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
        ],
        [data, setData],
    );

    const [currentIdRow, setCurrentIdRow] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const similarWorker = useCallback(
        (row) => {
            setCurrentIdRow(row.id);
            setCurrentRow(row);
            setOpenModalSimilarWorker(true)
        },
        [data],
    );

    const executeJob = useCallback(
        (row) => {
            setCurrentIdRow(row.id);
            setCurrentRow(row);
            setOpenModalExecuteJob(true)
        },
        [data],
    );

    const closeExecuteJob = () => {
        setCurrentIdRow(null);
        setCurrentRow(null);
        setOpenModalExecuteJob(false)
    };

    const closeSimilarWorker = () => {
        setCurrentIdRow(null);
        setCurrentRow(null);
        setOpenModalSimilarWorker(false)
    };

    const onExecute = () => {
        JobService.executeJob(currentIdRow, DateTimeUtil.dateConvert(currentDate))
            .then(function () {
                setData(prev => prev.map(el => el.id === currentIdRow ? {
                    ...el,
                    completed: true,
                    completionDateTime: currentDate
                } : el))
                closeExecuteJob();
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: similarWorker},
        {type: "execute", text: "Выполнить работу", handler: executeJob}
    ]
    return (
        <div>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        },
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,

                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        backgroundColor: (theme) => theme.palette.primary.light,
                    }
                }}
                columns={columns}
                data={data}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                rowCount={tableOptions.totalElements}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowHeight={13}
                columnSpacing={1}
                enableGrouping={true}
                initialState={{
                    expanded: true,
                    grouping: ['typeJobName'],
                    columnVisibility: {
                        id: false,
                        customerId: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                getRowId={(originalRow) => originalRow.id}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    const active = (el.type === "edit" && !row.original.completed) || (el.type === "execute" && (!row.original.completed && estimateIsCurrent))
                    return <Tooltip arrow placement="left"
                                    title={!active ? "заблокировано" : ""}>
                        <MenuItem
                            key={el.type}
                            onClick={() => {
                                active && el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                opacity: !active && 0.5,
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem></Tooltip>
                })}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />
            <ModalReplacementWorker
                open={openModalSimilarWorker}
                onClose={closeSimilarWorker}
                selectId={currentIdRow}
                data={data}
                setData={setData}
                currentRow={currentRow}
            />
            <ModalExecuteJob
                open={openModalExecuteJob}
                onClose={closeExecuteJob}
                date={currentDate}
                setDate={setCurrentDate}
                onExecute={onExecute}
            />
        </div>
    );
};

export default TechnicalMapTable;
