import {useLocation} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import Box from "@mui/material/Box";
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Typography} from "@mui/material";
import ButtonCreate from "../../../components/UI/button/ButtonCreate";
import {MaterialReactTable} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import CurrencyService from "../../../API/orders/warehouse/currency/AdminCurrencyService";
import TextFieldValidation from "../../../components/UI/table/customer-detales/TextFieldValidation";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonExit from "../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../components/UI/button/ButtonAdd";

const initValue = {
    curName: "",
    curAbbreviation: "",
    defaultBase: true,
    storageExchangeRateNationalBank: true
}
const Currency = ({itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        CurrencyService.getAll().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleClose = () => {
        setModal(false)
        setCurrentEditRowData(initValue)
    }

    const handleUpdate = (value) => {
        CurrencyService.update(value, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                handleClose()
            })
            .catch(function (error) {
                handleError(error)
            })

    }

    const handleSubmit = (value) => {
        CurrencyService.add(value)
            .then(function (response) {
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
                handleClose()
            })
            .catch(function (error) {
                handleError(error)
            })

    }

    const handleAdd = () => {
        setModal(true)
    }
    const handleEdit = (row) => {
        setCurrentEditRowData({...row, name: row.title});
        setModal(true)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Найменование', accessorKey: 'curName', minSize: 350,
            },
            {
                header: 'Аббревиатура', accessorKey: 'curAbbreviation', minSize: 50,
            },
            {
                header: 'Курс национального бакнка',
                accessorKey: 'storageExchangeRateNationalBank',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                minSize: 250,
                Cell: ({cell, row}) => <Checkbox
                    cell={cell}
                    checked={row.original.storageExchangeRateNationalBank}
                    sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                >
                </Checkbox>,
            }
        ]

        return baseColumns;

    }, [data]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEdit},
    ]

    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
        >
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>Валюта</Typography>
            <ButtonCreate
                text={"Добавить валюту"}
                width={200}
                onClick={handleAdd}
            />
        </Box>
        <MaterialReactTable
            muiTableContainerProps={{
                sx: {
                    width: '1100px',
                }
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "50px",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            rowHeight={13}
            columnSpacing={1}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            rowCount={tableOptions.totalElements}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return (el.type === "edit" || (el.type === "delete" && !row.original.blocked)) && <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
        />
        <CurrencyModal
            title={currentEditRowData.id ? "Изменить" : "Добавить"}
            open={modal} onClose={handleClose}
            handleSubmit={currentEditRowData.id ? handleUpdate : handleSubmit}
            initValue={currentEditRowData}
            handleError={handleError}/>
    </div>);
};
const CurrencyModal = ({open, onClose, handleSubmit, initValue, title, handleError}) => {
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({curName: "", curAbbreviation: "",})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])


    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            onClose()
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: "column"}}>
                <TextFieldValidation
                    name={"curName"}
                    value={formState?.curName}
                    label="Наименование"
                    setCurrValues={setFormState}
                    validationError={validationState?.curName}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"curAbbreviation"}
                    value={formState?.curAbbreviation}
                    label="Аббревиатура"
                    setCurrValues={setFormState}
                    validationError={validationState?.curAbbreviation}
                    setValidationState={setValidationState}
                />
                <FormControlLabel control={<Checkbox
                    checked={formState?.storageExchangeRateNationalBank === true}
                    onChange={() => setFormState(value => ({
                        ...value,
                        storageExchangeRateNationalBank: !formState.storageExchangeRateNationalBank
                    }))}
                />} label={"Курс национального бакнка"}/>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}

export default Currency;