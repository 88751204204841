import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle, Step, StepLabel, Stepper,
} from "@mui/material";
import ButtonAdd from "../../../button/ButtonAdd";
import ButtonExit from "../../../button/ButtonExit";
import React, {useEffect, useState} from "react";
import JobMetricOrganizationTable from "./JobMetricOrganizationTable";
import JobMetricOrganizationService from "../../../../../API/orders/job/JobMetricOrganizationService";
import Box from "@mui/material/Box";
import TextFieldValidation from "../../customer-detales/TextFieldValidation";
import {orderJobType} from "../../../../../data/OrderJobType";
import MenuItem from "@mui/material/MenuItem";
import JobMetricAdminPackingService from "../../../../../API/orders/job/Admin/JobMetricAdminPackingService";
import JobMetricAdminMillingService from "../../../../../API/orders/job/Admin/JobMetricAdminMillingService";
import JobMetricAdminEdgeService from "../../../../../API/orders/job/Admin/JobMetricAdminEdgeService";
import JobMetricAdminDrillingService from "../../../../../API/orders/job/Admin/JobMetricAdminDrillingService";
import JobMetricAdminCuttingService from "../../../../../API/orders/job/Admin/JobMetricAdminCuttingService";
import ButtonBack from "../../../button/ButtonBack";

const ModalAddJob = ({
                         open, onClose, onSubmit, handleError, materialOrganizationOptionId, baseCurrency,
                         alternativeCurrency, group, deleteJob, admin, type, itemId
                     }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [jobMetric, setJobMetric] = useState([])
    const [rowSelection, setRowSelection] = useState([])
    const allOrderJobTypes = Object.entries(orderJobType)
    const [formState, setFormState] = useState({currJob: allOrderJobTypes[0][0]})
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 10,
        totalElements: jobMetric?.length,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }
    const service = (type || formState.currJob) === "PACKAGING" ? JobMetricAdminPackingService :
        (type || formState.currJob) === "MILLING" ? JobMetricAdminMillingService
            : (type || formState.currJob) === "EDGE" ? JobMetricAdminEdgeService
                : (type || formState.currJob) === "DRILLING" ? JobMetricAdminDrillingService
                    : (type || formState.currJob) === "CUT" ? JobMetricAdminCuttingService
                        : JobMetricOrganizationService

    const handleSubmit = () => {
        if (activeStep === 0) {
            setActiveStep(1)
            const api = group ? service.get() : service.getForAddMaterial(materialOrganizationOptionId)
            api.then(function (response) {
                setJobMetric(response.data)
                setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
            }).catch(function (error) {
                handleError(error)
            })
        } else {
            const addedData = jobMetric.filter(el => rowSelection.includes(el.id))
            onSubmit(rowSelection, addedData, formState.currJob)
        }

    }

    useEffect(() => {
        if (type) {
            setActiveStep(1)
            open && service.getForAddMaterial(materialOrganizationOptionId).then(function (response) {
                setJobMetric(response.data)
                setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
            }).catch(function (error) {
                handleError(error)
            })
        } else if (!admin) {
            setActiveStep(1)
            const api = group ? JobMetricOrganizationService.get() : JobMetricOrganizationService.getAllForAddMaterialOrganizationOptionId(materialOrganizationOptionId)
            open && api.then(function (response) {
                setJobMetric(response.data)
                setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
            }).catch(function (error) {
                handleError(error)
            })
        }
    }, [open, type]);

    const handleClose = () => {
        onClose()
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: activeStep === 0 ? "400px" : admin ? "1700px" : "1400px",
                borderRadius: 8,
            }
        }}>
            <Box style={{
                padding: "16px 24px",
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    margin: "auto",
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{deleteJob ? "Удалить работы" : "Добавить работы"}</DialogTitle>
                {admin && <Stepper
                    alternativeLabel
                    style={{width: "600px"}}
                    activeStep={activeStep}>
                    {["Тип работ", "Работы"].map((label) => (
                        <Step key={label} sx={{
                            "& .MuiStepConnector-root": {
                                top: "20px",
                                left: 'calc(-50% + 30px)',
                                right: "calc(50% + 30px)"
                            }
                        }}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                },

                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>}
            </Box>
            <DialogContent>
                {activeStep === 0
                    ?
                    <TextFieldValidation
                        select
                        name="currJob"
                        label="Тип работ"
                        value={formState.currJob}
                        setCurrValues={setFormState}
                    >
                        {allOrderJobTypes.map((option) => (
                            <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                            </MenuItem>
                        ))}
                    </TextFieldValidation>
                    : <JobMetricOrganizationTable
                        data={jobMetric}
                        isModal={true}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        enableRowSelection={true}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                        tableOptions={tableOptions}
                        tableOptionsHandle={tableOptionsHandle}
                        columnAdded={false}
                        handleError={handleError}
                        admin={admin}
                        itemId={itemId || ((admin && formState.currJob === "EDGE") && "listJobEdge")}
                    />}
            </DialogContent>
            <DialogActions style={{padding: 20, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep === 1 && admin &&
                    <ButtonBack text={"Назад"} onClick={() => setActiveStep(0)}/>} </Box>
                <Box>
                    <ButtonExit text={"Отменить"} onClick={handleClose}/>
                    <ButtonAdd text={"Выбрать"} onClick={handleSubmit}/>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
export default ModalAddJob