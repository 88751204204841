export const estimateTableData = (currentCurrency, data) => {
    return [
        {
            param: "COST",
            plan: currentCurrency === 1 ? data[0]?.calculationCostBasePrice : data[0]?.calculationCostAlternativePrice,
            fact: currentCurrency === 1 ? data[0]?.costBasePrice : data[0]?.costAlternativePrice
        },
        {
            param: "JOBCOST",
            plan: currentCurrency === 1 ? data[0]?.calculationJobCostBasePrice : data[0]?.calculationJobCostAlternativePrice,
            fact: currentCurrency === 1 ? data[0]?.jobCostBasePrice : data[0]?.jobCostAlternativePrice
        },


        {
            param: "TOTALCOST",
            plan: currentCurrency === 1 ? data[0]?.calculationTotalCostBasePrice : data[0]?.calculationTotalCostAlternativePrice,
            fact: currentCurrency === 1 ? data[0]?.totalCostBasePrice : data[0]?.totalCostAlternativePrice
        },


        {param: "PRICE", fact: currentCurrency === 1 ? data[0]?.basePrice : data[0]?.alternativePrice,},
        {param: "JOB", fact: currentCurrency === 1 ? data[0]?.baseJobPrice : data[0]?.alternativeJobPrice,},
        {param: "SALES", fact: currentCurrency === 1 ? data[0]?.baseTotalPrice : data[0]?.alternativeTotalPrice,},
        {
            param: "PROFIT",
            plan: currentCurrency === 1 ? data[0]?.profitCalculationBaseTotalPrice : data[0]?.profitCalculationAlternativeTotalPrice,
            fact: currentCurrency === 1 ? data[0]?.profitBaseTotalPrice : data[0]?.profitAlternativeTotalPrice
        },

        {param: "GROSSMARGIN", plan: data[0]?.calculationGrossMargin, fact: data[0]?.grossMargin},
        {param: "STATE", plan: data[0]?.estimateState}]
}




