import React, {useEffect, useState} from 'react';
import SupplierMaterialTable
    from "../../../../../components/UI/table/warehouse/material/supplier/SupplierMaterialTable";
import {useParams} from "react-router-dom";
import UserSupplierMaterialService from "../../../../../API/orders/warehouse/material/UserSupplierMaterialService";

const SupplierMaterialArchiveItem = () => {

    const [data, setData] = useState([]);
    const params = useParams();
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState, currTab) => {
        setTableOptions(nextState)
    }

    useEffect(() => {
        UserSupplierMaterialService.getAllByMaterialId(params.materialId)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <SupplierMaterialTable
                data={data}
                isArchive={true}
                materialId={params.materialId}
                baseUnit={params.baseUnit}
                typeMaterial={params.typeMaterial}
                isAll={true}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
        </div>
    );
};

export default SupplierMaterialArchiveItem;