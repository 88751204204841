import React, {useEffect, useState} from "react";
import {Box, Container} from "@mui/system";
import {Paper, Typography} from "@mui/material";
import {BreadcrumbsComponent} from "../../components/UI/breadcrumb/BreadcrumbComponent";
import ModalAlert from "../../components/UI/table/customer-detales/ModalAlert";
import CircularProgress from "@mui/material/CircularProgress";
import {newsData} from "../../data/NewsData";
import {stockData} from "../../data/StockData";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {constantsErrorMessages} from "../../Constants";
import Footer from "./Footer";
import Card from "../../components/UI/Card";
import {newsType} from "../../data/NewsType";
import {stockType} from "../../data/StockType";
import UtilityService from "../../API/common/UtilityService";

const NewsDetail = ({news}) => {
    const id = useParams().id;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)
    const [alertData, setAlertData] = useState({});

    const [allData, setAllData] = useState({})
    const [data, setData] = useState({})
    const [banners, seBanners] = useState([])

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    useEffect(() => {
        try {
            UtilityService.getById(1).then(function (response) {
                seBanners(response.data.pictures)
            })
                .catch(function (error) {
                    console.log(error);
                });

            const allData = news ? newsData : stockData
            const currData = allData.find(el => el.id === +id)
            setAllData(allData)
            setData(currData)
            setIsLoading(false)
        } catch (error) {
            handleError(error)
        }
    }, [news, id])

    const onClickHandle = (el) => {
        navigate(`/${news ? "news" : "stock"}/${el.id}`);
    }
    return <>
        <ModalAlert
            data={alertData}
            onClose={() => setAlertData((alert) => ({...alert, open: false}))}
        />
        {isLoading ?
            <Box sx={{
                height: "calc(100vh - 150px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> :
            <>
                <Paper sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: "space-between",
                    padding: "0 0 40px 0",
                }}>
                    <Container sx={{
                        "&.MuiContainer-root": {
                            maxWidth: "1668px",
                            display: 'flex',
                            flexDirection: "column",
                            gridGap: "100px",
                        },
                    }}>
                        <Box>
                            <BreadcrumbsComponent
                                page={data.text}
                                path={[
                                    {name: "Главная", href: "/"},
                                    {name: news ? "Новости" : "Акции", href: news ? "/news" : "/stock"},
                                ]}/>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "column",
                                gridGap: "40px",
                                justifyContent: "center",
                                width: "100%",
                            }}>
                                <Typography sx={{
                                    fontSize: "40px", fontWeight: 700, lineHeight: 1,
                                    width: 1209,
                                    '@media (max-width: 1450px)': {
                                        fontSize: "28px",
                                    }
                                }}>
                                    {data.text}
                                </Typography>
                                <Box sx={{
                                    display: "flex", gap: "24px",
                                }}>
                                    <Box
                                        sx={{
                                            width: "379px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: "16px",
                                        }}>
                                        <Box sx={{
                                            borderRadius: "28px",
                                            backgroundColor: (theme) => theme.palette.background.grey
                                        }}>
                                            <Typography sx={{
                                                fontSize: "16px",
                                                color: (theme) => theme.palette.text.main,
                                                lineHeight: 1,
                                                padding: "4px 8px",
                                            }}>{newsType[data.type] || stockType[data.type]}</Typography>
                                        </Box>
                                        <Typography sx={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: 1.2,
                                            color: (theme) => theme.palette.text.light,
                                        }}>
                                            {data.date}
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        width: 782,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px"
                                    }}>
                                        <Box sx={{
                                            borderRadius: "8px",
                                            height: 550,
                                            width: 782,
                                            backgroundImage: `url(${data.img})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }}/>
                                        <Typography sx={{
                                            textWrap: "wrap",
                                            fontSize: "16px",
                                            lineHeight: 1.2,
                                            color: (theme) => theme.palette.text.main,
                                        }}>
                                            {data.description}
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        width: 387,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                        flexShrink: 0,
                                        borderLeft: (theme) => `2px solid ${theme.palette.background.grey}`,
                                        paddingLeft: "24px"
                                    }}>
                                        <Typography sx={{fontSize: "24px", fontWeight: 700, lineHeight: 1,}}>
                                            Новости партнёров(реклама)
                                        </Typography>
                                        <Box sx={{
                                            height: '278px',
                                            width: "387px",
                                            borderRadius: "8px",
                                            backgroundImage: `url(${banners[2]?.relativeUrl})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }}>
                                        </Box>
                                        <Box sx={{
                                            height: '278px',
                                            width: "387px",
                                            borderRadius: "8px",
                                            backgroundImage: `url(${banners[3]?.relativeUrl})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }}>
                                        </Box>
                                        <Typography sx={{fontSize: "24px", fontWeight: 700, lineHeight: 1,}}>
                                            Актуальные новости
                                        </Typography>
                                        <Box sx={{
                                            display: "flex", gap: "16px", flexDirection: "column",
                                        }}>
                                            {allData.slice(0, 3).map((el) => {
                                                return <Card
                                                    key={el.id}
                                                    element={el}
                                                    clickable={true}
                                                    picturePosition={"left"}
                                                    width={387}
                                                    height={180}
                                                    imgHeight={"147px"}
                                                    imgWidth={"150px"}
                                                    padding={"16px"}
                                                    onClickHandle={() => onClickHandle(el)}
                                                />
                                            })}
                                        </Box>
                                    </Box>
                                </Box>

                                <Typography sx={{
                                    fontSize: "40px", fontWeight: 700, lineHeight: 1,
                                    '@media (max-width: 1450px)': {
                                        fontSize: "28px",
                                    }
                                }}>
                                    Популярные новости
                                </Typography>
                                <Box sx={{
                                    display: "flex", gap: "16px",
                                }}>
                                    {allData.map((el) => {
                                        return <Card
                                            key={el.id}
                                            element={el}
                                            clickable={true}
                                            picturePosition={"top"}
                                            width={311}
                                            height={380}
                                            imgHeight={"174px"}
                                            onClickHandle={() => onClickHandle(el)}
                                        />
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Paper>
                <Footer></Footer>
            </>
        }
    </>
}
export default NewsDetail