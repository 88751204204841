import React, {useEffect, useState} from 'react';
import ArrivalMaterialTable from "../../../../../components/UI/table/warehouse/material/arrival/ArrivalMaterialTable";
import {Box} from "@mui/system";
import {BreadcrumbsComponent} from "../../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {Button, Paper} from "@mui/material";
import ButtonSelectAction from "../../../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../../../components/UI/modal/ModalSelectAction";
import ArrivalMaterialService from "../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";

const ArrivalMaterial = () => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = useState([]);
    const [buttonActionVisible, setButtonActionVisible] = useState(false);
    const [open, setOpen] = useState(false)
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        ArrivalMaterialService.getAll('not-archive').then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const handleMergeArrivalMaterial = async () => {
        ArrivalMaterialService.merge(rowSelection)
            .then(function (response) {
                const newData = data.filter(el => !rowSelection.includes(el.id))
                newData.push(response.data);
                setData(newData);
                setTableOptions((prev) => ({...prev, totalElements: newData.length}))
                setRowSelection([])
            })
            .catch(function (error) {
                console.log(error);
            })

        setButtonActionVisible(false)
        setOpen(false)
    };
    const modalActions = [
        {name: "Объединить заявки", type: "action1"},
    ]

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Заявки на поставку"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Заявки на поставку
                    </Typography>
                </Box>
                <ArrivalMaterialTable
                    isCreateOrUpdate={true}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    buttonActionVisible={buttonActionVisible}
                    setButtonActionVisible={setButtonActionVisible}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    data={data}
                    setData={setData}
                    enableRowSelection={true}
                    setTableOptions={setTableOptions}
                />
                {buttonActionVisible && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>}
                <ModalSelectAction
                    open={open}
                    reset={() => setOpen(false)}
                    submit={() => handleMergeArrivalMaterial()}
                    modalActions={modalActions}
                />
            </Box>
        </Box>
    );
};

export default ArrivalMaterial;