import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import ButtonExit from "../button/ButtonExit";
import ButtonAdd from "../button/ButtonAdd";
import MaterialPageItemModule from "../modul/MaterialPageItemModule";
import MaterialTree from "../tree/MaterialTree";
import AllMaterialItem from "../../../pages/material-cloud/admin/AllMaterialItem";
import {AddNewMaterial} from "../../../pages/common/settings/AddNewMaterial";
import ButtonBack from "../button/ButtonBack";


export const ModalAddMaterial = ({
                                     open,
                                     title,
                                     onClose,
                                     onSubmit,
                                     groupMap,
                                     dataTree,
                                     filterGroup,
                                     setFilterGroup,
                                     setChangeFilterGroup,
                                     admin
                                 }) => {
    const initValue = {
        "defaultSupplierId": 0,
        "manufacturerId": 0,
        "unitId": 0,
        "articleNumber": "",
        "name": "",
        "materialId": null,
        "typeMaterial": 0,
        "subTypeMaterial": "",
        "display": true,
        "weight": 0,
        "height": 0,
        "thickness": 0,
        "density": 0,
        "structure": "",
        "trimmingLeft": 0,
        "trimmingRight": 0,
        "trimmingTop": 0,
        "trimmingBottom": 0,
        "trimmingLeftCutOff": 0,
        "trimmingRightCutOff": 0,
        "trimmingTopCutOff": 0,
        "trimmingBottomCutOff": 0,
        "cutDefault": "",
        "minBalanceUpToWholeSheet": 0,
        "minWeightCutOff": 0,
        "minHeightCutOff": 0,
        "limitNumberTurns": 0,
        "cutWeight": 0,
        "archive": false,
        "deliveryNotAccordingToEstimate": false,
        "shortName": "",
        "overhangEdge": 0
    }
    const [activeStep, setActiveStep] = useState(0)
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState(Object.keys(initValue).reduce((acc, it) => ({
        ...acc,
        [it]: ""
    }), {}))
    const [type, setType] = useState("1")
    const [selectedString, setSelectedString] = useState({})
    const [currentGroup, setCurrentGroup] = useState("")

    const handleEditType = (type) => {
        type === "1" && setSelectedString({})
        setType(type)
    }
    useEffect(() => {
        admin && setActiveStep(1)
    }, [open])
    const handleClose = () => {
        setType("1")
        setActiveStep(0)
        setSelectedString({})
        setFormState(initValue)
        setCurrentGroup("")
        setValidationState(Object.keys(initValue).reduce((acc, it) => ({...acc, [it]: ""}), {}))
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if ((name === "articleNumber" || name === "name" || name === "shortName") && value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            setActiveStep(2)
        }
    }
    const submit = () => {
        if (activeStep === 0) {
            setActiveStep(1)
        } else if (activeStep === 1) {
            if (type === "2") {
                setActiveStep(2)
            } else {
                handleValidationData()
            }
        } else {
            if (type === "2") {
                onSubmit(true, {materialId: selectedString?.id, groupMaterialId: currentGroup.id})
                handleClose()
            } else {
                const {
                    defaultSupplierId,
                    manufacturerId,
                    unitId,
                    ...data
                } = formState
                const queryString = admin ? {
                    supplierId: defaultSupplierId,
                    manufacturerId,
                    groupMaterialId: currentGroup?.id
                } : {
                    defaultSupplierId,
                    manufacturerId,
                    unitId,
                    groupMaterialId: currentGroup?.id
                }
                const formData = admin ? {
                    ...data, "unit": {
                        "id": unitId
                    }
                } : data
                onSubmit(false, queryString, formData)
                handleClose()
            }
        }
    }
    const back = () => {
        setActiveStep(prev => prev - 1)
    }
    const stepsArray = admin ? ["Основное", "Группы"] : ["Поставщики", "Основное", "Группы"]

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: selectedString?.typeMaterial !== "SLAB" && activeStep === 1 && type === "2" ? "500px" : activeStep === 2 ? "500px" : "1050px",
                height: activeStep === 1 && type === "1" ? "1200px" : "950px",
            }
        }}>
            <Box style={{
                padding: 40,
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    margin: "auto",
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{title}</DialogTitle>
                <Stepper
                    alternativeLabel
                    style={{width: "600px"}}
                    activeStep={activeStep}
                >
                    {stepsArray.map((label) => (
                        <Step key={label} sx={{
                            "& .MuiStepConnector-root": {
                                top: "20px",
                                left: 'calc(-50% + 30px)',
                                right: "calc(50% + 30px)"
                            }
                        }}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                },

                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <DialogContent>
                {activeStep === 0
                    ?
                    <Box style={{display: "flex", flexDirection: "column"}}>
                        <Box style={{width: "100%"}}>
                            <RadioGroup
                                defaultValue="female"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={type}
                                onChange={(event) => handleEditType(event.target.value)}
                            >
                                {[{id: 1, name: "Добавить новый материал"},
                                    {id: 2, name: "Выбрать из каталога"}].map((option) => (
                                        <FormControlLabel
                                            key={option.id}
                                            value={option.id}
                                            control={<Radio/>}
                                            label={option.name}
                                            sx={{
                                                width: "100%",
                                                height: "36px",
                                                "&:not(:last-of-type)": {
                                                    marginBottom: "4px",
                                                },
                                                borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                                "&:hover": {
                                                    backgroundColor: (theme) => theme.palette.primary.light
                                                }
                                            }
                                            }

                                        />
                                    )
                                )}

                            </RadioGroup>
                            <MaterialPageItemModule
                                modal={true}
                                selectMaterialId={selectedString?.id}
                                selectMaterial={setSelectedString}
                                disabled={type === "1"}
                                materialCloud={true}
                            />
                        </Box>
                    </Box>
                    : activeStep === 1 ?
                        <Box>
                            {type === "1" ?
                                <AddNewMaterial
                                    formState={formState} setFormState={setFormState}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                    admin={admin}/> :
                                <AllMaterialItem
                                    settings={true}
                                    settingsParams={{
                                        materialId: selectedString?.id,
                                        typeMaterial: selectedString?.typeMaterial,
                                        baseUnit: selectedString?.unit?.name,
                                        id: selectedString?.id
                                    }}
                                />}
                        </Box> : <Box sx={{display: "flex", justifyContent: "center"}}>
                            <MaterialTree
                                name={"Группы"}
                                setFilterGroup={setFilterGroup}
                                setChangeFilterGroup={setChangeFilterGroup}
                                filterGroup={filterGroup}
                                data={dataTree}
                                groupMap={groupMap}
                                currentGroup={currentGroup}
                                setCurrentGroup={setCurrentGroup}
                            /></Box>}
            </DialogContent>
            <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <Box>{(!admin && activeStep !== 0 || (admin && activeStep !== 1)) &&
                    <ButtonBack text={"Назад"} onClick={back}/>} </Box>
                <Box>
                    <ButtonExit text={"Отменить"} onClick={handleClose}/>
                    <ButtonAdd text={"Выбрать"} onClick={submit}
                               disabled={(activeStep === 0 && type === "2" && !selectedString.id) || (activeStep === 2 && !currentGroup.id)}/>
                </Box>
            </DialogActions>
        </Dialog>
    );
};