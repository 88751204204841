import axios from "axios";
import {apiVersionUtility, gatewayUrl, utility} from "../../../../Constants";

const restCurrencyUrl = gatewayUrl + utility + apiVersionUtility + '/admin/currencies'

export default class CurrencyService {

    static async getAll() {
        return axios.get(restCurrencyUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async add(data) {
        return await axios.post(restCurrencyUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        return await axios.put(`${restCurrencyUrl}/${id}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}