import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonText from "../button/ButtonText";
import ButtonBlue from "../button/ButtonBlue";
import {Button, InputBase} from "@mui/material";
import * as React from "react";
import {Container, styled} from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import {ReactComponent as UserIcon} from "../../../components/Icons/user.svg"
import {ReactComponent as UserLight} from "../../Icons/userLight.svg"
import {authorizedAdmin, authorizedOrganization} from "../../../Constants";
import {useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useState} from "react";

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "&.MuiToolbar-root": {
        padding: 0
    }
}));

export const ToolbarFirst = ({profile, handleChange}) => {
    const navigate = useNavigate();
    const isOrganization = profile?.userRoles?.some(role => authorizedOrganization.includes(role.name))
    const isAdmin = profile?.userRoles?.some(role => authorizedAdmin.includes(role.name))
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettings = () => {
        handleClose()
        navigate('/settings')
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return <AppBar
        position="static"
        sx={{
            width: '100%',
            height: "76px",
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.primary.lightGrey,
        }}>
        <Container sx={{
            "&.MuiContainer-root": {
                maxWidth: "1668px",
            }
        }}>
            <StyledToolbar>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            fontWeight: 800,
                            fontSize: "32px",
                            letterSpacing: '.3rem',
                            color: (theme) => theme.palette.primary.main,
                            textDecoration: 'none',
                            marginRight: "40px",
                        }}
                    >
                        UpMe
                    </Typography>
                    {/*{profile && <SearchComponent placeholder={"Выберите город"}/>}*/}
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: "40px",
                }}>
                    <ButtonText onClick={() => navigate('/help')} text={"Помощь"}></ButtonText>
                    <ButtonText onClick={() => navigate('/stock')} text={"Акции"}></ButtonText>
                    <ButtonText onClick={() => navigate('/news')} text={"Новости"}></ButtonText>
                    <ButtonText onClick={() => navigate('/contacts')} text={"Контакты"}></ButtonText>
                    <ButtonText onClick={() => navigate('/all-materials')} text={"Материалы"}></ButtonText>
                    <ButtonText onClick={() => navigate('/work')} text={"Работа в UpMe"} width={115}></ButtonText>
                    {profile && !isOrganization && !isAdmin && <ButtonBlue text={"Оставить заявку"}/>}
                    <div>
                        <Button
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={profile ? handleMenu : () => handleChange(true)}
                            sx={{
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: "center",
                                textTransform: 'none',
                                padding: 0,
                                gridGap: "4px",
                                "& svg": {
                                    width: "24px",
                                    height: "24px",
                                },
                                "& .MuiTypography-root": {
                                    fontSize: "12px",
                                    width: 84,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    color: (theme) => profile ? theme.palette.text.main : theme.palette.text.light,
                                }
                            }}>
                            {profile ? <UserIcon/> : <UserLight/>}
                            <Typography>{profile ? profile.userName : "Войти"}</Typography>
                        </Button>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                            }}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleChange}>Выйти</MenuItem>
                            <MenuItem onClick={handleSettings}>Настройки</MenuItem>
                        </Menu>
                    </div>
                </Box>
            </StyledToolbar>
        </Container>
    </AppBar>

}