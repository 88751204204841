import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import PackagingSetService from "../../../../../API/model-estimate/packaging/PackagingSetService";
import Typography from "@mui/material/Typography";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";
import PackagingUnitTable from "./PackagingUnitTable";
import PackagingUnitService from "../../../../../API/model-estimate/packaging/PackagingUnitService";

const PackagingItemTable = ({id, article, data, setData, tableOptions, tableOptionsHandle, handleError}) => {
    const [modal, setModal] = useState(false);

    const handleDeleteRow = useCallback(
        (row) => {
            PackagingSetService.deletePackagingUnitById(id, row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );

    const handleSubmit = (data) => {
        PackagingSetService.addPackagingUnit(id, data.id)
            .then(function (response) {
                setData(prev => [...prev, data])
                setModal(false)
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length}))
            })
            .catch(function (error) {
                handleError(error)
            })
    }

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 450,
            },
            {
                id: 'weight',
                header: 'Вес',
                accessorKey: 'weight',
                minSize: 450,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );
    const menuItem = [
        {type: "delete", text: "Удалить", handler: handleDeleteRow}
    ]
    return (
        <Box sx={{width: '1000px',}}>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                    Комплект упаковки артикул: {article}
                </Typography>
                <ButtonCreate
                    text={"Добавить ед. упаковки"}
                    width={230}
                    onClick={() => setModal(true)}
                />

            </Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '1000px',
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return (el.type === "edit" || (el.type === "delete" && !row.original.blocked)) && <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                localization={MRT_Localization_RU}
            />
            <PackagingAddUnitModal
                open={modal}
                onClose={() => setModal(false)}
                handleSubmit={handleSubmit}
                handleError={handleError}/>
        </Box>

    );
};
const PackagingAddUnitModal = ({open, onClose, handleSubmit, handleError}) => {
    const [data, setData] = useState([]);
    const [selectedString, setSelectedString] = useState({})
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 10,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }
    useEffect(() => {
        PackagingUnitService.getAll().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "800px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">Добавить единицы упаковки</DialogTitle>
            <DialogContent>
                <PackagingUnitTable
                    handleError={handleError}
                    data={data}
                    setData={setData}
                    modal={true}
                    selectedStringId={selectedString?.id}
                    setSelectedString={setSelectedString}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}/>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"} onClick={() => handleSubmit(selectedString)}/>
            </DialogActions>
        </Dialog>
    );
}

export default PackagingItemTable;