import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";

const packagingUnitRestUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/packaging-units'

export default class PackagingUnitService {

    static async getAll() {
        return axios.get(packagingUnitRestUrl);
    }

    static async getAllByPackagingSetId(id) {
        return axios.get(packagingUnitRestUrl + '/packaging-sets/' + id);
    }

    static async update(id, data) {
        await axios.post(packagingUnitRestUrl + '/' + +id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(packagingUnitRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(packagingUnitRestUrl + '/' + id);
    }
}