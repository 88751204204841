import React, {useEffect, useState} from 'react';
import WorkingCuttingMapTable from "../../../../components/UI/table/cutting/workingcuttingmap/WorkingCuttingMapTable";
import WorkingCuttingMapService from "../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";
import WorkingCuttingMapUtil from "../../../../utils/cutting/WorkingCuttingMapUtil";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../Constants";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";

const QueueCuttingMaps = () => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    const [minNumberCutting, setMinNumberCutting] = useState(null);
    const [maxNumberCutting, setMaxNumberCutting] = useState(null);
    const [unableCut, setUnableCut] = useState({});

    useEffect(() => {
        getStartPageSize()
        WorkingCuttingMapService.getAllForCuttingWithOptimization().then(function (response) {
            const data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            const minAndMax = WorkingCuttingMapUtil.getMinAndMax(data);
            setMinNumberCutting(minAndMax.min_numberCutting)
            setMaxNumberCutting(minAndMax.max_numberCutting)
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Порядок раскроя"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Порядок раскроя
                    </Typography>
                </Box>
                <WorkingCuttingMapTable
                    data={data}
                    setData={setData}
                    queue={true}
                    minNumberCutting={minNumberCutting}
                    maxNumberCutting={maxNumberCutting}
                    unableCut={unableCut}
                    setUnableCut={setUnableCut}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
        </Box>
    );
};

export default QueueCuttingMaps;