import React, {useMemo} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";

const SupplierTable = ({data, selectId, select}) => {

    const columns = useMemo(
        () => [
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 30,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data],
    );

    return <MaterialReactTable
        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 10px',
                m: '0px 0px 0px 0px',
                border: "none",
                "& td:after": {
                    "&:hover": {
                        backgroundColor: "red"
                    }
                },
                "&:not(:last-of-type)": {
                    borderRight: '1px solid #EAEBEA',

                },
            },
        }}
        muiTableHeadCellProps={{
            sx: {
                border: '1px solid #EAEBEA',
                backgroundColor: "#F5F6FA",
                padding: "10px",
                paddingBottom: " 0.2rem",
                paddingTop: "0.2rem",
                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                    "minWidth": "100%"
                }
            },
        }}
        muiTableBodyRowProps={({row}) => {
            return ({
                onClick: () => select && select(row.original),
                sx: () => ({
                    height: '36px',
                    cursor: select && "pointer",
                    "&:hover& td:after": {
                        display: "none",
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: row.original.id === selectId ? "#D7E8FA" : '#F5F6FA',
                    },
                    backgroundColor: row.original.id === selectId ? "#D7E8FA" : "white",
                })
            })
        }}

        muiSearchTextFieldProps={{
            placeholder: `Поиск`,
            sx: {
                paddingTop: "5px",
                paddingBottom: "0px",
                "& .MuiCollapse-root": {
                    borderRadius: '12px',
                    height: 40, width: 290,
                    "& .MuiSvgIcon-root": {
                        color: "#737478"
                    },
                    '& .MuiOutlinedInput-input': {
                        padding: 0
                    }
                }
            },
            variant: 'outlined',
        }}

        columns={columns}
        data={data}
        initialState={{
            columnVisibility: {
                id: false,
            },
            density: "compact",
            pagination: {pageIndex: 0, pageSize: 10},
        }}
        state={{showGlobalFilter: true}}
        enableToolbarInternalActions={false}
        localization={MRT_Localization_RU}
    />;
};

export default SupplierTable;