import {Box} from "@mui/system";
import AppRouter from "./AppRouter";
import React, {useEffect, useState} from "react";
import {authorization, logout} from "../Constants";
import OrganizationCustomerService from "../API/orders/orders/organization/OrganizationCustomerService";
import OrganizationsStageSettingsService from "../API/organization/OrganizationsStageSettingsService";
import {determineAccessibility} from "./UI/navbar/determineAccessibility";
import {EditLocalStoragePagesData} from "../utils/EditLocalStoragePagesData";
import {ToolbarFirst} from "./UI/navbar/ToolbarFirst";
import {ToolbarSecond} from "./UI/navbar/ToolbarSecond";

const ContainerComponent = () => {
    const profileStorage = JSON.parse(localStorage.getItem("profile"))
    const auth = authorization + '/oauth2/authorization/up-me-client-authorization-code';
    const [profile, setProfile] = useState(profileStorage)
    const [accessibility, setAccessibility] = useState({})
    const [stage, setStage] = useState(null)

    useEffect(() => {
        OrganizationCustomerService.profile().then(function (response) {
            if (response.data?.userName === "AnonymousUser") {
                localStorage.setItem("profile", null)
                setProfile(null)
            }
            response.data?.userRoles && handleAuth(response.data)
            return response.data
        }).then((data) => {
            const isOrganization = data?.userRoles && data.userRoles.some(role => ['ROLE_ORGANIZATION_USER_SETTINGS', 'ROLE_ORGANIZATION_USER', 'ROLE_ORGANIZATION_USER', 'ROLE_ORGANIZATION_USER_SETTINGS_MATERIAL', "ROLE_ORGANIZATION_SUPER_USER"].includes(role.name))
            isOrganization ? OrganizationsStageSettingsService.get().then(function (response) {
                setStage(response.data.stage)
                localStorage.setItem("stage", JSON.stringify(response.data.stage))
            }) : setStage(0)
        }).catch(handleError);

    }, []);

    const handleAuth = (data) => {
        localStorage.setItem("profile", JSON.stringify(data))
        setProfile(data)
        setAccessibility(() => determineAccessibility(data.userRoles))
    }

    const handleError = () => {
        const currentUrl = window.location.href
        if (currentUrl !== authorization + "/") {
            window.location.href = authorization + "/"
        }
        localStorage.setItem("profile", JSON.stringify(null))
        setProfile(null)
        setStage(null)
        setAccessibility([])
    }

    const handleChange = (menu) => {
        if (profile) {
            OrganizationCustomerService.profileHead().then(function () {
                window.location.href = logout;
                localStorage.setItem("profile", JSON.stringify(null))
                localStorage.setItem("stage", JSON.stringify(null))
            }).catch(function () {
                window.location.href = auth
            });
        } else if (menu) {
            window.location.href = auth
            EditLocalStoragePagesData("/settings", "expanded", [])
            EditLocalStoragePagesData("/settings", "settingName", "profile")
        } else {
            window.location.href = "/";
            localStorage.setItem("profile", null)
            setProfile(null)
        }
    }
    return <Box sx={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
        <Box sx={{width: "100%",}}>
            <ToolbarFirst profile={profile} handleChange={handleChange}/>
            {profile && <ToolbarSecond stage={stage} accessibility={accessibility}/>}
        </Box>
        <AppRouter stage={stage} setStage={setStage} profile={profile}/>
    </Box>
}
export default ContainerComponent;