import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import CustomerMaterialEstimateUnitService
    from "../../../API/orders/estimate/customer/CustomerMaterialEstimateUnitService";
import {useNavigate} from "react-router-dom";
import CustomerEstimateService from "../../../API/orders/estimate/customer/CustomerEstimateService";
import ButtonCreate from "../../../components/UI/button/ButtonCreate";
import CustomerOrderService from "../../../API/orders/orders/customer/CustomerOrderService";
import {LinearProgress, linearProgressClasses, Typography} from "@mui/material";
import {offerWaitingTime} from "../../../Constants";

import {calcDiffDate} from "../../../utils/common/calcDiffDate";


const CustomerEstimateTableItem = ({
                                       orderId, customerOrderNumber, handleError
                                   }) => {
    const initDataOffers = {
        "all": 0,
        "calculated": 0,
        "estimateState": "WAIT_OFFERS"
    }
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [offersData, setOffersData] = useState(initDataOffers);
    const [rowSelection, setRowSelection] = useState({});
    const [progress, setProgress] = useState(0);
    const [blockedButton, setBlockedButton] = useState(false);
    const [longWait, setLongWait] = useState(false);
    let timerId

    const createOffers = async (orderId) => {
        setBlockedButton(true)
        CustomerEstimateService.createOffers(orderId)
            .then(function () {
                getOffersData(orderId)
            })
            .catch(function (error) {
                handleError(error);
                setOffersData(initDataOffers)
            }).finally(() => setBlockedButton(false))
    };

    const getOffersData = async (orderId) => {
        clearTimeout(timerId)
        setLongWait(false)
        CustomerOrderService.getCountOffers(orderId).then(function (response) {
            const data = response.data;
            setOffersData(data);
            setProgress((data.calculated + data.finishedError) * 100 / data.all)
            if (data.estimateState === "CALCULATED_OFFERS" && (data.all !== (data.calculated + data.finishedError) || data.all === 0)) {
                const minutesFromStart = calcDiffDate(data.startCalculate)
                minutesFromStart > offerWaitingTime && setLongWait(true)
                timerId = setTimeout(() => getOffersData(orderId), 4000)
            }
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    const cancelOffers = async (orderId) => {
        CustomerOrderService.deleteOffers(orderId).then(function () {
            clearTimeout(timerId)
            setOffersData(initDataOffers)
        })
            .catch(function (error) {
                handleError(error);
            });
    };

    const redirectOffers = (orderId, customerOrderNumber) => {
        navigate('/customer/orders/' + orderId + '/' + customerOrderNumber + '/estimates');
    };
    useEffect(() => {
        getOffersData(orderId)

        CustomerMaterialEstimateUnitService.getAllByOrderId(orderId).then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [])

    useEffect(() => {
        return () => {
            clearTimeout(timerId)
        };
    }, [timerId]);

    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialArticleNumber',
                minSize: 15,
                maxSize: 25,
            },
            {
                id: 'materialName',
                header: 'Найменование',
                accessorKey: 'materialName',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'unitName',
                minSize: 5,
                maxSize: 5,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}>
                {offersData.estimateState !== "WAIT_OFFERS"
                    ? <Box sx={{display: 'flex', alignItems: 'center', width: "750px", gap: "20px"}}>
                        <Box sx={{width: '100%', mr: 1}}>
                            <LinearProgress variant="determinate" value={progress} sx={{
                                borderRadius: 5,
                                [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 5
                                }
                            }}/>
                        </Box>
                        <Box sx={{minWidth: 35}}>
                            <Typography variant="body2" sx={{color: 'text.secondary', width: "270px"}}>
                                {offersData.all === (offersData.calculated + offersData.finishedError) ? `Рассчёт окончен. Рассчитано ${offersData.calculated} из ${offersData.all}` : `Рассчитано ${offersData.calculated} из ${offersData.all}`}
                            </Typography>
                        </Box>
                    </Box>
                    : <Box/>
                }
                <Box sx={{display: 'flex', gap: "20px"}}>
                    <ButtonCreate
                        icon={false}
                        text={"Расчитать предложения"}
                        disabled={offersData.estimateState !== "WAIT_OFFERS" || blockedButton}
                        width={200}
                        onClick={() => createOffers(orderId)}
                    />
                    <ButtonCreate
                        icon={false}
                        text={"Отменить предложение"}
                        disabled={!longWait && (offersData.estimateState !== "CALCULATED_OFFERS" || (offersData.all !== (offersData.calculated + offersData.finishedError)) || offersData.all === 0)}
                        width={200}
                        onClick={() => cancelOffers(orderId)}
                    />
                    <ButtonCreate
                        icon={false}
                        text={"Смотреть предложения"}
                        disabled={offersData.estimateState === "WAIT_OFFERS" || blockedButton}
                        width={200}
                        onClick={() => redirectOffers(orderId, customerOrderNumber)}
                    />
                </Box>
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light,
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light
                                },
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                enableEditing={false}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection, showGlobalFilter: true}}
                enableRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                localization={MRT_Localization_RU}
                enableToolbarInternalActions={false}
            />
        </div>
    );
};

export default CustomerEstimateTableItem;

