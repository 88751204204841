import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import MaterialTextFieldObjectWithEntityList from "../../../modul/mainoptions/MaterialTextFieldObjectWithEntityList";
import {typeMaterial} from "../../../../../data/TypeMaterial";
import TextFieldWithSetValue from "../../../textfield/TextFieldWithSetValue";
import {TextFieldWithEntityList} from "./TextFieldWithEntityList";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const ModalUpdateOrAddModalEstimateUnit = ({
                                               open,
                                               handleClose,
                                               title,
                                               values,
                                               setValues,
                                               handleSubmit,
                                               isNew,
                                               listUnits,
                                               setUnitId
                                           }) => {

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        <TextFieldWithSetValue
                            label="Найменование"
                            values={values}
                            setValues={setValues}
                            field={'tempName'}
                        />
                        <TextFieldWithSetValue
                            label="Количестов"
                            values={values}
                            setValues={setValues}
                            field={'quantity'}
                        />
                        {listUnits && <TextFieldWithEntityList
                            label="Ед. изм"
                            values={values}
                            field={'unitId'}
                            setId={setUnitId}
                            entityList={listUnits}
                        />}
                        <MaterialTextFieldObjectWithEntityList
                            label="Тип"
                            values={values}
                            setValues={setValues}
                            field={'typeMaterial'}
                            entityList={typeMaterial}
                        />
                        <TextFieldWithSetValue
                            label="Коментарий"
                            values={values}
                            setValues={setValues}
                            field={'note'}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit
                    text={"Выйти"} onClick={handleClose}/>
                <ButtonAdd
                    text={isNew ? 'Создать' : 'Обновить'} onClick={handleSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ModalUpdateOrAddModalEstimateUnit;