import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const orderRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/customers/orders'

export default class CustomerOrderService {

    static async getAllCustomerOrders() {
        return axios.get(orderRestUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async getCountOffers(id) {
        return axios.get(orderRestUrl + "/count-offers/" + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async deleteOffers(id) {
        return axios.delete(orderRestUrl + '/offers/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getById(id) {
        return axios.get(orderRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(orderRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async edit(id, data) {
        return await axios.put(`${orderRestUrl}/${id}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        return axios.delete(orderRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async sendToArchiveById(id) {
        return await axios.patch(orderRestUrl + '/' + id + '/set-to-archive',
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async sendToArchive(data) {
        return await axios.patch(orderRestUrl + '/set-to-archive',
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async getOrdersArchive() {
        return axios.get(orderRestUrl + '/archive', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}