import React, {useEffect, useState} from 'react';
import {Box} from "@mui/system";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import RulesModifyingOptimizationTable
    from "../../../../components/UI/table/optimization/rulesmodifyingoptimization/RulesModifyingOptimizationTable";
import RulesModifyingOptimizationService from "../../../../API/details/RulesModifyingOptimizationService";

const RulesModifyingOptimization = ({itemId, handleError}) => {
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    useEffect(() => {
        getStartPageSize()
        RulesModifyingOptimizationService.getAll().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`settings+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`settings+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    return (
        <Box sx={{padding: "0 30px"}}>
            <RulesModifyingOptimizationTable
                data={data}
                setData={setData}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        </Box>
    );
};

export default RulesModifyingOptimization;