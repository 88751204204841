import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const optimizationServiceRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/optimizations'

export default class OptimizationService {

    static async getAllNotArchive() {
        return axios.get(optimizationServiceRestUrl + '/not-archive', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByOptimizationState(optimizationState) {
        return axios.get(optimizationServiceRestUrl + '/by-optimization-state' + '?optimizationState=' + optimizationState, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllNotArchiveTypeCalculation() {
        return axios.get(optimizationServiceRestUrl + '/not-archive-calculation', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(optimizationServiceRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createOptimizationByIds(ids, optimization) {
        let data = ObjectUtil.createRequestObjectOptimizationIds(ids, optimization);
        return await axios.post(optimizationServiceRestUrl + '/create-by-ids-requests-cutting',
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async createOptimization(data) {
        return await axios.post(optimizationServiceRestUrl,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async unableStatus(id, optimizationState) {
        return axios.patch(optimizationServiceRestUrl + '/' + id + '/unable-status?optimizationState=' + optimizationState, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}