import React from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import Typography from "@mui/material/Typography";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";

const RequestsCuttingCalculation = () => {

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Заявки на раскрой для расчета"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Заявки на раскрой для расчета
                    </Typography>
                </Box>
                <RequestsCuttingTable
                    getAll={RequestsCuttingService.getAllWithMaterialNotArchiveTypeCalculation}
                    isRedirectRequestsCutting={false}
                    isButtonAddRequestsCutting={false}
                    isOrder={true}
                    isCreateOptimizationCalculation={true}
                    isRedirectOrders={true}
                    isSetForOptimization={true}
                    page={'RequestsCuttingCalculation'}
                    isCalculation={true}
                    isDeleteOptimization={true}
                    isOptimization={true}
                />
            </Box>
        </Box>
    );
};

export default RequestsCuttingCalculation;