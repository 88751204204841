import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldObject from "../../../textfield/TextFieldObject";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const ModalSetSupplier = ({
                              open,
                              handleClose,
                              title,
                              supplierList,
                              setSupplierId,
                              supplierId,
                              handleSubmit
                          }) => {
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle textAlign="center">{title}</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                        >
                            <TextFieldObject
                                label="Поставщик"
                                field={'supplier'}
                                setId={setSupplierId}
                                entityList={supplierList}
                                value={supplierId}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <ButtonExit text={"Выйти"} onClick={handleClose}/>
                    <ButtonAdd text={"Обновить"} onClick={handleSubmit}/>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalSetSupplier;