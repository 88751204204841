import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_ToggleFiltersButton} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link} from 'react-router-dom';
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import AttributeUtil from "../../../../../../utils/orders/AttributeUtil";
import OrderUtil from "../../../../../../utils/orders/OrderUtil";
import MenuItem from "@mui/material/MenuItem";
import DataUtil from "../../../../../../utils/common/DataUtil";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import {EditLocalStoragePagesData} from "../../../../../../utils/EditLocalStoragePagesData";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import {BreadcrumbsComponent} from "../../../../breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";

const ArchiveTable = () => {

    const [data, setData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);
    const [constructorList, setConstructorList] = useState([]);
    const [technologistList, setTechnologistList] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData("organization/archive", "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData["organization/archive"]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    useEffect(() => {
        getStartPageSize()
        OrderService.getOrdersArchive().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
            setCustomerList(DataUtil.getListChildField(response.data, 'customerOrder', 'name'));
            setNameList(DataUtil.getListChildField(response.data, 'header', 'name'));
            setConstructorList(DataUtil.getListChildField(response.data, 'constructor', 'name'));
            setTechnologistList(DataUtil.getListChildField(response.data, 'technologist', 'name'));
            setAttributeList(OrderUtil.getStatuses(response.data, 'attributes'));
            setPlanningList(DataUtil.getListField(response.data, 'state'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const columns = useMemo(
        () => [
            {
                id: 'number',
                header: '№ заказа',
                accessorKey: 'number',
                filterFn: 'contains',
                minSize: 200,
                Cell: ({cell, row}) => (
                    <Link
                        to={'/organization/orders/' + data[row.index].id + '/' + cell.getValue() + '/'
                            + data[row.index].header.id + '/'
                            + data[row.index].year}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>
                ),
            },
            {
                id: 'numberCustomer',
                header: 'Номер заказчика                                             ',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
            },
            {
                id: 'customer',
                header: 'Заказчик                                                           ',
                accessorKey: 'customerOrder.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: customerList.map((customer) => (
                        <MenuItem key={customer} value={customer}>
                            {customer}
                        </MenuItem>
                    )),
                },
                minSize: 15,
            },
            {
                id: 'header',
                header: 'Найменование                                                 ',
                accessorKey: 'header.name',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: nameList.map((header) => (
                        <MenuItem key={header} value={header}>
                            {header}
                        </MenuItem>
                    )),
                },
                enableGrouping: true,
                minSize: 15,
            },
            {
                id: 'model',
                header: 'Модель                                                             ',
                accessorKey: 'modelOrder.name',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                minSize: 150,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfReceipt === null) {
                    } else {
                        return new Date(row.dateOfReceipt)
                    }
                },
                id: 'dateOfReceipt',
                header: 'Дата поступления                                           ',
                sortingFn: 'datetime',
                size: 10,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                header: 'Констуктор                                                       ',
                accessorKey: 'constructor.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: constructorList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: constructorList.map((constructor) => (
                        <MenuItem key={constructor} value={constructor}>
                            {constructor}
                        </MenuItem>
                    )),
                },
                maxSize: 100,
                minSize: 100,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfConstructorDocumentation === null) {
                    } else {
                        return new Date(row.dateOfConstructorDocumentation)
                    }
                },
                id: 'dateOfConstructorDocumentation',
                header: 'Дата тех. документации конструктор           ',
                sortingFn: 'datetime',
                minSize: 250,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                header: 'Технолог                                                           ',
                accessorKey: 'technologist.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: technologistList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: technologistList.map((technologist) => (
                        <MenuItem key={technologist} value={technologist}>
                            {technologist}
                        </MenuItem>
                    )),
                },
                maxSize: 20,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfTechnicalDocumentation === null) {
                    } else {
                        return new Date(row.dateOfTechnicalDocumentation)
                    }
                },
                id: 'dateOfTechnicalDocumentation',
                header: 'Дата тех. документации технолог                ',
                sortingFn: 'datetime',
                minSize: 250,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                id: 'attributes',
                header: 'Атрибуты                                                         ',
                accessorKey: 'attributes',
                filterVariant: 'multi-select',
                filterSelectOptions: attributeList,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkStatus(row.getValue(id), filterValue)
                },
                minSize: 20,
                Cell: ({cell}) => (<div>{AttributeUtil.convertAttributes(cell.getValue())}</div>),

            },
            {
                accessorFn: (row) => {
                    if (row.manufactured === null) {
                    } else {
                        return new Date(row.manufactured)
                    }
                },
                id: 'manufactured',
                header: 'Произведено                                                   ',
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkDate(row.getValue(id), filterValue)
                },
                sortingFn: 'datetime',
                size: 10,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                accessorFn: (row) => {
                    if (row.shipped === null) {
                    } else {
                        return new Date(row.shipped)
                    }
                },
                id: 'shipped',
                header: 'Отгружено                                                        ',
                sortingFn: 'datetime',
                size: 10,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                id: 'state',
                header: 'Планирование                                                 ',
                accessorKey: 'state',
                filterVariant: 'multi-select',
                filterSelectOptions: planningList,
                minSize: 6,
                maxSize: 6,
            },
            {
                id: 'year',
                header: 'год                                                                     ',
                accessorKey: 'year',
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (<Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Архив заказов"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Архив заказов
                    </Typography>
                </Box>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='ru'
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <MaterialReactTable
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: 0
                                }
                            }
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                p: '0px 0px 0px 10px',
                                m: '0px 0px 0px 0px',
                                border: "none",
                                "&:not(:last-of-type)": {
                                    borderRight: '1px solid #EAEBEA',

                                },
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }

                                }
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            sx: {
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        columns={columns}
                        data={data}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{showGlobalFilter: true, pagination: tableOptions,}}
                        rowCount={tableOptions.totalElements}
                        initialState={{
                            columnVisibility: {
                                id: false,
                                state: false,
                                model: false,
                                year: false,
                            },
                        }}
                        renderTopToolbarCustomActions={({table}) => (
                            <Box sx={{
                                width: "100%",
                                padding: 0,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItem: "center"
                            }}>
                                <MRT_ToggleFiltersButton table={table}/>
                            </Box>)}
                        muiToolbarAlertBannerChipProps={{color: 'primary'}}
                        localization={MRT_Localization_RU}
                        enableRowActions={false}
                        enableToolbarInternalActions={false}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
};

export default ArchiveTable;