import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {Cached, PlaylistAdd} from "@mui/icons-material";
import CellShortenedName from "../../../orders/organization/estimate/CellShortenedName";

const MaterialTable = ({
                           currentData,
                           refMaterial,
                           isSelectInEstimate,
                           selectInEstimate,
                           rowSelection,
                           setRowSelection,
                           selectMaterialId,
                           selectMaterial,
                           tableOptions,
                           tableOptionsHandle,
                           modal,
                           allMaterial,
                           enableRowSelection,
                           disabled,
                           settings
                       }) => {

    const currentSelection = currentData.reduce(
        (acc, {id}) => {
            if (!rowSelection.includes(id)) {
                return acc
            }

            const index = currentData.findIndex((it) => it.id === id).toString()

            return {...acc, [index]: true}
        },
        {}
    );

    const onRowSelectionChange = (updater) => {
        const result = updater(currentSelection)

        const currentSelectedIds = Object.entries(result)
            .filter(([_, value]) => value)
            .map(([index]) => currentData[index].id)

        const currentPageIds = currentData.map(({id}) => id)
        const allSelectedWithoutCurrentPage = rowSelection.filter((it) => !currentPageIds.includes(it))

        setRowSelection(Array.from(new Set([...allSelectedWithoutCurrentPage, ...currentSelectedIds])))
    }

    const columns = useMemo(
        () => [
            {
                header: 'Артикул',
                accessorKey: 'articleNumber',
                size: 170,
                Cell: ({cell}) => <CellShortenedName
                    value={cell.getValue()}
                    numberOfCharacters={20}
                />,
            },
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: modal ? 520 : 850,
                Cell: ({cell, row}) => (refMaterial ?
                        <li key={cell.getValue()}>
                            <Link
                                to={allMaterial ? `/all-materials/${currentData[row.index].id}/${currentData[row.index].unit?.name}/${currentData[row.index].typeMaterial}${settings ? "?isSettings" : ""}` : '/organization/materials/' + currentData[row.index].id + '/' + currentData[row.index].unit?.name + '/' + currentData[row.index].typeMaterial + '/' + currentData[row.index].materialId}>
                                {cell.getValue()}</Link>
                        </li> : cell.getValue()
                ),
            },
            {
                header: 'Ед. изм.',
                accessorKey: 'unit.name',
                maxSize: 100,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [currentData, refMaterial],
    );

    return (
        <Box sx={{overflow: 'auto'}}>
            <MaterialReactTable
                muiTablePaperProps={{
                    sx: disabled && {
                        opacity: 0.7
                    }
                }}
                muiTopToolbarProps={{
                    sx: {
                        "& .MuiBox-root": {
                            padding: "8px 0"
                        }
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        },
                        "&:first-of-type": enableRowSelection && {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem",
                        "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                            "minWidth": "100%"
                        }
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        onClick: () => selectMaterial && !disabled && selectMaterial(row.original),
                        sx: () => ({
                            height: '36px',
                            cursor: selectMaterial && !disabled && "pointer",
                            "&:hover& td:after": (!modal || (disabled && modal) || row.original.id === selectMaterialId) && {
                                display: "none",
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: row.original.id === selectMaterialId ? "#D7E8FA" : '#F5F6FA',
                            },
                            backgroundColor: row.original.id === selectMaterialId ? "#D7E8FA" : "white",
                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-select': {
                        size: "50px",
                        grow: false,
                    },
                }}
                columns={columns}
                enableMultiRowSelection
                enableRowSelection={enableRowSelection}
                data={currentData}
                enableStickyHeader={true}
                enableStickyFooter={true}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: modal ? "550px" : Object.keys(rowSelection).length !== 0 ? `calc(100vh - 530px)` : `calc(100vh - 395px)`,
                        width: modal ? "770px" : settings ? "65vw" : "80vw"
                    }
                }}
                manualSorting={true}
                localization={MRT_Localization_RU}
                renderRowActions={({row}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: '50px'
                    }}>
                        {isSelectInEstimate && <Tooltip arrow placement="left" title="Заменить">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => selectInEstimate(row)}>
                                <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>}
                    </Box>
                )}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        backgroundColor: (theme) => theme.palette.primary.light,
                    }
                }}
                onGlobalFilterChange={value => tableOptionsHandle({...tableOptions, searchData: value})}
                manualPagination={true}
                rowCount={tableOptions.totalElements}
                onRowSelectionChange={onRowSelectionChange}
                selectAllMode={'all'}
                state={{
                    rowSelection: currentSelection,
                    columnVisibility: {
                        id: false,
                        "unit.name": !modal
                    },
                    pagination: tableOptions,
                    showGlobalFilter: true,
                }}

                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    sx: {
                        paddingTop: modal ? "32px" : "5px",
                        paddingBottom: modal ? "32px" : "0px",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: '12px',
                            border: '1 px solid red',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                enableToolbarInternalActions={false}
            />
        </Box>);
};

export default MaterialTable;
