import axios from "axios";
import {apiVersionCutting, gatewayUrl, nameAuthorizationServer} from "../../Constants";

const restUrl = gatewayUrl + nameAuthorizationServer + apiVersionCutting + '/customers/organizations'

export default class CustomerOrganizationService {

    static async getAll() {
        return axios.get(restUrl + "/favorite");
    }

    static async deleteInFavorite(organizationName) {
        return axios.patch(restUrl + "/delete-in-favorite" + "?organizationName=" + organizationName, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}