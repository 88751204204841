import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import ModelReplacementService from "../../../../../API/model-estimate/model/ModelReplacementService";
import Typography from "@mui/material/Typography";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

export const ModelReplacementsTable = ({materialId, data, setData, tableOptions, tableOptionsHandle, handleError}) => {
    const initValue = {tempName: "", materialName: ""}
    const [modalOpen, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);

    const handleClose = () => {
        setModal(false)
        setCurrentEditRowData(initValue)
    }
    const handleEdit = async (values) => {
        ModelReplacementService.update(values, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                handleClose()
            })
            .catch(function (error) {
                handleError(error);
            })
    };
    const handleSubmit = async (values) => {
        ModelReplacementService.create(materialId, values)
            .then(function (response) {
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
                handleClose()
            })
            .catch(function (error) {
                handleError(error);
            })
    };

    const handleDeleteRow = useCallback(
        (row) => {
            ModelReplacementService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );

    const handleUpdateModel = (row) => {
        setCurrentEditRowData(row);
        setModal(true);
    }


    const columns = useMemo(
        () => [
            {
                id: 'tempName',
                header: 'Заменяемое поле',
                accessorKey: 'tempName',
                minSize: 500,
            },
            {
                id: 'materialName',
                header: 'Имя материала',
                accessorKey: 'materialName',
                minSize: 600,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleUpdateModel},
        {type: "delete", text: "Удалить", handler: handleDeleteRow}
    ]
    return (
        <Box sx={{width: '1200px',}}>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                    Соответствие материалов
                </Typography>
                <ButtonCreate
                    text={"Создать"}
                    width={120}
                    onClick={() => setModal(true)}
                />
            </Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '1200px',
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                localization={MRT_Localization_RU}
            />
            <CreateNewReplacementModal
                title={currentEditRowData ? "Изменить" : "Добавить"}
                open={modalOpen}
                onClose={handleClose}
                handleSubmit={currentEditRowData?.id ? handleEdit : handleSubmit}
                initValue={currentEditRowData}/>
        </Box>
    );
};
const CreateNewReplacementModal = ({open, onClose, handleSubmit, initValue, title}) => {
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({tempName: "", materialName: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleClose = () => {
        setValidationState({tempName: "", materialName: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    name={"tempName"}
                    value={formState?.tempName}
                    label="Заменяемое поле"
                    setCurrValues={setFormState}
                    validationError={validationState?.tempName}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"materialName"}
                    value={formState?.materialName}
                    label="Имя материала"
                    setCurrValues={setFormState}
                    validationError={validationState?.materialName}
                    setValidationState={setValidationState}
                />
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}