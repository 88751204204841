import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import ArrivalMaterialService from "../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import ModalUpdateOrAddMaterialArrival from "../material/arrival/ModalUpdateOrAddMaterialArrival";
import CurrencyService from "../../../../../API/common/CurrencyService";
import SupplierService from "../../../../../API/orders/warehouse/supplier/SupplierService";
import WarehouseService from "../../../../../API/orders/warehouse/WarehouseService";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import {materialReserveState} from "../../../../../data/MaterialReserveState";
import ModalSetSupplier from "./ModalSetSupplier";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";
import ButtonCreate from "../../../button/ButtonCreate";
import {ModalSelectAction} from "../../../modal/ModalSelectAction";
import MenuItem from "@mui/material/MenuItem";
import selectionTableUtil from "../../../../../utils/common/SelectionTableUtil";

const ReservationMaterialTable = ({
                                      data,
                                      setData,
                                      isColumnOrder,
                                      isColumnMaterialWarehouse,
                                      isColumnOptimization,
                                      enableGrouping,
                                      expanded,
                                      pageSize,
                                      orderList,
                                      change,
                                      setChange,
                                      columnGrouping,
                                      titleModalUpdateOrAddMaterialArrival,
                                      typeMaterial,
                                      rowSelection,
                                      setRowSelection,
                                      tableOptions,
                                      tableOptionsHandle,
                                      openSelectRowModal,
                                      closeSelectRowModal,
                                      handleError
                                  }) => {
        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [setSupplierModalOpen, setSetSupplierModalOpen] = useState(false);
        const [date, setDate] = useState(null);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [materialDeliveryDate, setMaterialDeliveryDate] = useState(null);
        const [currencyList, setCurrencyList] = useState([]);
        const [currencyId, setCurrencyId] = useState(null);
        const [supplierList, setSupplierList] = useState([]);
        const [supplierId, setSupplierId] = useState(null);
        const [warehouseList, setWarehouseList] = useState([]);
        const [warehouseId, setWarehouseId] = useState(null);

        useEffect(() => {
            CurrencyService.getAll().then(function (response) {
                let data = response.data;
                data.sort((a, b) =>
                    a.defaultBase === b.defaultBase ? 0 : a.defaultBase ? -1 : 1
                );
                setCurrencyList(data);
            })
                .catch(function (error) {
                    handleError(error);
                });
            SupplierService.get().then(function (response) {
                let data = response.data;
                setSupplierList(data);
            })
                .catch(function (error) {
                    handleError(error);
                });
            WarehouseService.getAll().then(function (response) {
                let data = response.data;
                setWarehouseList(data);
            })
                .catch(function (error) {
                    handleError(error);
                });
        }, []);

        const handleDeleteRow = useCallback(
            (row) => {
            },
            [data],
        );
        const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

        const handleUpdateReserve = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setSetSupplierModalOpen(true);
                setSupplierId(row.supplierMaterial.supplier.id);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleCloseUpdateReserve = () => {
            setSetSupplierModalOpen(false);
            setSupplierId(null);
        };

        const handleCloseCreateOrUpdateModalOpen = () => {
            setCreateOrUpdateModalOpen(false)
            setCurrencyId(null);
            setSupplierId(null);
            setWarehouseId(null);
        };

        const handleSetSupplier = async () => {
            MaterialReserveService.setSupplier(currentEditRowData.id, supplierId, typeMaterial)
                .then(function (response) {
                    setData(prev => prev.map(el => el.id === response.data.id ? {
                        ...el,
                        supplierMaterial: {...el.supplierMaterial, supplier: response.data.supplierMaterial.supplier}
                    } : el))
                    handleCloseUpdateReserve()
                })
                .catch(function (error) {
                    handleError(error);
                })
        };

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'materialArticleNumber',
                    header: 'Артикул',
                    accessorKey: 'materialArticleNumber',
                    minSize: 100,
                },
                {
                    id: 'materialName',
                    header: 'Найменование',
                    accessorKey: 'materialName',
                    minSize: 700,

                },
                {
                    id: 'quantity',
                    header: 'Необходимо.',
                    accessorKey: 'quantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'primary.main',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),
                },
                {
                    id: 'availabilityQuantity',
                    header: 'Наличие',
                    accessorKey: 'availabilityQuantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'success.main',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),

                },
                {
                    id: 'orderedQuantity',
                    header: 'Заказано',
                    accessorKey: 'orderedQuantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'secondary.main',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),
                },
                {
                    id: 'needToOrderQuantity',
                    header: 'Необходимо заказать',
                    accessorKey: 'needToOrderQuantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'error.light',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),
                },
                {
                    id: 'supplier',
                    header: 'Поставщик',
                    accessorKey: 'supplierMaterial.supplier.name',
                    minSize: 55,
                    maxSize: 55,
                },
                {
                    id: 'materialReserveState',
                    header: 'Статус',
                    accessorKey: 'materialReserveState',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({cell}) => materialReserveState[cell.getValue()],
                },
                {
                    id: 'orderNumber',
                    header: 'Заказы',
                    accessorKey: 'orders',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => {
                        return OrderUtil.convertNumberOrders(cell.getValue())
                    },
                    filterVariant: 'multi-select',
                    filterSelectOptions: orderList,
                    filterFn: (row, id, filterValue) => {
                        return OrderUtil.checkNumberOrder(row.getValue(id), filterValue)
                    },
                },
                {
                    id: 'plan.number',
                    header: 'План',
                    accessorKey: 'plan.number',
                    minSize: 15,
                    maxSize: 15,
                },

                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (isColumnMaterialWarehouse) {
                baseColumns.splice(2, 0, {
                    id: 'size',
                    header: 'Размеры',
                    accessorKey: 'size',
                    minSize: 15,
                    maxSize: 15,
                });
            }

            if (isColumnOptimization) {
                baseColumns.splice(2, 0, {
                    id: 'optimization.number',
                    header: 'Оптимизация',
                    accessorKey: 'optimization.number',
                    minSize: 150,
                    maxSize: 150,
                    Cell: ({cell, row}) => {
                        const optimization = row.original.optimization;
                        if (optimization) {
                            return (
                                <li key={cell.getValue()}>
                                    <Link
                                        to={'/organization/requests-cutting/optimization/' + optimization.id + '/' + optimization.number}>
                                        {cell.getValue()}
                                    </Link>
                                </li>
                            );
                        } else {
                            return null;
                        }
                    },
                });
            }

            return baseColumns;

        }, [data, setData, isColumnOrder]);

        const handleCreateData = () => {
            const ArrivalMaterial = {
                arrivalState: 'EXECUTION_OF_APPLICATION',
                comment: '',
                currencyArrival: null,
                date: null,
                id: null,
                materialDeliveryDate: null,
                supplier: null,
                warehouse: null,
            };
            setCurrentEditRowData(ArrivalMaterial);
            setDate(new Date());
            setMaterialDeliveryDate(new Date())
            setCreateOrUpdateModalOpen(true);
        };

        const handleSubmitCreateByReserves = () => {
            currentEditRowData.date = date === null ? null : DateTimeUtil.dateConvert(date);
            currentEditRowData.materialDeliveryDate = materialDeliveryDate === null ? null : DateTimeUtil.dateConvert(materialDeliveryDate);

            const currentCurrencyId = currencyId === null ? currencyList[0].id : currencyId;
            const currentWarehouseId = warehouseId == null ? warehouseList[0].id : warehouseId;

            ArrivalMaterialService.createByReserves(rowSelection, currentEditRowData, currentCurrencyId, currentWarehouseId,
            ).then(function () {
                setChange(!change)
                closeSelectRowModal()
                setRowSelection([])
                handleCloseCreateOrUpdateModalOpen();
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        const navigate = useNavigate();

        const redirectMaterialsArrival = () => {
            navigate('/organization/materials-arrival');
        };

        const handleSelectAction = () => {
            handleCreateData()
        }
        const modalActions = [
            {name: "Создать закупку", type: "createByReserves"},
        ]
        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleUpdateReserve}
        ]
        const activeColumn = typeMaterial === 'SLAB' ? "&:nth-of-type(3)" : "&:nth-of-type(2)"
        return (
            <Box sx={{maxWidth: "2600px"}}>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
                >
                    <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>

                        <ButtonCreate
                            text={"Приход материалов"}
                            width={170}
                            icon={false}
                            onClick={redirectMaterialsArrival}
                        />
                    </Box>
                </Box>
                <MaterialReactTable
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            [activeColumn]: {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem",
                            "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                "minWidth": "100%"
                            }
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            sx: () => ({
                                height: '36px',
                                backgroundColor: "white",
                                "&:hover& td:after": {
                                    display: "none",
                                },
                                "&:hover": {
                                    "& td:after": {
                                        display: "none",
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                },
                            })
                        })
                    }}

                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        sx: {
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                            muiTableBodyCellProps: {
                                align: 'center',
                            },
                        },
                        'mrt-row-select': {
                            size: "50px",
                            grow: false,
                        },
                        "mrt-row-expand": {
                            size: "50px",
                            grow: false,

                        }
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        expanded: expanded,
                        grouping: columnGrouping,
                    }}
                    enableGrouping={enableGrouping}
                    onRowSelectionChange={onRowSelectionChange}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowSelection={true}
                    enableRowActions={true}
                    getRowId={(originalRow) => originalRow.id}
                    enableToolbarInternalActions={false}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    muiToolbarAlertBannerProps={{
                        sx: {
                            backgroundColor: (theme) => theme.palette.primary.light,
                        }
                    }}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })}
                    localization={MRT_Localization_RU}
                />
                <ModalUpdateOrAddMaterialArrival
                    open={createOrUpdateModalOpen}
                    handleClose={() => handleCloseCreateOrUpdateModalOpen()}
                    title={titleModalUpdateOrAddMaterialArrival}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    date={date}
                    setDate={setDate}
                    materialDeliveryDate={materialDeliveryDate}
                    setMaterialDeliveryDate={setMaterialDeliveryDate}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    setCurrencyId={setCurrencyId}
                    supplierList={supplierList}
                    supplierId={supplierId}
                    setSupplierId={setSupplierId}
                    warehouseList={warehouseList}
                    warehouseId={warehouseId}
                    setWarehouseId={setWarehouseId}
                    handleSubmit={handleSubmitCreateByReserves}
                    isNew={true}
                />
                <ModalSetSupplier
                    open={setSupplierModalOpen}
                    handleClose={() => handleCloseUpdateReserve()}
                    title={"Изменить поставщика"}
                    setSupplierId={setSupplierId}
                    supplierList={supplierList}
                    supplierId={supplierId}
                    handleSubmit={handleSetSupplier}
                />
                <ModalSelectAction
                    open={openSelectRowModal}
                    reset={closeSelectRowModal}
                    submit={handleSelectAction}
                    modalActions={modalActions}
                />
            </Box>
        );
    }
;

export default ReservationMaterialTable;