import React from 'react';
import {Route, Routes} from "react-router-dom";
import {routes} from "../router/routes";
import Home from "../pages/common/Home";
import Error from "../pages/common/Error";
import Settings from "../pages/common/Settings";

const AppRouter = ({stage, setStage, profile}) => {
    return (<Routes>
        <Route
            element={<Home/>}
            path="/home"
            errorElement={<Error/>}
        />
        <Route
            element={<Home/>}
            path=""
            errorElement={<Error/>}
        />
        <Route
            element={<Settings stage={stage} setStage={setStage} profile={profile}/>}
            path="/settings"
            errorElement={<Error/>}
        />
        {routes.map(route => <Route
            key={route.path}
            element={route.component}
            path={"" + route.path}
            errorElement={route.errorElement}
        />)}
    </Routes>);
};

export default AppRouter;