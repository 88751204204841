import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import CustomerRequestsCuttingService from "../../../API/orders/optimization/customer/CustomerRequestsCuttingService";
import CustomerDetailsTable from "../../../components/UI/table/customer-detales/CustomerDetailsTable";
import DetailControllerService from "../../../API/details/DetailControllerService";
import {constantsErrorMessages} from "../../../Constants";
import CustomerRequestsCuttingEstimateItem from "./CustomerRequestsCuttingEstimateItem";
import CustomerEstimateTableItem from "./CustomerEstimateTableItem";
import CustomerRequestsEdges from "../edges/CustomerRequestsEdges";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import CustomerOrderService from "../../../API/orders/orders/customer/CustomerOrderService";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import CheckIcon from '@mui/icons-material/Check';
import {ButtonGroupTabBar} from "../../../components/UI/button/ButtonGroupTabBar";

const textOrder = [
    "Различные файлы касающиеся заказа",
    "Создание или импорт заявки на раскрой",
    "Список всех деталей на обработку",
    "Добавление кромочного материала и способа его оклеивания",
    "Список материалов не требующих раскроя, а также не требующего оклеивания",
    "Общая смета всех используемых материалов"]

const CustomerOrderItem = () => {
        const pathname = useLocation().pathname
        const params = useParams();
        const navigate = useNavigate();

        const [tab, setTab] = useState(1);
        const [change, setChange] = useState(null);
        const [orderData, setOrderData] = useState({})


        const handleChange = (event, newTab) => {
            setTab(newTab);
            EditLocalStoragePagesData(pathname, "tab", newTab)
        };

        const [data, setData] = useState([]);
        const [unable, setUnable] = useState({unableTurn: {}, unablePackage: {}});
        const [isLoading, setIsLoading] = useState(true);
        const [isError, setIsError] = useState(false);
        const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'

        const handleError = (error) => {
            setIsError(true)
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }

        useEffect(() => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const savedTab = pagesData[pathname]?.tab
            const openFirstTab = (orderData.orderType === "CUT" && (savedTab === 3 || savedTab === 5)) || (orderData.orderType === "CUT_PROCESSING" && savedTab === 5)

            if (openFirstTab || !savedTab) {
                setTab(1)
            } else {
                setTab(savedTab)
            }
            CustomerOrderService.getById(params.id)
                .then(function (response) {
                    setOrderData(response.data)
                })
                .catch(function (error) {
                    handleError(error)
                })
        }, [])

        useEffect(() => {
            setIsLoading(true)
            DetailControllerService.getAllByOrderId(params.id)
                .then(function (response) {
                    const data = response?.data
                    setData(data);
                    setUnable(({unableTurn, unablePackage}) => {
                        data.forEach(row => {
                            unableTurn[row.id] = row.turn;
                            unablePackage[row.id] = row.package;
                        })
                        return {unableTurn, unablePackage}
                    })
                    setIsError(false)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => setIsLoading(false))
        }, [change]);

        const renderContent = () => {
            switch (tab) {
                case 1:
                    return <Box
                        sx={{display: ' block', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>

                        {/*   <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'проекта'}
                                label={'Загрузить файлы проекта'}
                                getAllFile={FileService.getAllProjectFileNamesByOrderId}
                                fileNameType={'PROJECT'}
                            />
                            <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'замера'}
                                label={'Загрузить файлы замера'}
                                getAllFile={FileService.getAllMeasuringFileNamesByOrderId}
                                fileNameType={'MEASURING'}
                            />*/}
                    </Box>
                case 2:
                    return <CustomerRequestsCuttingEstimateItem
                        getAll={CustomerRequestsCuttingService.getAllByCustomerOrderId}
                        isOptimization={false}
                        isRedirectRequestsCutting={false}
                        orderId={params.id}
                        /* nameOrder={nameOrder}*/
                        // idHeader={orderIdHeader}
                        // year={year}
                        isOrder={true}
                        isButtonAddRequestsCutting={true}
                        isEstimate={true}
                        isCreateOptimizationCalculation={false}
                        isSetForOptimization={false}
                        customerOrderNumber={orderData.numberCustomer}
                        handleError={handleError}
                    />
                case 3:
                    return <CustomerDetailsTable
                        data={data}
                        unableTurn={unable.unableTurn}
                        unablePackage={unable.unablePackage}
                        pageSize={100}
                        isError={isError}
                        setData={setData}
                        enableEditing={true}
                        orderId={params.id}
                        customerOrderNumber={orderData.numberCustomer}
                        handleError={handleError}
                    />
                case 4:
                    return <CustomerRequestsEdges orderId={params.id} handleError={handleError}/>
                case 5:
                    return
                // <EstimateItem
                //     estimateId={1}
                //     orderId={params.id}
                //     /* orderNumber={nameOrder}*/
                //     // orderHeaderId={orderIdHeader}
                //     // year={year}
                //     isButtonPurchase={true}
                //     enableEditing={true}
                //     isActual={true}
                //     inWork={true}
                // />
                default:
                    return <CustomerEstimateTableItem
                        orderId={params.id}
                        customerOrderNumber={orderData.numberCustomer}
                        handleError={handleError}
                    />
            }
        }

        return (
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent page={orderData.numberCustomer}
                                      path={[{name: "Главная", href: "/"}, {name: "Заказы", href: "/customer/orders"}]}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                        <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                            Заказ № {orderData.numberCustomer}
                        </Typography>
                        <Typography>{textOrder[tab - 1]}</Typography>
                    </Box>
                    <ButtonGroupTabBar activeTab={tab}>
                        <Button tabIndex={1} onClick={(e) => handleChange(e, 1)}>
                            <CheckIcon/>
                            Файлы
                        </Button>
                        <Button tabIndex={2} onClick={(e) => handleChange(e, 2)}>
                            <CheckIcon/>
                            Заявки на раскрой
                        </Button>
                        <Button tabIndex={4} onClick={(e) => handleChange(e, 4)}>
                            <CheckIcon/>
                            Кромки
                        </Button>
                        {orderData.orderType !== "CUT" &&
                            <Button tabIndex={3} onClick={(e) => handleChange(e, 3)}>
                                <CheckIcon/>
                                Обработка
                            </Button>
                        }
                        {orderData.orderType === "CUT_PROCESSING_ACCESSORIES" &&
                            <Button tabIndex={5} onClick={(e) => handleChange(e, 5)}>
                                <CheckIcon/>
                                Фурнитура
                            </Button>
                        }
                        <Button tabIndex={6} onClick={(e) => handleChange(e, 6)}>
                            <CheckIcon/>
                            Общая смета
                        </Button>
                    </ButtonGroupTabBar>
                    {renderContent()}
                    <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                        return {...alert, open: false}
                    })}/>
                </Box>
            </Box>
        );
    }
;

export default CustomerOrderItem;
