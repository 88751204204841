import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {Box, Button,} from "@mui/material";
import JobMetricOrderOrganizationTable
    from "../../../components/UI/table/orders/organization/estimate/JobMetricOrderOrganizationTable";
import CustomerEstimateOrganizationCostMaterialTable
    from "../../../components/UI/table/orders/customer/order/CustomerEstimateOrganizationCostMaterialTable";
import CustomerMaterialEstimateUnitService
    from "../../../API/orders/estimate/customer/CustomerMaterialEstimateUnitService";
import CustomerEstimateOrganizationTotalMaterialTable
    from "../../../components/UI/table/orders/customer/order/CustomerEstimateOrganizationTotalMaterialTable";
import {constantsErrorMessages} from "../../../Constants";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import {ButtonGroupTabBar} from "../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";

const CustomerOrganizationEstimateItem = ({}) => {
    const pathname = useLocation().pathname
    const [alertData, setAlertData] = useState({});
    const params = useParams();
    const {orderId, customerOrderNumber, estimateId, base, alternative} = params;
    const [tab, setTab] = useState(1);
    const [JobMetricOrderOrganization, setJobMetricOrderOrganization] = useState([]);
    const [materialEstimateUnit, setMaterialEstimateUnit] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const handleChangeTab = (tab) => {
        setTab(tab)
        EditLocalStoragePagesData(pathname, "tab", tab)
    }

    useEffect(() => {
        getStartPageSize()
        CustomerMaterialEstimateUnitService.getAllByEstimateOrganization(estimateId)
            .then(function (response) {
                const fetchedData = response.data;
                setTableOptions((prev) => ({...prev, totalElements: fetchedData.length}))
                setMaterialEstimateUnit(fetchedData);
                const calculationWithJobMetric = [];
                fetchedData.forEach(item => {
                    let jobMetricOrderOrganizations = item.jobMetricOrderOrganizations;
                    if (jobMetricOrderOrganizations) {
                        calculationWithJobMetric.push(...item.jobMetricOrderOrganizations);
                    }
                });
                setJobMetricOrderOrganization(calculationWithJobMetric);
            })
            .catch(function (error) {
                handleError(error);
            });
    }, [estimateId]);


    const generalTabComponents = [
        {
            tabIndex: 1, label: "Итоги",
            component: <CustomerEstimateOrganizationTotalMaterialTable
                data={materialEstimateUnit}
                setData={setMaterialEstimateUnit}
                orderId={orderId}
                estimateId={estimateId}
                baseCurrency={base}
                alternativeCurrency={alternative}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        }, {
            tabIndex: 2, label: "Стоимость материалов",
            component: <CustomerEstimateOrganizationCostMaterialTable
                data={materialEstimateUnit}
                setData={setMaterialEstimateUnit}
                estimateId={estimateId}
                baseCurrency={base}
                alternativeCurrency={alternative}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        }, {
            tabIndex: 3, label: "Стоимость работ",
            component: <JobMetricOrderOrganizationTable
                customer={true}
                data={JobMetricOrderOrganization}
                setData={setJobMetricOrderOrganization}
                currency={[{id: 1, value: base}, {id: 2, value: alternative}]}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        },]


    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Подробная смета`}
                path={[{name: "Главная", href: "/"},
                    {name: "Заказы", href: "/customer/orders"},
                    {
                        name: `Заказ ${customerOrderNumber}`,
                        href: `/customer/orders/${orderId}/${customerOrderNumber}`
                    }, {
                        name: `Предложение заказа ${customerOrderNumber}`,
                        href: `/customer/orders/${orderId}/${customerOrderNumber}/estimates`
                    }]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                    Подробная смета
                </Typography>
                <ButtonGroupTabBar activeTab={tab}>
                    {generalTabComponents.map(({tabIndex, label}, index) => {
                        return <Button key={tabIndex} tabIndex={tabIndex} onClick={() => handleChangeTab(tabIndex)}>
                            <CheckIcon/>
                            {label}
                        </Button>
                    })}
                </ButtonGroupTabBar>
                {generalTabComponents.find(component => component.tabIndex === tab)?.component}
            </Box>
            <ModalAlert
                data={alertData}
                onClose={() => setAlertData((alert) => ({...alert, open: false}))}
            />
        </Box>
    );
};

export default CustomerOrganizationEstimateItem;