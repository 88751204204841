import React, {useEffect, useState} from 'react';
import RatingJobWorkerService from "../../../../API/orders/job/RatingJobWorkerService";
import {useParams} from "react-router-dom";
import RatingWorker from "../../../../components/UI/table/orders/organization/workerTable/RatingWorker";
import Typography from "@mui/material/Typography";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../../Constants";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import ButtonExit from "../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../components/UI/button/ButtonAdd";
import TypeJobOrganizationService from "../../../../API/orders/job/TypeJobOrganizationService";
import TextFieldValidation from "../../../../components/UI/table/customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import {Rating} from "@mui/lab";
import StarIcon from "@mui/icons-material/Star";

const RatingsWorker = () => {
    const [alertData, setAlertData] = useState({});
    const params = useParams();
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false)
    const [unAddedWorks, setUnAddedWorks] = useState([]);

    useEffect(() => {
        RatingJobWorkerService.getAllByWorkerId(params.id).then(response => response.data)
            .then((ratings) => TypeJobOrganizationService.getAllOrganization()
                .then(function (response) {
                    const unAddedWorks = response.data.filter(work => !ratings.find(el => el.typeJob.id === work.id))
                    setData(ratings);
                    setUnAddedWorks(unAddedWorks);
                }))
            .catch(function (error) {
                handleError(error);
            });
    }, []);
    
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const handleDelete = (item, setConfirm) => {
        RatingJobWorkerService.delete(item.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== item.id))
                setConfirm(false)
                setUnAddedWorks(prev => [...prev, item.typeJob])
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const handleEdit = (id, data) => {
        RatingJobWorkerService.update(id, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const handleSubmit = (data, handleClose) => {
        RatingJobWorkerService.save({level: data.level, typeJob: {id: data.typeJobId}, workerId: params.id})
            .then(function (response) {
                setData(prev => [...prev, response.data])
                setUnAddedWorks(prev => prev.filter(el => el.id !== data.typeJobId))
                handleClose()
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Рейтинг"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>

            <Box sx={{display: "flex", flexDirection: "column", gap: "30px"}}>
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                    Работник: {params.nameWorker}
                </Typography>
                <Box sx={{display: "flex", flexDirection: "column", gap: "20px"}}>
                    {data.map((item, index) => (
                        <RatingWorker
                            key={index}
                            item={item}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                        />
                    ))}
                </Box>
                {unAddedWorks.length !== 0 && <Box sx={{marginLeft: "20px"}}>
                    <ButtonCreate
                        text={"Добавить"}
                        width={130}
                        onClick={() => setModalOpen(true)}
                    />
                </Box>}
            </Box>
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
            <ModalAddRating
                unAddedWorks={unAddedWorks}
                modalOpen={modalOpen}
                handleClose={() => setModalOpen(false)}
                handleSubmit={handleSubmit}/>
        </Box>
    );
};

const ModalAddRating = ({modalOpen, handleClose, handleSubmit, unAddedWorks}) => {
    const [currData, setCurrData] = useState({typeJobId: null, level: 5});

    useEffect(() => {
        setCurrData({...currData, typeJobId: unAddedWorks[0]?.id})
    }, [unAddedWorks])

    return (
        <Dialog PaperProps={{
            style: {
                minWidth: "500px",
            }
        }} open={modalOpen} onClose={handleClose}>
            <DialogTitle textAlign="center">Добавить</DialogTitle>
            <DialogContent sx={{padding: "3px 24px"}}>
                <TextFieldValidation
                    select
                    name="typeJobId"
                    label="Работа"
                    value={currData.typeJobId}
                    setCurrValues={setCurrData}
                >
                    {unAddedWorks.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextFieldValidation>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <Rating
                        name={currData.level}
                        value={currData.level}
                        onChange={(event, newValue) => {
                            setCurrData({...currData, level: newValue});
                        }}
                        precision={0.5}
                        emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit"/>}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={handleClose}/>
                <ButtonAdd text={"Добавить"} onClick={() => handleSubmit(currData, handleClose)}/>
            </DialogActions>
        </Dialog>
    );
};

export default RatingsWorker;