import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import ContainerComponent from "./components/ContainerComponent";

const theme = createTheme({
    palette: {
        text: {
            main: '#2C3038',
            light: '#737478',
        },
        background: {
            grey: "#F3F4F6"
        },
        primary: {
            dark: '#0080E0',
            main: '#3B8BEC',
            lightGrey: "#F5F6FA",
            light: '#D7E8FA',
            stroke: "#EAEBEA"
        },
        secondary: {
            main: '#B23850',
            add: '#F87B0E',
        },
        success: {
            main: '#2e7d32',
            light: '#4caf50',
        },
        error: {
            main: '#d32f2f',
            light: '#ef5350',
        },
        pencil: {
            main: '#ea8508'
        },
    },
    typography: {
        fontWeight: 700,
        fontFamily: [
            'Manrope',
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
        "fontSize": 16,
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ContainerComponent/>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
