import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {
    Button,
    Checkbox,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import {modelParameterUnit} from "../../../../../data/ModelParameterUnit";
import {orderJobType} from "../../../../../data/OrderJobType";
import UtilityService from "../../../../../API/common/UtilityService";
import JobMetricOrganizationService from "../../../../../API/orders/job/JobMetricOrganizationService";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import UnitService from "../../../../../API/orders/warehouse/UnitService";
import {typeMaterialWithSubtype} from "../../../../../data/TypeMaterial";
import {millingJob} from "../../../../../data/MillingJobType";
import {contourType} from "../../../../../data/ContourType";
import {grooveType} from "../../../../../data/GrooveType";
import {toolType} from "../../../../../data/ToolType";
import {gluing} from "../../../../../data/Gluing";
import {edgeEnd} from "../../../../../data/EdgeEnd";
import {edgeJob} from "../../../../../data/EdgeJob";
import selectionTableUtil from "../../../../../utils/common/SelectionTableUtil";
import ModalChooseJob from "./ModalModalChooseJob";
import ModalAddJobInAdmin from "./ModalAddJobInAdmin";
import MaterialOrganizationOptionService
    from "../../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import ModalAddJob from "./ModalAddJob";
import MaterialService from "../../../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";

const JobMetricOrganizationTable = ({
                                        data,
                                        setData,
                                        enableEditing,
                                        isRedirectSettings,
                                        isChooseSignJob,
                                        enableRowSelection,
                                        rowSelection,
                                        setRowSelection,
                                        setRowIndexSelection,
                                        setSelectedIds,
                                        isPrice,
                                        isUnit,
                                        baseCurrency,
                                        alternativeCurrency,
                                        isModal,
                                        onlyName,
                                        selectedString,
                                        setSelectedString,
                                        fullWidth,
                                        columnAdded,
                                        tableOptions,
                                        tableOptionsHandle,
                                        handleError,
                                        admin,
                                        api,
                                        itemId,
                                        addJob,
                                        materialId,
                                        id,
                                        rowActions = true,
                                        settings,
                                        type,
                                        allCurrencies
                                    }) => {
        const [modalChooseJob, setModalChooseJob] = useState(false);
        const [modalAddJob, setModalAddJob] = useState(false);
        const [currencies, setCurrencies] = useState([])
        const [listUnits, setListUnits] = useState([]);
        const [currentEditRowData, setCurrentEditRowData] = useState({});
        const [modalAddInAdmin, setModalAddInAdmin] = useState(false);
        const allMaterials = Object.values(typeMaterialWithSubtype)
        const allSubMaterials = allMaterials.reduce((acc, el) => {
            return [...acc, ...Object.entries(el.subtype)]
        }, [])
        const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

        useEffect(() => {
            // allCurrencies ? setCurrencies(allCurrencies) :
            UtilityService.getCurrencies()
                .then(function (response) {
                    setCurrencies(response.data)
                }).catch(function (error) {
                handleError(error)
            })
            UnitService.getAll().then(function (response) {
                let data = response.data;
                setListUnits(data)
            })
                .catch(function (error) {
                    handleError(error);
                });
        }, []);
        const navigate = useNavigate();

        const redirectSettings = () => {
            navigate('/settings-orders');
        };

        const handleSubmitAddSign = (value) => {
            JobMetricOrganizationService.create(currentEditRowData.metricName, value.currency, currentEditRowData.orderJobType, value.price)
                .then(function (response) {
                    setData(prev => prev.map(el => el.metricName === response.data.metricName ? response.data : el))
                }).catch(function (error) {
                handleError(error)
            })
        }
        const handleSubmitAddJob = (ids, addedData) => {
            if (admin) {
                MaterialService.addJobMetric(id, {"ids": ids}, type).then(function (response) {
                    setData(data, addedData)
                    tableOptionsHandle((prev) => ({
                        ...prev,
                        totalElements: data.length + ids.length
                    }))
                    setModalAddJob(false)
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            } else {
                MaterialOrganizationOptionService.addJobMetric(id, {"ids": ids}).then(function (response) {
                    setData(prev => ([...prev, ...addedData]))
                    tableOptionsHandle((prev) => ({
                        ...prev,
                        totalElements: data.length + ids.length
                    }))
                    setModalAddJob(false)
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }

        }
        const handleDelete = (row) => {
            api.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        }
        const handleSubmitModalAdmin = (value) => {
            currentEditRowData.id ? api.update(value, value.id)
                .then(function (response) {
                    setData(prev => prev.map(el => el.id === currentEditRowData.id ? response.data : el))
                    setModalAddInAdmin(false)
                })
                .catch(function (error) {
                    handleError(error);
                }) : api.create(value)
                .then(function (response) {
                    setData(prev => [...prev, response.data])
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
                    setModalAddInAdmin(false)
                })
                .catch(function (error) {
                    handleError(error);
                })
        }
        const handleEdit = (row) => {
            setCurrentEditRowData(row)
            setModalAddInAdmin(true)
        }

        const handleChange = (row) => {
            if (row?.added) {
                JobMetricOrganizationService.delete(row.id)
                    .then(function (response) {
                        setData(prev => prev.map(el => el.metricName === row.metricName ? {
                            ...row,
                            added: false,
                            basePrice: null,
                            alternativePrice: null
                        } : el))
                    }).catch(function (error) {
                    handleError(error)
                })
            } else {
                setCurrentEditRowData(row)
                setModalChooseJob(true)
            }
        }
        const sortArr = {"CUT": 0, "MILLING": 1, "EDGE": 2, "DRILLING": 3, "PACKAGING": 4}

        const handleAdd = () => {
            if (admin && settings) {
                const initData = columns.reduce((acc, el) => ({
                    ...acc,
                    [el.accessorKey]: el.checkBox === true ? true : ""
                }), {})
                setCurrentEditRowData(initData)
                setModalAddInAdmin(true)
            } else {
                setModalAddJob(true)
            }

        }

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: admin ? "name" : "metricName",
                    header: 'Найменование операции',
                    accessorKey: admin ? "name" : "metricName",
                    enableEditing: false,
                    enableGrouping: true,
                    minSize: 35,
                    Cell: ({cell, row}) => (row.original?.added && !isModal ?
                        <Link to={`/settings/signJob/${row.original.id}`}>{cell.getValue()}</Link> : cell.getValue()),
                },

                {
                    header: `Тип работ`,
                    accessorKey: 'orderJobType',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => orderJobType[cell.getValue()],
                    enableSorting: !admin,
                    sortingFn: (rowA, rowB, columnId) => {
                        return sortArr[rowA.original.orderJobType] > sortArr[rowB.original.orderJobType] ? 1 : -1
                    }
                },
                {
                    id: "modelParameterUnit",
                    header: 'Параметр с модели',
                    accessorKey: 'modelParameterUnit',
                    enableEditing: false,
                    enableGrouping: true,
                    minSize: 280,
                    Cell: ({cell}) => modelParameterUnit[cell.getValue()],
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableColumnFilter: false,
                    enableEditing: false,
                },
            ];
            if (admin && itemId !== "listJobEdge") {
                baseColumns.splice(1, 0, {
                    id: "subTypeMaterial",
                    header: `Подтип материала`,
                    accessorKey: 'subTypeMaterial',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => allSubMaterials.find(el => el[0] === cell.getValue())[1],
                });
            }

            if (!admin) {
                baseColumns.splice(1, 0, {
                    id: "basePrice",
                    header: `Стоимость: ${baseCurrency}`,
                    accessorFn: (row) => row.basePrice?.price,
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? '-' : value.toFixed(2)
                    },
                });
                baseColumns.splice(2, 0, {
                    id: "alternativePrice",
                    header: `Стоимость: ${alternativeCurrency}`,
                    accessorFn: (row) => row.alternativePrice?.price,
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? '-' : value.toFixed(2)
                    },
                },);
            }
            if (admin && !isModal) {
                baseColumns.splice(4, 0, {
                    header: 'Компактная пластина',
                    accessorKey: 'compactPlate',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.compactPlate}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
                baseColumns.splice(5, 0, {
                    header: 'Обязательный',
                    accessorKey: 'necessarily',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.necessarily}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
                baseColumns.splice(6, 0, {
                    header: 'Видимость',
                    accessorKey: 'display',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.display}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
                baseColumns.splice(7, 0, {
                    header: 'Деталь',
                    accessorKey: 'detail',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.detail}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
            }
            if (admin && (itemId === "listJobMilling" || itemId === "listJobEdge") && !isModal) {
                baseColumns.splice(4, 0, {
                    header: 'Количество от',
                    accessorKey: 'restrictionsQuantityFrom',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                });
                baseColumns.splice(5, 0, {
                    header: 'Количество до',
                    accessorKey: 'restrictionsQuantityTo',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                })
            }

            if (admin && itemId === "listJobMilling" && !isModal) {
                baseColumns.splice(4, 0, {
                    header: 'Толщина материала от',
                    accessorKey: 'thicknessMaterialFrom',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                });
                baseColumns.splice(5, 0, {
                    header: 'Толщина материала до',
                    accessorKey: 'thicknessMaterialTo',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                })
                baseColumns.splice(9, 0, {
                    header: 'Конец фрезирования',
                    accessorKey: 'millingEnd',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.millingEnd}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
                baseColumns.splice(4, 0, {
                    header: 'Работа фрезой',
                    accessorKey: 'millingJob',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => millingJob[cell.getValue()],
                });
                baseColumns.splice(4, 0, {
                    header: 'Тип паза',
                    accessorKey: 'grooveType',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => grooveType[cell.getValue()],
                });
                baseColumns.splice(4, 0, {
                    header: 'Тип контура',
                    accessorKey: 'contourType',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => contourType[cell.getValue()],
                });
                baseColumns.splice(4, 0, {
                    header: 'Инструмент',
                    accessorKey: 'tool',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => toolType[cell.getValue()],
                });
            }
            if (admin && itemId === "listJobDrilling" && !isModal) {
                baseColumns.splice(4, 0, {
                    header: 'Диаметр тверстия от',
                    accessorKey: 'diameterHoleFrom',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                });
                baseColumns.splice(4, 0, {
                    header: 'Диаметр тверстия до',
                    accessorKey: 'diameterHoleTo',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                });
                baseColumns.splice(4, 0, {
                    header: 'Глубина тверстия от',
                    accessorKey: 'depthHoleFrom',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                });
                baseColumns.splice(4, 0, {
                    header: 'Диаметр тверстия до',
                    accessorKey: 'depthHoleTo',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                });
                baseColumns.splice(8, 0, {
                    header: 'Торец',
                    accessorKey: 'drillingEnd',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.drillingEnd}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
                baseColumns.splice(9, 0, {
                    header: 'Большегабаритная деталь',
                    accessorKey: 'overSizedPart',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.overSizedPart}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
                baseColumns.splice(10, 0, {
                    header: 'Малогабаритная деталь',
                    accessorKey: 'smallSizedPart',
                    enableColumnActions: false,
                    enableEditing: false,
                    minSize: "150px",
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.smallSizedPart}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
            }
            if (admin && itemId === "listJobEdge" && !isModal) {
                baseColumns.splice(4, 0, {
                    header: 'Работа',
                    accessorKey: 'edgeJob',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => edgeJob[cell.getValue()],
                });
                baseColumns.splice(4, 0, {
                    header: 'Край',
                    accessorKey: 'edgeEnd',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => edgeEnd[cell.getValue()],
                });
                baseColumns.splice(4, 0, {
                    header: 'Тип',
                    accessorKey: 'edgeType',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell}) => gluing[cell.getValue()],
                });
                baseColumns.splice(9, 0, {
                    id: "curvilinearEdge",
                    header: `Криволинейный край`,
                    accessorKey: 'curvilinearEdge',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    checkBox: true,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.curvilinearEdge}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    >
                    </Checkbox>,
                });
            }
            if (isUnit || admin) {
                baseColumns.splice(3, 0, {
                    header: 'Ед.изм.',
                    accessorKey: 'unit',
                    accessorFn: (row) => row.unit?.name,
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                });
            }
            if (!isModal && !admin) {
                baseColumns.splice(0, 0, {
                    header: 'Добавлен',
                    accessorKey: 'added',
                    enableColumnActions: false,
                    enableEditing: false,
                    size: 8,
                    Cell: ({cell, row}) => <Checkbox
                        cell={cell}
                        checked={row.original.added}
                        id={data[row.index].id}
                        sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                        onChange={() => handleChange(row.original)}
                        disabled={row.original.blockDelete && row.original.added === true}
                    >
                    </Checkbox>,
                });
            }
            return baseColumns;

        }, [data, setData, isPrice, isUnit]);

        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleEdit},
            {type: "delete", text: "Удалить", handler: handleDelete}
        ]

        return (
            <Box
                sx={{
                    overflow: 'auto',
                    maxWidth: (itemId === "listJobPacking" || itemId === "listJobCutting") ? "1800px" : !settings ? "2700px" : `calc(100vw - 450px)`
                }}>
                <Box
                    sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
                >
                    {!isModal && <Typography sx={{fontSize: "24px", fontWeight: 700}}>Наименования работ</Typography>}
                    {((admin && !isModal) || addJob) && <ButtonCreate
                        text={"Добавить работу"}
                        width={190}
                        onClick={handleAdd}
                    />}
                </Box>
                <MaterialReactTable
                    muiTableContainerProps={{
                        sx: {
                            width: isModal || fullWidth ? "100%" : `calc(100vw - 450px)`,
                            maxHeight: Object.keys(rowSelection)?.length !== 0 ? `calc(100vh - 720px)` : isModal ? "530px" : `calc(100vh - 350px)`,
                        }
                    }}
                    muiTopToolbarProps={{
                        sx: {
                            "& .MuiBox-root": {
                                padding: "8px 0"
                            }
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            "&:first-of-type": (columnAdded || admin) && {
                                padding: 0,
                                width: admin ? "50px" : 'calc(100% - 1px)',
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            border: "none",
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',
                            },
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"

                                }
                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem"
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            onClick: () => setSelectedString && isModal && setSelectedString(row.original),
                            sx: () => ({
                                height: '36px',
                                cursor: setSelectedString && isModal && "pointer",
                                backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : "white",
                                opacity: !row.original?.added && !isModal && !admin && columnAdded && 0.6,
                                "&:hover& td:after": {
                                    display: "none",
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : '#F5F6FA',
                                },
                            })
                        })
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                        'mrt-row-select': {
                            size: "50px",
                            grow: false,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        expanded: false,
                        columnVisibility: {
                            id: false,
                            added: columnAdded && !admin,
                            "basePrice": !onlyName && !admin,
                            "alternativePrice": !onlyName && !admin,
                            "orderJobType": !onlyName,
                            "modelParameterUnit": !onlyName,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 100},
                        sorting: isModal ?
                            [] :
                            [{id: 'added', desc: true}, {id: 'orderJobType', desc: false}],
                    }}
                    onRowSelectionChange={onRowSelectionChange}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowSelection={enableRowSelection}
                    getRowId={(originalRow) => originalRow.id}
                    localization={MRT_Localization_RU}
                    enableEditing={enableEditing}
                    enableToolbarInternalActions={false}
                    enableRowActions={admin && !isModal && rowActions}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })}
                    renderTopToolbarCustomActions={({table}) => {
                        const chooseSignJob = () => {
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            const selectedIndexRows = selectedRows.map((row) => row.index);
                            setSelectedIds(selectedIds);
                            setRowIndexSelection(selectedIndexRows);
                            // setShowConfirmAddSignJob(true)
                        };

                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {isRedirectSettings && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectSettings}
                                        variant="contained"
                                    >
                                        Вернуться в настройки
                                    </Button>}
                                    {isChooseSignJob && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={chooseSignJob}
                                        variant="contained"
                                        disabled={table.getSelectedRowModel().rows.length === 0}
                                    >
                                        Установить
                                    </Button>}
                                    {/*{uploadSignJob && <UploadSignJobFileForm*/}
                                    {/*    label={'Загрузить операции'}*/}
                                    {/*    change={change}*/}
                                    {/*    setChange={setChange}*/}
                                    {/*/>}*/}
                                </Box>
                            </div>
                        );
                    }}
                />
                {!isModal && <ModalChooseJob
                    open={modalChooseJob}
                    onClose={() => setModalChooseJob(false)}
                    handleSubmit={handleSubmitAddSign}
                    currencies={currencies}
                    handleError={handleError}
                />}
                {modalAddInAdmin && <ModalAddJobInAdmin
                    title={currentEditRowData?.id ? "Изменить" : "Добавить"}
                    open={modalAddInAdmin}
                    onClose={() => setModalAddInAdmin(false)}
                    handleSubmit={handleSubmitModalAdmin}
                    currentEditRowData={currentEditRowData}
                    columns={columns}
                    allSubMaterials={allSubMaterials}
                />}
                {modalAddJob && <ModalAddJob
                    open={modalAddJob}
                    onClose={() => setModalAddJob(false)}
                    onSubmit={handleSubmitAddJob}
                    handleError={handleError}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    materialOrganizationOptionId={id}
                    type={type}
                    admin={admin}
                    itemId={itemId}
                />}
            </Box>
        );
    }
;


export default JobMetricOrganizationTable;