import React, {useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link} from "react-router-dom";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import CellCheckboxCurrentWithUnable from "../../../../сheckbox/CellCheckboxCurrentWithUnable";
import {estimateStatuses} from "../../../../../../data/EstimateState";
import ModalConfirm from "../../../../modal/ModalConfirm";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import CustomerEstimateService from "../../../../../../API/orders/estimate/customer/CustomerEstimateService";
import MenuItem from "@mui/material/MenuItem";
import {Tooltip} from "@mui/material";
import {Box} from "@mui/system";
import {country} from "../../../../../../data/Country";

const CustomerOffersTable = ({
                                 orderId,
                                 customerOrderNumber,
                                 data,
                                 setData,
                                 currents,
                                 setCurrents,
                                 tableOptions,
                                 tableOptionsHandle,
                                 handleError
                             }) => {
    const [unabledChangeCurrent, setUnabledChangeCurrent] = useState(false);
    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const handleSubmitSetPurchase = async () => {
        CustomerEstimateService.confirmPerformer(orderId, currentRow.id)
            .then(function () {
                setData(prev => prev.map(el => el.id === currentRow.id ? {
                    ...el,
                    estimateState: "PERFORMER_SELECTED"
                } : el))
                currents[currentRow.id] = true
                setSetPurchaseModalOpen(false)
            })
            .catch(function (error) {
                handleError(error);
            })
    }
    const renderCellValue = (cell, row, curAbbreviation) => {
        const value = cell.getValue();
        const currency = row.original[curAbbreviation];
        return value === null || value === undefined
            ? 'Ожидание'
            : value.price.toFixed(2) + " " + currency;
    };

    const columns = useMemo(
        () => [
            {
                id: 'nameOrganization',
                header: 'Организация',
                accessorKey: 'nameOrganization',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell, row}) => (
                    row.original.deleted ? <Tooltip
                        sx={{height: "40px"}}
                        arrow placement="right"
                        title={"Невозможно просмотреть смету, она удалена организацией"}>
                        {cell.getValue()}
                    </Tooltip> : <Link
                        to={'/customer/orders/' + orderId + '/' + customerOrderNumber + '/estimates/'
                            + data[row.index].id
                            + '/' + row.original.baseCurAbbreviation
                            + '/' + row.original.alternativeCurAbbreviation
                        }>{cell.getValue()}</Link>
                ),
            },
            {
                id: 'current',
                header: 'Текущий',
                accessorKey: 'current',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Cell: ({cell, row}) => <CellCheckboxCurrentWithUnable
                    id={row.original.id}
                    unable={currents}
                    setUnable={setCurrents}
                    orderId={orderId}
                    disabled={currents[row.original.id]}
                    unableMethod={EstimateService.unableCurrent}
                />,
            },
            {
                id: 'basePrice',
                header: `Материал`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'baseCurAbbreviation')
            },
            {
                id: 'baseJobPrice',
                header: `Работа`,
                accessorKey: 'baseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'baseCurAbbreviation')
            },
            {
                id: 'baseTotalPrice',
                header: `Итого:`,
                accessorKey: 'baseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'baseCurAbbreviation')
            },
            {
                id: 'alternativePrice',
                header: `Материал`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'alternativeCurAbbreviation')
            },
            {
                id: 'alternativeJobPrice',
                header: `Работа`,
                accessorKey: 'alternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'alternativeCurAbbreviation')
            },
            {
                id: 'alternativeTotalPrice',
                header: `Итого:`,
                accessorKey: 'alternativeTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'alternativeCurAbbreviation')
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimateState',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },
            {
                accessorFn: (row) => {
                    if (row.date === null) {
                    } else {
                        return new Date(row.date)
                    }
                },
                id: 'date',
                header: 'Дата расчета',
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfPerformance === null || row.dateOfPerformance === undefined) {
                        return null;
                    } else {
                        return new Date(row.dateOfPerformance);
                    }
                },
                id: 'dateOfPerformance',
                header: 'Дата изготовления',
                sortingFn: 'dateOfPerformance',
                size: 10,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    if (value) {
                        return value.toLocaleDateString();
                    }
                    return '';
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'Локация',
                id: 'locality',
                accessorKey: 'organization.address',
                maxSize: 35,
                Cell: ({cell, row}) => {
                    const address = cell.getValue()
                    const addressString = address && [country[address?.country], address?.locality, address?.street, address?.houseNumber, address?.building, address?.roomNumber].filter(el => el).join(", ")
                    return <Tooltip arrow placement="top" title={addressString}>
                        {address?.locality}
                    </Tooltip>
                },
            },

            {
                header: 'Регион доставки',
                id: 'regionDelivery',
                accessorKey: 'organization.regionDelivery',
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data],
    );
    const handleConfirm = (row) => {
        setCurrentRow(row)
        setSetPurchaseModalOpen(true)
    }
    const menuItem = [
        {type: "confirm", text: "Подтвердить исполнителя", handler: handleConfirm},
    ]
    return (
        <Box sx={{maxWidth: "2100px"}}>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '2100px',
                    }
                }}
                muiTableBodyCellProps={({cell, table}) => {
                    return ({
                        sx: () => ({
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',
                            },
                            "&": cell.column.id === "requests_cutting" && {
                                width: 'calc(100% - 1px)',
                                padding: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            },
                            "&:first-of-type": {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },

                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        })
                    })
                }}

                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem",
                        "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                            "minWidth": "100%"
                        }
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    sx: {
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        disabled={row.original.estimateState !== "CALCULATED_OFFERS"}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                localization={MRT_Localization_RU}
                getRowId={(originalRow) => originalRow.id}
            />
            <ModalConfirm
                title={"Подтвердить выбор исполнителя?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={() => setSetPurchaseModalOpen(false)}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
        </Box>
    );
};

export default CustomerOffersTable;