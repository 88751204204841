import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import PackagingItemTable from "../../../../components/UI/table/model-estimate/packaging/PackagingItemTable";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import PackagingUnitService from "../../../../API/model-estimate/packaging/PackagingUnitService";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";

const PackagingSetItem = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        PackagingUnitService.getAllByPackagingSetId(params.id).then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData("organization/packaging-sets", "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData["organization/packaging-sets"]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true, message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Комплект упаковки ${params.article}`}
                path={[{name: "Главная", href: "/"}, {name: "Настройки", href: "/settings"}]}/>

            <PackagingItemTable
                id={params.id}
                article={params.article}
                data={data}
                setData={setData}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
            <ModalAlert data={alertData} onClose={handleCloseAlert}/>
        </Box>
    );
};

export default PackagingSetItem;