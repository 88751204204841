import React, {useContext, useEffect, useState} from 'react';
import OrderProductionTable from "../../../../components/UI/table/orders/organization/production/OrderProductionTable";
import OrderService from "../../../../API/orders/orders/organization/OrderService";
import OrderUtil from "../../../../utils/orders/OrderUtil";
import {useLocation, useParams} from "react-router-dom";
import TypeJobService from "../../../../API/orders/orders/organization/TypeJobService";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";

const PlanItem = () => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);
    const [additionallyColumn, setAdditionallyColumn] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const params = useParams();

    let planId = params.id;

    useEffect(() => {
        getStartPageSize()
        TypeJobService.getAllByOrganizationId().then(function (response) {
            const data = response.data
            data.sort((a, b) => b.outputPriority - a.outputPriority);
            setAdditionallyColumn(data);
        })
            .catch(function (error) {
                console.log(error);
            });

        OrderService.getOrdersProductionByPlanId(planId).then(function (response) {
            const data = response.data;
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
            setData(data);
            setCustomerList(OrderUtil.getListField(data, 'customerName'));
            setNameList(OrderUtil.getListField(data, 'header'));
            setAttributeList(OrderUtil.getStatuses(data, 'attributes'));
            setPlanningList(OrderUtil.getListField(data, 'state'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <OrderProductionTable
                data={data}
                setData={setData}
                customerList={customerList}
                nameList={nameList}
                attributeList={attributeList}
                planningList={planningList}
                isColumnPlan={true}
                additionallyColumn={additionallyColumn}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
        </div>
    );
};

export default PlanItem;