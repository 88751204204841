import React, {useEffect, useState} from 'react';
import ModelDetailTable from "../../../../components/UI/table/model-estimate/details/ModelDetailTable";
import ModelDetailService from "../../../../API/model-estimate/details/ModelDetailService";
import {useLocation, useParams} from "react-router-dom";
import {Box, Button, Checkbox} from "@mui/material";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import UploadModelDetailFileForm
    from "../../../../components/UI/table/model-estimate/details/UploadModelDetailFileForm";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";

const ModelDetails = ({estimateId, handleError}) => {
        const pathname = useLocation().pathname
        const [tab, setTab] = useState(1);
        const [data, setData] = useState([]);
        const params = useParams();

        const basicModelId = params.basicModelId;
        const [unableCut, setUnableCut] = useState({});
        const [unableTurn, setUnableTurn] = useState({});
        const [selectedIds, setSelectedIds] = useState([]);
        const [modalStatusOpen, setModalStatusOpen] = useState(false);
        const [status, setStatus] = useState(true);
        const [typeStatus, setTypeStatus] = useState(null);
        const [change, setChange] = useState(false);
        const [isError, setIsError] = useState(false);
        const [tableOptions, setTableOptions] = useState({
            pageIndex: 0,
            pageSize: 20,
            totalElements: 0,
            searchData: ""
        })
        const tableOptionsHandle = (nextState) => {
            setTableOptions(nextState)
            if (nextState.pageSize !== tableOptions.pageSize) {
                EditLocalStoragePagesData(`${pathname}+2`, "pageSize", nextState.pageSize)
            }
        }

        const getStartPageSize = () => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const pageSize = pagesData[`${pathname}+2`]?.pageSize
            pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
        }

        useEffect(() => {
                getStartPageSize()
                ModelDetailService.getAllModelEstimateId(estimateId).then(function (response) {
                    setData(response.data);
                    setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
                    data.forEach((row) => {
                        unableCut[row.id] = row.cut;
                        unableTurn[row.id] = row.turn;
                    });
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, [change]
        );
        const generalTabComponents = [
            {
                tabIndex: 1, label: "Детали",
                component:
                    <ModelDetailTable
                        data={data}
                        setData={setData}
                        unableCut={unableCut}
                        unableTurn={unableTurn}
                        setUnableCut={setUnableCut}
                        setUnableTurn={setUnableTurn}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        modalStatusOpen={modalStatusOpen}
                        setModalStatusOpen={setModalStatusOpen}
                        status={status}
                        setStatus={setStatus}
                        typeStatus={typeStatus}
                        setTypeStatus={setTypeStatus}
                        isColumnOrder={true}
                        warehouse={'WarehouseFinishedDetail'}
                        expanded={true}
                        pageSize={100}
                        change={change}
                        setChange={setChange}
                        isError={isError}
                        estimateId={estimateId}
                        enableEditing={true}
                        isColumnCut={false}
                        isColumnTurn={false}
                        tableOptions={tableOptions}
                        tableOptionsHandle={tableOptionsHandle}
                    />
            }, {
                tabIndex: 2, label: "Импорт",
                component:
                    <UploadModelDetailFileForm
                        estimateId={estimateId}
                        labelForm={'Загрузить файлы деталей'}
                        change={change}
                        setChange={setChange}
                    />

            }]

        return (
            <Box>
                <ButtonGroupTabBar activeTab={tab}>
                    {generalTabComponents.map(({tabIndex, label}, index) => (
                        <Button key={tabIndex} tabIndex={tabIndex} onClick={() => setTab(tabIndex)}>
                            <CheckIcon/>
                            {label}
                        </Button>
                    ))}
                </ButtonGroupTabBar>
                <Box sx={{paddingTop: "32px", display: "flex", gap: "24px"}}>
                    {generalTabComponents.find(component => component.tabIndex === tab)?.component}
                </Box>
            </Box>
        );
    }
;

export default ModelDetails;