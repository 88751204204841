import React, {useEffect, useState} from 'react';
import RequestsPurchaseTableItem from "../../../../components/UI/table/warehouse/purchase/RequestsPurchaseTableItem";
import {useParams} from "react-router-dom";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import {estimateStatuses} from "../../../../data/EstimateState";
import {Paper} from "@mui/material";
import ButtonSelectAction from "../../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../../components/UI/modal/ModalSelectAction";
import MaterialEstimateUnitService from "../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";

const RequestsPurchaseItem = () => {
    const params = useParams();
    const [rowSelection, setRowSelection] = useState([]);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/requests-purchase`, "pageSize", nextState.pageSize)
        }
    }

    const modalActions = [
        {name: "Действие1", type: "action1"},
        {name: "Действие2", type: "action2"},
    ]
    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/requests-purchase`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleSelectAction = () => {

    }
    const estimateId = params.estimateId;
    const orderId = params.orderId;
    const orderNumber = params.orderNumber;
    const status = params.status

    useEffect(() => {
        getStartPageSize()
        MaterialEstimateUnitService.getAllByOrderIdForRequestsPurchase(orderId).then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Заявка на заказ"}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заявки на закупку", href: "/organization/requests-purchase"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Заявка на заказ {orderNumber}
                    </Typography>
                    <Typography>{`Статус: ${estimateStatuses[status]}`}</Typography>
                </Box>
                <RequestsPurchaseTableItem
                    estimateId={estimateId}
                    orderId={orderId}
                    orderNumber={orderNumber}
                    status={status}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    data={data}
                    setData={setData}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                />
                {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>}
                <ModalSelectAction
                    open={open}
                    reset={() => setOpen(false)}
                    submit={handleSelectAction}
                    modalActions={modalActions}
                />
            </Box>
        </Box>
    );
};

export default RequestsPurchaseItem;