import React from 'react';
import ArchiveTable from "../../../../components/UI/table/orders/organization/archive/ArchiveTable";
import Typography from "@mui/material/Typography";

const Archive = () => {
    return (
            <ArchiveTable/>
    );
};

export default Archive;