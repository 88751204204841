import React, {useState} from 'react';
import ModelFileService from "../../../../../API/model-estimate/file/ModelFileService";
import UploadFilesForm from "../../../../form/UploadFilesForm";

export const UploadModelEstimateFileForm = ({label, modelId, change, setChange, handleError}) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleUpload = async (event) => {
        event.preventDefault();
        ModelFileService.uploadModelEstimateFile(modelId, selectedFile[0])
            .then(function () {
                setChange(!change)
                setSelectedFile(null)
                event.target.reset();
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    return (
        <div>
            <UploadFilesForm
                uploadData={{
                    id: 1,
                    helpText: label,
                }}
                selectedFiles={selectedFile}
                setSelectedFiles={setSelectedFile}
                multiple={false}
                label={label}
                handleUpload={handleUpload}
            />

        </div>
    );
};