import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import SettingsSignJobTable from "../../../components/UI/table/settings/SettingsSignJobTable";
import {useParams} from "react-router-dom";
import {constantsErrorMessages} from "../../../Constants";
import CircularProgress from "@mui/material/CircularProgress";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import SignJobService from "../../../API/orders/job/SignJobService";
import UtilityService from "../../../API/common/UtilityService";
import SignJobNameService from "../../../API/orders/job/SignJobNameService";
import MaterialCloudUnits from "../../../API/material-cloud/common/MaterialCloudUnits";
import {modelParameterUnit} from "../../../data/ModelParameterUnit";
import {signJobType} from "../../../data/SignJobType";

const SettingsSignJob = () => {
    const profile = JSON.parse(localStorage.getItem("profile"))
    const {baseCurAbbreviation: baseCurrency, alternativeCurAbbreviation: alternativeCurrency} = profile
    const params = useParams();
    const {id} = params

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [alertData, setAlertData] = useState({});

    const [currencies, setCurrencies] = useState([])
    const [jobNames, setJobNames] = useState([])
    const [units, setUnits] = useState([])

    const allParameterUnit = Object.entries(modelParameterUnit)
    const allSignJobType = Object.entries(signJobType)

    const initValue = {
        curAbbreviation: currencies && currencies[0]?.curAbbreviation,
        signJobNameId: jobNames && jobNames[0]?.id,
        modelParameterUnit: allParameterUnit[0][0],
        signJobType: allSignJobType[0][0],
        unitId: units && units[0]?.id,
        price: "",
        laborIntensity: ""
    }

    const handleError = (error) => {
        setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }

    useEffect(() => {
        setIsLoading(true)
        SignJobService.get(id)
            .then(function (response) {
                const data = response?.data
                setData(data);
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
            .finally(() => setIsLoading(false))

        UtilityService.getCurrencies()
            .then(function (response) {
                setCurrencies(response.data)
            }).catch(function (error) {
            handleError(error)
        });

        SignJobNameService.getAllOrganization().then(function (response) {
            setJobNames(response.data);
        }).catch(function (error) {
            handleError(error)
        });

        MaterialCloudUnits.get().then(function (response) {
            setUnits(response.data);
        })
            .catch(function (error) {
                handleError(error)
            });

    }, []);

    return isLoading ?
        <Box sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <CircularProgress disableShrink/>
        </Box> :
        <Box>
            <ModalAlert data={alertData} onClose={handleCloseAlert}/>
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={"Затраты по работам"}
                    path={[{name: "Главная", href: "/"}, {name: "Настройки", href: "/settings"}]}/>
                <SettingsSignJobTable
                    data={data}
                    setData={setData}
                    isError={isError}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    id={id}
                    dataForSelects={{
                        currencies: currencies,
                        jobNames: jobNames,
                        allParameterUnit: allParameterUnit,
                        allSignJobType: allSignJobType,
                        units: units
                    }}
                    initValue={initValue}
                    handleError={handleError}/>
            </Box>
        </Box>

}
export default SettingsSignJob