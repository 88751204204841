export default class MaterialPriceUnitUtil {

    static materialPriceUnit = {
        supplierMaterial: null,
        amountInCurrencyArrival: null,
        officialExchangeRateToBaseCurrencyBaseMaterials: 0,
        exchangeRateToBaseCurrencyBaseMaterials: 0,
        officialExchangeRateToAlternativeCurrencyBaseMaterials: 0,
        exchangeRateToAlternativeCurrencyBaseMaterials: 0,
        officialExchangeRateToBasePrice: 0,
        exchangeRateToBasePrice: 0,
        length: 0,
        width: 0,
        quantity: 0,
        materialOrganizationOption: null
    }

    static addIdMaterialInMap(materialPriceUnits, idsMap) {
        materialPriceUnits.forEach(materialPriceUnit => {
            idsMap.set(materialPriceUnit.id, materialPriceUnit);
        });
    }

    static addOrUpdateMaterialPriceUnits(materialPriceUnits, values) {
        materialPriceUnits.set(values.materialOrganizationOption.id, values)
    }

    static checkAvailability(materialPriceUnits, id) {
        for (let materialPriceUnit of materialPriceUnits.values()) {
            if (materialPriceUnit.materialOrganizationOption.id === id && materialPriceUnit.supplierMaterial.typeSupplierMaterial === "WHOLE") {
                return materialPriceUnit;
            }
        }
        return null;
    }

    static setExchangeRateBasePriceCurrency(baseCurrency, alternativeCurrency, basePriceCurrency, values) {

        if (baseCurrency === basePriceCurrency) {
            values.exchangeRateToBasePrice = values.exchangeRateToBaseCurrencyBaseMaterials;
        }
        if (alternativeCurrency === basePriceCurrency) {
            values.exchangeRateToBasePrice = values.exchangeRateToAlternativeCurrencyBaseMaterials;
        }
    }

    static setExchangeRate(currency1, currency2) {

        const officialRateArrivalPrice = currency2?.curOfficialRate;
        const officialRateBasePrice = currency1?.curOfficialRate;

        const curScaleArrival = currency2?.curScale;
        const curScaleBase = currency1?.curScale;

        const exchangeRateArrivalPrice = officialRateArrivalPrice / curScaleArrival;
        const exchangeRateBasePrice = officialRateBasePrice / curScaleBase;

        return (exchangeRateArrivalPrice / exchangeRateBasePrice).toFixed(3);
    }

    static getMaterialPriceUnitIdById(id, data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                return data[i].materialPriceUnit.id;
            }
        }
        return undefined;
    }

    static getById(id, data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].materialPriceUnit.id === id) {
                return data[i];
            }
        }
        return undefined;
    }

    static checkSlab(data) {
        for (let i = 0; i < data.length; i++) {
            console.log(data[i])
            if (data[i].materialOrganizationOption.typeMaterial === "SLAB") {
                return true;
            }
        }
        return false;
    }
}