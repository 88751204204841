import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Box} from "@mui/system";

const CurrentCurrencyNationalBankTable = ({
                                              data,
                                              setData,
                                              tableOptions,
                                              tableOptionsHandle
                                          }) => {
    const columns = useMemo(
        () => [
            {
                id: 'curName',
                header: 'Наименование иностранной валюты',
                accessorKey: 'curName',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curAbbreviation',
                header: 'Аббревиатура',
                accessorKey: 'curAbbreviation',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curScale',
                header: 'Единиц',
                accessorKey: 'curScale',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curOfficialRate',
                header: 'Официальный курс',
                accessorKey: 'curOfficialRate',
                size: 8,
                maxSize: 8,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
            },
        ],
        [data, setData],
    );

    return (
        <Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',
                        },
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                rowCount={tableOptions.totalElements}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                enableToolbarInternalActions={false}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                localization={MRT_Localization_RU}
            />
        </Box>
    );
};

export default CurrentCurrencyNationalBankTable;