import {apiVersionAuthorizationServer, gatewayUrl, nameAuthorizationServer} from "../../Constants";
import axios from "axios";

const restUrl = gatewayUrl + nameAuthorizationServer + apiVersionAuthorizationServer + '/organizations/stage-setting'

export default class OrganizationsStageSettingsService {

    static async get() {
        return axios.get(restUrl);
    }

    static async nextStage() {
        return axios.post(restUrl + "/next-stage" , [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
