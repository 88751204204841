import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextFieldValidation from "../customer-detales/TextFieldValidation";
import ButtonExit from "../../button/ButtonExit";
import ButtonAdd from "../../button/ButtonAdd";
import MenuItem from "@mui/material/MenuItem";

const WarehouseModal = ({open, onClose, handleSubmit, initValue, title, addressData}) => {
    const [formState, setFormState] = useState({})
    const [validationState, setValidationState] = useState({name: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleClose = () => {
        setValidationState({name: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    name={"name"}
                    value={formState?.name}
                    label="Наименование"
                    setCurrValues={setFormState}
                    validationError={validationState?.name}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    select
                    name={"address"}
                    value={formState?.address}
                    label={"Адрес"}
                    setCurrValues={setFormState}
                    required={false}
                >
                    {addressData.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {`${option?.locality} ${option?.street} ${option?.houseNumber}`}
                        </MenuItem>))}
                </TextFieldValidation>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default WarehouseModal