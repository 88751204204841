import {apiVersionAuthorizationServer, gatewayUrl, nameAuthorizationServer} from "../../Constants";
import axios from "axios";

const restUrl = gatewayUrl + nameAuthorizationServer + apiVersionAuthorizationServer + '/organizations'

export default class OrganizationService {

    static async getAll() {
        return axios.get(restUrl + "/for-settings");
    }

    static async edit(address, residentialAddress, data, id) {
        return axios.put(restUrl + "/" + id + "?residentialAddressId=" + residentialAddress + "&addressId=" + address, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setCurrency(baseCurrencyId, alternativeCurrencyId) {
        return axios.patch(restUrl + "/organization-settings/set-currency?baseCurrencyId=" + baseCurrencyId + "&alternativeCurrencyId=" + alternativeCurrencyId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}