import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {ruRU} from '@mui/x-date-pickers/locales';

const OrderDatePicker = ({label, date, setDate}) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='ru'
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DatePicker
                sx={{width: "100%"}}
                slotProps={{textField: {size: 'small', margin: "normal", width: "100%",}}}
                label={label}
                onChange={(newValue) => {
                    setDate(new Date(newValue))
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                    />
                )}
                value={date ? dayjs(date) : null}
            />
        </LocalizationProvider>
    );
};

export default OrderDatePicker;
