import React, {useEffect, useState} from 'react';
import TypeJobOrganizationTable from "../../../components/UI/table/settings/TypeJobOrganizationTable";
import TypeJobOrganizationService from "../../../API/orders/job/TypeJobOrganizationService";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";

const TypeJobOrganization = ({workers, itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [change, setChange] = useState(false)
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        TypeJobOrganizationService.getAllOrganization().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [change]);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    return (
        <div>
            <TypeJobOrganizationTable
                data={data}
                setData={setData}
                workers={workers}
                setChange={setChange}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        </div>
    );
};

export default TypeJobOrganization;