import React, {useState} from "react";
import UploadFilesForm from "../../../../components/form/UploadFilesForm";

const UploadFilesRequestsForm = ({
                                     orderId,
                                     change,
                                     setChange,
                                     calculationRequestsCutting,
                                     detail,
                                     uploadData,
                                     handleError
                                 }) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {
        event.preventDefault();
        uploadData.upload && uploadData.upload(selectedFiles, calculationRequestsCutting, orderId)
            .then(function () {
                setSelectedFiles([]);
                setChange(!change)
                event.target.reset();
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    return (
        <div>
            <UploadFilesForm
                uploadData={uploadData}
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={detail ? "Загрузить делали" : "Загрузить заявки на раскрой"}
            />
        </div>
    );
};

export default UploadFilesRequestsForm;