export const rulesModifyingOptimizationInputFields = {
    "name": "Найменование",
    "position": "Позиция",
    "method": "Метод",
    "lesThan": "Меньше чем",
    "newPosition": "Новая позиция",
    "plusPosition": "Плюс позиция",
    "plusOldLength": "Плюс мм к длине",
    "plusOldWidth": "Плюс мм к ширине",
    "newSizeLength": "Новый размер по длине",
    "newSizeWidth": "Новый размер по ширине",
    "l1": "Новый l1",
    "l1Name": "Новый l1Name",
    "l2": "Новый l2",
    "l2Name": "Новый l2Name",
    "w1": "Новый w1",
    "w1Name": "Новый w1Name",
    "w2": "Новый w2",
    "w2Name": "Новый w2Name",
    "priority": "Приоритет",
}