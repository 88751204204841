import React, {useEffect, useRef, useState} from 'react';
import WarehouseMaterialItemTable
    from "../../../../../components/UI/table/warehouse/material/materialwarehouse/WarehouseMaterialItemTable";
import {useLocation, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import CloudOptionsMaterialModule from "../../../../../components/UI/modul/mainoptions/CloudOptionsMaterialModule";
import SupplierService from "../../../../../API/orders/warehouse/supplier/SupplierService";
import GroupMaterialService from "../../../../../API/orders/orders/organization/GroupMaterialService";
import SupplierMaterialItemModule from "../../../../../components/UI/modul/mainoptions/SupplierMaterialItemModule";
import MaterialOrganizationOptionService
    from "../../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import CurrentOptionsMaterialModule from "../../../../../components/UI/modul/mainoptions/CurrentOptionsMaterialModule";
import UserSupplierMaterialService from "../../../../../API/orders/warehouse/material/UserSupplierMaterialService";
import UserManufacturerMaterialCloudService
    from "../../../../../API/material-cloud/user/UserManufacturerMaterialCloudService";
import UserGroupMaterialMaterialCloudService
    from "../../../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import UserMaterialMaterialCloudService from "../../../../../API/material-cloud/user/UserMaterialMaterialCloudService";
import AdminMaterialMaterialCloudService
    from "../../../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";
import JobMetricOrganizationTable
    from "../../../../../components/UI/table/prodaction/jobmetricorganization/JobMetricOrganizationTable";
import {BreadcrumbsComponent} from "../../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {ButtonGroupTabBar} from "../../../../../components/UI/button/ButtonGroupTabBar";
import {Button, Paper} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import ModalAlert from "../../../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../../../Constants";
import ButtonSelectAction from "../../../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../../../components/UI/modal/ModalSelectAction";
import UtilityService from "../../../../../API/common/UtilityService";
import MaterialWarehouseCloudMarketTable
    from "../../../../../components/UI/table/warehouse/material/materialwarehouse/MaterialWarehouseCloudMarketTable";
import CircularProgress from "@mui/material/CircularProgress";

const MaterialItem = () => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [alertData, setAlertData] = useState({});
    const [tab, setTab] = useState(3);

    const [data, setData] = useState(null);
    const [dataMaterial, setDataMaterial] = useState(null);

    const [supplierList, setSupplierList] = useState(null);
    const [groupMaterialList, setGroupMaterialList] = useState(null);

    const [supplierMaterialCloudList, setSupplierMaterialCloudList] = useState(null);
    const [groupMaterialMaterialCloudList, setGroupMaterialMaterialCloudList] = useState(null);
    const [manufacturerMaterialCloudList, setManufacturerMaterialCloudList] = useState(null);

    const [supplierId, setSupplierId] = useState(null);
    const [manufacturerId, setManufacturerId] = useState(null);
    const [groupMaterialId, setGroupMaterialId] = useState(null);

    const {id, baseUnit, typeMaterial, materialId} = params;

    const profile = JSON.parse(localStorage.getItem("profile"))
    const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
    const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);
    const [currencyMap, setCurrencyMap] = useState(new Map);
    const [currencies, setCurrencies] = useState()
    const [openSelection, setOpenSelection] = useState(false)
    const [dataMaterialWholeData, setDataMaterialWholeData] = useState(null);
    const [dataMaterialMinStripeData, setDataMaterialMinStripeData] = useState(null);
    const [dataMaterialFixedStripeData, setDataMaterialFixedStripeData] = useState(null);

    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const firstRender = useRef(true);
    const handleError = (error) => {
        setIsLoading(false)
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        return pagesData["/organization/materials"]?.tab

    }
    const getTabsData = (tab) => {
        const tabsData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = tabsData[`/organization/materials+${tab}`]?.pageSize

        pageSize ? setTableOptions((prev) => ({
            ...prev,
            pageSize: pageSize,
            totalElements: dataJobMetricOrganization?.length
        })) : setTableOptions((prev) => ({
            ...prev,
            totalElements: dataJobMetricOrganization?.length
        }))
    }

    const tableOptionsHandle = (nextState, currTab) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`/organization/materials+${tab}`, "pageSize", nextState.pageSize)
        }
    }

    const handleChangeTab = (tab) => {
        setTab(tab)
        getTabsData(tab)
        EditLocalStoragePagesData(`/organization/materials`, "tab", tab)
    }

    const getDataFromBackendByTabs = (savedTab) => {
        if ((savedTab === 0 || savedTab === 1) && (!manufacturerMaterialCloudList || !supplierList)) {
            Promise.all([UserManufacturerMaterialCloudService.getAll(), SupplierService.get()]).then((values) => {
                setManufacturerMaterialCloudList(values[0]?.data);
                setSupplierList(values[1]?.data);
                setIsLoading(false)
            }).catch(function (error) {
                handleError(error);
            });
        }
        if (savedTab === 1 && !groupMaterialList) {
            GroupMaterialService.getAllByOrganization().then(function (response) {
                setGroupMaterialList(response.data);
                setIsLoading(false)
            })
                .catch(function (error) {
                    handleError(error);
                });
        } else if (savedTab === 0 && (!supplierMaterialCloudList || !dataMaterial)) {
            Promise.all([UserMaterialMaterialCloudService.getForMaterialOrganizationOption(materialId), UserGroupMaterialMaterialCloudService.getAll()]).then((values) => {
                setDataMaterial(values[0]?.data);
                setSupplierMaterialCloudList(values[1]?.data);
                setIsLoading(false)
            }).catch(function (error) {
                handleError(error);
            });
        } else if (savedTab === 2 && (!dataMaterialWholeData || !dataMaterialMinStripeData || !dataMaterialFixedStripeData)) {
            Promise.all([UserSupplierMaterialService.getAllCurrentWholeByMaterialOrganizationOptionId(id), UserSupplierMaterialService.getAllCurrentByMaterialOrganizationOptionIdMinStripe(id), UserSupplierMaterialService.getAllCurrentByMaterialOrganizationOptionIdFixedStripe(id)]).then((values) => {
                setDataMaterialWholeData(values[0]?.data);
                setDataMaterialMinStripeData(values[1]?.data);
                setDataMaterialFixedStripeData(values[2]?.data);
                setIsLoading(false)
            }).catch(function (error) {
                handleError(error);
            });
        } else {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        const savedTab = getStartPageSize() || tab
        if (firstRender.current) {
            UtilityService.getCurrencies()
                .then(function (response) {
                    const data = response.data;
                    data.forEach((c) => {
                        currencyMap.set(c.id, c);
                    });
                    setCurrencies(data)
                }).catch(function (error) {
                handleError(error)
            })
            MaterialOrganizationOptionService.getWithGroupMaterialAndDefaultSupplierById(id).then(function (response) {
                let data = response.data;
                setData(data);
                setDataJobMetricOrganization(data.jobMetricOrganizations);
                firstRender.current = false
                getTabsData(savedTab)
                savedTab === tab ? getDataFromBackendByTabs(savedTab) : setTab(savedTab)
                setTableOptions((prev) => ({...prev, totalElements: data.jobMetricOrganizations.length}))
            }).catch(function (error) {
                handleError(error);
            });
        } else {
            getDataFromBackendByTabs(savedTab)
        }
    }, [tab]);

    const [change, setChange] = useState(false);
    const [dataJobMetricOrganization, setDataJobMetricOrganization] = useState(null);
    const [rowSelection, setRowSelection] = useState([]);
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);

    const handleSubmitUpdateMainOptions = () => {
        const currentSupplierId = supplierId === null ? data.defaultSupplier.id : supplierId;
        const currentManufacturerId = manufacturerId == null ? data.manufacturer.id : manufacturerId;
        const currentGroupMaterialId = groupMaterialId == null ? data.groupMaterial.id : groupMaterialId;

        AdminMaterialMaterialCloudService.updateMainOptions(data, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
        ).then(function (response) {
            let data = response.data;
            handleUpdate(data)
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    const handleSubmitUpdateCurrentOptions = () => {
        const currentSupplierId = supplierId === null ? data.defaultSupplier?.id : supplierId;
        const currentManufacturerId = manufacturerId == null ? data.manufacturer?.id : manufacturerId;
        const currentGroupMaterialId = groupMaterialId == null ? data.groupMaterial?.id : groupMaterialId;

        MaterialOrganizationOptionService.updateCurrentOptions(data, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
        ).then(function (response) {
            let data = response.data;
            handleUpdate(data)
        })
            .catch(function (error) {
                handleError(error);
            });
    }
    const handleDeleteJob = () => {
        MaterialOrganizationOptionService.deleteJobMetric(id, {"ids": rowSelection}).then(function (response) {
            const newJobMetricData = dataJobMetricOrganization.filter(el => !rowSelection.includes(el.id))
            setDataJobMetricOrganization(newJobMetricData)
            tableOptionsHandle((prev) => ({
                ...prev,
                totalElements: dataJobMetricOrganization.length - rowSelection.length
            }))
            setOpenSelection(false)
            setRowSelection([])
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    const handleUpdate = (values) => {
        setData(values);
    };

    const renderContent = () => {
        switch (tab) {
            case 0:
                return <CloudOptionsMaterialModule
                    data={dataMaterial}
                    setData={setDataMaterial}
                    supplierList={supplierMaterialCloudList}
                    groupMaterialList={groupMaterialMaterialCloudList}
                    manufacturerList={manufacturerMaterialCloudList}
                    handleSubmit={handleSubmitUpdateMainOptions}
                    setSupplierId={setSupplierId}
                    setManufacturerId={setManufacturerId}
                    setGroupMaterialId={setGroupMaterialId}
                    baseUnit={baseUnit}
                    isMaterialOrganizationOption={true}
                    changeOptionsDisabled={true}
                />
            case 1:
                return <CurrentOptionsMaterialModule
                    data={data}
                    setData={setData}
                    supplierList={supplierList}
                    groupMaterialList={groupMaterialList}
                    manufacturerList={manufacturerMaterialCloudList}
                    handleSubmit={handleSubmitUpdateCurrentOptions}
                    setSupplierId={setSupplierId}
                    setManufacturerId={setManufacturerId}
                    setGroupMaterialId={setGroupMaterialId}
                    baseUnit={baseUnit}
                    isMaterialOrganizationOption={true}
                    changeOptionsDisabled={true}
                    isDataMOO={true}
                />
            case 2:
                return <SupplierMaterialItemModule
                    id={id}
                    materialId={materialId}
                    baseUnit={baseUnit}
                    typeMaterial={typeMaterial}
                    dataMaterialWholeData={dataMaterialWholeData}
                    dataMaterialMinStripeData={dataMaterialMinStripeData}
                    dataMaterialFixedStripeData={dataMaterialFixedStripeData}
                    setDataMaterialWholeData={setDataMaterialWholeData}
                    setDataMaterialMinStripeData={setDataMaterialMinStripeData}
                    setDataMaterialFixedStripeData={setDataMaterialFixedStripeData}
                    allPrice={true}
                    create={UserSupplierMaterialService.createByMaterialOrganizationOption}
                    update={UserSupplierMaterialService.update}
                    urlArchiveSM={'/organization/supplier-material-archive-item'}
                    urlArchiveMS={'organization/material-min-archive-item'}
                    urlArchiveFS={'organization/material-fixed-archive-item'}
                    addArchive={UserSupplierMaterialService.sendToArchive}
                    isFixPrice={true}
                    currencyMap={currencyMap}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
            case 3:
                return <WarehouseMaterialItemTable
                    id={id}
                    materialId={materialId}
                    baseUnit={baseUnit}
                    typeMaterial={typeMaterial}
                    material={data}
                    currencyMap={currencyMap}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    currencies={currencies}
                    handleError={handleError}
                />
            case 4:
                return <JobMetricOrganizationTable
                    materialId={materialId}
                    id={id}
                    data={dataJobMetricOrganization || []}
                    setData={setDataJobMetricOrganization}
                    change={change}
                    setChange={setChange}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    rowIndexSelection={rowIndexSelection}
                    setRowIndexSelection={setRowIndexSelection}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    isChooseSignJob={false}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    fullWidth={true}
                    columnAdded={false}
                    handleError={handleError}
                    enableRowSelection={true}
                    addJob={true}
                    allCurrencies={currencies}
                />
            case 5:
                return <MaterialWarehouseCloudMarketTable
                    materialId={materialId}
                    currencies={currencies}
                    typeMaterial={typeMaterial}
                    handleError={handleError}
                />
        }
    }
    const handleSelectAction = () => {
        handleDeleteJob()
    }
    const modalActions = [
        {name: "Удалить работу", type: "delete"},
    ]
    return (
        isLoading ?
            <Box sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> : <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={data?.name}
                    path={[{name: "Главная", href: "/"}, {name: "Материалы", href: "/organization/materials"}]}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                        <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                            {data?.name}
                        </Typography>
                    </Box>
                    <ButtonGroupTabBar activeTab={tab}>
                        <Button tabIndex={1} onClick={(e) => handleChangeTab(1)}>
                            <CheckIcon/>Основные
                        </Button>
                        <Button tabIndex={2} onClick={(e) => handleChangeTab(2)}>
                            <CheckIcon/>Поставщики
                        </Button>
                        <Button tabIndex={3} onClick={(e) => handleChangeTab(3)}>
                            <CheckIcon/>Склад
                        </Button>
                        <Button tabIndex={4} onClick={(e) => handleChangeTab(4)}>
                            <CheckIcon/>Перечень работ
                        </Button>
                        <Button tabIndex={0} onClick={(e) => handleChangeTab(0)}>
                            <CheckIcon/>Облако
                        </Button>
                        <Button tabIndex={5} onClick={(e) => handleChangeTab(5)}>
                            <CheckIcon/>Рынок остатков
                        </Button>
                    </ButtonGroupTabBar>
                    {renderContent()}
                </Box>
                {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpenSelection(true)}/>
                </Paper>}
                <ModalSelectAction
                    open={openSelection}
                    reset={() => setOpenSelection(false)}
                    submit={handleSelectAction}
                    modalActions={modalActions}
                />
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
    );
};

export default MaterialItem;