import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";
import axios from "axios";

const modulesRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/order-modules'

export default class OrderModelsService {

    static async getAll(orderId) {
        return axios.get(modulesRestUrl + "?orderId=" + orderId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(estimateId, data) {
        return await axios.post(modulesRestUrl + "?estimateId=" + estimateId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id, estimateId) {
        await axios.delete(modulesRestUrl + '/' + id + "?estimateId=" + estimateId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}