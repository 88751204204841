import React, {useCallback, useMemo, useState} from 'react';
import {Button, Stack, Tooltip, Typography} from '@mui/material';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import ModalConfirm from "../../../../modal/ModalConfirm";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import {estimateStatuses} from "../../../../../../data/EstimateState";
import MenuItem from "@mui/material/MenuItem";

const OrderEconomicTable = ({
                                data,
                                setData,
                                tableOptions,
                                tableOptionsHandle,
                                handleError
                            }) => {
    const [sendArchiveModalOpen, setSendArchiveModalOpen] = useState(false);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [dateOfReceipt, setDateOfReceipt] = useState(null);
    const [dateOfPlannedProduction, setDateOfPlannedProduction] = useState(null);
    const [plannedDateOfShipment, setPlannedDateOfShipment] = useState(null);
    const [manufactured, setManufactured] = useState(null);
    const [shipped, setShipped] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [setPlanModalOpen, setSetPlanModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [defaultAttributeId, setDefaultAttributeId] = useState(null);

    const navigate = useNavigate();

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const handleUpdate = (row, values) => {
        data[row[currentEditRowId]] = values;
        setData([...data]);
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setDateOfReceipt(row.dateOfReceipt);
            setDateOfPlannedProduction(row.dateOfPlannedProduction)
            setPlannedDateOfShipment(row.plannedDateOfShipment)
            setManufactured(row.manufactured)
            setShipped(row.shipped)
            setCurrentEditRowId(row.id);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const sendToArchive = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setSendArchiveModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleSubmitSendToArchive = () => {
        OrderService.sendToArchive(currentEditRowData.id)
            .then(function () {
                data.splice(currentEditRowData.index, 1);
                setData([...data]);
                setCurrentEditRowData(null);
                setSendArchiveModalOpen(false);
            })
            .catch(function (error) {
                handleError(error);
            })
    };

    const handleCloseModalSendToArchive = () => {
        setSendArchiveModalOpen(false);
    };

    const calcSum = (type, rows, margin = false) => {
        let marginNum = 0
        const newData = rows.reduce((acc, row) => {
            if (row.original.estimate[type] !== null && row.original.estimate[type] !== undefined) {
                if (margin) {
                    marginNum += 1
                    return acc + row.original.estimate[type];
                } else {
                    return acc + row.original.estimate[type].price;
                }
            } else {
                return acc;
            }
        }, 0)

        return margin ? (newData / marginNum)?.toLocaleString?.('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }) : newData?.toLocaleString?.('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        })

    }

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'numberCustomer',
                header: 'Номер заказа',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/orders-cloud/' + data[row.index].id + '/' + data[row.index].numberCustomer}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'calculationTotalCostBasePrice',
                header: `СБ план:`,
                accessorKey: 'estimate.calculationTotalCostBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: ({column, footer, table}) => {
                    return <Stack>
                        <Box color="warning.main">
                            {calcSum("calculationTotalCostBasePrice", table.getRowModel().rows)}
                        </Box>
                    </Stack>
                }
            },
            {
                id: 'totalCostBasePrice',
                header: `СБ факт:`,
                accessorKey: 'estimate.totalCostBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: ({column, footer, table}) => {
                    return <Stack>
                        <Box color="warning.main">
                            {calcSum("totalCostBasePrice", table.getRowModel().rows)}
                        </Box>
                    </Stack>
                }
            },
            {
                id: 'baseTotalPrice',
                header: `Продажа`,
                accessorKey: 'estimate.baseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: ({column, footer, table}) => {
                    return <Stack>
                        <Box color="warning.main">
                            {calcSum("baseTotalPrice", table.getRowModel().rows)}
                        </Box>
                    </Stack>
                }
            },
            {
                id: 'profitCalculationBaseTotalPrice',
                header: `Прибыль план:`,
                accessorKey: 'estimate.profitCalculationBaseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: ({column, footer, table}) => {
                    return <Stack>
                        <Box color="warning.main">
                            {calcSum("profitCalculationBaseTotalPrice", table.getRowModel().rows)}
                        </Box>
                    </Stack>
                }
            },
            {
                id: 'profitBaseTotalPrice',
                header: `Прибыль факт:`,
                accessorKey: 'estimate.profitBaseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: ({column, footer, table}) => {
                    return <Stack>
                        <Box color="warning.main">
                            {calcSum("profitBaseTotalPrice", table.getRowModel().rows)}
                        </Box>
                    </Stack>
                }
            },
            {
                id: 'calculationGrossMargin',
                header: `Валовая маржа план`,
                accessorKey: 'estimate.calculationGrossMargin',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: ({column, footer, table}) => {
                    return <Stack>
                        <Box color="warning.main">
                            {calcSum("calculationGrossMargin", table.getRowModel().rows, true)}
                        </Box>
                    </Stack>
                }
            },
            {
                id: 'grossMargin',
                header: `Валовая маржа факт`,
                accessorKey: 'estimate.grossMargin',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: ({column, footer, table}) => {
                    return <Stack>
                        <Box color="warning.main">
                            {calcSum("grossMargin", table.getRowModel().rows, true)}
                        </Box>
                    </Stack>
                }
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimate.estimateState',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfCreate === null) {
                    } else {
                        return new Date(row.dateOfCreate)
                    }
                },
                id: 'dateOfCreate',
                header: 'Дата',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'year',
                header: 'год                                                                     ',
                accessorKey: 'year',
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        return baseColumns;

    }, [data, setData]);
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleUpdateModel},
        {type: "archive", text: "Поместить в архив", handler: sendToArchive}]
    return (
        <>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px", gap: "16px"}}>
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>Экономика</Typography>
            </Box>
            <MaterialReactTable
                enableColumnFilterModes
                enableGrouping
                enableStickyHeader
                enablePinning
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        // "&:first-of-type":  {
                        //     padding: 0,
                        //     width: "50px",
                        //     height: "36px",
                        //     display: "flex",
                        //     justifyContent: "center",
                        //     alignItems: "center",
                        //     color: (theme) => theme.palette.text.light,
                        //     "& .MuiButtonBase-root": {
                        //         borderRadius: "4px",
                        //         width: "24px",
                        //         height: "24px",
                        //         opacity: 1,
                        //         margin: 0,
                        //         padding: 0,
                        //         "&:hover": {
                        //             backgroundColor: (theme) => theme.palette.primary.stroke,
                        //         }
                        //     }
                        // },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: '#F5F6FA',
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                rowCount={tableOptions.totalElements}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                initialState={{
                    columnVisibility: {
                        id: false,
                        model: false,
                        year: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                    columnPinning: {left: ['numberCustomer']}
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
                enableRowActions={false}
                enableToolbarInternalActions={false}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
            />
            <ModalConfirm
                title={"Подтвердить поместить в архив"}
                modalOpen={sendArchiveModalOpen}
                handleClose={handleCloseModalSendToArchive}
                handleSubmit={handleSubmitSendToArchive}
                action={"Подтвердить"}
            />
        </>
    );
};

export default OrderEconomicTable;