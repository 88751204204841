import {useLocation} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import Box from "@mui/material/Box";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import ButtonCreate from "../../../components/UI/button/ButtonCreate";
import {MaterialReactTable} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import CurrencyService from "../../../API/orders/warehouse/currency/AdminCurrencyService";
import TextFieldValidation from "../../../components/UI/table/customer-detales/TextFieldValidation";
import ButtonExit from "../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../components/UI/button/ButtonAdd";
import AdminCurrencyNationalBankService from "../../../API/orders/warehouse/currency/AdminCurrencyNationalBankService";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {ruRU} from "@mui/x-date-pickers/locales";

const initValue = {
    curId: "",
    curOfficialRate: "",
    curScale: "",
    date: dayjs()
}
const CurrenciesNationalBank = ({itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);
    const [allCurrency, setAllCurrency] = useState([])
    const [currData, setCurrData] = useState(dayjs())
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        CurrencyService.getAll().then(function (response) {
            setAllCurrency(response.data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    useEffect(() => {
        const date = currData.format(`YYYY-MM-DD`)
        AdminCurrencyNationalBankService.getByDate(date).then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [currData])

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleClose = () => {
        setModal(false)
        setCurrentEditRowData(initValue)
    }
    const handleDelete = (row) => {
        AdminCurrencyNationalBankService.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
            })
            .catch(function (error) {
                handleError(error)
            })
    };
    const handleUpdate = (value) => {
        const currCurrency = allCurrency.find(el => el.id === value.curId)
        AdminCurrencyNationalBankService.update({
            ...value,
            curName: currCurrency?.curName,
            curAbbreviation: currCurrency?.curAbbreviation
        }, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                handleClose()
            })
            .catch(function (error) {
                handleError(error)
            })

    }

    const handleSubmit = (value) => {
        const currCurrency = allCurrency.find(el => el.id === value.curId)
        AdminCurrencyNationalBankService.add({
            ...value,
            curName: currCurrency?.curName,
            curAbbreviation: currCurrency?.curAbbreviation
        })
            .then(function (response) {
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
                handleClose()
            })
            .catch(function (error) {
                handleError(error)
            })

    }

    const handleAdd = () => {
        setModal(true)
        setCurrentEditRowData(prev => ({...prev, curId: allCurrency[0]?.id}));
    }
    const handleEdit = (row) => {
        setCurrentEditRowData(row);
        setModal(true)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'curName',
                header: 'Наименование',
                accessorKey: 'curName',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curAbbreviation',
                header: 'Аббревиатура',
                accessorKey: 'curAbbreviation',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curScale',
                header: 'Единиц',
                accessorKey: 'curScale',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curOfficialRate',
                header: 'Официальный курс',
                accessorKey: 'curOfficialRate',
                size: 8,
                maxSize: 8,
            },
        ]

        return baseColumns;

    }, [data]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}
    ]

    return (<div>
        <Typography sx={{fontSize: "24px", fontWeight: 700, lineHeight: 1}}>Валюта</Typography>
        <Box
            sx={{display: 'flex', justifyContent: "space-between", padding: "30px  0"}}
        >
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale='ru'
                localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker
                    sx={{
                        "& .MuiInputBase-root": {
                            height: "38px"
                        }
                    }}
                    onChange={newValue => {
                        setCurrData(newValue)
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            helperText={'Дата'}
                            sx={{minWidth: '120px', height: "38px"}}
                            variant="standard"
                        />
                    )}
                    value={currData}
                    inputFormat="DD/MM/YYYY"
                />
            </LocalizationProvider>
            <ButtonCreate
                text={"Добавить валюту"}
                width={200}
                onClick={handleAdd}
            />
        </Box>


        <MaterialReactTable
            muiTableContainerProps={{
                sx: {
                    width: '800px',
                }
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "50px",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            rowHeight={13}
            columnSpacing={1}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            rowCount={tableOptions.totalElements}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                const disabled = el.type === "edit" && currData.isBefore(dayjs(), "day")
                return <MenuItem
                    key={el.type}
                    disabled={disabled}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
        />
        <CurrenciesNationalBankModal
            title={currentEditRowData.id ? "Изменить" : "Добавить"}
            open={modal} onClose={handleClose}
            handleSubmit={currentEditRowData.id ? handleUpdate : handleSubmit}
            initValue={currentEditRowData}
            handleError={handleError}
            allCurrency={allCurrency}/>
    </div>);
};
const CurrenciesNationalBankModal = ({open, onClose, handleSubmit, initValue, title, allCurrency}) => {
    const [formState, setFormState] = useState(initValue)

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: "column"}}>
                <TextFieldValidation
                    select
                    name="curId"
                    label="Валюта"
                    value={formState.curId}
                    setCurrValues={setFormState}
                >
                    {allCurrency.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.curName}
                        </MenuItem>
                    ))}
                </TextFieldValidation>
                <TextFieldValidation
                    name={"curOfficialRate"}
                    value={formState?.curOfficialRate}
                    label="Официальный курс"
                    type={"number"}
                    setCurrValues={setFormState}
                />
                <TextFieldValidation
                    name={"curScale"}
                    value={formState?.curScale}
                    type={"number"}
                    label="Единиц"
                    setCurrValues={setFormState}
                />
                {!initValue.id && open && <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='ru'
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker
                        onChange={newValue => {
                            setFormState(prev => ({...prev, date: newValue}))
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                helperText={'Дата'}
                                sx={{minWidth: '120px'}}
                                variant="standard"
                            />
                        )}
                        value={formState?.date}
                        inputFormat="DD/MM/YYYY"
                    />
                </LocalizationProvider>}

            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"} onClick={() => handleSubmit(formState)}/>
            </DialogActions>
        </Dialog>
    );
}

export default CurrenciesNationalBank;