import React, {useEffect, useState} from 'react';
import AddressOrganizationService from "../../../API/organization/AddressOrganizationService";
import AddressOrganizationTable from "../../../components/UI/table/settings/AddressOrganizationTable";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import AddressCustomerService from "../../../API/customer/AddressCustomerService";

const AddressOrganization = ({isCustomer, itemId,handleError}) => {
    const api = isCustomer ? AddressCustomerService : AddressOrganizationService
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        api.getAll().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    return (
        <div>
            <AddressOrganizationTable
                api={api}
                handleError={handleError}
                data={data}
                setData={setData}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
        </div>
    );
};

export default AddressOrganization;