import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const jobRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/job-metric-organizations'

export default class JobMetricOrganizationService {

    static async get() {
        return axios.get(jobRestUrl);
    }

    static async getByMaterialOrganizationOption(id) {
        return axios.get(jobRestUrl + "/by-material-organization-option" + "?materialOrganizationOptionId=" + id);
    }

    static async getAllJobMetricOrganizationForSettings() {
        return axios.get(jobRestUrl + '/for-settings');
    }

    static async delete(id) {
        await axios.delete(jobRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(id, currency, price) {
        return await axios.patch(jobRestUrl + '/' + id + "?currency=" + currency + '&price=' + price,
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async create(jobMetricName, currency, orderJobType, price) {
        return await axios.post(jobRestUrl + "?jobMetricName=" + jobMetricName + '&currency=' + currency +
            '&orderJobType=' + orderJobType + '&price=' + price, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllForAddMaterialOrganizationOptionId(materialOrganizationOptionId) {
        return axios.get(jobRestUrl + '/for-add-material-organization-option'
            + '?materialOrganizationOptionId=' + materialOrganizationOptionId);
    }
}