import React, {useState} from 'react';
import {Checkbox} from "@mui/material";
import OrganizationCustomerService from "../../../../../../API/orders/orders/organization/OrganizationCustomerService";

const CellCheckboxCTUnable = ({enabled, id, handleUnable}) => {
    return (<div>
        <Checkbox onChange={() => handleUnable(id, !enabled)}
                  checked={enabled}
                  size="small"
                  sx={{padding: "6px", '& .MuiSvgIcon-root': {fontSize: 24}}}
        />
    </div>);
};

export default CellCheckboxCTUnable;