import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldObject from "../../../textfield/TextFieldObject";
import OptimizationService from "../../../../../API/orders/optimization/organization/OptimizationService";
import RequestsCuttingService from "../../../../../API/orders/optimization/organization/RequestsCuttingService";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const ModalSetOptimization = ({
                                  open,
                                  handleClose,
                                  selectedIds,
                                  data,
                                  setData,
                                  change,
                                  handleError,
                                  isCalculation,
                                  setRowSelection,
                                  deleteRowBeforeAction,
                                  setTableOptions
                              }) => {

        const [optimizationList, setOptimizationList] = useState([]);
        const [optimizationId, setOptimizationId] = useState(null);
        const [defaultOptimizationId, setDefaultOptimizationId] = useState([]);

        useEffect(() => {
            {
                open && OptimizationService.getAllNotArchive().then(function (response) {
                    let data = response.data;
                    setOptimizationList(data);
                    setDefaultOptimizationId(data[0]?.id)
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }


        }, [change, open]);

        const handleSubmit = () => {
            let currentOptimizationId = optimizationId === null ? defaultOptimizationId : optimizationId;
            RequestsCuttingService.setOptimizationByIds(selectedIds,
                currentOptimizationId, isCalculation
            ).then(function () {
                const newData = deleteRowBeforeAction ? data.filter(item => {
                    return !selectedIds.includes(item.id)
                }) : data.map(item => {
                    if (selectedIds.includes(item.id)) {
                        const selectedOptimization = optimizationList.find(optimization => optimization.id.toString() === currentOptimizationId.toString());
                        return {
                            ...item,
                            optimization: selectedOptimization,
                            requestsCuttingState: 'FORMED_OPTIMIZATION'
                        };
                    } else {
                        return item;
                    }
                })
                deleteRowBeforeAction && setTableOptions((prev) => ({...prev, totalElements: data.length - 1}))
                setRowSelection([])
                setData(newData);
                handleClose();
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle textAlign="center">{"Добавить в заявки в оптимизацию"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                        >
                            <TextFieldObject
                                label="Оптимизация"
                                field={'optimization'}
                                setId={setOptimizationId}
                                entityList={optimizationList}
                                entityField={'number'}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <ButtonExit text={"Выйти"} onClick={handleClose}/>
                    <ButtonAdd text={"Добавить"} onClick={handleSubmit}/>
                </DialogActions>
            </Dialog>
        );
    }
;

export default ModalSetOptimization;