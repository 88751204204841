import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import ArrivalMaterialService from "../../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateFilter from "../../../../filter/DateRangeFilter";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ModalConfirm from "../../../../modal/ModalConfirm";
import ModalUpdateOrAddMaterialArrival from "./ModalUpdateOrAddMaterialArrival";
import CurrencyService from "../../../../../../API/common/CurrencyService";
import SupplierService from "../../../../../../API/orders/warehouse/supplier/SupplierService";
import WarehouseService from "../../../../../../API/orders/warehouse/WarehouseService";
import {statuses} from "../../../../../../data/ArrivalState";
import MenuItem from "@mui/material/MenuItem";
import ButtonCreate from "../../../../button/ButtonCreate";
import selectionTableUtil from "../../../../../../utils/common/SelectionTableUtil";

const ArrivalMaterialTable = ({
                                  isCreateOrUpdate,
                                  rowSelection,
                                  setRowSelection,
                                  buttonActionVisible = false,
                                  setButtonActionVisible,
                                  tableOptions,
                                  tableOptionsHandle,
                                  data,
                                  setData,
                                  enableRowSelection,
                                  setTableOptions,
                                  archive
                              }) => {

        const [isFullScreen, setIsFullScreen] = useState(false);


        const profile = JSON.parse(localStorage.getItem("profile"))
        const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
        const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);

        const [showConfirmationModalDelete, setShowConfirmationModalDelete] = useState(false);
        const [rowToDelete, setRowToDelete] = useState(null);

        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRow, setCurrentEditRow] = useState(null);
        const [date, setDate] = useState(null);
        const [materialDeliveryDate, setMaterialDeliveryDate] = useState(null);
        const [rowIndexSelection, setRowIndexSelection] = useState([])
        const [selectedIds, setSelectedIds] = useState([]);

        const [currencyList, setCurrencyList] = useState([]);
        const [currencyId, setCurrencyId] = useState(null);

        const [supplierList, setSupplierList] = useState([]);
        const [supplierId, setSupplierId] = useState(null);

        const [warehouseList, setWarehouseList] = useState([]);
        const [warehouseId, setWarehouseId] = useState(null);

        const navigate = useNavigate();

        const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

        useEffect(() => {
            CurrencyService.getAll().then(function (response) {
                let data = response.data;
                data.sort((a, b) =>
                    a.defaultBase === b.defaultBase ? 0 : a.defaultBase ? -1 : 1
                );
                setCurrencyList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            SupplierService.get().then(function (response) {
                let data = response.data;
                setSupplierList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            WarehouseService.getAll().then(function (response) {
                let data = response.data;
                setWarehouseList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setCurrentEditRow(row);
                setDate(row.date);
                setMaterialDeliveryDate(row.materialDeliveryDate)
                setCreateOrUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleCreateRow = () => {
            const ArrivalMaterial = {
                arrivalState: 'EXECUTION_OF_APPLICATION',
                comment: '',
                currencyArrival: null,
                date: null,
                id: null,
                materialDeliveryDate: null,
                supplier: null,
                warehouse: null,
            };
            setCurrentEditRowData(ArrivalMaterial);
            setDate(new Date());
            setMaterialDeliveryDate(new Date())
            setCreateOrUpdateModalOpen(true);
        };

        const handleDeleteRow = (row) => {
            setShowConfirmationModalDelete(true);
            setRowToDelete(row);
        };

        const handleDeleteRowWithConfirm = () => {
            ArrivalMaterialService.delete(rowToDelete.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== rowToDelete.id))
                    setTableOptions((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    console.log(error);
                })
            cancelDelete()
        };

        const cancelDelete = () => {
            setShowConfirmationModalDelete(false);
            setRowToDelete(null);
        };

        const redirectByArrivalMaterialsArchive = () => {
            navigate('/organization/materials-arrival-archive');
        };

        const redirectReservationPieceMaterial = () => {
            navigate('/organization/reservation-piece-material');
        };

        const redirectReservationSlabMaterial = () => {
            navigate('/organization/reservation-slab-material');
        };

        const redirectReservationEdgeMaterial = () => {
            navigate('/organization/reservation-linear-material');
        };

        const handleCloseCreateOrUpdateModalOpen = () => {
            setCreateOrUpdateModalOpen(false)
            setCurrentEditRowData(null);
            setCurrentEditRow(null);
            setCurrencyId(null);
            setSupplierId(null);
            setWarehouseId(null);
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const handleUpdate = (values) => {
            setData(prev => prev.map(el => el.id === values.id ? values : el))
        };

        function CellComponent({isCreateOrUpdate, cell, row, baseCurrency, alternativeCurrency}) {
            return !isCreateOrUpdate ? (
                <li key={cell.getValue()}>
                    <Link
                        to={'/materials-price-unit-arrival-archive/' + row.original.id + '/'
                            + row.original.currencyArrival.curAbbreviation + '/' + baseCurrency + '/' + alternativeCurrency}
                    >
                        {cell.getValue()}
                    </Link>
                </li>
            ) : (
                <span>{cell.getValue()}</span>
            );
        }

        const columns = useMemo(
            () => [
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                    flex: 0.5,
                    enableHiding: false,
                    enableColumnFilter: false,
                },
                {
                    header: 'Номер',
                    accessorKey: 'number',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                    flex: 0.5,
                    Cell: ({cell, row, rowIndex}) => {
                        const rowCurAbbreviation = row.original.currencyArrival.curAbbreviation;
                        const supplierId = row.original.supplier.id;
                        const isBaseCurrency = rowCurAbbreviation !== baseCurrency;
                        const isAlternativeCurrency = rowCurAbbreviation !== alternativeCurrency;
                        return (
                            <span>
                                <Link
                                    to={'/organization/materials-price-unit-arrival/' + row.original.id + '/' + row.original.number + '/' + row.original.currencyArrival.curAbbreviation + '/'
                                        + isBaseCurrency + '/' + isAlternativeCurrency
                                        + '/' + baseCurrency + '/' + alternativeCurrency + '/' + supplierId + '/' + row.original.warehouse.id}>
                            {row.original.number}
                        </Link>
                            </span>
                        );

                    },
                },
                {
                    id: 'supplier.name',
                    header: 'Поставщик',
                    accessorKey: 'supplier.name',
                    size: 600,
                    grow: true,
                },
                {
                    accessorFn: (row) => {
                        if (row.date === null) {
                        } else {
                            return new Date(row.date)
                        }
                    },
                    id: 'date',
                    header: 'Дата оформления',
                    sortingFn: 'date',
                    size: 10,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        return DateTimeUtil.checkDate(row.getValue(id), filterValue)
                    },
                    Filter: ({column}) => <DateFilter column={column}/>,
                },
                {
                    accessorFn: (row) => {
                        if (row.materialDeliveryDate === null) {
                        } else {
                            return new Date(row.materialDeliveryDate)
                        }
                    },
                    id: 'materialDeliveryDate',
                    header: 'Дата поставки',
                    sortingFn: 'materialDeliveryDate',
                    size: 10,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        return DateTimeUtil.checkDate(row.getValue(id), filterValue)
                    },
                    Filter: ({column}) => <DateFilter column={column}/>,
                },
                {
                    id: 'currencyArrival.curAbbreviation',
                    header: 'Валюта прихода',
                    accessorKey: 'currencyArrival.curAbbreviation',
                    minSize: 10,
                    axSize: 10,
                },
                {
                    id: 'warehouse',
                    header: 'Склад',
                    accessorKey: 'warehouse.name',
                    minSize: 20,
                    maxSize: 20,
                },
                {
                    id: 'arrivalState',
                    header: 'Статус',
                    accessorKey: 'arrivalState',
                    minSize: 70,
                    Cell: ({cell}) => statuses[cell.getValue()],
                },
                {
                    id: 'comment',
                    header: 'Коментарий',
                    accessorKey: 'comment',
                    minSize: 30,
                    maxSize: 150,
                },
            ],
            [data, setData, baseCurrency, alternativeCurrency],
        );

        const isNew = currentEditRowData === null ? true : currentEditRowData.id === null

        const handleSubmit = () => {
            currentEditRowData.date = date === null ? null : DateTimeUtil.dateConvert(date);
            currentEditRowData.materialDeliveryDate = materialDeliveryDate === null ? null : DateTimeUtil.dateConvert(materialDeliveryDate);

            const currentCurrencyId = isNew ? (currencyId === null ? currencyList[0].id : currencyId) : currentEditRowData.currencyArrival.id
            const currentSupplierIdId = isNew ? (supplierId === null ? supplierList[0].id : supplierId) : currentEditRowData.supplier.id
            const currentWarehouseId = isNew ? (warehouseId == null ? warehouseList[0].id : warehouseId) : (warehouseId === null ? currentEditRowData.warehouse.id : warehouseId)

            ArrivalMaterialService.createOrUpdate(currentEditRowData, currentCurrencyId, currentSupplierIdId, currentWarehouseId,
            ).then(function (response) {
                let data = response.data;
                isNew ? handleCreateNewRow(data) : handleUpdate(data)
                handleCloseCreateOrUpdateModalOpen();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow}
        ]

        return (
            <Box sx={{maxWidth: "2000px"}}>
                <Box sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "8px"}}>
                    <Box sx={{display: 'flex', justifyContent: "space-between", gap: "9px"}}> <ButtonCreate
                        text={"Резервы фурнитуры"}
                        width={170}
                        icon={false}
                        onClick={() => redirectReservationPieceMaterial()}
                    />
                        <ButtonCreate
                            text={"Резервы плит. мат."}
                            width={160}
                            icon={false}
                            onClick={() => redirectReservationSlabMaterial()}
                        />
                        <ButtonCreate
                            text={"Резервы погон. мат."}
                            width={170}
                            icon={false}
                            onClick={() => redirectReservationEdgeMaterial()}
                        />
                        {isCreateOrUpdate && <ButtonCreate
                            text={"Архив"}
                            width={60}
                            icon={false}
                            onClick={() => redirectByArrivalMaterialsArchive()}
                        />}
                    </Box>

                    {isCreateOrUpdate && <ButtonCreate
                        text={"Создать приход материалов"}
                        width={280}
                        onClick={() => handleCreateRow()}
                    />}

                </Box>
                <Box sx={{overflow: 'auto'}}>
                    <MaterialReactTable
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: "8px 0"
                                }
                            }
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                p: '0px 0px 0px 10px',
                                m: '0px 0px 0px 0px',
                                border: "none",
                                "& td:after": {
                                    "&:hover": {
                                        backgroundColor: "red"
                                    }
                                },
                                "&:not(:last-of-type)": {
                                    borderRight: '1px solid #EAEBEA',

                                },
                                "&:first-of-type": {
                                    padding: 0,
                                    width: "50px",
                                    height: "36px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",

                                },

                                listStyleType: "none",
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }

                                }
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: "",
                                size: 3,
                                minSize: 3,
                                grow: false,
                            },
                            'mrt-row-select': {
                                size: "50px",
                                grow: false,
                            },
                        }}
                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            backgroundColor: "red",
                            sx: {
                                backgroundColor: "red",
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        columns={columns}
                        data={data}
                        initialState={{
                            pagination: {pageIndex: 0, pageSize: 50},
                            columnVisibility: {
                                id: false,
                            },
                        }}
                        onRowSelectionChange={onRowSelectionChange}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                        rowCount={tableOptions.totalElements}
                        enableMultiRowSelection
                        enableRowSelection={enableRowSelection}
                        getRowId={(originalRow) => originalRow.id}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: buttonActionVisible ? `calc(100vh - 600px)` : `calc(100vh - 520px)`,
                                width: "2000px"
                            }
                        }}
                        enableStickyHeader={true}
                        enableStickyFooter={true}
                        enableRowActions={!archive}
                        enableToolbarInternalActions={false}
                        renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                            return (el.type === "edit" || (el.type === "delete" && !row.original.blocked)) && <MenuItem
                                key={el.type}
                                onClick={() => {
                                    el.handler(row.original)
                                    closeMenu();
                                }}
                                sx={{
                                    margin: "0 8px",
                                    borderRadius: "4px",
                                    "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                                }}
                            >
                                {el.text}
                            </MenuItem>
                        })}
                        localization={MRT_Localization_RU}
                    />
                </Box>
                <ModalConfirm
                    title={"Вы действительно хотите удалить?"}
                    modalOpen={showConfirmationModalDelete}
                    handleClose={cancelDelete}
                    handleSubmit={handleDeleteRowWithConfirm}
                    action={'Удалить'}
                />

                <ModalUpdateOrAddMaterialArrival
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    open={createOrUpdateModalOpen}
                    handleClose={() => handleCloseCreateOrUpdateModalOpen()}
                    date={date}
                    setDate={setDate}
                    materialDeliveryDate={materialDeliveryDate}
                    setMaterialDeliveryDate={setMaterialDeliveryDate}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    setCurrencyId={setCurrencyId}
                    supplierList={supplierList}
                    supplierId={supplierId}
                    setSupplierId={setSupplierId}
                    warehouseList={warehouseList}
                    warehouseId={warehouseId}
                    setWarehouseId={setWarehouseId}
                    handleCreateNewRow={handleCreateNewRow}
                    handleUpdate={handleUpdate}
                    handleSubmit={handleSubmit}
                    isNew={isNew}
                    isSupplier={true}
                />
            </Box>
        );
    }
;
export default ArrivalMaterialTable;