import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Link, useLocation, useNavigate} from "react-router-dom";
import UnitService from "../../../../../API/orders/warehouse/UnitService";
import EstimateService from "../../../../../API/orders/estimate/organization/EstimateService";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import {estimateStatuses} from "../../../../../data/EstimateState";
import ModalOpenSetStatus from "./ModalOpenSetStatus";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import {BreadcrumbsComponent} from "../../../breadcrumb/BreadcrumbComponent";
import ButtonBack from "../../../button/ButtonBack";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";

const RequestsPurchaseTable = () => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [currents, setCurrents] = useState({});
    const [change, setChange] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [modalStatusOpen, setModalStatusOpen] = useState(false);
    const [estimateState, setEstimateState] = useState(null);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    useEffect(() => {
        getStartPageSize()
        EstimateService.getAllNotCalculationAndNotArchive().then(function (response) {
            const data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            data.forEach((row) => {
                currents[row.id] = row.current;

            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        UnitService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };


    const handleDeleteRow = useCallback(
        (row) => {
            UnitService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const redirectOrders = () => {
        navigate('/organization/orders/');
    };

    const updateStatusByIds = async () => {
        EstimateService.setStatus(selectedIds, estimateState)
            .then(function () {
            })
            .catch(function (error) {
                console.log(error);
            })
        handleCloseModalStatus();
    }

    const handleCloseModalStatus = () => {
        setModalStatusOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                id: 'number',
                header: 'Номер',
                accessorKey: 'number',
                minSize: 20,
                maxSize: 20,
            },
            {
                id: 'order',
                header: 'Заказ',
                accessorKey: 'orderNumber',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/requests-purchase/' + row.original.id + '/' + row.original.orderId + '/' + row.original.orderNumber + '/' + row.original.estimateState}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimateState',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },
            {
                accessorFn: (row) => {
                    if (row.date === null) {
                    } else {
                        return new Date(row.date)
                    }
                },
                id: 'date',
                header: 'Дата',
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Заявки на закупку"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>Заявки на закупку</Typography>
                <ButtonBack
                    text={"Вернуться в заказы"}
                    width={200}
                    onClick={redirectOrders}
                />
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                onEditingRowSave={handleSaveRowEdits}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
                getRowId={(originalRow) => originalRow.id}
                onRowSelectionChange={setRowSelection}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowSelection={false}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                enableToolbarInternalActions={false}
            />
            <ModalOpenSetStatus
                title={'Изменить статус'}
                label={'статус'}
                modalOpen={modalStatusOpen}
                handleClose={handleCloseModalStatus}
                setValue={setEstimateState}
                handleSubmit={updateStatusByIds}
            />
        </Box>
    );
};

export default RequestsPurchaseTable;