import axios from "axios";
import {apiVersionUtility, utility} from "../../Constants";
import FileService from "../orders/orders/organization/FileService";

export default class UtilityService {

    static async getById(id) {
        return axios.get(`${utility}${apiVersionUtility}/banners/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getCurrencies() {
        return axios.get(`${utility}${apiVersionUtility}/organizations/currencies`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllProjectFileNames(orderId) {
        return axios.get(`${utility}${apiVersionUtility}/organizations/fileNames/orders/${orderId}/project`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async uploadOrderFile(year, orderId, fileNameType, files) {
        return await axios.post(`${utility}${apiVersionUtility}/files/upload/order-files?orderId=${orderId}&year=${year}&fileNameType=${fileNameType}`,
            FileService.getFormDataArray(files), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async delete(fileId) {
        return await axios.delete(`${utility}${apiVersionUtility}/files/delete?fileId=${fileId}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
}
