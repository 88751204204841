import React from 'react';

const PriceWithCurrency = ({cell, row, currencyMap}) => {
    return (
        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
            <div style={{display: 'flex', alignItems: 'flex-start', height: '13px', lineHeight: '13px'}}>
                {cell.getValue() === null || cell.getValue() === undefined ? ''
                    : `${cell.getValue()?.toFixed(2)} ${currencyMap.get(row.original.materialPriceUnit.materialPriceInCurrencyArrival.currencyId)?.curAbbreviation}`}
            </div>
        </div>
    );
}

export default PriceWithCurrency;