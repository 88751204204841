import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ButtonExit from "../../button/ButtonExit";
import ButtonAdd from "../../button/ButtonAdd";
import TextFieldValidation from "../customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import {addressInputFields} from "../../../../data/AddressInputFields";

const AddressModal = ({open, onClose, handleSubmit, initValue, title, allCountry}) => {
    const [formState, setFormState] = useState(initValue)

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "700px",
                height: "750px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{
                display: "flex", flexFlow: "column wrap", gap: "20px",
                "& div": {
                    height: "50px"
                }
            }}>
                {Object.entries(formState).map(([key, value]) => key !== "id" &&
                    <TextFieldValidation
                        key={key}
                        select={key === "country"}
                        name={key}
                        value={value}
                        label={addressInputFields[key]}
                        setCurrValues={setFormState}
                        required={false}
                    >
                        {key === "country" && allCountry.map((option) => (
                            <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                            </MenuItem>))}
                    </TextFieldValidation>
                )}
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={initValue.id? "Изменить":"Добавить"} onClick={() => handleSubmit(formState)}/>
            </DialogActions>
        </Dialog>
    );
}
export default AddressModal