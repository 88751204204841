import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MainMenu from "./MainMenu";
import * as React from "react";
import {Container, styled} from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import {useState} from "react";

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "&.MuiToolbar-root": {
        padding: 0
    }
}));

export const ToolbarSecond = ({accessibility, stage}) => {
    const [menuOpen, setMenuOpen] = useState({})
    return <AppBar
        position="static"
        sx={{
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            width: '100%',
            height: "52px",
            backgroundColor: (theme) => theme.palette.primary.main,
        }}>
        <Container sx={{
            "&.MuiContainer-root": {
                maxWidth: "1668px",
            }
        }}>
            <StyledToolbar>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gridGap: "40px",
                }}>
                    {(stage === 0 || stage > 7) && accessibility?.menu?.map(menu => {
                        const subMenu = accessibility.subMenu.filter((subMenu) => subMenu.menuType === menu.type)
                        return <MainMenu
                            key={menu.type}
                            name={menu.type}
                            handleMenu={(event) => setMenuOpen({
                                type: menu.type,
                                anchor: event.currentTarget
                            })}
                            anchorEl={menuOpen.type === menu.type && menuOpen.anchor}
                            handleClose={() => setMenuOpen({})}
                            menuItems={subMenu}
                            labelName={menu.name}
                        />
                    })}
                </Box>

            </StyledToolbar>
        </Container>
    </AppBar>
}