import React, {useEffect, useState} from "react";
import {toolType} from "../../../../../data/ToolType";
import {millingJob} from "../../../../../data/MillingJobType";
import {grooveType} from "../../../../../data/GrooveType";
import {contourType} from "../../../../../data/ContourType";
import {gluing} from "../../../../../data/Gluing";
import {edgeJob} from "../../../../../data/EdgeJob";
import {edgeEnd} from "../../../../../data/EdgeEnd";
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack} from "@mui/material";
import TextFieldValidation from "../../customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const ModalAddJobInAdmin = ({
                                open,
                                onClose,
                                handleSubmit,
                                currentEditRowData,
                                title,
                                columns,
                                allSubMaterials
                            }) => {
    const [formState, setFormState] = useState(currentEditRowData)
    const allToolType = Object.entries(toolType)
    const allMillingJob = Object.entries(millingJob)
    const allGrooveType = Object.entries(grooveType)
    const allContourType = Object.entries(contourType)
    const allGluing = Object.entries(gluing)
    const allEdgeJob = Object.entries(edgeJob)
    const allEdgeEnd = Object.entries(edgeEnd)

    useEffect(() => {
        const data = currentEditRowData?.id ? currentEditRowData : {
            ...currentEditRowData,
            subTypeMaterial: allSubMaterials[0][0],
            tool: allToolType[0][0],
            millingJob: allMillingJob[0][0],
            grooveType: allGrooveType[0][0],
            contourType: allContourType[0][0],
            edgeType: allGluing[0][0],
            edgeJob: allEdgeJob[0][0],
            edgeEnd: allEdgeEnd[0][0]
        }
        setFormState(data)
    }, [currentEditRowData])

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <Stack
                    sx={{
                        width: '100%',
                        minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    }}
                >
                    {columns.map((column) => {
                        const dataSelect = column.accessorKey === "tool" ? allToolType :
                            column.accessorKey === "millingJob" ? allMillingJob :
                                column.accessorKey === "grooveType" ? allGrooveType :
                                    column.accessorKey === "contourType" ? allContourType :
                                        column.accessorKey === "edgeType" ? allGluing :
                                            column.accessorKey === "edgeJob" ? allEdgeJob :
                                                column.accessorKey === "edgeEnd" ? allEdgeEnd :
                                                    allSubMaterials

                        return column.accessorKey !== "id" && column.accessorKey !== "millingEnd" && column.accessorKey !== "unit" && column.accessorKey !== "detail" && column.accessorKey !== "orderJobType" && column.accessorKey !== "modelParameterUnit" && column.accessorKey !== "compactPlate" && (
                            column.checkBox !== true ? <TextFieldValidation
                                key={column.accessorKey}
                                select={["subTypeMaterial", "tool", "millingJob", "grooveType", "contourType", "edgeType", "edgeJob", "edgeEnd"].includes(column.accessorKey)}
                                name={column.accessorKey}
                                type={["restrictionsQuantityFrom", "restrictionsQuantityTo", "thicknessMaterialFrom", "thicknessMaterialTo", "diameterHoleFrom", "diameterHoleTo", "depthHoleTo", "depthHoleFrom"].includes(column.accessorKey) ? "number" : "text"}
                                value={formState[column.accessorKey]}
                                label={column.header}
                                setCurrValues={setFormState}
                            >
                                {dataSelect.map((option) => (
                                    <MenuItem key={option[0]} value={option[0]}>
                                        {option[1]}
                                    </MenuItem>))}
                            </TextFieldValidation> : <FormControlLabel control={<Checkbox
                                checked={formState[column.accessorKey] === true}
                                onChange={() => setFormState(value => ({
                                    ...value,
                                    [column.accessorKey]: !value [column.accessorKey]
                                }))}
                            />} label={column.header}/>
                        )
                    })}
                </Stack>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"} onClick={() => handleSubmit(formState)}/>
            </DialogActions>
        </Dialog>
    )
        ;
}
export default ModalAddJobInAdmin