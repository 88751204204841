import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import OrderTable from "../../orders/organization/order/OrderTable";
import OrderService from "../../../../../API/orders/orders/organization/OrderService";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const ModalCreateIssuingEdge = ({
                                    open,
                                    onClose,
                                    titleModal,
                                    createIssuingEdge,
                                    change,
                                    handleError
                                }) => {

        const [data, setData] = useState([]);
        const [rowSelection, setRowSelection] = useState([]);
        const [customerList, setCustomerList] = useState([]);
        const [nameList, setNameList] = useState([]);
        const [planningList, setPlanningList] = useState([]);
        const [tableOptions, setTableOptions] = useState({
            pageIndex: 0,
            pageSize: 10,
            totalElements: 0,
            searchData: ""
        })
        const tableOptionsHandle = (nextState) => {
            setTableOptions(nextState)
        }

        useEffect(() => {
                OrderService.getAllIssuingEdges().then(function (response) {
                    const data = response.data;
                    setData(data);
                    setTableOptions((prev) => ({...prev, totalElements: data.length}))
                    setCustomerList(OrderUtil.getListField(data, 'customerName'));
                    setNameList(OrderUtil.getListField(data, 'header'));
                    setPlanningList(OrderUtil.getListField(data, 'state'));
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }, [change]
        )
        ;

        return (
            <div>
                <Dialog open={open} fullWidth={true} PaperProps={{
                    style: {
                        minWidth: "1400px"
                    }
                }}>
                    <DialogTitle textAlign="center">{titleModal}</DialogTitle>
                    <DialogContent>
                        <Box sx={{mt: '0.2rem'}}>
                            <OrderTable
                                data={data}
                                setData={setData}
                                customerList={customerList}
                                nameList={nameList}
                                planningList={planningList}
                                enableEditing={false}
                                tableOptions={tableOptions}
                                tableOptionsHandle={tableOptionsHandle}
                                handleError={handleError}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                isIssuingEdge={true}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{p: '1.25rem'}}>
                        <ButtonExit text={"Выйти"} onClick={onClose}/>
                        <ButtonAdd text={"Создать"} disabled={rowSelection.length === 0}
                                   onClick={() => createIssuingEdge(rowSelection)}/>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
;

export default ModalCreateIssuingEdge;