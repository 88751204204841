import React, {useEffect, useMemo, useState} from "react";
import MaterialWarehouseService from "../../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import {country} from "../../../../../../data/Country";
import Box from "@mui/material/Box";
import {MaterialReactTable} from "material-react-table";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {EditLocalStoragePagesData} from "../../../../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";

const MaterialWarehouseCloudMarketTable = ({materialId, currencies, typeMaterial,handleError}) => {
    const pathname = useLocation().pathname
    const allCountry = Object.entries(country)
    const [data, setData] = useState([])
    const [currCountry, setCurrCountry] = useState(allCountry[0][0])
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname + "+" + 5, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = (totalElements) => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname + "+" + 5]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize, totalElements: totalElements}))
    }

    useEffect(() => {
        MaterialWarehouseService.getByMaterialId(materialId, currCountry).then(function (response) {
            setData(response.data);
            getStartPageSize(response.data?.length)
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [currCountry]);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'name',
                header: `Наименование`,
                accessorFn: (row) => row.organization?.name,
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'country',
                header: `Страна`,
                accessorFn: (row) => row.organization?.country,
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Cell: ({cell}) => country[cell.getValue()],
            },
            {
                id: 'materialWarehouseCloudMarketPrice',
                header: `Цена продажи`,
                accessorFn: (row) => row.materialWarehouseCloudMarketPrice && `${row.materialWarehouseCloudMarketPrice?.price} ${currencies?.find(el => el.id === row.materialWarehouseCloudMarketPrice?.currencyId)?.curAbbreviation}`,
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                header: 'Адрес',
                accessorFn: (row) => row.organization.address ? `${row.organization.address?.locality} ${row.organization.address?.street} ${row.organization.address?.houseNumber}` : "",
                minSize: 500,
            },]
        if (typeMaterial === 'SLAB') {
            baseColumns.splice(3, 0, {
                id: 'length',
                header: 'Ширина',
                accessorKey: 'materialPriceUnit.length',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (typeMaterial === 'SLAB') {
            baseColumns.splice(3, 0, {
                id: 'width',
                header: 'Высота',
                accessorKey: 'materialPriceUnit.width',
                minSize: 15,
                maxSize: 15,
            });
        }
        if (typeMaterial === 'SLAB') {
            baseColumns.splice(3, 0, {
                id: 'quantity',
                header: 'Количество',
                accessorKey: 'materialPriceUnit.quantity',
                minSize: 15,
                maxSize: 15,
            });
        }
        return baseColumns;
    }, [data])

    return <div>
        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px", gap: "10px"}}
        >
            <Box sx={{width: "180px"}}>
                <TextFieldValidation
                    select
                    name="currCountry"
                    label="Страна"
                    value={currCountry}
                    setCurrValues={(getData) => setCurrCountry(getData().currCountry)}
                >
                    {allCountry?.map((option) => (
                        <MenuItem key={option[0]} value={option[0]}>
                            {option[1]}
                        </MenuItem>))}
                </TextFieldValidation>
            </Box>
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            initialState={{
                columnVisibility: {
                    id: false,
                },
                isFullScreen: false,
                density: "compact",
                pagination: {pageIndex: 0, pageSize: 20},
            }}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            rowCount={tableOptions.totalElements}
            localization={MRT_Localization_RU}
            enableRowActions={false}
            enableToolbarInternalActions={false}
        />
    </div>

}
export default MaterialWarehouseCloudMarketTable