export const rulesModifyingOptimizationMethod = {
    "LESS_THAN_LENGTH": "Увеличение по длине",
    "LESS_THAN_WIDTH": "Увеличение по ширине",
    "FIND_BY_POSITION": "Поиск по позиции",
    "DELETE": "Удаление по позиции",
    "SKIP": "Пропуск по позиции",
}
export const rulesModifyingAvailableField = {
    "LESS_THAN_LENGTH": ["name", "method", "lesThan", "newPosition", "newSizeLength", "plusPosition", "priority", "plusOldWidth", "plusOldLength"],
    "LESS_THAN_WIDTH": ["name", "method", "lesThan", "newPosition", "newSizeWidth", "plusPosition", "priority", "plusOldWidth", "plusOldLength"],
    "FIND_BY_POSITION": ["name", "position", "method", "newPosition", "plusPosition", "plusOldLength", "plusOldWidth", "newSizeLength", "newSizeWidth", "l1", "l1Name", "l2", "l2Name", "w1", "w1Name", "w2", "w2Name", "priority"],
    "DELETE": ["name", "position", "method", "priority", "plusOldWidth", "plusOldLength"],
    "SKIP": ["name", "position", "method", "priority", "plusOldWidth", "plusOldLength"],

}