import React, {useEffect, useState} from 'react';
import IssuingEdgeService from "../../../API/orders/prodaction.issueedge/issueedge/IssuingEdgeService";
import IssuingEdgesTable from "../../../components/UI/table/prodaction/issuingEdges/IssuingEdgesTable";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../Constants";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";

const IssuingEdges = () => {
    const pathname = useLocation().pathname
    const [alertData, setAlertData] = useState({});
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    useEffect(() => {
        getStartPageSize()
        IssuingEdgeService.getAllByOrganizationNotClose().then(function (response) {
            const data = response.data;
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            setData(data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Выдача производственных материалов"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Выдача производственных материалов
                    </Typography>
                </Box>
                <IssuingEdgesTable
                    data={data}
                    setData={setData}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
            </Box>
            <ModalAlert
                data={alertData}
                onClose={() => setAlertData((alert) => ({...alert, open: false}))}
            />
        </Box>
    );
};

export default IssuingEdges;