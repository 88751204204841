import Box from "@mui/material/Box";
import React, {useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {ReactComponent as User} from "../../../../components/Icons/user.svg"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import IconButton from "@mui/material/IconButton";
import {ListItemIcon, ListItemText} from "@mui/material";
import ProfileModal from "./ProfileModal";
import OrganizationCustomerService from "../../../../API/orders/orders/organization/OrganizationCustomerService";

const Profile = ({data, setData, handleError}) => {
    const [modal, setModal] = useState(false)
    const [modalParams, setModalParams] = useState({})

    const profileSettings = [
        {id: "userName", icon: <User/>, name: "Логин", title: "Изменить логин"},
        {id: "email", icon: <EmailOutlinedIcon/>, name: "Почта", title: "Изменить почту"},
        {id: "password", icon: <HttpsOutlinedIcon/>, name: "Пароль", title: "Изменить пароль"}]

    const editHandle = (value) => {
        setModal(true)
        setModalParams(value)
    }
    const handleClose = () => {
        setModal(false)
        setModalParams({})
    }
    const handleSubmit = (res, handleClose) => {
        const api = modalParams.id === "userName" ? OrganizationCustomerService.editLogin : modalParams.id === "email" ? OrganizationCustomerService.editMail : OrganizationCustomerService.editPassword
        api(res).then(function (response) {
            setData(response.data)
            handleClose()
        })
            .catch(function (error) {
                handleError(error);
            })
    }

    return <Box sx={{width: '600px'}}>
        <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            {profileSettings.map((value) => (
                <ListItem
                    key={value.id}
                    disableGutters
                    secondaryAction={
                        <IconButton aria-label="comment" onClick={() => editHandle(value)}>
                            <EditOutlinedIcon/>
                        </IconButton>
                    }
                >
                    <ListItemIcon>{value.icon}</ListItemIcon>
                    <ListItemText primary={value.name} secondary={data[value.id] || "***"}/>
                </ListItem>
            ))}
        </List>
        <ProfileModal
            title={modalParams.title}
            open={modal}
            onClose={handleClose}
            handleSubmit={handleSubmit}
            initValue={{id: modalParams.id, name: modalParams.name, value: data[modalParams.id] || ""}}
            handleError={handleError}/>
    </Box>
}
export default Profile