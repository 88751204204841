import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import MaterialOptimizationTextFieldObjectWithEntityList
    from "../../../../textfield/MaterialOptimizationTextFieldObjectWithEntityList";
import CurrencyTextFieldObjectWithEntityList from "../../../../textfield/CurrencyTextFieldObjectWithEntityList";
import TextFieldSimpleSupplierMaterial from "./TextFieldSimpleSupplierMaterial";
import {statusesYesOrNo} from "../../../../../../data/StatusesYesOrNo";
import MaterialTextFieldObjectWithEntityList from "../../../../modul/mainoptions/MaterialTextFieldObjectWithEntityList";
import TextFieldWithSetValueMainOptions from "../../../../modul/mainoptions/TextFieldWithSetValueMainOptions";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import {log} from "three/src/nodes/math/MathNode";

const ModalCreateOrUpdateSupplierMaterial = ({
                                                 open,
                                                 onClose,
                                                 title,
                                                 values,
                                                 setValues,
                                                 labelButtonSSubmit,
                                                 handleSubmit,
                                                 supplierList,
                                                 setSupplierId,
                                                 setCurrencyId,
                                                 price,
                                                 setPrice,
                                                 isNew,
                                                 isFixPrice,
                                                 currencyMap
                                             }) => {
    const currencyList = Array.from(currencyMap.values())

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <MaterialOptimizationTextFieldObjectWithEntityList
                            label="Поставщик"
                            field={'supplier'}
                            setId={setSupplierId}
                            entityList={supplierList}
                            disabled={!isNew}
                        />
                        <TextFieldWithSetValueMainOptions
                            label="Артикул поставщика"
                            values={values}
                            setValues={setValues}
                            field={'article'}
                        />
                        <CurrencyTextFieldObjectWithEntityList
                            label="Валюта базовой цены"
                            values={values}
                            field={'basePrice'}
                            setId={setCurrencyId}
                            entityList={currencyList}
                            disabled={!isNew}
                        />
                        {isNew && <TextFieldSimpleSupplierMaterial
                            label="Базовая цена"
                            value={price}
                            setValue={setPrice}
                            field={'price'}
                        />}
                        {!isNew && <TextFieldWithSetValueMainOptions
                            label="Базовая цена"
                            values={values ? values.basePrice : null}
                            setValues={setValues}
                            field={'price'}
                            disabled={true}
                        />}
                        {isFixPrice && <MaterialTextFieldObjectWithEntityList
                            label="Фиксированная цена"
                            values={values}
                            setValues={setValues}
                            field={'fixPrice'}
                            entityList={statusesYesOrNo}
                        />}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={onClose}/>
                <ButtonAdd text={labelButtonSSubmit} onClick={handleSubmit}/>
            </DialogActions>
        </Dialog>
    );
};

export default ModalCreateOrUpdateSupplierMaterial;