import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import HeaderService from "../../../../../API/orders/orders/organization/HeaderService";
import ModelsUtil from "../../../../../utils/orders/ModelUtils";
import {Link, useNavigate} from "react-router-dom";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../../сheckbox/CellCheckboxWithUnable";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import BasicModelService from "../../../../../API/model-estimate/model/BasicModelService";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const BasicModelsTable = ({
                              setBasicModelId,
                              setModalModelsOpen,
                              modal,
                              data,
                              setData,
                              unable,
                              setUnable,
                              change,
                              setChange,
                              tableOptions,
                              tableOptionsHandle,
                              selectId,
                              select,
                              handleError
                          }) => {
        const [createModalOpen, setCreateModalOpen] = useState(false);
        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [modelHeaderList, setModelHeaderList] = useState([]);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
            unable[values.id] = values.enabled;
            tableOptionsHandle((prev) => ({...prev, totalElements: data.length}))
        };

        const handleUpdate = (row, values) => {
            data[row[currentEditRowId]] = values;
            setData([...data]);
        };

        const openModels = useCallback(
            (row) => {
                setBasicModelId(row.id)
                setModalModelsOpen(true);
            },
            [data],
        );


        const handleDeleteRow = useCallback(
            (row) => {
                BasicModelService.delete(row.id)
                    .then(function () {
                        setData(prev => prev.filter(el => el.id !== row.id))
                        tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                    })
                    .catch(function (error) {
                        handleError(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setCurrentEditRowId(row?.id);
                setUpdateModalOpen(true);

            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleUnableCheckbox = (id, status) => {
            BasicModelService.unable(id, status)
                .then(function () {
                    handleUnable(id, status)
                })
                .catch(function (error) {
                    handleError(error);
                })
        };

        const handleUnable = (id, status) => {
            unable[id] = status
            setUnable(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'name',
                    header: 'Модель',
                    accessorKey: 'name',
                    minSize: 350,
                    Cell: ({cell, row}) => {
                        return (
                            !modal ? <li key={cell.getValue()}>
                                <Link
                                    to={'/organization/basic-models/' + row.original.id + '/estimates'
                                    }>{cell.getValue() === undefined ? 'Не определено' : cell.getValue()}</Link>
                            </li> : cell.getValue() === undefined ? 'Не определено' : cell.getValue()
                        )
                    }
                },
                {
                    id: 'headerName',
                    header: 'Наименование',
                    accessorKey: 'headerName',
                    filterVariant: 'multi-select',
                    filterSelectOptions: modelHeaderList,
                    minSize: 30,
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (!modal) {
                baseColumns.splice(7, 0, {
                    header: 'Статус',
                    accessorKey: 'enabled',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.original.id}
                        unable={unable}
                        setUnable={handleUnableCheckbox}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            if (!modal) {
                baseColumns.splice(8, 0, {
                    id: 'packagingSet.articleNumber',
                    header: 'Артикул упаковки',
                    accessorKey: 'packagingSet.articleNumber',
                    minSize: 15,
                    maxSize: 15,
                    enableEditing: false,
                    Cell: ({cell, row}) => {
                        if (data[row.index].packagingSet !== null) {
                            return (
                                <li key={cell.getValue()}>
                                    <Link
                                        to={'/models/set-packaging-set/' + data[row.index].packagingSet.id + '/' + data[row.index].packagingSet.articleNumber
                                        }>{cell.getValue() === undefined ? 'Не определено' : cell.getValue()}</Link>
                                </li>
                            );
                        } else {
                            return (
                                <li key={cell.getValue()}>
                                    {cell.getValue() === undefined ? 'Не определено' : cell.getValue()}
                                </li>
                            );
                        }
                    }
                });
            }

            return baseColumns;

        }, [data, setData,]);

        const menuItem = modal ? [
            {type: "delete", text: "Показать модели", handler: openModels},
        ] : [
            {type: "edit", text: "Редактировать", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow},
        ]
        return (
            <div>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
                >
                    {!modal && <ButtonCreate
                        text={"Создать модель"}
                        width={180}
                        onClick={() => setCreateModalOpen(true)}
                    />}
                </Box>

                <MaterialReactTable
                    muiTableContainerProps={{
                        sx: {
                            width: '1000px',
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": !modal && {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& li": {
                                listStyleType: "none",
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }
                                }
                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem"
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            onClick: () => select && select(row.original),
                            sx: () => ({
                                height: '36px',
                                cursor: select && "pointer",
                                "&:hover& td:after": {
                                    display: "none",
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: row.original.id === selectId ? "#D7E8FA" : '#F5F6FA',
                                },
                                backgroundColor: row.original.id === selectId ? "#D7E8FA" : "white",
                            })
                        })
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        backgroundColor: "red",
                        sx: {
                            backgroundColor: "red",
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        sorting: [{id: 'name', desc: false}],
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                    }}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowActions={!modal}
                    enableToolbarInternalActions={false}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })
                    }
                    localization={MRT_Localization_RU}
                />
                {!modal && <CreateNewModelModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                />}
                {!modal && <UpdateModelModal
                    columns={columns}
                    open={updateModalOpen}
                    onClose={() => setUpdateModalOpen(false)}
                    onSubmit={handleUpdate}
                    values={currentEditRowData}
                    currentEditRowId={currentEditRowId}
                    change={change}
                    setChange={setChange}
                />}
            </div>
        );
    }
;

export const CreateNewModelModal = ({open, columns, onClose, onSubmit}) => {
    const [modelHeaderList, setModelHeaderList] = useState([]);
    const [values, setValues] = useState({
        headerId: modelHeaderList[0]?.id,
        headerName: modelHeaderList[0]?.name,
        "name": "",
    })

    useEffect(() => {
        HeaderService.getAllUnableForModels().then(function (response) {
            setModelHeaderList(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleSubmit = () => {
        console.log(values)
        const data = {
            headerId: values.headerId || modelHeaderList[0]?.id,
            headerName: values.headerName || modelHeaderList[0]?.name,
            "name": values.name, enabled: true
        }
        BasicModelService.save(data, data.headerId).then(function (response) {
            onSubmit(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Создать модель</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        <TextField
                            size={"small"}
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '0.01rem',
                            }}
                            margin="normal"
                            id="modelHeader"
                            select
                            label="Найменование"
                            value={[values.headerName, values.headerId]}
                            onChange={(e) => {
                                const [headerName, headerId] = e.target.value.split(",");
                                setValues({...values, headerId: headerId, headerName: headerName})
                            }}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {modelHeaderList.map((modelHeader) => (
                                <option
                                    key={modelHeader.id}
                                    value={[modelHeader.name, modelHeader.id]}
                                >
                                    {modelHeader.name}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            size={"small"}
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '0.01rem',
                            }}
                            margin="normal"
                            key={"name"}
                            label={"Модель"}
                            name={"name"}
                            onChange={(e) =>
                                setValues({...values, name: e.target.value})
                            }
                        />


                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit
                    text={"Выйти"} onClick={onClose}/>
                <ButtonAdd
                    text={"Сохранить"} onClick={handleSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export const UpdateModelModal = ({open, columns, onClose, onSubmit, values, change, setChange}) => {

    const [modelHeaderList, setModelHeaderList] = useState([]);
    const [modelHeaderId, setModelHeaderId] = useState(null);


    useEffect(() => {
        HeaderService.getAllUnableForModels().then(function (response) {
            setModelHeaderList(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleSubmit = () => {
        BasicModelService.update(values, modelHeaderId === null ? values.headerId : modelHeaderId).then(function (response) {
            onSubmit(response.data);
            setModelHeaderId(null)
            if (modelHeaderId !== null) {
                setChange(!change)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Редактировать модель</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                        }}
                    >
                        {columns.map((column) => (
                            ModelsUtil.checkCreateField(column) &&
                            <TextField
                                size={"small"}
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '0.01rem',
                                }}
                                margin="normal"
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                defaultValue={values === null ? "" : values[column.accessorKey]}
                                onChange={(e) =>
                                    values[column.accessorKey] = e.target.value
                                }
                            />
                        ))}

                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit
                    text={"Выйти"} onClick={onClose}/>
                <ButtonAdd
                    text={"Сохранить"} onClick={handleSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export default BasicModelsTable;