import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";

const modelRestUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/replacements'

export default class ModelReplacementService {
    static async getAllByMaterialId(modelId) {
        return axios.get(modelRestUrl + '?modelId=' + modelId);
    }

    static async create(modelId, data) {
        return await axios.post(modelRestUrl + '?modelId=' + modelId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        return await axios.put(modelRestUrl + '/' + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(modelRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}