import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import ArrivalMaterialPriceUnitTable
    from "../../../../../components/UI/table/warehouse/material/arrival/ArrivalMaterialPriceUnitTable";
import {BreadcrumbsComponent} from "../../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAlert from "../../../../../components/UI/table/customer-detales/ModalAlert";

const ArrivalMaterialPriceUnit = () => {
    const params = useParams();
    const [alertData, setAlertData] = useState({});

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Заявка на поставку ${params.arrivalMaterial}`}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заявки на поставку", href: "/organization/materials-arrival"}
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        {`Заявка на поставку ${params.arrivalMaterial}`}
                    </Typography>
                </Box>
                <ArrivalMaterialPriceUnitTable
                    arrivalMaterialId={params.arrivalMaterialId}
                    currentCurrency={params.currentCurrency}
                    isBaseCurrency={params.isBaseCurrency}
                    isAlternativeCurrency={params.isAlternativeCurrency}
                    baseCurrency={params.baseCurrency}
                    alternativeCurrency={params.alternativeCurrency}
                    supplierId={params.supplierId}
                    warehouseId={params.warehouseId}
                    handleError={handleError}
                />
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
        </Box>
    );
};

export default ArrivalMaterialPriceUnit;