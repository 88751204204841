import Box from "@mui/material/Box";
import React from "react";
import List from "@mui/material/List";
import {ListItemButton, ListItemText, Step, StepLabel, Stepper, Typography} from "@mui/material";
import ButtonAdd from "../../../components/UI/button/ButtonAdd";
import OrganizationsStageSettingsService from "../../../API/organization/OrganizationsStageSettingsService";

const SetupStages = ({handleError, stage, setStage, handleActiveItemsChange}) => {

    const handleNextState = () => {
        OrganizationsStageSettingsService.nextStage().then(function (response) {
            setStage(response.data.stage)
            localStorage.setItem("stage", JSON.stringify(response.data.stage))
            if (response.data.stage === 9) handleActiveItemsChange("profile")
        })
            .catch(function (error) {
                handleError(error);
            })
    }
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        width: "1100px",
        gap: "40px",
        padding: "24px 0 ",
        borderRadius: "16px",
        border: (theme) => `${theme.palette.primary.dark} 1px solid`
    }}>
        <Stepper
            alternativeLabel
            style={{width: "1100px"}}
            activeStep={stage - 1}
        >
            {["1", '2', '3', '4', '5', '6', "7", "8"].map((label) => (
                <Step key={label} sx={{
                    "& .MuiStepConnector-root": {
                        top: "20px",
                        left: 'calc(-50% + 30px)',
                        right: "calc(50% + 30px)"
                    }
                }}>
                    <StepLabel sx={{
                        ".MuiSvgIcon-root": {
                            fontSize: 40,
                            color: "#737478",
                            "&.Mui-active": {
                                color: "#3B8BEC",
                            }
                        },
                        "& .MuiStepLabel-label": {
                            fontSize: 18,
                            "&.Mui-active": {
                                fontWeight: 700,
                            }
                        },

                    }}></StepLabel>
                </Step>
            ))}
        </Stepper>
        <Box sx={{
            padding: "0 40px",
            display: "flex",
            flexDirection: "column"
        }}>
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                {stage === 8 ? "Все базовые настройки выполнены. Что бы закончить нажмите кнопку \"Завершить\"" : "Для перехода на следующий шаг настройки организацинеобходимо:"}
            </Typography>
            <List>
                {
                    stage === 1 ?
                        <ListItemButton
                            sx={{
                                color: "#006CCC",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                    textDecoration: "underline",
                                }
                            }}
                            onClick={() => handleActiveItemsChange("addressList")}
                        >
                            <ListItemText
                                primary={"Добавить адрес регистрации, фактический адрес и адрес склада (При совпадении адресов, не нужно дублировать)"}/>
                        </ListItemButton> : stage === 2 ? <ListItemButton
                            sx={{
                                color: "#006CCC",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                    textDecoration: "underline",
                                }
                            }}
                            onClick={() => handleActiveItemsChange("generalSettings")}
                        >
                            <ListItemText
                                primary={"Установить адрес регистрации и фактический адрес"}/>
                        </ListItemButton> : stage === 3 ? <ListItemButton
                            sx={{
                                color: "#006CCC",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                    textDecoration: "underline",
                                }
                            }}
                            onClick={() => handleActiveItemsChange("generalSettings")}
                        >
                            <ListItemText
                                primary={"Установить базовую и альтернативную валюту (в дальнейшем изменять валюту нельзя, все расчеты будут производится  базовой и альтернативной валюте)"}/>
                        </ListItemButton> : stage === 4 ? <ListItemButton
                            sx={{
                                color: "#006CCC",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                    textDecoration: "underline",
                                }
                            }}
                            onClick={() => handleActiveItemsChange("warehouseName")}
                        >
                            <ListItemText
                                primary={"Необходимо создать минимум один склад, с установкой адреса склада"}/>
                        </ListItemButton> : stage === 5 ?
                            <Box sx={{display: "flex", flexDirection: "column", gap: "20px"}}>
                                <Typography sx={{textDecoration: "underline",}}>Нажать кнопку "Далее"</Typography>
                                <Box>
                                    <Typography>На данном этапе произойдет создание:</Typography>
                                    <Typography>1. Базовых групп материалов</Typography>
                                    <Typography>2. Базовых материалов</Typography>
                                    <Typography>3. Работ над материалами</Typography>
                                    <Typography>4. Наименования затрат по работам над материалами</Typography>
                                    <Typography>5. Затрат по работам над материалами</Typography>
                                    <Typography>6. Группы работ над материаламив</Typography>
                                    <Typography>7. Поставщиков</Typography>
                                    <Typography>8. Базовых цен на материалы и работы</Typography>
                                </Box>
                                <Typography>После автоматической настройки, в ручном режиме можно изменить любой из
                                    показателей</Typography>
                            </Box>
                            : stage === 6 ? <ListItemButton
                                sx={{
                                    color: "#006CCC",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                        textDecoration: "underline",
                                    }
                                }}
                                onClick={() => handleActiveItemsChange("headers")}
                            >
                                <ListItemText
                                    primary={"Необходимо создать минимум один, заголовок заказа"}/>
                            </ListItemButton> : stage === 7 ? <ListItemButton
                                sx={{
                                    color: "#006CCC",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                        textDecoration: "underline",
                                    }
                                }}
                                onClick={() => handleActiveItemsChange("attributes")}
                            >
                                <ListItemText
                                    primary={"Необходимо создать минимум один, атрибут заказа"}/>
                            </ListItemButton> : <Box sx={{display: "flex", flexDirection: "column", gap: "20px"}}>
                                <Typography> Рекомендуется удалить работы которые не планируются выполнять, материалы
                                    которые не используются, а так же обновить собственные цены на материалы и работы,
                                    зарегистрировать работников, при необходимости пользоваться разделом
                                    помощи.</Typography>
                            </Box>
                }
            </List>
        </Box>
        <Box sx={{display: "flex", justifyContent: "flex-end", paddingRight: "24px"}}>
            <ButtonAdd
                text={stage === 8 ? "Завершить" : "Далее"} width={100} onClick={handleNextState}
            />
        </Box>
    </Box>
}
export default SetupStages