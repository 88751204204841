import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import PackagingUnitService from "../../../../../API/model-estimate/packaging/PackagingUnitService";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";

const PackagingUnitTable = ({
                                handleError,
                                data,
                                setData,
                                tableOptions,
                                tableOptionsHandle,
                                modal,
                                selectedStringId,
                                setSelectedString
                            }) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);

    const handleClose = () => {
        setCreateModalOpen(false)
        setCurrentEditRowData(null)
    }
    const handleSaveRowEdits = async (values) => {
        PackagingUnitService.update(currentEditRowData.id, values)
            .then(function () {
                setData(prev => prev.map(el => el.id === currentEditRowData.id ? {id: currentEditRowData.id, ...values} : el))
            })
            .catch(function (error) {
                handleError(error);
            })
        handleClose()
    };

    const handleDeleteRow = useCallback(
        (row) => {
            PackagingUnitService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );
    const handleEditRow = (row) => {
        setCreateModalOpen(true)
        setCurrentEditRowData(row)
    }
    const handleSubmit = (values) => {
        if (values.weight === "") {
            values.weight = 0;
        }
        PackagingUnitService.save(values).then(function (response) {
            setData(prev => ([...prev, response.data]));
            tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
        })
            .catch(function (error) {
                handleError(error);
            });
        handleClose()
    };


    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 500,
            },
            {
                id: 'weight',
                header: 'Вес',
                accessorKey: 'weight',
                minSize: 100,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditRow},
        {type: "delete", text: "Удалить", handler: handleDeleteRow}
    ]

    return (
        <>
            {!modal && <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>Единицы упаковки</Typography>
                <ButtonCreate
                    text={"Создать ед. упаковки"}
                    width={230}
                    onClick={() => setCreateModalOpen(true)}
                />
            </Box>}
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '800px',
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": !modal && {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        onClick: () => setSelectedString && setSelectedString(row.original),
                        sx: () => ({
                            height: '36px',
                            cursor: setSelectedString && "pointer",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: row.original.id === selectedStringId ? "#D7E8FA" : '#F5F6FA',
                            },
                            backgroundColor: row.original.id === selectedStringId ? "#D7E8FA" : "white",
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={!modal}
                enableToolbarInternalActions={false}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })
                }
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    density: "compact",
                }}
                localization={MRT_Localization_RU}
            />
            <CreateNewPackagingUnit
                initValue={currentEditRowData}
                open={createModalOpen}
                onClose={handleClose}
                onSubmit={currentEditRowData ? handleSaveRowEdits : handleSubmit}
            />

        </>
    );
};

export const CreateNewPackagingUnit = ({open, onClose, onSubmit, initValue}) => {
    const [values, setValues] = useState({name: "", weight: ""});

    useEffect(() => {
        setValues(initValue)
    }, [initValue])

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{initValue ? "Создать ед. упаковки" : "Изменить ед. упаковки"}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextFieldValidation
                            name={"name"}
                            value={values?.name}
                            label="Наименование"
                            setCurrValues={setValues}
                        />
                        <TextFieldValidation
                            name={"weight"}
                            value={values?.weight}
                            label="Вес"
                            setCurrValues={setValues}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"} onClick={() => onSubmit(values)}/>
            </DialogActions>
        </Dialog>
    );
};

export default PackagingUnitTable;