
export const orderStatus = {
    NEW: "Новый",
    ADOPTED: "Принят",
    DEVELOPMENT: "Проектирование",
    DEVELOPED: "Разработан",
    PRODUCTION: "Производство",
    PRODUCED: "Произведен",
    AWAITING_SHIPMENT: "Ожидание огрузки",
    SHIPPED: "Отгружен",
    ARCHIVE : "Архив",
}
export const orderStatusForCreate = {
    NEW: "Новый",
    ADOPTED: "Принят",
    DEVELOPMENT: "Проектирование",
    DEVELOPED: "Разработан",
}