import React, {useEffect, useState} from 'react';
import OrderTable from "../../../../components/UI/table/orders/organization/order/OrderTable";
import OrderService from "../../../../API/orders/orders/organization/OrderService";
import DataUtil from "../../../../utils/common/DataUtil";
import OrderUtil from "../../../../utils/orders/OrderUtil";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {Box} from "@mui/system";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";
import ButtonSelectAction from "../../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../../components/UI/modal/ModalSelectAction";
import ModalSetPlan from "../../../../components/UI/table/orders/organization/order/ModalSetPlan";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";

const Orders = () => {
    const [rowSelection, setRowSelection] = useState([]);
    const [data, setData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);
    const [change, setChange] = useState(null);
    const [open, setOpen] = useState(false)
    const [alertData, setAlertData] = useState({});
    const [setPlanModalOpen, setSetPlanModalOpen] = useState(false);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/orders`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/orders`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const modalActions = [
        {name: "Установить план", type: "plan"},
    ]
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    const handleSelectAction = () => {
        setOpen(false)
        setSetPlanModalOpen(true)
    }
    useEffect(() => {
        getStartPageSize()
        OrderService.getOrders().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            setCustomerList(DataUtil.getListChildField(data, 'customerOrder', 'name'));
            setNameList(DataUtil.getListChildField(data, 'header', 'name'));
            setAttributeList(OrderUtil.getStatuses(data, 'attributes'));
            setPlanningList(DataUtil.getListChildField(data, 'plan', 'name'));
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [change]);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Заказы"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Заказы
                    </Typography>
                </Box>
                <OrderTable
                    data={data}
                    setData={setData}
                    customerList={customerList}
                    nameList={nameList}
                    attributeList={attributeList}
                    planningList={planningList}
                    enableEditing={true}
                    change={change}
                    setChange={setChange}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
                {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>}
                <ModalSelectAction
                    open={open}
                    reset={() => setOpen(false)}
                    submit={handleSelectAction}
                    modalActions={modalActions}
                />
                <ModalSetPlan
                    open={setPlanModalOpen}
                    handleClose={() => setSetPlanModalOpen(false)}
                    selectedIds={rowSelection}
                    data={data}
                    setData={setData}
                    handleError={handleError}
                />
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
        </Box>
    );
};

export default Orders;