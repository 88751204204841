import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Checkbox, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import AttributesService from "../../../../../../API/orders/orders/organization/AttributesService";
import {useLocation, useNavigate} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../../../utils/EditLocalStoragePagesData";
import ButtonCreate from "../../../../button/ButtonCreate";
import JobNameModal from "../job/JobNameModal";
import {constantsErrorMessages} from "../../../../../../Constants";
import ModalAlert from "../../../customer-detales/ModalAlert";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";

const AttributesTable = ({itemId}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [alertData, setAlertData] = useState({});
    const [modal, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        AttributesService.getAll().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const setEnabledDefault = (row) => {
        AttributesService.unableDefault(row.id)
            .then(function () {
                setData(prev => prev.map(el => el.id === row.id
                    ? {...el, enabledDefault: true}
                    : {...el, enabledDefault: false}
                ))
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleAdd = () => {
        setModal(true)
    }
    const handleClose = () => {
        setModal(false)
        setCurrentEditRowData(null)
    }

    const handleUpdate = (data) => {
        AttributesService.update(data, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }
    const handleSubmit = (data) => {
        AttributesService.save({...data, enabled: true})
            .then(function (response) {
                setData(prev => [...prev, response.data])
                setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }
    const handleDelete = (row) => {
        AttributesService.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
                setTableOptions((prev) => ({...prev, totalElements: data.length - 1}))
            })
            .catch(function (error) {
                handleError(error)
            })
    };
    const handleStatus = (data) => {
        AttributesService.unable(data.id, !data.enabled)
            .then(function () {
                setData(prev => prev.map(el => el.id === data.id ? {...el, enabled: !el.enabled} : el))
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    const handleEdit = (row) => {
        setCurrentEditRowData(row);
        setModal(true)
    };

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 500,

            },
            {
                id: 'enabledDefault',
                header: 'По умочанию',
                accessorKey: 'enabledDefault',
                maxSize: 130,
                enableColumnActions: false,
                Cell: ({cell, row}) => <Radio
                    onChange={() => setEnabledDefault(row.original)}
                    checked={row.original.enabledDefault}
                    sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
                />
            },
            {
                id: 'enabled',
                header: 'Статус',
                accessorKey: 'enabled',
                maxSize: 80,
                enableColumnActions: false,

                Cell: ({cell, row}) => <Checkbox
                    onChange={() => handleStatus(row.original)}
                    checked={row.original.enabled}
                    sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
                />
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}
    ]
    return (
        <>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
            >
                <Typography sx={{fontSize: "24px", fontWeight: 700}}>Атрибуты заказов</Typography>
                <ButtonCreate
                    text={"Добавить атрибуты"}
                    width={220}
                    onClick={handleAdd}
                />
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={({cell, table}) => {
                    return ({
                        sx: () => ({
                            p: cell.column.id === "name" ? '0px 0px 0px 10px' : 0,
                            m: '0px 0px 0px 0px',
                            border: "none",
                            textAlign: cell.column.id === "name" ? "left" : "center",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": {
                                width: "50px",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& li": {
                                listStyleType: "none",
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }
                                }
                            }
                        })
                    })
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: "white",
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey
                                },
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />
            <ModalAlert data={alertData} onClose={handleCloseAlert}/>
            <JobNameModal
                title={currentEditRowData ? "Изменить" : "Добавить"}
                open={modal} onClose={handleClose}
                handleSubmit={currentEditRowData ? handleUpdate : handleSubmit}
                initValue={currentEditRowData}/>
        </>
    );
};

export default AttributesTable;