import {useLocation} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import Box from "@mui/material/Box";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup,
    Tooltip,
    Typography
} from "@mui/material";
import ButtonCreate from "../../../components/UI/button/ButtonCreate";
import {MaterialReactTable} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import UtilityHelpService from "../../../API/common/UtiltyHelpServices";
import TextFieldValidation from "../../../components/UI/table/customer-detales/TextFieldValidation";
import ButtonExit from "../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../components/UI/button/ButtonAdd";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const HelpSetting = ({itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState({text: "", src: "", themeHelps: []});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
        getStartPageSize()
        UtilityHelpService.get().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleClose = () => {
        setModal(false)
        setCurrentEditRowData({text: "", src: "", themeHelps: []})
    }

    const handleUpdate = (data) => {
        UtilityHelpService.update(data, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                handleClose()
            })
            .catch(function (error) {
                handleError(error)
            })

    }

    const handleDelete = (row) => {
        UtilityHelpService.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleSubmit = (data) => {
        UtilityHelpService.add(data)
            .then(function (response) {
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length + 1}))
                handleClose()
            })
            .catch(function (error) {
                handleError(error)
            })

    }

    const handleAdd = () => {
        setModal(true)
    }
    const handleEdit = (row) => {
        setCurrentEditRowData(row);
        setModal(true)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Найменование', accessorKey: 'text', minSize: 200,
            },
            {
                header: 'Ссылка на видео', accessorKey: 'src', minSize: 500,
            },
            {
                header: 'Темы', accessorKey: 'themeHelps', minSize: 300,
                Cell: ({cell, row}) => {
                    const text = cell.getValue()?.map(theme => `${theme.title}, `)
                    return <Box>{text}</Box>
                }
            },
        ]

        return baseColumns;

    }, [data]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}
    ]

    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
        >
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>Настройки помощи</Typography>
            <ButtonCreate
                text={"Добавить"}
                width={130}
                onClick={handleAdd}
            />
        </Box>
        <MaterialReactTable
            muiTableContainerProps={{
                sx: {
                    width: '1200px',
                }
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "50px",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            rowHeight={13}
            columnSpacing={1}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            rowCount={tableOptions.totalElements}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return (el.type === "edit" || (el.type === "delete" && !row.original.blocked)) && <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })}
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
        />
        <HelpSettingModal
            title={currentEditRowData.id ? "Изменить" : "Добавить"}
            open={modal} onClose={handleClose}
            handleSubmit={currentEditRowData.id ? handleUpdate : handleSubmit}
            initValue={currentEditRowData}
            handleError={handleError}/>
    </div>);
};

const HelpSettingModal = ({open, onClose, handleSubmit, initValue, title, handleError}) => {
    const [formState, setFormState] = useState({text: "", src: "", themeHelps: []})
    const [validationState, setValidationState] = useState({text: "", src: "",})
    const [theme, setTheme] = useState([])

    useEffect(() => {
        UtilityHelpService.getThemeHelp().then(function (response) {
            setTheme(response.data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [open]);

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleClose = () => {
        setFormState({text: "", src: "", themeHelps: []})
        setValidationState({text: "", src: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    name={"text"}
                    value={formState?.text}
                    label="Наименование"
                    setCurrValues={setFormState}
                    validationError={validationState?.text}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"src"}
                    value={formState?.src}
                    label="Ссылка на видео"
                    setCurrValues={setFormState}
                    validationError={validationState?.src}
                    setValidationState={setValidationState}
                    adornment={<Tooltip
                        sx={{height: "40px"}}
                        arrow placement="top"
                        title={"Выбрать видео на youtube => нажать поделиться => встроить => текст после 'src='"}>
                        <HelpOutlineIcon/>
                    </Tooltip>}

                />
                <FormGroup>
                    {theme.map((option) => (
                        <FormControlLabel
                            key={option.id}
                            control={<Checkbox/>}
                            checked={!!formState?.themeHelps?.find(el => el.id === option.id)}
                            onChange={() => setFormState(prev => {
                                const added = prev?.themeHelps?.find(el => el.id === option.id)
                                const themeHelps = added ? prev?.themeHelps?.filter(el => el.id !== option.id) : [...prev?.themeHelps, option]
                                return {...prev, themeHelps}
                            })}
                            label={option.title}/>
                    ))}
                </FormGroup>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default HelpSetting;