import React, {useEffect, useState} from 'react';
import SuppliersTable from "../../../components/UI/table/orders/organization/suppliers/SuppliersTable";
import SupplierService from "../../../API/orders/warehouse/supplier/SupplierService";
import {Typography} from "@mui/material";
import {useLocation} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import AdminSuppliersService from "../../../API/material-cloud/admin/AdminSuppliersService";

const Suppliers = ({suppliers, itemId, handleError, admin}) => {
    const api = admin ? AdminSuppliersService : SupplierService
    const pathname = useLocation().pathname
    const [data, setData] = useState([])
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        api.get().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [])
    return (
        <div>
            <SuppliersTable
                data={data} setData={setData} suppliers={suppliers} tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle} handleError={handleError} api={api} admin={admin}/>
        </div>
    );
};

export default Suppliers;