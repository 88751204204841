import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import PlanService from "../../../../../../API/orders/optimization/organization/PlanService";
import TextFieldObject from "../../../../textfield/TextFieldObject";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import ButtonCancel from "../../../../button/ButtonCancel";
import ButtonAdd from "../../../../button/ButtonAdd";

const ModalSetPlan = ({
                          open,
                          handleClose,
                          selectedIds,
                          data,
                          setData,
                          handleError
                      }) => {

        const [planList, setPlanList] = useState([]);
        const [planId, setPlanId] = useState(null);
        const [defaultPlanId, setDefaultPlanId] = useState([]);

        useEffect(() => {
            PlanService.getAllNotArchive().then(function (response) {
                let data = response.data;
                setPlanList(data);
                setDefaultPlanId(data[0]?.id)
            })
                .catch(function (error) {
                    handleError(error);
                });
        }, []);

        const handleSubmit = () => {
            let currentPlanId = planId === null ? defaultPlanId : planId;

            OrderService.setPlanByIds(selectedIds, currentPlanId,
            ).then(function () {
                const newData = data.map(item => {
                    if (selectedIds.includes(item.id)) {
                        const selectedPlan = planList.find(plan => plan.id.toString() === currentPlanId.toString());
                        return {
                            ...item,
                            plan: selectedPlan
                        };
                    } else {
                        return item;
                    }
                });
                setData(newData);
                handleClose();
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle textAlign="center">{"Добавить в план"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                        >
                            <TextFieldObject
                                label="План"
                                field={'plan'}
                                setId={setPlanId}
                                entityList={planList}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <ButtonCancel text={"Выйти"} onClick={handleClose}/>
                    <ButtonAdd text={"Обновить"} onClick={handleSubmit}/>
                </DialogActions>
            </Dialog>
        );
    }
;

export default ModalSetPlan;