import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../components/UI/table/customer-detales/TextFieldValidation";
import ButtonExit from "../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../components/UI/button/ButtonAdd";

const CurrencyModal = ({open, onClose, handleSubmit, initValue, currencies}) => {
    const [formState, setFormState] = useState(initValue)

    useEffect(() => {
        setFormState(initValue)
    }, [initValue]);

    const handleClose = () => {
        onClose()
        setFormState(initValue)
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">Изменить</DialogTitle>
            <DialogContent sx={{padding: "13px 24px", minWidth: "350px"}}>
                {Object.entries(formState).map(([name, value]) => {
                    return (
                        <TextFieldValidation
                            select
                            key={name}
                            name={name}
                            value={value}
                            label={name === "baseCurrencyId" ? "Базовая валюта" : "Альтернативная валюта"}
                            setCurrValues={setFormState}
                            required={false}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option?.curName}
                                </MenuItem>))}
                        </TextFieldValidation>)

                })}
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Добавить"} onClick={() => handleSubmit(formState, handleClose)}
                           disabled={!formState.baseCurrencyId || !formState.alternativeCurrencyId}/>
            </DialogActions>
        </Dialog>
    );
}
export default CurrencyModal