import Box from "@mui/material/Box";
import React, {useEffect, useMemo, useState} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ListItemText} from "@mui/material";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import PersonalizationModal from "./PersonalizationModal";
import CustomerService from "../../../../API/customer/CustomerService";
import {country} from "../../../../data/Country";
import OrganizationService from "../../../../API/organization/OrganizationService";
import UtilityService from "../../../../API/common/UtilityService";
import CurrencyModal from "../CurrencyModal";

const CustomerSettings = ({handleError, organization, stage}) => {
    const [data, setData] = useState({})
    const [currencies, setCurrencies] = useState([])
    const [modal, setModal] = useState(false)
    const [modalCurrency, setModalCurrency] = useState(false)
    const addressText = [country[data.address?.country], data.address?.street, data.address?.houseNumber, data.address?.building, data.address?.roomNumber].filter(el => el).join(", ")
    const residentialAddressText = [country[data.residentialAddress?.country], data.residentialAddress?.street, data.residentialAddress?.houseNumber, data.residentialAddress?.building, data.residentialAddress?.roomNumber].filter(el => el).join(", ")
    const currBaseCurrency = currencies.find(el => el.id === data?.organizationSettings?.baseCurrencyId)
    const alternativeCurrency = currencies.find(el => el.id === data?.organizationSettings?.alternativeCurrencyId)

    const api = organization ? OrganizationService : CustomerService

    useEffect(() => {
        api.getAll().then(function (response) {
            setData(response.data)
        }).catch(function (error) {
            handleError(error);
        })
        organization && stage > 2 && UtilityService.getCurrencies()
            .then(function (response) {
                setCurrencies(response.data)
            }).catch(function (error) {
                handleError(error)
            })

    }, [])

    const handleClose = () => {
        setModal(false)
    }
    const handleSubmit = (res, handleClose) => {
        const {organizationSettings, address, residentialAddress, ...value} = res
        api.edit(address, residentialAddress, value, data?.organizationSettings?.id).then(function (response) {
            setData(response.data)
            handleClose()
        })
            .catch(function (error) {
                handleError(error);
            })
    }
    const handleSubmitCurrency = (res, handleClose) => {
        api.setCurrency(res.baseCurrencyId, res.alternativeCurrencyId).then(function (response) {
            setData(prev => ({...prev, organizationSettings: response.data}))
            handleClose()
        })
            .catch(function (error) {
                handleError(error);
            })
    }
    return <Box sx={{display: "flex", gap: "20px"}}>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "900px",
            padding: "24px",
            borderRadius: "16px",
            border: (theme) => `${theme.palette.primary.stroke} 1px solid`
        }}>
            <Box Box sx={{
                display: "flex"
            }}>
                <List sx={{width: '100%', maxWidth: 450,}}>
                    <ListItem>
                        <ListItemText primary={"Наименование:"} secondary={data.name || "-"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Организационно-правовая форма:"}
                                      secondary={data.organizationalAndLegalForm || "-"}/>
                    </ListItem>
                </List>
                <List sx={{width: '100%', maxWidth: 450}}>
                    <ListItem>
                        <ListItemText primary={"Почта:"} secondary={data.email || "-"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Регистрационный номер:"} secondary={data.registrationNumber || "-"}/>
                    </ListItem>
                </List>
            </Box>
            <List sx={{width: '100%', maxWidth: 700}}>
                <ListItem>
                    <ListItemText
                        primary={"Адрес:"}
                        secondary={addressText || "-"}/>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={"Адрес доставки:"}
                        secondary={residentialAddressText || "-"}/>
                </ListItem>

            </List>
            <Box Box sx={{
                display: "flex",
                justifyContent: "end"
            }}>
                <ButtonCreate icon={false} text={"Изменить"} width={90} onClick={() => setModal(true)}></ButtonCreate>
            </Box>
        </Box>
        {organization && stage > 2 && <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "400px",
            height: data?.organizationSettings && (!data?.organizationSettings?.baseCurrencyId && !data?.organizationSettings?.alternativeCurrencyId) ? "210px" : "170px",
            padding: "24px",
            borderRadius: "16px",
            border: (theme) => `${theme.palette.primary.stroke} 1px solid`
        }}>
            <List sx={{width: '100%', maxWidth: 700}}>
                <ListItem>
                    <ListItemText
                        primary={"Базовая валюта:"}
                        secondary={currBaseCurrency?.curName || "-"}/>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={"Альтернативная валюта:"}
                        secondary={alternativeCurrency?.curName || "-"}/>
                </ListItem>
            </List>
            <Box Box sx={{
                display: "flex",
                justifyContent: "end"
            }}>
                {data?.organizationSettings && (!data?.organizationSettings?.baseCurrencyId && !data?.organizationSettings?.alternativeCurrencyId) &&
                    <ButtonCreate icon={false} text={"Добавить"} width={90}
                                  onClick={() => setModalCurrency(true)}>
                    </ButtonCreate>
                }
            </Box>
        </Box>}
        <PersonalizationModal
            open={modal}
            onClose={handleClose}
            handleSubmit={handleSubmit}
            initValue={{
                ...data,
                address: data.address?.id || "",
                residentialAddress: data.residentialAddress?.id || ""
            }}
            handleError={handleError}
            organization={organization}
        />
        {data?.organizationSettings && <CurrencyModal
            open={modalCurrency}
            onClose={() => setModalCurrency(false)}
            handleSubmit={handleSubmitCurrency}
            initValue={{
                baseCurrencyId: data?.organizationSettings?.baseCurrencyId || "",
                alternativeCurrencyId: data?.organizationSettings?.alternativeCurrencyId || ""
            }}
            handleError={handleError}
            currencies={currencies}
        />}
    </Box>
}
export default CustomerSettings