import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import IssuingEdgeMaterialMaterialReserveTable from "./IssuingEdgeMaterialMaterialReserveTable";
import ModulAddMaterialIssuingEdge from "./ModulAddMaterialIssuingEdge";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import IssuingEdgesItemTable from "../issuingedgeitem/IssuingEdgesItemTable";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";
import {BreadcrumbsComponent} from "../../../breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {ButtonGroupTabBar} from "../../../button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import ModalAlert from "../../customer-detales/ModalAlert";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../../Constants";
import ButtonCreate from "../../../button/ButtonCreate";

const ModulIssuingEdge = ({id, number}) => {
    const pathname = useLocation().pathname
    const [tab, setTab] = useState(1);
    const [data, setData] = useState([]);
    const [change, setChange] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [addMaterialModalOpen, setAddMaterialModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const getTabsData = (tab, start) => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const tableOptions = pagesData[`${pathname}+1`]?.tableOptions
        tableOptions && setTableOptions((prev) => ({
            ...prev,
            pageSize: tableOptions?.pageSize || 20,
            totalElements: data.length
        }))
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const savedTab = pagesData[pathname]?.tab
        savedTab && setTab(savedTab)

        savedTab === 1 && getTabsData(savedTab, true)
    }
    const tableOptionsHandle = (nextState, currTab) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+1`, "tableOptions", nextState)
        }
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    const handleChangeTab = (tab) => {
        setTab(tab)
        tab === 1 && getTabsData(tab)
        EditLocalStoragePagesData(pathname, "tab", tab)
    }

    const closeAddMaterialModal = () => {
        setAddMaterialModalOpen(false)
    };

    const openAddMaterialModal = () => {
        setAddMaterialModalOpen(true)
    };

    useEffect(() => {
        getStartPageSize()
        MaterialReserveService.getAllByIssuingEdgeId(id).then(function (response) {
            const data = response.data;
            setData(data);
            setOrderList(OrderUtil.getListOrderNumbers(data))
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [change]);

    useEffect(() => {

        /*JobService.getJobsByEstimateId(estimateId, ).then(function (response) {
            setTechnicalMapData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });*/
    }, []);

    const navigate = useNavigate();

    const redirectOrders = () => {
        navigate('/organization/orders/');
    };
    const generalTabComponents = [
        {
            tabIndex: 1, label: "В заказах",
            component: <IssuingEdgeMaterialMaterialReserveTable
                data={data}
                setData={setData}
                pageSize={100}
                enableGrouping={true}
                columnGrouping={["materialName"]}
                expanded={false}
                isColumnMaterialWarehouse={true}
                isColumnOptimization={false}
                orderList={orderList}
                change={change}
                setChange={setChange}
                titleModalUpdateOrAddMaterialArrival={"Создать приход плитных материалов"}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        }, {
            tabIndex: 2, label: "Склад",
            component: <ModulAddMaterialIssuingEdge
                open={addMaterialModalOpen}
                onClose={closeAddMaterialModal}
                titleModal={"Выдать материал"}
                id={id}
                change={change}
                setChange={setChange}
                handleError={handleError}
            />
        }, {
            tabIndex: 3, label: "Выдано",
            component: <IssuingEdgesItemTable
                id={id}
                change={change}
                setChange={setChange}
                handleError={handleError}
            />
        }]


    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`${number}`}
                path={[{name: "Главная", href: "/"},
                    {name: "Выдача производственных материалов", href: "/organization/issuing-edges"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                    {number}
                </Typography>
                <ButtonCreate
                    text={"Вернуться в заказы"}
                    width={170}
                    icon={false}
                    onClick={redirectOrders}
                />
                <ButtonGroupTabBar activeTab={tab}>
                    {generalTabComponents.map(({tabIndex, label}, index) => {
                        return <Button key={tabIndex} tabIndex={tabIndex} onClick={() => handleChangeTab(tabIndex)}>
                            <CheckIcon/>
                            {label}
                        </Button>
                    })}
                </ButtonGroupTabBar>
                {generalTabComponents.find(component => component.tabIndex === tab)?.component}
            </Box>
            <ModalAlert
                data={alertData}
                onClose={() => setAlertData((alert) => ({...alert, open: false}))}
            />
        </Box>
    );
};

export default ModulIssuingEdge;