import services1 from "../components/Icons/services1.png";
import services3 from "../components/Icons/services3.png";

export const stockData = [
    {
        id: 1, text: "stock1", img: services3, date: "11.11.12",
        type: "stock",
        description: "Небольшой текст в две строки, информация о том, о чем будет эта новость. Текст в 2 строки с многоточием"
    },
    {
        id: 2, text: "stock2", img: services1, date: "11.11.12",
        type: "stock",
        description: "Небольшой текст в две строки, информация о том, о чем будет эта новость. Текст в 2 строки с многоточием"
    },
    {
        id: 3, text: "stock2", img: services3, date: "11.11.12",
        type: "stock",
        description: "Небольшой текст в две строки, информация о том, о чем будет эта новость. Текст в 2 строки с многоточием"
    }]