import React, {useEffect, useState} from 'react';
import CurrentCurrencyNationalBankTable
    from "../../../../components/UI/table/warehouse/currency/CurrentCurrencyNationalBankTable";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import CurrencyNationalBankService from "../../../../API/orders/warehouse/currency/CurrencyNationalBankService";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";

const CurrentCurrencyNationalBank = () => {
    const [data, setData] = useState([]);
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/current-currencies`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/current-currencies`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    useEffect(() => {
        getStartPageSize()
        CurrencyNationalBankService.getAll().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Курсы валют"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column", paddingBottom: "30px"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Курсы валют
                    </Typography>
                </Box>
                <CurrentCurrencyNationalBankTable
                    data={data}
                    setData={setData}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}/>
            </Box>
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </Box>
    );
};

export default CurrentCurrencyNationalBank;