import axios from "axios";
import {
    apiVersionAuthorizationServer,
    apiVersionOrders,
    gatewayUrl,
    nameAuthorizationServer,
    nameOrders
} from "../../../../Constants";

const customerRestUrl = gatewayUrl + nameAuthorizationServer + apiVersionAuthorizationServer + '/organizations/customers'
const customerAuthRestUrl = gatewayUrl + nameAuthorizationServer + apiVersionAuthorizationServer + '/organizations/customers'

export default class OrganizationCustomerService {

    static async getAllBySettings() {
        return axios.get(customerRestUrl + '/by-settings');
    }

    static async profileSettings() {
        return axios.get(gatewayUrl + nameAuthorizationServer + "/api/v1/users");
    }

    static async verification() {
        return await axios.post(gatewayUrl + nameAuthorizationServer + "/api/v1/users/verification-code", {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async editLogin(data) {
        return axios.patch(gatewayUrl + nameAuthorizationServer + '/api/v1/users/set-name?verificationCode=' + data.verificationCode + '&newName=' + data.value, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async editMail(data) {
        return axios.patch(gatewayUrl + nameAuthorizationServer + '/api/v1/users/set-email?verificationCode=' + data.verificationCode + '&newMail=' + data.value, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async editPassword(data) {
        return axios.patch(gatewayUrl + nameAuthorizationServer + '/api/v1/users/set-password?verificationCode=' + data.verificationCode + '&password=' + data.value + '&passwordConfirmation=' + data.passwordConfirmation + "&oldPassword=" + data.oldPassword, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async profile() {
        return axios.get(gatewayUrl + nameAuthorizationServer + "/api/v1/users/profile");
    }

    static async profileHead() {
        return axios.head(gatewayUrl + nameAuthorizationServer + "/api/v1/users/profile");
    }

    static async getAllUnable() {
        return axios.get(customerAuthRestUrl + '/enabled?enabled=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        return await axios.patch(customerRestUrl + '/' + id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async merge(ids) {
        return await axios.patch(customerRestUrl + '/merge?ids=' + ids[0] + '&ids=' + ids[1], [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            ;
    }

    static async save(data) {
        return await axios.post(customerRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(customerRestUrl + '/' + id)
    }

    static async unable(id, status) {
        return axios.patch(customerRestUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
