import React, {useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import ButtonCreate from "../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {country} from "../../../../data/Country";
import AddressModal from "./AddressModal";
import {Typography} from "@mui/material";

const AddressOrganizationTable = ({
                                      api,
                                      handleError,
                                      data,
                                      setData,
                                      tableOptions,
                                      tableOptionsHandle
                                  }) => {

    const allCountry = Object.entries(country)
    const initValue = {
        "postalcode": "",
        "country": allCountry[0][0],
        "republic": "",
        "territory": "",
        "region": "",
        "districts": "",
        "locality": "",
        "street": "",
        "houseNumber": "",
        "building": "",
        "roomType": "",
        "roomNumber": "",
        "latitude": "",
        "longitude": "",
        "additionalInfo": "",
    }
    const [modal, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);

    const handleClose = () => {
        setModal(false)
        setCurrentEditRowData(initValue)
    }

    const handleUpdate = (data) => {
        api.update(data, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleDelete = (row) => {
        api.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleSubmit = (data) => {
        api.create(data)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }


    const handleAdd = () => {
        setModal(true)
    }
    const handleEdit = (row) => {
        setCurrentEditRowData(row);
        setModal(true)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'postalcode',
                header: 'Почтовый индекс',
                accessorKey: "postalcode",
                size: 50,
                grow: true,
            },
            {
                id: 'country',
                header: 'Страна',
                accessorKey: "country",
                size: 30,
                grow: true,
                Cell: ({cell}) => country[cell.getValue()],
            },
            {
                id: 'republic',
                header: 'Республика',
                accessorKey: "republic",
                size: 30,
                grow: true,
            },
            {
                id: 'territory',
                header: 'Край',
                accessorKey: "territory",
                size: 30,
                grow: true,
            },
            {
                id: 'region',
                header: 'Область',
                accessorKey: "region",
                size: 150,
                grow: true,
            },
            {
                id: 'districts',
                header: 'Район',
                accessorKey: "districts",
                size: 150,
                grow: true,
            },
            {
                id: 'locality',
                header: 'Населённый пункт',
                accessorKey: "locality",
                size: 30,
                grow: true,
            },
            {
                id: 'street',
                header: 'Улица',
                accessorKey: "street",
                size: 30,
                grow: true,
            },
            {
                id: 'houseNumber',
                header: 'Номер дома(здания)',
                accessorKey: "houseNumber",
                size: 30,
                grow: true,
            },
            {
                id: 'building',
                header: 'Корпус',
                accessorKey: "building",
                size: 30,
                grow: true,
            },
            {
                id: 'roomType',
                header: 'Тип помещения',
                accessorKey: "roomType",
                size: 30,
                grow: true,
            },
            {
                id: 'roomNumber',
                header: 'Номер помещения',
                accessorKey: "roomNumber",
                size: 30,
                grow: true,
            },
            {
                id: 'latitude',
                header: 'Широта',
                accessorKey: "latitude",
                size: 30,
                grow: true,
            },
            {
                id: 'longitude',
                header: 'Долгота',
                accessorKey: "longitude",
                size: 30,
                grow: true,
            },
            {
                id: 'additionalInfo',
                header: 'Дополнительная информация',
                accessorKey: "additionalInfo",
                size: 300,
                grow: true,
            },
        ]

        return baseColumns;

    }, [data]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}
    ]

    return (<Box sx={{overflow: 'auto'}}>
        <Box
            sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
        >
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>Список адресов</Typography>
            <ButtonCreate
                text={"Добавить адрес"}
                width={180}
                onClick={handleAdd}
            />
        </Box>
        <MaterialReactTable
            muiTableContainerProps={{
                sx: {
                    width: "75vw",
                }
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "50px",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            rowHeight={13}
            columnSpacing={1}
            getRowId={(originalRow) => originalRow.id}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            rowCount={tableOptions.totalElements}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })}
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
        />
        <AddressModal
            title={currentEditRowData?.id ? "Изменить адрес" : "Добавить адрес"}
            open={modal} onClose={handleClose}
            handleSubmit={currentEditRowData?.id ? handleUpdate : handleSubmit}
            initValue={currentEditRowData}
            allCountry={allCountry}/>
    </Box>);
};
export default AddressOrganizationTable;