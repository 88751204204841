import {Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Tooltip} from "@mui/material";
import TextFieldValidation from "../../../../components/UI/table/customer-detales/TextFieldValidation";
import ButtonExit from "../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../components/UI/button/ButtonAdd";
import React, {useEffect, useState} from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {Box} from "@mui/system";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import OrganizationCustomerService from "../../../../API/orders/orders/organization/OrganizationCustomerService";

const ProfileModal = ({open, onClose, handleSubmit, title, initValue, handleError}) => {
    const [formState, setFormState] = useState({
        verificationCode: "",
        value: "",
        passwordConfirmation: "",
        oldPassword: ""
    })
    const [validationState, setValidationState] = useState({verificationCode: "", value: ""})
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setFormState({verificationCode: "", value: initValue.value || "", passwordConfirmation: "", oldPassword: ""})
    }, [initValue]);
    const [codeIsSend, setCodeIsSend] = useState(false)

    const verificationHandle = () => {
        OrganizationCustomerService.verification().then(function (response) {
            setCodeIsSend(true);
        })
            .catch(function (error) {
                handleError(error);
            })
    }
    const handleClose = () => {
        setShowPassword(false)
        setCodeIsSend(false)
        setValidationState({verificationCode: "", value: "", passwordConfirmation: "", oldPassword: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (initValue.id === "password") {
                if (name === "passwordConfirmation" && value !== formState.value) {
                    newErrors.passwordConfirmation = "Пароли не совпадают"
                }
                if (value === "") {
                    newErrors[name] = "пустое поле"
                }
            } else {
                if ((name === "value" || name === "verificationCode") && value === "") {
                    newErrors[name] = "пустое поле"
                }
            }
        })
        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState, handleClose)
        }
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{padding: "13px 24px"}}>
                {initValue.id === "password" && <TextFieldValidation
                    type={showPassword ? "text" : initValue.id}
                    name={"oldPassword"}
                    value={formState.oldPassword}
                    label={"Введите старый пароль"}
                    setCurrValues={setFormState}
                    validationError={validationState?.oldPassword}
                    setValidationState={setValidationState}
                    adornment={initValue.id === "password" && <IconButton
                        onClick={() => setShowPassword(true)}
                        onMouseDown={() => setShowPassword(false)}
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>}
                />}
                <TextFieldValidation
                    type={showPassword ? "text" : initValue.id}
                    name={"value"}
                    value={formState.value}
                    label={initValue.name}
                    setCurrValues={setFormState}
                    validationError={validationState?.value}
                    setValidationState={setValidationState}
                    adornment={initValue.id === "password" && <IconButton
                        onClick={() => setShowPassword(true)}
                        onMouseDown={() => setShowPassword(false)}
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>}
                />
                {initValue.id === "password" && <TextFieldValidation
                    type={showPassword ? "text" : initValue.id}
                    name={"passwordConfirmation"}
                    value={formState.passwordConfirmation}
                    label={"Повторите пароль"}
                    setCurrValues={setFormState}
                    validationError={validationState?.passwordConfirmation}
                    setValidationState={setValidationState}
                    adornment={initValue.id === "password" && <IconButton
                        onClick={() => setShowPassword(true)}
                        onMouseDown={() => setShowPassword(false)}
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>}
                />}
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    margin: "10px 0"
                }}>
                    <ButtonAdd width={250} text={"Отправить код верификации"} onClick={verificationHandle}></ButtonAdd>
                    <Tooltip sx={{height: "36px"}} arrow placement="top"
                             title={"Отправить код верификации на вашу почту, для подтверждения изменений"}>
                        <HelpOutlineIcon/>
                    </Tooltip>
                </Box>
                {codeIsSend && <TextFieldValidation
                    name={"verificationCode"}
                    value={formState.verificationCode}
                    label={"Введите код верификации"}
                    setCurrValues={setFormState}
                    validationError={validationState?.verificationCode}
                    setValidationState={setValidationState}
                />}
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Изменить"} onClick={handleValidationData} disabled={!codeIsSend}/>
            </DialogActions>
        </Dialog>
    );
}
export default ProfileModal