import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
} from "@mui/material";
import React, {useState} from "react";
import ButtonExit from "../../button/ButtonExit";
import WorkerTable from "../orders/organization/workerTable/WorkerTable";

const WorkersModal = ({open, onClose, workers, currentEditWorkersData, setCurrentEditWorkersData}) => {
    const [change, setChange] = useState(false)
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: workers?.length,
        searchData: ""
    })

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)

    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "800px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">Работники</DialogTitle>
            <DialogContent>
                <WorkerTable
                    data={workers} isModal={true} currentEditWorkersData={currentEditWorkersData}
                    setCurrentEditWorkersData={setCurrentEditWorkersData} setChange={setChange}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}/>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={() => onClose(change)}/>
            </DialogActions>
        </Dialog>
    );
}
export default WorkersModal