import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../Constants";
import CustomerEdgesTable from "../../../components/UI/table/customer-detales/eges/CustomerEdgesTable";
import OrderEdgeController from "../../../API/details/OrderEdgeController";

const CustomerRequestsEdges = ({samples, system, orderId}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [change, setChange] = useState(false);
    const [isError, setIsError] = useState(false);

    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'

    const [data, setData] = useState([]);


    const handleError = (error) => {
        setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }

    useEffect(() => {
        if (!system) {
            setIsLoading(true)
            OrderEdgeController.getAll(orderId)
                .then(function (response) {
                    const data = response?.data
                    setData(data);
                    setIsError(false)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => setIsLoading(false))
        } else {
            setIsLoading(false)
        }
    }, [change]);

    return (
        isLoading ?
            <Box sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> :
            <Box>
                <ModalAlert data={alertData} onClose={handleCloseAlert}/>
                <CustomerEdgesTable
                    samples={samples}
                    data={data}
                    isError={isError}
                    setData={setData}
                    enableEditing={true}
                    orderId={orderId}/>
            </Box>
    )
}
export default CustomerRequestsEdges
