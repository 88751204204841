import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Paper} from "@mui/material";

import {Link, useLocation, useNavigate} from "react-router-dom";
import RequestsCuttingService from "../../../../../API/orders/optimization/organization/RequestsCuttingService";
import ModalUpdateStatus from "../ModalUpdateStatus";
import OptimizationService from "../../../../../API/orders/optimization/organization/OptimizationService";
import {requestsCuttingStatuses} from "../../../../../data/RequestsCuttingState";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import FileService from "../../../../../API/orders/orders/organization/FileService";
import ModalAddDetailOrRequestsCuttingForFiles from "../../cutting/details/ModalAddDetailOrRequestsCuttingForFiles";
import ModalOpenCreatePlanOrOptimization from "../plans/ModalOpenCreatePlanOrOptimization";
import ModalSetOptimization from "./ModalSetOptimization";
import ModalConfirm from "../../../modal/ModalConfirm";
import ButtonCreate from "../../../button/ButtonCreate";
import ModalAddCustomerRequestCutting from "./ModalAddCustomerRequestCutting";
import CustomerRequestsCuttingService
    from "../../../../../API/orders/optimization/customer/CustomerRequestsCuttingService";
import MenuItem from "@mui/material/MenuItem";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAlert from "../../customer-detales/ModalAlert";
import ButtonSelectAction from "../../../button/ButtonSelectAction";
import {ModalSelectAction} from "../../../modal/ModalSelectAction";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import selectionTableUtil from "../../../../../utils/common/SelectionTableUtil";

const RequestsCuttingTable = ({
                                  getAll,
                                  optimizationId,
                                  isOptimization,
                                  isRedirectRequestsCutting,
                                  orderId,
                                  requestsCuttingPlan,
                                  planId,
                                  isOrder,
                                  isButtonAddRequestsCutting,
                                  isPlan,
                                  isRedirectOrder,
                                  nameOrder,
                                  idHeader,
                                  year,
                                  isRedirectOrders,
                                  isRedirectPlans,
                                  isRedirectOptimizations,
                                  isCreateOptimization,
                                  isCreateOptimizationCalculation,
                                  isDeleteOptimization,
                                  isAddOptimization,
                                  isSetForOptimization,
                                  page,
                                  hideOrder,
                                  hideWorkingCuttingMap,
                                  customer,
                                  isRedirectCalculationOptimizations,
                                  isRedirectCalculationCalculationWorkingCuttingMaps,
                                  isRedirectCalculationRequestsCuttings,
                                  customerOrderNumber,
                                  isCalculation,
                                  isButtonCreate,
                                  deleteRowBeforeAction
                              }) => {

        const pathname = useLocation().pathname
        const [customerRequestsCuttingModalOpen, setCustomerRequestsCuttingModalOpen] = useState(false);

        const [open, setOpen] = useState(false)
        const [isFullScreen, setIsFullScreen] = useState(false);
        const [data, setData] = useState([]);
        const [change, setChange] = useState(false);
        const [rowSelection, setRowSelection] = useState([]);

        const [updateModalOpen, setUpdateModalOpen] = useState(false);

        const [modalAddRequestsCuttingOpen, setModalAddRequestsCuttingOpen] = useState(false);
        const [calculationRequestsCutting, setCalculationRequestsCutting] = useState(false);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentAllowedStatuses, setCurrentAllowedStatuses] = useState(['ARCHIVE']);

        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [modalOpenCreateOptimization, setModalOpenCreateOptimization] = useState(false);
        const [newRowDataOptimization, setNewRowDataOptimization] = useState(null);
        const [setOptimizationModalOpen, setSetOptimizationModalOpen] = useState(false);
        const [isCalculationOptimization, setIsCalculationOptimization] = useState(null);
        const [showConfirmationModalDeleteOptimization, setShowConfirmationModalDeleteOptimization] = useState(false);

        const [showConfirmationModalSetForCalculation, setShowConfirmationModalSetForCalculation] = useState(false);
        const [tableOptions, setTableOptions] = useState({
            pageIndex: 0,
            pageSize: 20,
            totalElements: 0,
            searchData: ""
        })
        const tableOptionsHandle = (nextState) => {
            setTableOptions(nextState)
            if (nextState.pageSize !== tableOptions.pageSize) {
                EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
            }
        }

        const getStartPageSize = () => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const pageSize = pagesData[pathname]?.pageSize
            pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
        }
        const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)
        const [alertData, setAlertData] = useState({});
        useEffect(() => {
                getStartPageSize()
                const id = isOrder ? orderId : (requestsCuttingPlan ? planId : optimizationId);
                getAll(id, isCalculation).then(function (response) {
                    let data = response.data;
                    setData(data);
                    setTableOptions((prev) => ({...prev, totalElements: data.length}))
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }, [change]
        );
        const handleError = (error) => {
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }
        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.id;
                const service = customer ? CustomerRequestsCuttingService : RequestsCuttingService
                service.delete(id)
                    .then(function () {
                        setData(prev => prev.filter(el => el.id !== id))
                        setTableOptions((prev) => ({...prev, totalElements: data.length - 1}))
                    })
                    .catch(function (error) {
                        handleError(error);
                    })
                setCurrentEditRowData(null)
            },
            [data],
        );

        const handleUpdateRow = (materialId) => {
            CustomerRequestsCuttingService.edit(currentEditRowData.id, {
                "materialId": materialId,
                materialOrganizationOptionId: null
            })
                .then(function (response) {
                    setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                })
                .catch(function (error) {
                    handleError(error)
                })
            setCurrentEditRowData(null)
            setCustomerRequestsCuttingModalOpen(false)
        }

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentAllowedStatuses([...currentAllowedStatuses, row.original.requestsCuttingState]);
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleClose = () => {
            currentAllowedStatuses.splice(1, 1);
            setUpdateModalOpen(false)
        };

        const navigate = useNavigate();

        const submitCustomerRequestsCutting = (materialId) => {
            const api = customer ? CustomerRequestsCuttingService.add({
                "materialId": materialId,
                "orderId": orderId
            }) : RequestsCuttingService.add(materialId)
            api.then(function (response) {
                setData(prev => [...prev, response.data])
                setTableOptions((prev) => ({...prev, totalElements: data.length + 1}))
            })
                .catch(function (error) {
                    handleError(error);
                })
            setCustomerRequestsCuttingModalOpen(false)
        }
        const customerEditRequestsCutting = (row) => {
            setCurrentEditRowData(row)
            setCustomerRequestsCuttingModalOpen(true)
        };

        const createOptimizationByIds = () => {
            const requestsCuttingState = isCalculationOptimization ? 'FORMED_CALCULATION_OPTIMIZATION' : 'FORMED_OPTIMIZATION';
            OptimizationService.createOptimizationByIds(rowSelection, newRowDataOptimization)
                .then(function (response) {
                    const newData = deleteRowBeforeAction ? data.filter(item => {
                        return !rowSelection.includes(item.id)
                    }) : data.map(item => {
                        if (rowSelection.includes(item.id)) {
                            return {
                                ...item,
                                optimization: response.data,
                                requestsCuttingState: requestsCuttingState
                            };
                        } else {
                            return item;
                        }
                    })
                    deleteRowBeforeAction && setTableOptions((prev) => ({...prev, totalElements: data.length - 1}))
                    setData(newData);
                    handleCloseModalOpenCreateOptimization();
                    setChange(!change)
                })
                .catch(function (error) {
                    handleError(error);
                })
        };

        const deleteOptimizationByIds = () => {
            const requestsCuttingState = isCalculationOptimization ? 'CALCULATION' : 'EXPECTATION';
            RequestsCuttingService.deleteOptimizationByIds(rowSelection, isCalculation)
                .then(function () {
                    const newData = deleteRowBeforeAction ? data.filter(item => {
                        return !rowSelection.includes(item.id)
                    }) : data.map(item => {
                        if (rowSelection.includes(item.id)) {
                            return {
                                ...item,
                                optimization: null,
                                requestsCuttingState: requestsCuttingState
                            };
                        } else {
                            return item;
                        }
                    })
                    deleteRowBeforeAction && setTableOptions((prev) => ({...prev, totalElements: data.length - 1}))
                    setData(newData);
                    handleCloseDeleteOptimization();
                })
                .catch(function (error) {
                    handleError(error);
                })
        };

        const handleCloseModalOpenCreateOptimization = () => {
            setModalOpenCreateOptimization(false);
            setRowSelection([]);
        };

        const handleCloseDeleteOptimization = () => {
            setShowConfirmationModalDeleteOptimization(false);
            setRowSelection([]);
        };


        const handleCloseSetForCalculation = () => {
            setShowConfirmationModalSetForCalculation(false);
            setRowSelection([]);
        };

        const redirectRequestsCutting = () => {
            navigate('/organization/requests-cutting');
        };

        const redirectOrders = () => {
            navigate('/organization/orders');
        };

        const redirectPlans = () => {
            navigate('/organization/plans');
        };

        const redirectOptimizations = () => {
            navigate('/organization/optimizations');
        };

        const redirectOrder = () => {
            navigate('/organization/orders/' + orderId + '/' + nameOrder + '/' + idHeader + '/' + year);
        };

        const redirectCalculationWorkingCuttingMaps = () => {
            navigate('/organization/working-cutting-maps-calculation');
        };

        const redirectCalculationOptimizations = () => {
            navigate('/organization/optimizations-calculation');
        };


        const redirectCalculationRequestsCutting = () => {
            navigate('/organization/requests-cutting/Calculation');
        }

        const addAndCalculateRequestsCutting = () => {
            setCalculationRequestsCutting(true);
            setModalAddRequestsCuttingOpen(true)
        };

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'number',
                    header: 'Номер',
                    accessorKey: 'number',
                    minSize: 8,
                    maxSize: 8,
                },
                {
                    id: 'nominalQuantity',
                    header: 'Ном. кв.м.',
                    accessorKey: 'nominalQuantity',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell}) => cell.getValue() === null ? null : cell.getValue().toFixed(3),
                },
                {
                    accessorFn: (row) => {
                        if (row.dateOfReceipt === null) {
                        } else {
                            return new Date(row.dateOfReceipt)
                        }
                    },
                    id: 'date',
                    header: 'Дата поспупления',
                    sortingFn: 'date',
                    size: 10,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        const startDate = filterValue?.startDate;
                        const endDate = filterValue?.endDate;
                        if (startDate && endDate) {
                            return (
                                DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                                DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                            );
                        }
                        return true;
                    },
                    Filter: ({column}) => <DateRangeFilter
                        column={column}
                    />,
                },
                {
                    id: 'requestsCuttingState',
                    header: 'Статус',
                    accessorKey: 'requestsCuttingState',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({cell}) => requestsCuttingStatuses[cell.getValue()],
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                },
            ];

            if (customer) {
                baseColumns.splice(1, 0, {
                    id: 'materialCustomer',
                    header: 'материал',
                    accessorKey: 'materialName',
                    minSize: 150,
                    maxSize: 150,
                    Cell: ({cell, row}) => (
                        <li key={cell.getValue()}>
                            <Link
                                to={'/customer/orders/' + orderId + "/" + customerOrderNumber + '/requests-cutting/' + row.original.id + "/" + row.original.number + '/details?materialId=' + row.original.materialId}>
                                {cell.getValue() === null ? '' : cell.getValue()}
                            </Link>
                        </li>
                    ),
                });
            }

            if (!customer) {
                baseColumns.splice(1, 0, {
                    id: 'material',
                    header: 'материал',
                    accessorKey: 'materialOrganizationOption.name',
                    minSize: 150,
                    maxSize: 300,
                    Cell: ({cell, row}) => (
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/requests-cutting/detail/' + row.original.id + '/' + row.original.number}>
                                {cell.getValue() === null ? '' : cell.getValue()}
                            </Link>
                        </li>
                    ),
                });
            }

            if (!hideOrder) {
                baseColumns.splice(3, 0, {
                    id: 'orderNumber',
                    header: 'Номер заказа',
                    accessorKey: 'order.number',
                    minSize: 8,
                    maxSize: 8,
                });
            }

            if (!hideWorkingCuttingMap) {
                baseColumns.splice(2, 0, {
                    id: 'workingCuttingMap.number',
                    header: 'К. раскроя',
                    accessorKey: 'workingCuttingMap.number',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => (
                        cell.getValue() ? (
                            <li key={cell.getValue()}>
                                <Link
                                    to={'/organization/working-cutting-map/' + row.original.workingCuttingMap.id + '/' + row.original.materialOrganizationOption.name}>
                                    {cell.getValue()}
                                </Link>
                            </li>
                        ) : ''
                    ),
                });
            }

            if (isOptimization) {
                baseColumns.splice(3, 0, {
                    id: 'optimization',
                    header: 'Оптимизация',
                    accessorKey: 'optimization.number',
                    minSize: 150,
                    maxSize: 150,
                    Cell: ({cell, row}) => (
                        cell.getValue() ? (
                            <li key={cell.getValue()}>
                                <Link
                                    to={'/organization/requests-cutting/optimization/' + row.original.optimization.id + '/' + row.original.optimization.number}>
                                    {cell.getValue()}
                                </Link>
                            </li>
                        ) : ''
                    ),
                });
            }

            if (isPlan) {
                baseColumns.splice(4, 0, {
                    id: 'plan',
                    header: 'План',
                    accessorKey: 'plan.name',
                    minSize: 8,
                    maxSize: 8,
                    Cell: ({cell, row}) => (
                        cell.getValue() ? (
                            <li key={cell.getValue()}>
                                <Link
                                    to={'/organization/requests-cutting/plan/' + row.original.plan.id + '/' + row.original.plan.name}>
                                    {cell.getValue()}
                                </Link>
                            </li>
                        ) : ''
                    ),
                });
            }

            return baseColumns;
        }, [data, setData, isOptimization]);

        const menuItem = [
            {type: "delete", text: "Удалить", handler: handleDeleteRow},
            {type: "edit", text: "Изменить", handler: customerEditRequestsCutting}
        ]

        const modalActions = (() => {
            const actions = [];

            if (isCreateOptimizationCalculation) {
                actions.push({name: "Создать расчетную оптимизацию", type: "optimizationCalc"});
            }
            if (isCreateOptimization) {
                actions.push({name: "Создать оптимизацию", type: "optimization"});
            }
            if (isDeleteOptimization) {
                actions.push(
                    {name: "Удалить оптимизацию", type: "delete"},
                );
            }
            if (isAddOptimization) {
                actions.push(
                    {name: "Переместить в оптимизацию", type: "add"},
                );
            }
            return actions;
        })();

        const createOptimization = (optimizationState) => {
            const number = optimizationState === 'CALCULATION' ? "СOP" : "OP";
            const optimization = {
                id: null,
                number: number,
                optimizationState: optimizationState,
                optimized: null,
            }
            setNewRowDataOptimization(optimization);
            setIsCalculationOptimization(optimizationState === 'CALCULATION');
            setModalOpenCreateOptimization(true)
        };

        const handleSelectAction = (type) => {
            if (type === "optimizationCalc") {
                createOptimization('CALCULATION')
            } else if (type === "optimization") {
                createOptimization('EXPECTATION')
            } else if (type === "add") {
                setSetOptimizationModalOpen(true)
            } else if (type === "delete") {
                setShowConfirmationModalDeleteOptimization(true)
            } else if (type === "return") {
                setShowConfirmationModalSetForCalculation(true)
            }
            setOpen(false)
        }

        return (
            <div>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", gap: "10px", paddingBottom: "30px"}}
                >
                    {isButtonCreate && <ButtonCreate
                        text={"Добавить заявку на раскрой"}
                        width={270}
                        onClick={() => setCustomerRequestsCuttingModalOpen(true)}
                    />}
                    {isRedirectOrders && <ButtonCreate
                        text={"Заказы"}
                        icon={false}
                        width={70}
                        onClick={redirectOrders}
                    />}
                    {isRedirectPlans && <ButtonCreate
                        text={"Планы"}
                        icon={false}
                        width={70}
                        onClick={redirectPlans}
                    />}
                    {isRedirectRequestsCutting && <ButtonCreate
                        text={"Заявки на раскрой"}
                        icon={false}
                        width={160}
                        onClick={redirectRequestsCutting}
                    />}
                    {isRedirectOptimizations && <ButtonCreate
                        text={"Оптимизации"}
                        icon={false}
                        width={120}
                        onClick={redirectOptimizations}
                    />}
                    {isRedirectCalculationRequestsCuttings && <ButtonCreate
                        text={"Рас. заявки на раскрой"}
                        icon={false}
                        width={200}
                        onClick={redirectCalculationRequestsCutting}
                    />}
                    {isRedirectCalculationOptimizations && <ButtonCreate
                        text={" Рас. оптимизации"}
                        icon={false}
                        width={160}
                        onClick={redirectCalculationOptimizations}
                    />}
                </Box>
                <MaterialReactTable
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": {
                                padding: 0,
                                width: "100%",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& li": {
                                listStyleType: "none",
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }
                                }
                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem"
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            sx: () => ({
                                height: '36px',
                                backgroundColor: "white",

                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light,
                                    "& td:after": {
                                        display: "none",
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.light
                                    },
                                },
                            })
                        })
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        backgroundColor: "red",
                        sx: {
                            backgroundColor: "red",
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    onRowSelectionChange={onRowSelectionChange}
                    getRowId={(originalRow) => originalRow.id}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowSelection={!customer}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}

                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return (el.type === "delete" || customer) && <MenuItem
                            disabled={row.original.requestsCuttingState !== 'CALCULATION'}
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })}
                    localization={MRT_Localization_RU}
                    enableRowActions={true}
                    enableToolbarInternalActions={false}
                />
                {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>}
                {!customer && <ModalSelectAction
                    open={open}
                    reset={() => setOpen(false)}
                    submit={handleSelectAction}
                    modalActions={modalActions}
                />}
                <ModalUpdateStatus
                    title={'Обновить статус'}
                    data={data}
                    setData={setData}
                    open={updateModalOpen}
                    handleClose={handleClose}
                    currentEditRowId={currentEditRowId}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    currentAllowedStatuses={currentAllowedStatuses}
                    statusesList={requestsCuttingStatuses}
                    field={'requestsCuttingState'}
                    unableStatus={RequestsCuttingService.unableStatus}
                />
                <ModalOpenCreatePlanOrOptimization
                    label={'Название оптимизации'}
                    field={'number'}
                    values={newRowDataOptimization}
                    setValues={setNewRowDataOptimization}
                    title={'Создать оптимизацию'}
                    modalOpen={modalOpenCreateOptimization}
                    handleClose={() => setModalOpenCreateOptimization(false)}
                    handleSubmit={createOptimizationByIds}
                    change={change}
                    setChange={setChange}
                    isCalculationOptimization={isCalculationOptimization}
                />
                <ModalAddDetailOrRequestsCuttingForFiles
                    title={'Добавить заявки на раскрой из файлов'}
                    labelForm={'Загрузить заявки на раскрой'}
                    modalOpen={modalAddRequestsCuttingOpen}
                    handleClose={() => setModalAddRequestsCuttingOpen(false)}
                    requestsCuttingId={null}
                    change={change}
                    setChange={setChange}
                    upload={FileService.uploadRequestsCuttingFiles}
                    orderId={orderId}
                    requestsCutting={false}
                    calculationRequestsCutting={calculationRequestsCutting}
                />
                <ModalSetOptimization
                    open={setOptimizationModalOpen}
                    handleClose={() => setSetOptimizationModalOpen(false)}
                    selectedIds={rowSelection}
                    data={data}
                    setData={setData}
                    change={change}
                    handleError={handleError}
                    isCalculation={isCalculation}
                    setRowSelection={setRowSelection}
                    deleteRowBeforeAction={deleteRowBeforeAction}
                    setTableOptions={setTableOptions}
                />
                <ModalConfirm
                    title={"Вы действительно хотите удалить оптимизацию?"}
                    modalOpen={showConfirmationModalDeleteOptimization}
                    handleClose={handleCloseDeleteOptimization}
                    handleSubmit={deleteOptimizationByIds}
                    action={'Удалить'}
                />
                <ModalAddCustomerRequestCutting
                    title={currentEditRowData ? "Изменить заявку на раскрой" : "Добавить заявку на раскрой"}
                    initValue={currentEditRowData?.materialId}
                    open={customerRequestsCuttingModalOpen}
                    onClose={() => setCustomerRequestsCuttingModalOpen(false)}
                    handleSubmit={currentEditRowData ? handleUpdateRow : submitCustomerRequestsCutting}
                    handleError={handleError}
                    isCustomer={customer}
                    orderId={orderId}
                />
                <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                    return {...alert, open: false}
                })}/>
            </div>
        );
    }
;

export default RequestsCuttingTable;
