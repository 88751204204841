import React from 'react';
import {TextField} from "@mui/material";

const ChoiceTextFieldObjectWithEntityListSetSimpleValue = ({
                                                               entityList,
                                                               keyFilter,
                                                               label,
                                                               value,
                                                               setValue,
                                                               field,
                                                               disabled,
                                                           }) => {

    const filteredEntityList = Object.entries(entityList)
        .filter(([key, value]) => keyFilter?.includes(key))
        .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});

    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                value={value === null ? entityList[0] : value}
                name={field}
                onChange={(e) =>
                    setValue(e.target.value === "" ? entityList[0] : e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {Object.keys(filteredEntityList).map((statusKey) => (
                    <option key={statusKey} value={statusKey}>
                        {filteredEntityList[statusKey]}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default ChoiceTextFieldObjectWithEntityListSetSimpleValue;