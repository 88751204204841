import {Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Tooltip} from "@mui/material";
import TextFieldValidation from "../../../../components/UI/table/customer-detales/TextFieldValidation";
import ButtonExit from "../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../components/UI/button/ButtonAdd";
import React, {useEffect, useState} from "react";
import {personalization} from "../../../../data/Personalization";
import OrderDatePicker from "../../../../components/UI/datepicker/OrderDatePicker";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import AddressCustomerService from "../../../../API/customer/AddressCustomerService";
import AddressOrganizationService from "../../../../API/organization/AddressOrganizationService";

const PersonalizationModal = ({open, onClose, handleSubmit, initValue, handleError, organization, generalSettings}) => {
    const [formState, setFormState] = useState({"residentialAddress": "", "address": ""})
    const [validationState, setValidationState] = useState(initValue)
    const [addressData, setAddressData] = useState([])

    useEffect(() => {
        setFormState(initValue)
    }, [initValue]);

    useEffect(() => {
        if (open) {
            const api = organization ? AddressOrganizationService : AddressCustomerService
            api.getAll().then(function (response) {
                setAddressData(response.data);
            })
                .catch(function (error) {
                    handleError(error);
                });
        }
    }, [open]);

    const handleClose = () => {
        onClose()
        setFormState(initValue)
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}
        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState, handleClose)
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">Изменить</DialogTitle>
            <DialogContent sx={{padding: "13px 24px", minWidth: "350px"}}>
                {Object.entries(formState).map(([name, value]) => {
                    return name !== "organizationSettings" && name !== "id" && name !== "enabled" && (name === "birthday"
                        ? <OrderDatePicker
                            key={name}
                            label={personalization[name]}
                            date={value || ""}
                            setDate={(res) => setFormState((prev) => ({
                                ...prev,
                                birthday: dayjs(res).format('YYYY-MM-DD')
                            }))}/>
                        : name === "residentialAddress" || name === "address" ? <TextFieldValidation
                                select
                                key={name}
                                name={name}
                                value={value}
                                label={personalization[name]}
                                setCurrValues={setFormState}
                                validationError={validationState[name]}
                                setValidationState={setValidationState}
                            >
                                {addressData.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {`${option?.locality} ${option?.street} ${option?.houseNumber}`}
                                    </MenuItem>))}
                            </TextFieldValidation>
                            : <TextFieldValidation
                                key={name}
                                name={name}
                                type={name === "phoneNumber" ? "tel" : "text"}
                                value={value || ""}
                                disabled={name === "position"}
                                label={personalization[name]}
                                setCurrValues={setFormState}
                                required={false}
                            />)
                })}
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Изменить"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default PersonalizationModal