import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import ModalCreateIssuingEdge from "./ModalCreateIssuingEdge";
import ModalUpdateIssuingEdge from "./ModalDeleteOrdersInIssuingEdge";
import IssuingEdgeService from "../../../../../API/orders/prodaction.issueedge/issueedge/IssuingEdgeService";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";

const IssuingEdgesTable = ({data, setData, tableOptions, tableOptionsHandle, handleError}) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const [change, setChange] = useState(false);

    const createIssuingEdge = (ids) => {
        IssuingEdgeService.createByOrderIds(ids).then(function (response) {
            setData([...data, response.data]);
            setChange(!change)
            closeCreateModal();
        })
            .catch(function (error) {
                handleError(error);
            });
    };

    const deleteOrdersInIssuingEdge = (ids) => {
        IssuingEdgeService.deleteOrderByIds(currentEditRowId, ids).then(function (response) {
            setData(prev => prev.map(el => el.id === currentEditRowId ? response.data : el))
            closeUpdateModal();
        })
            .catch(function (error) {
                handleError(error);
            });
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: 'Номер',
                accessorKey: 'number',
                minSize: 8,
                maxSize: 8,
                Cell: ({cell, row}) => (
                    <Link
                        to={'/organization/issuing-edges/' + row.original.id + "/" + row.original.number}>
                        {cell.getValue() === null ? '' : cell.getValue()}
                    </Link>
                ),
            },
            {
                accessorFn: (row) => {
                    if (row.date === null) {
                    } else {
                        return new Date(row.date)
                    }
                },
                id: 'date',
                header: 'Дата поспупления',
                sortingFn: 'date',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'orders',
                header: 'Заказы',
                accessorKey: 'orders',
                minSize: 1200,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
                maxSize: 2,
            },
        ];

        return baseColumns;
    }, [data, setData]);

    const closeCreateModal = () => {
        setCreateModalOpen(false)
    };

    const closeUpdateModal = () => {
        setCurrentEditRowData(null);
        setCurrentEditRowId(null);
        setUpdateModalOpen(false)
    };

    const createIssuingEdgesTable = () => {
        setCreateModalOpen(true)
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setCurrentEditRowId(row.id);
            setUpdateModalOpen(true)
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleDeleteRow = useCallback(
        (row) => {
            IssuingEdgeService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );
    const menuItem = [
        {type: "edit", text: "Изменить", handler: handleUpdateModel},
        {type: "delete", text: "Удалить", handler: handleDeleteRow},
    ]

    return (
        <Box sx={{width: '1700px',}}>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
            >
                <ButtonCreate
                    text={"Создать выдачу материалов"}
                    width={270}
                    onClick={() => createIssuingEdgesTable()}
                />
            </Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '1700px',
                    }
                }}
                muiTableBodyCellProps={({cell, table}) => {
                    return ({
                        sx: () => ({
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',
                            },
                            "&:first-of-type": {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },

                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        })
                    })
                }}

                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem",
                        "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                            "minWidth": "100%"
                        }
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    sx: {
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                localization={MRT_Localization_RU}
            />
            <ModalCreateIssuingEdge
                open={createModalOpen}
                onClose={closeCreateModal}
                titleModal={"Создать выдачу материалов"}
                createIssuingEdge={createIssuingEdge}
                change={change}
                setChange={setChange}
                handleError={handleError}
            />
            <ModalUpdateIssuingEdge
                open={updateModalOpen}
                onClose={closeUpdateModal}
                issuingEdgesId={currentEditRowId}
                titleModal={"Удалить заказы из выдачи"}
                deleteOrdersInIssuingEdge={deleteOrdersInIssuingEdge}
                handleError={handleError}
            />
        </Box>
    );
};

export default IssuingEdgesTable;