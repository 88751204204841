import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import ModalCreateOrUpdateMaterialStripe from "./ModalCreateOrUpdateMaterialStripe";
import CellCheckboxAMPUTFixPrice from "../arrival/CellCheckboxAMPUTFixPrice";
import CellPriceWithCurrency from "../../../../сell/CellPriceWithCurrency";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import CellCheckbox from "../../../../сheckbox/CellCheckbox";
import SupplierMaterialUtil from "../../../../../../utils/warehouse/SupplierMaterialUtil";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import ButtonBack from "../../../../button/ButtonBack";

const MaterialStripeTable = ({
                                 id,
                                 materialId,
                                 data,
                                 setData,
                                 supplierList,
                                 isArchive,
                                 urlArchive,
                                 baseUnit,
                                 typeMaterial,
                                 setSupplierId,
                                 setCurrencyId,
                                 price,
                                 setPrice,
                                 supplierId,
                                 currencyId,
                                 isMin,
                                 allPrice,
                                 create,
                                 update,
                                 addArchive,
                                 isAll,
                                 currencyMap,
                                 tableOptions,
                                 tableOptionsHandle,
                                 handleError
                             }) => {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
    const [isNew, setIsNew] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const navigate = useNavigate();

    const redirectArchive = () => {
        navigate('/' + urlArchive + '/' + id + '/' + baseUnit + '/' + typeMaterial + '/' + materialId);
    };

    const redirectMaterial = () => {
        navigate((isAll ? '/all-materials/' : '/organization/materials/') + id + '/' + baseUnit + '/' + typeMaterial + '/' + materialId);
    };

    const handleAddStripe = () => {

        const basePrice = {
            id: null, currency: currencyMap.get(1), price: "",
        };

        setIsNew(true);

        const stripe = {
            id: null,
            supplier: null,
            basePrice: basePrice,
            fixPrice: false,
            length: 2800,
            width: 0,
            trimmingLeft: 20,
            trimmingRight: 20,
            trimmingTop: 20,
            trimmingBottom: 20,
            typeSupplierMaterial: isMin ? "BY_MIN_STRIPE" : "BY_FIXED_STRIPE",
        };

        setCurrentEditRowData(stripe);
        setCreateOrUpdateModalOpen(true);
    };

    const handleCloseCreateOrUpdate = () => {

        setCurrentEditRowData(null);
        setIsNew(null);
        setSupplierId(null);
        setCurrencyId(null);
        setPrice(null);
        setCreateOrUpdateModalOpen(false);
    };

    const handleSubmitCreateStripe = () => {

        const currentSupplierId = supplierId === null ? supplierList[0].id : supplierId;
        const currentCurrencyId = currencyId === null ? currencyMap.get(1).id : currencyId;

        create(currentEditRowData, id, currentSupplierId, currentCurrencyId, price).then(function (response) {
            let data = response.data;
            handleCreateNewRow(data)
            handleCloseCreateOrUpdate();
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    const handleCreateNewRow = (values) => {
        if (isMin) {
            SupplierMaterialUtil.deleteSupplierId(data, supplierId === null ? supplierList[0].id : supplierId);
            setData(data);
        } else {
            SupplierMaterialUtil.deleteSupplierIdWithWeightAndHeight(data,
                supplierId === null ? supplierList[0].id : supplierId,
                values.length, values.width);
        }

        data.push(values);
        setData([...data]);
    };

    const handleUpdateStripe = useCallback((row) => {
        setCurrentEditRowData(row);
        setCurrentEditRowId(row.index);
        setCreateOrUpdateModalOpen(true);
        setIsNew(false);
    }, [data, currentEditRowData, setCurrentEditRowData]);

    const handleSubmitUpdateStripe = () => {
        update(currentEditRowData).then(function (response) {
            let data = response.data;
            handleUpdate(data)
            handleCloseCreateOrUpdate();
        })
            .catch(function (error) {
                handleError(error);
            });
    }

    const handleUpdate = (values) => {
        data[currentEditRowId] = values;
        setData([...data]);
    };

    const sendToArchive = useCallback((row) => {
        addArchive(row.id)
            .then(function () {
                data.splice(row.index, 1);
                setData([...data]);
            })
            .catch(function (error) {
                handleError(error);
            })
    }, [data]);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Поставщик',
                accessorFn: (row) => row.supplier?.name,
                minSize: 150,
                Cell: ({cell, row}) => (<li key={cell.getValue()}>
                    <Link
                        to={'/suppliers/' + data[row.index].supplier?.id}>{cell.getValue()}</Link>
                </li>),
            },
            {
                header: 'Артикул поставщика',
                accessorKey: 'article',
                minSize: 150,
            },
            {
                id: 'length', header: 'Ширина', accessorKey: 'length', minSize: 15, maxSize: 15,
            },
            {
                id: 'width', header: 'Высота', accessorKey: 'width', minSize: 15, maxSize: 15,
            },
            {
                id: 'trimming',
                header: 'Отступы',
                accessorFn: (row) => row.materialPriceUnit?.trimmingLeft,
                minSize: 10,
                maxSize: 10,
                Cell: ({row}) => '['
                    + row.original.trimmingLeft + ', '
                    + row.original.trimmingRight + ', '
                    + row.original.trimmingTop + ', '
                    + row.original.trimmingBottom + ']',
            },
            {
                id: 'basePrice',
                header: 'Баз. цена',
                accessorFn: (row) => row.materialPriceUnit?.trimmingLeft,
                size: 8, Cell: ({row}) => {
                    const basePrice = row.original.basePrice || null;
                    return basePrice === null ? '' : (<CellCheckboxAMPUTFixPrice
                        price={basePrice}
                        fixPrice={row.original.fixPrice}
                        currencyMap={currencyMap}
                    />);
                }, minSize: 8, maxSize: 8,
            },
            {
                id: 'basePrice.dateTime',
                header: 'Дата',
                accessorFn: (row) => row.basePrice?.dateTime,
                minSize: 10,
                maxSize: 10,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '' : DateTimeUtil.dateTimeToString(value);
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter column={column}/>,
            },
        ];

        if (isArchive) {
            baseColumns.splice(0, 0, {
                id: 'current',
                header: 'Текущий',
                accessorKey: 'current',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            });
        }

        if (allPrice) {
            baseColumns.splice(7, 0, {
                id: 'lastRealPrice',
                header: 'Посл. реальная цена',
                accessorFn: (row) => row.lastRealPrice?.price,
                size: 8,
                Cell: ({row}) => <CellPriceWithCurrency
                    price={row.original.lastRealPrice}
                    currencyMap={currencyMap}
                />,
                minSize: 10,
                maxSize: 10,
            });
        }

        if (allPrice) {
            baseColumns.splice(8, 0, {
                id: 'averageRealPrice',
                header: 'Ср. реальная цена',
                accessorFn: (row) => row.averageRealPrice?.price,
                size: 8,
                Cell: ({row}) => <CellPriceWithCurrency
                    price={row.original.averageRealPrice}
                    currencyMap={currencyMap}
                />,
                minSize: 10,
                maxSize: 10,
            });
        }

        if (allPrice) {
            baseColumns.splice(9, 0, {
                id: 'maxRealPrice',
                header: 'Макс. реальная цена',
                accessorFn: (row) => row.maxRealPrice?.price,
                size: 8,
                Cell: ({row}) => <CellPriceWithCurrency
                    price={row.original.maxRealPrice}
                    currencyMap={currencyMap}
                />,
                minSize: 10,
                maxSize: 10,
            });
        }

        return baseColumns;

    }, [data, isArchive, currencyMap]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleUpdateStripe},
        {type: "archive", text: "В архив", handler: sendToArchive}
    ]
    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px", gap: "10px"}}
        >
            {!isArchive && <ButtonCreate
                text={"Добавить полосу поставщика"}
                width={300}
                onClick={handleAddStripe}
            />}
            {!isArchive && <ButtonCreate
                text={"Архив цен"}
                width={150}
                onClick={redirectArchive}
            />}
            {isArchive && <ButtonBack
                text={"Вернуться к материалу"}
                width={230}
                onClick={redirectMaterial}
            />}
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "100%",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data || []}
            rowHeight={13}
            columnSpacing={1}
            initialState={{
                columnVisibility: {
                    id: false,
                }, isFullScreen: false, density: "compact", pagination: {pageIndex: 0, pageSize: 20},
            }}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            rowCount={tableOptions.totalElements}
            enableEditing={!isArchive}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })}
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
        />
        {createOrUpdateModalOpen && <ModalCreateOrUpdateMaterialStripe
            open={createOrUpdateModalOpen}
            onClose={handleCloseCreateOrUpdate}
            title={isNew ? 'Добавить базовую цену поставщика' : 'Редактировать базовую цену поставщика'}
            values={currentEditRowData}
            setValues={setCurrentEditRowData}
            labelButtonSSubmit={isNew ? 'Добавить' : 'Обновить'}
            handleSubmit={isNew ? handleSubmitCreateStripe : handleSubmitUpdateStripe}
            supplierList={supplierList}
            setSupplierId={setSupplierId}
            currencyMap={currencyMap}
            setCurrencyId={setCurrencyId}
            price={price}
            setPrice={setPrice}
            isNew={isNew}
            allPrice={allPrice}
        />}
    </div>);
};
export default MaterialStripeTable;