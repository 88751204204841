import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_GlobalFilterTextField, MRT_ToggleFiltersButton} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import AttributeUtil from "../../../../../../utils/orders/AttributeUtil";
import OrderUtil from "../../../../../../utils/orders/OrderUtil";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import ModalConfirm from "../../../../modal/ModalConfirm";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import UpdateOrderModal from "./UpdateOrderModal";
import CreateNewOrderModal from "./CreateNewOrderModal";
import {orderStatus} from "../../../../../../data/OrderStatus";
import ButtonCreate from "../../../../button/ButtonCreate";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import selectionTableUtil from "../../../../../../utils/common/SelectionTableUtil";

const OrderTable = ({
                        data,
                        setData,
                        customerList,
                        nameList,
                        attributeList,
                        planningList,
                        isIssuingEdge,
                        change,
                        setChange,
                        forWriteOff,
                        archive,
                        rowSelection,
                        setRowSelection,
                        tableOptions,
                        tableOptionsHandle,
                        handleError,
                        enableRowSelection = true
                    }) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const [sendArchiveModalOpen, setSendArchiveModalOpen] = useState(false);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [dateOfReceipt, setDateOfReceipt] = useState(null);
    const [dateOfPlannedProduction, setDateOfPlannedProduction] = useState(null);
    const [plannedDateOfShipment, setPlannedDateOfShipment] = useState(null);
    const [manufactured, setManufactured] = useState(null);
    const [shipped, setShipped] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [defaultAttributeId, setDefaultAttributeId] = useState(null);
    const navigate = useNavigate();

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

    const onSubmit = (data, customerId, customerName, modelHeaderId, attributeId) => {
        OrderService.save(data, customerId, customerName, modelHeaderId, attributeId).then(function (response) {
            setData(prev => [...prev, response.data])
            tableOptionsHandle((prev) => ({...prev, totalElements: response?.data.length}))
        })
            .catch(function (error) {
                handleError(error)
            });
    };

    const handleUpdate = (values, modelHeaderId) => {
        OrderService.update(values, modelHeaderId).then(function (response) {
            setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
        })
            .catch(function (error) {
                handleError(error)
            });
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setDateOfReceipt(row.dateOfReceipt);
            setDateOfPlannedProduction(row.dateOfPlannedProduction)
            setPlannedDateOfShipment(row.plannedDateOfShipment)
            setManufactured(row.manufactured)
            setShipped(row.shipped)
            setCurrentEditRowId(row.id);
            setUpdateModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const sendToArchive = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setSendArchiveModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleSubmitSendToArchive = () => {
        OrderService.sendToArchive(currentEditRowData.id)
            .then(function () {
                data.splice(currentEditRowData.id, 1);
                setData([...data]);
                setCurrentEditRowData(null);
                setSendArchiveModalOpen(false);
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleCloseModalSendToArchive = () => {
        setSendArchiveModalOpen(false);
    };

    const handleDeleteRow = useCallback(
        (row) => {
            OrderService.delete(row.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== row.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    handleError(error)
                })
        },
        [data],
    );

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: '№ заказа',
                accessorKey: 'number',
                filterFn: 'contains',
                minSize: 250,
                Cell: ({cell, row}) => {
                    const headerValue = data[row.index].header; // Получаем значение header
                    let header = null
                    if (headerValue !== null) {
                        header = headerValue.id;
                    }
                    return isIssuingEdge ? cell.getValue() : <Link
                        to={'/organization/orders/' + data[row.index].id + '/' + cell.getValue() + '/' + header + '/' + data[row.index].year}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>
                },
            },
            {
                id: 'customer',
                header: 'Заказчик',
                accessorKey: 'customerName',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: customerList.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                minSize: 400,
            },
            {
                id: 'header',
                header: 'Найменование',
                accessorFn: (row) => row.header?.name,
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                maxSize: 5,
            },
            {
                id: 'plan',
                header: 'План',
                accessorFn: (row) => row.plan?.name,
                filterVariant: 'multi-select',
                filterSelectOptions: planningList,
                minSize: 6,
                maxSize: 6,
                Cell: ({cell, row}) => (
                    <>
                        {data[row.index]?.plan !== null && (
                            <Link
                                to={'/organization/plans/' + data[row.index]?.plan?.id}>{data[row.index]?.plan?.name}</Link>
                        )}
                    </>
                ),
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfPlannedProduction === null) {
                    } else {
                        return new Date(row.dateOfPlannedProduction)
                    }
                },
                id: 'dateOfPlannedProduction',
                header: 'План. пр.',
                sortingFn: 'datetime',
                maxSize: 25,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },

            {
                id: 'year',
                header: 'год                                                                     ',
                accessorKey: 'year',
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        if (!isIssuingEdge) {
            baseColumns.splice(2, 0, {
                id: 'numberCustomer',
                header: '№ заказчика',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(4, 0, {
                accessorFn: (originalRow) => new Date(originalRow.dateOfReceipt), //convert to date for sorting and filtering
                id: 'dateOfReceipt',
                header: 'Поступление',
                filterVariant: 'date-range',
                Cell: ({cell}) => cell.getValue().toLocaleDateString("en-GB"),
            });
        }

        //TODO починить фильтр
        if (!isIssuingEdge) {
            baseColumns.splice(5, 0, {
                id: 'status',
                header: 'Статус',
                accessorKey: 'status',
                Cell: ({cell}) => orderStatus[cell.getValue()],
                enableGrouping: true,
                maxSize: 25,
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(6, 0, {
                id: 'attributes',
                header: 'Атрибуты',
                accessorKey: 'attributes',
                filterVariant: 'multi-select',
                filterSelectOptions: attributeList,
                maxSize: 5,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkStatus(row.getValue(id), filterValue)
                },
                minSize: 20,
                Cell: ({cell, row}) => (
                    <Link
                        to={'/organization/orders/set-attribute/' + data[row.index].id + '/' + data[row.index].number + '/'
                            + data[row.index].header?.id + '/' + data[row.index].year}
                    >{data[row.index].attributes.length === 0 ? 'Не устанволен' : AttributeUtil.convertAttributes(cell.getValue())}</Link>
                ),
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(10, 0, {
                id: 'manufactured',
                header: 'Произв.',
                accessorFn: (row) => {
                    if (row.manufactured === null) {
                    } else {
                        return new Date(row.manufactured)
                    }
                },
                filterVariant: 'date-range',
                // sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(11, 0, {
                accessorFn: (row) => {
                    if (row.plannedDateOfShipment === null) {
                    } else {
                        return new Date(row.plannedDateOfShipment)
                    }
                },
                id: 'plannedDateOfShipment',
                header: 'План. отг.',
                sortingFn: 'datetime',
                maxSize: 25,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            });
        }

        if (!isIssuingEdge && (forWriteOff || archive)) {
            baseColumns.splice(11, 0, {
                accessorFn: (row) => {
                    if (row.shipped === null) {
                    } else {
                        return new Date(row.shipped)
                    }
                },
                id: 'shipped',
                header: 'Отгружено',
                sortingFn: 'datetime',
                maxSize: 25,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            });
        }

        return baseColumns;

    }, [data, setData, isIssuingEdge]);

    const menuItem = forWriteOff ? [
        {type: "archive", text: "Поместить в архив", handler: sendToArchive}
    ] : [{type: "edit", text: "Редактировать", handler: handleUpdateModel}, {
        type: "delete",
        text: "Удалить",
        handler: handleDeleteRow
    },]

    return (
        <Box sx={{maxWidth: "2500px"}}>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
            >
                <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>
                    {!isIssuingEdge && <ButtonCreate
                        text={"Планы"}
                        width={70}
                        icon={false}
                        onClick={redirectPlans}
                    />}
                    {!isIssuingEdge && !forWriteOff && <ButtonCreate
                        text={"Создать заказ"}
                        width={170}
                        onClick={() => setCreateModalOpen(true)}
                    />}
                </Box>
            </Box>
            <Box sx={{overflow: 'auto'}}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='ru'
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <MaterialReactTable
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: 0
                                }
                            }
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                p: '0px 0px 0px 10px',
                                m: '0px 0px 0px 0px',
                                border: "none",
                                "& td:after": {
                                    "&:hover": {
                                        backgroundColor: "red"
                                    }
                                },
                                "&:not(:last-of-type)": {
                                    borderRight: '1px solid #EAEBEA',

                                },
                                "&:first-of-type": {
                                    padding: 0,
                                    width: "50px",
                                    height: "36px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: (theme) => theme.palette.text.light,
                                    "& .MuiButtonBase-root": {
                                        borderRadius: "4px",
                                        width: "24px",
                                        height: "24px",
                                        opacity: 1,
                                        margin: 0,
                                        padding: 0,
                                        "&:hover": {
                                            backgroundColor: (theme) => theme.palette.primary.stroke,
                                        }
                                    }
                                },
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }

                                }
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            sx: {
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: "",
                                size: 3,
                                minSize: 3,
                                grow: false,
                            },
                            'mrt-row-select': {
                                size: "50px",
                                grow: false,
                            },
                        }}
                        columns={columns}
                        data={data}
                        onRowSelectionChange={onRowSelectionChange}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                        rowCount={tableOptions.totalElements}
                        enableRowSelection={enableRowSelection}
                        selectionPadding={1}
                        getRowId={(originalRow) => originalRow.id}
                        selectionCss={{
                            backgroundColor: 'lightblue',
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            borderColor: 'blue',
                            borderRadius: '4px',
                        }}
                        initialState={{
                            columnVisibility: {
                                id: false,
                                model: false,
                                year: false,
                            },
                            isFullScreen: false,
                            density: "compact",
                        }}
                        localization={MRT_Localization_RU}
                        enableRowActions={!isIssuingEdge}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={({table}) => (
                            <Box sx={{
                                width: "100%",
                                padding: 0,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItem: "center"
                            }}>
                                <MRT_ToggleFiltersButton table={table}/>
                            </Box>)}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: Object.keys(rowSelection)?.length !== 0 ? `calc(100vh - 600px)` : `calc(100vh - 520px)`,
                                width: "2500px"
                            }
                        }}
                        renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                            return <MenuItem
                                key={el.type}
                                disabled={el.type === "delete" && row.original.dateOfConstructorDocumentation}
                                onClick={() => {
                                    el.handler(row.original)
                                    closeMenu();
                                }}
                                sx={{
                                    margin: "0 8px",
                                    borderRadius: "4px",
                                    "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                                }}
                            >
                                {el.text}
                            </MenuItem>
                        })}
                    />
                </LocalizationProvider>
            </Box>
            <CreateNewOrderModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={onSubmit}
                customerList={customerList}
                defaultAttributeId={defaultAttributeId}
                setDefaultAttributeId={setDefaultAttributeId}
            />
            <UpdateOrderModal
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                onSubmit={handleUpdate}
                values={currentEditRowData}
                currentEditRowId={currentEditRowId}
                change={change}
                setChange={setChange}
                setValues={setCurrentEditRowData}
                dateOfReceipt={dateOfReceipt}
                setDateOfReceipt={setDateOfReceipt}
                dateOfPlannedProduction={dateOfPlannedProduction}
                setDateOfPlannedProduction={setDateOfPlannedProduction}
                plannedDateOfShipment={plannedDateOfShipment}
                setPlannedDateOfShipment={setPlannedDateOfShipment}
                manufactured={manufactured}
                setManufactured={setManufactured}
                shipped={shipped}
                setShipped={setShipped}
            />
            <ModalConfirm
                title={"Подтвердить поместить в архив"}
                modalOpen={sendArchiveModalOpen}
                handleClose={handleCloseModalSendToArchive}
                handleSubmit={handleSubmitSendToArchive}
                action={"Подтвердить"}
            />
        </Box>
    );
};

export default OrderTable;