import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import EntityList from "./EntityList";
import ButtonExit from "../../button/ButtonExit";
import ButtonAdd from "../../button/ButtonAdd";

const ModalUpdateStatus = ({
                               title,
                               data,
                               setData,
                               open,
                               handleClose,
                               values,
                               setValues,
                               currentEditRowId,
                               currentAllowedStatuses,
                               statusesList,
                               field,
                               unableStatus
                           }) => {

        const handleSubmit = () => {
            unableStatus(values.id, values[field],
            ).then(function () {
                setData(prev => prev.filter(el => el.id !== values.id || el.id === values.id && values[field] !== 'ARCHIVE').map(el => el.id === values.id ? values : el))
                handleClose();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle textAlign="center">{title}</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                        >
                            <EntityList
                                label="Статус"
                                values={values}
                                setValues={setValues}
                                field={field}
                                allowedStatuses={currentAllowedStatuses}
                                statusesList={statusesList}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <ButtonExit text={"Выйти"} onClick={handleClose}/>
                    <ButtonAdd text={"Обновить"} onClick={handleSubmit}/>
                </DialogActions>
            </Dialog>
        );
    }
;

export default ModalUpdateStatus;