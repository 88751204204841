import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import CloudOptionsMaterialModule from "../../../components/UI/modul/mainoptions/CloudOptionsMaterialModule";
import SupplierService from "../../../API/orders/warehouse/supplier/SupplierService";
import GroupMaterialService from "../../../API/orders/orders/organization/GroupMaterialService";
import MaterialOrganizationOptionService
    from "../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import CurrentOptionsMaterialModule from "../../../components/UI/modul/mainoptions/CurrentOptionsMaterialModule";
import UserGroupMaterialMaterialCloudService
    from "../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import UserManufacturerMaterialCloudService
    from "../../../API/material-cloud/user/UserManufacturerMaterialCloudService";
import UserMaterialMaterialCloudService from "../../../API/material-cloud/user/UserMaterialMaterialCloudService";
import AdminMaterialMaterialCloudService from "../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";
import {authorizedAdmin, authorizedOrganization, constantsErrorMessages} from "../../../Constants";
import JobMetricCloudModul from "../../../components/UI/table/cloud/material/JobMetricCloudModul";
import MaterialItemSettings from "../../common/settings/MaterialItemSettings";
import MaterialCloudUnits from "../../../API/material-cloud/common/MaterialCloudUnits";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {ButtonGroupTabBar} from "../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import MaterialWarehouseCloudMarketTable
    from "../../../components/UI/table/warehouse/material/materialwarehouse/MaterialWarehouseCloudMarketTable";
import CurrencyService from "../../../API/orders/warehouse/currency/AdminCurrencyService";
import MaterialTree from "../../../components/UI/tree/MaterialTree";
import ButtonExit from "../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../components/UI/button/ButtonAdd";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";

const AllMaterialItem = ({settings, settingsParams}) => {
        const profile = JSON.parse(localStorage.getItem("profile"))
        const isOrganization = profile?.userRoles.some(role => authorizedOrganization.includes(role.name))
        const isAdmin = profile?.userRoles.some(role => authorizedAdmin.includes(role.name))
        const tabCurrentOptionsVisible = profile?.userRoles.some(role => ['ROLE_ORGANIZATION_USER_SETTINGS_MATERIAL', "ROLE_ORGANIZATION_USER_SETTINGS", "ROLE_ORGANIZATION_SUPER_USER"].includes(role.name))

        const params = useParams();
        const location = useLocation();
        const isSettings = new URLSearchParams(location.search).size > 0;
        const [alertData, setAlertData] = useState({});

        const [data, setData] = useState(null);
        const [dataMOO, setDataMOO] = useState(null);
        const [change, setChange] = useState(null);
        const [group, setGroup] = useState(null);

        const [supplierList, setSupplierList] = useState(null);
        const [groupMaterialList, setGroupMaterialList] = useState(null);
        const [manufacturerList, setManufacturerList] = useState(null);
        const [unitsList, setUnitsList] = useState([])

        const [supplierMaterialCloudList, setSupplierMaterialCloudList] = useState(null);
        const [groupMaterialMaterialCloudList, setGroupMaterialMaterialCloudList] = useState(null);
        const [manufacturerMaterialCloudList, setManufacturerMaterialCloudList] = useState(null);

        const [supplierId, setSupplierId] = useState(null);
        const [manufacturerId, setManufacturerId] = useState(null);
        const [groupMaterialId, setGroupMaterialId] = useState(null);
        const [isDataMOO, setIsDataMOO] = useState(false);
        const [currencies, setCurrencies] = useState()
        const [modalSelectGroup, setModalSelectGroup] = useState(false)

        const {id, baseUnit, typeMaterial} = settings ? settingsParams : params;

        const handleError = (error) => {
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }
        useEffect(() => {
            getStartPageSize()
            MaterialCloudUnits.get().then(function (response) {
                setUnitsList(response.data);
            })
                .catch(function (error) {
                    handleError(error);
                });
            // UserSupplierMaterialCloudService.getAll().then(function (response) {
            //     setSupplierMaterialCloudList(response.data);
            // })
            //     .catch(function (error) {
            //         handleError(error);
            //     });
            CurrencyService.getAll()
                .then(function (response) {
                    const data = response.data;
                    setCurrencies(data)
                }).catch(function (error) {
                handleError(error)
            })
            UserGroupMaterialMaterialCloudService.getAll().then(function (response) {
                setGroupMaterialMaterialCloudList(response.data);

            })
                .catch(function (error) {
                    handleError(error);
                });
            UserManufacturerMaterialCloudService.getAll().then(function (response) {
                setManufacturerMaterialCloudList(response.data);
            })
                .catch(function (error) {
                    handleError(error);
                });

            !isAdmin && loadLists();
            UserMaterialMaterialCloudService.getWithAllPrices(id).then(function (response) {
                let data = response.data;
                setData(data)
                setGroup(data.groupMaterial)
            })
                .catch(function (error) {
                    handleError(error);
                });

            !isAdmin && MaterialOrganizationOptionService.getForCloudCatalog(id).then(function (response) {
                let data = response.data;
                setIsDataMOO(data !== null && data !== undefined && data !== '');
                setDataMOO(data);
            })
                .catch(function (error) {
                    handleError(error);
                });
            // UserSupplierMaterialCloudService.getAllCurrentWholeByMaterialId(id)
            //     .then(function (response) {
            //         setDataMaterialWholeData(response.data);
            //     })
            //     .catch(function (error) {
            //         handleError(error);
            //     });
            // UserSupplierMaterialCloudService.getAllCurrentByMaterialIdMinStripe(id)
            //     .then(function (response) {
            //         setDataMaterialMinStripeData(response.data);
            //     })
            //     .catch(function (error) {
            //         handleError(error);
            //     });
            // UserSupplierMaterialCloudService.getAllCurrentByMaterialIdFixedStripe(id)
            //     .then(function (response) {
            //         setDataMaterialFixedStripeData(response.data);
            //     })
            //     .catch(function (error) {
            //         handleError(error);
            //     });

        }, []);

        const [dataMaterialWholeData, setDataMaterialWholeData] = useState([]);
        const [dataMaterialMinStripeData, setDataMaterialMinStripeData] = useState([]);
        const [dataMaterialFixedStripeData, setDataMaterialFixedStripeData] = useState([]);


        const handleSubmitUpdateMainOptions = () => {


            const currentSupplierId = supplierId === null ? data.defaultSupplier.id : supplierId;
            const currentManufacturerId = manufacturerId == null ? data.manufacturer.id : manufacturerId;
            const currentGroupMaterialId = groupMaterialId == null ? data.groupMaterial.id : groupMaterialId;

            AdminMaterialMaterialCloudService.updateMainOptions(data, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
            ).then(function (response) {
                let data = response.data;
                handleUpdate(data)
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        const handleSubmitUpdateCurrentOptions = () => {

            const currentSupplierId = supplierId === null ? dataMOO.defaultSupplier.id : supplierId;
            const currentManufacturerId = manufacturerId == null ? dataMOO.manufacturer?.id : manufacturerId;
            const currentGroupMaterialId = groupMaterialId == null ? dataMOO.groupMaterial.id : groupMaterialId;

            MaterialOrganizationOptionService.updateCurrentOptions(dataMOO, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
            ).then(function (response) {
                let data = response.data;
                handleUpdateMOO(data)
            })
                .catch(function (error) {
                    handleError(error);
                });
        }


        const addMOOByMaterialId = (currentGroup) => {
            MaterialOrganizationOptionService.createByMaterial(id, currentGroup?.id).then(function (response) {
                let data = response.data;
                setIsDataMOO(data !== null && data !== undefined && data !== '');
                groupMaterialList.push(data.groupMaterial);
                setGroupMaterialId([...groupMaterialList]);
                setDataMOO(data)
                setModalSelectGroup(false)
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        const loadLists = () => {
            GroupMaterialService.getAllByOrganization().then(function (response) {
                setGroupMaterialList(response.data);

            })
                .catch(function (error) {
                    handleError(error);
                });
            SupplierService.get().then(function (response) {
                setSupplierList(response.data);

            })
                .catch(function (error) {
                    handleError(error);
                });

            UserManufacturerMaterialCloudService.getAll().then(function (response) {
                setManufacturerList(response.data);

            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        const handleUpdateMOO = (values) => {
            setIsDataMOO(values);
        };

        const handleUpdate = (values) => {
            setData(values);
        };

        const [tab, setTab] = useState(0);
        const getStartPageSize = () => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const savedTab = pagesData["/all-materials"]?.tab
            savedTab && setTab(savedTab)
            getTabsData(savedTab)
        }
        const handleChangeTab = (tab) => {
            setTab(tab)
            getTabsData(tab)
            EditLocalStoragePagesData(`/all-materials`, "tab", tab)
        }
        const getTabsData = (tab) => {
            const tabsData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const pageSize = tabsData[`/all-materials`]?.pageSize
        }
        const renderContent = () => {
            switch (tab) {
                case 0:
                    return <CloudOptionsMaterialModule
                        data={data}
                        setData={setData}
                        supplierList={supplierMaterialCloudList}
                        groupMaterialList={groupMaterialMaterialCloudList}
                        manufacturerList={manufacturerMaterialCloudList}
                        isSettings={isSettings}
                        handleSubmit={handleSubmitUpdateMainOptions}
                        setSupplierId={setSupplierId}
                        setManufacturerId={setManufacturerId}
                        setGroupMaterialId={setGroupMaterialId}
                        baseUnit={baseUnit}
                        changeOptionsDisabled={!isAdmin}
                    />
                case 1:
                    return <CurrentOptionsMaterialModule
                        data={dataMOO}
                        setData={setDataMOO}
                        supplierList={supplierList}
                        groupMaterialList={groupMaterialList}
                        manufacturerList={manufacturerList}
                        handleSubmit={handleSubmitUpdateCurrentOptions}
                        setSupplierId={setSupplierId}
                        setManufacturerId={setManufacturerId}
                        setGroupMaterialId={setGroupMaterialId}
                        baseUnit={baseUnit}
                        addMaterial={() => setModalSelectGroup(true)}
                        isDataMOO={isDataMOO}
                    />
                case 3:
                    return <JobMetricCloudModul
                        id={id}
                        handleError={handleError}
                    />
                case 4:
                    return <MaterialWarehouseCloudMarketTable
                        materialId={id}
                        currencies={currencies}
                        typeMaterial={typeMaterial}
                    />
            }
        }
        return (
            <div>
                {settings ? <MaterialItemSettings
                        data={data}
                        dataMOO={dataMOO}
                        setData={setData}
                        supplierList={supplierList}
                        groupMaterialList={groupMaterialList}
                        manufacturerList={manufacturerMaterialCloudList}
                        unitsList={unitsList}
                        handleSubmit={handleSubmitUpdateCurrentOptions}
                        setSupplierId={setSupplierId}
                        setManufacturerId={setManufacturerId}
                        setGroupMaterialId={setGroupMaterialId}
                        baseUnit={baseUnit}
                        isMaterialOrganizationOption={true}
                        isDataMOO={true}
                    /> :
                    <Box sx={{padding: "0 30px"}}>
                        <BreadcrumbsComponent
                            page={data?.name}
                            path={isSettings ? [{name: "Главная", href: "/"}, {
                                name: "Настройки",
                                href: "/settings"
                            }] : [{name: "Главная", href: "/"}]}/>
                        <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                            <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                                <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                                    {data?.name}
                                </Typography>
                            </Box>
                            <ButtonGroupTabBar activeTab={tab}>
                                <Button tabIndex={0} onClick={(e) => handleChangeTab(0)}>
                                    <CheckIcon/>Облако
                                </Button>
                                {tabCurrentOptionsVisible && <Button tabIndex={1} onClick={(e) => handleChangeTab(1)}>
                                    <CheckIcon/>Текущие
                                </Button>}
                                {isOrganization && <Button tabIndex={2} onClick={(e) => handleChangeTab(2)}>
                                    <CheckIcon/>Поставщики
                                </Button>}
                                <Button tabIndex={3} onClick={(e) => handleChangeTab(3)}>
                                    <CheckIcon/>Перечень работ
                                </Button>
                                <Button tabIndex={4} onClick={(e) => handleChangeTab(4)}>
                                    <CheckIcon/>Рынок остатков
                                </Button>
                            </ButtonGroupTabBar>
                            {renderContent()}
                        </Box>
                    </Box>
                }
                <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                    return {...alert, open: false}
                })}/>
                <ModalConfirm
                    modalOpen={modalSelectGroup}
                    handleSubmit={addMOOByMaterialId}
                    handleClose={() => setModalSelectGroup(false)}
                    handleError={handleError}
                    dataForGroup={{typeMaterial: data?.typeMaterial, subTypeMaterial: data?.subTypeMaterial}}
                />
            </div>
        );
    }
;
const ModalConfirm = ({modalOpen, handleClose, handleSubmit, handleError, dataForGroup}) => {
    const [currentGroup, setCurrentGroup] = useState(null)
    const [dataTree, setDataTree] = useState(null)

    useEffect(() => {
        modalOpen && GroupMaterialService.getAllByOrganization().then((response) => {
            const buildTreeGroups = GroupMaterialUtil.buildTreeGroups(response.data);
            setDataTree(buildTreeGroups);
        }).catch(function (error) {
            handleError(error)
        });

    }, [modalOpen]);

    return (
        <div>
            <Dialog open={modalOpen} onClose={handleClose} PaperProps={{
                style: {
                    height: "600px"
                }
            }}>
                <DialogTitle textAlign="center">Выбрать группу</DialogTitle>
                <DialogContent>
                    {dataTree && <MaterialTree
                        data={dataTree}
                        currentGroup={currentGroup}
                        setCurrentGroup={setCurrentGroup}
                        readOnly={true}
                    />}
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <ButtonExit text={"Отменить"} onClick={handleClose}/>
                        <ButtonAdd text={"Выбрать"} disabled={!currentGroup}
                                   onClick={() => handleSubmit(currentGroup)}/>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AllMaterialItem;