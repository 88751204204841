import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldObjectWithEntityList from "../../../../textfield/TextFieldObjectWithEntityList";
import CurrencyTextFieldObjectWithEntityList from "../../../../textfield/CurrencyTextFieldObjectWithEntityList";
import OrderDatePicker from "../../../../datepicker/OrderDatePicker";
import TextFieldWithSetValue from "../../../../textfield/TextFieldWithSetValue";
import ArrivalStateTextFieldObjectWithEntityList from "../../../../textfield/ArrivalStateTextFieldObjectWithEntityList";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import {Box} from "@mui/system";

const ModalUpdateOrAddMaterialArrival = ({
                                             open,
                                             handleClose,
                                             title,
                                             values,
                                             setValues,
                                             date,
                                             setDate,
                                             materialDeliveryDate,
                                             setMaterialDeliveryDate,
                                             currencyList,
                                             setCurrencyId,
                                             supplierList,
                                             setSupplierId,
                                             warehouseList,
                                             setWarehouseId,
                                             handleSubmit,
                                             isNew,
                                             isSupplier
                                         }) => {

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        {isSupplier && <TextFieldObjectWithEntityList
                            disabled={!isNew}
                            label="Поставщик"
                            values={values}
                            field={'supplier'}
                            setId={setSupplierId}
                            entityList={supplierList}
                        />}

                        <OrderDatePicker
                            label="Дата оформления"
                            date={date}
                            height={"44px"}
                            setDate={setDate}
                        />

                        <OrderDatePicker
                            label="Дата поставки"
                            date={materialDeliveryDate}
                            setDate={setMaterialDeliveryDate}
                        />

                        <CurrencyTextFieldObjectWithEntityList
                            disabled={!isNew}
                            label="Валюта прихода"
                            values={values}
                            field={'currencyArrival'}
                            setId={setCurrencyId}
                            entityList={currencyList}
                        />
                        <TextFieldObjectWithEntityList
                            label="Склад"
                            values={values}
                            field={'warehouse'}
                            setId={setWarehouseId}
                            entityList={warehouseList}
                        />
                        <ArrivalStateTextFieldObjectWithEntityList
                            label="Статус"
                            values={values}
                            setValues={setValues}
                            field={'arrivalState'}
                            excludeStatus={'ARCHIVE'}
                        />
                        <TextFieldWithSetValue
                            label="Коментарий"
                            values={values}
                            setValues={setValues}
                            field={'comment'}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={handleClose}/>
                <ButtonAdd text={isNew ? 'Создать' : 'Обновить'} onClick={handleSubmit}/>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUpdateOrAddMaterialArrival;