import axios from "axios";
import {apiVersionUtility, gatewayUrl, utility} from "../../../../Constants";

const restCurrencyNationalBankRestUrl = gatewayUrl + utility + apiVersionUtility + '/admin/currencies-national-bank'

export default class AdminCurrencyNationalBankService {

    static async getByDate(date) {
        return axios.get(restCurrencyNationalBankRestUrl + '/by-date?date=' + date, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async add(data) {
        return await axios.post(restCurrencyNationalBankRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        return await axios.put(`${restCurrencyNationalBankRestUrl}/${id}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        return await axios.delete(`${restCurrencyNationalBankRestUrl}/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
}