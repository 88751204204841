import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import BasicModelsTable from "./BasicModelsTable";
import BasicModelService from "../../../../../API/model-estimate/model/BasicModelService";

export const UpdateOrAddBasicModelsModule = ({
                                                 open,
                                                 onClose,
                                                 setBasicModelId,
                                                 setModalModelsOpen,
                                                 orderIdHeader,
                                                 handleError
                                             }) => {
    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 10,
        totalElements: 0,
        searchData: ""
    })
    useEffect(() => {
            open && BasicModelService.getAllUnableByHeader(orderIdHeader).then(function (response) {
                let data = response.data;
                setData(data);
                data.forEach((row) => {
                    unable[row.id] = row.enabled;
                });
            })
                .catch(function (error) {
                    handleError(error);
                });

        }, [open]
    )
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }

    return (<div>
        <Dialog open={open} fullWidth={true} maxWidth='lg'>
            <DialogTitle textAlign="center">Выбрать базовую модель</DialogTitle>
            <DialogContent>
                <BasicModelsTable
                    modal={true}
                    setBasicModelId={setBasicModelId}
                    setModalModelsOpen={setModalModelsOpen}
                    data={data}
                    setData={setData}
                    unable={unable}
                    setUnable={setUnable}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                />
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти</Button>
            </DialogActions>
        </Dialog>
    </div>);
};