import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import ButtonArrow from "./button/ButtonArrow";
import React from "react";
import {newsType} from "../../data/NewsType";
import {stockType} from "../../data/StockType";

const Card = ({
                  isHome,
                  clickable,
                  onClickHandle,
                  element,
                  picturePosition = "right",
                  width = 531,
                  height = 220,
                  imgHeight,
                  imgWidth,
                  padding = "24px"
              }) => {
    return <Box
        onClick={clickable ? onClickHandle : undefined}
        sx={{
            cursor: clickable && !element.src && "pointer",
            display: 'flex',
            flexDirection: picturePosition === "top" ? "column-reverse" : picturePosition === "left" ? "row-reverse" : "row",
            backgroundColor: (theme) => theme.palette.background.grey,
            height,
            width,
            borderRadius: "16px",
            border: (theme) => `transparent 1px solid`,
            padding: padding,
            justifyContent: "space-between",
            boxSizing: "border-box",
            position: "relative",

            "&:hover": clickable && {
                backgroundColor: "white",
                border: (theme) => `${theme.palette.primary.main} 1px solid`,
                "& .MuiButton-root": {
                    backgroundColor: "white",
                    boxShadow: "12px 12px 22px 0px rgba(9, 88, 146, 0.12),0px 12px 24px 0px rgba(44, 112, 163, 0.22)",
                    "& svg": {
                        fill: "#3B8BEC"
                    },
                },
                "& .typeNews": {backgroundColor: (theme) => theme.palette.background.grey}
            }
        }}>
        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: "space-between",
            paddingRight: picturePosition === "right" ? "15px" : 0,
            paddingLeft: picturePosition === "left" ? "15px" : 0,
        }}>
            <Box sx={{
                display: 'flex',
                gap: "8px",
                flexDirection: "column",
            }}>
                {!isHome && !element.src &&
                    <Box sx={{display: "flex", alignItems: "center", gap: "16px", flexWrap: "wrap"}}>
                        <Box className={"typeNews"} sx={{
                            borderRadius: "28px",
                            backgroundColor: "white"
                        }}>
                            <Typography sx={{
                                fontSize: "14px",
                                color: (theme) => theme.palette.text.main,
                                lineHeight: 1,
                                padding: "4px 8px",
                            }}>{newsType[element.type] || stockType[element.type]}</Typography>
                        </Box>
                        <Typography sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: 1.2,
                            color: (theme) => theme.palette.text.light,
                        }}>
                            {element.date}
                        </Typography>
                    </Box>}
                <Typography sx={{
                    textWrap: "wrap",
                    fontSize: isHome ? "24px" : "20px",
                    width: `calc(100% - 30px)`,
                    fontWeight: 700,
                    lineHeight: 1.2,
                    color: (theme) => theme.palette.text.main,
                    WebkitLineClamp: 2,
                    display: !isHome && "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                }}>
                    {element.text}
                </Typography>
                {element?.description && <Typography sx={{
                    width: `calc(100% - 30px)`,
                    textWrap: "wrap",
                    fontSize: "16px",
                    lineHeight: 1.2,
                    color: (theme) => theme.palette.text.main,
                    paddingRight: "30px",
                    WebkitLineClamp: 2,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                }}>
                    {element?.description}
                </Typography>}
            </Box>

            {clickable && <ButtonArrow isHome={isHome}/>}
        </Box>
        {element.src
            ? <iframe
                width={imgWidth}
                height={imgHeight}
                src={element.src}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen="allowfullscreen"/>
            : <Box sx={{
                flexShrink: 0,
                borderRadius: "8px",
                minHeight: '126px',
                height: imgHeight,
                minWidth: "150px",
                width: imgWidth,
                backgroundImage: `url(${element.img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}/>}
    </Box>
}
export default Card