import React from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";

const RequestsCuttingPlan = () => {

    const params = useParams();

    const planId = params.planId;
    const name = params.name;

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Заявки на раскрой плана ${name}`}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заказы", href: "/organization/orders"},
                    {name: "Планы", href: "organization/plans/"},
                ]}/>
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>
                Заявки на раскрой плана {name}
            </Typography>
            <RequestsCuttingTable
                getAll={RequestsCuttingService.getAllWithMaterialByPlanId}
                isOptimization={true}
                isRedirectRequestsCutting={true}
                requestsCuttingPlan={true}
                planId={planId}
                isOrder={false}
                isButtonAddRequestsCutting={false}
                isSetForOptimization={true}
                isAddOptimization={true}
                isDeleteOptimization={true}
                isCreateOptimization={true}
            />
        </Box>
    );
};

export default RequestsCuttingPlan;