export const requestsCuttingStatuses = {
    "CALCULATION": "Расчетная",
    "EXPECTATION": "Ожидание",
    "FORMED_CALCULATION_OPTIMIZATION": "Сформирована расчет. оптим.",
    "CREATED_CALCULATION_CUTTING_MAPS": "Создана расчетная карта раскроя",
    "FORMED_OPTIMIZATION": "Сформирована оптим.",
    "OPTIMIZED_CALCULATION": "Оптимизирована для расчета",
    "OPTIMIZED": "Оптимизирована",
    "CREATED_CUTTING_MAPS": "Создана карта раскроя",
    "FOR_CUTTING": "Кроить",
    "CUTTING_STARTED": "Начат раскрой",
    "CUT_OUT": "Раскроены",
    "ARCHIVE": "Архив"
}