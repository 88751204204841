import React, {useEffect, useState} from 'react';
import {Box, Button} from "@mui/material";
import DetailTable from "../../../../components/UI/table/cutting/details/DetailTable";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DetailService from "../../../../API/orders/cutting/detail/DetailService";
import MaterialWarehouseTable
    from "../../../../components/UI/table/warehouse/material/materialwarehouse/MaterialWarehouseTable";
import MaterialWarehouseService from "../../../../API/orders/warehouse/material/MaterialWarehouseService";
import Typography from "@mui/material/Typography";
import ReservationTableCuttingItem
    from "../../../../components/UI/table/warehouse/reservation/ReservationTableCuttingItem";
import WorkingCuttingMapService from "../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";
import * as Dimensions from "../../../../data/Dimensions";
import SupplierService from "../../../../API/orders/warehouse/supplier/SupplierService";
import CuttingModule from "../../../../components/UI/modul/CuttingModule";
import SettingsCuttingModule from "../../../../components/UI/modul/cuttingmodule/SettingsCuttingModule";
import MaterialOrganizationOptionService
    from "../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import SettingsCutOffCuttingModule from "../../../../components/UI/modul/cuttingmodule/SettingsCutOffCuttingModule";
import ReservationService from "../../../../API/orders/reservation/MaterialReserveService";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";

const WorkingCuttingMapItem = () => {
        const pathname = useLocation().pathname
        const [data, setData] = useState([]);
        const [alertData, setAlertData] = useState({});
        const [dataMaterialWarehouse, setDataMaterialWarehouse] = useState([]);
        const [unableCutMaterialWarehouse, setUnableCutMaterialWarehouse] = useState({});

        const [dataMaterialWarehouseCuttingMap, setDataMaterialWarehouseCuttingMap] = useState([]);

        const [isLoadingCuttingMap, setIsLoadingCuttingMap] = useState(false);

        const [images, setImages] = useState([]);
        const [currentPage, setCurrentPage] = useState(1);
        const [drawEdge, setDrawEdge] = useState(true);

        const [changeCuttingMap, setChangeCuttingMap] = useState(true);
        const [dataMaterialReserve, setDataMaterialReserve] = useState([]);

        const [unableCut, setUnableCut] = useState({});
        const [unableTurn, setUnableTurn] = useState({});
        const [selectedIds, setSelectedIds] = useState([]);
        const [modalStatusOpen, setModalStatusOpen] = useState(false);
        const [status, setStatus] = useState(true);
        const [typeStatus, setTypeStatus] = useState(null);
        const [tab, setTab] = useState(3);
        const [change, setChange] = useState(false);
        const [isError, setIsError] = useState(false);
        const [state, setState] = useState(null);
        const [cancelReserveButton, setCancelReserveButton] = useState(null);
        const [approveButton, setApproveButton] = useState(null);
        const [cutButton, setCutButton] = useState(null);
        const [currentOrdersCuttingMapDataObjectDetails, setCurrentOrdersCuttingMapDataObjectDetails] = useState([])
        const [notUsedDetails, setNotUsedDetails] = useState([])
        const [currentOrdersCuttingMapDataObjectReserve, setCurrentOrdersCuttingMapDataObjectReserve] = useState([])

        const [supplierList, setSupplierList] = useState(null);
        const [supplierId, setSupplierId] = useState(null);
        const [defaultSupplierId, setDefaultSupplierId] = useState(null);
        const [minBalanceUpToWholeSheet, setMinBalanceUpToWholeSheet] = useState(null);
        const [material, setMaterial] = useState(null);
        const [cutDefault, setCutDefault] = useState(null);

        const [trimmingLeft, setTrimmingLeft] = useState(null);
        const [trimmingRight, setTrimmingRight] = useState(null);
        const [trimmingBottom, setTrimmingBottom] = useState(null);
        const [trimmingTop, setTrimmingTop] = useState(null);

        const [trimmingLeftCutOff, setTrimmingLeftCutOff] = useState(null);
        const [trimmingRightCutOff, setTrimmingRightCutOff] = useState(null);
        const [trimmingBottomCutOff, setTrimmingBottomCutOff] = useState(null);
        const [trimmingTopCutOff, setTrimmingTopCutOff] = useState(null);

        const profile = JSON.parse(localStorage.getItem("profile"))
        const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
        const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);
        const [isCalculation, setIsCalculation] = useState(false);
        const [isArchive, setIsArchive] = useState(false);
        const [isCalculationArchive, setIsCalculationArchive] = useState(false);

        const [baseIndents, setBaseIndents] = useState({
            trimmingLeft: 20,
            trimmingRight: 20,
            trimmingTop: 20,
            trimmingBottom: 20,
        });

        const [tempBaseIndents, setTempBaseIndents] = useState({
            trimmingLeft: 20,
            trimmingRight: 20,
            trimmingTop: 20,
            trimmingBottom: 20,
        });

        const params = useParams();

        const workingCuttingMapId = params.workingCuttingMapId;
        const nameMaterial = params.nameMaterial;

        const handleError = (error) => {
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }
        const notUsedDetailsIsEmpty = () => {
            return !(Array.isArray(notUsedDetails) && notUsedDetails.length === 0);
        };

        useEffect(() => {
            getStartPageSize()
            DetailService.getAllWorkingCuttingMapId(workingCuttingMapId).then(function (response) {
                let data = response.data;
                setData(data);
                data.forEach((row) => {
                    unableCut[row.id] = row.cut;
                    unableTurn[row.id] = row.turn;
                });
            })
                .catch(function (error) {
                    handleError(error);
                });

            SupplierService.get().then(function (response) {
                setSupplierList(response.data);
            })
                .catch(function (error) {
                    handleError(error);
                });
            MaterialOrganizationOptionService.getWithDefaultSupplier(nameMaterial).then(function (response) {
                const data = response.data;
                setMaterial(data);
                setCutDefault(data.cutDefault);
                setDefaultSupplierId(data.defaultSupplier.id);
                setSupplierId(data.defaultSupplier.id)
                setMinBalanceUpToWholeSheet(data.minBalanceUpToWholeSheet);

                const trimmingLeft = data.trimmingLeft;
                const trimmingRight = data.trimmingRight;
                const trimmingBottom = data.trimmingBottom;
                const trimmingTop = data.trimmingTop;

                const trimmingLeftCutOff = data.trimmingLeftCutOff;
                const trimmingRightCutOff = data.trimmingRightCutOff;
                const trimmingBottomCutOff = data.trimmingBottomCutOff;
                const trimmingTopCutOff = data.trimmingTopCutOff;

                const currentTrimmingLeftCutOff = trimmingLeftCutOff ? trimmingLeftCutOff : 10;
                const currentTrimmingRightCutOff = trimmingRightCutOff ? trimmingRightCutOff : 10;
                const currentTrimmingBottomCutOff = trimmingBottomCutOff ? trimmingBottomCutOff : 10;
                const currentTrimmingTopCutOff = trimmingTopCutOff ? trimmingTopCutOff : 10;

                const trimming = {
                    trimmingLeft: trimmingLeft,
                    trimmingRight: trimmingRight,
                    trimmingTop: trimmingTop,
                    trimmingBottom: trimmingBottom
                }
                setBaseIndents(trimming);

                setTrimmingLeft(trimmingLeft);
                setTrimmingRight(trimmingRight);
                setTrimmingBottom(trimmingBottom);
                setTrimmingTop(trimmingTop);

                setTrimmingLeftCutOff(currentTrimmingLeftCutOff);
                setTrimmingRightCutOff(currentTrimmingRightCutOff);
                setTrimmingBottomCutOff(currentTrimmingBottomCutOff);
                setTrimmingTopCutOff(currentTrimmingTopCutOff);
            })
                .catch(function (error) {
                    handleError(error);
                });
        }, []);

        useEffect(() => {

                MaterialWarehouseService.getAllByMaterialName(nameMaterial).then(function (response) {
                    const data = response.data;
                    setDataMaterialWarehouse(data);
                    data.forEach((row) => {
                        unableCutMaterialWarehouse[row.id] = row.materialPriceUnit.cut;
                    });
                })
                    .catch(function (error) {
                        handleError(error);
                    });

                WorkingCuttingMapService.getState(workingCuttingMapId).then(function (response) {
                    const data = response.data;
                    setIsCalculation(data === 'CALCULATION' || data === 'CALCULATION_RESERVES_CANCEL' || data === 'CALCULATION_RESERVES_CREATED' || data === 'ARCHIVE_CALCULATION');
                    setIsArchive(data === 'ARCHIVE');
                    setIsCalculationArchive(data === 'ARCHIVE_CALCULATION');
                    setState(data);
                    setApproveButton(data === 'RESERVES_CREATED');
                    setCutButton(data === 'EXPECTATION' || data === 'MAPS_CANCEL' || data === 'CALCULATION'
                        || data === 'RESERVES_CANCEL' || data === 'CALCULATION_RESERVES_CANCEL');
                    setCancelReserveButton(data === 'RESERVES_CREATED' || data === 'CALCULATION_RESERVES_CREATED');
                })
                    .catch(function (error) {
                        handleError(error);
                    });

                DetailService.getAllNotUsedDetailsWorkingCuttingMapId(workingCuttingMapId).then(function (response) {
                    const data = response.data;
                    setNotUsedDetails(data);
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }, [changeCuttingMap]
        );

        useEffect(() => {

                setIsLoadingCuttingMap(true)
                WorkingCuttingMapService.getAllWorkingCuttingMapId(workingCuttingMapId, drawEdge)
                    .then(function (response) {
                        setIsLoadingCuttingMap(false)
                        setDataMaterialWarehouseCuttingMap(response.data)
                        const images = response.data.map((item) => {
                            const image = new Image();
                            image.src = 'data:image/png;base64,' + item.base64Image;
                            return image;
                        });
                        setImages(images);
                    })
                    .catch(function (error) {
                        handleError(error);
                        setIsLoadingCuttingMap(false)
                    })
                ReservationService.getAllByWorkingCuttingMapId(workingCuttingMapId).then(function (response) {
                    const data = response.data;
                    setDataMaterialReserve(data);
                })
                    .catch(function (error) {
                        handleError(error);
                    });

            },
            [changeCuttingMap]
        );

        useEffect(() => {
            if (dataMaterialWarehouseCuttingMap.length !== 0) {
                const currentOrdersCuttingMapDataObjectId = dataMaterialWarehouseCuttingMap[currentPage - 1].id
                DetailService.getAllByCuttingMapDataObjectId(currentOrdersCuttingMapDataObjectId).then(function (response) {
                    const data = response.data;
                    setCurrentOrdersCuttingMapDataObjectDetails(data);
                })
                    .catch(function (error) {
                        handleError(error);
                    });
                ReservationService.getByOrdersCuttingMapDataObjectId(currentOrdersCuttingMapDataObjectId).then(function (response) {
                    const data = response.data;
                    const array = [];
                    if (data !== '') {
                        array.push(data)
                        setCurrentOrdersCuttingMapDataObjectReserve(array);
                    }
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }
        }, [currentPage, dataMaterialWarehouseCuttingMap, changeCuttingMap]);

        const navigate = useNavigate();

        const approve = async () => {
            setIsLoadingCuttingMap(true)
            WorkingCuttingMapService.approveCuttingMap(workingCuttingMapId)
                .then(function () {
                    setIsLoadingCuttingMap(false)
                    setChangeCuttingMap(!changeCuttingMap)
                })
                .catch(function (error) {
                    handleError(error);
                    setIsLoadingCuttingMap(false)
                })
        }

        const cancelReserve = async () => {
            setIsLoadingCuttingMap(true)
            WorkingCuttingMapService.cancelReserve(workingCuttingMapId)
                .then(function () {
                    setIsLoadingCuttingMap(false);
                    setChangeCuttingMap(!changeCuttingMap);
                    setCurrentOrdersCuttingMapDataObjectDetails([]);
                    setCurrentOrdersCuttingMapDataObjectReserve([]);
                })
                .catch(function (error) {
                    handleError(error);
                    setIsLoadingCuttingMap(false)
                })
        }

        const cancel = async () => {
            setIsLoadingCuttingMap(true)
            WorkingCuttingMapService.cancelCuttingMap(workingCuttingMapId)
                .then(function () {
                    setIsLoadingCuttingMap(false);
                    setChangeCuttingMap(!changeCuttingMap);
                    setCurrentOrdersCuttingMapDataObjectDetails([]);
                    setCurrentOrdersCuttingMapDataObjectReserve([]);
                })
                .catch(function (error) {
                    handleError(error);
                    setIsLoadingCuttingMap(false)
                })
        }

        const [fitToStripFirst, setFitToStripFirst] = useState(false);
        const [fitToStripSecond, setFitToStripSecond] = useState(false);
        const [dimensions, setDimensions] = useState(Object.keys(Dimensions.dimensions)[0]);
        const [combiningDrops, setCombiningDrops] = useState(false);
        const [cutDropDetail, setCutDropDetail] = useState(false);
        const [cutFitToStripFirstDropDetail, setCutFitToStripFirstDropDetail] = useState(false);
        const [cutFitToStripSecondDropDetail, setCutFitToStripSecondDropDetail] = useState(false);
        const [cutCombiningDrops, setCutCombiningDrops] = useState(false);
        const [takeAccountDimensions, setTakeAccountDimensions] = useState(true);
        const [cutWastes, setCutWastes] = useState(false);
        const [cutCutOffs, setCutCutOffs] = useState(false);
        const [cutWeight, setCutWeight] = useState(4.2);
        const [minACutOff, setMinACutOff] = useState(464);
        const [minBCutOff, setMinBCutOff] = useState(64);
        const [limitNumberTurns, setLimitNumberTurns] = useState(2);
        const [rotatePartInsideStrip, setRotatePartInsideStrip] = useState(false);
        const [cutOnlyCutOffs, setCutOnlyCutOffs] = useState(false);
        const [cutOnlyWarehouse, setCutOnlyWarehouse] = useState(false);

        const cutting = async () => {
            setIsLoadingCuttingMap(true)

            const currentSupplierId = supplierId === null ? defaultSupplierId : supplierId;

            WorkingCuttingMapService.cut(workingCuttingMapId, cutWeight, minACutOff, minBCutOff, rotatePartInsideStrip, fitToStripFirst, fitToStripSecond, dimensions, combiningDrops,
                cutDropDetail, cutFitToStripFirstDropDetail, cutFitToStripSecondDropDetail,
                cutCombiningDrops, takeAccountDimensions, cutWastes, cutCutOffs, limitNumberTurns, cutOnlyCutOffs, cutOnlyWarehouse, currentSupplierId, minBalanceUpToWholeSheet,
                cutDefault, trimmingLeft, trimmingRight, trimmingBottom, trimmingTop, trimmingLeftCutOff, trimmingRightCutOff, trimmingBottomCutOff, trimmingTopCutOff).then(function () {
                setIsLoadingCuttingMap(false)
                setChangeCuttingMap(!changeCuttingMap)
            })
                .catch(function (error) {
                    handleError(error);
                    setIsLoadingCuttingMap(false)
                })
        }
        const getStartPageSize = () => {
            const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
            const savedTab = pagesData[pathname]?.tab
            savedTab && setTab(savedTab)
            // getTabsData(savedTab, true)
        }
        const handleChangeTab = (tab) => {
            setTab(tab)
            EditLocalStoragePagesData(pathname, "tab", tab)
        }
        const generalTabComponents = [
            {
                tabIndex: 1, label: "Материалы",
                component: <> <Typography
                    sx={{
                        marginBottom: '3px',
                    }}
                    variant="body1" component="h3">
                    {nameMaterial}
                </Typography>
                    <MaterialWarehouseTable
                        data={dataMaterialWarehouse}
                        setData={setDataMaterialWarehouse}
                        nameMaterial={nameMaterial}
                        isPriceInCurrencyArrival={false}
                        isPriceInCurrencyAlternative={false}
                        isPriceInCurrencyBase={false}
                        isWarehouse={true}
                        baseIndents={baseIndents}
                        tempBaseIndents={tempBaseIndents}
                        setTempBaseIndents={setTempBaseIndents}
                        unableCut={unableCutMaterialWarehouse}
                        setUnableCut={setUnableCutMaterialWarehouse}
                        isAddCutOffButton={true}
                        isIndentsButton={true}
                        isActions={true}
                        isColumnCut={true}
                        isColumnReserve={true}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                    /></>
            },
            {
                tabIndex: 2, label: "Детали",
                component: <DetailTable
                    data={data}
                    setData={setData}
                    unableCut={unableCut}
                    unableTurn={unableTurn}
                    setUnableCut={setUnableCut}
                    setUnableTurn={setUnableTurn}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    modalStatusOpen={modalStatusOpen}
                    setModalStatusOpen={setModalStatusOpen}
                    status={status}
                    setStatus={setStatus}
                    typeStatus={typeStatus}
                    setTypeStatus={setTypeStatus}
                    isColumnOrder={true}
                    warehouse={'orders'}
                    expanded={true}
                    pageSize={100}
                    change={change}
                    setChange={setChange}
                    isError={isError}
                    isColumnCut={true}
                    isColumnTurn={true}
                    enableRowSelection={true}
                    enableEditing={true}
                    isButtonSetStatus={true}
                    hideQuantity={true}
                />
            },
            {
                tabIndex: 3, label: "Карты раскроя",
                component: <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        p: '0.5rem',
                        flexWrap: 'wrap',
                        flexDirection: 'column'
                    }}
                >
                    <CuttingModule
                        images={images}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        isLoading={isLoadingCuttingMap}
                        changeCuttingMap={changeCuttingMap}
                        setChangeCuttingMap={setChangeCuttingMap}
                        fitToStripFirst={fitToStripFirst}
                        setFitToStripFirst={setFitToStripFirst}
                        fitToStripSecond={fitToStripSecond}
                        setFitToStripSecond={setFitToStripSecond}
                        dimensions={dimensions}
                        setDimensions={setDimensions}
                        combiningDrops={combiningDrops}
                        setCombiningDrops={setCombiningDrops}
                        cutDropDetail={cutDropDetail}
                        setCutDropDetail={setCutDropDetail}
                        cutFitToStripFirstDropDetail={cutFitToStripFirstDropDetail}
                        setCutFitToStripFirstDropDetail={setCutFitToStripFirstDropDetail}
                        cutFitToStripSecondDropDetail={cutFitToStripSecondDropDetail}
                        setCutFitToStripSecondDropDetail={setCutFitToStripSecondDropDetail}
                        cutCombiningDrops={cutCombiningDrops}
                        setCutCombiningDrops={setCutCombiningDrops}
                        takeAccountDimensions={takeAccountDimensions}
                        setTakeAccountDimensions={setTakeAccountDimensions}
                        cutWastes={cutWastes}
                        setCutWastes={setCutWastes}
                        cutCutOffs={cutCutOffs}
                        setCutCutOffs={setCutCutOffs}
                        cutOnlyCutOffs={cutOnlyCutOffs}
                        setCutOnlyCutOffs={setCutOnlyCutOffs}
                        cutOnlyWarehouse={cutOnlyWarehouse}
                        setCutOnlyWarehouse={setCutOnlyWarehouse}
                    />
                    <Box sx={{
                        marginRight: '10px'
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                paddingTop: '0.5rem',
                                paddingBottom: '0.5rem',
                                flexWrap: 'wrap',
                            }}
                        >
                            {cutButton &&
                                <Button sx={{marginRight: '0px'}} variant="contained" color="secondary"
                                        onClick={cutting}>Раскроить</Button>}
                            {cancelReserveButton &&
                                <Button sx={{marginRight: '0px'}} variant="contained" color="secondary"
                                        onClick={cancelReserve}>Отменить резервы</Button>}
                            {approveButton &&
                                <Button sx={{marginRight: '0px'}} variant="contained" color="success"
                                        onClick={approve}>Отправить на закупку</Button>}
                            {state === 'MAPS_APPROVED' &&
                                <Button sx={{marginRight: '10px'}} variant="contained" color="secondary"
                                        onClick={cancel}>Отменить карты</Button>}
                        </Box>
                        {/*<Box
                    sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}
                >
                    {state === 'MAPS_APPROVED' &&
                        <Button sx={{marginRight: '0px'}} variant="contained" color="secondary"
                            onClick={cutting}>Перекроить текущий лист</Button>}
                    {state === 'MAPS_APPROVED' &&
                        <Button sx={{marginRight: '10px'}} variant="contained" color="secondary"
                            onClick={cutting}>Перезаписать текущий лист</Button>}
                </Box>*/}
                    </Box>
                    <Typography
                        sx={{
                            marginBottom: '3px',
                        }}
                        variant="body1" component="h3">
                        Резерв текущей карты
                    </Typography>
                    <ReservationTableCuttingItem
                        pageSize={100}
                        enableGrouping={false}
                        columnGrouping={['articleNumber']}
                        isColumnMaterialWarehouse={true}
                        expanded={false}
                        data={currentOrdersCuttingMapDataObjectReserve}
                        setData={setCurrentOrdersCuttingMapDataObjectReserve}
                        nameMaterial={nameMaterial}
                        isCalculation={isCalculation}
                    />
                    <Typography
                        sx={{
                            marginBottom: '3px',
                        }}
                        variant="body1" component="h3">
                        Детали текущей карты
                    </Typography>
                    <DetailTable
                        data={currentOrdersCuttingMapDataObjectDetails}
                        setData={setCurrentOrdersCuttingMapDataObjectDetails}
                        unableCut={unableCut}
                        unableTurn={unableTurn}
                        setUnableCut={setUnableCut}
                        setUnableTurn={setUnableTurn}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        modalStatusOpen={modalStatusOpen}
                        setModalStatusOpen={setModalStatusOpen}
                        status={status}
                        setStatus={setStatus}
                        typeStatus={typeStatus}
                        setTypeStatus={setTypeStatus}
                        isColumnOrder={true}
                        warehouse={'orders'}
                        expanded={true}
                        pageSize={100}
                        change={change}
                        setChange={setChange}
                        isError={isError}
                        hideQuantity={true}
                    />
                </Box>
            }, {
                tabIndex: 4, label: "Настройки",
                component: <SettingsCuttingModule
                    material={material}
                    setSupplierId={setSupplierId}
                    supplierId={supplierId}
                    supplierList={supplierList}
                    cutDefault={cutDefault}
                    setCutDefault={setCutDefault}
                    setDrawEdge={setDrawEdge}
                    drawEdge={drawEdge}
                    limitNumberTurns={limitNumberTurns}
                    setLimitNumberTurns={setLimitNumberTurns}
                    rotatePartInsideStrip={rotatePartInsideStrip}
                    setRotatePartInsideStrip={setRotatePartInsideStrip}
                    cutWeight={cutWeight}
                    setCutWeight={setCutWeight}
                    trimmingLeft={trimmingLeft}
                    setTrimmingLeft={setTrimmingLeft}
                    trimmingRight={trimmingRight}
                    setTrimmingRight={setTrimmingRight}
                    trimmingBottom={trimmingBottom}
                    setTrimmingBottom={setTrimmingBottom}
                    trimmingTop={trimmingTop}
                    setTrimmingTop={setTrimmingTop}
                    minBalanceUpToWholeSheet={minBalanceUpToWholeSheet}
                    setMinBalanceUpToWholeSheet={setMinBalanceUpToWholeSheet}
                    minACutOff={minACutOff}
                    setMinACutOff={setMinACutOff}
                    minBCutOff={minBCutOff}
                    setMinBCutOff={setMinBCutOff}
                />
            }, {
                tabIndex: 5, label: "Настройки обрезков",
                component: <> <Typography
                    sx={{
                        marginBottom: '3px',
                    }}
                    variant="body1" component="h3">
                    Настройки получаемых обрезков после раскроя
                </Typography>
                    <SettingsCutOffCuttingModule
                        trimmingLeftCutOff={trimmingLeftCutOff}
                        setTrimmingLeftCutOff={setTrimmingLeftCutOff}
                        trimmingRightCutOff={trimmingRightCutOff}
                        setTrimmingRightCutOff={setTrimmingRightCutOff}
                        trimmingTopCutOff={trimmingTopCutOff}
                        setTrimmingTopCutOff={setTrimmingTopCutOff}
                        trimmingBottomCutOff={trimmingBottomCutOff}
                        setTrimmingBottomCutOff={setTrimmingBottomCutOff}
                    /></>
            }, {
                tabIndex: 6, label: "Неиспользованные детали",
                component: <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        p: '0.5rem',
                        flexWrap: 'wrap',
                        flexDirection: 'column'
                    }}
                >
                    <DetailTable
                        data={notUsedDetails}
                        setData={setNotUsedDetails}
                        unableCut={unableCut}
                        unableTurn={unableTurn}
                        setUnableCut={setUnableCut}
                        setUnableTurn={setUnableTurn}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        modalStatusOpen={modalStatusOpen}
                        setModalStatusOpen={setModalStatusOpen}
                        status={status}
                        setStatus={setStatus}
                        typeStatus={typeStatus}
                        setTypeStatus={setTypeStatus}
                        isColumnOrder={true}
                        warehouse={'orders'}
                        expanded={true}
                        pageSize={100}
                        change={change}
                        setChange={setChange}
                        isError={isError}
                    />
                </Box>
            }, {
                tabIndex: 7, label: "Резервы",
                component: <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        p: '0.5rem',
                        flexWrap: 'wrap',
                        flexDirection: 'column'
                    }}
                >
                    <ReservationTableCuttingItem
                        pageSize={100}
                        enableGrouping={false}
                        columnGrouping={['articleNumber']}
                        isColumnMaterialWarehouse={true}
                        expanded={false}
                        data={dataMaterialReserve}
                        setData={setDataMaterialReserve}
                        nameMaterial={nameMaterial}
                        isCalculation={isCalculation}
                    />
                </Box>
            },]
        return (
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={`Карта раскроя ${nameMaterial}`}
                    path={[
                        {name: "Главная", href: "/"},
                    ]}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                            Карта раскроя {nameMaterial}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', gap: "10px"}}>
                        {!isCalculation && <ButtonCreate
                            text={"Заявки на раскрой"}
                            width={160}
                            icon={false}
                            onClick={() => navigate('/organization/requests-cutting')}
                        />}
                        {isCalculation && <ButtonCreate
                            text={"Рас. заявки на раскрой"}
                            width={200}
                            icon={false}
                            onClick={() => navigate('/organization/requests-cutting/Calculation')}
                        />}
                        {!isCalculation && <ButtonCreate
                            text={"Оптимизации"}
                            width={120}
                            icon={false}
                            onClick={() => navigate('/organization/optimizations')}
                        />}
                        {isCalculation && <ButtonCreate
                            text={"Рас.оптимизации"}
                            width={150}
                            icon={false}
                            onClick={() => navigate('/organization/optimizations-calculation')}
                        />}
                        {!isCalculation && <ButtonCreate
                            text={"Планы"}
                            width={70}
                            icon={false}
                            onClick={() => navigate('/organization/plans')}
                        />}
                        {!isCalculation && <ButtonCreate
                            text={"Карты раскроя"}
                            width={130}
                            icon={false}
                            onClick={() => navigate('/organization/working-cutting-maps')}
                        />}
                        {isCalculation && <ButtonCreate
                            text={"Рас. карты раскроя"}
                            width={170}
                            icon={false}
                            onClick={() => navigate('/organization/working-cutting-maps-calculation')}
                        />}
                    </Box>
                    <ButtonGroupTabBar activeTab={tab}>
                        {generalTabComponents.map(({tabIndex, label}, index) => {
                            const buttonVisible = ((tabIndex === 1 || tabIndex === 4 || tabIndex === 5) && !(isArchive || isCalculationArchive))
                                || (tabIndex === 2 && !(isCalculationArchive)) || tabIndex === 3 || tabIndex === 7 || (tabIndex === 6 && notUsedDetailsIsEmpty())
                            return buttonVisible &&
                                <Button key={tabIndex} tabIndex={tabIndex} onClick={() => handleChangeTab(tabIndex)}>
                                    <CheckIcon/>
                                    {label}
                                </Button>
                        })}
                    </ButtonGroupTabBar>
                    {generalTabComponents.find(component => component.tabIndex === tab)?.component}
                </Box>
                <ModalAlert
                    data={alertData}
                    onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                />
            </Box>
        );
    }
;

export default WorkingCuttingMapItem;
