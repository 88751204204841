import React, {useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import ModalConfirm from "../../../../modal/ModalConfirm";
import {estimateStatuses} from "../../../../../../data/EstimateState";
import MenuItem from "@mui/material/MenuItem";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import ButtonCreate from "../../../../button/ButtonCreate";

const OrderCloudTable = ({
                             data,
                             setData,
                             tableOptions,
                             tableOptionsHandle,
                             handleError,
                             archive
                         }) => {
    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const handleSubmitSetPurchase = async () => {
        EstimateService.confirmExecution(currentRow.id)
            .then(function () {
                setSetPurchaseModalOpen(false)
                setData(prev => prev.filter(el => el.id !== currentRow.id))
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'numberCustomer',
                header: 'Номер заказа',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                minSize: 300,
                Cell: ({cell, row}) => (
                    <Link
                        to={'/organization/orders-cloud/' + data[row.index].id + '/' + data[row.index].numberCustomer}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>

                ),
            },
            {
                id: 'customerName',
                header: 'Заказчик',
                accessorKey: 'customerName',
                filterFn: 'contains',
                minSize: 300,
            },
            {
                id: 'calculationTotalCostBasePrice',
                header: `Себестоимость:`,
                accessorKey: 'estimate.calculationTotalCostBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
            },
            {
                id: 'baseTotalPrice',
                header: `Продажа`,
                accessorKey: 'estimate.baseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
            },
            {
                id: 'profitCalculationBaseTotalPrice',
                header: `Прибыль:`,
                accessorKey: 'estimate.profitCalculationBaseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
            },
            {
                id: 'calculationGrossMargin',
                header: `Валовая маржа`,
                accessorKey: 'estimate.calculationGrossMargin',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimate.estimateState',
                minSize: 250,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfCreate === null) {
                    } else {
                        return new Date(row.dateOfCreate)
                    }
                },
                id: 'dateOfCreate',
                header: 'Дата расчета',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfPerformance === null || row.dateOfPerformance === undefined) {
                        return null;
                    } else {
                        return new Date(row.dateOfPerformance);
                    }
                },
                id: 'dateOfPerformance',
                header: 'Дата изготовления',
                sortingFn: 'dateOfPerformance',
                size: 10,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    if (value) {
                        return value.toLocaleDateString();
                    }
                    return '';
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'year',
                header: 'год                                                                     ',
                accessorKey: 'year',
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        return baseColumns;

    }, [data, setData]);

    const handleConfirm = (row) => {
        setCurrentRow(row)
        setSetPurchaseModalOpen(true)
    }
    const menuItem = [
        {type: "confirm", text: "Подтвердить исполнение", handler: handleConfirm},
    ]
    const navigate = useNavigate();

    return (
        <Box sx={{width: '2100px',}}>
            {!archive && <Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
            >
                <ButtonCreate
                    text={"Архив"}
                    width={70}
                    icon={false}
                    onClick={() => navigate('/organization/orders-cloud-archive')}
                />
            </Box>}
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '2100px',
                    }
                }}
                muiTableBodyCellProps={({cell, table}) => {
                    return ({
                        sx: () => ({
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',
                            },
                            "&": cell.column.id === "requests_cutting" && {
                                width: 'calc(100% - 1px)',
                                padding: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            },
                            "&:first-of-type": {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },

                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }

                            }
                        })
                    })
                }}

                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem",
                        "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                            "minWidth": "100%"
                        }
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    sx: {
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                enableRowActions={!archive}
                enableToolbarInternalActions={false}
                columns={columns}
                data={data}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                initialState={{
                    columnVisibility: {
                        id: false,
                        model: false,
                        year: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
            />
            <ModalConfirm
                title={"Подтвердить исполнение?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={() => setSetPurchaseModalOpen(false)}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
        </Box>
    );
};

export default OrderCloudTable;