import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {modelParameterUnit} from "../../../../../data/ModelParameterUnit";
import {Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper} from "@mui/material";
import Typography from "@mui/material/Typography";
import BasicModelEstimateTableItem from "../estimate/BasicModelEstimateTableItem";
import JobMetricOrganizationTable from "../../prodaction/jobmetricorganization/JobMetricOrganizationTable";
import TextFieldValidation from "../../customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import ButtonBack from "../../../button/ButtonBack";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";
import ModelProductionCostsService from "../../../../../API/model-estimate/estimate/ModelProductionCostsService";
import ButtonCreate from "../../../button/ButtonCreate";

const ModelProductionCosts = ({
                                  data,
                                  setData,
                                  tableOptions,
                                  tableOptionsHandle,
                                  materials,
                                  listUnits,
                                  handleError,
                                  calcData
                              }) => {
    const materialWithWork = materials.filter(el => el.jobMetricOrderOrganizations.length > 0)
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);

    const columns = useMemo(
        () => [
            {
                id: "nameMaterial",
                header: 'Материал',
                accessorKey: 'nameMaterial',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                header: 'Найменование операции',
                accessorKey: 'nameOperations',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                header: 'Параметр с модели',
                accessorKey: 'modelParameterUnit',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
                Cell: ({cell}) => modelParameterUnit[cell.getValue()],
            },
            {
                header: 'Ед.изм.',
                accessorKey: 'unit',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'Объем в заказе',
                accessorKey: 'orderQuantity',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 50,
            },
            {
                header: 'Коментарий',
                accessorKey: 'note',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 50,
            },
        ],
        [data, setData],
    );
    const handleSubmit = (materialEstimateUnitId, jobMetricModelOrganizationId, value, onClose) => {
        ModelProductionCostsService.create(jobMetricModelOrganizationId, value)
            .then(function (response) {
                const newData = materials.map(material => {
                    if (material.id === materialEstimateUnitId) {
                        const jobMetricOrderOrganizations = material.jobMetricOrderOrganizations.map(job => job.id === jobMetricModelOrganizationId ? {
                                ...job,
                                productionCosts: [...job?.productionCosts, response.data]
                            }
                            : job)
                        return {...material, jobMetricOrderOrganizations}
                    } else {
                        return material
                    }
                })
                calcData(newData)
                onClose()
            }).catch(function (error) {
            handleError(error);
        })
    }

    const handleUpdate = (value, onClose) => {
        ModelProductionCostsService.update(currentEditRowData.id, value)
            .then(function (response) {
                const newData = materials.map(material => {
                    if (material.id === currentEditRowData.material) {
                        const jobMetricOrderOrganizations = material.jobMetricOrderOrganizations.map(job => {
                            if (job.id === currentEditRowData.job) {
                                const productionCosts = job?.productionCosts.map(el => el.id === currentEditRowData.id ? response.data : el)
                                return {
                                    ...job,
                                    productionCosts: productionCosts
                                }
                            } else {
                                return job
                            }
                        })
                        return {...material, jobMetricOrderOrganizations}
                    } else {
                        return material
                    }
                })
                calcData(newData)
                onClose(false)
            }).catch(function (error) {
            handleError(error);
        })
    }
    const handleDeleteRow = useCallback(
        (row) => {
            ModelProductionCostsService.delete(row.id)
                .then(function () {
                    const newData = materials.map(material => {
                        if (material.id === row.material) {
                            const jobMetricOrderOrganizations = material.jobMetricOrderOrganizations.map(job => {
                                if (job.id === row.job) {
                                    const productionCosts = job?.productionCosts.filter(el => el.id !== row.id)
                                    return {
                                        ...job,
                                        productionCosts: productionCosts
                                    }
                                } else {
                                    return job
                                }
                            })
                            return {...material, jobMetricOrderOrganizations}
                        } else {
                            return material
                        }
                    })
                    calcData(newData)
                    setCreateModalOpen(false)
                })
                .catch(function (error) {
                    handleError(error);
                })
        },
        [data],
    );
    const handleUpdateModel = (row) => {
        setCurrentEditRowData(row);
        setCreateModalOpen(true);
    }

    const menuItem = [
        {type: "edit", text: "Изменить", handler: handleUpdateModel},
        {type: "delete", text: "Удалить", handler: handleDeleteRow},
    ]

    return (
        <div>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", gap: "10px", paddingBottom: "30px"}}
            >
                <ButtonCreate
                    text={"Создать"}
                    width={130}
                    onClick={() => setCreateModalOpen(true)}
                />
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        },
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,

                            },
                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        backgroundColor: (theme) => theme.palette.primary.light,
                    }
                }}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                rowCount={tableOptions.totalElements}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableExpanding={true}
                enableGrouping={true}
                initialState={{
                    expanded: true,
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    grouping: ['nameMaterial'],
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                getRowId={(originalRow) => originalRow.id}
                localization={MRT_Localization_RU}
            />
            {createModalOpen && <CreateProductionCostsModal
                open={createModalOpen}
                handleClose={() => setCreateModalOpen(false)}
                handleSubmit={currentEditRowData ? handleUpdate : handleSubmit}
                handleError={handleError}
                materials={materialWithWork}
                listUnits={listUnits}
                initValue={currentEditRowData}
            />}
        </div>
    );
};
const CreateProductionCostsModal = ({
                                        open,
                                        handleClose,
                                        handleSubmit,
                                        handleError,
                                        materials,
                                        listUnits,
                                        initValue
                                    }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [selectedString, setSelectedString] = useState({})
    const [dataJobMetricOrganization, setDataJobMetricOrganization] = useState([]);
    const [selectedJob, setSelectedJob] = useState({})
    const [formState, setFormState] = useState({
        "unit": listUnits[0].name,
        "note": ""
    })

    useEffect(() => {
        if (initValue) {
            setFormState({
                "unit": initValue.unit,
                "note": initValue.note
            })
            setActiveStep(2)
        }
    }, [])

    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 10,
        totalElements: materials.length,
        searchData: ""
    })
    const onClose = () => {
        handleClose()
        setActiveStep(0)
        setSelectedString({})
        setSelectedJob({})
    }
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }

    const onBack = () => {
        if (activeStep === 1) {
            setTableOptions((prev) => ({...prev, totalElements: materials.length}))
        }
        setActiveStep(activeStep - 1)
    }

    const submit = () => {
        if (activeStep === 0) {
            const jobMetricOrderOrganizations = selectedString.jobMetricOrderOrganizations.map(el => ({
                ...el,
                metricName: el.jobMetricOrganizationName
            }))
            setDataJobMetricOrganization(jobMetricOrderOrganizations)
            setTableOptions((prev) => ({...prev, totalElements: jobMetricOrderOrganizations.length}))
            setActiveStep(1)
        } else if (activeStep === 1) {
            setActiveStep(2)
        } else {
            initValue ?
                handleSubmit({
                    "unit": formState.unit,
                    "note": formState.note,
                }, onClose)
                : handleSubmit(selectedString?.id, selectedJob?.id, {
                    "unit": formState.unit,
                    "note": formState.note,
                }, onClose)
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${initValue ? "500px" : activeStep === 0 ? "1520px" : activeStep === 1 ? "950px" : "950px"}`,
                height: `${initValue ? "370px" : activeStep === 0 ? "920px" : activeStep === 1 ? "1000px" : "600px"}`,
                borderRadius: 8,
            }
        }}>
            <Box style={{
                padding: initValue ? 20 : 40,
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{initValue ? "Редактировать затрату" : "Добавить затрату"}</DialogTitle>
                {!initValue && <Stepper
                    activeStep={activeStep}
                    style={{width: "600px"}}>
                    {["Материал", "Операция", "Параметры"].map((label) => (
                        <Step key={label}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                }
                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>}
            </Box>
            {!initValue && <Box sx={{
                height: "32px",
                padding: "4px 0 4px 14px",
                fontSize: "18px",
                margin: "0px 40px 0 40px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                border: selectedString?.id ? "#3B8BEC 1px solid" : "transparent 1px solid",
                '& .MuiTypography-root': {
                    fontWeight: (theme) => theme.typography.fontWeight,
                    color: (theme) => theme.palette.text.main,
                    '&:first-of-type': {
                        marginRight: '10px'
                    }
                },
            }}>
                <Typography component="p">{selectedString?.tempName}</Typography>
            </Box>}
            {activeStep !== 0 && selectedJob?.id && <Box sx={{
                height: "32px",
                padding: "4px 0 4px 14px",
                fontSize: "18px",
                border: selectedJob?.id ? "#3B8BEC 1px solid" : "transparent 1px solid",
                borderRadius: "4px",
                display: "flex",
                margin: "25px 40px 0px 40px",
                alignItems: "center",
                '& .MuiTypography-root': {
                    fontWeight: (theme) => theme.typography.fontWeight,
                    color: (theme) => theme.palette.text.main,
                    '&:first-of-type': {
                        marginRight: '10px'
                    }
                },
            }}>
                <Typography
                    component="p">{selectedJob?.metricName}
                </Typography>
            </Box>}
            <DialogContent sx={{padding: "20px 40px"}}>
                {activeStep === 0 ?
                    <BasicModelEstimateTableItem
                        selectedStringId={selectedString?.id}
                        setSelectedString={setSelectedString}
                        modal={true}
                        tableOptions={tableOptions}
                        tableOptionsHandle={tableOptionsHandle}
                        data={materials}
                        handleError={handleError}
                    /> : activeStep === 1
                        ?
                        <Box
                            style={{width: "100%"}}
                        >
                            <JobMetricOrganizationTable
                                data={dataJobMetricOrganization}
                                isModal={true}
                                onlyName={true}
                                columnAdded={false}
                                selectedString={selectedJob}
                                setSelectedString={setSelectedJob}
                                tableOptions={tableOptions}
                                tableOptionsHandle={tableOptionsHandle}
                                handleError={handleError}/>
                        </Box> :
                        <Box>
                            <TextFieldValidation
                                select
                                name="unit"
                                label="Ед. изм."
                                value={formState.unit}
                                setCurrValues={setFormState}
                            >
                                {listUnits.map((option) => (
                                    <MenuItem key={option.name} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextFieldValidation>
                            <TextFieldValidation
                                name="note"
                                label="Коментарий"
                                value={formState.note}
                                setCurrValues={setFormState}
                            >
                            </TextFieldValidation>
                        </Box>}
            </DialogContent>
            <DialogActions style={{padding: initValue ? 20 : 40, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep !== 0 && !initValue && <ButtonBack text={"Назад"} onClick={onBack}/>} </Box>
                <Box>
                    <ButtonExit
                        text={"Выйти"} onClick={onClose}/>
                    <ButtonAdd
                        text={"Выбрать"} onClick={submit}
                        disabled={(activeStep === 0 && !selectedString?.id) || (activeStep === 1 && !selectedJob?.id)}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
}
export default ModelProductionCosts;