import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../../сheckbox/CellCheckboxWithUnable";
import ModalOpenSetValuesCutOrTurnDetail from "../../../modal/ModalOpenSetValuesCutOrTurnDetail";
import ModalAddDetailOrRequestsCuttingForFiles from "./ModalAddDetailOrRequestsCuttingForFiles";
import FileService from "../../../../../API/orders/orders/organization/FileService";
import ModalCreateOrUpdateDetailModel from "./ModalCreateOrUpdateDetailModel";
import DetailUtil from "../../../../../utils/cutting/DetailUtil";
import ErrorModal from "../../../modal/ErrorModal";
import InfoModal from "../../../modal/InfoModal";
import ModalConfirm from "../../../modal/ModalConfirm";
import {useNavigate} from "react-router-dom";
import ModelDetailService from "../../../../../API/model-estimate/details/ModelDetailService";
import MenuItem from "@mui/material/MenuItem";
import ButtonCreate from "../../../button/ButtonCreate";

const ModelDetailTable = ({
                              data,
                              setData,
                              isError,
                              unableCut,
                              unableTurn,
                              setUnableCut,
                              setUnableTurn,
                              selectedIds,
                              setSelectedIds,
                              modalStatusOpen,
                              setModalStatusOpen,
                              status,
                              setStatus,
                              typeStatus,
                              setTypeStatus,
                              isColumnMaterial,
                              isColumnOrder,
                              enableGrouping,
                              columnGrouping,
                              expanded,
                              pageSize,
                              warehouse,
                              requestsCuttingId,
                              isGetTXTSELCO,
                              optimizationNumber,
                              optimizationId,
                              change,
                              setChange,
                              isOptimization,
                              materialList,
                              isRedirectOptimizations,
                              isRedirectPlans,
                              isButtonSetStatus,
                              isButtonRunModify,
                              isButtonAddDetail,
                              isAddDetailForFiles,
                              isColumnCut,
                              isColumnTurn,
                              enableRowSelection,
                              enableEditing,
                              estimateId,
                              tableOptions,
                              tableOptionsHandle,
                          }) => {

        const [isFullScreen, setIsFullScreen] = useState(false);
        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [errorModalOpen, setErrorModalOpen] = useState(false);
        const [infoModalOpen, setInfoModalOpen] = useState(false);

        const [modalAddDetailForFilesOpen, setModalAddDetailForFilesOpen] = useState(false);
        const [isNew, setIsNew] = useState(null);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
        const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);

        const [showConfirmationModalModified, setConfirmationModalModified] = useState(false);

        const [messageError, setMessageError] = useState(null);
        const [messageInfo, setMessageInfo] = useState(null);
        const [rowSelection, setRowSelection] = useState({});
        const [materialId, setMaterialId] = useState(null);

        const handleDeleteRow = useCallback(
            (row) => {
                ModelDetailService.delete(row.id, warehouse)
                    .then(function () {
                        data.splice(row.id, 1);
                        setData([...data]);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const setTitleModalOpenMethod = (typeStatus) => {
            return typeStatus === "cut" ?
                'Установть статус кроить' : 'Установть статус поворот';
        };

        const setLabelModalOpenMethod = (typeStatus) => {
            return typeStatus === "cut" ?
                'Установть статус кроить' : 'Установть статус поворот';
        };

        const handleUnableCheckboxCut = (id, status) => {
            ModelDetailService.unableCutOrTurn(id, status, 'cut', warehouse).then(function () {
                handleUnableCut(id, status)
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleUnableCut = (id, status) => {
            unableCut[id] = status
            setUnableCut(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const handleUnableCheckboxTurn = (id, status) => {
            ModelDetailService.unableCutOrTurn(id, status, 'turn', warehouse).then(function () {
                handleUnableTurn(id, status);
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleUnableTurn = (id, status) => {
            unableTurn[id] = status
            setUnableTurn(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const updateStatusByIds = async () => {
            ModelDetailService.updateCutOrTurnByIds(selectedIds, status, typeStatus, warehouse)
                .then(function () {
                })
                .catch(function (error) {
                    console.log(error);
                })
            handleCloseModalStatus();
        }

        const handleCloseModalStatus = () => {
            setModalStatusOpen(false);
        };

        const handleAddDetail = () => {

            setIsNew(true);

            const detail = {
                position: "",
                name: "",
                length: "",
                height: "",
                quantity: 0,
                turn: false,
                cut: true,
                l1: "",
                l2: "",
                w1: "",
                w2: "",
                groove: ""
            };

            setCurrentEditRowData(detail);
            setCreateOrUpdateModalOpen(true);
        };

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setCurrentEditRowId(row.id);
                setCreateOrUpdateModalOpen(true);
                setIsNew(false);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleUpdate = (values) => {
            data[currentEditRowId] = values;
            setData([...data]);
        };

        const handleSubmitUpdateDetail = () => {

            if (!DetailUtil.compareEqual(currentEditRowData, data[currentEditRowId])) {

                const newData = Array.from(data);
                newData[currentEditRowId] = currentEditRowData;

                if (isOptimization) {
                    newData[currentEditRowId].modified = true
                }

                const error = DetailUtil.checkDuplicatePositions(newData, isOptimization);

                if (!error) {
                    ModelDetailService.update(currentEditRowData, warehouse,
                    ).then(function (response) {
                        let data = response.data;
                        handleUpdate(data)
                        handleCloseCreateOrUpdate();
                    })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    setMessageError("Одинаковые позиции, но разные детали");
                    setErrorModalOpen(true)
                }


            } else {
                setMessageError("Детали одинаковые");
                setErrorModalOpen(true)
            }
        }

        const handleSubmitCreateDetail = () => {

            /*
                  const newData = Array.from(data);
                  newData.push(currentEditRowData);
                  const error = DetailUtil.checkDuplicatePositions(newData, isOptimization);
                  if (!error) {
                      if (isOptimization) {
                          const currentMaterialId = materialId === null ? materialList[0].id : materialId;
                          ModelDetailService.saveOptimizations(currentEditRowData, optimizationId, currentMaterialId, ).then(function (response) {
                              let data = response.data;
                              handleCreateNewRow(data)
                              handleCloseCreateOrUpdate();
                              setChange(!change)
                          })
                              .catch(function (error) {
                                  console.log(error);
                              });
                      } else {
                          ModelDetailService.save(currentEditRowData, requestsCuttingId, warehouse, ).then(function (response) {
                              let data = response.data;
                              handleCreateNewRow(data)
                              handleCloseCreateOrUpdate();
                              setChange(!change)
                          })
                              .catch(function (error) {
                                  console.log(error);
                              });
                      }

                  } else {
                      setMessageError("Одинаковые позиции, но разные детали");
                      setErrorModalOpen(true)
                  }
              }*/
        }

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const handleCloseCreateOrUpdate = () => {
            setCurrentEditRowData(null);
            setCurrentEditRowId(null);
            setIsNew(null);
            setCreateOrUpdateModalOpen(false);
            setMaterialId(null)
        };

        const handleSubmitCreateTxtFileDetailsSelco = () => {

            ModelDetailService.createTXTFileSelco(selectedIds, optimizationNumber,
            ).then(function () {
                setMessageInfo("TXT файл успешно создан");
                setInfoModalOpen(true);
                setConfirmationModalModified(false);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }


        const handleSubmitModify = () => {

            ModelDetailService.modifyOptimizations(optimizationId,
            ).then(function () {
                setInfoModalOpen(true);
                setMessageInfo("Модификция прошла успешна");
                setChange(!change)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }


        const navigate = useNavigate();

        const redirectOptimizations = () => {
            navigate('/optimizations');
        };

        const redirectPlans = () => {
            navigate('/plans');
        };

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'position',
                    header: 'Позиция',
                    accessorKey: 'position',
                    minSize: 70,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'name',
                    header: 'Наименование',
                    accessorKey: 'name',
                    minSize: 60,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'length',
                    header: 'W',
                    accessorKey: 'length',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'height',
                    header: 'H',
                    accessorKey: 'height',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'quantity',
                    header: 'кол.',
                    accessorKey: 'quantity',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'l1',
                    header: 'l1',
                    accessorKey: 'l1',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'l2',
                    header: 'l2',
                    accessorKey: 'l2',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'w1',
                    header: 'w1',
                    accessorKey: 'w1',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'w2',
                    header: 'w2',
                    accessorKey: 'w2',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'groove',
                    header: 'Паз',
                    accessorKey: 'groove',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'nameMaterial',
                    header: 'Материал',
                    accessorKey: 'nameMaterial',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (isColumnCut) {
                baseColumns.splice(1, 0, {
                    id: 'cut',
                    header: 'Кроить',
                    accessorKey: 'cut',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unableCut}
                        setUnable={handleUnableCheckboxCut}
                        disabled={row.original.excluded}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            if (isColumnTurn) {
                baseColumns.splice(2, 0, {
                    id: 'turn',
                    header: 'Поворот',
                    accessorKey: 'turn',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unableTurn}
                        setUnable={handleUnableCheckboxTurn}
                        disabled={row.original.excluded}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            return baseColumns;

        }, [data, setData, isColumnMaterial, isColumnOrder]);

        const menuItem = [
            {type: "edit", text: "Изменить", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow},
        ]
        const setStatusTable = (typeStatus) => {
            setModalStatusOpen(true)
            setTypeStatus(typeStatus)
            setTitleModalOpenSetValues(setTitleModalOpenMethod(typeStatus))
            setLabelModalOpenSetValues(setLabelModalOpenMethod(typeStatus))
            // const selectedRows = table.getSelectedRowModel().flatRows;
            // const selectedIds = selectedRows.map((row) => row.getValue('id'));
            setSelectedIds(selectedIds);
        };

        const createTxtFileDetailsSelco = () => {
            // const selectedRows = table.getSelectedRowModel().flatRows;
            // const selectedIds = selectedRows.map((row) => row.getValue('id'));
            // setSelectedIds(selectedIds);
            // handleSubmitCreateTxtFileDetailsSelco();
        };

        const runModify = () => {
            setConfirmationModalModified(true);
        };
        return (
            <Box sx={{maxWidth: "1500px", overflow: 'auto',}}>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", gap: "10px", paddingBottom: "30px"}}
                >
                    {isRedirectOptimizations && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectOptimizations}
                        variant="contained"
                    >
                        Оптимизации
                    </Button>}
                    {isRedirectPlans && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectPlans}
                        variant="contained"
                    >
                        Планы
                    </Button>}
                    {isError && <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        sx={{
                            animation: 'blinking 1s infinite',
                            WebkitAnimation: 'blinking 1s infinite',
                            '@keyframes blinking': {
                                '0%': {backgroundColor: '#fff'},
                                '50%': {backgroundColor: '#f00'},
                                '100%': {backgroundColor: '#fff'},
                            },
                        }}
                    >
                        Ошибка позиции
                    </Button>}
                    {isButtonAddDetail && <Button
                        size="small"
                        color="success"
                        onClick={handleAddDetail}
                        variant="contained"
                    >
                        Добавить деталь
                    </Button>}
                    {!isOptimization && isAddDetailForFiles && <Button
                        size="small"
                        color="success"
                        onClick={() => setModalAddDetailForFilesOpen(true)}
                        variant="contained"
                    >
                        Добавить детали из файла
                    </Button>}
                    {isButtonSetStatus && <Button
                        size="small"
                        color="success"
                        // disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                        onClick={() => setStatusTable('cut')}
                        variant="contained"
                    >
                        Установить статус кроить
                    </Button>}
                    {isButtonSetStatus && <Button
                        size="small"
                        color="success"
                        // disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                        onClick={() => setStatusTable('turn')}
                        variant="contained"
                    >
                        Установить статус поворот
                    </Button>}
                </Box>
                <MaterialReactTable
                    muiTableContainerProps={{
                        sx: {
                            width: "1500px",
                            maxHeight: `calc(100vh - 500px)`
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            // "&:first-of-type": {
                            //     padding: 0,
                            //     width: "50px",
                            //     height: "36px",
                            //     display: "flex",
                            //     justifyContent: "center",
                            //     alignItems: "center",
                            //     color: (theme) => theme.palette.text.light,
                            //     "& .MuiButtonBase-root": {
                            //         borderRadius: "4px",
                            //         width: "24px",
                            //         height: "24px",
                            //         opacity: 1,
                            //         margin: 0,
                            //         padding: 0,
                            //         "&:hover": {
                            //             backgroundColor: (theme) => theme.palette.primary.stroke,
                            //         }
                            //     }
                            // },
                            "& li": {
                                listStyleType: "none",
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }
                                }
                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem"
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            sx: () => ({
                                height: '36px',
                                backgroundColor: "white",

                                "&:hover": {
                                    "& td:after": {
                                        display: "none",
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey,

                                },
                            })
                        })
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        backgroundColor: "red",
                        sx: {
                            backgroundColor: "red",
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                            material: isColumnMaterial,
                            order: isColumnOrder,
                        },
                        isFullScreen: false,
                        density: "compact",
                        grouping: columnGrouping,
                        expanded: expanded,
                        sorting: [{id: 'nameMaterial', desc: false}, {id: 'position', desc: false}],
                    }}
                    enableGrouping={enableGrouping}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowActions={false}
                    enableToolbarInternalActions={false}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })}
                    localization={MRT_Localization_RU}

                />
                <ModalOpenSetValuesCutOrTurnDetail
                    title={titleModalOpenSetValues}
                    label={labelModalOpenSetValues}
                    modalOpen={modalStatusOpen}
                    handleClose={handleCloseModalStatus}
                    setValue={setStatus}
                    handleSubmit={updateStatusByIds}
                />
                <ModalAddDetailOrRequestsCuttingForFiles
                    title={'Добавить детали из файла'}
                    labelForm={'Загрузить файлы деталей'}
                    modalOpen={modalAddDetailForFilesOpen}
                    handleClose={() => setModalAddDetailForFilesOpen(false)}
                    requestsCuttingId={requestsCuttingId}
                    requestsCutting={true}
                    change={change}
                    setChange={setChange}
                    upload={FileService.uploadDetailFiles}
                />
                <ModalCreateOrUpdateDetailModel
                    open={createOrUpdateModalOpen}
                    onClose={handleCloseCreateOrUpdate}
                    title={isNew ? 'Создать деталь' : 'Редактировать деталь'}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    labelButtonSSubmit={isNew ? 'Создать' : 'Обновить'}
                    handleSubmit={isNew ? handleSubmitCreateDetail : handleSubmitUpdateDetail}
                    isOptimization={isOptimization}
                    materialList={materialList}
                    setMaterialId={setMaterialId}
                />
                <ErrorModal
                    open={errorModalOpen}
                    setOpen={setErrorModalOpen}
                    message={messageError}
                />
                <InfoModal
                    open={infoModalOpen}
                    setOpen={setInfoModalOpen}
                    message={messageInfo}
                />
                <ModalConfirm
                    title={"Подтвердить модификацию?"}
                    modalOpen={showConfirmationModalModified}
                    handleClose={() => setConfirmationModalModified(false)}
                    handleSubmit={handleSubmitModify}
                    action={"Подтвердить"}/>
            </Box>
        );
    }
;

export default ModelDetailTable;