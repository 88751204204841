import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import WorkerService from "../../../../../../API/orders/job/WorkerService";
import Box from "@mui/material/Box";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../../../сheckbox/CellCheckboxWithUnable";
import {Link} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import TypeJobOrganizationService from "../../../../../../API/orders/job/TypeJobOrganizationService";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    Typography
} from "@mui/material";
import ModalConfirm from "../../../../modal/ModalConfirm";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import CheckFieldUtil from "../../../../../../utils/orders/CustomerUtil";
import ButtonCreate from "../../../../button/ButtonCreate";

const WorkerTable = ({
                         data,
                         setData,
                         unable,
                         setUnable,
                         unableProduction,
                         setUnableProduction,
                         unableConstructor,
                         setUnableConstructor,
                         isModal,
                         currentEditWorkersData,
                         setChange,
                         tableOptions,
                         tableOptionsHandle,
                         handleError
                     }) => {
    const initValue = {
        name: "",
        email: "",
        registrationNumber: "",
        residentialAddress: "",
        organizationalAndLegalForm: "",
        address: "",
    }
    const [modalOpen, setModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [currentRowId, setCurrentRowId] = useState("");

    const handleClose = () => {
        setModalOpen(false)
        setCurrentEditRowData(initValue)
    }
    const handleSaveRowEdits = async (data) => {
        const {id, ...values} = data
        WorkerService.update(id, values)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === id ? response.data : el))
                handleClose()
            })
            .catch(function (error) {
                handleError(error);
            })

    };
    const handleSubmit = (values) => {
        WorkerService.save(values.email).then(function (response) {
            handleClose()
        })
            .catch(function (error) {
                handleError(error);
            });

    };

    const handleEditRow = (row) => {
        setCurrentEditRowData(row);
        setModalOpen(true)
    }
    const handleDeleteRow = (row) => {
        setCurrentRowId(row.id)
        setConfirmModalOpen(true)
    }

    const handleDelete = (row) => {
        WorkerService.delete(currentRowId)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== currentRowId))
                setConfirmModalOpen(false)
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const handleUnableCheckboxConstructor = (id, status) => {
        WorkerService.unableConstructor(id, status)
            .then(function () {
                handleUnableConstructor(id, status)
            })
            .catch(function (error) {
                handleError(error);
            })
    };

    const handleWorkers = (id, status) => {
        if (status) {
            TypeJobOrganizationService.addWorker(currentEditWorkersData.idGroup, id)
                .then(function (response) {
                    setChange(true)
                })
                .catch(function (error) {
                    handleError(error);
                })
        } else {
            TypeJobOrganizationService.deleteWorker(currentEditWorkersData.idGroup, id)
                .then(function (response) {
                    setChange(true)
                })
                .catch(function (error) {
                    handleError(error);
                })
        }
    };

    const handleUnableConstructor = (id, status) => {
        unableConstructor[id] = status
        setUnableConstructor(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    const handleUnableCheckbox = (id, status) => {
        WorkerService.unable(id, status)
            .then(function () {
                handleUnable(id, status)
            })
            .catch(function (error) {
                handleError(error);
            })
    };

    const handleUnable = (id, status) => {
        unable[id] = status
        setUnable(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    const handleUnableCheckboxProduction = (id, status) => {
        WorkerService.unableProduction(id, status)
            .then(function () {
                handleUnableProduction(id, status)
            })
            .catch(function (error) {
                handleError(error);
            })
    };

    const handleUnableProduction = (id, status) => {
        unableProduction[id] = status
        setUnableProduction(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditRow},
        {type: "delete", text: "Удалить", handler: handleDeleteRow}]


    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
            {
                header: 'Фамилия',
                accessorKey: 'lastName',
                minSize: 25,
            },
            {
                header: 'Имя',
                accessorKey: 'firstName',
                minSize: 25,
            },
            {
                header: 'Отчество',
                accessorKey: 'middleName',
                minSize: 25,
            },
        ];
        if (!isModal) {
            baseColumns.splice(4, 0, {
                header: 'Должность',
                accessorKey: 'position',
                minSize: 50,
                Cell: ({cell, row}) => (
                    <Link
                        to={'/organization/workers/' + data[row.index].id + '/ratings/' + row.original.lastName + ' ' + row.original.firstName + ' ' + row.original.middleName}>
                        {data[row.index].position === null ? 'Не определена' : cell.getValue()}</Link>
                ),
            });
            baseColumns.splice(4, 0, {
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unable}
                    setUnable={handleUnableCheckbox}
                >
                </CellCheckboxWithUnable>,
            });
            baseColumns.splice(4, 0, {
                header: 'Производство',
                accessorKey: 'production',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unableProduction}
                    setUnable={handleUnableCheckboxProduction}
                >
                </CellCheckboxWithUnable>,
            });
            baseColumns.splice(4, 0, {
                header: 'Проектирование',
                accessorKey: 'constructor',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unableConstructor}
                    setUnable={handleUnableCheckboxConstructor}
                >
                </CellCheckboxWithUnable>,
            });
            baseColumns.splice(4, 0, {
                header: 'Email',
                accessorKey: 'email',
                minSize: 50
            });
            baseColumns.splice(4, 0, {
                header: 'Номер телефона',
                accessorKey: 'phoneNumber',
                size: 8,
            });
        }
        if (isModal) {
            baseColumns.splice(0, 0, {
                header: 'Добавлен',
                accessorKey: "addedWorker",
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => {
                    return <CellCheckboxWithUnable
                        id={row.id}
                        unable={currentEditWorkersData.workers}
                        setUnable={handleWorkers}
                    />
                }
            });
        }
        return baseColumns;
    }, [data, setData],);

    return <div>
        <Box sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}>
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>Работники</Typography>
            {!isModal && <ButtonCreate
                text={"Добавить рабртника"}
                width={210}
                onClick={() => setModalOpen(true)}
            />}
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "50px",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& a": {
                        color: "#006CCC",
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "underline"
                        }

                    }
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: '#F5F6FA',
                        },
                    })
                })
            }}
            isFullScreen={false}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 10,
                    minSize: 10,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                sx: {
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            columns={columns}
            data={data}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            getRowId={(originalRow) => originalRow.id}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            rowCount={tableOptions.totalElements}
            initialState={{
                sorting: [{id: 'firstName', desc: false}],
                columnVisibility: {
                    id: false,
                },
                isFullScreen: false,
                density: "compact",
                pagination: {pageIndex: 0, pageSize: 20},
                columnPinning: {left: ['number']}
            }}
            localization={MRT_Localization_RU}
            enableRowActions={!isModal}
            enableToolbarInternalActions={false}
        />
        <ModalConfirm
            title={"Удалить работника?"}
            modalOpen={confirmModalOpen}
            handleClose={() => setConfirmModalOpen(false)}
            handleSubmit={handleDelete}
            action={"Удалить"}/>
        <WorkerModal
            open={modalOpen}
            columns={columns}
            onClose={handleClose}
            initValue={currentEditRowData}
            onSubmit={currentEditRowData.id ? handleSaveRowEdits : handleSubmit}
            handleError={handleError}
        />
    </div>
};
export const WorkerModal = ({open, columns, onClose, onSubmit, initValue, handleError}) => {

    const [values, setValues] = useState(initValue);

    useEffect(() => {
        setValues(initValue)
    }, [initValue])

    return (
        <Dialog open={open}>
            <DialogTitle
                textAlign="center">{initValue.id ? "Изменить" : "Добавить работника"}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                        }}
                    >{initValue.id ? columns.map((column) => (
                        CheckFieldUtil.worker(column) &&
                        <TextFieldValidation
                            key={column.accessorKey}
                            name={column.accessorKey}
                            label={column.header}
                            value={values[column.accessorKey]}
                            required={false}
                            setCurrValues={setValues}
                        />)) : <TextFieldValidation
                        key={"email"}
                        name={"email"}
                        label={"E-mail"}
                        value={values["email"]}
                        required={false}
                        setCurrValues={setValues}
                    />}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Сохранить"} onClick={() => onSubmit(values)}/>
            </DialogActions>
        </Dialog>
    );
};
export default WorkerTable;