import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const materialRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/admin/materials'

export default class MaterialService {
    static async setGroup(groupId, data) {
        return axios.patch(materialRestUrl + '/set-group?groupId=' + groupId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setToArchive(data) {
        return axios.patch(materialRestUrl + '/set-to-archive', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllWithGroupMaterialAndUnit(nameMethodGetMaterial, supplierId) {
        return axios.get(materialRestUrl + '/' + nameMethodGetMaterial + "?supplierId="
            + supplierId);
    }

    static async getWithAllPrices(id) {
        return axios.get(materialRestUrl + '/with-all-prices/' + id);
    }

    static async updateMainOptions(data, supplierId, manufacturerId, groupMaterialId) {
        return await axios.post(materialRestUrl + '/' + data.id + "?supplierId="
            + supplierId + "&manufacturerId=" + manufacturerId + "&groupMaterialId=" + groupMaterialId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(queryStringData, data) {
        const queryString = Object.entries(queryStringData).map(([key, value], i) => `${key}=${value}`).join("&")
        return await axios.post(materialRestUrl + "?" + queryString, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(materialRestUrl + '/' + id);
    }

    static async setBaseTrimming(trimmings, materialName) {
        await axios.patch(materialRestUrl + '/set-base-trimming' + "?materialName=" + materialName, trimmings);
    }

    static async deleteJobMetricByMaterialGroup(data, orderJobType) {
        return axios.patch(`${materialRestUrl}/delete-job-metric-in-materials-by-material-group?orderJobType=${orderJobType}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addJobMetricByMaterialGroup(data, orderJobType) {
        return axios.patch(`${materialRestUrl}/add-job-metric-in-materials-by-material-group?orderJobType=${orderJobType}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteJobMetricByMaterialIds(data, orderJobType) {
        return axios.patch(`${materialRestUrl}/delete-job-metric-in-materials-by-material-ids?orderJobType=${orderJobType}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addJobMetricByMaterialIds(data, orderJobType) {
        return axios.put(`${materialRestUrl}/add-job-metric--in-materials-by-material-ids?orderJobType=${orderJobType}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteJobMetric(materialId, data, orderJobType) {
        return axios.patch(`${materialRestUrl}/delete-job-metric?materialId=${materialId}&orderJobType=${orderJobType}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addJobMetric(materialId, data, orderJobType) {
        return axios.patch(`${materialRestUrl}/add-job-metric?materialId=${materialId}&orderJobType=${orderJobType}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}