import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import Delete from "@mui/icons-material/DeleteOutlined";
import {Edit} from "@mui/icons-material";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAlert from "../ModalAlert";
import ModalAddVariable from "./ModalAddVariable";
import VariableControllerService from "../../../../../API/details/VariableControllerService";
import {materialReserveState} from "../../../../../data/MaterialReserveState";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";

const immutableVariables = ["W", "H", "L"]

const VariablesTable = ({
                            data, isError, setData, enableEditing, enableRowSelection, orderId, detailId
                        }) => {
    const rowInitValue = {name: "", pattern: "", comment: ""}

    const [variableModalOpen, setVariableModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({});

    const [rowSelection, setRowSelection] = useState({});
    const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [isNew, setIsNew] = useState(true);

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const handleClose = () => {
        setVariableModalOpen(false)
        setIsNew(true)
        setCurrentEditRowData(rowInitValue)
    }

    const handleUpdate = (data) => {
        VariableControllerService.edit(orderId, detailId, currentEditRowId, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleDeleteVariable = (row) => {
        VariableControllerService.delete(orderId, detailId, row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleSubmit = (data) => {
        VariableControllerService.save(orderId, detailId, data)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleAddVariable = () => {
        setVariableModalOpen(true)
    };

    const handleEditVariable = (row) => {
        setCurrentEditRowData((prev) => {
            return Object.keys(prev).reduce((acc, it) => ({...acc, [it]: row[it]}), {})
        });
        setVariableModalOpen(true)
        setCurrentEditRowId(row.id);
        setIsNew(false);
    };

    const columns = useMemo(() => {
        const baseColumns = [{
            id: 'name', header: 'Наименование', accessorKey: 'name', minSize: 60,
        }, {
            id: 'pattern', header: 'Значение', accessorKey: 'pattern', Cell: ({row}) => {
                const numberPattern = Number(row.original.pattern)
                return isNaN(numberPattern) ? row.original.pattern : numberPattern.toFixed(2)
            }
        }, {
            id: 'value',
            header: 'Текущее значение',
            accessorKey: 'value',
            Cell: ({row}) => row.original.value.toFixed(2),
        }, {
            id: 'comment', header: 'Комментарий', accessorKey: 'comment',
        },]

        return baseColumns;

    }, [data]);
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditVariable},
        {type: "delete", text: "Удалить", handler: handleDeleteVariable}
    ]
    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
        >
            <ButtonCreate
                text={"Добавить переменную"}
                width={230}
                onClick={handleAddVariable}
                disabled={isError}
            />
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "100%",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",

                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.primary.light,
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light
                            },
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={isError ? [] : data}
            rowHeight={13}
            columnSpacing={1}
            onRowSelectionChange={setRowSelection}
            state={{rowSelection, showGlobalFilter: true}}
            enableRowSelection={enableRowSelection}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    disabled={immutableVariables.includes(row.original.name)}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })}
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}
        />
        <ModalAddVariable title={isNew ? "Добавить переменную" : "Изменить переменную"}
                          initValue={currentEditRowData}
                          open={variableModalOpen}
                          onClose={handleClose}
                          handleSubmit={isNew ? handleSubmit : handleUpdate}
                          labelButtonSubmit={isNew ? 'Создать' : 'Обновить'}
        />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
    </div>);
};

export default VariablesTable;
