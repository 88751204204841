import React, {useEffect, useState} from 'react';
import OrderService from "../../../../API/orders/orders/organization/OrderService";
import OrderCloudTable from "../../../../components/UI/table/orders/organization/order-cloud/OrderCloudTable";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";

const OrdersCloudArchive = () => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [alertData, setAlertData] = useState({});
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        OrderService.getOrdersByCloudArchive().then(function (response) {
            let data = response.data;
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            setData(data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Архив"}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заказы из облака", href: "/organization/orders-cloud"},
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column", paddingBottom: "30px"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Архив
                    </Typography>
                </Box>
                <OrderCloudTable
                    data={data}
                    setData={setData}
                    enableEditing={true}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    handleError={handleError}
                    archive={true}
                />
            </Box>
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </Box>
    );
};

export default OrdersCloudArchive;