import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import MaterialPageItemModule from "../../../../components/UI/modul/MaterialPageItemModule";
import Typography from "@mui/material/Typography";
import ButtonExit from "../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../components/UI/button/ButtonAdd";

const ReplacementSimilarMaterials = ({
                                         open,
                                         setOpen,
                                         currentEditRowData,
                                         setCurrentEditRowData,
                                         replacement,
                                         handleError
                                     }) => {

    const onClose = () => {
        setOpen(false);
        setCurrentEditRowData(null)
    };

    const selectMaterial = (material) => {
        setCurrentEditRowData(material)
    }

    return (
        <div>
            <Dialog open={open} PaperProps={{
                style: {
                    minWidth: "1050px",
                }
            }}>
                <DialogTitle textAlign="center">Заменить материал</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        height: "32px",
                        fontSize: "18px",
                        margin: "20px 40px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: currentEditRowData.id ? "#3B8BEC 1px solid" : "transparent 1px solid",
                        '& .MuiTypography-root': {
                            fontWeight: (theme) => theme.typography.fontWeight,
                            color: (theme) => theme.palette.text.main,
                            '&:first-of-type': {
                                marginRight: '10px'
                            }
                        },
                    }}>
                        <Typography component="p">{currentEditRowData.articleNumber}</Typography>
                        <Typography component="p">{currentEditRowData.name}</Typography>
                    </Box>
                    <Box style={{height: 700}}>
                        <MaterialPageItemModule
                            pageSize={20}
                            selectMaterial={selectMaterial}
                            modal={true}
                            selectMaterialId={currentEditRowData?.id}
                            handleError={handleError}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <ButtonExit text={"Выйти"} onClick={onClose}/>
                    <ButtonAdd text={"Замена"} onClick={replacement}/>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ReplacementSimilarMaterials;