import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const ratingJobWorkerRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/ratings-job-worker'

export default class RatingJobWorkerService {

    static async getAllByWorkerId(id) {
        return axios.get(ratingJobWorkerRestUrl + '/workers/' + id);
    }

    static async update(id, data) {
        return await axios.put(ratingJobWorkerRestUrl + '/' + id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(ratingJobWorkerRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(ratingJobWorkerRestUrl + '/' + id)
    }
}