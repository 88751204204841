import React, {useEffect, useState} from 'react';
import ReservationMaterialEstimateUnitTable
    from "../../../../components/UI/table/warehouse/reservation/ReservationMaterialEstimateUnitTable";
import {useLocation, useParams} from "react-router-dom";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import MaterialReserveService from "../../../../API/orders/reservation/MaterialReserveService";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {estimateStatuses} from "../../../../data/EstimateState";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";

const ReservationMaterialEstimateUnit = () => {
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const pathname = useLocation().pathname
    const params = useParams();

    const {
        materialEstimateUnitId,
        estimateId,
        estimateState,
        orderId,
        orderNumber,
        orderHeaderId,
        year,
        isButtonPurchase,
        isSlab,
        articleNumber,
        type,
        name,
        isIssue
    } = params

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    useEffect(() => {
        getStartPageSize()
        MaterialReserveService.getAllByMaterialEstimateUnitId(type, materialEstimateUnitId).then(function (response) {
            const data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Резервы"}
                path={[
                    {name: "Главная", href: "/"},
                    {
                        name: "Смета",
                        href: '/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year
                    },
                    {
                        name: name,
                        href: '/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year + '/estimate/' + estimateId + "/" + estimateState
                    }

                ]}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Резервы
                    </Typography>
                    <Typography>{`Арикул: ${articleNumber}`}</Typography>
                    <Typography>{`Найменование: ${name}`}</Typography>
                    <Typography>{`Статус: ${estimateStatuses[estimateState]}`}</Typography>
                </Box>
                <ReservationMaterialEstimateUnitTable
                    data={data}
                    setData={setData}
                    orderId={orderId}
                    nameOrder={orderNumber}
                    orderHeaderId={orderHeaderId}
                    year={year}
                    estimateId={estimateId}
                    isButtonPurchase={isButtonPurchase}
                    isSlab={isSlab}
                    isColumnOrder={false}
                    isIssue={isIssue === "true"}
                    enableEditing={true}
                    materialEstimateUnitId={materialEstimateUnitId}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                />
            </Box>
        </Box>
    );
};

export default ReservationMaterialEstimateUnit;