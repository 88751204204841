import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_ToggleFiltersButton} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import PlanService from "../../../../../API/orders/optimization/organization/PlanService";
import {planStateStatuses} from "../../../../../data/PlanState";
import ModalUpdateStatus from "../ModalUpdateStatus";
import ModalOpenCreatePlanOrOptimization from "./ModalOpenCreatePlanOrOptimization";
import {ReactComponent as SawIcon} from "../../../../../components/Icons/processing.svg";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const PlanTable = ({
                       data,
                       setData,
                       tableOptions,
                       tableOptionsHandle
                   }) => {

        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [newRowData, setNewRowData] = useState(null);
        const [currentAllowedStatuses, setCurrentAllowedStatuses] = useState(['EXPECTATION', 'PLANNED', 'WORK', 'ARCHIVE']);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [addModalOpen, setAddModalOpen] = useState(false);

        const handleDeleteRow = (row) => {
            const id = row.id;
            PlanService.delete(id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setCurrentEditRowId(row.id);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleClose = () => {
            setUpdateModalOpen(false)
        };

        const navigate = useNavigate();

        const redirectOrdersProduction = () => {
            navigate('/organization/orders-production');
        };

        const redirectRequestsCutting = () => {
            navigate('/organization/requests-cutting');
        };

        const redirectOptimizations = () => {
            navigate('/organization/optimizations');
        };

        const handleCreatePlan = () => {
            const plan = {
                id: null,
                name: "P",
                planState: 'EXPECTATION',
                planned: null,
            }
            setNewRowData(plan);
            setAddModalOpen(true)
        };

        const createPlan = () => {
            PlanService.createPlan(newRowData).then(function (response) {
                handleCreateNewRow(response.data);
                setNewRowData(null);
                setAddModalOpen(false)
                tableOptionsHandle((prev) => ({...prev, totalElements: response.data.length}))
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const columns = useMemo(
                () => [
                    {
                        id: 'name',
                        header: 'Найменование',
                        accessorKey: 'name',
                        minSize: 600,
                        Cell: ({cell, row}) => (
                            <Link to={'/organization/plans/' + row.original.id}>
                                {cell.getValue() === null ? '' : cell.getValue()}
                            </Link>

                        ),
                    },
                    {
                        id: 'requests_cutting',
                        header: 'Заявки на раскрой',
                        accessorKey: 'id',
                        maxSize: 50,
                        enableSorting: false,
                        enableColumnActions: false,
                        Cell: ({row}) => (
                            <Button
                                onClick={() => navigate(`/organization/requests-cutting/plan/${row.original.id}/${row.original.name}`)}>
                                <SawIcon style={{width: "30px", height: "30px"}}/>
                            </Button>
                        ),
                    },
                    {
                        id: 'planState',
                        header: 'Статус',
                        accessorKey: 'planState',
                        minSize: 300,
                        Cell:
                            ({cell}) => planStateStatuses[cell.getValue()],
                    },
                    {
                        accessorFn: (row) => {
                            if (row.planned === null) {
                            } else {
                                return new Date(row.planned)
                            }
                        },
                        id: 'planned',
                        header: 'Дата планирования',
                        minSize: 300,
                        filterVariant: 'date-range',
                        Cell: ({cell}) => {
                            if (cell.getValue() !== undefined) {
                                return cell.getValue().toLocaleDateString('en-GB');
                            }
                        },
                    },
                ],
                [data, setData],
            )
        ;
        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow}
        ]

        return (
            <Box sx={{maxWidth: "1500px"}}>
                <Box
                    sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
                >
                    <Typography sx={{fontSize: "24px", fontWeight: 700}}>Планы</Typography>
                    <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>
                        <ButtonCreate
                            text={"Производство"}
                            width={130}
                            icon={false}
                            onClick={redirectOrdersProduction}
                        />
                        <ButtonCreate
                            text={"Заявки на раскрой"}
                            width={170}
                            icon={false}
                            onClick={redirectRequestsCutting}
                        />
                        <ButtonCreate
                            text={"Оптимизации"}
                            width={130}
                            icon={false}
                            onClick={redirectOptimizations}
                        />
                        <ButtonCreate
                            text={"Создать план"}
                            width={170}
                            onClick={handleCreatePlan}
                        />
                    </Box>
                </Box>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='ru'
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <MaterialReactTable
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: 0
                                }
                            }
                        }}

                        muiTableBodyCellProps={({cell, table}) => {
                            return ({
                                sx: () => ({
                                    p: '0px 0px 0px 10px',
                                    m: '0px 0px 0px 0px',
                                    border: "none",
                                    "& td:after": {
                                        "&:hover": {
                                            backgroundColor: "red"
                                        }
                                    },
                                    "&:not(:last-of-type)": {
                                        borderRight: '1px solid #EAEBEA',
                                    },
                                    "&": cell.column.id === "requests_cutting" && {
                                        width: 'calc(100% - 1px)',
                                        padding: 0,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    },
                                    "&:first-of-type": {
                                        padding: 0,
                                        width: "50px",
                                        height: "36px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: (theme) => theme.palette.text.light,
                                        "& .MuiButtonBase-root": {
                                            borderRadius: "4px",
                                            width: "24px",
                                            height: "24px",
                                            opacity: 1,
                                            margin: 0,
                                            padding: 0,
                                            "&:hover": {
                                                backgroundColor: (theme) => theme.palette.primary.stroke,
                                            }
                                        }
                                    },

                                    "& a": {
                                        color: "#006CCC",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline"
                                        }

                                    }
                                })
                            })
                        }}

                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            sx: {
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: "",
                                size: 3,
                                minSize: 3,
                                grow: false,
                            },
                        }}
                        columns={columns}
                        data={data}
                        rowHeight={13}
                        columnSpacing={1}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{showGlobalFilter: true, pagination: tableOptions,}}
                        rowCount={tableOptions.totalElements}
                        renderTopToolbarCustomActions={({table}) => (
                            <Box sx={{
                                width: "100%",
                                padding: 0,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItem: "center"
                            }}>
                                <MRT_ToggleFiltersButton table={table}/>
                            </Box>)}
                        initialState={{
                            columnVisibility: {
                                id: false,
                            },
                            isFullScreen: false,
                            density: "compact",
                            pagination: {pageIndex: 0, pageSize: 50},
                        }}
                        renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                            return <MenuItem
                                key={el.type}
                                onClick={() => {
                                    el.handler(row.original)
                                    closeMenu();
                                }}
                                sx={{
                                    margin: "0 8px",
                                    borderRadius: "4px",
                                    "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                                }}
                            >
                                {el.text}
                            </MenuItem>
                        })}
                        localization={MRT_Localization_RU}
                        enableRowActions={true}
                        enableToolbarInternalActions={false}
                    />
                </LocalizationProvider>
                <ModalUpdateStatus
                    title={'Обновить статус'}
                    data={data}
                    setData={setData}
                    open={updateModalOpen}
                    handleClose={handleClose}
                    currentEditRowId={currentEditRowId}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    currentAllowedStatuses={currentAllowedStatuses}
                    statusesList={planStateStatuses}
                    field={'planState'}
                    unableStatus={PlanService.unableStatus}
                    tableOptionsHandle={tableOptionsHandle}
                />
                <ModalOpenCreatePlanOrOptimization
                    label={'Название плана'}
                    field={'name'}
                    values={newRowData}
                    setValues={setNewRowData}
                    title={'Создать план'}
                    modalOpen={addModalOpen}
                    handleClose={() => {
                        setAddModalOpen(false)
                    }}
                    handleSubmit={createPlan}
                />
            </Box>
        );
    }
;

export default PlanTable;