import React, {useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import ModalConfirm from "../../../../modal/ModalConfirm";
import CustomerEstimateService from "../../../../../../API/orders/estimate/customer/CustomerEstimateService";
import ButtonCreate from "../../../../button/ButtonCreate";

const CustomerEstimateOrganizationTotalMaterialTable = ({
                                                            data,
                                                            setData,
                                                            orderId,
                                                            estimateId,
                                                            baseCurrency,
                                                            alternativeCurrency,
                                                            tableOptions,
                                                            tableOptionsHandle,
                                                            handleError
                                                        }) => {

    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeTotalPrice && mEU.alternativeTotalPrice.price !== 0) {
                return acc + mEU.alternativeTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseTotalPrice && mEU.baseTotalPrice.price !== 0) {
                return acc + mEU.baseTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.basePrice && mEU.basePrice.price !== 0) {
                return acc + mEU.basePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumJobBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseJobPrice && mEU.baseJobPrice.price !== 0) {
                return acc + mEU.baseJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumJobAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeJobPrice && mEU.alternativeJobPrice.price !== 0) {
                return acc + mEU.alternativeJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativePrice && mEU.alternativePrice.price !== 0) {
                return acc + mEU.alternativePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );


    const handleSubmitSetPurchase = async () => {
        CustomerEstimateService.confirmPerformer(orderId, estimateId)
            .then(function () {
                handleCloseSetPurchase()
            })
            .catch(function (error) {
                handleError(error);
            })
    }

    const handleCloseSetPurchase = () => {
        setSetPurchaseModalOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'articleNumber',
                minSize: 15,
                maxSize: 25,
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'unitName',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'basePrice',
                header: `Материал ${baseCurrency}`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'baseJobPrice',
                header: `Работа ${baseCurrency}`,
                accessorKey: 'baseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumJobBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'baseTotalPrice',
                header: `Итого: ${baseCurrency}`,
                accessorKey: 'baseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativePrice',
                header: `Материал ${alternativeCurrency}`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativeJobPrice',
                header: `Работа ${alternativeCurrency}`,
                accessorKey: 'alternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumJobAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativeTotalPrice',
                header: `Итого: ${alternativeCurrency}`,
                accessorKey: 'alternativeTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
            >
                <ButtonCreate
                    text={"Подтвердить исполнителя"}
                    width={220}
                    icon={false}
                    onClick={() => setSetPurchaseModalOpen(true)}
                />
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={({cell, table}) => {
                    return ({
                        sx: () => ({
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',
                            },
                        })
                    })
                }}

                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem",
                        "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                            "minWidth": "100%"
                        }
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    sx: {
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                enableToolbarInternalActions={false}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                localization={MRT_Localization_RU}
            />
            <ModalConfirm
                title={"Подтвердить выбор исполнителя?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
        </div>
    );
};

export default CustomerEstimateOrganizationTotalMaterialTable;