import FileService from "../API/orders/orders/organization/FileService";
import ModelFileService from "../API/model-estimate/file/ModelFileService";
import AdminMaterialCloudFileService from "../API/material-cloud/admin/AdminMaterialCloudFileService";

export const importArray = [
    {
        id: 1,
        helpText: "Импорт групп",
        downloadFile: FileService.downloadGroupFile
    },
    {
        id: 2, helpText: "Импорт базы материалов",
        downloadFile: FileService.downloadMaterialsFile
    },
    {
        id: 3,
        helpText: "Импорт цен поставщиков",
        downloadFile: FileService.downloadSupplierMaterialsFile
    },
    {
        id: 4,
        helpText: "Импорт наименований работ(при совпадении имени данные перезаписываются)",
        downloadFile: FileService.uploadSignJobNameFile
    },
    {
        id: 5,
        helpText: "Импорт наименований затрат",
        downloadFile: FileService.uploadJobMetricOrganizationFile
    },
    {
        id: 6,
        helpText: "Импорт затрат по работам",
        downloadFile: FileService.uploadSignJobFile
    },
    {
        id: 7,
        helpText: "Импорт базовых моделей",
        downloadFile: ModelFileService.uploadBasicModelsFile
    },
    {
        id: 8,
        helpText: "Импорт файлов смет",
        downloadFile: ModelFileService.uploadModelEstimatesFiles
    },
    {
        id: 9,
        helpText: "Импорт файлов раскроя",
        downloadFile: ModelFileService.uploadModelRequestsCuttingFiles
    },
    {
        id: 10,
        helpText: "Загрузить модели",
        downloadFile: ModelFileService.uploadModelsFile
    },
    {
        id: 11,
        helpText: "Загрузить замены материалов",
        downloadFile: ModelFileService.uploadReplacementModelMaterialsFile
    },
    {
        id: 12,
        helpText: "Импорт базы материалов",
        downloadFile: AdminMaterialCloudFileService.downloadMaterialsFile
    },
    {
        id: 13,
        helpText: "Импорт групп",
        downloadFile: AdminMaterialCloudFileService.downloadGroupFile
    }]

