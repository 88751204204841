import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const rulesModifyingOptimizationRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/organization/rules-modifying-optimizations'

export default class RulesModifyingOptimizationService {
    static async getAll() {
        return axios.get(rulesModifyingOptimizationRestUrl);
    }

    static async update(data, id) {
        return await axios.put(rulesModifyingOptimizationRestUrl + "/" + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(rulesModifyingOptimizationRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async create(data) {
        return await axios.post(rulesModifyingOptimizationRestUrl,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}