import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../TextFieldValidation";
import {gluing} from "../../../../../data/Gluing";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MaterialPageItemModule from "../../../modul/MaterialPageItemModule";
import ButtonAdd from "../../../button/ButtonAdd";
import ButtonExit from "../../../button/ButtonExit";
import ButtonBack from "../../../button/ButtonBack";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

const steps = [
    'Материал',
    'Способ поклейки',
];

const ModalAddEdges = ({initValue, open, onClose, title, handleSubmit, handleError}) => {
    const allGluing = Object.entries(gluing)
    const [activeStep, setActiveStep] = useState(0)

    const [data, setData] = useState(initValue)

    useEffect(() => {
        setData(initValue)
    }, [initValue])

    const selectGluing = (value) => {
        setData((prev) => {
            return {...prev, edgeType: value}
        })
    }
    const selectMaterial = (material) => {
        setData((value) => {
            return {...value, material: material}
        })
    }
    const submit = () => {
        if (activeStep === 0) {
            setActiveStep(1)
        } else {
            handleSubmit({
                edgeType: data.edgeType, materialId: data.material.id
            })
            setActiveStep(0)
        }
    }
    const reset = () => {
        setActiveStep(0)
        onClose()
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${activeStep === 0 ? "1080px" : "800px"}`,
                height: `${activeStep === 0 ? "716px" : "485px"}`,
                borderRadius: 8,
            }
        }}>
            <IconButton
                aria-label="close"
                onClick={reset}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: 40,
                    height: 40,
                    color: "#06522D",
                })}
            >
                <CloseIcon style={{fontSize: 24}}/>
            </IconButton>
            <Box style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{title}</DialogTitle>
                <Stepper
                    style={{width: 446}}
                    activeStep={activeStep}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                }
                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box sx={{
                height: "32px",
                fontSize: "18px",
                margin: "0px 40px 0px 40px",
                display: "flex",
                alignItems: "center",
                borderBottom: data.material.id ? "#3B8BEC 1px solid" : "transparent 1px solid",
                '& .MuiTypography-root': {
                    fontWeight: (theme) => theme.typography.fontWeight,
                    color: (theme) => theme.palette.text.main,
                    '&:first-of-type': {
                        marginRight: '10px'
                    }
                },
            }}>
                <Typography component="p">{data.material.articleNumber}</Typography>
                <Typography component="p">{data.material.name}</Typography>
            </Box>
            <DialogContent sx={{padding: "0px 20px 0px 40px"}}>
                {activeStep === 0 ?
                    <MaterialPageItemModule
                        isOrganization={false}
                        selectMaterialId={data.material.id}
                        selectMaterial={selectMaterial}
                        subTypeMaterial={"EDGE"}
                        modal={true}
                        handleError={handleError}
                    />
                    :
                    <Box style={{display: "flex", flexDirection: "column"}}>

                        <Box style={{width: 300}}>
                            <RadioGroup
                                defaultValue="female"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={data.edgeType}
                                onChange={(event) => selectGluing(event.target.value)}
                            >
                                {allGluing.map((option) => (
                                        <FormControlLabel
                                            value={option[0]}
                                            control={<Radio/>}
                                            label={option[1]}
                                            sx={{
                                                width: "752px",
                                                height: "36px",
                                                "&:not(:last-of-type)": {
                                                    marginBottom: "4px",
                                                },
                                                borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                                "&:hover": {
                                                    backgroundColor: (theme) => theme.palette.primary.light
                                                }
                                            }
                                            }

                                        />
                                    )
                                )}

                            </RadioGroup>
                        </Box>
                    </Box>}
            </DialogContent>

            <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep !== 0 && <ButtonBack text={"Назад"} onClick={() => setActiveStep(0)}/>} </Box>
                <Box>
                    <ButtonExit text={"Выйти"} onClick={reset}/>
                    <ButtonAdd text={"Выбрать"} onClick={submit} disabled={!data.material.id}/>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
export default ModalAddEdges
