import {Button} from "@mui/material";
import React from "react";
import {ReactComponent as ArrowIcon} from "../../Icons/arrow.svg";

const ButtonArrow = ({isHome}) => {
    return <Button
        sx={{
            position: "absolute",
            right: !isHome && 24,
            left: isHome && 24,
            bottom: 24,
            minWidth: 32,
            height: 32,
            borderRadius: 32,
            backgroundColor: "white",
            cursor: "inherit",
            boxShadow: "0px 3px 12px 0px rgba(40, 65, 155, 0.12)",
        }}>
        <ArrowIcon style={{width: "16px", height: "19px"}}/>

    </Button>
}
export default ButtonArrow