import React, {useEffect, useState} from 'react';
import CustomerOffersTable from "../../../components/UI/table/orders/customer/order/CustomerOffersTable";
import {useLocation, useParams} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import CustomerEstimateService from "../../../API/orders/estimate/customer/CustomerEstimateService";
import {constantsErrorMessages} from "../../../Constants";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CustomerOrderOffers = () => {
    const params = useParams();
    const pathname = useLocation().pathname
    const {customerOrderNumber, orderId} = params;
    const [data, setData] = useState([]);
    const [currents, setCurrents] = useState({});
    const [alertData, setAlertData] = useState({});

    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }

    useEffect(() => {
        getStartPageSize()
        CustomerEstimateService.getAllByOrderId(orderId).then(function (response) {
            const data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
            data.forEach((row) => {
                currents[row.id] = row.estimateState === "PERFORMER_SELECTED" || row.estimateState === "PERFORMER_CONFIRMED";
            });
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Предложение`}
                path={[{name: "Главная", href: "/"},
                    {name: "Заказы", href: "/customer/orders"},
                    {
                        name: `Заказ ${customerOrderNumber}`,
                        href: `/customer/orders/${orderId}/${customerOrderNumber}`
                    }]}/>

            <Typography component="p" sx={{fontSize: "24px", fontWeight: 700, paddingBottom: "30px"}}>
                Предложение заказа {customerOrderNumber}
            </Typography>

            <CustomerOffersTable
                orderId={orderId}
                customerOrderNumber={customerOrderNumber}
                data={data}
                setData={setData}
                currents={currents}
                setCurrents={setCurrents}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </Box>
    );
};

export default CustomerOrderOffers;