import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/job-metric-order-organizations'

export default class JobMetricOrderOrganizationService {

    static async getAllByOrderIdForOrganization(orderId) {
        return axios.get(restUrl + '/by-order-for-organization?orderId=' + orderId);
    }

    static async save(data) {
        return axios.post(restUrl + '?orderId=' + data.orderId + '&jobMetricOrganizationId=' + data.jobMetricOrganizationId + '&materialEstimateUnitId=' + data.materialEstimateUnitId + '&quantity=' + data.quantity, JSON.stringify({}),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        return axios.delete(restUrl + '/' + id);
    }
}