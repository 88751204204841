import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import BasicModelService from "../../../../API/model-estimate/model/BasicModelService";
import ModelService from "../../../../API/model-estimate/model/ModelService";
import {Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BasicModelsTable from "../../../../components/UI/table/model-estimate/model/BasicModelsTable";
import ModelsTable from "../../../../components/UI/table/model-estimate/model/ModelsTable";
import ButtonBack from "../../../../components/UI/button/ButtonBack";
import ButtonExit from "../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../components/UI/button/ButtonAdd";

export const CreateNewModelInOrderModal = ({open, handleClose, handleSubmit, handleError}) => {
    const pathname = useLocation().pathname
    const [activeStep, setActiveStep] = useState(0)
    const [baseData, setBaseData] = useState([]);
    const [data, setData] = useState([]);
    const [baseModel, setBaseModel] = useState({})
    const [model, setModel] = useState({})
    const [idBaseModelLoadedData, setIdBaseModelLoadedData] = useState("")
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 10,
        totalElements: 0,
        searchData: ""
    })
    useEffect(() => {
            getStartPageSize()
            BasicModelService.getAll().then(function (response) {
                let data = response.data;
                setBaseData(data);
                setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
            })
                .catch(function (error) {
                    handleError(error);
                });

        }, [open]
    )
    const onClose = () => {
        handleClose()
        setActiveStep(0)
        setBaseModel({})
        setIdBaseModelLoadedData("")
    }
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const select = (value) => {
        activeStep === 0 ? setBaseModel(value) : setModel(value)
    }
    const onBack = () => {
        setActiveStep(0)
        setTableOptions((prev) => ({...prev, totalElements: baseData.length}))
    }
    const submit = () => {
        if (activeStep === 0) {
            if (idBaseModelLoadedData === baseModel.id) {
                setTableOptions((prev) => ({...prev, totalElements: data.length}))
            } else {
                ModelService.getAllUnableByBasicModelId(baseModel.id).then(function (response) {
                    let data = response.data;
                    setData(data);
                    setIdBaseModelLoadedData(baseModel.id)
                    setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }
            setActiveStep(1)
        } else {
            handleSubmit({
                "moduleType": "STANDARD",
                "originalId": model.id,
                "originalName": model.name,
                "articleNumber": model.articleNumber,
                "barcode": model.barcode
            }, onClose)
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${activeStep === 0 ? "1080px" : "1370px"}`,
                height: "900px",
                borderRadius: 8,
            }
        }}>
            <Box style={{
                padding: 40,
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>Добавить модель</DialogTitle>
                <Stepper
                    style={{width: 446}}
                    activeStep={activeStep}
                >
                    {["Базовая модель", "Модель"].map((label) => (
                        <Step key={label}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                }
                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box sx={{
                height: "32px",
                fontSize: "18px",
                margin: "0px 40px 0px 40px",
                display: "flex",
                alignItems: "center",
                borderBottom: baseModel?.id ? "#3B8BEC 1px solid" : "transparent 1px solid",
                '& .MuiTypography-root': {
                    fontWeight: (theme) => theme.typography.fontWeight,
                    color: (theme) => theme.palette.text.main,
                    '&:first-of-type': {
                        marginRight: '10px'
                    }
                },
            }}>
                <Typography component="p">{baseModel?.name}</Typography>
                <Typography component="p">{baseModel?.headerName}</Typography>
            </Box>
            <DialogContent sx={{padding: "0px 20px 0px 40px"}}>
                {activeStep === 0 ?
                    <BasicModelsTable
                        selectId={baseModel?.id}
                        select={select}
                        modal={true}
                        tableOptions={tableOptions}
                        tableOptionsHandle={tableOptionsHandle}
                        data={baseData}
                        handleError={handleError}
                    />
                    :
                    <ModelsTable
                        selectId={model?.id}
                        select={select}
                        modal={true}
                        tableOptions={tableOptions}
                        tableOptionsHandle={tableOptionsHandle}
                        data={data}/>}
            </DialogContent>
            <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep !== 0 && <ButtonBack text={"Назад"} onClick={onBack}/>} </Box>
                <Box>
                    <ButtonExit
                        text={"Выйти"} onClick={onClose}/>
                    <ButtonAdd
                        text={"Выбрать"} onClick={submit}
                        disabled={(activeStep === 0 && !baseModel?.id) || (activeStep === 1 && !model?.id)}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
};