import React, {useEffect, useState} from 'react';
import CustomerOrderService from "../../../API/orders/orders/customer/CustomerOrderService";
import CustomerOrderTable from "../../../components/UI/table/orders/customer/order/CustomerOrderTable";
import {useLocation} from 'react-router-dom';
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import ButtonSelectAction from "../../../components/UI/button/ButtonSelectAction";
import {ModalSelectAction} from "../../../components/UI/modal/ModalSelectAction";
import {constantsErrorMessages} from "../../../Constants";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";

const CustomerOrders = () => {
    const [rowSelection, setRowSelection] = useState([]);
    const [open, setOpen] = useState(false)
    const location = useLocation();
    const createType = new URLSearchParams(location.search).get('create');
    const [alertData, setAlertData] = useState({});
    const [data, setData] = useState([]);
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`customer/orders`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`customer/orders`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    useEffect(() => {
        getStartPageSize()
        CustomerOrderService.getAllCustomerOrders().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const modalActions = [
        {name: "Переместить в архив", type: "archive"},
    ]
    const handleSelectAction = () => {
        CustomerOrderService.sendToArchive({
            "ids": rowSelection
        })
            .then(function () {
                setData(prev => prev.filter(el => rowSelection.includes(el.id)))
                setTableOptions((prev) => ({...prev, totalElements: data.length-rowSelection.length}))
                setOpen(false)
                setRowSelection([])
            })
            .catch(function (error) {
                handleError(error);
            })
    }
    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent page={"Заказы"} path={[{name: "Главная", href: "/"}]}/>
            <CustomerOrderTable
                createType={createType}
                data={data}
                setData={setData}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleError={handleError}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
            {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: '100%',
                height: "88px",
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.primary.lightGrey,
            }} component="footer">
                <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
            </Paper>}
            <ModalSelectAction
                open={open}
                reset={() => setOpen(false)}
                submit={handleSelectAction}
                modalActions={modalActions}
            />
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </Box>
    );
};

export default CustomerOrders;