import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import CellCheckboxOATUnable from "./CellCheckboxOATUnable";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ButtonCreate from "../../../../button/ButtonCreate";

const SetAttributeOrderTable = ({idOrder, number, idHeader, year, data, setData, tableOptions, tableOptionsHandle}) => {
    const navigate = useNavigate();

    const redirectOrder = () => {
        navigate('/organization/orders/' + idOrder + '/' + number + '/' + idHeader + '/' + year);
    };

    const redirectConstructor = () => {
        navigate('/organization/constructors/');
    };

    const columns = useMemo(
        () => [
            {
                header: 'Найменование',
                accessorKey: 'attributeName',
                minSize: 25,
                enableEditing: false,
            },
            {
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                Cell: ({cell, row}) => <CellCheckboxOATUnable
                    cell={cell} id={idOrder}
                    attributeId={data[row.index].id}></CellCheckboxOATUnable>,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (<Box sx={{maxWidth: "1500px"}}>
        <Box
            sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "30px"}}
        >
            <Typography sx={{fontSize: "24px", fontWeight: 700}}> Установить атрибуты к заказу №: {number}</Typography>
            <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>
                <ButtonCreate
                    text={"Вернуться к заказу"}
                    width={170}
                    icon={false}
                    onClick={redirectOrder}
                />
                <ButtonCreate
                    text={"Вернуться в проектирование"}
                    width={240}
                    icon={false}
                    onClick={redirectConstructor}
                />
            </Box>
        </Box>
        <MaterialReactTable
            muiTopToolbarProps={{
                sx: {
                    "& .MuiBox-root": {
                        padding: 0
                    }
                }
            }}

            muiTableBodyCellProps={({cell, table}) => {
                return ({
                    sx: () => ({
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',
                        },
                        "&": cell.column.id === "requests_cutting" && {
                            width: 'calc(100% - 1px)',
                            padding: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },

                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }

                        }
                    })
                })
            }}

            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem",
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                sx: {
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            initialState={{
                columnVisibility: {
                    id: false,
                },
                isFullScreen: false,
                density: "compact",
                pagination: {pageIndex: 0, pageSize: 20},
                columnPinning: {left: ['number']}
            }}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            state={{showGlobalFilter: true, pagination: tableOptions,}}
            rowCount={tableOptions.totalElements}
            localization={MRT_Localization_RU}
            enableToolbarInternalActions={false}
        /></Box>)
};

export default SetAttributeOrderTable;