import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import ButtonAdd from "../../../../button/ButtonAdd";

const ModalOpenSetValues = ({title, label, modalOpen, handleClose, setValue, handleSubmit}) => {
    return (
        <div>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                        margin="normal"
                        key={label}
                        label={label}
                        name={label}
                        onChange={(e) =>
                            setValue(e.target.value)
                        }
                    />
                </DialogContent>
                <DialogActions style={{display: "flex", justifyContent: "flex-end"}}>
                    <ButtonAdd text={"Применить"} onClick={handleSubmit}/>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalOpenSetValues;