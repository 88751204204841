export const typeMaterial = {
    "SLAB": "Плитный",
    "LINEAR": "Погонный",
    "PIECE": "Штучный",
}

export const typeMaterialWithSubtype = {
    "SLAB": {
        name: "Плитный",
        subtype: {
            "SHEET": "Плитный материал",
            "TABLETOP": "Столешница",
            "WALL_PANEL": "Стеновая панель",
            "GLASS": "Стекло",
            "COMPACT_PLATE": "Компак плита",
        }
    },
    "LINEAR": {
        name: "Погонный", subtype: {"EDGE": "Кромочные материалы"}
    },
    "PIECE": {
        name: "Штучный", subtype: {"ACCESSORIES": "Фурнитура"},
    }
}