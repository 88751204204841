import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_ToggleFiltersButton} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link} from 'react-router-dom';
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import AttributeUtil from "../../../../../../utils/orders/AttributeUtil";
import OrderUtil from "../../../../../../utils/orders/OrderUtil";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip} from "@mui/material";
import TextFieldObjectWithEntityList from "../../../../textfield/TextFieldObjectWithEntityList";
import OrderDatePicker from "../../../../datepicker/OrderDatePicker";
import Box from "@mui/material/Box";
import WorkerService from "../../../../../../API/orders/job/WorkerService";
import {BreadcrumbsComponent} from "../../../../breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import {EditLocalStoragePagesData} from "../../../../../../utils/EditLocalStoragePagesData";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";

const OrderConstructorTable = () => {

    const [data, setData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [constructorList, setConstructorList] = useState([]);
    const [technologistList, setTechnologistList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [dateOfConstructorDocumentation, setDateOfConstructorDocumentationOfReceipt] = useState(null);
    const [dateOfTechnicalDocumentation, setDateOfTechnicalDocumentation] = useState(null);

    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [change, setChange] = useState(null);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`organization/constructors`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`organization/constructors`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    useEffect(() => {
        getStartPageSize()
        OrderService.getOrdersConstructors().then(function (response) {
            setData(response.data);
            setTableOptions((prev) => ({...prev, totalElements: response.data.length}))
            setCustomerList(OrderUtil.getListField(response.data, 'customerName'));
            setConstructorList(OrderUtil.getListFieldObject(response.data, 'constructor'))
            setTechnologistList(OrderUtil.getListFieldObject(response.data, 'technologist'))
            setNameList(OrderUtil.getListField(response.data, 'header'));
            setAttributeList(OrderUtil.getStatuses(response.data, 'attributes'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleUpdate = (row, values) => {
        data[row[currentEditRowId]] = values;
        setData([...data]);
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setDateOfConstructorDocumentationOfReceipt(row.dateOfConstructorDocumentation);
            setDateOfTechnicalDocumentation(row.dateOfTechnicalDocumentation)
            setCurrentEditRowId(row.id);
            setUpdateModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const columns = useMemo(
        () => [
            {
                header: 'Номер заказа',
                accessorKey: 'number',
                filterFn: 'contains',
                enableEditing: false,
                maxSize: 23,
                minSize: 35,
                Cell: ({cell, row}) => (
                    <Link
                        to={'/organization/technical-map/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                ),
            },
            {
                id: 'numberCustomer',
                header: 'Номер заказчика',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
            },
            {
                header: 'Заказчик',
                accessorKey: 'customerName',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                minSize: 200,
            },
            {
                header: 'Найменование',
                accessorKey: 'header',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                enableEditing: false,
                minSize: 15,
            },
            {
                id: 'model',
                header: 'Модель',
                accessorKey: 'model',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                enableEditing: false,
                minSize: 300,
            },
            {
                id: 'attributes',
                header: 'Атрибуты',
                accessorKey: 'attributes',
                filterVariant: 'multi-select',
                filterSelectOptions: attributeList,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkStatus(row.getValue(id), filterValue)
                },
                minSize: 20,
                enableEditing: false,
                Cell: ({cell, row}) => (
                    <Link
                        to={'/organization/orders/set-attribute/' + data[row.index].id + '/' + data[row.index].number + '/' + data[row.index].header.id}
                    >{data[row.index].attributes.length === 0 ? 'Не устанволен' : AttributeUtil.convertAttributes(cell.getValue())}</Link>
                ),

            },
            {
                accessorFn: (row) => {
                    if (row.dateOfPlannedProduction === null) {
                    } else {
                        return new Date(row.dateOfPlannedProduction)
                    }
                },
                id: 'dateOfPlannedProduction',
                header: 'Плановая дата произодства',
                sortingFn: 'datetime',
                minSize: 150,
                enableEditing: false,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                header: 'Констуктор',
                accessorKey: 'constructor.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: constructorList,
                maxSize: 100,
                minSize: 100,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfConstructorDocumentation === null) {
                    } else {
                        return new Date(row.dateOfConstructorDocumentation)
                    }
                },
                id: 'dateOfConstructorDocumentation',
                header: 'Дата тех. документации конструктор',
                sortingFn: 'datetime',
                minSize: 250,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                header: 'Технолог',
                accessorKey: 'technologist.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: technologistList,
                maxSize: 20,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfTechnicalDocumentation === null) {
                    } else {
                        return new Date(row.dateOfTechnicalDocumentation)
                    }
                },
                id: 'dateOfTechnicalDocumentation',
                header: 'Дата тех. документации технолог',
                sortingFn: 'datetime',
                minSize: 250,
                filterVariant: 'date-range',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString('en-GB');
                    }
                },
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleUpdateModel}]

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Проектирование"}
                path={[
                    {name: "Главная", href: "/"},
                ]}/>
            <Box sx={{maxWidth: "2500px", display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Проектирование
                    </Typography>
                </Box>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='ru'
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <MaterialReactTable
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: 0
                                }
                            }
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                p: '0px 0px 0px 10px',
                                m: '0px 0px 0px 0px',
                                border: "none",
                                "&:not(:last-of-type)": {
                                    borderRight: '1px solid #EAEBEA',

                                },
                                "&:first-of-type": {
                                    padding: 0,
                                    width: "50px",
                                    height: "36px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: (theme) => theme.palette.text.light,
                                    "& .MuiButtonBase-root": {
                                        borderRadius: "4px",
                                        width: "24px",
                                        height: "24px",
                                        opacity: 1,
                                        margin: 0,
                                        padding: 0,
                                        "&:hover": {
                                            backgroundColor: (theme) => theme.palette.primary.stroke,
                                        }
                                    }
                                },
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }

                                }
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            sx: {
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: "",
                                size: 3,
                                minSize: 3,
                                grow: false,
                            },
                        }}
                        columns={columns}
                        data={data}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{showGlobalFilter: true, pagination: tableOptions,}}
                        rowCount={tableOptions.totalElements}
                        initialState={{
                            columnVisibility: {
                                id: false,
                            },
                        }}
                        muiToolbarAlertBannerChipProps={{color: 'primary'}}
                        localization={MRT_Localization_RU}
                        enableRowActions={true}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={({table}) => (
                            <Box sx={{
                                width: "100%",
                                padding: 0,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItem: "center"
                            }}>
                                <MRT_ToggleFiltersButton table={table}/>
                            </Box>)}
                        renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                            return <MenuItem
                                key={el.type}
                                onClick={() => {
                                    el.handler(row.original)
                                    closeMenu();
                                }}
                                sx={{
                                    margin: "0 8px",
                                    borderRadius: "4px",
                                    "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                                }}
                            >
                                {el.text}
                            </MenuItem>
                        })}
                    />
                </LocalizationProvider>
            </Box>
            <UpdateOrderModal
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                onSubmit={handleUpdate}
                values={currentEditRowData}
                currentEditRowId={currentEditRowId}
                change={change}
                setChange={setChange}
                setValues={setCurrentEditRowData}
                dateOfConstructorDocumentation={dateOfConstructorDocumentation}
                setDateOfConstructorDocumentationOfReceipt={setDateOfConstructorDocumentationOfReceipt}
                dateOfTechnicalDocumentation={dateOfTechnicalDocumentation}
                setDateOfTechnicalDocumentation={setDateOfTechnicalDocumentation}
            />
        </Box>
    );
};

export const UpdateOrderModal = ({
                                     open, onClose,
                                     onSubmit,
                                     values,
                                     setValues,
                                     change,
                                     setChange,
                                     dateOfConstructorDocumentation,
                                     setDateOfConstructorDocumentationOfReceipt,
                                     dateOfTechnicalDocumentation,
                                     setDateOfTechnicalDocumentation,
                                 }) => {

    const [constructorList, setConstructorList] = useState([]);
    const [constructorId, setConstructorId] = useState(null);
    const [technologistId, setTechnologistId] = useState(null);


    useEffect(() => {

        WorkerService.getAllEnabledAndConstructor().then(function (response) {
            let data = response.data;
            setConstructorList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleSubmit = () => {

        values.dateOfConstructorDocumentation = dateOfConstructorDocumentation === null ?
            null : DateTimeUtil.dateConvert(dateOfConstructorDocumentation);
        values.dateOfTechnicalDocumentation = dateOfTechnicalDocumentation === null ?
            null : DateTimeUtil.dateConvert(dateOfTechnicalDocumentation);

        OrderService.updateConstructor(values,
            constructorId === null ?
                (constructorList === null ? null : constructorList[0].id) : constructorId,
            technologistId === null ?
                (constructorList === null ? null : constructorList[0].id) : technologistId,
        ).then(function (response) {
            onSubmit(response.data);
            setChange(!change)
            onClose();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Редактировать заказ</DialogTitle>
            <DialogContent sx={{padding: "0 20px"}}>
                <Stack
                    sx={{
                        width: '100%',
                        minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    }}
                >
                    <TextFieldObjectWithEntityList
                        label="Конструктор"
                        values={values}
                        field={'constructor'}
                        setId={setConstructorId}
                        entityList={constructorList}
                    />
                    <OrderDatePicker
                        label={'Дата тех. документации конструктор'}
                        date={dateOfConstructorDocumentation}
                        setDate={setDateOfConstructorDocumentationOfReceipt}
                    />
                    <TextFieldObjectWithEntityList
                        label="Технолог"
                        values={values}
                        field={'technologist'}
                        setId={setTechnologistId}
                        entityList={constructorList}
                    />
                    <OrderDatePicker
                        label={'Дата тех. документации технолог'}
                        date={dateOfTechnicalDocumentation}
                        setDate={setDateOfTechnicalDocumentation}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={onClose}/>
                <ButtonAdd text={"Сохранить"} onClick={handleSubmit}/>
            </DialogActions>
        </Dialog>
    );
};

export default OrderConstructorTable;