import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import AddMaterialPriceParameters from "./AddMaterialPriceParameters";

const ModalUpdateMaterialPriceUnit = ({
                                          open,
                                          onClose,
                                          values,
                                          setValues,
                                          currentCurrency,
                                          baseCurrency,
                                          basePriceCurrency,
                                          alternativeCurrency,
                                          isBaseCurrency,
                                          isAlternativeCurrency,
                                          handleSubmit,
                                          isSlabMaterial,
                                          typeSlabMaterial,
                                          isNew
                                      }) => {

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{"Редактировать приход материала"}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <AddMaterialPriceParameters
                        isNew={isNew}
                        values={values}
                        setValues={setValues}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                        currentCurrency={currentCurrency}
                        basePriceCurrency={basePriceCurrency}
                        typeSlabMaterial={typeSlabMaterial}
                        isSlabMaterial={isSlabMaterial}
                        isBaseCurrency={isBaseCurrency}
                        isAlternativeCurrency={isAlternativeCurrency}/>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit
                    text={"Выйти"} onClick={onClose}/>
                <ButtonAdd
                    text={"Сохранить"} onClick={handleSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ModalUpdateMaterialPriceUnit;