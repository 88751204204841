import React, {useEffect, useState} from 'react';
import BasicModelsTable from "../../../../components/UI/table/model-estimate/model/BasicModelsTable";
import BasicModelService from "../../../../API/model-estimate/model/BasicModelService";
import {EditLocalStoragePagesData} from "../../../../utils/EditLocalStoragePagesData";
import {constantsErrorMessages} from "../../../../Constants";
import ModalAlert from "../../../../components/UI/table/customer-detales/ModalAlert";
import {useLocation} from "react-router-dom";
import {Typography} from "@mui/material";

const BasicModels = ({itemId, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});
    const [change, setChange] = useState(null);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    useEffect(() => {
            getStartPageSize()
            BasicModelService.getAll().then(function (response) {
                let data = response.data;
                setData(data);
                setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
                data.forEach((row) => {
                    unable[row.id] = row.enabled;
                });
            })
                .catch(function (error) {
                    handleError(error);
                });

        }, [change]
    )
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+${itemId}`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`${pathname}+${itemId}`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    return (
        <div>
            <Typography sx={{fontSize: "24px", fontWeight: 700}}>Базовые модели</Typography>
            <BasicModelsTable
                data={data}
                setData={setData}
                unable={unable}
                setUnable={setUnable}
                change={change}
                setChange={setChange}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
                handleError={handleError}
            />
        </div>
    );
};

export default BasicModels;