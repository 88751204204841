import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {ReactComponent as SawIcon} from "../../../../../components/Icons/processing.svg";
import {useNavigate} from "react-router-dom";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import ModalUpdateStatus from "../ModalUpdateStatus";
import OptimizationService from "../../../../../API/orders/optimization/organization/OptimizationService";
import {optimizationStateStatuses} from "../../../../../data/OptizationState";
import ModalOpenCreatePlanOrOptimization from "../plans/ModalOpenCreatePlanOrOptimization";
import selectionTableUtil from "../../../../../utils/common/SelectionTableUtil";
import MenuItem from "@mui/material/MenuItem";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import ButtonCreate from "../../../button/ButtonCreate";
import Crop32Icon from '@mui/icons-material/Crop32';

const OptimizationTable = ({
                               data,
                               setData,
                               isCreateCreateOptimization,
                               isRedirectPlans,
                               isCalculation,
                               rowSelection,
                               setRowSelection,
                               tableOptions,
                               tableOptionsHandle,
                               handleError,
                           }) => {
        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentAllowedStatuses, setCurrentAllowedStatuses] = useState(['ARCHIVE']);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [addModalOpen, setAddModalOpen] = useState(false);
        const [newRowData, setNewRowData] = useState(null);
        const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.id;
                OptimizationService.delete(id)
                    .then(function () {
                        setData(prev => prev.filter(el => el.id !== id))
                        tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                    })
                    .catch(function (error) {
                        handleError(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentAllowedStatuses([...currentAllowedStatuses, row.optimizationState]);
                setCurrentEditRowData(row);
                setCurrentEditRowId(row.id);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleClose = () => {
            currentAllowedStatuses.splice(1, 1);
            setUpdateModalOpen(false)
        };

        const navigate = useNavigate();

        const redirectOptimizationsCalculationArchive = () => {
            navigate('/organization/optimizations-calculation-archive');
        };

        const redirectOptimizationsArchive = () => {
            navigate('/organization/optimizations-archive');
        };

        const handleCreateOptimization = () => {
            const optimization = {
                id: null,
                number: "OP",
                optimizationState: 'EXPECTATION',
                optimized: null,
            }
            setNewRowData(optimization);
            setAddModalOpen(true)
        };

        const createOptimization = () => {
            OptimizationService.createOptimization(newRowData,).then(function (response) {
                handleCreateNewRow(response.data);
                setAddModalOpen(false)
            })
                .catch(function (error) {
                    handleError(error);
                });
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const columns = useMemo(
            () => [
                {
                    id: 'number',
                    header: 'Номер',
                    accessorKey: 'number',
                    minSize: 300,

                },
                {
                    id: 'requests_cutting',
                    header: 'Заявки на раскрой',
                    accessorKey: 'id',
                    minSize: "50px",
                    enableSorting: false,
                    enableColumnActions: false,
                    Cell: ({row}) => (
                        <Button
                            onClick={() => navigate(`/organization/requests-cutting/optimization/${row.original.id}/${row.original.number}${isCalculation ? "/calculation" : ""}`)}>
                            <SawIcon style={{width: "30px", height: "30px"}}/>
                        </Button>
                    ),
                },
                {
                    id: 'details_cutting',
                    header: 'Детали на раскрой',
                    accessorKey: 'id',
                    minSize: "50px",
                    enableSorting: false,
                    enableColumnActions: false,
                    Cell: ({row}) => (
                        <Button
                            sx={{padding: 0}}
                            onClick={() => navigate(`/organization/details/optimization/${row.original.id}/${row.original.number}`)}>
                            <Crop32Icon style={{color: "black", fontSize: "42px"}}/>
                        </Button>
                    ),
                },
                {
                    accessorFn: (row) => {
                        if (row.optimized === null) {
                        } else {
                            return new Date(row.optimized)
                        }
                    },
                    id: 'optimized',
                    header: 'Дата оптимизации',
                    sortingFn: 'optimized',
                    size: 300,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        const startDate = filterValue?.startDate;
                        const endDate = filterValue?.endDate;
                        if (startDate && endDate) {
                            return (
                                DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                                DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                            );
                        }
                        return true;
                    },
                    Filter: ({column}) => <DateRangeFilter
                        column={column}
                    />,
                },
                {
                    id: 'optimizationState',
                    header: 'Статус',
                    accessorKey: 'optimizationState',
                    minSize: 350,
                    Cell: ({cell}) => optimizationStateStatuses[cell.getValue()],
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                },
            ],
            [data, setData],
        );
        const menuItem = [
            {type: "edit", text: "Редактировать", handler: handleUpdateModel},
            {type: "delete", text: "Удалить", handler: handleDeleteRow}
        ]

        return (
            <Box sx={{maxWidth: "1500px"}}>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
                >
                    <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>
                        <ButtonCreate
                            text={"Заказы"}
                            width={70}
                            icon={false}
                            onClick={() => navigate('/organization/orders')}
                        />
                        {!isCalculation && <ButtonCreate
                            text={"Заявки на раскрой"}
                            width={160}
                            icon={false}
                            onClick={() => navigate('/organization/requests-cutting')}
                        />}
                        {isCalculation && <ButtonCreate
                            text={"Заявки на просчет"}
                            width={160}
                            icon={false}
                            onClick={() => navigate('/organization/requests-cutting/calculation')}
                        />}

                        {!isCalculation && <ButtonCreate
                            text={"Архив"}
                            width={70}
                            icon={false}
                            onClick={isCalculation ? redirectOptimizationsCalculationArchive : redirectOptimizationsArchive}
                        />}
                        {isRedirectPlans && <ButtonCreate
                            text={"Планы"}
                            width={70}
                            icon={false}
                            onClick={() => navigate('/organization/plans')}
                        />}
                        {!isCalculation && <ButtonCreate
                            text={"Карты раскроя"}
                            width={130}
                            icon={false}
                            onClick={() => navigate('/organization/working-cutting-maps')}
                        />}
                        {isCalculation && <ButtonCreate
                            text={"Расчетные карты раскроя"}
                            width={220}
                            icon={false}
                            onClick={() => navigate('/organization/working-cutting-maps-calculation')}
                        />}
                        {isCreateCreateOptimization && <ButtonCreate
                            text={"Создать оптимизацию"}
                            width={230}
                            onClick={handleCreateOptimization}
                        />}
                    </Box>
                </Box>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='ru'
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <MaterialReactTable
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: Object.keys(rowSelection).length !== 0 ? `calc(100vh - 600px)` : `calc(100vh - 520px)`,
                                width: "1500px"
                            }
                        }}
                        muiTopToolbarProps={{
                            sx: {
                                "& .MuiBox-root": {
                                    padding: 0
                                }
                            }
                        }}
                        muiTableBodyCellProps={({cell, table}) => {
                            return ({
                                sx: () => ({
                                    p: '0px 0px 0px 10px',
                                    m: '0px 0px 0px 0px',
                                    border: "none",
                                    "& td:after": {
                                        "&:hover": {
                                            backgroundColor: "red"
                                        }
                                    },
                                    "&:not(:last-of-type)": {
                                        borderRight: '1px solid #EAEBEA',
                                    },
                                    "&": (cell.column.id === "requests_cutting" || cell.column.id === "details_cutting") && {
                                        width: 'calc(100% - 1px)',
                                        padding: "0 50px"
                                    },

                                    "&:first-of-type": {
                                        padding: 0,
                                        width: "50px",
                                        height: "36px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: (theme) => theme.palette.text.light,
                                        "& .MuiButtonBase-root": {
                                            borderRadius: "4px",
                                            width: "24px",
                                            height: "24px",
                                            opacity: 1,
                                            margin: 0,
                                            padding: 0,
                                            "&:hover": {
                                                backgroundColor: (theme) => theme.palette.primary.stroke,
                                            }
                                        }
                                    },

                                    "& a": {
                                        color: "#006CCC",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline"
                                        }

                                    }
                                })
                            })
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                border: '1px solid #EAEBEA',
                                backgroundColor: "#F5F6FA",
                                padding: "10px",
                                paddingBottom: " 0.2rem",
                                paddingTop: "0.2rem",
                                "&:first-of-type .Mui-TableHeadCell-Content-Wrapper": {
                                    "minWidth": "100%"
                                }
                            },
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return ({
                                sx: () => ({
                                    height: '36px',
                                    backgroundColor: "white",
                                    "&:hover& td:after": {
                                        display: "none",
                                    },
                                    "&:hover": {
                                        "& td:after": {
                                            display: "none",
                                        },
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                    },
                                })
                            })
                        }}

                        muiSearchTextFieldProps={{
                            placeholder: `Поиск`,
                            sx: {
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                "& .MuiCollapse-root": {
                                    borderRadius: '12px',
                                    height: 40, width: 290,
                                    "& .MuiSvgIcon-root": {
                                        color: "#737478"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0
                                    }
                                }
                            },
                            variant: 'outlined',
                        }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: "",
                                size: 3,
                                minSize: 3,
                                grow: false,
                            },
                            'mrt-row-select': {
                                size: "50px",
                                grow: false,
                            },
                        }}
                        columns={columns}
                        data={data}
                        enableRowSelection
                        rowHeight={13}
                        columnSpacing={1}
                        initialState={{
                            columnVisibility: {
                                id: false,
                            },
                            isFullScreen: false,
                            density: "compact",
                        }}
                        onRowSelectionChange={onRowSelectionChange}
                        getRowId={(originalRow) => originalRow.id}
                        onPaginationChange={(updater) => {
                            tableOptionsHandle(updater(tableOptions));
                        }}
                        state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions,}}
                        rowCount={tableOptions.totalElements}
                        selectionPadding={1}
                        enableRowActions={true}
                        enableToolbarInternalActions={false}
                        selectionCss={{
                            backgroundColor: 'lightblue',
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            borderColor: 'blue',
                            borderRadius: '4px',
                        }}
                        renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                            return <MenuItem
                                key={el.type}
                                disabled={el.type === "delete" && (row.original.optimizationState !== 'CALCULATION'
                                    && row.original.optimizationState !== 'EXPECTATION'
                                    && row.original.optimizationState !== 'NOT_CUTTING_REQUESTS')}
                                onClick={() => {
                                    el.handler(row.original)
                                    closeMenu();
                                }}
                                sx={{
                                    margin: "0 8px",
                                    borderRadius: "4px",
                                    "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                                }}
                            >
                                {el.text}
                            </MenuItem>
                        })}
                        localization={MRT_Localization_RU}
                    />
                </LocalizationProvider>
                <ModalUpdateStatus
                    title={'Обновить статус'}
                    data={data}
                    setData={setData}
                    open={updateModalOpen}
                    handleClose={handleClose}
                    currentEditRowId={currentEditRowId}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    currentAllowedStatuses={currentAllowedStatuses}
                    statusesList={optimizationStateStatuses}
                    field={'optimizationState'}
                    unableStatus={OptimizationService.unableStatus}
                />
                <ModalOpenCreatePlanOrOptimization
                    label={'Название оптимизации'}
                    field={'number'}
                    values={newRowData}
                    setValues={setNewRowData}
                    title={'Создать оптимизацию'}
                    modalOpen={addModalOpen}
                    handleClose={() => setAddModalOpen(false)}
                    handleSubmit={createOptimization}
                />

            </Box>
        );
    }
;

export default OptimizationTable;