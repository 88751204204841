import React, {useEffect, useState} from 'react';
import OrderProductionTable from "../../../components/UI/table/orders/organization/production/OrderProductionTable";
import OrderService from "../../../API/orders/orders/organization/OrderService";
import OrderUtil from "../../../utils/orders/OrderUtil";
import TypeJobService from "../../../API/orders/orders/organization/TypeJobService";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/system";

const OrdersProduction = () => {

    const [data, setData] = useState([]);
    const [additionallyColumn, setAdditionallyColumn] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData("organization/orders-production", "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData["organization/orders-production"]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }
    useEffect(() => {
        getStartPageSize()
        TypeJobService.getAllByOrganizationId().then(function (response) {
            const data = response.data
            data.sort((a, b) => b.outputPriority - a.outputPriority);
            setAdditionallyColumn(data);
        })
            .catch(function (error) {
                console.log(error);
            });

        OrderService.getOrdersProduction().then(function (response) {
            const data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
            setCustomerList(OrderUtil.getListField(data, 'customerName'));
            setNameList(OrderUtil.getListField(data, 'header'));
            setAttributeList(OrderUtil.getStatuses(data, 'attributes'));
            setPlanningList(OrderUtil.getListField(data, 'state'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={`Производство`}
                path={[{name: "Главная", href: "/"}]}/>
            <OrderProductionTable
                data={data}
                setData={setData}
                customerList={customerList}
                nameList={nameList}
                attributeList={attributeList}
                planningList={planningList}
                isColumnPlan={true}
                additionallyColumn={additionallyColumn}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
        </Box>
    );
};

export default OrdersProduction;