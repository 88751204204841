import axios from "axios";
import {apiVersionUtility, utility} from "../../Constants";

export default class UtilityHelpService {GET
    static async getThemeHelpUser() {
        return axios.get(`${utility}${apiVersionUtility}/user/theme-help`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async getHelpUser() {
        return axios.get(`${utility}${apiVersionUtility}/user/help`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async get() {
        return axios.get(`${utility}${apiVersionUtility}/admin/help`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async add(data) {
        return await axios.post(`${utility}${apiVersionUtility}/admin/help`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        return await axios.put(`${utility}${apiVersionUtility}/admin/help/${id}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        return await axios.delete(`${utility}${apiVersionUtility}/admin/help/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static async getThemeHelp() {
        return axios.get(`${utility}${apiVersionUtility}/admin/theme-help`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addThemeHelp(data) {
        return await axios.post(`${utility}${apiVersionUtility}/admin/theme-help`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateThemeHelp(data, id) {
        return await axios.put(`${utility}${apiVersionUtility}/admin/theme-help/${id}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteThemeHelp(id) {
        return await axios.delete(`${utility}${apiVersionUtility}/admin/theme-help/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
}