import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import {materialWarehouseStatuses} from "../../../../../data/MaterialWarehouseState";
import ModalConfirm from "../../../modal/ModalConfirm";
import ModalAddMaterialRetailIssuingEdge from "./ModalAddMaterialRetailIssuingEdge";
import {Link} from "react-router-dom";
import IssuingEdgeService from "../../../../../API/orders/prodaction.issueedge/issueedge/IssuingEdgeService";
import MenuItem from "@mui/material/MenuItem";

const IssuingEdgeWareHouseTable = ({
                                       data,
                                       setData,
                                       idIssuingEdge,
                                       change,
                                       setChange,
                                       tableOptions,
                                       tableOptionsHandle,
                                       handleError
                                   }) => {

        const [confirmIssueModalOpen, setConfirmIssueModalOpen] = useState(false);
        const [modalAddMaterialRetailIssuingEdgeOpen, setModalAddMaterialRetailIssuingEdgeOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [selectedRows, setSelectedRows] = useState([]);
        const [selectedIds, setSelectedIds] = useState([]);
        const [rowSelection, setRowSelection] = useState({});

        const [availability, setAvailability] = useState({});
        const [issue, setIssue] = useState({});

        const handleSubmitConfirmIssue = () => {

            IssuingEdgeService.issuanceEdgeByIds(idIssuingEdge, selectedIds).then(function () {
                setChange(!change)
                setConfirmIssueModalOpen(false);
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        const getColor = (row) => {
            if (row.original.free < 0) {
                return 'red';
            } else {
                if (row.original.availabilityQuantity === 0) {
                    return 'red';
                } else {
                    if (row.original.currentIssueReserve === 0 && row.original.free === 0) {
                        return 'red';
                    } else {
                        if (row.original.currentIssueReserve >= 0 && row.original.free === 0 && row.original.otherIssueReserve > 0 && row.original.totalIssued > row.original.currentIssueReserve) {
                            return 'red';
                        } else {
                            if (row.original.currentIssueReserve >= 0 && row.original.free >= 0 && row.original.otherIssueReserve > 0) {
                                return 'orange';
                            } else {
                                if (row.original.quantity !== row.original.availabilityQuantity) {
                                    return 'orange';
                                } else {
                                    if (row.original.currentIssueReserve >= 0 && row.original.free >= 0 && row.original.otherIssueReserve === 0) {
                                        return 'green';
                                    } else {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const handleCloseConfirmIssue = () => {
            setConfirmIssueModalOpen(false)
        };

        const handleCloseModalAddMaterialRetailIssuingEdge = () => {
            setModalAddMaterialRetailIssuingEdgeOpen(false)
        };

        const openRetailIssue = useCallback(
            (row) => {
                const subtractSumIssueOtherReserve = row.otherIssueReserve - row.sumIssueOtherReserve
                const currentAvailability = row.availabilityQuantity - subtractSumIssueOtherReserve;
                const currentIssue = currentAvailability - row.free;
                setCurrentEditRowData(row);
                setCurrentEditRowId(row.id);
                setAvailability(currentAvailability)
                setIssue(currentIssue)
                setModalAddMaterialRetailIssuingEdgeOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleSubmitConfirmRetailIssue = () => {

            IssuingEdgeService.issuanceEdgeRetail(currentEditRowData.id, issue, idIssuingEdge).then(function () {
                setChange(!change)
                handleCloseModalAddMaterialRetailIssuingEdge();
            })
                .catch(function (error) {
                    handleError(error);
                });
        }


        const columns = useMemo(() => {

            const baseColumns = [
                {
                    id: 'nameMaterial',
                    header: 'Найменование',
                    accessorKey: 'nameMaterial',
                    minSize: 10,
                    maxSize: 10,
                },
                {
                    id: 'quantity',
                    header: 'Кол.',
                    accessorKey: 'quantity',
                    minSize: 10,
                    maxSize: 10,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        const color = getColor(row);
                        return (
                            <span style={{color: color}}>
                {value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}
            </span>
                        );
                    },
                },
                {
                    id: 'availabilityQuantity',
                    header: 'Нал.',
                    accessorKey: 'availabilityQuantity',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                    },
                },
                {
                    id: 'currentIssueReserve',
                    accessorKey: 'currentIssueReserve',
                    size: 8,
                    maxSize: 8,
                    header: 'Тек. выд.',
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                    },
                },
                {
                    id: 'free',
                    header: 'Своб.',
                    accessorKey: 'free',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return <span style={{
                            color: value > 0 ? "green" : "black"
                        }}>{value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}</span>
                    }
                },
                {
                    id: 'otherIssueReserve',
                    header: 'Проч. выд.',
                    accessorKey: 'otherIssueReserve',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                    },
                },
                {
                    id: 'sumIssueCurrent',
                    header: 'Выдано в текущем',
                    accessorKey: 'sumIssueCurrent',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        return <li key={value}>
                            <Link
                                to={'/organization/' + idIssuingEdge + '/issuance-warehouse-material/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(2)}</Link>
                        </li>
                    },
                },
                {
                    id: 'totalIssued',
                    header: 'Выд. всего',
                    accessorKey: 'totalIssued',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        return <li key={value}>
                            <Link
                                to={'/organization/issuance-warehouse-material/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(2)}</Link>
                        </li>
                    },
                },
                {
                    id: 'overspending',
                    header: 'Перерасход',
                    accessorKey: 'overspending',
                    size: 8,
                    maxSize: 8,
                },
                {
                    id: 'materialWarehouseState',
                    header: 'Статус',
                    accessorKey: 'materialWarehouseState',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => materialWarehouseStatuses[cell.getValue()],
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    enableHiding: false,
                },
                {
                    id: 'warehouse',
                    header: 'Склад',
                    accessorKey: 'warehouse',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({cell, row}) => row.original.warehouse === null ? '' : cell.getValue(),
                },
                {
                    id: 'note',
                    header: 'Ком.',
                    accessorKey: 'note',
                    size: 8,
                    maxSize: 8,
                },
            ];

            return baseColumns;

        }, [data, setData,]);

        const menuItem = [
            {type: "add", text: "Выдать штучно", handler: openRetailIssue},
        ]

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            },

                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem"
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            sx: () => ({
                                height: '36px',
                                backgroundColor: "white",

                                "&:hover": {
                                    "& td:after": {
                                        display: "none",
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey,

                                },
                            })
                        })
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableBodyCellProps: {
                                sx: {
                                    width: "50px",
                                    "&.MuiTableCell-root": {
                                        padding: '0px 0px 0px 0px',
                                        border: "none",
                                        borderRight: '1px solid #EAEBEA'
                                    }
                                }
                            },
                            header: "",
                            size: 10,
                            grow: false,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        backgroundColor: "red",
                        sx: {
                            backgroundColor: "red",
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    muiToolbarAlertBannerProps={{
                        sx: {
                            backgroundColor: (theme) => theme.palette.primary.light,
                        }
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        sorting: [
                            {
                                id: 'nameMaterial',
                                desc: true,
                            },],
                        grouping: ['nameMaterial'],
                        expanded: true,
                    }}
                    enableGrouping={true}
                    enableToolbarInternalActions={false}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    rowCount={tableOptions.totalElements}
                    state={{showGlobalFilter: true, pagination: tableOptions,}}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        const active = row.original.availabilityQuantity > 0 && !(row.original.otherIssueReserve > 0 && row.original.free === 0 && row.original.currentIssueReserve === 0)
                        return <Tooltip arrow placement="left"
                                        title={!active ? "заблокировано" : ""}>
                            <MenuItem
                                key={el.type}
                                onClick={() => {
                                    active && el.handler(row.original)
                                    closeMenu();
                                }}
                                sx={{
                                    margin: "0 8px",
                                    borderRadius: "4px",
                                    opacity: !active && 0.5,
                                    "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                                }}
                            >
                                {el.text}
                            </MenuItem>
                        </Tooltip>
                    })}
                    localization={MRT_Localization_RU}
                    enableRowActions={true}
                />
                <ModalConfirm
                    title={"Подтвердить выдачу?"}
                    modalOpen={confirmIssueModalOpen}
                    handleClose={handleCloseConfirmIssue}
                    handleSubmit={handleSubmitConfirmIssue}
                    action={"Подтвердить"}
                />
                <ModalAddMaterialRetailIssuingEdge
                    open={modalAddMaterialRetailIssuingEdgeOpen}
                    onClose={handleCloseModalAddMaterialRetailIssuingEdge}
                    titleModal={"Выдать материал в количестве"}
                    availability={availability}
                    issue={issue}
                    setIssue={setIssue}
                    submit={handleSubmitConfirmRetailIssue}
                />
            </div>
        );
    }
;

export default IssuingEdgeWareHouseTable;