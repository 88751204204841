import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import FilterUtil from "../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../сheckbox/CellCheckboxWithUnable";
import ModalAddDetail from "./ModalAddDetail";
import {Link, useLocation, useNavigate} from "react-router-dom";
import DetailControllerService from "../../../../API/details/DetailControllerService";
import ModalAlert from "./ModalAlert";
import {constantsErrorMessages} from "../../../../Constants";
import ButtonCreate from "../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {Paper, Tooltip} from "@mui/material";
import ButtonSelectAction from "../../button/ButtonSelectAction";
import {ModalSelectAction} from "../../modal/ModalSelectAction";
import PackagingControllerService from "../../../../API/details/PackagingControllerService";
import SamplesControllerService from "../../../../API/samples/Customers/SamplesController";
import ModalImport from "./processing/ModalImport";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalSeeDetail from "./SeeDetail";
import ModalAddBySamples from "./ModalAddBySamples";
import SamplesControllerServiceAdmin from "../../../../API/samples/Admin/SamplesControllerAdmin";

const CustomerDetailsTable = ({
                                  data,
                                  materialTreeData,
                                  setChange,
                                  isError,
                                  unableTurn,
                                  isColumnMaterial,
                                  isColumnOrder,
                                  enableGrouping,
                                  columnGrouping,
                                  setData,
                                  orderId,
                                  requestsCuttingId,
                                  requestsCuttingNumber,
                                  customerOrderNumber,
                                  tableOptions,
                                  tableOptionsHandle,
                                  samples = false,
                                  system,
                                  admin,
                                  modal = false,
                                  selectedSamples,
                                  setSelectedSamples
                              }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const materialId = new URLSearchParams(location.search).get('materialId');
        const redirectProcessing = (el) => {
            samples ?
                navigate(`/customer/samples/details/${el.sampleId}`) :
                requestsCuttingId ?
                    navigate(`/customer/orders/${orderId}/${customerOrderNumber}/requests-cutting/${requestsCuttingId}/${requestsCuttingNumber}/details/${el.id}`) :
                    navigate(`/customer/orders/${orderId}/${customerOrderNumber}/requests-cutting/details/${el.id}`);
        };
        const [open, setOpen] = useState(false)
        const [modalImportOpen, setModalImportOpen] = useState(false)
        const [seeDetailData, setSeeDetailData] = useState({position: null, data: null})
        const popoverOpen = Boolean(seeDetailData.position);

        const [modalSamplesOpen, setModalSamplesOpen] = useState(false)

        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [alertData, setAlertData] = useState({});

        const rowInitValue = {position: "", name: "", length: "", height: "18", width: "", quantity: ""}

        const [rowSelection, setRowSelection] = useState({});
        const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [initValueGroup, setInitValueGroup] = useState({})
        const [isNew, setIsNew] = useState(null);

        const uniqueNames = data.map(el => el.position)

        const handleCloseAlert = () => {
            setAlertData((alert) => {
                return {...alert, open: false}
            })
        }
        const handleError = (error) => {
            setAlertData({
                type: 'error',
                open: true,
                message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
            })
        }

        const handleUpdateDetail = (row) => {
            if (samples) {
                const {detailDto, ...groupData} = row;
                setCurrentEditRowData((prev) => {
                    return Object.keys(prev).reduce((acc, it) => ({...acc, [it]: detailDto[it]}), {})
                });
                setInitValueGroup({id: groupData.groupId, name: groupData.name})
                setCurrentEditRowId(row.sampleId);
            } else {
                setCurrentEditRowData((prev) => {
                    return Object.keys(prev).reduce((acc, it) => ({...acc, [it]: row[it]}), {})
                });
                setCurrentEditRowId(row.id);
            }

            setIsNew(false);
            setCreateOrUpdateModalOpen(true);
        }

        const handleDeleteDetail = (row) => {
            if (samples) {
                const api = admin ? SamplesControllerServiceAdmin : SamplesControllerService
                api.delete(row.sampleId).then(function (response) {
                    setChange(change => !change)
                })
                    .catch(function (error) {
                        handleError(error)
                    })

            } else {
                DetailControllerService.delete(orderId, row)
                    .then(function () {
                        setData(prev => prev.filter(el => el.id !== row.id))
                    })
                    .catch(function (error) {
                        handleError(error)
                    })
            }
        };

        const handleAddDetail = () => {
            setIsNew(true);
            const detail = samples ? {...rowInitValue, quantity: "1"} : rowInitValue
            setCurrentEditRowData(detail);
            setCreateOrUpdateModalOpen(true);
        };

        const handleSubmitCreateDetail = (values) => {
            if (samples) {
                const api = admin ? SamplesControllerServiceAdmin : SamplesControllerService
                api.save({
                    createDetailDto: values.dto,
                    groupId: values.groupId,
                    name: values.name
                }).then(function (response) {
                    setChange(change => !change)
                })
                    .catch(function (error) {
                        handleError(error)
                    })
                    .then(handleCloseCreateOrUpdate)

            } else {
                DetailControllerService.save(orderId, requestsCuttingId, values).then(function (response) {
                    setData(value => [...value, response.data])
                })
                    .catch(function (error) {
                        handleError(error)
                    })
                    .then(handleCloseCreateOrUpdate)
            }
        }
        const handleSubmitUpdateDetail = (values) => {
            if (samples) {
                const api = admin ? SamplesControllerServiceAdmin : SamplesControllerService
                api.update(currentEditRowId, {
                    "updateDetailDto": values.dto,
                    "groupId": values.groupId,
                    "name": values.name
                }).then(function (response) {
                    setChange(change => !change)
                })
                    .catch(function (error) {
                        handleError(error)
                    })
                    .then(handleCloseCreateOrUpdate)

            } else {
                DetailControllerService.update(orderId, currentEditRowId, values).then(function (response) {
                    setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                })
                    .catch(function (error) {
                        handleError(error)
                    })
                    .then(handleCloseCreateOrUpdate)
            }

        }

        const handleCloseCreateOrUpdate = () => {
            setIsNew(null);
            setCurrentEditRowId(null);
            setCreateOrUpdateModalOpen(false);
        };

        const handleOpenSeeDetail = (position, rowData) => {
            setSeeDetailData({position, data: rowData})
        }
        const handleSubmitSamples = (data, selectedType) => {
            DetailControllerService.saveBySample(orderId, requestsCuttingId, data).then(function (response) {
                setData(value => [...value, response.data])
            })
                .catch(function (error) {
                    handleError(error)
                })
                .then(handleCloseCreateOrUpdate)

        }

        const columns = useMemo(() => {
            const baseColumns = [{
                id: 'position',
                header: 'Позиция',
                accessorKey: samples ? "detailDto.position" : "position",
                size: 300,
                grow: true,
                Cell: ({cell, row}) => {
                    const rowData = samples ? row.original.detailDto : row.original
                    const id = samples ? row.original.sampleId : rowData.id
                    return <li key={cell.getValue()} style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "10px"
                    }}>
                        <Tooltip
                            arrow
                            placement="right"
                            disableHoverListener={rowData.requiresImprovement !== true}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        width: '220px',
                                    },
                                },
                            }}
                            title="При импорте из файла приложение не смогло определить тип кромки">
                            {modal ? cell.getValue() :
                                <Link style={{color: rowData.requiresImprovement ? "red" : "#006CCC"}}
                                      to={samples ?
                                          `/customer/samples/details/${id}` :
                                          requestsCuttingId ?
                                              `/customer/orders/${orderId}/${customerOrderNumber}/requests-cutting/${requestsCuttingId}/${requestsCuttingNumber}/details/${id}` :
                                              `/customer/orders/${orderId}/${customerOrderNumber}/requests-cutting/details/${id}`}>
                                    {cell.getValue() === null ? '' : cell.getValue()}
                                </Link>}
                        </Tooltip>
                        <VisibilityIcon
                            aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(event) => handleOpenSeeDetail(event.currentTarget, rowData)}
                            onMouseLeave={() => setSeeDetailData({position: null, data: null})}
                            sx={{
                                color: (theme) => theme.palette.text.light,
                                fontSize: "24px",
                                cursor: "pointer"
                            }}></VisibilityIcon>
                    </li>
                }
            },

                {
                    id: 'name',
                    header: 'Наименование',
                    accessorKey: samples ? "detailDto.name" : "name",
                    size: 300,
                    grow: true,
                    Cell:
                        ({cell, row}) => <span style={{
                            color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                        }}>{cell.getValue()}</span>,
                },
                {
                    id: 'groupName',
                    header: 'Группа',
                    accessorKey: 'groupName',
                    size: 300,
                    grow: false,
                },
                {
                    id: 'length',
                    header: 'L',
                    accessorKey: samples ? "detailDto.length" : "length",
                    size: 10,
                    grow: false,
                    Cell:
                        ({cell, row}) => <span style={{
                            color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                        }}>{cell.getValue()}</span>,
                }, {
                    id: 'width',
                    header: 'W',
                    accessorKey: samples ? "detailDto.width" : "width",
                    size: 10,
                    grow: false,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                }, {
                    id: 'height',
                    header: 'H',
                    accessorKey: samples ? "detailDto.height" : "height",
                    size: 10,
                    grow: false,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'packagingMaterialName',
                    header: 'Упаковка',
                    accessorKey: 'packagingMaterialName',
                    size: 300,
                    grow: true,
                },

                {
                    id: 'quantity',
                    header: 'кол.',
                    accessorKey: samples ? "detailDto.quantity" : "quantity",
                    size: 10,
                    grow: false,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
            ];

            if (!requestsCuttingId && !samples) {
                baseColumns.splice(6, 0, {
                    id: 'materialName',
                    header: 'Материал',
                    accessorKey: 'materialName',
                    size: 300,
                    grow: true,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                });
            }
            baseColumns.splice(2, 0, {
                id: 'turn',
                header: 'Поворот',
                accessorKey: 'turn',
                size: 10,
                grow: false,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unableTurn}
                    disabled={row.original.excluded}
                >
                </CellCheckboxWithUnable>,
            });

            return baseColumns;

        }, [data, isColumnMaterial, isColumnOrder]);

        const menuItem = (!samples || samples && (!system || admin)) ? [
            {type: "edit", text: "Редактировать", handler: handleUpdateDetail},
            {type: "delete", text: "Удалить", handler: handleDeleteDetail},
            {type: "processing", text: "Обработка", handler: redirectProcessing}
        ] : [{type: "processing", text: "Обработка", handler: redirectProcessing}]
        const handleSelectAction = (action, packagingId) => {
            if (action === "addPackaging") {
                PackagingControllerService.add(orderId, {
                    "detailIds": Object.keys(rowSelection),
                    "packagingId": {
                        "packagingId": packagingId
                    }
                }).then(() => {
                    setRowSelection([])
                    setChange((change) => !change)
                }).catch(function (error) {
                    handleError(error)
                })
            } else {
                PackagingControllerService.remove(orderId, Object.keys(rowSelection)).then(() => {
                    setRowSelection([])
                    setChange((change) => !change)
                }).catch(function (error) {
                    handleError(error)
                })
            }
            setOpen(false)
        }
        const modalActions = [
            {name: "Убрать упаковку", type: "removePackaging"},
            {name: "Добавить упаковку", type: "addPackaging"},
        ]

        return (<div>
            {!modal && < Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px", gap: "16px"}}
            >
                {!samples && <ButtonCreate
                    text={"Добавить по шаблону"}
                    width={230}
                    onClick={() => setModalSamplesOpen(true)}
                    disabled={isError}
                />
                }
                {
                    (samples && (!system || admin)) && <ButtonCreate
                        text={"Добавить из файла"}
                        width={210}
                        onClick={() => setModalImportOpen(true)}
                        disabled={isError}
                    />
                }
                {
                    (requestsCuttingId || (samples && (!system || admin))) &&
                    <ButtonCreate
                        text={"Добавить деталь"}
                        width={190}
                        onClick={handleAddDetail}
                        disabled={isError}
                    />
                }
            </Box>
            }
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": !modal && {
                            padding: 0,
                            width: "100%",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        onClick: () => modal && setSelectedSamples(row.original),
                        sx: () => ({
                            height: '36px',
                            backgroundColor: modal && selectedSamples && row.original?.sampleId === selectedSamples?.sampleId ? "#D7E8FA" : "white",
                            cursor: modal && "pointer",
                            "&:hover": {
                                backgroundColor: modal && "rgba(0, 0, 0, 0.04)",
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: modal && selectedSamples && row.original?.sampleId === selectedSamples?.sampleId ? "#D7E8FA" : '#F5F6FA',
                                "&:hover": {
                                    backgroundColor: modal && "rgba(0, 0, 0, 0.04)"
                                },
                            },

                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={isError ? [] : data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                        material: isColumnMaterial,
                        order: isColumnOrder,
                        packagingMaterialName: !samples,
                        groupName: samples && !modal,
                        quantity: !samples,
                        turn: !modal,
                        height: !modal,
                    },
                    density: "compact",
                    grouping: columnGrouping,
                    // expanded: expanded,
                    pagination: tableOptions,
                    sorting: [{id: 'position', desc: false}],
                }}
                enableGrouping={enableGrouping}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection, showGlobalFilter: true, pagination: samples && tableOptions}}
                enableRowSelection={requestsCuttingId}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                onPaginationChange={(updater) => {
                    samples && tableOptionsHandle(updater(tableOptions));
                }}
                onGlobalFilterChange={value => tableOptionsHandle({...tableOptions, searchData: value})}
                manualPagination={true}
                rowCount={tableOptions?.totalElements}
                localization={MRT_Localization_RU}
                enableRowActions={!modal}
                enableToolbarInternalActions={false}
                enableColumnActions={false}
                enableStickyHeader={true}
                enableStickyFooter={true}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: Object.keys(rowSelection).length !== 0 ? `calc(100vh - 650px)` : `calc(100vh - 560px)`
                    }
                }}
            />
            <ModalAddDetail
                open={createOrUpdateModalOpen}
                onClose={handleCloseCreateOrUpdate}
                title={isNew ? 'Создать деталь' : 'Редактировать деталь'}
                labelButtonSubmit={isNew ? 'Создать' : 'Обновить'}
                handleSubmit={isNew ? handleSubmitCreateDetail : handleSubmitUpdateDetail}
                initValue={currentEditRowData}
                initValueGroup={initValueGroup}
                uniqueNames={isNew ? uniqueNames : uniqueNames.filter(el => el !== currentEditRowData.position)}
                samples={samples}
                handleError={handleError}
                materialTreeData={materialTreeData}
            />
            <ModalImport
                open={modalImportOpen}
                onClose={() => setModalImportOpen(false)}
            />
            <ModalAddBySamples
                open={modalSamplesOpen}
                onClose={() => setModalSamplesOpen(false)}
                handleSubmit={handleSubmitSamples}
            />

            <ModalAlert data={alertData} onClose={handleCloseAlert}/>
            {
                Object.keys(rowSelection).length !== 0 && <Paper sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "88px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.primary.lightGrey,
                }} component="footer">
                    <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
                </Paper>
            }
            <ModalSelectAction
                open={open}
                reset={() => setOpen(false)}
                submit={handleSelectAction}
                modalActions={modalActions}
                materialId={materialId}
                handleError={handleError}
            />
            {
                seeDetailData.data && <ModalSeeDetail
                    open={popoverOpen} seeDetailData={seeDetailData}
                    handleClose={() => setSeeDetailData({position: null, data: null})}></ModalSeeDetail>
            }
        </div>)
            ;
    }
;

export default CustomerDetailsTable;
