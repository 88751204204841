import React, {useEffect, useState} from 'react';
import CustomerOrderService from "../../../API/orders/orders/customer/CustomerOrderService";
import CustomerOrderTable from "../../../components/UI/table/orders/customer/order/CustomerOrderTable";
import {useLocation} from 'react-router-dom';
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import {constantsErrorMessages} from "../../../Constants";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";

const CustomerOrdersArchive = () => {
    const location = useLocation();
    const createType = new URLSearchParams(location.search).get('create');
    const [alertData, setAlertData] = useState({});
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`customer/archive`, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`customer/archive`]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: error.response?.data?.message || constantsErrorMessages[error.response?.status] || error.message
        })
    }
    useEffect(() => {
        getStartPageSize()
        CustomerOrderService.getOrdersArchive().then(function (response) {
            let data = response.data;
            setData(data);
            setTableOptions((prev) => ({...prev, totalElements: data.length}))

        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent page={"Архив"} path={[{name: "Главная", href: "/"}]}/>
            <CustomerOrderTable
                archive={true}
                createType={createType}
                data={data}
                setData={setData}
                handleError={handleError}
                tableOptions={tableOptions}
                tableOptionsHandle={tableOptionsHandle}
            />
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
        </Box>
    );
};

export default CustomerOrdersArchive;