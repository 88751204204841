import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step, StepLabel,
    Stepper,
    TextField
} from "@mui/material";
import ModelsUtil from "../../../../../utils/orders/ModelUtils";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import ButtonBack from "../../../button/ButtonBack";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";
import BasicModelService from "../../../../../API/model-estimate/model/BasicModelService";
import Typography from "@mui/material/Typography";
import BasicModelsTable from "./BasicModelsTable";

export const CreateNewModelModal = ({open, columns, handleClose, onSubmit, initValue, handleError}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [baseData, setBaseData] = useState([]);
    const [baseModel, setBaseModel] = useState({})
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 10,
        totalElements: 0,
        searchData: ""
    })
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
    }
    const onClose = () => {
        handleClose()
        setActiveStep(0)
        setBaseModel({})
    }
    useEffect(() => {
            if (initValue) {
                setActiveStep(1)
                setValues(initValue)
            } else {
                BasicModelService.getAll().then(function (response) {
                    let data = response.data;
                    setBaseData(data);
                    setTableOptions((prev) => ({...prev, totalElements: response?.data.length}))
                })
                    .catch(function (error) {
                        handleError(error);
                    });
            }
        }, []
    )
    const onBack = () => {
        setActiveStep(0)
        setTableOptions((prev) => ({...prev, totalElements: baseData.length}))
    }
    const submit = () => {
        if (activeStep === 0) {
            setActiveStep(1)
        } else {
            onSubmit(initValue ? values : {
                ...values,
                enabled: true,
                basicModel: {id: baseModel.id, name: baseModel.name}
            }, onClose)
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${activeStep === 0 ? "1080px" : "500px"}`,
                height: `${activeStep === 0 ? "900px" : initValue ? "400px" : "480px"}`,
                borderRadius: 8,
            }
        }}>
            <Box style={{
                padding: "16px 24px",
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{initValue ? "Редактировать модель" : "Создать модель"}</DialogTitle>
                {!initValue && <Stepper
                    style={{width: 446}}
                    activeStep={activeStep}
                >
                    {["Базовая модель", "Параметры"].map((label) => (
                        <Step key={label}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                }
                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>}
            </Box>
            {!initValue && <Box sx={{
                height: "32px",
                fontSize: "18px",
                margin: "0px 40px 0px 40px",
                display: "flex",
                alignItems: "center",
                borderBottom: baseModel?.id ? "#3B8BEC 1px solid" : "transparent 1px solid",
                '& .MuiTypography-root': {
                    fontWeight: (theme) => theme.typography.fontWeight,
                    color: (theme) => theme.palette.text.main,
                    '&:first-of-type': {
                        marginRight: '10px'
                    }
                },
            }}>
                <Typography component="p">{baseModel?.name}</Typography>
            </Box>}
            <DialogContent sx={{paddingTop: 0}}>
                {activeStep === 0 ?
                    <BasicModelsTable
                        selectId={baseModel?.id}
                        select={setBaseModel}
                        modal={true}
                        tableOptions={tableOptions}
                        tableOptionsHandle={tableOptionsHandle}
                        data={baseData}
                        handleError={handleError}
                    />
                    :
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >

                        {columns.map((column) => (
                            ModelsUtil.checkCreateField(column) &&
                            <TextField
                                size={"small"}
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '0.01rem',
                                }}
                                margin="normal"
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                value={values[column.accessorKey]}
                                onChange={(e) =>
                                    setValues({...values, [e.target.name]: e.target.value})
                                }
                            />
                        ))}

                    </Stack>}
            </DialogContent>

            <DialogActions style={{padding: 20, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep !== 0 && !initValue && <ButtonBack text={"Назад"} onClick={onBack}/>} </Box>
                <Box>
                    <ButtonExit
                        text={"Выйти"} onClick={handleClose}/>
                    <ButtonAdd
                        text={activeStep === 0 ? "Выбрать" : "Сохранить"} onClick={submit}
                        disabled={!baseModel?.id && !initValue}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
};