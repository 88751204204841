import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import ButtonExit from "../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../components/UI/button/ButtonAdd";
import TextFieldValidation from "../../../../components/UI/table/customer-detales/TextFieldValidation";
import MaterialTree from "../../../../components/UI/tree/MaterialTree";

const AddGroupModal = ({
                           initValue,
                           buttonText,
                           title,
                           open,
                           onClose,
                           onSubmit,
                           dataTree,
                           groupMap,
                           filterGroup,
                           setFilterGroup,
                           changeFilterGroup,
                           setChangeFilterGroup,
                           priorityField
                       }) => {

    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({
        "name": "",
        "currentGroup": ""
    })
    useEffect(() => {
        setFormState(initValue)
        setValidationState({
            "name": "",
            "currentGroup": ""
        })
    }, [initValue])

    const handleClose = () => {
        onClose()
        setValidationState({
            "name": "",
            "currentGroup": ""
        })
    }
    const changeCurrentGroup = (value) => {
        setValidationState(prev => ({...prev, currentGroup: ""}))
        setFormState(prev => ({...prev, currentGroup: value}))
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        if (formState.name === "") {
            newErrors.name = "пустое поле"
        }
        if (formState.currentGroup === null) {
            newErrors.currentGroup = "Выберите родительскую группу"
        }

        setValidationState(newErrors)
        if (newErrors.name === "" && newErrors.currentGroup === "") {
            onSubmit({
                "name": formState.name,
                "parentId": formState.currentGroup.id,
                "outputPriority": formState?.outputPriority
            })
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">
                {title}
            </DialogTitle>
            <DialogContent style={{paddingBottom: 7, minHeight: 80}}>
                <TextFieldValidation
                    name={"name"}
                    value={formState?.name}
                    label="Название группы"
                    setCurrValues={setFormState}
                    validationError={validationState?.name}
                    setValidationState={setValidationState}
                />
                {priorityField && <TextFieldValidation
                    name={"outputPriority"}
                    type={"number"}
                    value={formState?.outputPriority}
                    label="Приоритет"
                    setCurrValues={setFormState}
                    required={false}
                />}

                <Typography sx={{fontSize: "14px", color: (theme) => theme.palette.text.light}}>Родительская
                    группа *</Typography>
                <MaterialTree
                    name={"Группы"}
                    filterGroup={filterGroup}
                    setFilterGroup={setFilterGroup}
                    changeFilterGroup={changeFilterGroup}
                    setChangeFilterGroup={setChangeFilterGroup}
                    data={dataTree}
                    groupMap={groupMap}
                    currentGroup={formState.currentGroup}
                    setCurrentGroup={changeCurrentGroup}
                />
                {validationState.currentGroup &&
                    <Typography sx={{color: "#d32f2f", fontSize: "16px", paddingTop: "10px"}}>Выберите родительскую
                        группу</Typography>}
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={buttonText} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    )
}
export default AddGroupModal