import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import SupplierService from "../../../API/orders/warehouse/supplier/SupplierService";
import MaterialCloudUnits from "../../../API/material-cloud/common/MaterialCloudUnits";
import UserManufacturerMaterialCloudService
    from "../../../API/material-cloud/user/UserManufacturerMaterialCloudService";
import TextFieldValidation from "../../../components/UI/table/customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import {typeMaterialWithSubtype} from "../../../data/TypeMaterial";
import {structure} from "../../../data/Structure";
import CircularProgress from "@mui/material/CircularProgress";
import {materialCutDefault} from "../../../data/MaterialCutDefault";
import AdminSuppliersService from "../../../API/material-cloud/admin/AdminSuppliersService";
import AdminManufacturerService from "../../../API/material-cloud/admin/AdminManufacturerServicece";
import AdminUnitService from "../../../API/material-cloud/admin/AdminUnitService";

export const AddNewMaterial = ({formState, setFormState, validationState, setValidationState, admin}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [lists, setLists] = useState({supplier: [], manufacturer: [], units: []})
    const allMaterialTypes = Object.entries(typeMaterialWithSubtype)
    const allStructure = Object.entries(structure)
    const materialCut = Object.entries(materialCutDefault)

    useEffect(() => {
        const apiUnit = admin ? AdminUnitService : MaterialCloudUnits
        const apiSuppliers = admin ? AdminSuppliersService : SupplierService
        const apiManufacturer = admin ? AdminManufacturerService.get : UserManufacturerMaterialCloudService.getAll
        Promise.all([apiUnit.get(), apiSuppliers.get(), apiManufacturer()]).then((values) => {
            setLists({supplier: values[1].data, manufacturer: values[2].data, units: values[0].data})
            setFormState((prev) => ({
                ...prev,
                defaultSupplierId: values[1].data[0]?.id,
                manufacturerId: values[2].data[0]?.id,
                unitId: values[0].data[0]?.id,
                typeMaterial: allMaterialTypes[0][0],
                subTypeMaterial: Object.entries(allMaterialTypes[0][1].subtype)[0][0],
                structure: allStructure[1][0],
                cutDefault: materialCut[0][0]
            }))
            setIsLoading(false)
        });
    }, []);

    useEffect(() => {
        formState?.typeMaterial && setFormState(prev => ({
            ...prev, subTypeMaterial: Object.entries(allMaterialTypes.find(el => el[0] === formState?.typeMaterial
            )[1].subtype)[0][0]
        }))
    }, [formState?.typeMaterial])

    return isLoading ?
        <Box sx={{
            height: "800px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <CircularProgress disableShrink/>
        </Box> : <Box sx={{display: "flex", gap: "20px"}}><Box sx={{width: "50%"}}>
            <TextFieldValidation
                name={"articleNumber"}
                value={formState?.articleNumber}
                label="Артикул"
                setCurrValues={setFormState}
                validationError={validationState?.articleNumber}
                setValidationState={setValidationState}
            />
            <TextFieldValidation
                name={"name"}
                value={formState?.name}
                label="Наименование"
                setCurrValues={setFormState}
                validationError={validationState?.name}
                setValidationState={setValidationState}
            />
            <TextFieldValidation
                name={"shortName"}
                value={formState?.shortName}
                label="Короткое имя"
                setCurrValues={setFormState}
                validationError={validationState?.shortName}
                setValidationState={setValidationState}
            />
            <TextFieldValidation
                select
                name={"defaultSupplierId"}
                value={formState?.defaultSupplierId}
                label="Поставщик по умолчанию"
                setCurrValues={setFormState}
                validationError={validationState?.defaultSupplierId}
                setValidationState={setValidationState}
            >{lists.supplier.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>))}</TextFieldValidation>
            <TextFieldValidation
                select
                name={"unitId"}
                value={formState?.unitId}
                label="Ед. измерения"
                setCurrValues={setFormState}
                validationError={validationState?.unitId}
                setValidationState={setValidationState}
            >{lists.units.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>))}</TextFieldValidation>
            <TextFieldValidation
                select
                name={"typeMaterial"}
                value={formState?.typeMaterial}
                label="Тип материала"
                setCurrValues={setFormState}
                validationError={validationState?.typeMaterial}
                setValidationState={setValidationState}
            >{allMaterialTypes.map((option) => (
                <MenuItem key={option[0]} value={option[0]}>
                    {option[1].name}
                </MenuItem>))}</TextFieldValidation>
            <TextFieldValidation
                select
                name={"subTypeMaterial"}
                value={formState.subTypeMaterial}
                label="Подтип материала"
                setCurrValues={setFormState}
                validationError={validationState?.subTypeMaterial}
                setValidationState={setValidationState}
            >{Object.entries(allMaterialTypes.find(el => el[0] === formState?.typeMaterial
            )[1].subtype).map((option) => {
                    return <MenuItem key={option[0]} value={option[0]}>
                        {option[1]}
                    </MenuItem>
                }
            )}</TextFieldValidation>
            <TextFieldValidation
                select
                name={"manufacturerId"}
                value={formState?.manufacturerId}
                label="Производитель"
                setCurrValues={setFormState}
                validationError={validationState?.manufacturerId}
                setValidationState={setValidationState}
            >{lists.manufacturer.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>))}</TextFieldValidation>
            <TextFieldValidation
                select
                name={"archive"}
                value={formState?.archive}
                label="Архив"
                setCurrValues={setFormState}
                validationError={validationState?.archive}
                setValidationState={setValidationState}
            >{[{id: true, name: "да"}, {id: false, name: "нет"}].map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>))}</TextFieldValidation>
            <Box sx={{
                display: 'flex',
                gap: "20px"
            }}>
                <TextFieldValidation
                    name={"weight"}
                    type={"number"}
                    value={formState?.weight}
                    label="Ширина"
                    disabled={formState?.subTypeMaterial !== "EDGE" && formState?.typeMaterial !== "SLAB"}
                    setCurrValues={setFormState}
                    validationError={validationState?.weight}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"height"}
                    type={"number"}
                    value={formState?.height}
                    label="Высота"
                    disabled={formState?.subTypeMaterial !== "EDGE" && formState?.typeMaterial !== "SLAB"}
                    setCurrValues={setFormState}
                    validationError={validationState?.height}
                    setValidationState={setValidationState}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                gap: "20px"
            }}>
                <TextFieldValidation
                    name={"thickness"}
                    type={"number"}
                    value={formState?.thickness}
                    label="Толщина"
                    disabled={formState?.subTypeMaterial !== "EDGE" && formState?.typeMaterial !== "SLAB"}
                    setCurrValues={setFormState}
                    validationError={validationState?.thickness}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"density"}
                    type={"number"}
                    value={formState?.density}
                    label={formState.typeMaterial === 'SLAB' || formState?.subTypeMaterial === "EDGE" ? "Плотность" : "Вес"}
                    setCurrValues={setFormState}
                    validationError={validationState?.density}
                    setValidationState={setValidationState}
                />
            </Box>

        </Box>
            <Box sx={{width: "50%"}}>
                <TextFieldValidation
                    select
                    name={"structure"}
                    value={formState?.structure}
                    label="Структура"
                    setCurrValues={setFormState}
                    disabled={formState?.typeMaterial !== "SLAB"}
                    validationError={validationState?.structure}
                    setValidationState={setValidationState}
                >{allStructure.map((option) => (
                    <MenuItem key={option[0]} value={option[0]}>
                        {option[1]}
                    </MenuItem>))}</TextFieldValidation>
                <TextFieldValidation
                    select
                    name={"cutDefault"}
                    value={formState?.cutDefault}
                    label="Кроить по умолчанию"
                    setCurrValues={setFormState}
                    disabled={formState?.typeMaterial !== "SLAB"}
                    validationError={validationState?.cutDefault}
                    setValidationState={setValidationState}
                >{materialCut.map((option) => (
                    <MenuItem key={option[0]} value={option[0]}>
                        {option[1]}
                    </MenuItem>))}</TextFieldValidation>
                <Box sx={{
                    display: 'flex',
                    gap: "20px"
                }}>
                    <TextFieldValidation
                        name={"trimmingLeft"}
                        type={"number"}
                        value={formState?.trimmingLeft}
                        label="Отступ слева"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.trimmingLeft}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        name={"trimmingRight"}
                        type={"number"}
                        value={formState?.trimmingRight}
                        label="Отступ справа"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.trimmingRight}
                        setValidationState={setValidationState}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: "20px"
                }}>
                    <TextFieldValidation
                        name={"trimmingBottom"}
                        type={"number"}
                        value={formState?.trimmingBottom}
                        label="Отступ снизу"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.trimmingBottom}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        name={"trimmingTop"}
                        type={"number"}
                        value={formState?.trimmingTop}
                        label={"Отступ сверху"}
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.trimmingTop}
                        setValidationState={setValidationState}
                    />
                </Box>
                {!admin && <TextFieldValidation
                    name={"minBalanceUpToWholeSheet"}
                    type={"number"}
                    value={formState?.minBalanceUpToWholeSheet}
                    label={"Минимальный остаток до целого листа"}
                    setCurrValues={setFormState}
                    disabled={formState?.typeMaterial !== "SLAB"}
                    validationError={validationState?.minBalanceUpToWholeSheet}
                    setValidationState={setValidationState}
                />}
                {!admin && <Box sx={{
                    display: 'flex',
                    gap: "20px"
                }}>
                    <TextFieldValidation
                        name={"minWeightCutOff"}
                        type={"number"}
                        value={formState?.minWeightCutOff}
                        label="Мин. ширина обрезка"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.minWeightCutOff}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        name={"minHeightCutOff"}
                        type={"number"}
                        value={formState?.minHeightCutOff}
                        label="Мин. высота обрезка"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.minHeightCutOff}
                        setValidationState={setValidationState}
                    />
                </Box>}
                {!admin && <Box sx={{
                    display: 'flex',
                    gap: "20px"
                }}>
                    <TextFieldValidation
                        name={"trimmingLeftCutOff"}
                        type={"number"}
                        value={formState?.trimmingLeftCutOff}
                        label="Отсуп обрезка слева"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.trimmingLeftCutOff}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        name={"trimmingRightCutOff"}
                        type={"number"}
                        value={formState?.trimmingRightCutOff}
                        label="Отсуп обрезка справа"
                        setCurrValues={setFormState}
                        disabled={formState?.typeMaterial !== "SLAB"}
                        validationError={validationState?.trimmingRightCutOff}
                        setValidationState={setValidationState}
                    />
                </Box>}
                {!admin && <Box sx={{
                    display: 'flex',
                    gap: "20px"
                }}>
                    <TextFieldValidation
                        name={"trimmingBottomCutOff"}
                        type={"number"}
                        value={formState?.trimmingBottomCutOff}
                        label="Отсуп обрезка снизу"
                        setCurrValues={setFormState}
                        disabled={formState?.typeMaterial !== "SLAB"}
                        validationError={validationState?.trimmingBottomCutOff}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        name={"trimmingTopCutOff"}
                        type={"number"}
                        value={formState?.trimmingTopCutOff}
                        label="Отсуп обрезк сверху"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.trimmingTopCutOff}
                        setValidationState={setValidationState}
                    />
                </Box>}
                {!admin && <Box sx={{
                    display: 'flex',
                    gap: "20px"
                }}>
                    <TextFieldValidation
                        name={"cutWeight"}
                        type={"number"}
                        value={formState?.cutWeight}
                        label="Ширина реза"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.cutWeight}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        name={"limitNumberTurns"}
                        type={"number"}
                        value={formState?.limitNumberTurns}
                        label="Кол. поворотов"
                        disabled={formState?.typeMaterial !== "SLAB"}
                        setCurrValues={setFormState}
                        validationError={validationState?.limitNumberTurns}
                        setValidationState={setValidationState}
                    />
                </Box>}
                {!admin && <TextFieldValidation
                    select
                    name={"deliveryNotAccordingToEstimate"}
                    value={formState?.deliveryNotAccordingToEstimate}
                    label="Выдача не по смете"
                    setCurrValues={setFormState}
                    validationError={validationState?.deliveryNotAccordingToEstimate}
                    setValidationState={setValidationState}
                >{[{id: true, name: "да"}, {id: false, name: "нет"}].map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>))}</TextFieldValidation>}
                {!admin && <TextFieldValidation
                    name={"overhangEdge"}
                    type={"number"}
                    value={formState?.overhangEdge}
                    label="Свесы кромки"
                    setCurrValues={setFormState}
                    disabled={formState?.subTypeMaterial !== "EDGE"}
                    validationError={validationState?.overhangEdge}
                    setValidationState={setValidationState}
                />}
            </Box>
        </Box>
}