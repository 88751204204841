import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import ModelService from "../../../../../API/model-estimate/model/ModelService";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../../сheckbox/CellCheckboxWithUnable";
import {Link} from "react-router-dom";
import Common from "../../../../../utils/common/Common";
import ModalConfirm from "../../../modal/ModalConfirm";
import OrderService from "../../../../../API/orders/orders/organization/OrderService";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {CreateNewModelModal} from "./CreateNewModelModal";

const ModelsTable = ({
                         data,
                         setData,
                         unable,
                         setUnable,
                         isColumnUnable,
                         setModelsOpen,
                         setBasicModalModelsOpen,
                         orderId,
                         isRedirect,
                         isBasicModel,
                         change,
                         setChange,
                         modal,
                         selectId,
                         select,
                         tableOptions,
                         tableOptionsHandle,
                         handleError
                     }) => {
        const [createModalOpen, setCreateModalOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [confirmIssueModalOpen, setConfirmIssueModalOpen] = useState(false);
        const [currentIdModel, setCurrentIdModel] = useState(null);

        const handleUpdate = (data, onClose) => {
            ModelService.update(data)
                .then(function (response) {
                    setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                    onClose();
                })
                .catch(function (error) {
                    console.log(error);
                })
        };
        const handleCreate = (data, onClose) => {
            ModelService.save(data).then(function (response) {
                setData(prev => ([...prev, response.data]))
                unable[response.data.id] = response.data.enabled;
                tableOptionsHandle((prev) => ({...prev, totalElements: data.length}))
                onClose();
            })
                .catch(function (error) {
                    handleError(error);
                });
        }

        const handleDeleteRow = useCallback(
            (row) => {
                ModelService.delete(row.id)
                    .then(function () {
                        setData(prev => prev.filter(el => el.id !== row.id))
                        tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row);
                setCreateModalOpen(true);

            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const addModel = useCallback(
            (row) => {
                setCurrentIdModel(row.id)
                setConfirmIssueModalOpen(true);

            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleCloseConfirmIssue = () => {
            setConfirmIssueModalOpen(false)
        };

        const handleUnableCheckbox = (id, status) => {
            ModelService.unable(id, status)
                .then(function () {
                    Common.handleUnable(id, status, unable, setUnable)
                })
                .catch(function (error) {
                    console.log(error);
                })
        };

        const handleSubmitConfirmIssue = () => {

            OrderService.addModel(orderId, currentIdModel).then(function () {
                setConfirmIssueModalOpen(false);
                setModelsOpen(false);
                setBasicModalModelsOpen(false);
                setCurrentIdModel(null);
                setChange(!change)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'name',
                    header: 'Модель',
                    accessorKey: 'name',
                    minSize: 500,
                    Cell: ({cell, row}) => (
                        isRedirect ? <li key={cell.getValue()}>
                            <Link
                                to={'/organization/models/replacements/' + data[row.index].id}>{cell.getValue()}</Link>
                        </li> : cell.getValue()
                    ),
                },

                {
                    id: 'articleNumber',
                    header: 'Артикул',
                    accessorKey: 'articleNumber',
                    minSize: 15,
                    maxSize: 15,
                },
                {
                    id: 'barcode',
                    header: 'Штрих-код',
                    accessorKey: 'barcode',
                    minSize: 50,
                },

                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (isBasicModel) {
                baseColumns.splice(2, 0, {
                    id: 'basicModel.name',
                    header: 'Базовая модель',
                    accessorKey: 'basicModel.name',
                    minSize: 150,
                    Cell: ({cell, row}) => (
                        isRedirect ?
                            <li key={cell.getValue()}>
                                <Link
                                    to={'/organization/basic-models/' + data[row.index].basicModel?.id + '/estimates/'}>{cell.getValue() === undefined ? 'Не определено' : cell.getValue()}</Link>
                            </li> : cell.getValue()
                    ),
                });
            }

            if (isColumnUnable) {
                baseColumns.splice(3, 0, {
                    header: 'Статус',
                    accessorKey: 'enabled',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.original.id}
                        unable={unable}
                        setUnable={handleUnableCheckbox}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            return baseColumns;

        }, [data, setData, isBasicModel]);

        const menuItem = [
            {type: "delete", text: "Удалить", handler: handleDeleteRow},
            {type: "edit", text: "Редактировать", handler: handleUpdateModel},
        ]
        return (
            <Box sx={{width: '1500px',}}>
                <Box
                    sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
                >
                    {!modal && <ButtonCreate
                        text={"Создать модель"}
                        width={180}
                        onClick={() => setCreateModalOpen(true)}
                    />}
                </Box>
                <MaterialReactTable
                    muiTableContainerProps={{
                        sx: {
                            width: '1500px',
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                            border: "none",
                            "& td:after": {
                                "&:hover": {
                                    backgroundColor: "red"
                                }
                            },
                            "&:not(:last-of-type)": {
                                borderRight: '1px solid #EAEBEA',

                            },
                            "&:first-of-type": !modal && {
                                padding: 0,
                                width: "50px",
                                height: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: (theme) => theme.palette.text.light,
                                "& .MuiButtonBase-root": {
                                    borderRadius: "4px",
                                    width: "24px",
                                    height: "24px",
                                    opacity: 1,
                                    margin: 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.stroke,
                                    }
                                }
                            },
                            "& li": {
                                listStyleType: "none",
                                "& a": {
                                    color: "#006CCC",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }
                                }
                            }
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            border: '1px solid #EAEBEA',
                            backgroundColor: "#F5F6FA",
                            padding: "10px",
                            paddingBottom: " 0.2rem",
                            paddingTop: "0.2rem"
                        },
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return ({
                            onClick: () => select && select(row.original),
                            sx: () => ({
                                height: '36px',
                                cursor: select && "pointer",
                                "&:hover& td:after": !modal && {
                                    display: "none",
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: row.original.id === selectId ? "#D7E8FA" : '#F5F6FA',
                                },
                                backgroundColor: row.original.id === selectId ? "#D7E8FA" : "white",
                            })
                        })
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: "",
                            size: 3,
                            minSize: 3,
                            grow: false,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        placeholder: `Поиск`,
                        backgroundColor: "red",
                        sx: {
                            backgroundColor: "red",
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            "& .MuiCollapse-root": {
                                borderRadius: '12px',
                                height: 40, width: 290,
                                "& .MuiSvgIcon-root": {
                                    color: "#737478"
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: 0
                                }
                            }
                        },
                        variant: 'outlined',
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        sorting: [{id: 'name', desc: false}],
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        columnPinning: {left: ['number']}
                    }}

                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    onPaginationChange={(updater) => {
                        tableOptionsHandle(updater(tableOptions));
                    }}
                    state={{showGlobalFilter: true, pagination: tableOptions,}}
                    rowCount={tableOptions.totalElements}
                    enableRowActions={!modal}
                    enableToolbarInternalActions={false}
                    renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                        return <MenuItem
                            key={el.type}
                            onClick={() => {
                                el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    })
                    }

                    localization={MRT_Localization_RU}
                />
                {createModalOpen && <CreateNewModelModal
                    columns={columns}
                    open={createModalOpen}
                    handleClose={() => {
                        setCreateModalOpen(false);
                        setCurrentEditRowData(null)
                    }}
                    initValue={currentEditRowData}
                    onSubmit={currentEditRowData ? handleUpdate : handleCreate}
                    handleError={handleError}
                />}
                <ModalConfirm
                    title={"Подтвердить выбор модели?"}
                    modalOpen={confirmIssueModalOpen}
                    handleClose={handleCloseConfirmIssue}
                    handleSubmit={handleSubmitConfirmIssue}
                    action={"Подтвердить"}
                />
            </Box>
        );
    }
;

export default ModelsTable;