import React from 'react';
import {TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const MaterialTextFieldObjectWithEntityList = ({
                                                   entityList,
                                                   label,
                                                   values,
                                                   setValues,
                                                   field,
                                                   disabled,
                                                   excludeStatus,
                                                   minWidthXs,
                                                   minWidthSm,
                                                   minWidthMd,
                                               }) => {

    const currentMinWidthXs = minWidthXs ? minWidthXs : '300px';
    const currentMinWidthSm = minWidthSm ? minWidthSm : '360px';
    const currentMinWidthMd = minWidthMd ? minWidthMd : '400px';

    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: currentMinWidthXs, sm: currentMinWidthSm, md: currentMinWidthMd},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                value={values === null ? "" : (values[field] === null ? "" : values[field])}
                name={field}
                onChange={(e) =>
                    setValues({...values, [e.target.name]: e.target.value})
                }
            >
                {Object.keys(entityList).map((statusKey) => (
                    excludeStatus !== statusKey && <MenuItem key={statusKey} value={statusKey}>
                        {entityList [statusKey]}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default MaterialTextFieldObjectWithEntityList;