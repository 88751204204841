import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import CellCheckbox from "../../../сheckbox/CellCheckbox";
import {Link, useLocation} from "react-router-dom";
import ModalPerformRefund from "./ModalPerformRefund";
import ModalConfirm from "../../../modal/ModalConfirm";
import IssuingEdgeItemService
    from "../../../../../API/orders/prodaction.issueedge/issuingedgeitem/IssuingEdgeItemService";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import MenuItem from "@mui/material/MenuItem";

const IssuingEdgesItemTable = ({id, change, setChange, handleError}) => {
    const pathname = useLocation().pathname
    const [data, setData] = useState([]);
    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })
    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const tableOptions = pagesData[`${pathname}+3`]?.tableOptions
        tableOptions && setTableOptions((prev) => ({...prev, pageSize: tableOptions.pageSize}))
    }
    const tableOptionsHandle = (nextState, currTab) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`${pathname}+3`, "tableOptions", nextState)
        }
    }

    useEffect(() => {
        getStartPageSize()
        IssuingEdgeItemService.getAllByIssuingEdgeId(id).then(function (response) {
            let data = response.data;
            setData(data)
            setTableOptions((prev) => ({...prev, totalElements: data.length}))
        })
            .catch(function (error) {
                handleError(error);
            });
    }, [change]);

    const [performRefundModalOpen, setPerformRefundModalOpen] = useState(false);
    const [refund, setRefund] = useState(null);

    const [closeModalOpen, setCloseModalOpen] = useState(false);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);


    const handleSubmitPerformRefund = () => {
        IssuingEdgeItemService.performRefund(currentEditRowId, id, refund).then(function (response) {
            handleUpdate(response.data);
            closePerformRefundModalOpen();
            setChange(!change)
        })
            .catch(function (error) {
                handleError(error);
            });
    };

    const handleSubmitCloseIssuingEdgeItem = () => {
        IssuingEdgeItemService.closeIssuingEdgeItem(currentEditRowId).then(function () {
            handleClose();
            closeCloseModalOpen();
        })
            .catch(function (error) {
                handleError(error);
            });
    };

    const handleUpdate = (values) => {
        data[currentRowIndex] = values;
        setData([...data]);
    };

    const handleClose = () => {
        data[currentRowIndex].closed = true;
        setData([...data]);
    };

    useEffect(() => {

        IssuingEdgeItemService.getAllByIssuingEdgeId(id).then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                handleError(error);
            });
    }, []);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'materialOrganizationOption.name',
                header: 'Найменование материала',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 700,
            },
            {
                id: 'totalNeed',
                header: 'Необходимо',
                accessorKey: 'totalNeed',
                maxSize: 25,
            },
            {
                id: 'totalCount',
                header: 'Выдано',
                accessorKey: 'totalCount',
                maxSize: 25,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return <li key={value}>
                        <Link
                            to={'/organization/issuance-issuing-edges-item/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(2)}</Link>
                    </li>
                },
            },
            {
                id: 'overspending',
                header: 'Перерасход',
                accessorKey: 'overspending',
                maxSize: 25,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    return <span style={{
                        color: value < 0 ? "green" : "red"
                    }}>{value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}</span>
                }
            },
            {
                id: 'closed',
                header: 'Закрыто',
                accessorKey: 'closed',
                maxSize: 25,
                Cell: ({cell, row}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
                maxSize: 2,
            },
        ];

        return baseColumns;
    }, [data, setData]);


    const closePerformRefundModalOpen = () => {
        setCurrentRowIndex(null)
        setCurrentEditRowData(null);
        setCurrentEditRowId(null);
        setPerformRefundModalOpen(false)
    };

    const closeCloseModalOpen = () => {
        setCurrentRowIndex(null)
        setCurrentEditRowData(null);
        setCurrentEditRowId(null);
        setCloseModalOpen(false)
    };

    const handlePerformRefund = useCallback(
        (row) => {
            setCurrentRowIndex(row.id)
            setCurrentEditRowData(row);
            setCurrentEditRowId(row.id);
            setRefund(row.totalCount);
            setPerformRefundModalOpen(true)
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const close = useCallback(
        (row) => {
            setCurrentRowIndex(row.id)
            setCurrentEditRowData(row);
            setCurrentEditRowId(row.id);
            setCloseModalOpen(true)
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );
    const menuItem = [
        {type: "return", text: "Выполнить возврат", handler: handlePerformRefund},
        {type: "close", text: "Закрыть выдачу", handler: close},
    ]

    return (
        <Box sx={{width: '1500px',}}>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end"}}
            ></Box>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: '1500px',
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },

                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,

                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                localization={MRT_Localization_RU}
                enableToolbarInternalActions={false}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                rowCount={tableOptions.totalElements}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                enableRowActions={true}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    const active = !row.original.closed
                    return <Tooltip arrow placement="left"
                                    title={!active ? "заблокировано" : ""}>
                        <MenuItem
                            key={el.type}
                            onClick={() => {
                                active && el.handler(row.original)
                                closeMenu();
                            }}
                            sx={{
                                margin: "0 8px",
                                borderRadius: "4px",
                                opacity: !active && 0.5,
                                "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                            }}
                        >
                            {el.text}
                        </MenuItem>
                    </Tooltip>
                })}
            />
            <ModalPerformRefund
                open={performRefundModalOpen}
                onClose={closePerformRefundModalOpen}
                titleModal={"Вернуть материал в количестве"}
                issue={currentEditRowData ? currentEditRowData.totalCount : null}
                refund={currentEditRowData ? currentEditRowData.totalCount : null}
                setRefund={setRefund}
                submit={handleSubmitPerformRefund}
            />
            <ModalConfirm
                title={"Подтвердить закрыть выдачу?"}
                modalOpen={closeModalOpen}
                handleClose={closeCloseModalOpen}
                handleSubmit={handleSubmitCloseIssuingEdgeItem}
                action={"Подтвердить"}
            />
        </Box>
    );
};

export default IssuingEdgesItemTable;