import services1 from "../components/Icons/services1.png";
import services2 from "../components/Icons/services2.png";
import services3 from "../components/Icons/services3.png";

export const newsData = [
    {
        id: 1,
        text: "1 Название новости в одну или две строки. Если название длинное - то будет многоточие",
        date: "11.11.12",
        type: "production",
        description:
            "Эволюция потребителей и дизайнеров мебели отражает изменения в обществе и потребностях людей. Современный дизайнер уже не просто создаёт мебель или оформляет пространство — он формирует среду, в которой люди живут и работают.",
        img: services1,
    },
    {
        id: 2,
        text: "2 Название новости в одну или две строки. Если название длинное - то будет многоточие",
        type: "upMe",
        date: "11.11.12",
        description: "Эволюция потребителей и дизайнеров мебели отражает изменения в обществе и потребностях людей. Современный дизайнер уже не просто создаёт мебель или оформляет пространство — он формирует среду, в которой люди живут и работают.",
        img: services2,
    },
    {
        id: 3,
        text: "3 Название новости в одну или две строки. Если название длинное - то будет многоточие",
        type: "upMe",
        date: "11.11.12",
        description: "Эволюция потребителей и дизайнеров мебели отражает изменения в обществе и потребностях людей. Современный дизайнер уже не просто создаёт мебель или оформляет пространство — он формирует среду, в которой люди живут и работают. В последние десятилетия требования клиентов к мебели претерпели значительные изменения. Ранее основной акцент делался на внешний вид: главное было, чтобы мебель выглядела привлекательно и гармонировала с общим стилем помещения. Однако со временем потребители стали более требовательными и осведомлёнными, придавая большее значение комфорту.\n" +
            "Сегодня важными аспектами для заказчиков стали не только эстетика, но и ряд других характеристик:\n" +
            "• Эргономичность, поскольку удобство использования стало приоритетом;\n" +
            "\n" +
            "• Функциональность, позволяющая максимально эффективно использовать пространство;\n" +
            "\n" +
            "• Согласованность стиля, чтобы мебель органично вписывалась в интерьер и создавалась единая атмосфера;\n" +
            "\n" +
            "• Устойчивость и экологичность, чтобы изделия производились из безопасных для окружающей среды материалов и с использованием соответствующих технологий.",
        img: services3,
    },
    {
        id: 4,
        text: "4 Название новости в одну или две строки. Если название длинное - то будет многоточие",
        type: "upMe",
        date: "11.11.12",
        description: "Эволюция потребителей и дизайнеров мебели отражает изменения в обществе и потребностях людей. Современный дизайнер уже не просто создаёт мебель или оформляет пространство — он формирует среду, в которой люди живут и работают.",
        img: services3,
    },
    {
        id: 5,
        text: "5 Название новости в одну или две строки. Если название длинное - то будет многоточие",
        type: "upMe",
        date: "11.11.12",
        description: "Эволюция потребителей и дизайнеров мебели отражает изменения в обществе и потребностях людей. Современный дизайнер уже не просто создаёт мебель или оформляет пространство — он формирует среду, в которой люди живут и работают.",
        img: services3,
    }]