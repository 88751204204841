import {Paper, Typography} from "@mui/material";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import {BreadcrumbsComponent} from "../../components/UI/breadcrumb/BreadcrumbComponent";
import Footer from "./Footer";
import React from "react";

const Contacts = () => {
    return (
        <>
            <Paper sx={{
                width: '100%',
                paddingBottom: "40px",
                display: 'flex',
                justifyContent: "space-between",
                flexGrow: 1
            }}>
                <Container sx={{
                    "&.MuiContainer-root": {
                        maxWidth: "1668px",
                        display: 'flex',
                        flexDirection: "column",
                        gridGap: "100px",
                    },
                }}>
                    <Box>
                        <BreadcrumbsComponent
                            page={"Контакты"}
                            path={[
                                {name: "Главная", href: "/"},
                            ]}/>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: "column",
                            gridGap: "40px",
                            justifyContent: "center",
                            width: "100%",
                        }}>
                            <Typography sx={{
                                fontSize: "40px", fontWeight: 700, lineHeight: 1,
                                '@media (max-width: 1450px)': {
                                    fontSize: "28px",
                                }
                            }}>
                                Контакты
                            </Typography>
                            <Typography component="p" sx={{
                                fontSize: "16px",
                                "& a": {
                                    color: (theme) => theme.palette.primary.main,
                                    textDecoration: "none"
                                }
                            }}>
                                email: <a href="mailto:info@upmy.ru">info@upmy.ru</a>
                            </Typography>
                            <Typography component="p" sx={{fontSize: "18px", fontWeight: 500}}>
                                +375 29 444 00 65
                            </Typography>
                            <Typography sx={{
                                color: (theme) => theme.palette.text.main,
                            }}>
                                ИНН: 0901051787 КПП 090101001
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Paper>
            <Footer></Footer>
        </>
    );
};

export default Contacts;