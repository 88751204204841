export const materialEstimateUnitStates =
    {
        "CALCULATION": "Расчетный",
        "IN_WORK": "В работе",
        "CREATED_RESERVES": "Созданы резервы",
        "WAITING_FOR_AN_APPLICATION": "Ожидание закупки",
        "EXECUTION_OF_APPLICATION": "Оформление закупки",
        "RECONCILIATION_OF_DATA": "Согласование закупки",
        "WAITING_FOR_DELIVERY": "Ожидание доставки",
        "IN_STOCK": "В наличии",
        "ISSUED": "Выдан",
        "ARCHIVE": "Архив"
    }