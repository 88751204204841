import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import React, {useState} from "react";
import Typography from "@mui/material/Typography";

const EditEstimateModal = ({open, onClose, onSubmit, initFormState}) => {
    const [formState, setFormState] = useState(initFormState)

    const inputHandler = (getData) => {
        const [key, value] = Object.entries(getData())[0]
        setFormState(prev => ({...prev, [key]: {...prev[key], manualPrice: value}}))
    }
    return (
        <Dialog open={open}
                PaperProps={{
                    sx: {minWidth: "780px"}
                }}>
            <DialogTitle
                textAlign="center">Изменить</DialogTitle>
            <DialogContent style={{paddingBottom: 7}}>
                <FormControl sx={{minWidth: "100%"}}>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Box sx={{
                            width: 350,
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <Typography component="p">
                                    План
                                </Typography>
                            </Box>
                            <TextFieldValidation
                                name={"costBasePrice"}
                                required={false}
                                value={formState?.calculationCostBasePrice?.manualPrice}
                                label="Себестоимость материалов"
                                setCurrValues={inputHandler}
                                adornment={formState?.calculationCostBasePrice?.price?.toFixed(2)}
                            />
                            <TextFieldValidation
                                name={"calculationJobCostBasePrice"}
                                required={false}
                                value={formState?.calculationJobCostBasePrice?.manualPrice}
                                label="Себестоимость работ"
                                setCurrValues={inputHandler}
                                adornment={formState?.calculationJobCostBasePrice?.price?.toFixed(2)}
                            />

                        </Box>
                        <Box sx={{
                            minWidth: 350,

                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <Typography component="p">
                                    Фактическая
                                </Typography>
                            </Box>

                            <TextFieldValidation
                                name={"costBasePrice"}
                                required={false}
                                value={formState?.costBasePrice?.manualPrice}
                                label="Себестоимость материалов"
                                setCurrValues={inputHandler}
                                adornment={formState?.costBasePrice?.price?.toFixed(2)}
                            />
                            <TextFieldValidation
                                name={"jobCostBasePrice"}
                                required={false}
                                value={formState?.jobCostBasePrice?.manualPrice}
                                label="Себестоимость работ"
                                setCurrValues={inputHandler}
                                adornment={formState?.jobCostBasePrice?.price?.toFixed(2)}
                            />
                            <TextFieldValidation
                                name={"basePrice"}
                                required={false}
                                value={formState?.basePrice?.manualPrice}
                                label="Стоимость материалов"
                                setCurrValues={inputHandler}
                                adornment={formState?.basePrice?.price?.toFixed(2)}
                            />
                            <TextFieldValidation
                                name={"baseJobPrice"}
                                required={false}
                                value={formState?.baseJobPrice?.manualPrice}
                                label="Стоимость работ"
                                setCurrValues={inputHandler}
                                adornment={formState?.baseJobPrice?.price?.toFixed(2)}
                            />

                        </Box>
                    </Box>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"} onClick={() => onSubmit(formState)}/>
            </DialogActions>
        </Dialog>
    )
}
export default EditEstimateModal