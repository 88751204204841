export const personalization = {
    birthday: "Дата рождения",
    firstName: "Имя",
    lastName: "Фамилия",
    middleName: "Отчество",
    phoneNumber: "Номер телефона",
    position: "Должность",
    address: "Адрес",
    email: "Почта",
    name: "Наименование",
    organizationalAndLegalForm: "Организационно-правовая форма",
    registrationNumber: "Регистрационный номер",
    residentialAddress: "Адрес доставки",
    regionDelivery: "Область доставки"
}