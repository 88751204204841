import {Stack, TextField} from "@mui/material";
import TextFieldArrivalMaterialModuleItem
    from "../../../../modul/arrivalmaterialmoduleitem/TextFieldArrivalMaterialModuleItem";
import {materialCutDefault} from "../../../../../../data/MaterialCutDefault";
import ArrivalMaterialTextFieldObject from "./ArrivalMaterialTextFieldObject";
import ArrivalMaterialTextField from "./ArrivalMaterialTextField";
import React, {useState} from "react";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";

const AddMaterialPriceParameters = ({
                                        isNew,
                                        values,
                                        setValues,
                                        currentCurrency,
                                        baseCurrency,
                                        alternativeCurrency,
                                        basePriceCurrency,
                                        typeSlabMaterial,
                                        isSlabMaterial,
                                        isBaseCurrency,
                                        isAlternativeCurrency,
                                        calcQuantity,
                                        validationError,
                                        setValidationError
                                    }) => {
    const currentBasePriceCurrency = basePriceCurrency === null ? values !== null ? values.materialPriceInRealBasePrice.currency.curAbbreviation : null : basePriceCurrency
    const isFixedStripe = typeSlabMaterial === null ? (values !== null ? values.supplierMaterial.typeSupplierMaterial === "BY_FIXED_STRIPE" : null) : typeSlabMaterial === "BY_FIXED_STRIPE";
    const currentIsSlabMaterial = isSlabMaterial === null ? (values !== null ? values.materialOrganizationOption.typeMaterial === "SLAB" : null) : isSlabMaterial;

    const [numberOfSheets, setNumberOfSheets] = useState(1)

    const handleNumberOfSheets = (value) => {
        setNumberOfSheets(value)
        setValues(prev => ({
            ...prev,
            quantity: (+value * +values.width * +values.length) / 1000000,
        }))
    }

    return <Stack
        sx={{
            width: '100%',
            minWidth: {xs: '300px', sm: '360px', md: '400px'},
            gap: '0.01rem',
        }}
    >
        {currentIsSlabMaterial && <TextFieldArrivalMaterialModuleItem
            label="Тип прихода"
            value={isNew ? (typeSlabMaterial ? materialCutDefault[typeSlabMaterial] : Object.values(materialCutDefault)[0]) :
                (values !== null ? materialCutDefault[values.supplierMaterial.typeSupplierMaterial] : null)
            }
            field={typeSlabMaterial}
            maxWidth={'600px'}
            disabled={true}
        />}
        <ArrivalMaterialTextFieldObject
            label="Артикул"
            values={values}
            field1={'materialOrganizationOption'}
            field2={'articleNumber'}
            disabled={true}
        />
        <ArrivalMaterialTextFieldObject
            label="Материал"
            values={values}
            field1={'materialOrganizationOption'}
            field2={'name'}
            disabled={true}
        />
        <ArrivalMaterialTextFieldObject
            label="Ед. измерения"
            values={values && values.materialOrganizationOption}
            field1={'unit'}
            field2={'name'}
            disabled={true}
        />
        <ArrivalMaterialTextField
            label={calcQuantity ? "Количество m²" : "Количество"}
            values={values}
            setValues={setValues}
            field={'quantity'}
            disabled={(isSlabMaterial && isFixedStripe) || calcQuantity}
        />
        {calcQuantity && <TextFieldValidation
            label="Количество листов"
            value={numberOfSheets}
            name={"numberOfSheets"}
            setCurrValues={(value) => {
                !Number.isInteger(+value().numberOfSheets) ? setValidationError("Введите целое число") : setValidationError("")
                handleNumberOfSheets(value().numberOfSheets)
            }}
            type={"number"}
            validationError={validationError}
            setValidationState={setValidationError}
        />}
        {currentIsSlabMaterial && <ArrivalMaterialTextField
            label="Ширина"
            values={values}
            setValues={setValues}
            field={'length'}
            disabled={isFixedStripe}
        />}
        {currentIsSlabMaterial && <ArrivalMaterialTextField
            label="Высота"
            values={values}
            setValues={setValues}
            field={'width'}
            disabled={isFixedStripe}
        />}
        <ArrivalMaterialTextField
            label={`Сумма в ${currentCurrency}`}
            values={values}
            setValues={setValues}
            field={'amountInCurrencyArrival'}
        />
        {isBaseCurrency === "true" && <ArrivalMaterialTextField
            label={`Курс ${currentCurrency}\\${baseCurrency} (НБ РБ ${values === null ? null : values.officialExchangeRateToBaseCurrencyBaseMaterials})`}
            values={values}
            setValues={setValues}
            field={'exchangeRateToBaseCurrencyBaseMaterials'}
        />}
        {isAlternativeCurrency === "true" && <ArrivalMaterialTextField
            label={`Курс ${currentCurrency}\\${alternativeCurrency} (НБ РБ ${values === null ? null : values.officialExchangeRateToAlternativeCurrencyBaseMaterials})`}
            values={values}
            setValues={setValues}
            field={'exchangeRateToAlternativeCurrencyBaseMaterials'}
        />}
        {!(currentCurrency === currentBasePriceCurrency || currentBasePriceCurrency === baseCurrency || currentBasePriceCurrency === alternativeCurrency) &&
            <ArrivalMaterialTextField
                label={`Курс ${currentCurrency}\\${currentBasePriceCurrency} базовой цены (НБ РБ ${values === null ? null : values.officialExchangeRateToBasePrice})`}
                values={values}
                setValues={setValues}
                field={'exchangeRateToBasePrice'}
            />}
    </Stack>
}
export default AddMaterialPriceParameters