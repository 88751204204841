import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import {customerOrderType} from "../../../../../../data/CustomerOrderType";
import {useNavigate} from "react-router-dom";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";

const CreateNewOrderModal = ({title, open, onClose, onSubmit, initValue}) => {
    const navigate = useNavigate();

    const allOrderTypes = Object.entries(customerOrderType)
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({numberCustomer: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                "id": null,
                "numberCustomer": formState.numberCustomer,
                "year": null,
                "dateOfCreate": null,
                "orderType": formState.orderType
            })
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack spacing={2}>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextFieldValidation
                            name="numberCustomer"
                            label="Введите номер заказа"
                            value={formState?.numberCustomer}
                            setCurrValues={setFormState}
                            validationError={validationState?.numberCustomer}
                            setValidationState={setValidationState}
                        />
                        <TextFieldValidation
                            select
                            name="orderType"
                            label="Тип заказа"
                            value={formState?.orderType}
                            setCurrValues={setFormState}
                            disabled={initValue?.numberCustomer.length > 0}
                        >
                            {allOrderTypes.map((option) => (
                                <MenuItem key={option[0]} value={option[0]}>
                                    {option[1]}
                                </MenuItem>
                            ))}
                        </TextFieldValidation>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
};

export default CreateNewOrderModal;