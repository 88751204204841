import {
    apiVersionMaterialCloud,
    gatewayUrl,
    nameMaterialCloud,
} from "../../../../Constants";
import axios from "axios";

const jobRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/admin/job-metric-milling-material'

export default class JobMetricAdminMillingService {
    static async get() {
        return axios.get(jobRestUrl);
    }
    static async getForAddMaterial(materialId) {
        return axios.get(jobRestUrl + "/for-add-material?materialId=" + materialId);
    }
    static async update(data, id) {
        return await axios.put(jobRestUrl + "/" + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(jobRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async create(data) {
        return await axios.post(jobRestUrl,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}