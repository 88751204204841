import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Stack, Tooltip, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {materialReserveState} from "../../../../../data/MaterialReserveState";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import CellMaterialEstimateUnitTypePrice from "../../orders/organization/estimate/CellMaterialReserveTypePrice";
import MaterialReserveUtil from "../../../../../utils/warehouse/MaterialReserveUtil";
import CellCheckbox from "../../../сheckbox/CellCheckbox";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import ModalConfirm from "../../../modal/ModalConfirm";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";
import UnitService from "../../../../../API/orders/warehouse/UnitService";
import Common from "../../../../../utils/common/Common";
import {CreateSlabMaterialModal} from "./CreateSlabMaterialModal";
import CellMaterialEstimateBasePrice from "./CellMaterialEstimateBasePrice";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";

const ReservationMaterialEstimateUnitTable = ({
                                                  data,
                                                  setData,
                                                  isColumnMaterialWarehouse,
                                                  isSlab,
                                                  orderId,
                                                  orderHeaderId,
                                                  nameOrder,
                                                  isButtonPurchase,
                                                  estimateId,
                                                  year,
                                                  isIssue,
                                                  unablePackaging,
                                                  setUnablePackaging,
                                                  isLinear,
                                                  enableEditing,
                                                  packaging,
                                                  showSetOnIssue,
                                                  materialEstimateUnitId,
                                                  tableOptions,
                                                  tableOptionsHandle
                                              }) => {

    const allCalculation = MaterialReserveUtil.checkOrAllCalculation(data);

    const profile = JSON.parse(localStorage.getItem("profile"))
    const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
    const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);

    const [openModal, setOpenModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [currentEditRow, setCurrentEditRow] = useState(null);
    const currentIsSlab = isSlab === 'true'
    const currentPackaging = !isSlab && !isLinear && packaging
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mR) => acc + mR.amountAlternativePrice, 0),
        [data]
    );

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mR) => acc + mR.amountBasePrice, 0),
        [data]
    );

    const sumAmountQuantity = useMemo(
        () => data.reduce((acc, mR) => acc + mR.quantity, 0),
        [data]
    );

    const sumIssued = useMemo(
        () => data.reduce((acc, mR) => acc + mR.issued, 0),
        [data]
    );

    const navigate = useNavigate();


    const handleCloseSetPurchase = () => {
        setCurrentId(null);
        setCurrentEditRow(null);
        setOpenModal(false);
    };

    const handleSubmitSetPurchase = async () => {
        MaterialReserveService.issue(currentId,)
            .then(function (response) {
                let dataR = response.data;
                handleCloseSetPurchase()
                Common.handleUpdate(currentEditRow, dataR, data, setData)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        UnitService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };


    const setOnIssue = useCallback(
        (row) => {
            const id = row.id;
            setCurrentId(id);
            setOpenModal(true);
            setCurrentEditRow(row);
        },
        [data],
    );

    const handleDeleteRow = useCallback(
        (row) => {
            MaterialReserveService.delete(row.id, true, true, true, true)
                .then(function () {
                    data.splice(row.id, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'quantity',
                header: 'Необходимо.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountQuantity?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'materialReserveState',
                header: 'Статус',
                accessorKey: 'materialReserveState',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell}) => materialReserveState[cell.getValue()],
            },
            {
                id: 'supplier',
                header: 'Поставщик',
                accessorKey: 'materialWarehouse.materialPriceUnit.supplierMaterial.supplier.name',
                minSize: 350,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },];

        if (isColumnMaterialWarehouse) {
            baseColumns.splice(2, 0, {
                id: 'size', header: 'Размеры', accessorKey: 'size', minSize: 15, maxSize: 15,
            });
        }

        if (isIssue) {
            baseColumns.splice(4, 0, {
                id: 'issued',
                header: 'Выдано',
                accessorKey: 'issued',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return <li key={value}>
                        <Link
                            to={'/organization/issuance-material-reserve/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(3)}</Link>
                    </li>
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumIssued?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </Stack>
                ),
            });
        }

        //TODO
        if (currentPackaging) {
            baseColumns.splice(4, 0, {
                id: 'packaging',
                header: 'Собрано',
                accessorKey: 'packaging',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                /*    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unablePackaging}
                        setUnable={setUnablePackaging}
                    >
                    </CellCheckboxWithUnable>,*/
            });
        }

        if (isIssue) {
            baseColumns.splice(3, 0, {
                id: 'additional',
                header: 'Добавочный',
                accessorKey: 'additional',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell, row}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            });
        }

        if (!isIssue) {
            baseColumns.splice(5, 0, {
                id: 'basePrice',
                header: `Цена ${baseCurrency}`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) =>
                    <CellMaterialEstimateBasePrice
                        value={cell.getValue()}
                        price={row.original.basePrice}
                        typePrice={row.original.materialReserveTypePrice}
                    />,
            });
        }

        if (!isIssue) {
            baseColumns.splice(6, 0, {
                id: 'alternativePrice',
                header: `Цена ${alternativeCurrency}`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) =>
                    <CellMaterialEstimateBasePrice
                        value={cell.getValue()}
                        price={row.original.alternativePrice}
                        typePrice={row.original.materialReserveTypePrice}
                    />,
            });
        }

        if (true) {
            baseColumns.splice(7, 0, {
                id: 'amountBasePrice',
                header: `Сумма ${baseCurrency}`,
                accessorKey: 'amountBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (true) {
            baseColumns.splice(8, 0, {
                id: 'amountAlternativePrice',
                header: `Сумма ${alternativeCurrency}`,
                accessorKey: 'amountAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isIssue) {
            baseColumns.splice(9, 0, {
                id: 'materialReserveTypePrice',
                header: 'Тип цены',
                accessorKey: 'materialReserveTypePrice',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell, row}) => <CellMaterialEstimateUnitTypePrice
                    value={cell.getValue()}
                    row={row}
                />,
            });
        }

        if (!isIssue) {
            baseColumns.splice(10, 0, {
                accessorFn: (row) => {
                    if (row.alternativePrice === null) {
                    } else {
                        return new Date(row.alternativePrice.dateTime)
                    }
                },
                id: 'dateBasePrice',
                header: 'Дата цены',
                sortingFn: 'date',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            });
        }

        if (currentIsSlab) {
            baseColumns.splice(0, 0, {
                id: 'length',
                header: 'Ширина',
                accessorKey: 'materialWarehouse.materialPriceUnit.length',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (currentIsSlab) {
            baseColumns.splice(1, 0, {
                id: 'width',
                header: 'Высота',
                accessorKey: 'materialWarehouse.materialPriceUnit.width',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (!allCalculation) {
            baseColumns.splice(3, 0, {
                id: 'availabilityQuantity',
                header: 'Наличие',
                accessorKey: 'availabilityQuantity',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (!allCalculation) {
            baseColumns.splice(4, 0, {
                id: 'orderedQuantity',
                header: 'Заказано',
                accessorKey: 'orderedQuantity',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (!allCalculation) {
            baseColumns.splice(1, 0, {
                id: 'needToOrderQuantity',
                header: 'Необходимо заказать',
                accessorKey: 'needToOrderQuantity',
                minSize: 15,
                maxSize: 15,
            });
        }

        return baseColumns;

    }, [data, setData, isSlab]);
    const menuItem = showSetOnIssue ? [
        {type: "issue", text: "Выдать штучно", handler: setOnIssue},
        {type: "delete", text: "Удалить", handler: handleDeleteRow}] : [
        {type: "delete", text: "Удалить", handler: handleDeleteRow}
    ]
    return (
        <Box sx={{maxWidth: "2000px"}}>
            <Box
                sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
            >
                <Box sx={{display: 'flex', justifyContent: "spase-between", gap: "9px"}}>
                    <ButtonCreate
                        text={"Добавить резерв"}
                        width={200}
                        onClick={() => setCreateModalOpen(true)}
                    />
                </Box>
            </Box>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",

                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:last-of-type": {
                            padding: 0,
                            width: 'calc(100% - 1px)',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                getRowId={(originalRow) => originalRow.id}
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                state={{showGlobalFilter: true, pagination: tableOptions,}}
                rowCount={tableOptions.totalElements}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
                enableColumnActions={false}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    const available = row.original.quantity !== row.original.issued && row.original.materialReserveState === 'IN_STOCK'
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            available && el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            opacity: !available && 0.5,
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
            />
            <ModalConfirm
                title={"Подтвердить выдачу?"}
                modalOpen={openModal}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
            <CreateSlabMaterialModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                handleCreateNewRow={handleCreateNewRow}
                id={materialEstimateUnitId}
                isSlab={isSlab}
            />
        </Box>);
};

export default ReservationMaterialEstimateUnitTable;
