import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";
import {estimateStatuses} from "../../../../../../data/EstimateState";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import {estimateTableParams} from "../../../../../../data/EstimateTableParams";
import ButtonText from "../../../../button/ButtonText";
import ButtonCreate from "../../../../button/ButtonCreate";
import {estimateTableData} from "../../../../../../utils/orders/EstimateTableData";
import ModalConfirm from "../../../../modal/ModalConfirm";
import {Tooltip} from "@mui/material";
import EditEstimateModal from "./EditEstimateModal";

const EstimateTable = ({
                           data,
                           setData,
                           orderId,
                           orderNumber,
                           orderHeaderId,
                           year,
                           currentCurrency,
                           handleError
                       }) => {
    const tableData = estimateTableData(currentCurrency, data)
    const [modalInWork, setModalInWork] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);

    const navigate = useNavigate();
    const redirectEstimateItem = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year + '/estimate/' + data[0]?.id + "/" + data[0]?.estimateState);
    };

    const handleUpdate = () => {
        setModalEdit(true)
    };

    const handleSubmitModalInWork = () => {
        EstimateService.setInWork(orderId)
            .then(function () {
                setData([{...data[0], estimateState: "IN_WORK"}]);
                setCurrentEditRowData(null);
                setModalInWork(false)
            })
            .catch(function (error) {
                handleError(error);
            })
    };
    const handleSubmitEditData = (data) => {
        EstimateService.save(orderId, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
                setCurrentEditRowData(null);
            })
            .catch(function (error) {
                handleError(error);
            })
        setModalEdit(false)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'param',
                header: `Параметр`,
                accessorKey: "param",
                Cell: ({cell}) => estimateTableParams[cell.getValue()],
                minSize: 15,
                maxSize: 15,

            },
            {
                id: 'plan',
                header: `План`,
                accessorKey: "plan",
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const manual = cell.getValue()?.manualPrice !== null
                    const value = row.original.param === "STATE" || row.original.param === "GROSSMARGIN" ? cell.getValue() : manual ? cell.getValue()?.manualPrice : cell.getValue()?.price;
                    return <Tooltip arrow placement="left"
                                    title={manual ? `Расчётное значение ${cell.getValue()?.price?.toFixed(2)}` : ""}>
                        {row.original.param === "STATE" ? estimateStatuses[value] : row.original.param === "JOB" || row.original.param === "PRICE" || row.original.param === "SALES" ? "" : value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}
                    </Tooltip>
                },

            }, {
                id: 'fact',
                header: `Фактическая`,
                accessorKey: "fact",
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const manual = cell.getValue()?.manualPrice !== null
                    const value = row.original.param === "STATE" || row.original.param === "GROSSMARGIN" ? cell.getValue() : manual ? cell.getValue()?.manualPrice : cell.getValue()?.price;
                    return <Tooltip arrow placement="left"
                                    title={manual ? `Расчётное значение ${cell.getValue()?.price?.toFixed(2)}` : ""}>
                        {row.original.param === "STATE" ? "" : value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}
                    </Tooltip>
                },
            }
        ];

        return baseColumns;

    }, [data, setData]);

    return (
        <Box sx={{
            display: "flex",
            gap: "24px",
            flexDirection: "column",
            width: "746px",
            padding: "24px",
            borderRadius: "16px",
            border: (theme) => `${theme.palette.primary.stroke} 1px solid`
        }}>
            <MaterialReactTable
                muiTablePaperProps={{
                    sx: {
                        boxShadow: "none"
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        color: "rgba(115, 116, 120, 1)",
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem",
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}
                columns={columns}
                data={tableData}
                enableRowSelection={false}
                enableKeyboardShortcuts={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                localization={MRT_Localization_RU}
                enableTopToolbar={false}
                enableRowActions={false}
                enableToolbarInternalActions={false}
                enableTableFooter={false}
                enableBottomToolbar={false}
                enableStickyFooter={false}
                muiTableContainerProps={{
                    sx: {
                        width: "746px"
                    }
                }}
            />
            <Box sx={{display: "flex", gap: "24px"}}>
                <ButtonText text={"Подробнее"} blue={true} onClick={() => redirectEstimateItem()}/>
                <ButtonCreate
                    text={"Изменить"}
                    width={90}
                    icon={false}
                    onClick={handleUpdate}
                />
                <ButtonCreate
                    text={"Отправить в работу"}
                    width={170}
                    icon={false}
                    onClick={() => setModalInWork(true)}
                    disabled={data[0]?.estimateState !== 'CALCULATION'}
                />
            </Box>

            {modalInWork && <ModalConfirm
                title={"Отправить заказ в работу?"}
                modalOpen={modalInWork}
                handleClose={() => setModalInWork(false)}
                handleSubmit={handleSubmitModalInWork}
                action={"Подтвердить"}
            />}
            {modalEdit && <EditEstimateModal
                open={modalEdit}
                onClose={() => setModalEdit(false)}
                onSubmit={handleSubmitEditData}
                initFormState={data[0]}/>}
        </Box>
    );
};

export default EstimateTable;