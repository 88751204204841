import React from 'react';
import MainOptionsMaterialModuleEntityTextField from "./MainOptionsMaterialModuleEntityTextField";
import {Button, DialogContent, Stack} from "@mui/material";
import TextFieldWithSetValueMainOptions from "./TextFieldWithSetValueMainOptions";
import Box from "@mui/material/Box";
import MaterialTextFieldObjectWithEntityList from "./MaterialTextFieldObjectWithEntityList";
import {materialCutDefault} from "../../../../data/MaterialCutDefault";
import {statusesYesOrNo} from "../../../../data/StatusesYesOrNo";
import {typeMaterial, typeMaterialWithSubtype} from "../../../../data/TypeMaterial";
import TextFieldArrivalMaterialModuleItem from "../arrivalmaterialmoduleitem/TextFieldArrivalMaterialModuleItem";
import ButtonAdd from "../../button/ButtonAdd";
import {structure} from "../../../../data/Structure";

const CurrentOptionsMaterialModule = ({
                                          data,
                                          setData,
                                          supplierList,
                                          groupMaterialList,
                                          handleSubmit,
                                          addMaterial,
                                          setSupplierId,
                                          setManufacturerId,
                                          manufacturerList,
                                          setGroupMaterialId,
                                          baseUnit,
                                          isDataMOO,
                                          isMaterialOrganizationOption,
                                      }) => {

    const isSlab = data?.typeMaterial === 'SLAB'

    const isEDGE = data?.subTypeMaterial === 'EDGE'

    const setId = (type, id) => {
        setData(prev => ({...prev, [type]: {id: id}}))
    }
    return (
        <DialogContent sx={{padding: 0}}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    "& > *:not(:last-child)": {marginRight: '20px'}
                }}>
                    {isDataMOO && <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '500px',
                        }}
                    >
                        <Box>
                            <TextFieldWithSetValueMainOptions
                                label="Артикул"
                                values={data}
                                setValues={setData}
                                field={'articleNumber'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Текущее найменование"
                                values={data}
                                setValues={setData}
                                field={'name'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Короткое имя"
                                values={data}
                                setValues={setData}
                                field={'shortName'}
                            />
                            {supplierList && <MainOptionsMaterialModuleEntityTextField
                                label={'Поставщик по умолчанию'}
                                values={data}
                                field={'defaultSupplier'}
                                childFirstField={'id'}
                                setId={(id) => setId("defaultSupplier", id)}
                                entityList={supplierList}
                            />}
                            <TextFieldArrivalMaterialModuleItem
                                label="Ед. измерения"
                                value={baseUnit}
                                field={baseUnit}
                                maxWidth={'600px'}
                                disabled={true}
                            />
                            <MaterialTextFieldObjectWithEntityList
                                label="Тип материала"
                                values={data}
                                setValues={setData}
                                field={'typeMaterial'}
                                entityList={typeMaterial}
                                disabled={true}
                            />
                            <MaterialTextFieldObjectWithEntityList
                                label="Подтип материала"
                                values={data}
                                setValues={setData}
                                field={'subTypeMaterial'}
                                entityList={typeMaterialWithSubtype[data?.typeMaterial]?.subtype}
                                disabled={true}
                            />
                            {manufacturerList && <MainOptionsMaterialModuleEntityTextField
                                label={'Производитель'}
                                values={data}
                                field={'manufacturer'}
                                childFirstField={'id'}
                                setId={(id) => setId("manufacturer", id)}
                                entityList={manufacturerList}
                            />}
                            <MaterialTextFieldObjectWithEntityList
                                label="Архив"
                                values={data}
                                setValues={setData}
                                field={'archive'}
                                entityList={statusesYesOrNo}
                            />

                        </Box>
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Ширина"
                                values={data}
                                setValues={setData}
                                field={'length'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Высота"
                                values={data}
                                setValues={setData}
                                field={'width'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Толщина"
                                values={data}
                                setValues={setData}
                                field={'thickness'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Плотность, Вес"
                                values={data}
                                setValues={setData}
                                field={'density'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>

                            <TextFieldWithSetValueMainOptions
                                label="Маржа по умолчанию"
                                values={data}
                                setValues={setData}
                                field={'defaultMargin'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Коэффициент облака"
                                values={data}
                                setValues={setData}
                                field={'recalculationRatioPrice'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>
                        <MaterialTextFieldObjectWithEntityList
                            label="Автоматический пересчёт коэффициента облака"
                            values={data}
                            setValues={setData}
                            field={'automaticRecalculationPrice'}
                            entityList={statusesYesOrNo}
                        />
                    </Stack>}
                    {isDataMOO && <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '200px',
                        }}
                    >
                        {(isSlab || isEDGE) && <MaterialTextFieldObjectWithEntityList
                            label="Структура"
                            values={data}
                            setValues={setData}
                            field={'structure'}
                            entityList={structure}
                        />}
                        {isSlab && <MaterialTextFieldObjectWithEntityList
                            label="Кроить по умолчанию"
                            values={data}
                            setValues={setData}
                            field={'cutDefault'}
                            entityList={materialCutDefault}
                        />}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп слева"
                                values={data}
                                setValues={setData}
                                field={'trimmingLeft'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп справа"
                                values={data}
                                setValues={setData}
                                field={'trimmingRight'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп снизу"
                                values={data}
                                setValues={setData}
                                field={'trimmingBottom'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                marginRight={'10px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп сверху"
                                values={data}
                                setValues={setData}
                                field={'trimmingTop'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>}
                        {isSlab && <TextFieldWithSetValueMainOptions
                            label="Минимальный остаток до целого листа"
                            values={data}
                            setValues={setData}
                            field={'minBalanceUpToWholeSheet'}
                            minWidthXS={'25px'}
                            minWidthSM={'25px'}
                            minWidthPX={'25px'}
                        />}

                        {isEDGE && <TextFieldWithSetValueMainOptions
                            label="Свесы кромки"
                            values={data}
                            setValues={setData}
                            field={'overhangEdge'}
                            minWidthXS={'25px'}
                            minWidthSM={'25px'}
                            minWidthPX={'25px'}
                        />}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Мин. ширина обрезка"
                                values={data}
                                setValues={setData}
                                field={'minWidthCutOff'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Мин. высота обрезка"
                                values={data}
                                setValues={setData}
                                field={'minLengthCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезка слева"
                                values={data}
                                setValues={setData}
                                field={'trimmingLeftCutOff'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезка справа"
                                values={data}
                                setValues={setData}
                                field={'trimmingRightCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезка снизу"
                                values={data}
                                setValues={setData}
                                field={'trimmingBottomCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                marginRight={'10px'}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезк сверху"
                                values={data}
                                setValues={setData}
                                field={'trimmingTopCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Ширина реза"
                                values={data}
                                setValues={setData}
                                field={'cutWeight'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                            <Box sx={{marginLeft: '10px'}}/>
                            <TextFieldWithSetValueMainOptions
                                label="Кол. поворотов"
                                values={data}
                                setValues={setData}
                                field={'limitNumberTurns'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />
                        </Box>}
                        <MaterialTextFieldObjectWithEntityList
                            label="Выдача не по смете"
                            values={data}
                            setValues={setData}
                            field={'deliveryNotAccordingToEstimate'}
                            entityList={statusesYesOrNo}
                        />
                        <MaterialTextFieldObjectWithEntityList
                            label="Собвенный"
                            values={data}
                            setValues={setData}
                            field={'custom'}
                            entityList={statusesYesOrNo}
                        />
                        <MaterialTextFieldObjectWithEntityList
                            label="Отобразить"
                            values={data}
                            setValues={setData}
                            field={'display'}
                            entityList={statusesYesOrNo}
                        />
                    </Stack>}
                </Box>
                <Box sx={{
                    width: 920,
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: data ? "flex-end" : "flex-start"
                }}>
                    {isDataMOO ? <ButtonAdd text={"Применить"} onClick={handleSubmit}/> :
                        <ButtonAdd text={"Добавить"} onClick={addMaterial}/>}
                </Box>
            </form>
        </DialogContent>
    );
};

export default CurrentOptionsMaterialModule;