import React, {useCallback, useMemo, useState} from 'react';
import {Button, Tooltip, Typography} from '@mui/material';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import ModalConfirm from "../../../../modal/ModalConfirm";
import CreateNewOrderModal from "./CreateNewOrderModal";
import CustomerOrderService from "../../../../../../API/orders/orders/customer/CustomerOrderService";
import {customerOrderType} from "../../../../../../data/CustomerOrderType";
import {estimateStatuses} from "../../../../../../data/EstimateState";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import selectionTableUtil from "../../../../../../utils/common/SelectionTableUtil";

const CustomerOrderTable = ({
                                archive,
                                data,
                                setData,
                                createType,
                                rowSelection,
                                setRowSelection,
                                handleError,
                                tableOptions,
                                tableOptionsHandle,
                            }) => {
    const navigate = useNavigate();
    const [createModalOpen, setCreateModalOpen] = useState(createType);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState({
        numberCustomer: "",
        orderType: createType || Object.entries(customerOrderType)[0][0]
    });
    const [isDelete, setIsDelete] = useState(false);
    const {currentSelection, onRowSelectionChange} = selectionTableUtil(data, rowSelection, setRowSelection)

    const handleClose = () => {
        createType && navigate('/customer/orders')
        setCreateModalOpen(false)
        setCurrentEditRowData(null)
    }
    const handleUpdateModel = (row) => {
        setCurrentEditRowData({
            numberCustomer: row.numberCustomer,
            orderType: row.orderType
        })
        setCreateModalOpen(true)
    }
    const handleCreateModel = () => {
        setCurrentEditRowData({
            numberCustomer: "",
            orderType: Object.entries(customerOrderType)[0][0]
        })
        setCreateModalOpen(true)
    }

    const handleSubmit = (values) => {
        CustomerOrderService.save(values)
            .then(function (response) {
                setData(prev => [...prev, response.data])
                tableOptionsHandle((prev) => ({...prev, totalElements: response?.data.length}))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    };

    const handleUpdate = (data) => {
        CustomerOrderService.edit(setCurrentEditRowData?.id, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    };

    const sendToArchive = (row) => {
        setCurrentEditRowData(row);
        setConfirmModalOpen(true);
    }

    const handleDeleteOrder = (row) => {
        setConfirmModalOpen(true);
        setCurrentEditRowData(row)
        setIsDelete(true)
    };
    const handleSubmitConfirm = () => {
        if (isDelete) {
            CustomerOrderService.delete(currentEditRowData?.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== currentEditRowData?.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                    setCurrentEditRowData(null)
                    setConfirmModalOpen(false);
                    setIsDelete(false)
                })
                .catch(function (error) {
                    handleError(error)
                })
        } else {
            CustomerOrderService.sendToArchiveById(currentEditRowData.id)
                .then(function () {
                    setData(prev => prev.filter(el => el.id !== currentEditRowData?.id))
                    tableOptionsHandle((prev) => ({...prev, totalElements: data.length - 1}))
                    setCurrentEditRowData(null);
                    setConfirmModalOpen(false);
                })
                .catch(function (error) {
                    handleError(error);
                })
        }
    };

    const handleCloseConfirm = () => {
        setConfirmModalOpen(false);
        setCurrentEditRowData(null)
        setIsDelete(false)
    };


    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'numberCustomer',
                header: 'Номер заказа',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                size: 400,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/customer/orders/' + data[row.index].id + '/' + data[row.index].numberCustomer}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'orderType',
                header: 'Тип заказа',
                accessorKey: 'orderType',
                filterFn: 'contains',
                size: 400,
                Cell: ({cell}) => customerOrderType[cell.getValue()],
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimateState',
                filterFn: 'contains',
                size: 400,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfCreate === null) {
                    } else {
                        return new Date(row.dateOfCreate)
                    }
                },
                id: 'dateOfCreate',
                header: 'Дата',
                size: 400,
                sortingFn: 'datetime',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        return baseColumns;

    }, [data, setData]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleUpdateModel},
        {type: "archive", text: "Поместить в архив", handler: sendToArchive},
        {type: "delete", text: "Удалить", handler: handleDeleteOrder}
    ]

    return (
        <Box sx={{maxWidth: "1800px"}}>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "8px"}}
            ><
                Typography sx={{fontSize: "24px", fontWeight: 700}}>Заказы</Typography>
                <Box sx={{display: 'flex', gap: "8px"}}>
                    {!archive && <ButtonCreate
                        onClick={handleCreateModel}
                        width={170}
                        variant="contained"
                        text={"Создать заказ"}
                    />}
                </Box>
            </Box>
            <MaterialReactTable
                enableColumnFilterModes
                enableGrouping={false}
                enableStickyHeader={false}
                enablePinning={false}
                // muiTableProps={{
                //     sx: {
                //         tableLayout: 'fixed',
                //     },
                // }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "50px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light,
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light
                                },
                            },
                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: "50px",
                        grow: false,
                    },
                }}
                columns={columns}
                data={data}
                onRowSelectionChange={onRowSelectionChange}
                state={{rowSelection: currentSelection, showGlobalFilter: true, pagination: tableOptions}}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                initialState={{
                    columnVisibility: {
                        id: false,
                        model: false,
                        year: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}

                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        disabled={el.type === "archive" && row.original.estimateState !== "SHIPMENT" || el.type === "delete" && row.original.estimateState !== "WAIT_OFFERS"}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })
                }
                onPaginationChange={(updater) => {
                    tableOptionsHandle(updater(tableOptions));
                }}
                rowCount={tableOptions.totalElements}
                enableRowSelection={archive ? false : (row) => row.original.estimateState === "SHIPMENT"}
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />

            <CreateNewOrderModal
                title={currentEditRowData?.id ? "Изменить номер заказа" : "Создать заказ"}
                initValue={currentEditRowData}
                open={createModalOpen}
                onClose={handleClose}
                onSubmit={currentEditRowData?.id ? handleUpdate : handleSubmit}
            />
            <ModalConfirm
                title={isDelete ? "Подтвердить удаление" : "Подтвердить поместить в архив"}
                modalOpen={confirmModalOpen}
                handleClose={handleCloseConfirm}
                handleSubmit={handleSubmitConfirm}
                action={"Подтвердить"}
            />
        </Box>
    );
};

export default CustomerOrderTable;