import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";
import axios from "axios";

const materialEstimateUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/job-metric-order-organization'

export default class ModelJobMetricOrderService {
    static async create(materialEstimateUnitId, data) {
        return await axios.post(materialEstimateUrl + '?materialEstimateUnitId=' + materialEstimateUnitId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(materialEstimateUrl + '/' + id);
    }

    static async update(id,data) {
        return await axios.put(materialEstimateUrl + '/' + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}