import React, {useEffect, useState} from 'react';
import HeaderService from "../../../../../../API/orders/orders/organization/HeaderService";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../../textfield/TextFieldWithSetValue";
import TextFieldObjectWithEntityList from "../../../../textfield/TextFieldObjectWithEntityList";
import OrderDatePicker from "../../../../datepicker/OrderDatePicker";
import MaterialTextFieldObjectWithEntityList from "../../../../modul/mainoptions/MaterialTextFieldObjectWithEntityList";
import {orderStatus} from "../../../../../../data/OrderStatus";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";

const UpdateOrderModal = ({
                              open,
                              onClose,
                              onSubmit,
                              values,
                              setValues,
                              change,
                              setChange,
                              dateOfReceipt,
                              setDateOfReceipt,
                              dateOfPlannedProduction,
                              setDateOfPlannedProduction,
                              plannedDateOfShipment,
                              setPlannedDateOfShipment,
                              manufactured,
                              setManufactured,
                              shipped,
                              setShipped
                          }) => {
    const [modelHeaderList, setModelHeaderList] = useState([]);
    const [modelHeaderId, setModelHeaderId] = useState(null);

    useEffect(() => {
        HeaderService.getAllUnable().then(function (response) {
            const data = response.data;
            setModelHeaderList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const currentStatus = values?.status;
    const delta = currentStatus === "NEW" ? 0 : 1
    const statusesToExclude = Object.keys(orderStatus).slice(0, Object.keys(orderStatus).indexOf(currentStatus) - delta);
    statusesToExclude.push('ARCHIVE');

    const filteredOrderStatus = Object.fromEntries(
        Object.entries(orderStatus).filter(([key]) => !statusesToExclude.includes(key))
    );

    const handleSubmit = () => {
        values.dateOfReceipt = dateOfReceipt === null ? null : DateTimeUtil.dateConvert(dateOfReceipt);
        values.dateOfPlannedProduction = dateOfPlannedProduction === null ? null : DateTimeUtil.dateConvert(dateOfPlannedProduction);
        values.plannedDateOfShipment = plannedDateOfShipment === null ? null : DateTimeUtil.dateConvert(plannedDateOfShipment);
        values.manufactured = manufactured === null ? null : DateTimeUtil.dateConvert(manufactured);
        values.shipped = shipped === null ? null : DateTimeUtil.dateConvert(shipped);
        onSubmit(values,
            modelHeaderId === null ? values.header.id : modelHeaderId,
        );
        onClose();

    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Редактировать заказ</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                        }}
                    >
                        <TextFieldWithSetValue
                            label="Номер заказа"
                            values={values}
                            setValues={setValues}
                            field={'number'}
                        />
                        <TextFieldWithSetValue
                            label="Номер заказчика"
                            values={values}
                            setValues={setValues}
                            field={'numberCustomer'}
                        />
                        <TextFieldObjectWithEntityList
                            label="Найменование"
                            values={values}
                            field={'header'}
                            setId={setModelHeaderId}
                            entityList={modelHeaderList}
                        />
                        <OrderDatePicker
                            label={'Дата поступления'}
                            date={dateOfReceipt}
                            setDate={setDateOfReceipt}
                        />
                        <MaterialTextFieldObjectWithEntityList
                            label="Статус"
                            values={values}
                            setValues={setValues}
                            field={'status'}
                            entityList={filteredOrderStatus}
                        />
                        <OrderDatePicker
                            label={'Плановая дата производства'}
                            date={dateOfPlannedProduction}
                            setDate={setDateOfPlannedProduction}
                        />
                        <OrderDatePicker
                            label={'Произведено'}
                            date={manufactured}
                            setDate={setManufactured}
                        />
                        <OrderDatePicker
                            label={'Плановая дата отгрузки'}
                            date={plannedDateOfShipment}
                            setDate={setPlannedDateOfShipment}
                        />
                        <OrderDatePicker
                            label={'Отгружено'}
                            date={shipped}
                            setDate={setShipped}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выход"} onClick={onClose}/>
                <ButtonAdd text={"Сохранить"} onClick={handleSubmit}/>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateOrderModal;