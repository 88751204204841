import React from 'react';
import MainOptionsMaterialModuleEntityTextField from "./MainOptionsMaterialModuleEntityTextField";
import {Button, DialogContent, Stack} from "@mui/material";
import TextFieldWithSetValueMainOptions from "./TextFieldWithSetValueMainOptions";
import Box from "@mui/material/Box";
import MaterialTextFieldObjectWithEntityList from "./MaterialTextFieldObjectWithEntityList";
import {typeMaterial} from "../../../../data/TypeMaterial";
import {structure} from "../../../../data/Structure";
import {statusesYesOrNo} from "../../../../data/StatusesYesOrNo";
import TextFieldArrivalMaterialModuleItem from "../arrivalmaterialmoduleitem/TextFieldArrivalMaterialModuleItem";
import ButtonAdd from "../../button/ButtonAdd";

const CloudOptionsMaterialModule = ({
                                        data,
                                        setData,
                                        supplierList,
                                        groupMaterialList,
                                        manufacturerList,
                                        handleSubmit,
                                        setSupplierId,
                                        setManufacturerId,
                                        setGroupMaterialId,
                                        baseUnit,
                                        changeOptionsDisabled,
                                        isMaterialOrganizationOption,
                                        isSettings
                                    }) => {

    const isSlab = isMaterialOrganizationOption ? data && data.typeMaterial === 'SLAB' : data && data.typeMaterial === 'SLAB';

    return (
        <DialogContent sx={{padding: 0}}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    "& > *:not(:last-child)": {marginRight: '20px'}
                }}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '500px',
                        }}
                    >
                        <TextFieldWithSetValueMainOptions
                            label="Артикул"
                            values={data}
                            setValues={setData}
                            field={'articleNumber'}
                            disabled={changeOptionsDisabled}
                        />
                        <TextFieldWithSetValueMainOptions
                            label="Найменование по умолчанию"
                            values={data}
                            setValues={setData}
                            field={'name'}
                            disabled={changeOptionsDisabled}
                        />
                        <TextFieldArrivalMaterialModuleItem
                            label="Ед. измерения"
                            value={baseUnit}
                            field={baseUnit}
                            maxWidth={'600px'}
                            disabled={true}
                        />
                        <MaterialTextFieldObjectWithEntityList
                            label="Тип материала"
                            values={data}
                            setValues={setData}
                            field={'typeMaterial'}
                            entityList={typeMaterial}
                            disabled={changeOptionsDisabled}
                        />
                        {manufacturerList && <MainOptionsMaterialModuleEntityTextField
                            label={'Производитель'}
                            values={data}
                            field={'manufacturer'}
                            childFirstField={'id'}
                            setId={setManufacturerId}
                            entityList={manufacturerList}
                            disabled={changeOptionsDisabled}
                        />}
                        {<MaterialTextFieldObjectWithEntityList
                            label="Архив"
                            values={data}
                            setValues={setData}
                            field={'archive'}
                            entityList={statusesYesOrNo}
                            disabled={changeOptionsDisabled}
                        />}
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '200px',
                        }}
                    >
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Ширина"
                                values={data}
                                setValues={setData}
                                field={'length'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={changeOptionsDisabled}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Высота"
                                values={data}
                                setValues={setData}
                                field={'width'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={changeOptionsDisabled}
                            />
                        </Box>}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Толщина"
                                values={data}
                                setValues={setData}
                                field={'thickness'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={changeOptionsDisabled}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Плотность"
                                values={data}
                                setValues={setData}
                                field={'density'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={changeOptionsDisabled}
                            />
                        </Box>}
                        {isSlab && <MaterialTextFieldObjectWithEntityList
                            label="Структура"
                            values={data}
                            setValues={setData}
                            field={'structure'}
                            entityList={structure}
                            disabled={changeOptionsDisabled}
                        />}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп слева"
                                values={data}
                                setValues={setData}
                                field={'trimmingLeft'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={changeOptionsDisabled}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп справа"
                                values={data}
                                setValues={setData}
                                field={'trimmingRight'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={changeOptionsDisabled}
                            />
                        </Box>}
                        {isSlab && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп снизу"
                                values={data}
                                setValues={setData}
                                field={'trimmingBottom'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                marginRight={'10px'}
                                disabled={changeOptionsDisabled}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп сверху"
                                values={data}
                                setValues={setData}
                                field={'trimmingTop'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={changeOptionsDisabled}
                            />
                        </Box>}
                    </Stack>
                </Box>
                <Box sx={{width: 920, paddingTop: "20px", display: "flex", justifyContent: "flex-end"}}>
                    {!changeOptionsDisabled && isSettings && <ButtonAdd text={"Сохранить"} onClick={handleSubmit}/>}
                </Box>
            </form>
        </DialogContent>
    );
};

export default CloudOptionsMaterialModule;