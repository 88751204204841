import React, {useEffect, useState} from 'react';

import {Button, Paper, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import JobMetricCutting from "../../../../../API/material-cloud/common/JobMetricCutting";
import JobMetricEdge from "../../../../../API/material-cloud/common/JobMetricEdge";
import JobMetricMilling from "../../../../../API/material-cloud/common/JobMetricMilling";
import JobMetricDrilling from "../../../../../API/material-cloud/common/JobMetricDrilling";
import JobMetricPacking from "../../../../../API/material-cloud/common/JobMetricPacking";
import JobMetricOrganizationTable from "../../prodaction/jobmetricorganization/JobMetricOrganizationTable";
import ButtonSelectAction from "../../../button/ButtonSelectAction";
import {ModalSelectAction} from "../../../modal/ModalSelectAction";
import {EditLocalStoragePagesData} from "../../../../../utils/EditLocalStoragePagesData";
import MaterialService from "../../../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";
import {ButtonGroupTabBar} from "../../../button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";

const JobMetricCloudModul = ({id, handleError}) => {
    const [tab, setTab] = useState("0");
    const [dataJobMetric, setDataJobMetric] = useState({
        "0": {type: "CUT", data: []},
        "1": {type: "MILLING", data: []},
        "2": {type: "EDGE", data: []},
        "3": {type: "DRILLING", data: []},
        "4": {type: "PACKAGING", data: []},
    });

    const [rowSelection, setRowSelection] = useState([]);
    const [openSelection, setOpenSelection] = useState(false)

    const [tableOptions, setTableOptions] = useState({
        pageIndex: 0,
        pageSize: 20,
        totalElements: 0,
        searchData: ""
    })

    const tableOptionsHandle = (nextState, currTab) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(`/all-materials/jobs+${tab}`, "pageSize", nextState.pageSize)
        }
    }
    const getStartPageSize = (values) => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const savedTab = pagesData["/all-materials/jobs"]?.tab
        savedTab && setTab(savedTab)
        getTabsData(savedTab)
        setTableOptions((prev) => ({
            ...prev,
            totalElements: values[savedTab]?.data.length
        }))
    }
    useEffect(() => {
        Promise.all([JobMetricCutting.getJobMetricCuttingByMaterialId(id), JobMetricMilling.getJobMetricMillingByMaterialId(id), JobMetricEdge.getJobMetricEdgeByMaterialId(id), JobMetricDrilling.getJobMetricDrillingByMaterialId(id), JobMetricPacking.getJobMetricPackingByMaterialId(id)]).then((values) => {
            setDataJobMetric({
                "0": {type: "CUT", data: values[0]?.data},
                "1": {type: "MILLING", data: values[1]?.data},
                "2": {type: "EDGE", data: values[2]?.data},
                "3": {type: "DRILLING", data: values[3]?.data},
                "4": {type: "PACKAGING", data: values[4]?.data},
            })
            getStartPageSize(values)

        }).catch(function (error) {
            handleError(error);
        });
    }, []);

    const handleDeleteJob = () => {
        MaterialService.deleteJobMetric(id, {"ids": rowSelection}, dataJobMetric[tab].type).then(function (response) {
            const newJobMetricData = dataJobMetric[tab].data.filter(el => !rowSelection.includes(el.id))
            setDataJobMetric(prev => ({...prev, [tab]: newJobMetricData}))
            tableOptionsHandle((prev) => ({
                ...prev,
                totalElements: dataJobMetric[tab]?.data.length - rowSelection.length
            }))
            setOpenSelection(false)
            setRowSelection([])
        })
            .catch(function (error) {
                handleError(error);
            });
    }
    const handleAddJob = (data, addedData) => {
        setDataJobMetric(prev => ({...prev, [tab]: [...data, ...addedData]}))
    }

    const handleSelectAction = () => {
        handleDeleteJob()
    }
    const modalActions = [
        {name: "Удалить работу", type: "delete"},
    ]
    const getTabsData = (tab, start) => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[`/all-materials/jobs+${tab}`]?.pageSize

        const totalElements = dataJobMetric[tab]?.data?.length

        setTableOptions((prev) => ({
            ...prev,
            pageSize: pageSize || 20,
            totalElements: start ? tableOptions?.totalElements : totalElements
        }))
    }

    const handleChangeTab = (tab) => {
        setTab(tab)
        getTabsData(tab)
        EditLocalStoragePagesData(`/all-materials/jobs`, "tab", tab)
    }
    const renderContent = () => {
        switch (tab) {
            case "0":
                return <JobMetricOrganizationTable
                    id={id}
                    data={dataJobMetric["0"]?.data}
                    type={dataJobMetric["0"]?.type}
                    setData={handleAddJob}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isChooseSignJob={false}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    columnAdded={false}
                    admin={true}
                    rowActions={false}
                    handleError={handleError}
                    itemId={"listJobCutting"}
                    enableRowSelection={true}
                    fullWidth={true}
                    addJob={true}
                />
            case "1":
                return <JobMetricOrganizationTable
                    id={id}
                    data={dataJobMetric["1"]?.data}
                    type={dataJobMetric["1"]?.type}
                    setData={handleAddJob}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isChooseSignJob={false}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    columnAdded={false}
                    admin={true}
                    rowActions={false}
                    handleError={handleError}
                    itemId={"listJobMilling"}
                    enableRowSelection={true}
                    fullWidth={true}
                    addJob={true}
                />
            case "2":
                return <JobMetricOrganizationTable
                    id={id}
                    data={dataJobMetric["2"]?.data}
                    type={dataJobMetric["2"]?.type}
                    setData={handleAddJob}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isChooseSignJob={false}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    columnAdded={false}
                    admin={true}
                    rowActions={false}
                    handleError={handleError}
                    itemId={"listJobEdge"}
                    enableRowSelection={true}
                    fullWidth={true}
                    addJob={true}
                />
            case "3":
                return <JobMetricOrganizationTable
                    id={id}
                    data={dataJobMetric["3"]?.data}
                    type={dataJobMetric["3"]?.type}
                    setData={handleAddJob}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isChooseSignJob={false}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    columnAdded={false}
                    admin={true}
                    rowActions={false}
                    handleError={handleError}
                    itemId={"listJobDrilling"}
                    enableRowSelection={true}
                    fullWidth={true}
                    addJob={true}
                />
            case "4":
                return <JobMetricOrganizationTable
                    id={id}
                    data={dataJobMetric["4"]?.data}
                    type={dataJobMetric["4"]?.type}
                    setData={handleAddJob}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isChooseSignJob={false}
                    tableOptions={tableOptions}
                    tableOptionsHandle={tableOptionsHandle}
                    columnAdded={false}
                    admin={true}
                    rowActions={false}
                    handleError={handleError}
                    itemId={"listJobPacking"}
                    enableRowSelection={true}
                    fullWidth={true}
                    addJob={true}
                />
        }
    }
    return (
        <div>
            {<Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Box sx={{
                    flexGrow: 1, display: "flex",
                    flexDirection: "column", gap: "20px"
                }}>
                    <ButtonGroupTabBar activeTab={tab}>
                        <Button tabIndex={"0"} onClick={(e) => handleChangeTab("0")}>
                            <CheckIcon/>Раскрой
                        </Button>
                        <Button tabIndex={'1'} onClick={(e) => handleChangeTab('1')}>
                            <CheckIcon/>Фрезерование
                        </Button>
                        <Button tabIndex={'2'} onClick={(e) => handleChangeTab('2')}>
                            <CheckIcon/>Кромление
                        </Button>
                        <Button tabIndex={'3'} onClick={(e) => handleChangeTab('3')}>
                            <CheckIcon/>Сверление
                        </Button>
                        <Button tabIndex={'4'} onClick={(e) => handleChangeTab('4')}>
                            <CheckIcon/>Упаковка
                        </Button>
                    </ButtonGroupTabBar>
                    {renderContent()}
                </Box>
            </Box>}
            {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: '100%',
                height: "88px",
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.primary.lightGrey,
            }} component="footer">
                <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpenSelection(true)}/>
            </Paper>}
            <ModalSelectAction
                open={openSelection}
                reset={() => setOpenSelection(false)}
                submit={handleSelectAction}
                modalActions={modalActions}
            />
        </div>
    );
};

export default JobMetricCloudModul;