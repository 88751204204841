import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle, Step, StepLabel, Stepper,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ButtonExit from "../../../../button/ButtonExit";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MaterialPageItemModule from "../../../../modul/MaterialPageItemModule";
import ButtonBack from "../../../../button/ButtonBack";
import ButtonAdd from "../../../../button/ButtonAdd";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";

const ModalAddMaterial = ({title, open, initValue, onClose, handleSubmit, handleError}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [formState, setFormState] = useState(initValue)

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const selectMaterial = (material) => {
        setFormState((value) => {
            return {...value, material: material}
        })
    }
    const submit = () => {
        if (activeStep === 0) {
            setActiveStep(1)
        } else {
            handleSubmit(formState)
            onClose()
            setActiveStep(0)
        }
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${activeStep === 0 ? "1080px" : "900px"}`,
                height: `${activeStep === 0 ? "870px" : "500px"}`,
                borderRadius: 8,
            }
        }}>
            <Box style={{
                padding: 40,
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{title}</DialogTitle>
                <Stepper
                    style={{width: 446}}
                    activeStep={activeStep}
                >
                    {["Материал", "Количество"].map((label) => (
                        <Step key={label}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                }
                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box sx={{
                height: "32px",
                fontSize: "18px",
                margin: "0px 40px 0px 40px",
                display: "flex",
                alignItems: "center",
                borderBottom: formState.material?.id ? "#3B8BEC 1px solid" : "transparent 1px solid",
                '& .MuiTypography-root': {
                    fontWeight: (theme) => theme.typography.fontWeight,
                    color: (theme) => theme.palette.text.main,
                    '&:first-of-type': {
                        marginRight: '10px'
                    }
                },
            }}>
                <Typography component="p">{formState.material?.articleNumber}</Typography>
                <Typography component="p">{formState.material?.name}</Typography>
            </Box>
            <DialogContent sx={{padding: "0px 20px 0px 40px"}}>
                {activeStep === 0 ?
                    <MaterialPageItemModule
                        selectMaterialId={formState.material?.id}
                        selectMaterial={selectMaterial}
                        modal={true}
                        handleError={handleError}
                    />
                    :
                    <Box style={{display: "flex", flexDirection: "column", paddingTop: "10px"}}>
                        <TextFieldValidation
                            name={"quantity"}
                            value={formState?.quantity}
                            label="Количество"
                            setCurrValues={setFormState}
                            // validationError={validationState?.name}
                            // setValidationState={setValidationState}
                        />
                        <TextFieldValidation
                            name={"note"}
                            value={formState?.note}
                            label="Заметки"
                            setCurrValues={setFormState}
                            // validationError={validationState?.name}
                            // setValidationState={setValidationState}
                        />
                    </Box>}
            </DialogContent>

            <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <Box>{activeStep !== 0 && <ButtonBack text={"Назад"} onClick={() => setActiveStep(0)}/>} </Box>
                <Box>
                    <ButtonExit text={"Выйти"} onClick={onClose}/>
                    <ButtonAdd text={"Выбрать"} onClick={submit} disabled={!formState.material?.id}/>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
export default ModalAddMaterial


